import * as React from "react";

const BotIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".bot1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path
        className="bot"
        d="M566.7 102.8H457.3c-95.8 0-173.4 77.6-173.4 173.4 0 95.8 77.6 173.4 173.4 173.4h109.3c95.8 0 173.4-77.6 173.4-173.4.1-95.7-77.6-173.4-173.3-173.4zm90.1 151.9c0 42.2-34.2 76.3-76.3 76.3H435.3c-42.2 0-76.3-34.2-76.3-76.3 0-42.2 34.2-76.3 76.3-76.3h145.1c42.2 0 76.4 34.1 76.4 76.3zM440.4 480.7h143.3v51.5H440.4z"
        fill={color}
      />
      <circle className="bot1" cx={441.8} cy={254.7} r={31.1} />
      <circle className="bot1" cx={582.2} cy={254.7} r={31.1} />
      <path
        className="bot"
        d="M638.8 508H385.2c-94.1 0-171 77-171 171v.2C214.2 843 348.2 977 512 977s297.8-134 297.8-297.8v-.2c0-94.1-76.9-171-171-171zm73.4 279.2c0 32.8-26.6 59.4-59.4 59.4H371.2c-32.8 0-59.4-26.6-59.4-59.4V622.6c0-32.8 26.6-59.4 59.4-59.4h281.6c32.8 0 59.4 26.6 59.4 59.4v164.6zM452.8 65.3H563v56.5H452.8z"
        fill={color}
      />
      <path
        className="bot"
        d="M507.9 93.5c-7 0-12.7-5.7-12.7-12.7V49.7c0-7 5.7-12.7 12.7-12.7 7 0 12.7 5.7 12.7 12.7v31.2c0 7-5.7 12.6-12.7 12.6z"
        fill={color}
      />
      <circle className="bot1" cx={512} cy={708.7} r={73.3} />
      <circle className="bot1" cx={512} cy={640.7} r={26.2} />
      <path
        className="bot1"
        d="M462.8 673.9 428.9 640v-41.5h14.9v35.3l29.5 29.5zM443.8 811.3h-14.9v-47.9l29.4-29.3 10.5 10.5-25 25zM458.9 692.7H411l-29.3-29.3 10.5-10.5 25 25h41.7zM392.2 754.6l-10.5-10.5 29.3-29.3h47.9v14.8h-41.7zM561.2 673.9l-10.5-10.6 29.5-29.5v-35.3h14.9V640zM595.1 811.3h-14.9v-41.7l-25-25 10.5-10.5 29.4 29.3zM613 692.7h-47.9v-14.8h41.7l25-25 10.5 10.5zM631.8 754.6l-25-25h-41.7v-14.8H613l29.3 29.3z"
      />
    </g>
  </svg>
);

export default BotIcon;
