import * as React from "react";

const StaticIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".sta1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path
        className="sta"
        d="M775.5 788.2v45.9c0 13.6-11.1 24.6-24.6 24.6H132.4c-13.6 0-24.6-11.1-24.6-24.6V402.3h70.5V273h-45.9c-31.8 0-57.5 25.8-57.5 57.5v503.6c0 31.8 25.8 57.5 57.5 57.5h618.4c31.8 0 57.5-25.8 57.5-57.5v-45.9h-32.8zM138.6 314.7c13.5 0 24.5 10.9 24.5 24.5 0 13.5-10.9 24.5-24.5 24.5-13.5 0-24.5-10.9-24.5-24.5.1-13.6 11-24.5 24.5-24.5z"
        fill={color}
      />
      <path
        className="sta"
        d="M891.6 132.3H273.1c-31.8 0-57.5 25.8-57.5 57.5v503.6c0 31.8 25.8 57.5 57.5 57.5h618.4c31.8 0 57.5-25.8 57.5-57.5V189.9c.1-31.8-25.7-57.6-57.4-57.6zM438.2 174c13.5 0 24.5 10.9 24.5 24.5 0 13.5-10.9 24.5-24.5 24.5s-24.5-10.9-24.5-24.5c.1-13.6 11-24.5 24.5-24.5zm-79.4 0c13.5 0 24.5 10.9 24.5 24.5 0 13.5-10.9 24.5-24.5 24.5-13.5 0-24.5-10.9-24.5-24.5s11-24.5 24.5-24.5zm-79.5 0c13.5 0 24.5 10.9 24.5 24.5 0 13.5-10.9 24.5-24.5 24.5-13.5 0-24.5-10.9-24.5-24.5.1-13.6 11-24.5 24.5-24.5zm636.9 519.4c0 13.6-11.1 24.6-24.6 24.6H273.1c-13.6 0-24.6-11.1-24.6-24.6V261.6h667.7v431.8z"
        fill={color}
      />
      <circle className="sta1" cx={421.7} cy={407.1} r={58} />
      <circle className="sta1" cx={421.7} cy={353.2} r={20.8} />
      <path
        className="sta1"
        d="m382.7 379.5-26.8-26.8v-32.8h11.8v27.9l23.4 23.4zM367.7 488.3h-11.8v-37.9l23.2-23.2 8.3 8.3-19.7 19.8zM379.6 394.5h-37.9l-23.2-23.3 8.3-8.3 19.8 19.8h33zM326.8 443.4l-8.3-8.3 23.2-23.2h37.9v11.8h-33zM460.6 379.5l-8.3-8.3 23.3-23.4v-27.9h11.8v32.8zM487.4 488.3h-11.8v-33l-19.7-19.8 8.3-8.3 23.2 23.2zM501.6 394.5h-37.9v-11.8h33l19.8-19.8 8.3 8.3zM516.5 443.4l-19.8-19.7h-33v-11.8h37.9l23.2 23.2zM579.4 329.5h266.8v44.3H579.4zM579.4 421.3h266.8v44.3H579.4zM318.5 513h527.7v44.3H318.5zM318.5 604.8H726v44.3H318.5z"
      />
    </g>
  </svg>
);

export default StaticIcon;
