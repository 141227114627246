import * as React from "react"

const Vob = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="M167.898 262.411c-15.865.319-24.948 16.034-24.948 36.793 0 20.936 9.381 35.986 25.104 36.529 16.255.564 25.742-15.245 25.742-36.804-.005-19.911-9.326-36.852-25.898-36.518zM273.264 306.598l-9.366-.111v32.864c2.689.443 5.924.554 10.624.723 12.635.433 23.991-3.775 23.991-16.348 0-12.061-11.361-16.959-25.249-17.128zM295.515 272.921c0-9.015-7.246-13.634-19.899-13.381-5.982.119-9.395.546-11.723.934v27.155l8.997-.011c14.633-.02 22.625-5.864 22.625-14.697z" fill={color} />
    <path  d="M539.573 49.198h-178.2c-.786 0-1.561.077-2.342.124V0L11.227 46.417V540.98l347.804 47.62v-50.814c.781.053 1.551.116 2.342.116h178.2c20.846 0 37.8-16.965 37.8-37.8V86.999c0-20.839-16.955-37.801-37.8-37.801zM82.616 349.465l-23.889-1.128-27.812-99.127 21.389-.717 10.779 42.104c3.063 11.952 5.869 23.501 8.016 36.136l.398.016c2.283-12.103 5.125-24.1 8.25-35.688l11.815-43.875 22.887-.762-31.833 103.041zm84.185 5.754c-30.428-1.477-47.596-25.818-47.596-55.703 0-31.443 18.961-55.614 49.312-56.663 33.093-1.15 51.856 24.202 51.856 55.413-.004 37.083-22.198 58.482-53.572 56.953zm148.772-4.408c-9.329 6.56-24.717 9.745-49.167 8.563-13.339-.644-23.135-1.962-29.02-3.09V243.857c6.927-1.587 21.162-3.093 34.781-3.557 17.025-.572 27.649.665 36.915 5.535 8.991 4.216 15.577 12.334 15.577 23.327 0 10.824-6.771 20.904-21.263 25.877v.351c14.677 3.826 25.734 14.384 25.734 30.109 0 11.041-5.452 19.521-13.557 25.312zm43.458-29.806h139.842v92.222H359.031v-92.222zm118.326-13.168h-34.246l25.286-25.273h33.449l-24.489 25.273zm-59.517 0h-33.697l25.286-25.273h34.42l-26.009 25.273zm-58.809-.163V282.57h25.102l-25.102 25.104zm196.742 192.428c0 8.928-7.268 16.199-16.2 16.199h-178.2c-.796 0-1.571-.115-2.342-.231v-92.586h145.927a8.412 8.412 0 0 0 8.422-8.422V284.979c0-4.662-3.771-8.44-8.422-8.44H359.031V257.81c47.81-28.701 116.095-71.534 116.095-71.534 4.029-2.331 5.4-7.488 3.085-11.518l-8.427-20.745c-2.336-4.04-7.478-5.4-11.522-3.08l-99.23 60.317V71.041c.771-.113 1.54-.242 2.342-.242h178.2c8.933 0 16.2 7.269 16.2 16.2v413.103zM374.15 235.319l5.416-28.537 30.428-18.966-5.458 28.55-30.386 18.953zm53.657-32.727 5.068-28.617 29.9-19.197-4.361 28.162-30.607 19.652z" fill={color} />
    <path  d="M397.975 331.504a1.61 1.61 0 0 0-1.703-.174 1.59 1.59 0 0 0-.896 1.445v68.186c0 .601.349 1.17.896 1.434l.707.169c.358 0 .712-.126 1.002-.358l53.388-34.088c.375-.306.606-.78.606-1.266 0-.475-.227-.938-.606-1.266l-53.394-34.082z" fill={color} />
  </svg>
)

export default Vob
