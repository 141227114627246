import * as React from "react"

const Fla = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="m261.166 240.724-.357.01c-1.806 7.171-3.611 16.294-5.595 23.276l-7.182 25.481 26.974.084-7.664-25.654c-2.189-7.144-4.36-16.068-6.176-23.197z" fill={color} />
    <path  d="M357.396 535.33c.776.047 1.542.109 2.329.109h177.39c20.75 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.077-2.329.113V0L11.176 46.206v492.311l346.22 47.401V535.33zm0-464.613c.766-.113 1.532-.241 2.329-.241h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.893-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.127-2.329-.232v-443zM111.395 245.208l-40.315.682v25.303l37.628-.187v20.726l-37.628-.169v44.515l-23.118-.588V225.963l63.434-1.67v20.915zm88.427 94.094-70.014-1.755V223.809l24.945-.662v93.177l45.074.698v22.279h-.005zm88.806 2.225-9.585-31.402-34.94-.42-8.536 30.498-27.625-.704 36.161-118.701 36.221-.956 39.067 122.457-30.763-.772z" fill={color} />
    <path  d="M398.519 299.826h57.344V275.57h-57.344v-44.638h61.367v-24.244h-88.853v161.778h27.486zM524.506 371.363s.083-3.716.083-26.172c-13.92 0-16.808-2.321-16.808-14.878V207.705h-27.707v134.681c0 26.321 22.51 29.588 44.432 28.977z" fill={color} />
  </svg>
)

export default Fla
