import * as React from "react"

const Pct = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M537.115 48.974h-177.39c-.787 0-1.553.076-2.329.123V0L11.176 46.208v492.308l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.756 0 37.627-16.883 37.627-37.627V86.602c0-20.743-16.871-37.628-37.627-37.628zM357.396 291.728l45.879 19.94v43.549h-45.879v-63.489zm0-13.26v-26.877h36.294l-36.294 26.877zm-250.421 26.339c-7.756 7.899-19.087 11.354-32.073 11.244-2.856-.026-5.411-.205-7.381-.54v37.905l-20.953-.803V249.761c6.433-1.53 15.609-2.897 28.769-3.503 13.573-.641 23.431 1.795 30.129 7.335 6.486 5.268 10.885 14.269 10.885 25.051 0 10.795-3.339 19.958-9.376 26.163zm80.411 33.243c7.842.185 16.653-1.354 21.876-3.327l4.032 20.986c-4.911 2.27-15.83 4.458-29.796 3.905-38.176-1.521-57.017-26.151-57.017-57.784 0-37.882 25.887-60.203 59.55-61.804 13.515-.645 23.937 1.667 28.682 3.914l-5.451 21.426c-5.407-2.111-12.83-3.966-22.048-3.674-20.203.637-35.499 13.373-35.499 38.563.006 22.667 12.993 37.249 35.671 37.795zm136.256-78.226-36.8 1.108v100.952l-29.237-1.124v-98.95l-33.124 1.005v-22.67l99.161-4.585v24.264zM553.24 497.823c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.117-2.329-.231V360.591h51.255v-8.555c4.142 33.827 33.046 60.116 67.975 60.116 37.759 0 68.488-30.731 68.488-68.496 0-37.763-30.729-68.49-68.488-68.49-.189 0-.363 0-.562.011l-9.439-78.277-65.717 49.315h-43.512V70.717c.766-.113 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.127v411.221zm-77.455-217.244c.29-.01.562-.042.841-.042 34.809 0 63.113 28.319 63.113 63.121 0 34.794-28.314 63.115-63.113 63.115s-63.108-28.321-63.108-63.115c0-8.613 1.942-17.196 5.454-25.18l64.347 27.953-7.534-65.852zm-67.134 54.879v-21.461l5.327 2.321c-2.686 6.111-4.513 12.567-5.327 19.14z" fill={color} />
    <path  d="M76.476 264.41c-4.43.144-7.401.719-8.955 1.236v31.302c1.827.457 4.105.598 7.233.576 11.677-.072 18.982-6.695 18.982-17.778 0-9.95-6.288-15.685-17.26-15.336z" fill={color} />
  </svg>
)

export default Pct
