import * as React from "react";
const FileIcon = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 64 64">
    <path
      fill={color}
      d="M60 0H14c-2.211 0-4 1.789-4 4v27H4c-2.211 0-4 1.789-4 4v23a6 6 0 0 0 6 6h52a6 6 0 0 0 6-6V4c0-2.211-1.789-4-4-4zM10 58a4 4 0 0 1-8 0V35a2 2 0 0 1 2-2h6v25zm11-45h15a1 1 0 1 1 0 2H21a1 1 0 1 1 0-2zm32 38H21a1 1 0 1 1 0-2h32a1 1 0 1 1 0 2zm0-6H21a1 1 0 1 1 0-2h32a1 1 0 1 1 0 2zm0-6H21a1 1 0 1 1 0-2h32a1 1 0 1 1 0 2zm0-6H21a1 1 0 1 1 0-2h32a1 1 0 1 1 0 2zm0-6H21a1 1 0 1 1 0-2h32a1 1 0 1 1 0 2zm0-6H21a1 1 0 1 1 0-2h32a1 1 0 1 1 0 2z"
    />
  </svg>
);
export default FileIcon;
