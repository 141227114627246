import * as React from "react"

const Tga = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M537.115 48.974h-177.39c-.787 0-1.553.076-2.329.123V0L11.176 46.208v492.308l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.745 0 37.627-16.883 37.627-37.627V86.602c0-20.743-16.882-37.628-37.627-37.628zM98.004 258.002l-27.077.714v86.929l-21.65-.933V259.28l-24.673.641v-19.63l73.4-3.102v20.813zm99.574 87.63c-8.161 2.471-23.324 5.637-38.182 4.997-19.979-.883-34.084-6.646-43.732-16.919-9.504-9.796-14.665-24.278-14.504-40.413.157-36.52 25.255-58.479 60.888-60.016 14.567-.63 26 1.837 31.699 4.391l-5.525 21.449c-6.343-2.615-14.139-4.656-26.502-4.324-20.7.56-35.935 12.94-35.935 37.197 0 23.095 13.783 37.111 34.119 37.677 5.792.169 10.47-.387 12.49-1.363v-24.264l-17.27-.158v-20.201l42.449-.252v62.198h.005zm97.252 9.607-10.317-32.662-37.069-.778-8.927 31.025-28.63-1.239 37.557-120.734 38.447-1.627 42.438 127.441-33.499-1.426zm258.41 142.584c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.117-2.329-.231V353.863h49.868v-49.88l12.379 5.291c-4.924 9.408-7.518 19.854-7.518 30.342 0 36.284 29.522 65.807 65.801 65.807 36.284 0 65.802-29.522 65.802-65.807s-29.518-65.804-65.802-65.804c-.981 0-1.958.053-2.924.113l-9.196-76.121-62.699 47.055h-45.711V70.717c.766-.113 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.127v411.221zM365.779 280.042l35.754-26.835v42.101l-35.754-15.266zm56.011 21.84-12.614-5.396v-46.618l50.63-37.993 7.354 60.881c-18.472 2.969-34.846 13.484-45.37 29.126zm48.18-21.807 5.664 46.858-47.749-20.379c9.569-14.451 25.045-24.201 42.085-26.479z" fill={color} />
    <path  d="m265.458 251.053-.386.01c-1.914 7.391-3.827 16.788-5.924 23.958l-7.575 26.116 28.62.189-8.187-26.457c-2.312-7.346-4.624-16.512-6.548-23.816z" fill={color} />
  </svg>
)

export default Tga
