import * as React from "react"

const Mp4 = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="M359.036 537.786c.771.042 1.551.115 2.342.115h178.2c20.84 0 37.8-16.959 37.8-37.8v-413.1c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.069-2.342.114V0L11.222 46.419v494.562L359.036 588.6v-50.814zm0-466.753c.771-.113 1.54-.232 2.342-.232h178.2c8.933 0 16.199 7.267 16.199 16.2v413.1c0 8.934-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.127-2.342-.242V71.033zM115.717 330.676l-1.505-40.972c-.443-12.841-.886-28.387-.886-43.934h-.464c-3.122 13.68-7.291 28.88-11.14 41.331l-12.053 41.19-17.233-.422-10.291-40.634c-3.133-12.218-6.394-27.005-8.63-40.547l-.285.011c-.566 14.019-.994 30.029-1.695 42.946L49.844 329l-19.62-.496 5.951-102.766 28.529-.747 9.46 34.765c3.02 12.129 6.064 25.229 8.234 37.57l.433.011c2.753-12.174 6.117-26.116 9.326-37.897l10.61-35.442 29.855-.775 5.656 108.013-22.561-.56zm108.475-48.855c-8.585 8.134-21.189 11.709-35.72 11.594-3.208-.027-6.096-.201-8.321-.56v39.456l-23.783-.602V224.068c7.351-1.484 17.75-2.732 32.579-3.122 15.206-.398 26.169 2.312 33.605 8.134 7.138 5.524 11.981 14.84 11.981 25.914s-3.68 20.477-10.341 26.827zm109.015 27.213-14.164-.2v27.021l-25.703-.654v-26.704l-49.718-.686v-16.686l42.335-67.753 33.086-.799v66.403l14.164.066v19.992z" fill={color} />
    <path  d="M190.233 239.826c-4.97.087-8.321.643-10.083 1.163v32.566c2.078.49 4.627.644 8.153.644 13.081-.023 21.223-6.848 21.223-18.291 0-10.287-7.003-16.299-19.293-16.082zM282.972 264.094l-15.103 24.284v.34l25.471.124v-24.819c0-6.719.346-13.614.875-20.859l-.695.011c-3.71 7.289-6.687 13.85-10.548 20.919zM526.489 240.629H374.582v111.32h151.907v-111.32zm-42.747 56.888-51.996 33.201a1.568 1.568 0 0 1-.97.338l-.696-.158a1.562 1.562 0 0 1-.875-1.403v-66.4c0-.588.348-1.149.875-1.402a1.556 1.556 0 0 1 1.666.171l51.996 33.187c.369.316.58.77.58 1.233 0 .473-.211.927-.58 1.233zM532.574 351.959h-6.085v1.735H374.582v-1.735h-5.505v27.19h163.497v-27.19zm-3.47 23.71H372.558V355.43h156.547v20.239z" fill={color} />
    <path  d="M526.489 238.892v1.737h6.085v-27.187H369.077v27.187h5.505v-1.737h151.907zm-153.931-1.735V216.92h156.547v20.236H372.558z" fill={color} />
    <path  d="M378.643 221.724h23.267v9.861h-23.267zM408.448 221.724h23.267v9.861h-23.267zM438.021 221.724h23.267v9.861h-23.267zM467.816 221.724h23.272v9.861h-23.272zM498.582 222.109h23.267v9.859h-23.267zM378.928 360.302h23.282v9.861h-23.282zM408.733 360.302H432v9.861h-23.267zM438.307 360.302h23.267v9.861h-23.267zM468.112 360.302h23.267v9.861h-23.267zM498.867 360.682h23.271v9.861h-23.271z" fill={color} />
  </svg>
)

export default Mp4
