import React from "react";

const MemoryForensics = () => {
  return (
    <div className="dropped-files border" style={{ minHeight: "600px" }}>
      MemoryForensics
    </div>
  );
};

export default MemoryForensics;
