const FilledStar = ({ color = "var(--dark-theme-element)", width = 28, height = 28 }) => {
  return (
    <svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}>
      <polygon
        fill={color}
        points="448,208 301,208 256,64 211,208 64,208 183.1,297.3 136,448 256,352 376,448 328.9,297.3 "
      />
    </svg>
  );
};

export default FilledStar;
