import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import {
  RequestSetConfig,
  RequestDeleteConfig,
  RequestUpdateConfig,
} from "../../../../store/actions/integrationsActions";
import { useLocation } from "react-router-dom";
import Input from "../../../common/SubmitPopup/Input/Input";
import EyeCloseIcon from "../../../Icon-SVG/EyeCloseIcon";
import EyeIcon from "../../../Icon-SVG/EyeIcon";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import BackArrowIcon from "../../../Icon-SVG/BackArrowIcon";
import BackArrowAfterIcon from "../../../Icon-SVG/BackArrowAfterIcon";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ImapemailSettings = ({ show, setShow, dataTmp = 0, setActiveInt }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const [authority, setAuthority] = useState("");
  const [clientid, setClientid] = useState("");
  const [secret, setSecret] = useState("");
  const [secretid, setSecretid] = useState("");
  const [username, setUsername] = useState("");
  const [scope, setScope] = useState([]);
  const [password, setPassword] = useState("");
  const [confType, setConfType] = useState(false);
  const [server, setServer] = useState("");
  const [hiddenPw, setHiddenPw] = useState(true);
  const getConfig = useSelector((state) => state.integrations.getConfig);
  const setConfig = useSelector((state) => state.integrations.setConfig);
  const deleteConfig = useSelector((state) => state.integrations.deleteConfig);
  const loadingUpdate = useSelector((state) => state.integrations.loadingUpdate);
  const loading = useSelector((state) => state.integrations.loading);
  const [hoverBack, setHoverBack] = useState(false);
  let confTmp = parseInt(dataTmp);

  const closePopupHandler = () => {
    cleanData();
    setShow(false);
    setActiveInt("");
    history.push("/integration-market");
  };

  const cleanData = () => {
    setAuthority("");
    setClientid("");
    setSecret("");
    setSecretid("");
    setUsername("");
    setScope("");
    setPassword("");
    setServer("");
  };

  useEffect(() => {
    if (query.get("t") === "add") {
      setConfType(true);
    } else if (query.get("t") === null) {
      setHoverBack(false);
      cleanData();
    } else {
      setConfType(false);
    }
  }, [query]);

  useEffect(() => {
    if (deleteConfig === "Success" && query.get("conf") === "imapemail" && !loadingUpdate) {
      cleanData();
    }
  }, [deleteConfig]);

  useEffect(() => {
    if (setConfig === "Success" && query.get("conf") === "imapemail" && !loadingUpdate) {
      cleanData();
    }
  }, [setConfig]);

  useEffect(() => {
    if (query.get("conf") === "imapemail") {
      if (getConfig) {
        const configData = getConfig.config[confTmp];
        if (configData) {
          setAuthority(configData?.authority);
          setClientid(configData?.client_id);
          setSecret(configData?.secret);
          setSecretid(configData?.["secret-id"]);
          setUsername(configData?.username);
          setScope(configData?.scope);
          setPassword(configData?.password);
          setServer(configData?.server);
        }
      }
    }
  }, [getConfig, confTmp]);

  const addConfig = () => {
    if (authority && clientid && secret && secretid && username && password && server && scope) {
      const config = {
        authority: authority,
        client_id: clientid,
        scope: scope,
        secret: secret,
        ["secret-id"]: secretid,
        username: username,
        password: password,
        server: server,
        bulk: 16,
        ssl: 1,
      };
      const configString = JSON.stringify(config);
      const data = { appname: "imapemail", config: configString };

      dispatch(RequestSetConfig(data));
    } else {
      console.log("error");
    }
  };

  const updateConfigData = () => {
    if (authority && clientid && secret && secretid && username && password && server && scope) {
      const config = {
        authority: authority,
        client_id: clientid,
        scope: scope,
        secret: secret,
        ["secret-id"]: secretid,
        username: username,
        password: password,
        server: server,
        bulk: 16,
        ssl: 1,
      };
      const configString = JSON.stringify(config);
      const data = {
        appname: "imapemail",
        config: configString,
        mountpoint: getConfig?.config[confTmp]?.authority,
      };

      dispatch(RequestUpdateConfig(data));
    } else {
      console.log("error");
      // console.log(
      //   authority && clientid && secret && secretid && username && password && server && scope,
      // );
    }
  };

  const delConfig = () => {
    dispatch(
      RequestDeleteConfig({
        inttype: "imapemail",
        mountpoint: getConfig?.config[confTmp]?.authority,
      }),
    );
  };

  return (
    <div className={`${show && "imapemail-settings-backdrop"}`} onClick={closePopupHandler}>
      <div onClick={(e) => e.stopPropagation()}>
        {show ? (
          <div className="imapemail-settings border">
            <div className="imapemail-settings-buttons">
              <div
                onMouseEnter={() => setHoverBack(true)}
                onMouseLeave={() => setHoverBack(false)}
                onClick={() => {
                  history.push("/integration-market?bck=" + query.get("conf"));
                }}>
                <div className="integration-config-buttons-back">
                  {!hoverBack ? (
                    <BackArrowIcon color="var(--dark-theme-element)" width={20} height={20} />
                  ) : (
                    <BackArrowAfterIcon color="var(--dark-theme-element)" width={20} height={20} />
                  )}
                </div>
              </div>
              <div className="imapemail-settings-close-button__outer">
                <div onClick={closePopupHandler} className="imapemail-settings-close-button__inner">
                  <label className="imapemail-settings-close-button__close">Close</label>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-align-items-center">
                <div className="flex-column m-r-10">
                  <p className="label">Authority</p>
                  <Input
                    onChange={(e) => setAuthority(e.target.value)}
                    value={authority}
                    placeholder="Enter Authority"
                  />
                </div>
                <div className="flex-column m-l-10 relative">
                  <p className="label">Client Id</p>
                  <Input
                    onChange={(e) => setClientid(e.target.value)}
                    value={clientid}
                    placeholder="Enter Client Id"
                  />
                </div>
                <div className="flex-column m-l-10 relative">
                  <p className="label">Secret</p>
                  <Input
                    onChange={(e) => setSecret(e.target.value)}
                    value={secret}
                    placeholder="Enter Secret"
                  />
                </div>
              </div>
              <div className="flex flex-align-items-center">
                <div className="flex-column m-r-10">
                  <p className="label">Secret Id</p>
                  <Input
                    onChange={(e) => setSecretid(e.target.value)}
                    value={secretid}
                    placeholder="Enter Secret Id"
                  />
                </div>
                <div className="flex-column m-l-10">
                  <p className="label">Username</p>
                  <Input
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    placeholder="Enter Username"
                  />
                </div>
                <div className="flex-column m-l-10">
                  <p className="label">Password</p>
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Enter Password"
                    type={hiddenPw ? "password" : "text"}
                  />
                  {hiddenPw ? (
                    <span className="eye_pw" onClick={() => setHiddenPw(!hiddenPw)}>
                      <EyeIcon />
                    </span>
                  ) : (
                    !hiddenPw && (
                      <span className="eye_pw" onClick={() => setHiddenPw(!hiddenPw)}>
                        <EyeCloseIcon />
                      </span>
                    )
                  )}
                </div>
              </div>
              <div className="flex flex-align-items-center">
                <div className="flex-column m-r-10">
                  <p className="label">Server</p>
                  <Input
                    onChange={(e) => setServer(e.target.value)}
                    value={server}
                    placeholder="Enter Server"
                  />
                </div>
                <div className="flex-column m-r-10">
                  <p className="label">Scope</p>
                  <Input
                    onChange={(e) => setScope([e.target.value])}
                    value={scope}
                    placeholder="Enter Scope"
                  />
                </div>
              </div>
              {confType ? (
                <div>
                  <button onClick={() => addConfig()} className="submit-button">
                    Add Config
                  </button>
                </div>
              ) : (
                <div>
                  <button onClick={() => updateConfigData()} className="submit-button">
                    Update
                  </button>
                  <button className="delete-button" onClick={() => delConfig()}>
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {(loading || loadingUpdate) && query.get("conf") === "imapemail" && (
        <Backdrop absolute={true} show={loading || loadingUpdate} message="Please waiting.." />
      )}
    </div>
  );
};

export default ImapemailSettings;
