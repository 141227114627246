import React, { useState } from "react";
import AnalyzeUrl from "./AnalyzeUrl/AnalyzeUrl";

import "./styles.scss";

const ScriptAnalysis = () => {
  const [active, setActive] = useState("analyzeScript");

  return (
    <div className="url-analysis__content ">
      <div className="url-analysis__tabs">
        <div
          className={`url-analysis__tabs${active === "analyzeScript" ? "--active" : ""}`}
          onClick={() => setActive("analyzeScript")}>
          Analyze URL
        </div>
      </div>
      <div className="file-analysis__component">
        <AnalyzeUrl />
      </div>
    </div>
  );
};

export default ScriptAnalysis;
