import { useSelector } from "react-redux";
import { CategoryCheck } from "../../ReportExport/ClassCategories/CategoryCheck";

const ClassificationTags = () => {
  const signatureList = useSelector((state) => state.analysisReports.signatureList);

  const createSignaturesCategoriesArrayWithEveryCategory = () => {
    const newSignatureArray = [];

    for (const signature of signatureList) {
      for (const categorie of signature.categories) {
        newSignatureArray.push({ categories: categorie, severity: signature.severity });
      }
    }

    return CategoryCheck(newSignatureArray);
  };

  const ITEMS = createSignaturesCategoriesArrayWithEveryCategory();

  return (
    ITEMS && (
      <div className="analysis-reports__body-block__classification__tags">
        {ITEMS.slice(0, 8).map((item, index) => (
          <div
            key={index}
            className={`analysis-reports__body-block__classification__border analysis-reports__body-block__classification__border--${
              item.value
                ? item.value >= 65
                  ? "malicious"
                  : item.value <= 40
                  ? "nothing"
                  : "suspicious"
                : "nothing"
            }`}>
            <span className="analysis-reports__body-block__classification__text">
              {item.header}
            </span>
          </div>
        ))}
      </div>
    )
  );
};
export default ClassificationTags;
