import React from "react";

const OutlookIcon = ({ color = "var(--text-color-card)", width = 15, height = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 16.376 16.376"
      xmlSpace="preserve">
      <g fill={color}>
        <path d="M16.376 6.059c0-.3-.215-.544-.478-.544h-4.697v1.087h3.717L12.48 8.611l-1.279-1.048v1.379l1.003.813a.437.437 0 00.555-.001l2.661-2.157v2.873h-4.219v1.087h4.697c.263 0 .478-.243.478-.544V6.541l-.002-.038a.51.51 0 00.002-.038v-.406zM5.115 10.433c.686 0 1.126-.904 1.126-2.253 0-1.119-.325-2.253-1.126-2.253-.847 0-1.16 1.149-1.16 2.253 0 1.287.406 2.253 1.16 2.253z"></path>
        <path d="M0 14.992l10.195.748V.637L0 1.534v13.458zm5.162-10.72c1.741 0 2.902 1.578 2.902 3.831 0 2.743-1.475 4-2.995 4-1.66 0-2.937-1.44-2.937-3.861.001-2.423 1.208-3.97 3.03-3.97z"></path>
      </g>
    </svg>
  );
};

export default OutlookIcon;
