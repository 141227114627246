import React from "react";

const NetworkTraffic = (props) => {
  const networkTraffic = props.row.original.networkTraffic;
  return (
    <div className="dashboard__table__networkTraffic">
      <span style={{ color: "white" }}>{networkTraffic}</span>
    </div>
  );
};

export default NetworkTraffic;
