import React from "react";
import UserIcon from "../../../../Icon-SVG/UserIcon";
const Sender = (props) => {
  const sender = props.row.original.sender;
  return (
    <div className="dashboard__table__sender">
      <span
        className="dashboard__table__user--icon"
        style={sender.email ? { paddingTop: "10px" } : null}>
        <UserIcon width={15} height={15} color={"var(--text-color-card)"} />
      </span>
      <span>
        {sender.name}
        <br />
        {sender.email}
      </span>
    </div>
  );
};

export default Sender;
