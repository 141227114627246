import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  requestGetMailingConfigs,
  requestResetMailingConfigs,
  requestSetMailingConfigs,
} from "../../../../../../store/actions/settingsActions";
import Field from "../../../Components/Field";
import Warning from "../../../../../../Components/common/Warning/Warning";
import Tooltip from "../../../../../../Components/common/Tooltip/Tooltip";

import "../styles.scss";
import Spinner from "../../../../../../Components/common/Spinner/Spinner";

const SPINNER_SIZE = { width: 22, height: 22 };

const MailingConfigs = () => {
  const dispatch = useDispatch();

  const mailingConfigs = useSelector((state) => state.settings.mailingConfigs);
  const mailingConfigsLoading = useSelector((state) => state.settings.mailingConfigsLoading);

  const [server, setServer] = useState("");
  const [port, setPort] = useState("");
  const [security, setSecurity] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderPassword, setSenderPassword] = useState("");

  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(requestGetMailingConfigs());
  }, []);

  useEffect(() => {
    if (mailingConfigs) {
      setServer(mailingConfigs.server);
      setPort(mailingConfigs.port);
      setSecurity(mailingConfigs.security);
      setSenderEmail(mailingConfigs.senderEmail);
    }
  }, [mailingConfigs]);

  const configsSaveButtonClickHandler = () => {
    if (server && port && senderEmail && senderPassword && security) {
      dispatch(
        requestSetMailingConfigs({
          server,
          port,
          security,
          senderEmail,
          senderPassword,
        }),
      );
    } else {
      setError("Please fill in all the fields");
      setShow(true);
    }
  };

  const configsDefaultButtonClickHandler = () => {
    dispatch(requestResetMailingConfigs());
    dispatch(requestGetMailingConfigs());
  };

  return (
    <div className="border mailing__content">
      <div className="mailing__content__body">
        <h3 className="m-b-20">Server Configs</h3>
        <div className="mailing__content__security m-b-20">
          <p className="light m-b-10 mailing__content__security__header">
            Security
            <Tooltip
              text={
                "Make sure to select SSL/TLS option for popular providers as most of them do not support starting the connection with unsecure options."
              }
            />
          </p>
          <div className="mailing__content__security__labels">
            <label className="align-items">
              <input
                className="mailing__content__security__input"
                type="radio"
                value="STARTTLS"
                name="security"
                checked={security === "STARTTLS"}
                onChange={(event) => setSecurity(event.target.value)}
              />
              <p className="margin-left-12">STARTTLS</p>
            </label>
            <label className="align-items">
              <input
                className="mailing__content__security__input"
                type="radio"
                value="SSL/TLS"
                name="security"
                checked={security === "SSL/TLS"}
                onChange={(event) => setSecurity(event.target.value)}
              />
              <p className="margin-left-12">SSL/TLS</p>
            </label>
            <label className="align-items">
              <input
                className="mailing__content__security__input"
                type="radio"
                value="NO SECURITY"
                name="security"
                checked={security === "NO SECURITY"}
                onChange={(event) => setSecurity(event.target.value)}
              />
              <p className="margin-left-12">NO ENCRYPTION </p>
            </label>
          </div>
        </div>
        <div className="flex flex-align-items-center m-b-10">
          <Field
            label="Server"
            width="131px"
            value={server}
            onChange={(e) => setServer(e.target.value)}
            parentClassName="p-r-20"
            tooltipText="Server domain or IP address can be provided. Only SMTP protocol is allowed."
          />
          <Field
            label="Port"
            onChange={(e) => setPort(e.target.value)}
            value={port}
            width="131px"
          />
        </div>
        <h3 className="flex flex-align-items-center m-b-20 m-t-20">Email Configs</h3>
        <div className="flex flex-align-items-center m-b-20">
          <Field
            label="Sender Email"
            onChange={(e) => setSenderEmail(e.target.value)}
            value={senderEmail}
            width="131px"
            parentClassName="p-r-20"
          />
          <Field
            label="Password"
            value={senderPassword}
            onChange={(e) => setSenderPassword(e.target.value)}
            width="131px"
            parentClassName="p-r-20"
            type="password"
            tooltipText="For some providers Application Password should be provided instead of the email's password. Use with caution as for some cases your account can be blocked."
          />
        </div>
        <div className="flex flex-align-items-center m-b-10">
          <button className="m-r-15 default-button" onClick={configsDefaultButtonClickHandler}>
            Reset
          </button>
          <button className="m-l-15 primary-button" onClick={configsSaveButtonClickHandler}>
            {mailingConfigsLoading ? (
              <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </div>
  );
};

export default MailingConfigs;
