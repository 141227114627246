import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ChatGPT from "../ChatGPT/ChatGPT";
import Message from "../../../../../common/Message/Message";

import { RequestGetComment } from "../../../../../../store/actions/analysisReportActions";
import AddNewComment from "./AddNewComment";

import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Comments = () => {
  const dispatch = useDispatch();
  const getComment = useSelector((state) => state.analysisReports.getComment);
  const users = useSelector((state) => state?.users.user);
  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  useEffect(() => {
    dispatch(RequestGetComment(query.get("data")));
  }, [dispatch, query.get("data")]);

  return (
    <div className="comments-messages__block border">
      <div className="comments-messages__left-block">
        <h3>GPT AI Comments</h3>
        <ChatGPT />
        <AddNewComment />
      </div>
      <div className="comments-messages__right-block">
        <h3>Analyst Comments</h3>
        {getComment && getComment.length > 0 ? (
          <div className="comments-messages__container">
            {getComment
              .filter((message) => !message.replyTo)
              .map((message) => (
                <div className="comments-messages__message-reply" key={message._id.$oid}>
                  <Message
                    // key={message._id.$oid}
                    commentID={message._id}
                    date={message.date}
                    username={message.user}
                    text={message.text}
                    edited={message.edited}
                    replyTo={message.replyTo}
                    current_user={users.username}
                  />
                  {getComment.filter((reply) => reply.replyTo === message._id.$oid).length > 0 ? (
                    <div className="comments-messages__reply-container">
                      {getComment
                        .filter((reply) => reply.replyTo === message._id.$oid)
                        .map((reply) => (
                          <Message
                            key={reply._id.$oid}
                            commentID={reply._id}
                            date={reply.date}
                            username={reply.user}
                            text={reply.text}
                            edited={reply.edited}
                            replyTo={reply.replyTo}
                            current_user={users.username}
                          />
                        ))}
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Comments;
