import React from "react";
import Tooltip from "../../../../Components/common/Tooltip/Tooltip";

const MultiLineField = ({
  width,
  value,
  onChange,
  tooltipText,
  label,
  className,
  parentClassName,
  placeholder,
  tooltipWidth,
  rows,
  cols,
}) => {
  return (
    <div className={parentClassName}>
      <p className="settings__label">
        {label}
        {tooltipText && <Tooltip tooltipWidth={tooltipWidth} text={tooltipText} />}
      </p>
      <textarea
        className={`settings__multi-line-field ${className}`}
        value={value}
        onChange={onChange}
        style={{ width }}
        rows={rows}
        cols={cols}
      />
    </div>
  );
};

export default MultiLineField;
