import React from "react";
import CardForm from "../../../Components/common/CardForm/CardForm";
import { Link } from "react-router-dom";
import MessageIcon from "../../../Components/Icon-SVG/MessageIcon";

const CheckEmail = ({ email }) => {
  return (
    <CardForm>
      <div className="card__body">
        <div className="card__body__icon">
          <MessageIcon color="var(--text-color-card)" width={60} height={52} />
        </div>
        <h2 style={{ textAlign: "center" }}>Check your email</h2>
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          We sent a password resent link to {email}
        </p>
      </div>
      <button className="card__form-btn">Open email app</button>
      <p style={{ textAlign: "center" }}>
        Go back to <Link to="/login">Login</Link>
      </p>
    </CardForm>
  );
};

export default CheckEmail;
