import * as React from "react";
const DataFlowIcon = ({ width = 56, height = 56 }) => (
  <svg
    xmlSpace="preserve"
    style={{
      maxHeight: 500,
    }}
    viewBox="2244 0 621 641.0138248847926"
    y="0px"
    x="0px"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width={width}
    height={height}>
    <style type="text/css">{"\n\t.st0{fill:#54595D;}\n\t.st1{fill:#E56C4D;}\n"}</style>
    <g>
      <g>
        <path
          d="M2245.3,636.8c-0.2-1.7,1.1-2.6,1.9-3.8c44.1-66.8,198.8-301.1,232.1-351.4c0.8-1.3,1.8-2.5,2.9-4.2&#xA;&#9;&#9;&#9;c1.2,1.8,2.2,3.2,3.1,4.6c65.6,98.2,197.9,295.7,198.2,296.4c0.9,1.9,2.4,2.5,4.5,2.4c9.1-0.1,46.3,0,55.9,0&#xA;&#9;&#9;&#9;c0.2-1.8-1.1-2.8-1.8-3.9c-26.9-39-93.3-134.9-99.3-144.1c-6.7-10.3-6.8-21-0.1-31.3c10.5-16.3,34.3-16.8,45.8-1.1&#xA;&#9;&#9;&#9;c4.9,6.7,147.5,213.5,160.6,232.5c0.8,1.2,1.9,2.2,2.1,3.7c-65.5,0-130.7,0-196.2,0c-0.9-1.7-1.6-2.7-2.3-3.7&#xA;&#9;&#9;&#9;c-11.5-17.1-123.5-183.4-168-249.5c-0.8-1.2-1.4-2.5-2.8-3.6c-44.2,66.8-88.2,133.5-132.4,200.3c1.8,0.7,3.1,0.5,4.4,0.5&#xA;&#9;&#9;&#9;c16.1,0,32.2-0.1,48.3,0.1c3,0,4.7-1,6.4-3.4c15.7-23.8,31.5-47.5,47.4-71.2c5.2-7.8,10.3-15.7,15.6-23.4&#xA;&#9;&#9;&#9;c7-10.1,19.1-14.4,30.6-11.2c18.6,5.2,26.4,26.9,15.6,43c-15.6,23.4-69.9,104.8-81.5,122.1C2372.6,636.8,2309,636.8,2245.3,636.8z"
          className="st0"
        />
        <path
          d="M2352.4,70.4c0.8,1.2,67.5,98,100,145c3.6,5.2,6.1,10.8,6.1,17.2c0.1,12.3-7.4,23.1-18.8,27&#xA;&#9;&#9;&#9;c-11.6,4-24.4,0.1-31.8-9.9c-3.2-4.3-147.7-213.9-160.4-232.3c-0.8-1.2-1.5-2.4-2.3-3.6c1.1-1,2.2-0.6,3.2-0.6&#xA;&#9;&#9;&#9;c63.1,0,126.2,0,189.3-0.1c3,0,4.2,1.4,5.6,3.4c40.7,60.5,169.9,252.3,171.1,254c44.3-67,88.4-133.7,132.5-200.5&#xA;&#9;&#9;&#9;c-1.5-1-2.7-0.6-3.8-0.6c-16.4,0-32.7,0-49.1-0.1c-2.7,0-4.3,0.8-5.8,3.1c-20.7,31.2-41.5,62.4-62.3,93.6&#xA;&#9;&#9;&#9;c-5,7.5-11.7,12-20.6,13.3c-14.9,2.3-30-9.5-31.4-24.6c-0.7-7.1,0.9-13.6,4.9-19.5c13.1-19.6,66-98.9,79.3-118.9&#xA;&#9;&#9;&#9;c1.6-2.4,3.2-3.2,6.1-3.2c61,0.1,122,0.1,183,0.1c1.3,0,2.6-0.3,4.3,0.6c-79,119.6-237,358.8-237,358.8S2412.9,72,2412.5,71.2&#xA;&#9;&#9;&#9;c-0.7-1.3-1.7-1.8-3.2-1.8c-18.6,0-37.2,0-55.8,0.1C2353.1,69.5,2352.6,69.5,2352.4,70.4z"
          className="st1"
        />
      </g>
      <g>
        <g>
          <path
            d="M238.1,224.4c14.7,0,28.5,2.4,41.4,7.1c12.9,4.7,24.1,11.4,33.7,20.1s17.1,19.6,22.7,32.8&#xA;&#9;&#9;&#9;&#9;c5.6,13.2,8.4,27.7,8.4,43.6s-2.8,30.4-8.4,43.6s-13.1,24.1-22.7,32.8c-9.6,8.7-20.8,15.4-33.7,20.1c-12.9,4.7-26.7,7.1-41.4,7.1&#xA;&#9;&#9;&#9;&#9;h-89.5V224.4H238.1z M238.1,387.1c16.7,0,30.4-5.1,41.1-15.2c10.8-10.2,16.1-24.8,16.1-43.8c0-18.9-5.4-33.5-16.1-43.7&#xA;&#9;&#9;&#9;&#9;s-24.4-15.3-41.2-15.3h-38.5v118.1h38.6V387.1z"
            className="st0"
          />
          <polygon
            points="489.8,224.4 433.7,224.4 354.5,431.6 406.8,431.6 461.8,277.1 516.9,431.6 569,431.6"
            className="st0"
          />
          <path d="M738.9,224.4V269h-59.2v162.7h-51.2V269H569v-44.5h169.9V224.4z" className="st0" />
          <polygon
            points="871.2,224.4 815.1,224.4 735.9,431.6 788.2,431.6 843.2,277.1 898.3,431.6 950.4,431.6"
            className="st0"
          />
        </g>
        <g>
          <path
            d="M1201.9,287.3h-49.6c-0.6-7.6-3.9-13.9-9.8-18.8c-6-4.9-14.2-7.4-24.8-7.4c-10.1,0-18.2,2-24.3,5.9&#xA;&#9;&#9;&#9;&#9;c-6.2,3.9-9.3,9.6-9.3,16.9c0,2.4,0.5,4.5,1.6,6.4s2.8,3.6,5.2,5s4.8,2.8,7.2,3.8c2.4,1.1,5.7,2.1,9.8,3.1&#xA;&#9;&#9;&#9;&#9;c4.1,1,7.6,1.8,10.7,2.4c3,0.6,7.1,1.5,12.1,2.4c5.1,1,9.1,1.8,12.1,2.5c21.6,5.1,37.4,11.8,47.4,20.1&#xA;&#9;&#9;&#9;&#9;c10,8.3,15.1,20.5,15.2,36.7c0.2,21-7.3,37.5-22.3,49.6c-15.1,12-36.1,18.1-63,18.1c-28,0-50-5.8-66-17.3s-24-28.7-24-51.4h51.8&#xA;&#9;&#9;&#9;&#9;c0.7,8.4,4.3,14.9,11,19.7c6.6,4.7,15.7,7.1,27.4,7.1c10.2-0.1,18.2-2.3,24-6.6c5.8-4.3,8.7-10,8.7-17.1c0-3.9-1.6-7.4-4.9-10.5&#xA;&#9;&#9;&#9;&#9;s-7.8-5.6-13.8-7.7s-11.8-3.8-17.7-5.3c-5.9-1.5-12.6-3.1-20.1-4.7c-7.5-1.7-13.4-3.2-17.7-4.6c-14.9-4.9-26-11.3-33.3-19.2&#xA;&#9;&#9;&#9;&#9;c-7.3-7.8-11-18.1-11-30.7c0-20.9,7.6-36.8,22.9-47.7c15.2-10.9,36.5-16.3,63.7-16.3c25,0,44.4,5.7,58.4,17.2&#xA;&#9;&#9;&#9;&#9;C1193.3,250.7,1200.8,266.7,1201.9,287.3z"
            className="st1"
          />
          <path
            d="M1385,269h-94.1v37.9h76.7v42.3h-76.7v37.9h94.1v44.5h-145.2V224.4H1385V269z"
            className="st1"
          />
          <path
            d="M1513.3,387.2c12.1,0,22.6-3.1,31.5-9.4s15-15.4,18.5-27.3l50.5,7.5c-5.4,23.6-17.3,42.2-35.7,55.7&#xA;&#9;&#9;&#9;&#9;s-40,20.3-64.8,20.3c-19.6,0-37.3-4.2-53.1-12.6c-15.7-8.4-28.3-20.7-37.6-36.9c-9.3-16.2-14-35.1-14-56.6s4.7-40.4,14-56.6&#xA;&#9;&#9;&#9;&#9;s21.9-28.5,37.6-36.9s33.4-12.6,53.1-12.6c24.9,0,46.5,6.8,64.8,20.3c18.4,13.6,30.2,32.1,35.7,55.7l-50.5,7.5&#xA;&#9;&#9;&#9;&#9;c-3.5-11.9-9.6-21-18.5-27.3s-19.4-9.4-31.5-9.4c-15.8,0-28.7,5.2-38.6,15.5c-10,10.4-14.9,24.9-14.9,43.7s5,33.3,14.9,43.7&#xA;&#9;&#9;&#9;&#9;C1484.6,382.1,1497.5,387.2,1513.3,387.2z"
            className="st1"
          />
          <path
            d="M1779.1,224.6h51.1v140.6c0,10.7-1.9,20.1-5.8,28.4c-3.8,8.3-8.8,14.9-14.8,19.9s-13.1,9.1-21.3,12.3&#xA;&#9;&#9;&#9;&#9;c-8.2,3.2-16.2,5.4-24.1,6.6s-16,1.8-24.4,1.8c-7-0.1-13.8-0.6-20.3-1.4c-6.6-0.8-13.3-2.2-20.4-4.2c-7-2-13.4-4.7-19.2-8.2&#xA;&#9;&#9;&#9;&#9;c-5.8-3.5-11-7.7-15.7-12.6s-8.3-11-11-18.2c-2.6-7.3-3.9-15.4-3.9-24.3V224.6h51.1v133.1c0,10.7,3.8,18.7,11.4,24&#xA;&#9;&#9;&#9;&#9;c7.6,5.4,16.9,8.1,28,8.1c11,0,20.4-2.7,28-8.1s11.4-13.4,11.4-24L1779.1,224.6L1779.1,224.6z"
            className="st1"
          />
          <path
            d="M2052.8,431.6h-53.4l-37.9-65h-39.8v65h-51.1V224.4h106.9c18.4,0,34,6.7,46.8,20.2&#xA;&#9;&#9;&#9;&#9;c12.7,13.5,19.1,30.4,19.1,50.8c0,14.1-3.1,26.7-9.4,37.8c-6.3,11.1-14.7,19.6-25.4,25.4L2052.8,431.6z M1921.6,269v55.4h46&#xA;&#9;&#9;&#9;&#9;c7,0,12.9-2.5,17.5-7.5c4.7-5,7-11.7,7-20.2s-2.3-15.2-7-20.2s-10.5-7.5-17.5-7.5L1921.6,269L1921.6,269z"
            className="st1"
          />
          <path
            d="M2225.2,269H2131v37.9h76.7v42.3H2131v37.9h94.1v44.5H2080V224.4h145.2V269z"
            className="st1"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default DataFlowIcon;
