import PlayIcon from "../../../../Icon-SVG/PlayIcon";
import PauseIcon from "../../../../Icon-SVG/PauseIcon";
import MaximizeIcon from "../../../../Icon-SVG/MaximizeIcon";
import MinimizeIcon from "../../../../Icon-SVG/MinimizeIcon";
import PaginationLeftIcon from "../../../../Icon-SVG/PaginationLeftIcon";
import PaginationRightIcon from "../../../../Icon-SVG/PaginationRightIcon";

const LeftNav = ({ onClick, disabled }) => (
  <button
    type="button"
    className="image-gallery-icon image-gallery-left-nav"
    disabled={disabled}
    onClick={onClick}
    aria-label="Previous Slide">
    <PaginationLeftIcon width={24} height={24} color="var(--header-search-text-color)" />
  </button>
);

const RightNav = ({ onClick, disabled }) => (
  <button
    type="button"
    className="image-gallery-icon image-gallery-right-nav"
    disabled={disabled}
    onClick={onClick}
    aria-label="Previous Slide">
    <PaginationRightIcon width={24} height={24} color="var(--header-search-text-color)" />
  </button>
);

const PlayPause = ({ onClick, isPlaying }) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-play-button"
      onClick={onClick}
      aria-label="Play or Pause Slideshow">
      {isPlaying ? <PauseIcon width={18} height={18} /> : <PlayIcon width={18} height={18} />}
    </button>
  );
};

const FullScreen = ({ onClick, isFullscreen }) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-fullscreen-button"
      onClick={onClick}
      aria-label="Open Fullscreen">
      {isFullscreen ? (
        <MinimizeIcon width={18} height={18} />
      ) : (
        <MaximizeIcon width={18} height={18} />
      )}
    </button>
  );
};

export const renderLeftNav = (onClick, disabled) => (
  <LeftNav onClick={onClick} disabled={disabled} />
);

export const renderRightNav = (onClick, disabled) => (
  <RightNav onClick={onClick} disabled={disabled} />
);

export const renderPlayPauseButton = (onClick, isPlaying) => (
  <PlayPause onClick={onClick} isPlaying={isPlaying} />
);

export const renderFullscreenButton = (onClick, isFullscreen) => (
  <FullScreen onClick={onClick} isFullscreen={isFullscreen} />
);
