import * as React from "react";

const AnalyserIcon = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 353.332 353.332"
    xmlSpace="preserve"
    width={width}
    height={height}
    fill={color}>
    <path d="M85.831 210h60c8.284 0 15-6.717 15-15 0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15 0 8.283 6.716 15 15 15z" />
    <path d="m348.107 327.725-30.938-30.938c7.573-11.537 11.995-25.319 11.995-40.121 0-35.297-25.068-64.844-58.334-71.786V125a15 15 0 0 0-4.393-10.607l-110-110A14.997 14.997 0 0 0 145.831 0h-130c-8.284 0-15 6.715-15 15v300c0 8.283 6.716 15 15 15h240c14.805 0 28.588-4.424 40.127-11.998l30.937 30.936a14.95 14.95 0 0 0 10.606 4.395c3.839 0 7.678-1.465 10.606-4.395 5.859-5.858 5.859-15.355 0-21.213zm-48.942-71.059c0 23.895-19.439 43.334-43.334 43.334-23.895 0-43.334-19.44-43.334-43.334s19.439-43.334 43.334-43.334c23.894 0 43.334 19.439 43.334 43.334zM160.831 51.213 219.618 110h-58.787V51.213zM30.831 30h100v95c0 8.284 6.716 15 15 15h95v44.88c-27.862 5.814-49.953 27.493-56.4 55.12h-98.6c-8.284 0-15 6.715-15 15 0 8.283 6.716 15 15 15h97.903a72.989 72.989 0 0 0 12.983 30H30.831V30z" />
  </svg>
);

export default AnalyserIcon;
