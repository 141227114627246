import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  requestGetMaliciousSubmissionMailContent,
  requestResetMaliciousSubmissionMailContent,
  requestSetMaliciousSubmissionMailContent,
} from "../../../../../../store/actions/settingsActions";
import Field from "../../../Components/Field";
import MultiLineField from "../../../Components/MultiLineField";
import Warning from "../../../../../../Components/common/Warning/Warning";

import "../styles.scss";

const MailContents = () => {
  const dispatch = useDispatch();

  const mailContent = useSelector((state) => state.settings.mailContent);

  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(requestGetMaliciousSubmissionMailContent());
  }, []);

  useEffect(() => {
    if (mailContent) {
      setEmailSubject(mailContent.mailSubject);
      setEmailBody(mailContent.mailBody);
    }
  }, [mailContent]);

  const maliciousSubmissionReporterContentReset = () => {
    dispatch(requestResetMaliciousSubmissionMailContent());
  };

  const maliciousSubmissionReporterContentSave = () => {
    if (emailSubject && emailBody) {
      dispatch(
        requestSetMaliciousSubmissionMailContent({ subject: emailSubject, body: emailBody }),
      );
    } else {
      setError("Please fill in all the fields");
      setShow(true);
    }
  };

  useEffect(() => {});
  return (
    <div className="border mailing__content">
      <div className="mailing__content__header">
        <h3>Mail Content</h3>
        <p>
          NOTE: This configuration is optional. If no email subject and body are set in this tab,
          default email subject and body will be used.
        </p>
      </div>
      <div className="mailing__content__body">
        <div className="m-b-10">
          <Field
            label="Email Subject"
            onChange={(e) => setEmailSubject(e.target.value)}
            value={emailSubject}
            width="500px"
            parentClassName="m-b-10"
            tooltipText={
              "Special variables can be used in mail subject. For now just one variable is available: File Name. \n Reported file's name can be accessed via {{fileName}}"
            }
          />
          <MultiLineField
            label="Email Body"
            onChange={(e) => setEmailBody(e.target.value)}
            value={emailBody}
            width="500px"
            tooltipText={
              "Special variables can be used in mail body. For now just one variable is available: File Name. \n Reported file's name can be accessed via {{fileName}}"
            }
          />
        </div>
        <div className="flex flex-align-items-center m-b-10">
          <button
            className="m-r-15 default-button"
            onClick={maliciousSubmissionReporterContentReset}>
            Default
          </button>
          <button
            className="m-l-15 primary-button"
            onClick={maliciousSubmissionReporterContentSave}>
            Save
          </button>
        </div>
      </div>
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </div>
  );
};

export default MailContents;
