import React from "react";

const FilePath = (props) => {
  const path = props.row.original.path;
  return (
    <div className="dashboard__table__user">
      <p>{path}</p>
    </div>
  );
};

export default FilePath;
