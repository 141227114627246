import React, { useState } from "react";
import EmailSend from "./Tabs/EmailSend";
import CheckEmail from "./Tabs/CheckEmail";

const ForgotPassword = () => {
  const [tabs, setTabs] = useState("email-send");
  const [email, setEmail] = useState("");

  return (
    <>
      {tabs === "email-send" ? (
        <EmailSend setTabs={setTabs} email={email} setEmail={setEmail} />
      ) : (
        tabs === "check-email" && <CheckEmail setTabs={setTabs} email={email} />
      )}
    </>
  );
};

export default ForgotPassword;
