import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  requestTimePeriod,
  updateTimePeriodRequest,
} from "../../../../../store/actions/settingsActions";

import "./styles.scss";

const DataRetention = () => {
  const timePeriodFromDB = useSelector((state) => state.settings.timePeriod);
  const dispatch = useDispatch();
  const [timePeriod, setTimePeriod] = useState(timePeriodFromDB);

  useEffect(() => {
    dispatch(requestTimePeriod());
  }, [dispatch]);

  useEffect(() => {
    setTimePeriod(timePeriodFromDB);
  }, [timePeriodFromDB]);

  const timePeriodHandler = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setTimePeriod(Number(e.target.value));
    }
  };

  const updateTimePeriodHandler = () => {
    if (timePeriod === "") return;
    dispatch(updateTimePeriodRequest({ time_period: timePeriod }));
  };

  return (
    <div className="data-retention">
      <h1 className="data-retention__header">Data Retention</h1>
      <div className="data-retention__body">
        <div className="data-retention__body__header">
          <h3>Data retention</h3>
          <p>NOTE: Delete operation will be performed at 11pm everyday.</p>
        </div>
        <div className="data-retention__body__content">
          <div className="data-retention__field">
            <p className="data-retention__field__label">Time period</p>
            <input
              className="data-retention__field__input"
              value={timePeriod}
              onChange={timePeriodHandler}
              placeholder="Time period"
            />
            <span className="data-retention__field__unit">days</span>
          </div>
          <button
            className="primary-button"
            onClick={updateTimePeriodHandler}
            disabled={timePeriod === "" || timePeriod === timePeriodFromDB}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataRetention;
