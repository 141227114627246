import React from "react";
import "./styles.scss";
import UpdateLogo from "../../PagesComponents/UserProfile/UpdateLogo/UpdateLogo";

const LogoSettings = ({ button }) => {
  return (
    <div className="logo-settings">
      <div className="logo-settings-inner">
        <div className="logo-settings-header">
          <p>Logo Settings</p>
        </div>
        <div className="flex flex-align-items-center flex-justify-content-center flex-wrap gap">
          <UpdateLogo />
        </div>
      </div>
    </div>
  );
};

export default LogoSettings;
