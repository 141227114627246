import React from "react";

const DoughnutChartIcon = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5233 8.47046H8.54418L13.1921 13.1184C13.3698 13.296 13.6621 13.3104 13.8448 13.1384C14.983 12.066 15.7659 10.6205 15.9957 8.99546C16.0351 8.71722 15.8042 8.47046 15.5233 8.47046ZM15.0577 6.56458C14.8154 3.05106 12.0077 0.243416 8.49418 0.00106353C8.22595 -0.0174658 8.00006 0.20871 8.00006 0.477533V7.0587H14.5815C14.8504 7.0587 15.0762 6.83281 15.0577 6.56458ZM6.5883 8.47046V1.49135C6.5883 1.21047 6.34153 0.979591 6.06359 1.019C2.55859 1.5143 -0.120524 4.57635 0.00418193 8.24605C0.132417 12.0149 3.37742 15.1054 7.14801 15.0581C8.63036 15.0396 10.0001 14.5619 11.1262 13.7631C11.3586 13.5984 11.3739 13.2563 11.1724 13.0549L6.5883 8.47046Z"
        fill={color || "var(--text-color-card)"}
      />
    </svg>
  );
};

export default DoughnutChartIcon;
