import React from "react";

const InformationIcon = ({ color = "var(--color-blue)", width = 18, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.75C4.44375 0.75 0.75 4.44375 0.75 9C0.75 13.5562 4.44375 17.25 9 17.25C13.5562 17.25 17.25 13.5562 17.25 9C17.25 4.44375 13.5562 0.75 9 0.75ZM8.625 4.5C8.42609 4.5 8.23532 4.57902 8.09467 4.71967C7.95402 4.86032 7.875 5.05109 7.875 5.25C7.875 5.44891 7.95402 5.63968 8.09467 5.78033C8.23532 5.92098 8.42609 6 8.625 6H9C9.19891 6 9.38968 5.92098 9.53033 5.78033C9.67098 5.63968 9.75 5.44891 9.75 5.25C9.75 5.05109 9.67098 4.86032 9.53033 4.71967C9.38968 4.57902 9.19891 4.5 9 4.5H8.625ZM7.5 7.5C7.30109 7.5 7.11032 7.57902 6.96967 7.71967C6.82902 7.86032 6.75 8.05109 6.75 8.25C6.75 8.44891 6.82902 8.63968 6.96967 8.78033C7.11032 8.92098 7.30109 9 7.5 9H8.25V11.25H7.5C7.30109 11.25 7.11032 11.329 6.96967 11.4697C6.82902 11.6103 6.75 11.8011 6.75 12C6.75 12.1989 6.82902 12.3897 6.96967 12.5303C7.11032 12.671 7.30109 12.75 7.5 12.75H10.5C10.6989 12.75 10.8897 12.671 11.0303 12.5303C11.171 12.3897 11.25 12.1989 11.25 12C11.25 11.8011 11.171 11.6103 11.0303 11.4697C10.8897 11.329 10.6989 11.25 10.5 11.25H9.75V8.25C9.75 8.05109 9.67098 7.86032 9.53033 7.71967C9.38968 7.57902 9.19891 7.5 9 7.5H7.5Z"
        fill={color}
      />
    </svg>
  );
};

export default InformationIcon;
