const detectedOrUndetected = (val, showCount) => {
  if (val && val !== "Unknown" && showCount) {
    return `DETECTED (${val})`;
  } else if (val === "Unknown") {
    return "N/A";
  } else if (val) {
    return "DETECTED";
  } else {
    return "CLEAN";
  }
};

const ClamCloudInfo = ({
  vt = null,
  clamav = null,
  cdecision = null,
  mlcheck = null,
  yara = null,
  networkTrafficCount = null,
  setStaticResultsActive = null,
  report = false,
  showNA = false,
}) => {
  const ITEMS = [];
  if (yara !== null) {
    ITEMS.push({
      header: "YARA",
      value: yara.value ? yara.value : yara === "Unknown" ? yara : false,
      showCount: true,
    });
  }
  if (clamav !== null) {
    ITEMS.push({
      header: "AV",
      value: clamav === "Detected" || clamav === "Unknown" ? clamav : false,
      showCount: false,
    });
  }
  if (cdecision !== null) {
    ITEMS.push({
      header: "Cloud",
      value: cdecision === "Detected" || cdecision === "Unknown" ? cdecision : false,
      showCount: false,
    });
  }
  if (mlcheck !== null) {
    ITEMS.push({
      header: "ML Checker",
      value: mlcheck === "Detected" || mlcheck === "Unknown" ? mlcheck : false,
      showCount: false,
    });
  }
  if (networkTrafficCount !== null) {
    ITEMS.push({
      header: "Network Traffic",
      value:
        networkTrafficCount === "Detected" || networkTrafficCount === "Unknown"
          ? networkTrafficCount
          : false,
      showCount: false,
    });
  }

  const setStaticResultsActiveHandler = (item) => {
    if (
      setStaticResultsActive &&
      (item.header === "YARA" ||
        item.header === "AV" ||
        item.header === "Cloud" ||
        item.header === "ML Checker")
    ) {
      setStaticResultsActive(true);
    } else if (setStaticResultsActive && item.header === "Network Traffic") {
      const networkActivityElement = document.getElementById("network-activity-map-scroll-element");
      networkActivityElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={`clamcloudml__display ${report ? "clamcloudml__display--report" : ""}`}>
      {vt !== null && (
        <a
          href={vt && vt.link}
          target="_blank"
          rel="noreferrer"
          className={`clamcloudml__vt-${
            vt ? "cursor" : "default"
          } clamcloudml__border clamcloudml__border--vt`}>
          {vt && (
            <span className="clamcloudml__text">
              {"VT"} :{vt.score}
            </span>
          )}
        </a>
      )}

      {ITEMS.map((item, index) => (
        <div
          key={item.header}
          className={`clamcloudml__border clamcloudml__border--${
            item.value === "Unknown" && !showNA
              ? "hidden"
              : item.value === "Unknown"
              ? "na"
              : item.value
              ? "detected"
              : "undetected"
          } ${setStaticResultsActive ? "clamcloudml__border--pointer" : ""} ${
            report && index > 4 ? "clamcloudml__border--below" : ""
          }`}
          onClick={() => setStaticResultsActiveHandler(item)}>
          <span className="clamcloudml__text">
            {item.header} : {detectedOrUndetected(item.value, item.showCount)}
          </span>
        </div>
      ))}
    </div>
  );
};
export default ClamCloudInfo;
