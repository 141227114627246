import * as React from "react"

const Msi = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M539.573 49.201h-178.2c-.791 0-1.561.074-2.341.124V0L11.228 46.419v494.562L359.032 588.6v-50.814c.78.053 1.55.115 2.341.115h178.2c20.841 0 37.8-16.959 37.8-37.8v-413.1c0-20.84-16.959-37.8-37.8-37.8zm-86.094 142.494L427.56 181.9l6.043-12.506 27.185 9.229-7.309 13.072zm.295 5.909 61.072 22.971-110.056 32.292-45.758-17.255v-12.25l94.742-25.758zm-5.842-1.351-88.899 22.956v-17.125c26.104-6.297 52.908-12.862 62.659-15.167l26.24 9.336zm-307.144 150.38-1.556-42.293c-.469-13.263-.934-29.286-.934-45.312l-.464.005c-3.262 14.083-7.56 29.737-11.546 42.544l-12.393 42.071-17.571-.77-10.404-41.46c-3.148-12.417-6.415-27.428-8.686-41.128l-.285.005c-.562 14.196-.989 30.412-1.69 43.496l-1.69 39.804-19.517-.881 5.888-103.457 28.566-1.081 9.569 35.316c3.071 12.366 6.165 25.753 8.379 38.383l.448.011c2.813-12.436 6.249-26.668 9.547-38.739l10.908-36.444 31.132-1.176 5.951 112.16-23.652-1.054zm70.807 4.915c-13.189-.611-25.975-4.599-32.276-8.411l5.134-21.099c6.858 3.738 17.519 7.636 28.698 7.983 12.239.38 18.784-4.492 18.784-12.191 0-7.352-5.675-11.639-19.828-16.844-19.122-6.834-31.309-17.357-31.309-33.897 0-19.406 16.071-34.895 43.529-35.968 13.511-.535 23.641 1.912 30.953 4.838l-6.215 22.003c-4.904-2.191-13.55-5.345-25.262-5.065-11.518.277-17.036 5.574-17.036 11.589 0 7.388 6.561 10.607 21.811 16.239 21.4 7.71 31.691 18.806 31.691 35.754 0 20.188-15.929 36.588-48.674 35.069zm97.698 2.69-29.682-1.339V230.942l29.682-1.128v124.424zm246.48 145.863c0 8.934-7.267 16.2-16.2 16.2h-178.2c-.801 0-1.571-.116-2.341-.232V414.355l56.215-14.518c-11.085-12.856-17.84-29.542-17.84-47.804 0-38.586 30.011-70.239 67.922-73.021v-35.487l61.557-22.642-71.519-27.034 9.355-16.751-32.517-11.032-8.142 16.854c-1.757.38-4.324 1.016-4.578 1.065-18.282 4.33-40.257 9.487-60.465 14.17V71.043c.775-.113 1.54-.242 2.347-.242h178.2c8.928 0 16.2 7.267 16.2 16.2v413.1h.006z" fill={color} />
    <path  d="M473.835 287.946c-36.767 0-66.577 29.795-66.577 66.545 0 36.772 29.811 66.583 66.577 66.583s66.562-29.811 66.562-66.583c-.001-36.75-29.795-66.545-66.562-66.545zm20.039 8.121-14.46 29.521c-2.811-1.081-5.579-.517-5.579-.517l-8.374-30.987c0-.001 11.101-4.978 28.413 1.983zm-20.05 84.628c-14.471 0-26.188-11.729-26.188-26.204 0-14.459 11.717-26.188 26.188-26.188s26.193 11.729 26.193 26.188c.001 14.475-11.722 26.204-26.193 26.204z" fill={color} />
    <path  d="M473.835 331.32c-12.783 0-23.172 10.404-23.172 23.182 0 12.772 10.389 23.172 23.172 23.172 12.772 0 23.171-10.399 23.171-23.172 0-12.778-10.399-23.182-23.171-23.182zm0 43.68c-11.307 0-20.503-9.187-20.503-20.498 0-11.316 9.196-20.503 20.503-20.503 11.301 0 20.492 9.187 20.492 20.503.006 11.311-9.191 20.498-20.492 20.498z" fill={color} />
    <path  d="M473.835 343.427c-6.117 0-11.085 4.968-11.085 11.074s4.968 11.085 11.085 11.085c6.106 0 11.079-4.979 11.079-11.085s-4.973-11.074-11.079-11.074zm0 19.475c-4.641 0-8.406-3.765-8.406-8.411 0-4.625 3.766-8.395 8.406-8.395 4.63 0 8.405 3.765 8.405 8.395 0 4.646-3.775 8.411-8.405 8.411z" fill={color} />
  </svg>
)

export default Msi
