import React from "react";

const ArrowTopIcon = ({ width = 5, height = 10, color = "var(--text-color-card)", margin = 0 }) => {
  return (
    <div style={{ cursor: "pointer", margin }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 14 8"
        fill="none">
        <path
          d="M-0.0012521 6.82968C-0.00176041 6.68028 0.0312119 6.53267 0.0952407 6.39769C0.159269 6.26271 0.252729 6.14379 0.368749 6.04968L6.36875 1.21968C6.54768 1.07259 6.77212 0.992188 7.00375 0.992188C7.23537 0.992188 7.45982 1.07259 7.63875 1.21968L13.6387 6.21968C13.843 6.38941 13.9714 6.63333 13.9958 6.89775C14.0201 7.16218 13.9385 7.42546 13.7687 7.62968C13.599 7.83389 13.3551 7.96232 13.0907 7.9867C12.8262 8.01108 12.563 7.92941 12.3587 7.75968L6.99875 3.28968L1.63875 7.60968C1.49196 7.73196 1.31322 7.80964 1.12367 7.83351C0.934125 7.85739 0.7417 7.82648 0.569173 7.74442C0.396646 7.66237 0.251234 7.53261 0.150144 7.3705C0.0490533 7.20839 -0.0034837 7.02071 -0.0012521 6.82968Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default ArrowTopIcon;
