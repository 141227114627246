import React from "react";
import { useSelector } from "react-redux";
import ClamCloudMlInfo from "./ClamCloudMlInfo";
import "./styles.scss";

const ClamCloudMl = ({ setStaticResultsActive, report = false, showNA = true }) => {
  const general = useSelector((state) => state.analysisReports.general);

  return (
    <ClamCloudMlInfo
      vt={general.vt_status}
      clamav={general.av_status}
      cdecision={general.cloud_status}
      mlcheck={general.mlchecker_status}
      yara={general.yara_status}
      networkTrafficCount={general.networktraffic_status}
      setStaticResultsActive={setStaticResultsActive}
      report={report}
      showNA={showNA}
    />
  );
};

export default ClamCloudMl;
