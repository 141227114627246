import React from "react";
import CardForm from "../../../Components/common/CardForm/CardForm";
import TextField from "../../../Components/common/TextField/TextField";
import { Link } from "react-router-dom";
import { forgotPasswordRequest } from "../../../store/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../../Components/UI/Backdrop/Backdrop";

const EmailSend = ({ email, setEmail, setTabs }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.users.loginLoading);

  const handleClick = () => {
    dispatch(forgotPasswordRequest({ email }));
    setTabs("check-email");
  };

  return (
    <CardForm title="Did you forget your password?" subTitle="Please contact our CyberFortress support team.">
      {
      /*<TextField
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        labelText="Email"
        name="email"
        required={true}
      />
      <button className="card__form-btn" onClick={() => handleClick()}>
        Reset Password
  </button>*/}
    
      <p style={{ textAlign: "center", marginTop:"100px" }}>
        Go back to <Link to="/login">Login</Link>
      </p>
      {loading && <Backdrop show={loading} />}
    </CardForm>
  );
};

export default EmailSend;
