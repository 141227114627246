import * as React from "react"

const Cpp = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M271.016 245.45c-6.144.137-10.281.807-12.445 1.421v37.66c2.565.567 5.7.778 10.054.789 16.261.058 26.448-7.836 26.448-21.228.005-12.016-8.786-18.988-24.057-18.642zM163.255 247.833c-5.332.118-8.923.738-10.805 1.305v35.084c2.228.536 4.962.726 8.735.741 14.093.042 22.902-7.307 22.902-19.754.006-11.171-7.582-17.668-20.832-17.376z" fill={color} />
    <path  d="M359.031 537.786c.781.053 1.551.115 2.342.115h178.2c20.851 0 37.8-16.964 37.8-37.8V86.999c0-20.838-16.949-37.8-37.8-37.8h-178.2c-.786 0-1.561.076-2.342.124V0L11.228 46.417v494.564L359.031 588.6v-50.814zm0-466.744c.771-.114 1.54-.243 2.342-.243h178.2c8.933 0 16.2 7.27 16.2 16.2v413.103c0 8.928-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.116-2.342-.232V71.042zM87.265 324.981c7.167.232 15.227-1.208 19.992-3.111l3.688 20.503c-4.482 2.204-14.462 4.282-27.25 3.676-35.04-1.672-52.423-25.94-52.423-56.972 0-37.188 23.852-58.767 54.752-59.896 12.355-.454 21.869 1.933 26.201 4.168l-4.962 20.849c-4.92-2.138-11.712-4.01-20.147-3.833-18.528.396-32.574 12.695-32.574 37.367-.012 22.209 11.937 36.574 32.723 37.249zm112.817-31.509c-9.373 8.802-23.042 12.535-38.717 12.261-3.441-.053-6.513-.285-8.91-.686v42.515l-25.241-1.171V231.125c7.765-1.653 18.818-3.104 34.657-3.684 16.395-.588 28.303 2.23 36.408 8.521 7.818 5.967 13.155 16.126 13.155 28.237 0 12.113-4.066 22.394-11.352 29.273zm113.477 1.018c-10.837 9.439-26.644 13.447-44.719 13.131-3.963-.073-7.507-.316-10.265-.738v45.604l-29.025-1.35V227.61c8.92-1.799 21.621-3.386 39.894-4.05 18.889-.686 32.656 2.328 42.021 9.065 9.07 6.413 15.235 17.323 15.235 30.364 0 13.036-4.678 24.097-13.141 31.501z" fill={color} />
    <path  d="M466.726 331.542c17.286 0 32.495-2.985 40.532-6.561l-5.358-33.392c-6.855 2.689-15.198 4.472-25.935 4.472-22.064 0-39.951-14.009-39.951-40.843-.296-23.856 15.208-40.845 39.055-40.845 11.929 0 19.976 2.091 25.339 4.472l7.15-33.685c-9.539-3.586-22.649-5.666-34.577-5.666-54.259 0-83.479 34.879-83.479 77.512 0 45.911 30.111 74.536 77.224 74.536zM409.973 428.029h12.303v-27.616h26.245V388.6h-26.245v-27.369h-12.303V388.6h-26.251v11.813h26.251zM497.133 361.231h-12.304V388.6h-26.245v11.813h26.245v27.616h12.304v-27.616h26.251V388.6h-26.251z" fill={color} />
  </svg>
)

export default Cpp
