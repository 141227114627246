import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import CustomTable from "../../../../../Components/common/Table/CustomTable";
import { snortsuricata } from "../../../../../utils/constants/tableHeaders";
import worldMill from "../../../../../Components/common/Map/worldMill.json";

import "./styles.scss";

const SnortSuricata = ({ data }) => {
  const [alerts, setAlerts] = useState([]);
  const snortsuricataRef = useRef(null);

  const snortSuricataAlerts = useSelector((state) => state.analysisReports.snortSuricataAlerts);

  useEffect(() => {
    if (snortSuricataAlerts) {
      const mappedAlerts = snortSuricataAlerts.map((data, i) => ({
        id: i,
        srcIp: data.srcip ? data.srcip + ":" + data.srcport : data.src_ip + ":" + data.src_port,
        destIp: data.dstip ? data.dstip + ":" + data.dstport : data.dst_ip + ":" + data.dst_port,
        signature: data.signature
          ? data.signature.replace("ET", "")
          : data.message.replace("503", ""),
        protocol: data.protocol,
        country_code: data.country_code || "-",
      }));
      setAlerts(mappedAlerts);
    }
  }, [snortSuricataAlerts]);

  if (alerts.length === 0) {
    return (
      <div className="snort-suricata">
        <div
          className="map-footer"
          style={{
            background: "var(--card-background-color)",
            height: "100%",
          }}>
          <div className="snort-suricata__no-data__header">
            <p className="snort-suricata__no-data__item">Source IP </p>
            <p className="snort-suricata__no-data__item">Destination IP</p>
            <p className="snort-suricata__no-data__item">Type</p>
            <p className="snort-suricata__no-data__item">CN</p>
            <p className="snort-suricata__no-data__item">Signature/Messages</p>
          </div>
          <div
            className="map-footer__header-item"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // fontFamily: "Nunito Sans",
              fontSize: "14px",
              fontWeight: "600",
              color: "#grey--text",
              textAlign: "center",
              textTransform: "uppercase",
              height: "100%",
              width: "100%",
            }}>
            No Data
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="snort-suricata">
      <CustomTable
        dataSnortSuricata={alerts}
        tableName={"snortSuricata"}
        column={snortsuricata}
        exportFile={true}
        paginationButtons={true}
        tableRef={snortsuricataRef}
        customPageSize={6}
        minHeight={418}
        borderRadius={4}
      />
    </div>
  );
};

export default SnortSuricata;
