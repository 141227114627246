import React from "react";

const Info = ({ md5, sha1, vhash, ssdeep, file_type, magic, interestingStrings = [] }) => {
  // Eliminate duplications
  const uniqueInterestingStrings = interestingStrings.filter(
    (filterData, index, arr) =>
      arr.findIndex((data) => filterData.string === data.string) === index,
  );

  return (
    <div className="files-info__block">
      <p>
        MD5: <span className="analysis-reports__body-block__information-value">{md5}</span>
      </p>
      <p>
        SHA-1: <span className="analysis-reports__body-block__information-value">{sha1}</span>
      </p>
      <p>
        Vhash: <span className="analysis-reports__body-block__information-value">{vhash}</span>
      </p>
      <p>
        SSDEEP: <span className="analysis-reports__body-block__information-value">{ssdeep}</span>
      </p>
      <p>
        Type: <span className="analysis-reports__body-block__information-value">{file_type}</span>
      </p>
      <p>
        Magic: <span className="analysis-reports__body-block__information-value">{magic}</span>
      </p>
      {
        <>
          <p>Interesting Strings:</p>
          <div className="analysis-reports__body-block__strings-container">
            {uniqueInterestingStrings.map((interestingString, i) => (
              <p key={i} className="analysis-reports__body-block__string">
                <span style={{ color: "var(--text-color-card)" }}>{i + 1}</span> - {interestingString.string}
              </p>
            ))}
          </div>
        </>
      }
    </div>
  );
};

export default Info;
