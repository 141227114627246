import React from "react";

function ComputerIcon({ color = "var(--border)", width = 24, height = 24 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 -2 64 64">
      <g fill={color} fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill={color}
          d="M44 60h-2.459c-1.466 0-2.625-1.596-2.959-2.507-.182-.497-.354-1.647-.463-2.493H25.881c-.109.846-.281 1.996-.463 2.493-.334.911-1.493 2.507-2.959 2.507H20a1 1 0 000 2h24a1 1 0 000-2zm18-16a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2h56a2 2 0 012 2v38zm-30 7a1 1 0 110-2 1 1 0 010 2zM60 2H4a4 4 0 00-4 4v44a4 4 0 004 4h56a4 4 0 004-4V6a4 4 0 00-4-4zm-49 8H3v1h8v3H3v1h8v3H3v1h8v3H3v1h8v3H3v1h8v3H3v1h8v3H3v1h8v3H3v1h8v3H3v1h8v2h1V11h49v-1H11zm35.5 9h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm14 4h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-7 0h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-7 0h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM40 25.5v-2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5zm.5 13.5h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-7 0h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-7 0h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM33 25.5v-2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5zm-7 0v-2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5zM18.5 23h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm16-1h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5zm-7.5-.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v2zM60.5 31h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-7 0h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-7 0h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-19-13h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5zm-7 0h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5zm-2-3h-5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z"
          transform="translate(0 -2)"></path>
      </g>
    </svg>
  );
}

export default ComputerIcon;
