import React from "react";

const SocialIcon = ({ color = "var(--text-color-card)", width = 15, height = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <path d="M8.5 10.35c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5zm3.5-1.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm5 0c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm7 1.47c0 5.36-5.38 9.71-12 9.71-1.06 0-2.11-.11-3.12-.33l-6.59 3.69 1.84-5.73C1.5 15.82 0 13.18 0 10.32 0 4.96 5.38.61 12 .61s12 4.35 12 9.71zm-2 0c0-4.25-4.49-7.71-10-7.71S2 6.07 2 10.32c0 2.38 1.4 4.59 3.84 6.07l.67.41-.76 2.37 2.83-1.59.38.09c.98.24 2 .36 3.03.36 5.52 0 10.01-3.46 10.01-7.71z"></path>
    </svg>
  );
};

export default SocialIcon;
