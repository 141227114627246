import React from "react";
import UserIcon from "../../../../Icon-SVG/UserIcon";
const User = (props) => {
  const user = props.row.original.user || props.row.original.log.username;
  return (
    <div className="dashboard__table__user">
      <span className="dashboard__table__user--icon">
        <UserIcon width={13} height={13} color={"var(--text-color-card)"} />
      </span>
      <span>{user}</span>
    </div>
  );
};

export default User;
