import * as React from "react";

const InternetIcon = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57.949 57.949"
    xmlSpace="preserve"
    width={width}
    height={height}
    fill={color}>
    <path
      d="M29.975 27.985h11.972a34.935 34.935 0 0 0-2.664-12.477 36.873 36.873 0 0 1-9.308 1.44v11.037zM40.384 13.057a34.821 34.821 0 0 0 8.328-4.169l.653-.5C44.588 3.658 38.18.58 31.071.069l-.518.421c4.214 3.489 7.546 7.778 9.831 12.567zM29.975 2.628v12.32a34.975 34.975 0 0 0 8.473-1.283 34.976 34.976 0 0 0-8.473-11.037zM41.531 42.202a36.863 36.863 0 0 1 8.885 4.639l.868.65a28.858 28.858 0 0 0 6.665-17.505H43.947a36.93 36.93 0 0 1-2.416 12.216zM9.237 8.888a34.877 34.877 0 0 0 8.328 4.169C19.85 8.269 23.182 3.98 27.397.49l-.518-.421c-7.125.512-13.547 3.603-18.328 8.35l.686.469zM27.975 2.628a34.98 34.98 0 0 0-8.473 11.036 34.926 34.926 0 0 0 8.473 1.283V2.628zM14.003 29.985H0A28.866 28.866 0 0 0 6.665 47.49l.85-.635a36.994 36.994 0 0 1 8.906-4.652 36.889 36.889 0 0 1-2.418-12.218zM16.752 14.89a36.864 36.864 0 0 1-8.644-4.351l-.938-.642A28.87 28.87 0 0 0 0 27.985h14.003a36.926 36.926 0 0 1 2.749-13.095zM16.003 27.985h11.972V16.948a36.94 36.94 0 0 1-9.309-1.438 34.897 34.897 0 0 0-2.663 12.475zM27.297 57.391A37.098 37.098 0 0 1 17.186 44.05a34.928 34.928 0 0 0-8.492 4.42l-.689.515c4.774 5.003 11.31 8.304 18.605 8.896l.687-.49zM27.975 40.023V29.985H16.003a34.912 34.912 0 0 0 2.313 11.58 36.828 36.828 0 0 1 9.659-1.542zM49.236 48.456a34.782 34.782 0 0 0-8.469-4.406 36.969 36.969 0 0 1-10.113 13.344l.685.487c7.295-.592 13.832-3.892 18.606-8.895l-.709-.53zM49.885 10.507a37.079 37.079 0 0 1-8.689 4.38 36.944 36.944 0 0 1 2.751 13.098H57.95a28.87 28.87 0 0 0-7.209-18.133l-.856.655zM27.975 55.342V42.023a34.844 34.844 0 0 0-8.882 1.4 34.94 34.94 0 0 0 8.882 11.919zM41.947 29.985H29.975v10.038a36.85 36.85 0 0 1 9.662 1.542 35.025 35.025 0 0 0 2.31-11.58zM38.86 43.423a34.904 34.904 0 0 0-8.885-1.4v13.323a34.83 34.83 0 0 0 8.885-11.923z"
      stroke={color}
    />
  </svg>
);

export default InternetIcon;
