import React, { useState } from "react";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import CustomTable from "../Table/CustomTable";
import { listConfigHeader, listConfigHeaderCstrike } from "../../../utils/constants/tableHeaders";
import AddIcon from "../../Icon-SVG/AddIcon";
import { useHistory } from "react-router-dom";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const IntegrationConfigList = ({ show, setShow, activeInt, setActiveInt }) => {
  const history = useHistory();
  let query = useQuery();
  const [hoverAdd, setHoverAdd] = useState(false);

  const closePopupHandler = () => {
    setShow(false);
    setActiveInt("");
    if (query.get("bck") !== null) {
      setActiveInt("");
      history.push("/integration-market");
    }
  };

  const closeListHandler = () => {
    setShow(false);
    setActiveInt("");
  };

  return (
    <div className={`${show && "integration-config-backdrop"}`} onClick={closePopupHandler}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {show ? (
          <div className={"integration-config border"}>
            <div className="integration-config-buttons">
              <div className="integration-config-close-button__outer">
                <div onClick={closePopupHandler} className="integration-config-close-button__inner">
                  <label className="integration-config-close-button__close">Close</label>
                </div>
              </div>
            </div>
            <table
              style={{
                width: "90%",
                borderBottom: "1px solid white",
                color: "white",
              }}>
              <tbody>
                {activeInt === "cstrike" ? (
                  <tr style={{ width: "90%" }}>
                    <td style={{ width: "5%" }}></td>
                    <td style={{ width: "35%" }}>Client Id</td>
                    <td style={{ width: "50%" }}>Secret</td>
                  </tr>
                ) : (
                  <tr style={{ width: "90%" }}>
                    <td style={{ width: "10%" }}>{activeInt === "rclshare" ? "Type" : "User"}</td>
                    <td style={{ width: "20%" }}>
                      {activeInt === "rclshare" ? "Server" : "Server"}
                    </td>
                    <td style={{ width: "40%" }}>
                      {activeInt === "rclshare" ? "Authority" : "Path"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className={"integration-configTable"} onClick={closeListHandler}>
              <CustomTable
                column={activeInt === "cstrike" ? listConfigHeaderCstrike : listConfigHeader}
                tableName={"listConfigPopup"}
                intName={activeInt}
                leftAction={false}
                tableFolder={"listPopup"}
                isSetUrl
                paginationButtons={false}
                className=""
                refresh={false}
                customPageSize={200}
                minHeight={50}
              />
            </div>
            <button
              title="Add"
              onMouseEnter={() => setHoverAdd(true)}
              onMouseLeave={() => setHoverAdd(false)}
              onClick={() => (
                history.push(`integration-market?conf=${activeInt}&t=add`), closeListHandler()
              )}
              className="integration-config__addicon">
              <AddIcon
                width={23}
                height={23}
                color={hoverAdd ? "var(--header-search-text-color)" : "var(--color-blue)"}
                backcolor="transparent"
              />
              <span style={{ color: hoverAdd ? "var(--header-search-text-color)" : "var(--color-blue)" }}>Add Config</span>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default IntegrationConfigList;
