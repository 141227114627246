import React, { useEffect, useRef, useState } from "react";
import ArrowBottomIcon from "../../Icon-SVG/ArrowBottomIcon";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import { useOnClickOutside } from "../../../utils/hook/useOnClickOutside";
import "./styles.scss";

const CustomSelect = ({
  data,
  width = "80px",
  height = "40px",
  color = "var(--dashboard-value-color)",
  setSelected,
  select,
  placeholder,
  disabled = false,
  defaultIndex = 0,
  onSelectItem = null,
}) => {
  const [showItems, setShowItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(select);

  const ref = useRef();

  useOnClickOutside(ref, () => setShowItems(false));

  const dropDown = () => {
    setShowItems(!showItems);
  };

  useEffect(() => {
    setSelectedItem(select);
  }, [select]);

  useEffect(() => {
    if (!select && !disabled) {
      setSelected(data[defaultIndex]);
      setSelectedItem(data[defaultIndex]);
    }
  }, [setSelected, setSelectedItem, disabled]);

  const selectItem = (item) => {
    if (onSelectItem) {
      onSelectItem(item);
    }
    setSelectedItem(item);
    setShowItems(false);
    setSelected(item);
  };

  return (
    <div className="select-box--box" style={{ width }} ref={ref}>
      <div className="select-box--container" onClick={dropDown} style={{ width, color, height }}>
        <div className="select-box--selected-item">
          {selectedItem?.value ? (
            selectedItem.value
          ) : (
            <span className="placeholder">{placeholder && placeholder}</span>
          )}
        </div>
        <div className="select-box--arrow">
          <ArrowBottomIcon width={14} height={14} />
        </div>
      </div>

      <div
        className="select-box--options"
        style={{
          display: showItems ? "block" : "none",
          width,
        }}>
        {/* <CustomScrollbar maxHeight={100} className="border"> */}
        {data.map((item) => (
          <div
            key={item.id}
            onClick={() => selectItem(item)}
            className={`select ${selectedItem === item ? "selected" : ""}`}>
            {item.value}
          </div>
        ))}
        {/* </CustomScrollbar> */}
      </div>
    </div>
  );
};

export default CustomSelect;
