import * as React from "react"

const Zip = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M359.031 537.78c.781.042 1.551.111 2.342.111h178.2c20.846 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.954-37.8-37.8-37.8h-178.2c-.786 0-1.561.077-2.342.113V0L11.228 46.417v494.564L359.031 588.6v-50.82zm0-466.744c.771-.113 1.54-.237 2.342-.237h178.2c8.933 0 16.2 7.27 16.2 16.2v413.103c0 8.928-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.127-2.342-.242V71.036zM145.257 348.828l-82.927-2.115V333.23l50.055-76.773v-.681l-45.467.751v-20.764l77.527-2.012v15.021L94.031 325.83v.665l51.226.807v21.526zm41.713 1.065-25.784-.66V233.318l25.784-.67v117.245zm98.574-53.568c-9.608 8.839-23.712 12.709-39.962 12.594-3.573-.021-6.803-.228-9.292-.602v42.831l-26.568-.675V233.634c8.208-1.619 19.818-2.979 36.395-3.417 16.999-.448 29.268 2.483 37.568 8.801 8.003 6.012 13.424 16.116 13.424 28.15-.001 12.04-4.109 22.254-11.565 29.157z" fill={color} />
    <path  d="M247.554 250.715c-5.545.098-9.305.696-11.264 1.266v35.363c2.31.53 5.168.707 9.115.696 14.628-.021 23.739-7.425 23.739-19.865 0-11.169-7.853-17.703-21.59-17.46zM424.043 80.038h42.366v17.474h-42.366zM381.676 106.197h42.367v17.484h-42.367zM424.043 134.815h42.366v17.487h-42.366zM424.043 190.057h42.366v17.479h-42.366zM381.676 161.99h42.367v17.479h-42.367zM423.626 378.707c23.172 0 41.939-18.784 41.939-41.966l-7.736-70.284c0-23.172-11.031-41.958-34.203-41.958s-34.193 18.786-34.193 41.958l-7.757 70.284c0 23.172 18.778 41.966 41.95 41.966zm-13.874-77.114h27.774v58.672h-27.774v-58.672z" fill={color} />
  </svg>
)

export default Zip
