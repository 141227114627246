import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestAgentStatus } from "../../../store/actions/integrationsActions";
import { useLocation } from "react-router-dom";
import Card from "../../../Components/PagesComponents/AgentStatus/Card/Card";
import CustomSelect from "../../../Components/common/CustomSelect/CustomSelect";

import ComputerIcon from "../../../Components/Icon-SVG/ComputerIcon";
import WindowsIcon from "../../../Components/Icon-SVG/WindowsIcon";
import SearchIcon from "../../../Components/Icon-SVG/SearchIcon";
import SortIcon from "../../../Components/Icon-SVG/SortIcon";

import Modal from "../../../Components/PagesComponents/AgentStatus/Modal/Modal";
import ListPopup from "../../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";

import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const filteringHeader = [
  {
    id: "none",
    value: "None",
  },
  {
    id: "hostname",
    value: "Host Name",
  },
  {
    id: "username",
    value: "User Name",
  },
  {
    id: "macAddress",
    value: "Mac Address",
  },
  {
    id: "cpuID",
    value: "CPU ID",
  },
];

const AgentStatus = () => {
  const dispatch = useDispatch();
  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();
  const [listPopupActive, setListPopupActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [sortField, setSortField] = useState(filteringHeader[0]);
  const agentData = useSelector((state) => state.integrations.agentStatus);

  useEffect(() => {
    dispatch(RequestAgentStatus());
  }, [dispatch]);

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }

    if (query.get("host") !== null) {
      setSelectedAgent(query.get("host"));
      setModalActive(true);
    }
  }, [query]);

  const sortedAgentData = [...agentData].sort((a, b) => {
    if (sortField.id && a[sortField.id] && b[sortField.id]) {
      return a[sortField.id].localeCompare(b[sortField.id]);
    }
    return 0;
  });

  const filteredAgentData = sortedAgentData?.filter((agent) => {
    const searchFields = [agent.hostname, agent.username, agent.macAddress, agent.cpuID];
    return searchFields.some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()));
  });

  const handleCardClick = (agent) => {
    setSelectedAgent(agent.hostname);
    setModalActive(true);
  };

  return (
    <div>
      <div className="agent">
        <div className="agent__header">
          <h1>Agent Status</h1>
          <p>You can monitor the agent information connected to the current server</p>
          <div className="search-sort-wrapper">
            <div className="search-wrapper">
              <div className="search-wrapper__item border">
                <SearchIcon width={18} height={18} color="white" />
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="sort-dropdown">
              <div className="sort-dropdown__item">
                <CustomSelect
                  width="178px"
                  data={filteringHeader}
                  select={sortField}
                  setSelected={setSortField}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="agent__body">
          {filteredAgentData?.map(
            (agent, index) =>
              agent?.pcStatus && (
                <Card
                  key={`${agent?.username}-${index}`}
                  hostName={agent?.hostname}
                  userName={agent?.username}
                  macAdress={agent?.macAddress}
                  cpuId={agent?.cpuID}
                  status={agent?.pcStatus ? "Online" : "Offline"}
                  updateTime={agent?.updateTime}
                  icon={<ComputerIcon width={20} height={20} color="var(--color-blue)" />}
                  icon1={<WindowsIcon width={20} height={20} />}
                  onClick={() => handleCardClick(agent)}
                />
              ),
          )}
        </div>
        {modalActive && selectedAgent && (
          <Modal setModalActive={setModalActive} selectedAgent={selectedAgent} />
        )}
      </div>
      <ListPopup
        onClickOk={() => setListPopupActive(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="agent"
        agentHostname={selectedAgent}
      />
    </div>
  );
};

export default AgentStatus;
