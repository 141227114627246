import BankerIcon from "../../../Icon-SVG/AnalysisIcons/BankerIcon";
import BotIcon from "../../../Icon-SVG/AnalysisIcons/BotIcon";
import CredentialsIcon from "../../../Icon-SVG/AnalysisIcons/CredentialsIcon";
import DiscoveryIcon from "../../../Icon-SVG/AnalysisIcons/DiscoveryIcon";
import EncryptionIcon from "../../../Icon-SVG/AnalysisIcons/EncryptionIcon";
import ExecutionIcon from "../../../Icon-SVG/AnalysisIcons/ExecutionIcon";
import ExploitIcon from "../../../Icon-SVG/AnalysisIcons/ExploitIcon";
import GenericIcon from "../../../Icon-SVG/AnalysisIcons/GenericIcon";
import InfoStealerIcon from "../../../Icon-SVG/AnalysisIcons/InfoStealerIcon";
import InjectionIcon from "../../../Icon-SVG/AnalysisIcons/InjectionIcon";
import NetworkIcon from "../../../Icon-SVG/AnalysisIcons/NetworkIcon";
import OfficeIcon from "../../../Icon-SVG/AnalysisIcons/OfficeIcon";
import OtherIcon from "../../../Icon-SVG/AnalysisIcons/OtherIcon";
import RansomwareIcon from "../../../Icon-SVG/AnalysisIcons/RansomwareIcon";
import SpreadingIcon from "../../../Icon-SVG/AnalysisIcons/SpreadingIcon";
import StaticIcon from "../../../Icon-SVG/AnalysisIcons/StaticIcon";
import StealthIcon from "../../../Icon-SVG/AnalysisIcons/StealthIcon";
import TrojanIcon from "../../../Icon-SVG/AnalysisIcons/TrojanIcon";

const ICON_WIDTH = 24;
const ICON_HEIGHT = 24;
const ICON_CONTAINER_STYLE = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: 4,
};

export const flattenSubSection = (subSection, finalList = []) => {
  if (subSection)
    return subSection.reduce((acc, item) => {
      acc.push({
        header: item.header,
        exp: item.exp,
        flexDirection: item.flexDirection,
        headerFontStyle: item.headerFontStyle ? item.headerFontStyle : "normal",
        expFontSize: item.expFontSize ? item.expFontSize : "14px",
        expFontStyle: item.expFontStyle ? item.expFontStyle : "normal",
        expFontWeight: item.expFontWeight ? item.expFontWeight : "400",
        expMargin: item.expMargin ? item.expMargin : "0",
        sectionMargin: item.sectionMargin ? item.sectionMargin : "3px 0",
      });
      if (item.subSections && item.subSections.length > 0) {
        return flattenSubSection(item.subSections, acc);
      }
      return acc;
    }, finalList);
  else return [];
};

export const INFORMATION_POPUP_DATA = {
  dashboard: {
    fileAnalysis: {
      subSections: [
        {
          header: "Quick:",
          exp: "A basic level analysis with limited depth. The settings are calibrated for a quick analysis.",
          flexDirection: "row",
          sectionMargin: "30px 0 3px",
        },
        {
          header: "Advanced:",
          exp: "A detailed settings configuration for the analysis.",
          flexDirection: "row",
          sectionMargin: "3px 0 20px",
        },
      ],
    },
    scriptAnalysis: {},
    submitPopup: {
      header: "",
      exp: "",
      flexDirection: "column",
      subSections: [
        {
          header: "Selected VMS:",
          exp: "The Operating System of the virtual machine in which the file is analysed.",
          flexDirection: "row",
        },
        {
          header: "Type:",
          exp: "This relates to the depth of analysis. Full Triage is the most extensive analysis type. Consequently, it is more time consuming in comparison to Basic Evasion. Basic Evasion is faster but only analyses at a limited depth. High Evasion is a special type of analysis at kernel level.",
          flexDirection: "row",
        },
        {
          header: "Command Line:",
          exp: "User may also set the function names and parameters when submitting DLL files.",
          flexDirection: "row",
        },
        {
          header: "Environmental Variable:",
          exp: "These are dynamic-named values that can affect the way running processes will behave on a computer. The user may set these variables for the VMs for the analysis to take place.",
          flexDirection: "row",
        },
        {
          header: "Related Passwords:",
          exp: "If the submitted file/s require a password (e.g., password protected Zip folder), a password should be entered here.",
          flexDirection: "row",
        },
        {
          header: "Runtime Duration:",
          exp: "The duration measured in seconds the runtime will run for.",
          flexDirection: "row",
        },
        {
          header: "Action Script:",
          exp: "The user may select a particular action script for a specific runtime to run the analysis on.",
          flexDirection: "row",
        },
        {
          header: "Enforce Runtime:",
          exp: "This will force the VM to finish the analysis in the Runtime Duration set above.",
          flexDirection: "row",
        },
        {
          header: "Network Traffic:",
          exp: "The network traffic options of the VM.",
          flexDirection: "row",
        },
        {
          header: "Force Submit:",
          exp: "Our software skips the analysis if there is an identical file already submitted. Choose this option if you would like to re-analyse the same file.",
          flexDirection: "row",
        },
      ],
    },
  },
  analysisReports: {
    decisionScore: {
      header: "Decision Score",
      exp: "This score indicates the confidence in our verdict. A higher number means there are more evidence supporting final decision; 'Malicious' or 'Not Suspicious'. Please note in case this score falls below 70% the system categories the file as 'Suspicious' and no score is shown.",
    },
    generalInformation: {
      header: "General Information",
      exp: "This section shows details of the file and the analysis.",
      flexDirection: "column",
      subSections: [
        {
          header: "Size:",
          exp: "The size of the file analysed.",
          flexDirection: "row",
        },
        {
          header: "Sha256:",
          exp: "The hash value of the file that is generated by the special hash function.",
          flexDirection: "row",
        },
        {
          header: "Analysis Started:",
          exp: "The date and time the analysis of the file started.",
          flexDirection: "row",
        },
        {
          header: "Report Date:",
          exp: "The date and time the report is generated.",
          flexDirection: "row",
        },
        {
          header: "Operating System:",
          exp: "The Operating System of the virtual machine in which the file is analysed.",
          flexDirection: "row",
        },
        {
          header: "Network Traffic:",
          exp: "The network traffic of the virtual machine in which the file is analysed.",
          flexDirection: "row",
        },
        {
          header: "Type:",
          exp: "The depth of the analysis.",
          flexDirection: "row",
        },
      ],
    },
    classification: {
      header: "Classification",
      exp: "Contemporary malware attacks often show more than one characteristic. The chart below assists in categorising behaviour the file has displayed during the analysis. Values within the inner yellow ring shows minimal behaviour while the outer red ring indicates significant activity within that category.",
      flexDirection: "column",
      subSections: [
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Banker">
              <BankerIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Banker:
            </div>
          ),
          exp: "Steals credentials from a financial institution's clients, or gains access to their financial information.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Bot">
              <BotIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Bot:
            </div>
          ),
          exp: "Infects your automated processes.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Credentials">
              <CredentialsIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Credentials:
            </div>
          ),
          exp: "Steals user credentials like username, email, names etc...",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Discovery">
              <DiscoveryIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Discovery:
            </div>
          ),
          exp: "Collects information about your system before the main attack.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Encryption">
              <EncryptionIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Encryption:
            </div>
          ),
          exp: "Encrypts some other parts of your system. Potential ransomware.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Execution">
              <ExecutionIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Execution:
            </div>
          ),
          exp: "Executes other programs.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Exploit">
              <ExploitIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Exploit:
            </div>
          ),
          exp: "Takes advantage of bugs or vulnerabilities, which cybercriminals use to gain illicit access to a system.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Generic">
              <GenericIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Generic:
            </div>
          ),
          exp: "Showing general malware characteristics but not in a specific category.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Infostealer">
              <InfoStealerIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Infostealer:
            </div>
          ),
          exp: "Gathers information from the infected system and potentially sends out.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Injection">
              <InjectionIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Injection:
            </div>
          ),
          exp: "Injects code into an application that potentially changes the way it executes.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Network">
              <NetworkIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Network:
            </div>
          ),
          exp: "Ability to spread from computer to computer in the same network.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Office">
              <OfficeIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Office:
            </div>
          ),
          exp: "Uses MS Office applications.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Other">
              <OtherIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Other:
            </div>
          ),
          exp: "Showing characteristics that are not covered in this section.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Ransomware">
              <RansomwareIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Ransomware:
            </div>
          ),
          exp: "Locks your computer and then demands a ransom for its release.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Spreading">
              <SpreadingIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Spreading:
            </div>
          ),
          exp: "Ability to spread from computer to computer if transferred. Similar to network.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Static">
              <StaticIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Static:
            </div>
          ),
          exp: "Makes static changes to your system.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Stealth">
              <StealthIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Stealth:
            </div>
          ),
          exp: "Conceals itself and any changes made by the malware to the infected system.",
          flexDirection: "row",
        },
        {
          header: (
            <div style={ICON_CONTAINER_STYLE} title="Trojan">
              <TrojanIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
              Trojan:
            </div>
          ),
          exp: "Misleads users of its true intent by disguising itself as a standard program.",
          flexDirection: "row",
        },
      ],
    },
    attackMap: {
      header: "Attack Map",
      exp: "The Attack Map displays the progression of a potential malware topographically. Each node is connected to its parent and icons at the bottom show the characteristics of the nodes.",
      flexDirection: "column",
      subSections: [
        {
          header: "",
          exp: "Please refer to the Radar Chart for the icon's descriptions.",
          expFontSize: "17px",
          expFontStyle: "italic",
          expFontWeight: "200",
          expMargin: "-10px 0 0 0",
          flexDirection: "column",
        },
      ],
    },
    signatureMatches: {
      header: "Signature Matches",
      exp: "Signatures represent suspicious patterns the file has shown during the analysis. The description column briefly explains the pattern; the severity column depicts the importance of that pattern. Furthermore, weight can be viewed as the frequency pattern generated by the file.",
      flexDirection: "column",
      subSections: [
        {
          header: "Ttps",
          exp: "The MITRE ATT&CK Framework is not a technology or software application, but rather a knowledge base and framework that describes these tactics, techniques, and procedures (TTPs) used by threat actors to carry out attacks.",
        },
      ],
    },
    mitre: {
      header: "Mitre Att&ck",
      exp: 'The MITRE ATT&CK matrix contains a set of techniques used by adversaries to accomplish a specific objective. Those objectives are categorized as tactics in the ATT&CK Matrix. The objectives are presented linearly from the point of reconnaissance to the final goal of exfiltration or "impact".',
      flexDirection: "column",
    },
    staticResults: {
      header: "Static Results",
      exp: "Static analysis, in the context of cybersecurity, refers to the process of examining software or code without executing it. It is a method used to identify potential security vulnerabilities and software defects by analysing the program's structure, syntax, and other characteristics.",
      flexDirection: "column",
      subSections: [
        {
          header: "",
          exp: "This table summarizes the results by categorising static analysis findings.",
          expFontSize: "17px",
          expMargin: "-10px 0 0 0",
          flexDirection: "column",
        },
      ],
    },
    fileDetails: {
      header: "File Details",
      exp: "This section shows additional document-specific information for some known file types, e.g., PDF, XLSX, DOCX, EXE, etc.",
      flexDirection: "column",
    },
    chatGPT: {
      header: "ChatGPT",
      exp: "ChatGPT is trained on a large amount of text data from the internet, including books, articles, and websites, allowing it to learn patterns and information about a wide range of topics. It uses this knowledge to generate responses to text prompts or questions provided by users. Our software, feeds ChatGPT’s engine with the findings from our simulation analysis and this section shows ChatGPT’s interpretation. Please note, while being a very powerful AI tool, ChatGPT response quality in cybersecurity still needs improvement. Therefore, please use this at your own discretion.",
      flexDirection: "column",
    },
  },
};

export const informationPopupContent = {
  fileAnalysis: INFORMATION_POPUP_DATA.dashboard.fileAnalysis,
  submitPopup: INFORMATION_POPUP_DATA.dashboard.submitPopup,
  decisionScore: INFORMATION_POPUP_DATA.analysisReports.decisionScore,
  generalInformation: INFORMATION_POPUP_DATA.analysisReports.generalInformation,
  classification: INFORMATION_POPUP_DATA.analysisReports.classification,
  attackMap: INFORMATION_POPUP_DATA.analysisReports.attackMap,
  signatureMatches: INFORMATION_POPUP_DATA.analysisReports.signatureMatches,
  mitre: INFORMATION_POPUP_DATA.analysisReports.mitre,
  staticResults: INFORMATION_POPUP_DATA.analysisReports.staticResults,
  fileDetails: INFORMATION_POPUP_DATA.analysisReports.fileDetails,
  chatGPT: INFORMATION_POPUP_DATA.analysisReports.chatGPT,
};
