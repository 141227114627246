import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import FileFormatIconSet from "../../../../common/FileFormatIconSet/FileFormatIconSet";

import { CopyClipboard } from "../../../../../store/actions/dashboardActions";

const File = (props) => {
  const dispatch = useDispatch();
  const file = props?.row?.original.file;
  const hashIcon = props.row.original.hashIcon;
  const hash = props.row.original.file.hash;
  const severity = props.row.original.severity;
  const id = props.row.original.id;
  const inttype = props.row.original.inttype;

  let freeRunID = "";
  if (id && typeof id === "string") {
    freeRunID = id?.split("win")[0].slice(-3);
  }

  const [copyHashHover, setCopyHashHover] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const copyHashHandler = (e) => {
    e.stopPropagation();
    dispatch(CopyClipboard(hash));
  };

  if (severity && severity === "loading" && !hashIcon?.fileType) {
    return (
      <div className="dashboard__table__filename-hash-running">
        <p className="running-filename">
          {file?.name === "freerun.txt"
            ? `Free Run ID ${freeRunID} (click remote connection icon)`
            : file?.name}
        </p>
        {file?.name !== "freerun.txt" ? (
          <p className="running-filehash">
            <span onClick={copyHashHandler}>&#128203;</span> {file?.hash}
          </p>
        ) : null}
      </div>
    );
  }

  return (
    <div className="dashboard__table__link" title={file?.name}>
      <div className="dashboard__table__first-row">
        {file.listType !== "listConfigPopup" && (
          <span
            title="Copy SHA256 Hash"
            onMouseOver={() => setCopyHashHover(true)}
            onMouseOut={() => setCopyHashHover(false)}
            onClick={copyHashHandler}
            className="dashboard__table__hash-copy">
            <FileFormatIconSet
              width={20}
              height={20}
              color={
                copyHashHover ? "var(--dashboard-table-row-hash-icon-hover)" : "var(--color-blue)"
              }
              extension={hashIcon?.fileType}
            />
            <span
              style={{
                color: copyHashHover
                  ? "var(--dashboard-table-row-hash-icon-hover)"
                  : "var(--color-blue)",
              }}>
              COPY
            </span>
          </span>
        )}
        <div className="dashboard__table__filename-hash">
          <p className="filename">
            {inttype === "cstrike"
              ? `${file.name?.substring(0, 15)}.........${file.name.substring(
                  file.name.length - 10,
                )}`
              : file?.name === "freerun.txt"
              ? `Free Run ID ${freeRunID}`
              : file?.name}
          </p>
          <p className="filehash">{file?.hash}</p>
        </div>
      </div>
      <p style={{ cursor: "pointer", color: "var(--color-blue)" }}>
        {windowSize.width > 1650
          ? file.type && file.type?.length > 65
            ? `${file.type?.substring(0, 55)}.........${file.type.substring(file.type.length - 10)}`
            : file.type
          : windowSize.width > 1340
          ? file.type && file.type?.length > 40
            ? `${file.type?.substring(0, 30)}.........${file.type.substring(file.type.length - 10)}`
            : file.type
          : file.type && file.type?.length > 30
          ? `${file.type?.substring(0, 15)}.........${file.type.substring(file.type.length - 10)}`
          : file.type}
      </p>
    </div>
  );
};

export default File;
