import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import CustomTable from "../../Components/common/Table/CustomTable";
import { StackedChart } from "../../Components/PagesComponents/Statistics/StackedChart/StackedChart";
import axiosApi from "../../utils/axios";
import { statisticsHeader } from "../../utils/constants/tableHeaders";
import CustomSelect from "../../Components/common/CustomSelect/CustomSelect";
import Warning from "../../Components/common/Warning/Warning";
import CustomMultiSelect from "../../Components/common/CustomMultiSelect/CustomMultiSelect";
import {
  calculateTotalFileCount,
  filterCustomTableData,
  filterListPopupData,
} from "../../Components/PagesComponents/Statistics/utils/statisticsUtil";

import "./styles.scss";
import StatisticsListPopup from "../../Components/PagesComponents/Statistics/StatisticsListPopup/StatisticsListPopup";

const FILTERING_TYPE = [
  {
    id: "daily",
    value: "Daily",
  },
  {
    id: "weekly",
    value: "Weekly",
  },
  {
    id: "monthly",
    value: "Monthly",
  },
];

const SUBMIT_TYPE = [
  {
    value: "UI",
    label: "UI",
    selected: true,
  },
  {
    value: "email",
    label: "Email",
    selected: true,
  },
  {
    value: "share",
    label: "File",
    selected: true,
  },
  {
    value: "icapweb",
    label: "Proxy",
    selected: true,
  },
];

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const flattenDataSet = (dataSet) => {
  return dataSet.reduce((acc, data, index) => {
    acc.push(...data.files);
    return acc;
  }, []);
};

const Statistics = () => {
  const currentUserRole = useSelector((state) => state?.users?.user?.role);

  const [dataSet, setDataSet] = useState([]);
  const [selectedDataFiles, setSelectedDataFiles] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteringType, setFilteringType] = useState(FILTERING_TYPE[0]);
  const [filteredSubmitTypes, setFilteredSubmitTypes] = useState(SUBMIT_TYPE);

  const [listPopupActive, setListPopupActive] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationParams, setNotificationParams] = useState({
    endDate: null,
    startDate: null,
    filteringType: "Daily",
  });

  const [errorShow, setErrorShow] = useState(false);
  const [errorText, setErrorText] = useState("");

  const customTableRef = useRef(null);

  let query = useQuery();

  const optionButtonClickHandler = async () => {
    try {
      if (!startDate || !endDate) {
        setErrorText("Please fill in all the fields");
        setErrorShow(true);

        return;
      }

      let result;

      const startDateYear = startDate.getFullYear();
      const startDateMonth =
        startDate.getMonth() < 9 ? `0${startDate.getMonth() + 1}` : startDate.getMonth() + 1;
      const startDateDay =
        startDate.getDate() < 10 ? `0${startDate.getDate()}` : startDate.getDate();

      const endDateYear = endDate.getFullYear();
      const endDateMonth =
        endDate.getMonth() < 9 ? `0${endDate.getMonth() + 1}` : endDate.getMonth() + 1;
      const endDateDay = endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();

      const submitTypesString = filteredSubmitTypes
        .filter((submitType) => submitType.selected)
        .map((submitType) => submitType.value)
        .join(",");

      if (currentUserRole === "ADMIN") {
        const usersString = filteredUsers
          .filter((user) => user.selected)
          .map((user) => user.value)
          .join(",");

        if (filteringType.id === "daily") {
          result = await axiosApi.get(
            `/api/reports/statistics/daily?StartDate=${startDateYear}-${startDateMonth}-${startDateDay}&EndDate=${endDateYear}-${endDateMonth}-${endDateDay}&Users=${usersString}&SubmitTypes=${submitTypesString}`,
          );
        } else if (filteringType.id === "weekly") {
          result = await axiosApi.get(
            `/api/reports/statistics/weekly?StartDate=${startDateYear}-${startDateMonth}-${startDateDay}&EndDate=${endDateYear}-${endDateMonth}-${endDateDay}&Users=${usersString}&SubmitTypes=${submitTypesString}`,
          );
        } else if (filteringType.id === "monthly") {
          result = await axiosApi.get(
            `/api/reports/statistics/monthly?StartDate=${startDateYear}-${startDateMonth}-${startDateDay}&EndDate=${endDateYear}-${endDateMonth}-${endDateDay}&Users=${usersString}&SubmitTypes=${submitTypesString}`,
          );
        }
      } else {
        if (filteringType.id === "daily") {
          result = await axiosApi.get(
            `/api/reports/statistics/daily?StartDate=${startDateYear}-${startDateMonth}-${startDateDay}&EndDate=${endDateYear}-${endDateMonth}-${endDateDay}&SubmitTypes=${submitTypesString}`,
          );
        } else if (filteringType.id === "weekly") {
          result = await axiosApi.get(
            `/api/reports/statistics/weekly?StartDate=${startDateYear}-${startDateMonth}-${startDateDay}&EndDate=${endDateYear}-${endDateMonth}-${endDateDay}&SubmitTypes=${submitTypesString}`,
          );
        } else if (filteringType.id === "monthly") {
          result = await axiosApi.get(
            `/api/reports/statistics/monthly?StartDate=${startDateYear}-${startDateMonth}-${startDateDay}&EndDate=${endDateYear}-${endDateMonth}-${endDateDay}&SubmitTypes=${submitTypesString}`,
          );
        }
      }

      setDataSet(result.data);
      setSelectedDataFiles([]);
      setShowNotification(true);
      setNotificationParams({
        endDate: endDate,
        startDate: startDate,
        filteringType: filteringType.value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getCurrentMonthsDailyData = async () => {
      try {
        const today = new Date();

        // Thirty days when today is included.
        const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 29));

        const currentYear = `${today.getFullYear()}`;
        const currentMonth =
          today.getMonth() < 9 ? `0${today.getMonth() + 1}` : `${today.getMonth() + 1}`;
        const currentDay = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;

        const priorYear = `${thirtyDaysAgo.getFullYear()}`;
        const priorMonth =
          thirtyDaysAgo.getMonth() < 9
            ? `0${thirtyDaysAgo.getMonth() + 1}`
            : `${thirtyDaysAgo.getMonth() + 1}`;
        const priorDay =
          thirtyDaysAgo.getDate() < 10
            ? `0${thirtyDaysAgo.getDate()}`
            : `${thirtyDaysAgo.getDate()}`;

        let result;

        const submitTypesString = filteredSubmitTypes
          .filter((submitType) => submitType.selected)
          .map((submitType) => submitType.value)
          .join(",");

        if (currentUserRole === "ADMIN") {
          let tempUsers = [];
          let usersString = "";
          const usersResponse = await axiosApi.get("/api/users/all");

          if (usersResponse.data && usersResponse.data.length > 0) {
            tempUsers = [...usersResponse.data];
            // Sort users alphabetically
            tempUsers.sort((a, b) => {
              const userA = a.username.toUpperCase();
              const userB = b.username.toUpperCase();

              return userA < userB ? -1 : userA > userB ? 1 : 0;
            });

            tempUsers = tempUsers.map((tempUser) => {
              const newTempUser = {
                value: tempUser.username,
                label: tempUser.username,
                selected: true,
              };

              return newTempUser;
            });

            usersString = tempUsers
              .filter((user) => user.selected)
              .map((user) => user.value)
              .join(",");
          }

          result = await axiosApi.get(
            `/api/reports/statistics/daily?StartDate=${priorYear}-${priorMonth}-${priorDay}&EndDate=${currentYear}-${currentMonth}-${currentDay}&Users=${usersString}&SubmitTypes=${submitTypesString}`,
          );

          setFilteredUsers(tempUsers);
        } else {
          result = await axiosApi.get(
            `/api/reports/statistics/daily?StartDate=${priorYear}-${priorMonth}-${priorDay}&EndDate=${currentYear}-${currentMonth}-${currentDay}&SubmitTypes=${submitTypesString}`,
          );
        }

        setDataSet(result.data);
        // console.log(result.data);
        setEndDate(today);
        setNotificationParams({
          endDate: today,
          startDate: thirtyDaysAgo,
          filteringType: "Daily",
        });
        setSelectedDataFiles([]);
        setShowNotification(true);
        setStartDate(thirtyDaysAgo);
      } catch (error) {
        console.log(error);
      }
    };

    getCurrentMonthsDailyData();
  }, []);

  useEffect(() => {
    if (selectedDataFiles.length) {
      customTableRef.current.scrollIntoView();
    }
  }, [selectedDataFiles]);

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  return (
    <div className="statistics">
      <div className="statistics__header">
        <h1>Statistics</h1>
        <p>This report shows the distribution of historical submissions between two dates.</p>
      </div>
      <div className="statistics__body">
        <div className="statistics__body__item">
          <div className={"statistics__body__item__header"}>Submission History</div>
          <br />
          <hr className="statistics__divider" />
          <div className="statistics__body__item__filter-area">
            <div className="statistics__body__item__filter-area__filter">
              <div className="statistics__body__item__filter-area__filter__header">Start Date</div>
              <div className="statistics__body__item__filter-area__filter__item">
                <DatePicker
                  selected={startDate}
                  closeOnScroll={true}
                  onChange={(date) => {
                    setStartDate(date);
                    setEndDate(null);
                  }}
                  selectsStart
                  showYearDropdown
                  showMonthDropdown
                  showDisabledMonthNavigation
                  isClearable
                  dateFormat={"dd MMM yyyy"}
                  className="statistics__body__item__filter-area__date-picker"
                />
              </div>
            </div>
            <div className="statistics__body__item__filter-area__filter">
              <div className="statistics__body__item__filter-area__filter__header">End Date</div>
              <div className="statistics__body__item__filter-area__filter__item">
                <DatePicker
                  selected={endDate}
                  closeOnScroll={true}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  minDate={startDate}
                  maxDate={
                    filteringType.id === "monthly"
                      ? addDays(startDate, 359)
                      : filteringType.id === "weekly"
                      ? addDays(startDate, 239)
                      : addDays(startDate, 119)
                  }
                  showYearDropdown
                  showMonthDropdown
                  showDisabledMonthNavigation
                  isClearable
                  dateFormat={"dd MMM yyyy"}
                  className="statistics__body__item__filter-area__date-picker"
                />
              </div>
            </div>
            {currentUserRole === "ADMIN" && (
              <div className="statistics__body__item__filter-area__filter">
                <div className="statistics__body__item__filter-area__filter__header">Users</div>
                <div className="statistics__body__item__filter-area__filter__item">
                  <CustomMultiSelect
                    options={filteredUsers}
                    setOptions={setFilteredUsers}
                    placeholder="Select..."
                  />
                </div>
              </div>
            )}
            <div className="statistics__body__item__filter-area__filter">
              <div className="statistics__body__item__filter-area__filter__header">Submit Type</div>
              <div className="statistics__body__item__filter-area__filter__item">
                <CustomMultiSelect
                  options={filteredSubmitTypes}
                  setOptions={setFilteredSubmitTypes}
                  placeholder="Select..."
                />
              </div>
            </div>
            <div className="statistics__body__item__filter-area__filter">
              <div className="statistics__body__item__filter-area__filter__header">Method</div>
              <div className="statistics__body__item__filter-area__filter__item">
                <CustomSelect
                  width="178px"
                  data={FILTERING_TYPE}
                  select={filteringType}
                  setSelected={setFilteringType}
                />
              </div>
            </div>
            <div className="statistics__body__item__filter-area__filter">
              <div className="statistics__body__item__filter-area__filter__header"></div>
              <div className="statistics__body__item__filter-area__filter__item">
                <button onClick={optionButtonClickHandler}>Search</button>
              </div>
            </div>
          </div>
          <StackedChart
            data={dataSet}
            filteringType={filteringType.id}
            setSelectedDataFiles={setSelectedDataFiles}
            startDate={startDate}
            endDate={endDate}
          />
          <div className="statistics__body__item__notification">
            {showNotification &&
              `Showing ${
                Math.floor(
                  (notificationParams.endDate.getTime() - notificationParams.startDate.getTime()) /
                    (1000 * 3600 * 24),
                ) + 1
              } days, grouped ${
                notificationParams.filteringType
              }. Total submission count is ${calculateTotalFileCount(dataSet)}`}
          </div>

          {selectedDataFiles.length !== 0 ? (
            <div className="statistics__table border" style={{ minHeight: "37.5rem" }}>
              <div>
                <CustomTable
                  isSetUrl
                  dataStatistics={filterCustomTableData(selectedDataFiles)}
                  minBottom="5px"
                  column={statisticsHeader}
                  refresh={false}
                  tableName={"statistics"}
                  paginationButtons={true}
                  tableRef={customTableRef}
                  minHeight="auto"
                  customPageSize={8}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="statistics__body__item__notification">
                HINT: Columns can be clicked to list the files submitted on that date.
              </div>
              <div className="statistics__body__item__notification">
                HINT: The maximum days can be selected are 120 for Daily, 240 for Weekly, 360 for
                Monthly submissions.
              </div>
            </>
          )}
        </div>
      </div>
      <StatisticsListPopup
        show={listPopupActive}
        groupID={query.get("data")}
        list={flattenDataSet(dataSet)}
        data={filterListPopupData(selectedDataFiles, query.get("data"))}
      />
      <Warning
        onClickOk={() => setErrorShow(false)}
        show={errorShow}
        setShow={setErrorShow}
        text={errorText}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </div>
  );
};

export default Statistics;
