import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestSetSlackConfig,
  requestGetSlackConfig,
} from "../../../../../../store/actions/settingsActions";
import Field from "../../../Components/Field";
import Warning from "../../../../../../Components/common/Warning/Warning";
import LogoIcon from "../../../../../../Components/Icon-SVG/LogoIcon";
import "../styles.scss";

const SlackSettings = () => {
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const [error, setError] = useState("");
  const [channelId, setChannelId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const slackconf = useSelector((state) => state.settings.slackconf);

  useEffect(() => {
    dispatch(requestGetSlackConfig());
  }, [dispatch]);

  useEffect(() => {
    if (slackconf) {
      setChannelId(slackconf?.channel_id);
      setAccessToken(slackconf?.access_token);
    }
  }, [slackconf]);

  const saveConfigHandler = () => {
    if (channelId && accessToken) {
      const sdata = {
        channel_id: channelId,
        access_token: accessToken,
      };
      dispatch(requestSetSlackConfig(sdata));
    } else {
      setError("Please fill in all fields.");
      setShowWarning(true);
    }
  };

  const deleteConfigHandler = () => {};

  return (
    <div style={{ backgroundColor: "var(--layout-bg)" }}>
      <div className="border mailing__content">
        <div className="mailing__content__body">
          <h3 className="m-b-20">Channel Configs</h3>
          <div className="flex flex-align-items-center m-b-10">
            <Field
              label="Group / Channel ID"
              width="131px"
              value={channelId}
              onChange={(e) => setChannelId(e.target.value)}
              parentClassName="p-r-20"
            />
            <Field
              label="Access Token"
              width="131px"
              value={accessToken}
              onChange={(e) => setAccessToken(e.target.value)}
              parentClassName="p-r-20"
            />
          </div>
          <div className="flex flex-align-items-center m-b-10">
            {/* <button className="m-r-15 default-button" onClick={deleteConfigHandler}>
              Delete
            </button> */}
            <button className="m-l-4 primary-button" onClick={saveConfigHandler}>
              Save
            </button>
          </div>
        </div>
        <Warning
          onClickOk={() => setShowWarning(false)}
          show={showWarning}
          setShow={setShowWarning}
          text={error}
          article="Warning"
          cancelDisable={true}
          textOK="Okay"
        />
      </div>
      <div className="border mailing__content" style={{ marginTop: "20px" }}>
        <div className="mailing__content__body">
          <h3 className="m-b-20">How to Configure Slack Settings?</h3>
          <div className="flex flex-align-items-center">
            <table style={{ color: "white" }}>
              <tbody>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    Install the Slack app from the following address:{" "}
                    <a
                      style={{ fontWeight: "bolder", color: "var(--color-blue)" }}
                      href="https://slack.com/oauth/v2/authorize?client_id=3717961996375.6874138970055&scope=calls:write,chat:write,chat:write.public,links:write&user_scope=calls:write,chat:write,links.embed:write">
                      SLACK APP
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    Once the installation is complete, make a note of the generated access token.
                    (For example: "Access Token:
                    xoxb-6885895766309-6891327782756-YViDOfhAUdHGX8vP3XE7YYMR")
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    Go to the Slack channel where you want to receive notifications and add the
                    application to the channel by mentioning @CyberFortress.
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    Right-click on the notification channel. When you click on the "View channel
                    details" option, note the "Channel ID" at the bottom of the window.
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    Enter the Access Token and Channel ID in the respective fields on the Slack
                    settings page and save them.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlackSettings;
