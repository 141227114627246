const IocIcon = ({ width = 22, height = 22, color = "var(--text-color-card)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24">
      <path
        fill={color}
        d="m17.903,9.834c.092.545-.201,1.196-.987,1.166-.479,0-.902-.346-.985-.834-.406-2.414-2.48-4.166-4.931-4.166-1.417,0-2.774.607-3.725,1.668-.368.411-.999.446-1.412.076-.411-.368-.445-1-.076-1.412,1.328-1.481,3.229-2.332,5.213-2.332,3.431,0,6.333,2.453,6.903,5.834Zm2.097,1.166v1c0,.553.447,1,1,1s1-.447,1-1v-1c0-1.932-.511-3.834-1.477-5.501-.276-.479-.89-.642-1.366-.364-.479.276-.642.889-.364,1.366.79,1.363,1.207,2.919,1.207,4.499ZM1,15c.553,0,1-.447,1-1v-3C2,6.037,6.037,2,11,2c1.963,0,3.83.624,5.398,1.804.443.334,1.068.243,1.4-.197.332-.441.244-1.068-.197-1.4-1.918-1.443-4.201-2.206-6.602-2.206C4.935,0,0,4.935,0,11v3c0,.553.447,1,1,1Zm5-4c0-.28.023-.562.069-.834.092-.545-.275-1.061-.82-1.152-.556-.085-1.062.276-1.152.82-.064.383-.097.774-.097,1.166,0,2.888-.553,6.436-3.188,6.937-.542.104-.898.627-.795,1.17.092.479.511.812.981.812,2.488-.406,5.001-2.319,5.001-8.919Zm16.44,13h-9.197c-1.203,0-1.952-1.305-1.346-2.343l4.599-7.884c.601-1.031,2.091-1.031,2.692,0l4.599,7.884c.606,1.039-.143,2.343-1.346,2.343Zm-3.599-2c0-.552-.448-1-1-1s-1,.448-1,1,.448,1,1,1,1-.448,1-1Zm0-5c0-.552-.448-1-1-1s-1,.448-1,1v2c0,.552.448,1,1,1s1-.448,1-1v-2ZM.999,19.919c-.064.01.062,0,0,0h0Zm11.001-8.895l.013.393c.022.667.044,1.297-.104,2.456-.069.548.317,1.049.865,1.119.554.062,1.05-.317,1.119-.865.168-1.319.144-2.061.119-2.778l-.012-.349c0-1.654-1.346-3-3-3s-3,1.346-3,3c0,2.604,0,6.964-3.164,9.181-.452.317-.562.941-.245,1.394.195.277.505.426.82.426.198,0,.398-.059.573-.181,4.016-2.814,4.016-8.021,4.016-10.819,0-.552.448-1,1-1s1,.448,1,1.024Z"
      />
    </svg>
  );
};

export default IocIcon;
