import * as React from "react"

const Eps = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.595 588.595"
    xmlSpace="preserve">
    <path  d="M539.564 49.198H361.365c-.786 0-1.562.077-2.347.124V0L11.225 46.414V540.98l347.804 47.614V537.78c.781.058 1.551.11 2.342.11h178.2c20.851 0 37.8-16.959 37.8-37.8V86.993c-.006-20.833-16.95-37.795-37.807-37.795zM110.972 364.526l-66.192-2.979V250.106l63.925-2.89v21.737l-41.154 1.171v23.577l38.741-.374v21.528l-38.741-.273v26.941l43.422 1.229v21.774zm91.278-53.488c-9.587 9.028-23.551 12.92-39.519 12.714-3.488-.058-6.626-.279-9.049-.664v43.353l-25.629-1.149V247.936c7.868-1.767 19.09-3.343 35.208-4.084 16.709-.77 28.859 2.004 37.135 8.351 8.013 6.046 13.469 16.393 13.469 28.798-.006 12.413-4.148 22.938-11.615 30.037zm62.877 62.374c-15.461-.707-30.388-5.221-37.747-9.529l5.999-23.768c8.002 4.219 20.472 8.606 33.584 8.997 14.365.433 22.085-5.084 22.085-13.796 0-8.321-6.685-13.162-23.306-18.989-22.388-7.636-36.616-19.451-36.616-38.077 0-21.853 18.773-39.445 50.965-40.948 15.912-.743 27.849 1.912 36.503 5.152l-7.356 25.001c-5.791-2.447-15.981-5.943-29.758-5.529-13.537.412-20.018 6.426-20.018 13.22 0 8.348 7.678 11.939 25.618 18.22 25.218 8.622 37.399 21.142 37.399 40.379 0 22.887-18.832 41.449-57.352 39.667zm290.638 126.69c0 8.933-7.271 16.199-16.2 16.199h-178.2c-.797 0-1.572-.115-2.347-.231V260.896c12.402-6.9 26.436-10.521 41.122-10.434 21.874.356 42.293 9.207 57.512 24.922.116.124.2.253.316.367v12.993h10.146c18.636 29.603 16.4 68.5-6.308 95.848H450.21v15.525h15.53v-11.803c21.368-25.544 25.871-60.592 13.289-90.186l40.731 48.6v16.062h16.918v-16.917h-10.6l-52.576-62.74v-9.914h-8.312l-55.993-66.82v-14.03H392.28v16.928h12.303l41.86 49.95c-13.643-8.92-29.51-13.908-46.222-14.186-14.491-.274-28.582 3.111-41.206 9.635V71.041c.774-.113 1.539-.242 2.347-.242h178.199c8.929 0 16.2 7.269 16.2 16.2v413.103zm-30.602-142.7v-6.123h6.117v6.123h-6.117zM403.8 197.775v6.127h-6.117v-6.127h6.117z" fill={color} />
    <path  d="M164.671 264.637c-5.435.169-9.094.82-10.99 1.427v35.799c2.247.538 5.044.706 8.873.696 14.352-.043 23.358-7.604 23.358-20.327-.005-11.422-7.752-18.003-21.241-17.595z" fill={color} />
  </svg>
)

export default Eps
