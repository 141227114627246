import React, { forwardRef, useEffect, useRef } from "react";

const CheckboxTable = forwardRef(({ id, indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <span className={`form-group`}>
        <input type="checkbox" id={new Date() + id} ref={resolvedRef} {...rest} />
        <label className="checkbox-label" htmlFor={new Date() + id} />
      </span>
    </>
  );
});
CheckboxTable.displayName = "CheckboxTable";
export default CheckboxTable;
