import * as React from "react";
const AddIcon = ({ width = "23", height = "23", backcolor = "var(--border)", color = "var(--border)"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8v8m4-4H8m4 9a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      fill={backcolor}
    />
  </svg>
);
export default AddIcon;
