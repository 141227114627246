import * as React from "react"

const Tex = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.335c.776.053 1.542.115 2.329.115h177.39c20.756 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.871-37.628-37.627-37.628h-177.39c-.781 0-1.553.076-2.329.123V0L11.176 46.206v492.311l346.22 47.401v-50.583zm0-464.618c.766-.113 1.532-.241 2.329-.241h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.116-2.329-.232v-443zm-239.114 188.21-28.609.553v90.938l-22.922-1.039v-89.453l-26.17.52v-20.57l77.701-2.71v21.761zm85.936 96.657-72.954-3.297V236.714l70.46-2.457v22.48l-45.32.908v24.429l42.659-.143v22.267l-42.659-.49v27.906l47.814 1.459v22.511zm86.484 3.917-12.045-23.612c-4.887-8.998-8.005-15.653-11.693-23.055h-.375c-2.698 7.259-5.971 13.727-9.986 22.383l-10.612 22.258-32.068-1.449 36.035-61.66-34.767-60.58 32.305-1.129 11.21 22.562c3.842 7.596 6.725 13.738 9.814 20.835l.388-.005c3.1-8.113 5.638-13.8 8.96-21.217l11.25-23.625 35.35-1.231-38.521 63.793 40.594 67.333-35.839-1.601z" fill={color} />
    <path  d="M498.006 358.997c0 1.88-.135 3.506-.409 4.913-.273 1.407-.912 2.571-1.933 3.517-1.008.924-2.55 1.648-4.607 2.147-2.08.52-4.888.771-8.479.771h-81.276v-14.972c0-6.004 1.387-10.541 4.158-13.626 2.771-3.077 7.5-5.046 14.205-5.9v-1.755h-25.019v87.934h25.019v-2.929c-3.739-.231-6.794-.873-9.177-1.917-2.372-1.06-4.252-2.478-5.617-4.272-1.364-1.796-2.299-3.921-2.803-6.378-.499-2.456-.767-5.276-.767-8.483v-12.746h81.271c3.506 0 6.278.258 8.305.771 2.025.504 3.56 1.218 4.609 2.163 1.055.935 1.737 2.101 2.057 3.501.305 1.397.462 3.041.462 4.913v3.732h2.919v-45.019h-2.919v3.635z" fill={color} />
    <path  d="M518.668 267.328c.819 1.166 1.47 2.51 1.932 4.032.462 1.521.782 3.328.935 5.438.158 2.104.231 4.672.231 7.72v18.006c0 2.258-.116 3.979-.346 5.145-.243 1.18-.758 2.026-1.576 2.577s-2.005.861-3.57.939c-1.563.084-3.662.104-6.319.104h-35.076v-23.26c0-3.436.263-6.16.771-8.187.504-2.026 1.365-3.601 2.576-4.737 1.208-1.125 2.82-1.94 4.846-2.444 2.026-.517 4.573-.969 7.602-1.357v-2.929h-36.956v2.919c2.656.157 4.956.475 6.894.937 1.953.473 3.548 1.31 4.802 2.52 1.255 1.21 2.185 2.866 2.81 4.965.619 2.103.94 4.872.94 8.308v23.265H427.17v-29.115c0-3.58.18-6.52.53-8.83.353-2.302 1.155-4.189 2.399-5.672 1.249-1.483 3.03-2.667 5.313-3.57 2.298-.892 5.364-1.729 9.186-2.509v-1.872h-23.265v81.853h2.918v-3.732c0-4.451 1.051-7.601 3.16-9.486 1.555-1.323 5.723-1.984 12.504-1.984h68.998c5.136 0 8.568.32 10.278.936.945.319 1.755.859 2.457 1.642a9.845 9.845 0 0 1 1.7 2.625c.432.987.756 2.016.998 3.104.23 1.091.347 2.135.347 3.165v3.732h2.919v-81.847l-26.657-9.239v3.161c6.709 3.503 11.58 6.813 14.615 9.932 1.25 1.331 2.289 2.567 3.098 3.745z" fill={color} />
    <path  d="M393.218 250.476c.587-2.182 1.595-4.35 3.044-6.488 1.438-2.139 3.465-4.371 6.069-6.664 2.609-2.302 6.026-4.893 10.231-7.78l30.636-20.923 31.69 25.016c2.719 2.18 5.154 4.283 7.297 6.32 2.15 2.029 3.947 4.076 5.386 6.134 1.437 2.082 2.582 4.231 3.443 6.488.86 2.268 1.364 4.682 1.522 7.257h2.919v-37.649h-2.919c-.158 3.977-.776 6.665-1.88 8.063-1.081 1.407-2.446 2.113-4.083 2.113-.553 0-1.051-.023-1.523-.065-.463-.032-1.008-.21-1.637-.527-.62-.315-1.397-.796-2.348-1.458-.924-.661-2.168-1.617-3.731-2.863l-28.532-22.331 27.239-18.005a139.934 139.934 0 0 0 4.101-2.701c1.013-.692 1.849-1.22 2.509-1.574.662-.344 1.171-.564 1.513-.638a4.744 4.744 0 0 1 1.112-.113c2.268 0 4.022.683 5.267 2.047 1.238 1.365 1.903 4.186 1.983 8.473h2.919v-45.607h-2.919c-.085 2.415-.368 4.497-.883 6.247-.503 1.753-1.501 3.549-2.981 5.386-1.479 1.83-3.633 3.822-6.487 5.961-2.846 2.141-6.679 4.85-11.517 8.118l-34.027 22.813-27.014-21.05c-5.606-4.441-9.344-7.758-11.224-9.945-1.795-2.026-3.233-4.145-4.326-6.375-1.091-2.215-1.753-5.124-1.984-8.714h-2.919v37.662h2.919c.158-4.209.631-7.16 1.397-8.832.787-1.682 2.309-2.522 4.567-2.522.629 0 1.175.042 1.637.124.468.076.992.275 1.581.574.582.318 1.327.785 2.221 1.41.891.622 2.094 1.52 3.558 2.688l23.98 18.824-24.442 16.368c-3.664 2.501-6.477 3.735-8.414 3.735-1.476 0-2.818-.767-4.031-2.331-1.213-1.553-1.896-4.399-2.048-8.538h-2.919v49.116h2.919c.174-2.643.54-5.055 1.129-7.244z" fill={color} />
  </svg>
)

export default Tex
