import * as React from "react"

const Xhtml = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M537.115 48.979h-177.39c-.787 0-1.553.077-2.329.124V0L11.176 46.206v492.311l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.745 0 37.627-16.888 37.627-37.627V86.602c0-20.743-16.882-37.623-37.627-37.623zM65.71 336.351l-5.026-11.266c-2.045-4.311-3.35-7.507-4.898-11.077h-.156c-1.142 3.539-2.522 6.71-4.215 10.951l-4.504 10.95-13.782-.319 15.475-30.479-14.927-29.719 13.885-.368 4.75 11.037a205.043 205.043 0 0 1 4.134 10.121h.165c1.302-3.929 2.36-6.68 3.754-10.255l4.688-11.36 14.518-.375-15.835 30.531 16.686 31.963-14.712-.335zm71.035 1.626-13.879-.324v-27.034l-22.282-.054v26.578l-13.208-.309v-62.857l13.208-.344v24.406l22.282-.167v-24.819l13.879-.368v65.292zm57.724-53.869-18.236.308v54.471l-14.806-.343v-53.881l-17.097.296v-12.475l50.134-1.318v12.942h.005zm69.275 56.783-1.108-27.397c-.328-8.588-.656-18.979-.656-29.375l-.333.008c-2.323 9.142-5.396 19.31-8.233 27.646l-8.904 27.529-12.706-.285-7.591-27.122c-2.302-8.157-4.703-18.027-6.37-27.059l-.2.005c-.417 9.358-.725 20.045-1.247 28.675l-1.236 26.259-14.444-.326 4.371-68.567 21.013-.554 6.971 23.194c2.236 8.1 4.473 16.846 6.074 25.097h.328c2.032-8.146 4.504-17.459 6.882-25.333l7.827-23.698 22.076-.58 4.176 72.258-16.69-.375zm80.271 1.844-50.208-1.15v-73.036l17.855-.469v59.882l32.353.441v14.332zM553.24 497.823c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.231V70.722c.766-.112 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.126v411.217z" fill={color} />
    <path  d="m484.17 284.877 47.968 20.566v.252L484.17 326.26v13.691l62.048-28.442v-11.884l-62.048-28.443zM385.176 231.528c-8.902 0-17.402 1.656-25.312 4.53v24.056c.189.01.346.01.545.026 7.96.671 4.986 6.302 8.306 7.635 3.316 1.331-2.667 5.961-3.989 8.611-1.324 2.654-3.309-3.317-3.309-3.317s1.984-2.291-1.322-2.984c-.083-.016-.147.021-.23.016v42.765c.462.109.881.21 1.213.31 2.324.672 6.965 1.002 9.627 3.659 2.641 2.651-1.989 7.632-2.335 9.291-.332 1.649-2.327 5.638-3.644 8.284-1.324 2.645-1.984 3.642-3.654 4.966-.462.366-.861.818-1.207 1.27v34.438c7.905 2.875 16.399 4.534 25.302 4.534 40.894 0 74.038-33.154 74.038-74.037.003-40.898-33.142-74.053-74.029-74.053zm27.828 29.381 5.295-3.536s-2.226-5.703-1.847-8.504c1.28-.62 6.498 2.113 10.902 6.75-2.966 11.047-9.926 9.725-9.926 9.725s-5.092-.118-4.424-4.435zm-29.724-12.373c-.341 3.325-5.628 7.622-4.966 8.966.667 1.333 0 7.002-4.636 2.027-4.65-4.979-9.297-6.667-8.954-9.992.083-.662 4.802-1.701 4.877-2.856 7.027-6.541 18.251-5.197 19.111-3.644-2.004 3.283-5.09 2.186-5.432 5.499zm57.602 22.265c4.482 7.686 11.034 28.194 8.614 31.863l-2.678-1.197h-8.845l5.298 6.19s2.565 3.758 6.198 3.517c-2.394 27.728-25.532 45.521-25.532 45.521-3.527-3.537-1.759-6.606-1.759-6.606l.992-5.313.562-8.946s0-9.269-8.846-4.864c-9.502 2.418-5.542 2.418-15.138 3.091-9.608.662-8.017-19.597-8.017-19.597 0-30.333 23.261-7.442 23.261-7.442 13.271 8.829 15.039-7.318 15.039-7.318l9.717-3.517.881-4.429-1.412-6.17-14.12-5.826s-1.36 4.587 2.262 10.223c0 0-1.207 6.401-4.178 4.756l-8.184-4.119s-2.757-1.186-7.172 1.47c-4.431 2.644-11.921-.126-11.921-.126s.604-4.449 5.26-6.751l3.802-2.519s-.998-4.538-.127-8.063c.872-3.538 2.436-.441 5.964-3.097 3.532-2.646 5.963 5.186 11.264 4.304 5.303-.879 2.657-1.762 6.201-3.525 3.542-1.773 6.188 3.525 6.188 3.525l7.056.882c-.004-.006-1.108-7.988-.63-5.917z" fill={color} />
  </svg>
)

export default Xhtml
