import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Backdrop from "../../UI/Backdrop/Backdrop";
import PreStaticResultsTableDetails from "./Components/PreStaticResultsTableDetails";
import CustomTable from "../Table/CustomTable";
import { compressedStaticResultsHeader } from "../../../utils/constants/tableHeaders";
import { isNotCompressedItself } from "../../../utils/function";
import {
  RequestGetPreStaticResults,
  ResetPreStaticResults,
} from "../../../store/actions/analysisReportActions";

import "./styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PreStaticResultsTable = ({ tableName }) => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state?.dashboard?.list);
  const submissionsListLoading = useSelector((state) => state?.dashboard?.submissionsListLoading);

  let query = useQuery();

  const compressedID = query.get("compressed");
  const hash = query.get("data");

  useEffect(() => {
    if (compressedID) {
      list
        ?.filter(
          (filterData) =>
            filterData.compressedIDfull === compressedID &&
            isNotCompressedItself(
              filterData.compressedID,
              filterData.compressedIDfull,
              filterData.fileIDfull,
            ),
        )
        .forEach((listData) => {
          dispatch(RequestGetPreStaticResults(listData.fileHash));
        });
    }

    return () => {
      dispatch(ResetPreStaticResults());
    };
  }, [compressedID]);

  useEffect(() => {
    if (!compressedID) {
      dispatch(RequestGetPreStaticResults(hash));
    }
  }, [compressedID, hash]);

  if (submissionsListLoading) {
    <div className="pre-static-table-wrapper">
      <Backdrop absolute={true} color="transparent" show={submissionsListLoading} />
    </div>;
  }

  return (
    <>
      {tableName === "compressedStaticResults" ? (
        <CustomTable
          column={compressedStaticResultsHeader}
          tableName={tableName}
          leftAction={false}
          tableFolder={tableName}
          isSetUrl
          paginationButtons={false}
          className=""
          listGroupData={compressedID}
          refresh={false}
          customPageSize={200}
          minHeight={50}
        />
      ) : tableName === "staticResults" ? (
        <div className="pre-static-table-wrapper">
          <PreStaticResultsTableDetails hash={hash} />
        </div>
      ) : null}
    </>
  );
};

export default PreStaticResultsTable;
