import { loadFromLocalStorage, saveToLocalStorage } from "../utils/localStorage";
import axiosApi from "../utils/axios";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { initialState } from "./slices/usersSlice";
import { loginSuccess, logoutSuccess } from "./actions/usersActions";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  devTools: true,
  preloadedState: loadFromLocalStorage("user"),
});

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  saveToLocalStorage("user", {
    users: {
      ...initialState,
      user: store.getState()?.users.user,
      rememberMe: store.getState()?.users.rememberMe,
    },
  });
});

axiosApi.interceptors.request.use((config) => {
  try {
    if (config.data === "401")
      config.headers["Authorization"] = `Bearer ${store.getState()?.users.user.refresh_token}`;
    else config.headers["Authorization"] = `Bearer ${store.getState()?.users.user.access_token}`;
  } catch (e) {
    // do nothing, no token exists
  }

  return config;
});

axiosApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config) {
      try {
        const response = await axiosApi.post("/api/token/token-refresh", "401", {
          headers: {
            Authorization: `Bearer ${store.getState()?.users.user.refresh_token}`,
          },
        });
        saveToLocalStorage("user", {
          users: {
            ...initialState,
            user: {
              access_token: response.data.access_token,
              refresh_token: store.getState()?.users.user.refresh_token,
              role: store.getState()?.users.user.role,
              email: store.getState()?.users.user.email,
            },
          },
        });
        store.dispatch(
          loginSuccess({
            user: {
              access_token: response.data.access_token,
              refresh_token: store.getState()?.users.user.refresh_token,
              role: store.getState()?.users.user.role,
              email: store.getState()?.users.user.email,
            },
          }),
        );
        return axiosApi.request(originalRequest);
      } catch (e) {
        store.dispatch(logoutSuccess());
      }
    }

    throw error;
  },
);

export default store;
