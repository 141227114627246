import * as React from "react";

const DiskIcon = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg">
    <title>{"hard-disk-solid"}</title>
    <path
      className="clr-i-solid clr-i-solid-path-1"
      d="M30.86 8.43A2 2 0 0 0 28.94 7H7.06a2 2 0 0 0-1.93 1.47L2.29 20h31.42Z"
    />
    <path
      className="clr-i-solid clr-i-solid-path-2"
      d="M2 22v7a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2v-7Zm28 5h-4v-2h4Z"
    />
    <path fill="none" d="M0 0h36v36H0z" />
  </svg>
);

export default DiskIcon;
