import * as React from "react";

const InfoStealerIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".info1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path
        className="info"
        d="M962 873.5H62l104.4-65.2h691.2zM592.9 873.5c0 11.6-9.4 20.9-20.9 20.9H454c-11.6 0-20.9-9.4-20.9-20.9H62v19.1c0 18 14.6 32.6 32.6 32.6h834.8c18 0 32.6-14.6 32.6-32.6v-19.1H592.9zM309.9 295.3h-60.5c-45.8 0-83 37.2-83 83v400.5h54V378.3c0-16 13-29 29-29h60.5v-54zM803.6 630.7h54v148.1h-54z"
        fill={color}
      />
      <path
        className="info1"
        d="M887.2 98.8H389.3c-25.6 0-46.3 20.7-46.3 46.3v405.4c0 25.6 20.7 46.3 46.3 46.3h497.9c25.6 0 46.3-20.7 46.3-46.3V145.1c0-25.6-20.7-46.3-46.3-46.3zm-365 33.5c10.9 0 19.7 8.8 19.7 19.7 0 10.9-8.8 19.7-19.7 19.7-10.9 0-19.7-8.8-19.7-19.7 0-10.9 8.8-19.7 19.7-19.7zm-64 0c10.9 0 19.7 8.8 19.7 19.7 0 10.9-8.8 19.7-19.7 19.7-10.9 0-19.7-8.8-19.7-19.7 0-10.9 8.8-19.7 19.7-19.7zm-64 0c10.9 0 19.7 8.8 19.7 19.7 0 10.9-8.8 19.7-19.7 19.7-10.9 0-19.7-8.8-19.7-19.7.1-10.9 8.9-19.7 19.7-19.7zM907 550.5c0 10.9-8.9 19.8-19.8 19.8H389.3c-10.9 0-19.8-8.9-19.8-19.8V202.9H907v347.6z"
      />
      <circle className="info1" cx={508.8} cy={320} r={46.7} />
      <circle className="info1" cx={508.8} cy={276.6} r={16.7} />
      <path
        className="info1"
        d="m477.5 297.8-21.6-21.6v-26.4h9.5v22.5l18.8 18.8zM465.4 385.4h-9.5v-30.5l18.7-18.7 6.7 6.7-15.9 15.9zM475 309.8h-30.5l-18.7-18.7 6.7-6.7 15.9 15.9H475zM432.5 349.3l-6.7-6.7 18.7-18.7H475v9.5h-26.6zM540.2 297.8l-6.7-6.7 18.8-18.8v-22.5h9.5v26.4zM561.8 385.4h-9.5v-26.6l-15.9-15.9 6.7-6.7 18.7 18.7zM573.2 309.8h-30.5v-9.5h26.6l15.9-15.9 6.7 6.7zM585.2 349.3l-15.9-15.9h-26.6v-9.5h30.5l18.7 18.7zM635.9 257.5h214.8v35.7H635.9zM635.9 331.4h214.8v35.7H635.9zM425.8 405.3h424.9V441H425.8zM425.8 479.2h328.1v35.7H425.8zM353 745.5h-46.5c-7.8 0-14.2-6.4-14.2-14.2v-46.5c0-7.8 6.4-14.2 14.2-14.2H353c7.8 0 14.2 6.4 14.2 14.2v46.5c0 7.8-6.3 14.2-14.2 14.2zM474.5 745.5H428c-7.8 0-14.2-6.4-14.2-14.2v-46.5c0-7.8 6.4-14.2 14.2-14.2h46.5c7.8 0 14.2 6.4 14.2 14.2v46.5c0 7.8-6.4 14.2-14.2 14.2zM596 745.5h-46.5c-7.8 0-14.2-6.4-14.2-14.2v-46.5c0-7.8 6.4-14.2 14.2-14.2H596c7.8 0 14.2 6.4 14.2 14.2v46.5c0 7.8-6.4 14.2-14.2 14.2zM717.4 745.5H671c-7.8 0-14.2-6.4-14.2-14.2v-46.5c0-7.8 6.4-14.2 14.2-14.2h46.5c7.8 0 14.2 6.4 14.2 14.2v46.5c-.1 7.8-6.4 14.2-14.3 14.2z"
      />
    </g>
  </svg>
);

export default InfoStealerIcon;
