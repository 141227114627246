import * as React from "react"

const Mdf = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="M181.725 269.072c-5.255.119-8.63.669-10.621 1.187v69.32c1.991.537 5.21.627 8.153.717 21.666.802 36.186-11.116 36.186-37.241.145-22.73-13.078-34.448-33.718-33.983z" fill={color} />
    <path  d="M359.031 537.785c.781.053 1.551.116 2.342.116h178.2c20.851 0 37.8-16.96 37.8-37.8v-413.1c0-20.841-16.949-37.8-37.8-37.8h-178.2c-.791 0-1.561.074-2.342.124V0L11.227 46.419V540.98l347.804 47.62v-50.815zm2.342-466.984h178.2c8.933 0 16.2 7.267 16.2 16.2v413.101c0 8.933-7.268 16.199-16.2 16.199h-178.2c-.802 0-1.571-.115-2.342-.231v-97.105c17.635 9.566 37.779 15.071 59.253 15.071 68.839 0 124.622-55.803 124.622-124.632 0-68.806-55.783-124.599-124.622-124.599-21.474 0-41.618 5.508-59.253 15.072V71.044c.77-.113 1.54-.243 2.342-.243zm56.9 189.583c27.095 0 49.032 21.94 49.032 49.02 0 27.095-21.938 49.043-49.032 49.043-27.074 0-49.032-21.948-49.032-49.043 0-27.086 21.958-49.02 49.032-49.02zm-15.651-64.077s20.767-9.337 53.188 3.715l-27.073 55.252c-5.258-2.036-10.458-.949-10.458-.949l-15.657-58.018zM109.943 355.071l-1.392-38.749c-.412-12.15-.833-26.834-.833-41.521l-.419.01c-2.9 12.912-6.763 27.25-10.315 38.995l-11.108 38.628-15.778-.644-9.353-38.085c-2.835-11.411-5.78-25.223-7.826-37.837l-.253.005c-.509 13.057-.896 27.981-1.519 40.015l-1.527 36.629-17.616-.749 5.312-95.296 25.748-.918 8.608 32.474c2.764 11.365 5.535 23.662 7.533 35.253l.388.01c2.534-11.411 5.611-24.489 8.564-35.563l9.779-33.415 27.799-.994 5.315 102.642-21.107-.89zm113.687-8.248c-11.602 9.165-28.901 12.952-49.465 12.065-12.016-.521-20.405-1.661-26.04-2.674V253.913c8.311-1.69 19.322-2.866 31.137-3.293 20.073-.728 33.415 2.526 44.044 10.167 11.62 8.232 19.034 21.795 19.034 41.486 0 21.347-7.91 35.854-18.71 44.55zm110.953-78.922-48.094 1.012v26.262l44.846-.145v21.798l-44.846-.496v46.217l-27-1.149V248.558l75.094-2.679v22.022z" fill={color} />
    <path  d="M418.284 352.804c23.92 0 43.379-19.47 43.379-43.38 0-23.931-19.464-43.397-43.379-43.397-23.921 0-43.369 19.467-43.369 43.397-.005 23.91 19.448 43.38 43.369 43.38zm0-81.783c21.167 0 38.38 17.22 38.38 38.403 0 21.157-17.218 38.364-38.38 38.364-21.157 0-38.37-17.218-38.37-38.364 0-21.178 17.212-38.403 38.37-38.403z" fill={color} />
    <path  d="M418.284 330.139c11.443 0 20.745-9.303 20.745-20.735s-9.302-20.735-20.745-20.735c-11.433 0-20.735 9.303-20.735 20.735s9.296 20.735 20.735 20.735zm0-36.458c8.68 0 15.746 7.042 15.746 15.723 0 8.68-7.066 15.735-15.746 15.735-8.681 0-15.736-7.056-15.736-15.735-.001-8.681 7.055-15.723 15.736-15.723z" fill={color} />
  </svg>
)

export default Mdf
