import integrationsSlice from "../slices/integrationsSlice";

export const {
  RequestDeployInt,
  SuccessDeployInt,
  FailureDeployInt,
  RequestGetConfig,
  SuccessGetConfig,
  FailureGetConfig,
  RequestDeleteInt,
  SuccessDeleteInt,
  FailureDeleteInt,
  RequestSetConfig,
  SuccessSetConfig,
  FailureSetConfig,
  RequestCheckConfig,
  SuccessCheckConfig,
  FailureCheckConfig,
  ClearGetConfig,
  RequestDeleteConfig,
  SuccessDeleteConfig,
  FailureDeleteConfig,
  RequestUpdateConfig,
  SuccessUpdateConfig,
  FailureUpdateConfig,
  RequestAgentStatus,
  SuccessAgentStatus,
  FailureAgentStatus,
} = integrationsSlice.actions;
