import * as React from "react"

const Vcf = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.335c.776.053 1.542.115 2.329.115h177.39c20.756 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.871-37.628-37.627-37.628h-177.39c-.781 0-1.553.076-2.329.123V0L11.176 46.206v492.311l346.22 47.401v-50.583zm0-180.742h49.469v-8.217h-49.469V326.33h49.469v-8.232h-49.469v-19.045h49.469v-8.235h-49.469v-19.206h49.469v-8.218h-49.469v-25.389h165.377c.377 0 .683.318.683.693v144.024a.682.682 0 0 1-.683.683H357.396v-28.812zm2.33-284.117h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.116-2.329-.232V390.262h165.377a7.538 7.538 0 0 0 7.538-7.539V238.698c0-4.165-3.381-7.546-7.538-7.546H357.396V70.717c.766-.118 1.533-.241 2.33-.241zM96.843 361.038l-26.95-1.402-31.35-110.029 24.108-.683 12.17 46.724c3.449 13.26 6.606 26.089 9.028 40.104l.452.016c2.58-13.396 5.785-26.684 9.31-39.513l13.349-48.58 25.811-.73-35.928 114.093zM202.368 345.6c8.242.314 17.499-1.155 22.987-3.155l4.224 21.985c-5.155 2.314-16.636 4.446-31.294 3.68-40.22-2.137-60.115-28.241-60.115-61.386 0-39.718 27.318-62.613 62.791-63.666 14.164-.42 25.134 2.184 30.103 4.617l-5.709 22.287c-5.677-2.299-13.483-4.371-23.163-4.234-21.265.315-37.375 13.378-37.375 39.757.004 23.736 13.694 39.192 37.551 40.115zm127.979-79.773-52.545.706v29.14l49 .247v24.188l-49-.966v51.286l-29.489-1.532V243.709l82.034-2.312v24.43z" fill={color} />
    <path  d="m498.804 314.979-16.236-8.545s-.859-1.015-2.362-2.259c3.14-3.61 5.513-8.445 6.105-13.217.462-.342 1.233-1.144 1.533-3.005 0 0 1.928-6.94-.903-5.617 1.989-10.541.483-14.143.483-14.143-2.016-6.963-2.184-8.473-9.518-10.323-7.303-1.858-16.415 1.01-16.415 1.01-6.068 1.809-8.567 5.221-8.567 5.221-5.321 5.995-2.561 15.644-1.742 18.122-2.379-.704-.572 5.73-.572 5.73.289 1.847 1.05 2.663 1.533 3.005.651 4.693 3.217 9.748 6.708 13.469a27.76 27.76 0 0 0-2.294 1.927s-.062 2.646-7.585 5.293c0 0-14.089 4.44-16.405 9.574 0 0-7.685 17.522-5.899 33.072 0 0 .085 1.29 2.646 2.917 0 0 17.732 7.424 42.102 6.92 0 0 23.705-1.208 38.834-7.959 0 0 2.143-.504 2.226-5.459 0 0-.776-21.711-7.349-31.454-.002.012-1.882-2.55-6.323-4.279z" fill={color} />
  </svg>
)

export default Vcf
