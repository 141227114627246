import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AppCard from "../../Components/common/AppCard/AppCard";
import IcapImg from "./assests/icap.png";
import FileShareImg from "./assests/fileshare.png";
import ImapImg from "./assests/imap.png";
import PostfixImg from "./assests/postfix.jpg";
import SharePointImg from "./assests/sharepoint.png";
import UsbImg from "./assests/usb.png";
import CStrikeImg from "./assests/cstrike.png";
import IntegrationConfigList from "../../Components/common/IntegrationConfigList/IntegrationConfigList";
import WinshareSettings from "../../Components/PagesComponents/IntegrationsMarket/WinshareSettings/WinshareSettings";
import { useHistory } from "react-router-dom";
import {
  RequestCheckConfig,
  RequestGetConfig,
  ClearGetConfig,
} from "../../store/actions/integrationsActions";
import "./styles.scss";
import RclshareSettings from "../../Components/PagesComponents/IntegrationsMarket/RclshareSettings/RclshareSettings";
import ImapemailSettings from "../../Components/PagesComponents/IntegrationsMarket/ImapemailSettings/ImapemailSettings";
import CstrikeSettings from "../../Components/PagesComponents/IntegrationsMarket/CstrikeSettings/CstrikeSettings";
import PostfixemailSettings from "../../Components/PagesComponents/IntegrationsMarket/PostfixemailSettings/PostfixemailSettings";
import IntegrationModal from "../../Components/PagesComponents/IntegrationsMarket/IntegrationModal/IntegrationModal";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CARD_DATA = [
  {
    intname: "imapemail",
    cardName: "Imap Email",
    cardDescription:
      "Imap Email integration scans incoming emails and automatically sends the attachments to CyberFortress SBOX for security analysis.",
    imgSrc: ImapImg,
    imgClassName: "integration-market__images imap",
  },
  {
    intname: "postfixemail",
    cardName: "Postfix Email",
    cardDescription:
      "Postfix Email integration scans incoming emails to the smtp port and automatically sends the attachments to CyberFortress SBOX for security analysis.",
    imgSrc: PostfixImg,
    imgClassName: "integration-market__images postfix",
  },
  {
    intname: "rclshare",
    cardName: "Sharepoint",
    cardDescription:
      "SharePoint integration enhances file sharing security in Microsoft’s cloud-based storage platforms through CyberFortress SBOX.",
    imgSrc: SharePointImg,
    imgClassName: "integration-market__images sharepoint",
  },
  {
    intname: "winshare",
    cardName: "File Share",
    cardDescription:
      "The File Share integration allows CyberFortress SBOX to scan the traffic that takes place in various other party file sharing locations.",
    imgSrc: FileShareImg,
    imgClassName: "integration-market__images",
  },
  {
    intname: "icapweb",
    cardName: "ICAP Proxy",
    cardDescription:
      "ICAP Proxy integration aims to protect users from malicious content within web proxy communications by analyzing it through CyberFortress SBOX.",
    imgSrc: IcapImg,
    imgClassName: "integration-market__images icap",
  },
  {
    intname: "cstrike",
    cardName: "CrowdStrike",
    cardDescription: "API integrations for SBOX",
    imgSrc: CStrikeImg,
    imgClassName: "integration-market__images",
  },
  {
    intname: "usbtransfer",
    cardName: "USB Transfer",
    cardDescription:
      "The USB Transfer integration secures data transfers conducted through USB using CyberFortress SBOX.",
    imgSrc: UsbImg,
    imgClassName: "integration-market__images usb",
  },
];

const IntegrationMarket = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  const history = useHistory();
  const [listPopupActive, setListPopupActive] = useState(false);
  const [winshareConfigActive, setWinshareConfigActive] = useState(false);
  const [rclshareConfigActive, setRclshareConfigActive] = useState(false);
  const [imapemailConfigActive, setImapemailConfigActive] = useState(false);
  const [cstrikeConfigActive, setCstrikeConfigActive] = useState(false);
  const [postfixemailConfigActive, setPostfixemailConfigActive] = useState(false);
  const [activeInt, setActiveInt] = useState("");
  const [modalActive, setModalActive] = useState(false);
  const [modalType, setModalType] = useState("");
  const setConfig = useSelector((state) => state.integrations.setConfig);
  const deleteConfig = useSelector((state) => state.integrations.deleteConfig);
  const action = useSelector((state) => state.integrations.action);
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const loadingUpdate = useSelector((state) => state.integrations.loadingUpdate);

  useEffect(() => {
    dispatch(RequestCheckConfig());
  }, [action, dispatch]);

  useEffect(() => {
    if (activeInt !== "") {
      if (
        activeInt === "imapemail" ||
        activeInt === "rclshare" ||
        activeInt === "winshare" ||
        activeInt === "cstrike"
      ) {
        setListPopupActive(true);
        dispatch(RequestGetConfig(activeInt));
      } else {
        history.push(`integration-market?conf=${activeInt}`);
      }
    } else if (query.get("conf") === null) {
      dispatch(ClearGetConfig());
    }
  }, [activeInt]);

  useEffect(() => {
    if (query.get("conf") !== null) {
      switch (query.get("conf")) {
        case "winshare":
          setWinshareConfigActive(true);
          break;
        case "imapemail":
          setImapemailConfigActive(true);
          break;
        case "cstrike":
          setCstrikeConfigActive(true);
          break;
        case "rclshare":
          setRclshareConfigActive(true);
          break;
        case "postfixemail":
          dispatch(RequestGetConfig(activeInt));
          setPostfixemailConfigActive(true);
          break;
        default:
          break;
      }
    } else {
      setWinshareConfigActive(false);
      setRclshareConfigActive(false);
      setImapemailConfigActive(false);
      setCstrikeConfigActive(false);
      setPostfixemailConfigActive(false);
    }

    if (query.get("bck") !== null) {
      dispatch(ClearGetConfig());
      setActiveInt("");
      setActiveInt(query.get("bck"));
    }
  }, [query]);

  useEffect(() => {
    if (setConfig === "Success") {
      history.push("/integration-market?bck=" + query.get("conf"));
    }
  }, [setConfig]);

  useEffect(() => {
    if (deleteConfig === "Success" && query.get("t") !== null) {
      if (!loadingUpdate) {
        history.push("/integration-market?bck=" + query.get("conf"));
      }
    }
  }, [deleteConfig]);

  const openModalHandler = (modalType) => {
    if (modalType !== "usbtransfer") {
      setModalActive(true);
      setModalType(modalType);
    }
  };
  const closeModalHandler = () => setModalActive(false);

  return (
    <div className="integration-market">
      <div className="integration-market__header">
        <h1>Integration Market</h1>
        <p>You can add and install the integrations we have provided in this section.</p>
      </div>
      <div className="integration-market__cards">
        {CARD_DATA.map((cardData) => (
          <AppCard
            key={cardData.intname}
            cardIcon={<img src={cardData.imgSrc} className={cardData.imgClassName} />}
            cardName={cardData.cardName}
            cardDescription={cardData.cardDescription}
            intname={cardData.intname}
            setActiveInt={setActiveInt}
            intActive={licenceCheck?.integrations}
            openModalHandler={openModalHandler}
          />
        ))}
      </div>
      {modalActive ? (
        <IntegrationModal closeModalHandler={closeModalHandler} modalType={modalType} />
      ) : null}
      <IntegrationConfigList
        show={listPopupActive}
        setShow={setListPopupActive}
        activeInt={activeInt}
        setActiveInt={setActiveInt}
      />
      <WinshareSettings
        show={winshareConfigActive}
        setShow={setWinshareConfigActive}
        dataTmp={query.get("t")}
        setActiveInt={setActiveInt}
        activeInt={activeInt}
      />
      <RclshareSettings
        show={rclshareConfigActive}
        setShow={setRclshareConfigActive}
        dataTmp={query.get("t")}
        setActiveInt={setActiveInt}
      />
      <ImapemailSettings
        show={imapemailConfigActive}
        setShow={setImapemailConfigActive}
        dataTmp={query.get("t")}
        setActiveInt={setActiveInt}
      />
      <CstrikeSettings
        show={cstrikeConfigActive}
        setShow={setCstrikeConfigActive}
        dataTmp={query.get("t")}
        setActiveInt={setActiveInt}
      />
      <PostfixemailSettings
        show={postfixemailConfigActive}
        setShow={setPostfixemailConfigActive}
        dataTmp={query.get("t")}
        setActiveInt={setActiveInt}
      />
    </div>
  );
};
export default IntegrationMarket;
