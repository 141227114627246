import React from "react";

const DownloadIcon = ({ color = "var(--text-color-card)", width = 14, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 14.25H14.25C14.4489 14.25 14.6397 14.329 14.7803 14.4697C14.921 14.6103 15 14.8011 15 15C15 15.1989 14.921 15.3897 14.7803 15.5303C14.6397 15.671 14.4489 15.75 14.25 15.75H3.75C3.55109 15.75 3.36032 15.671 3.21967 15.5303C3.07902 15.3897 3 15.1989 3 15C3 14.8011 3.07902 14.6103 3.21967 14.4697C3.36032 14.329 3.55109 14.25 3.75 14.25ZM9.75 9.88125L12.1823 7.44975L13.2428 8.51025L9 12.753L4.75725 8.51025L5.81775 7.44975L8.25 9.88125V1.5H9.75V9.88125Z"
        fill={color}
      />
    </svg>
  );
};

export default DownloadIcon;
