import * as React from "react";

const CredentialsIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".crd{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path
        className="crd"
        d="M778.3 609.2H442.1c-8 0-14.5-6.5-14.5-14.5v-38.2c0-8 6.5-14.5 14.5-14.5h336.2c8 0 14.5 6.5 14.5 14.5v38.2c0 8-6.5 14.5-14.5 14.5z"
      />
      <path
        className="crd1"
        d="M615 841.3H442.1c-8 0-14.5-6.5-14.5-14.5v-38.2c0-8 6.5-14.5 14.5-14.5H615c8 0 14.5 6.5 14.5 14.5v38.2c-.1 8-6.5 14.5-14.5 14.5z"
        fill={color}
      />
      <circle className="crd" cx={318.8} cy={585.2} r={42.2} />
      <path
        className="crd"
        d="M269 651.2h99.5c14.5 0 26.2 11.7 26.2 26.2v15.8c0 21.1-17.1 38.2-38.2 38.2h-75.4c-21.1 0-38.2-17.1-38.2-38.2v-15.8c0-14.4 11.7-26.2 26.1-26.2zM778.3 658.2H442.1c-8 0-14.5 6.5-14.5 14.5v38.2c0 8 6.5 14.5 14.5 14.5h336.2c8 0 14.5-6.5 14.5-14.5v-38.2c0-8.1-6.5-14.5-14.5-14.5zm-317.6 46.5c-7.4 0-13.4-6-13.4-13.4s6-13.4 13.4-13.4 13.4 6 13.4 13.4-6 13.4-13.4 13.4zm41.2 0c-7.4 0-13.4-6-13.4-13.4s6-13.4 13.4-13.4 13.4 6 13.4 13.4-6 13.4-13.4 13.4zm41.2 0c-7.4 0-13.4-6-13.4-13.4s6-13.4 13.4-13.4 13.4 6 13.4 13.4-6 13.4-13.4 13.4zm41.3 0c-7.4 0-13.4-6-13.4-13.4s6-13.4 13.4-13.4 13.4 6 13.4 13.4-6.1 13.4-13.4 13.4zm41.2 0c-7.4 0-13.4-6-13.4-13.4s6-13.4 13.4-13.4 13.4 6 13.4 13.4-6 13.4-13.4 13.4z"
      />
      <path
        className="crd1"
        d="M816 289.2H653.7c-1.3 15.1-5.9 26.5-6.8 28.7l-7.5 17.3c2.6.7 5.8 1.1 9.3 1.1 11.2 0 21.8-3.8 22.7-4.1l32.9-13.2-.2 35.6v40.3l-12.8 6.8c-16.9 8.9-35.1 13.6-52.7 13.6-26.2 0-49.9-10.1-66.9-28.5-18.4-20-27.1-48.2-24.6-79.5.5-6.6.8-12.6.9-18H208c-36.8 0-66.7 29.8-66.7 66.7v553.5c0 36.8 29.8 66.7 66.7 66.7h608c36.8 0 66.7-29.8 66.7-66.7V355.8c0-36.8-29.8-66.6-66.7-66.6zm-416.7 48.2c15.6 0 28.3 12.7 28.3 28.3S414.9 394 399.3 394c-15.6 0-28.3-12.7-28.3-28.3s12.6-28.3 28.3-28.3zm-92.1 0c15.6 0 28.3 12.7 28.3 28.3S322.8 394 307.2 394s-28.3-12.7-28.3-28.3 12.7-28.3 28.3-28.3zm-92.1 0c15.6 0 28.3 12.7 28.3 28.3S230.7 394 215.1 394s-28.3-12.7-28.3-28.3 12.7-28.3 28.3-28.3zm629.5 572c0 15.7-12.8 28.5-28.5 28.5H208c-15.7 0-28.5-12.8-28.5-28.5V439h665.2v470.4z"
        fill={color}
      />
      <path
        className="crd"
        d="M556.5 225.5s20.1 15.5 14.5 83.6c-5.6 68.1 53.3 100.6 109 71.2v-25.8s-33 12.9-56.3 0c-23.2-12.9-16.5-70.7-16.5-70.7l17.5 24.5c.1 0 33.1-76.6-68.2-82.8zM706.7 256.1c1.1 4.4 1.9 9 2.2 13.5h39.9c0-1.7.1-3.4.1-5 0-32.1-11.2-52.3-41.4-77-30.2-24.8-20.9-37.5-20.9-37.5h-50.7s-1.2 20.9 10.5 32.5c11.6 11.5 49.9 32.4 60.3 73.5z"
      />
      <path
        className="crd"
        d="M661.4 48c-30.4 0-55 24.6-55 55s24.6 55 55 55 55-24.6 55-55-24.6-55-55-55zm0 88c-18.3 0-33.1-14.8-33.1-33.1s14.8-33.1 33.1-33.1 33.1 14.8 33.1 33.1-14.8 33.1-33.1 33.1z"
      />
      <path
        className="crd"
        d="M661.4 102.9c-7.3 0-13.2-5.9-13.2-13.2V2.8h26.3v86.9c.1 7.3-5.8 13.2-13.1 13.2z"
      />
    </g>
  </svg>
);

export default CredentialsIcon;
