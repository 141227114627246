import * as React from "react"

const Raw = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="m154.172 259.95-.273.011c-1.399 5.853-2.801 13.31-4.339 18.995l-5.574 20.795 20.916.096-5.939-20.953c-1.684-5.828-3.371-13.13-4.791-18.944zM69.145 260.578c-4.735.092-7.423.554-8.788.861v25.817l7.632-.031c9.772-.027 15.609-5.386 15.609-13.686.001-8.714-5.445-12.972-14.453-12.961z" fill={color} />
    <path  d="M357.4 535.335c.767.042 1.543.115 2.33.115h177.39c20.745 0 37.627-16.883 37.627-37.628V86.604c0-20.743-16.882-37.628-37.627-37.628H359.73c-.782 0-1.563.069-2.33.113V0L11.17 46.207v492.31l346.23 47.401v-50.583zm2.33-464.856h177.39c8.892 0 16.125 7.233 16.125 16.125v411.218c0 8.893-7.233 16.127-16.125 16.127H359.73c-.792 0-1.563-.127-2.33-.243V326.397l73.911-62.804-72.546-12.293s-.566 1.414-1.364 3.918V70.709c.766-.107 1.532-.23 2.329-.23zM86.342 339.951c-1.431-2.693-3.517-10.477-6.113-22.173-2.333-11.801-6.079-15.046-14.034-15.224l-5.837-.031v36.778l-18.993-.483v-91.331c6.11-1.267 15.31-2.338 25.596-2.601 12.875-.339 21.998 1.533 28.308 6.751 5.289 4.42 8.21 11.046 8.21 19.857 0 12.213-8.087 20.591-15.699 23.552v.421c6.16 2.708 9.596 9.086 11.842 17.9 2.785 10.813 5.586 23.38 7.315 27.117l-20.595-.533zm89.133 2.273-7.434-25.611-27.108-.335-6.606 24.912-21.491-.561 28.097-96.978 28.108-.732 30.258 99.916-23.824-.611zm126.943 3.24-26.551-.672-9.01-45.062c-2.037-10.501-3.756-20.215-5.019-32.079h-.318c-1.882 11.73-3.59 21.588-6.092 32.048l-9.91 44.311-25.4-.651-23.738-101.144 24.2-.627 7.688 41.953c2.278 12.127 4.407 25.355 6.079 35.707h.317c1.672-11.086 4.108-23.402 6.73-36.083l8.767-42.347 25.41-.656 8.601 44.045c2.414 12.32 4.186 23.575 5.795 35.303l.318.011c1.606-11.718 4.031-24.074 6.297-36.624l8.956-43.515 25.647-.671-28.767 106.753z" fill={color} />
    <path  d="M546.105 290.644s-.903-35.478-29.181-69.408l-42.095 87.692 71.276-18.284zM360.402 333.999s16.368 31.485 56.663 49.602l-.599-97.285-56.064 47.683zM508.32 213.629s-28.514-21.137-72.661-19.748l43.176 87.171 29.485-67.423zM428.141 386.651s34.89 6.498 73.966-14.111l-77.02-59.413 3.054 73.524zM424.352 195.995s-34.206 9.459-60.284 45.114l95.266 19.632-34.982-64.746zM511.344 365.694s26.595-23.497 34.406-66.972l-94.227 24.115 59.821 42.857z" fill={color} />
  </svg>
)

export default Raw
