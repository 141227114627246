import * as React from "react"

const Nes = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M11.176 46.206v492.311l346.22 47.401v-50.583c.776.053 1.542.115 2.329.115h177.39c20.756 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.871-37.628-37.627-37.628h-177.39c-.787 0-1.553.076-2.329.123V0L11.176 46.206zm123.537 311.005-23.748-1.076-20.696-41.055c-5.651-11.161-11.798-24.52-16.378-36.555l-.409.145c.559 13.575.827 28.059.827 44.846v30.966L55.2 353.61V252.244l24.389-.732 19.8 37.77c5.764 11.069 11.57 24.244 15.971 36.26l.431.011c-1.457-14.006-1.903-28.299-1.903-44.147V250.48l20.819-.63v107.36h.006zm90.145 4.074-69.956-3.171v-108.87l67.568-2.032v21.138l-43.431.697v22.999l40.879-.021v20.929l-40.879-.551v26.279l45.814 1.428v21.176h.005zm47.293 3.885c-13.838-.631-27.264-4.688-33.894-8.572l5.41-21.381c7.192 3.811 18.394 7.796 30.124 8.173 12.819.42 19.682-4.498 19.682-12.289 0-7.422-5.934-11.769-20.785-17.085-20.06-7.009-32.861-17.729-32.861-34.479 0-19.674 16.885-35.257 45.681-36.155 14.144-.441 24.728 2.089 32.376 5.105l-6.5 22.189c-5.129-2.252-14.174-5.486-26.425-5.265-12.083.207-17.867 5.525-17.867 11.619 0 7.488 6.864 10.767 22.856 16.549 22.396 7.899 33.147 19.145 33.147 36.263.001 20.378-16.656 36.918-50.944 35.328zm87.575-294.69h177.39c8.893 0 16.125 7.236 16.125 16.127v411.215c0 8.893-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.232V70.722c.76-.118 1.532-.242 2.329-.242z" fill={color} />
    <path  d="M490.72 256.861h-81.975v11.202h-16.797v3.58h-8.851v3.14h8.851v4.032h16.797v19.708h-16.797v4.032h-8.851v3.139h8.851v3.58h16.797v21.051h-16.797v3.811h-8.851v3.139h8.851v3.802h16.797v15.233h81.975v-15.233h15.224v-3.802h8.85v-3.139h-8.85v-3.811H490.72v-21.051h15.224v-3.58h8.85v-3.139h-8.85v-4.032H490.72v-19.708h15.224v-4.032h8.85v-3.14h-8.85v-3.58H490.72v-11.202zm-15.675 26.877c-3.465 0-6.277-2.812-6.277-6.278s2.812-6.268 6.277-6.268c3.466 0 6.269 2.801 6.269 6.268s-2.803 6.278-6.269 6.278z" fill={color} />
    <path  d="m536.201 221.942-35.79-41.583H361.689v235.615h174.512V221.942zm-32.095-22.657 16.903 19.642h-16.903v-19.642zm22.552 207.134H371.232V189.91h123.314v38.576h32.111v177.933z" fill={color} />
  </svg>
)

export default Nes
