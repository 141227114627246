import React from "react";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import XlsxIcon from "../Components/Icon-SVG/XLSXIcon";

const ExportXLSX = ({ csvData, fileName, selected, columns }) => {
  // const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

  const exportToCSV = async (csvData, fileName) => {
    let newCsvData = csvData;
    if (selected) {
      newCsvData = newCsvData.map((csv) => csv.original);
    }

    const workbook = new ExcelJS.Workbook(); // Create a new workbook
    const worksheet = workbook.addWorksheet("Sheet 1"); // New Worksheet

    worksheet.columns = columns.map(({ Header, accessor }) => ({ header: Header, key: accessor }));

    const dataset = newCsvData.map((data) =>
      columns.map(({ accessor }) => {
        const keys = accessor.split(".");
        if (keys.length > 1) {
          let value = data;
          for (const key of keys) {
            value = value[key];
          }
          return value;
        } else {
          return data[accessor];
        }
      }),
    );

    worksheet.addRows(dataset);

    // const multiDataSet = [
    //   {
    //     columns: columns.map(({ Header }) => Header),
    //     data: dataset,
    //   },
    // ];

    // const excelData = [
    //   {
    //     sheetData: multiDataSet,
    //     sheetName: "Sheet 1",
    //   },
    // ];

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileNameWithExtension = fileName + ".xlsx";

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileNameWithExtension);
    // const exporter = new ExcelFile(excelData);
    // exporter.saveAs(fileNameWithExtension);
  };

  return (
    <button
      style={{ background: "none", border: "none", outline: "none", cursor: "pointer" }}
      onClick={() => exportToCSV(csvData, fileName)}>
      <XlsxIcon />
    </button>
  );
};

export default ExportXLSX;
