import React, { useState } from "react";
import TrashBucketIcon from "../../../../Icon-SVG/TrashBucketIcon";
import EditIcon from "../../../../Icon-SVG/EditIcon";

const IntegrationAction = (props) => {
  const [editHover, setEditHover] = useState(false);
  const [deleteHover, setDeleteHover] = useState(false);

  return (
    <div style={{ width: "auto" }}>
      <div style={{ display: "flex", float: "right", marginRight: "10px", pointerEvents: "all" }}>
        {/* <span
          style={{ zIndex: "10000000000" }}
          title="Edit"
          onMouseOver={() => setEditHover(true)}
          onMouseOut={() => setEditHover(false)}
        >
          <EditIcon width={18} height={18} color={editHover ? "white" : "var(--color-blue)"} />
        </span> */}
        <span
          style={{ float: "right", zIndex: "10000000000" }}
          title="Delete"
          onMouseOver={() => setDeleteHover(true)}
          onMouseOut={() => setDeleteHover(false)}>
          <TrashBucketIcon width={18} height={18} color={deleteHover ? "white" : "red"} />
        </span>
      </div>
    </div>
  );
};

export default IntegrationAction;
