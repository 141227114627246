import * as React from "react";

const SpreadingIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>
      {".spr1{fill:none;stroke:var(--header-search-text-color);stroke-width:21;stroke-miterlimit:10}.spr2{fill:var(--header-search-text-color)}"}
    </style>
    <g id="Layer_2">
      <path
        className="spr"
        d="M369.9 891.1H157.1l24.5-71.9h163.8zM462.5 562.1v127.2h-398V445.6h220.2v-29H72.6C53 416.6 37 432.5 37 452.2v300.1c0 19.6 15.9 35.6 35.6 35.6h381.9c19.6 0 35.6-15.9 35.6-35.6V562.1h-27.6zm-199 196.5c-10.7 0-19.3-8.7-19.3-19.3s8.7-19.3 19.3-19.3c10.7 0 19.3 8.7 19.3 19.3s-8.6 19.3-19.3 19.3z"
        fill={color}
      />
      <path
        className="spr"
        d="M454.4 416.6H242.3l21.4 29h198.8v243.7l27.5 63V452.2c0-19.7-15.9-35.6-35.6-35.6zM987 863.5H506l55.8-34.8h369.4zM789.7 863.5c0 6.2-5 11.2-11.2 11.2h-63.1c-6.2 0-11.2-5-11.2-11.2H506v10.2c0 9.6 7.8 17.4 17.4 17.4h446.1c9.6 0 17.4-7.8 17.4-17.4v-10.2H789.7zM886.8 554.5H606.1c-24.5 0-44.4 19.9-44.4 44.4v214h28.9v-214c0-8.5 6.9-15.5 15.5-15.5h280.8c8.5 0 15.5 6.9 15.5 15.5v214h28.9v-214c-.1-24.5-20-44.4-44.5-44.4z"
        fill={color}
      />
      <path className="spr1" d="M619.3 255.4h127.2v205" />
      <path className="spr2" d="m708.8 449.4 37.7 65.3 37.7-65.3z" />
      <path className="spr1" d="M263.5 381.5V255.4h124.1" />
      <path
        className="spr2"
        d="M505.5 132.9c-67.7 0-122.5 54.9-122.5 122.5 0 67.7 54.9 122.5 122.5 122.5 67.7 0 122.5-54.9 122.5-122.5 0-67.7-54.8-122.5-122.5-122.5zm94.1 161.5L580 274.8h-19.3c-1.2 4.1-2.9 7.9-4.9 11.6l14.9 14.9V339H559v-32.8l-10.1-10.1c-10.6 12.1-26.1 19.8-43.5 19.8s-32.9-7.7-43.5-19.8l-10.1 10.1V339h-11.7v-37.6l14.9-14.9c-2-3.6-3.7-7.5-4.9-11.6H431l-19.6 19.6-8.3-8.3 23-23h22c-.1-1.6-.2-3.2-.2-4.8 0-4.3.5-8.5 1.4-12.5h-23.1l-23-23 8.3-8.3 19.6 19.6h22.2c1.7-3.7 3.8-7.2 6.3-10.5l-19.3-19.3v-32.6H452v27.7l15.5 15.5c5.1-4.5 11-8.1 17.4-10.5.2-11.2 9.3-20.2 20.6-20.2 11.2 0 20.4 9 20.6 20.2 6.4 2.5 12.3 6.1 17.4 10.5l15.5-15.5v-27.7h11.7v32.6l-19.3 19.3c2.5 3.2 4.6 6.8 6.3 10.5H580l19.6-19.6 8.3 8.3-23 23h-23.1c.9 4 1.4 8.2 1.4 12.5 0 1.6-.1 3.2-.2 4.8h22l23 23-8.4 8.2z"
      />
      <circle className="spr" cx={258.5} cy={569.5} r={59.7} fill={color} />
      <circle className="spr" cx={258.5} cy={514.2} r={21.4} fill={color} />
      <path
        className="spr"
        d="m218.5 541.2-27.6-27.6v-33.8H203v28.8l24 24zM203 653.2h-12.1v-39l23.9-23.9 8.5 8.5-20.3 20.4zM215.3 556.6h-39l-23.9-23.9 8.5-8.6 20.4 20.4h34zM160.9 607l-8.5-8.6 23.9-23.9h39v12.2h-34zM298.6 541.2l-8.5-8.6 24-24v-28.8h12.1v33.8zM326.2 653.2h-12.1v-34l-20.3-20.4 8.5-8.5 23.9 23.9zM340.8 556.6h-39v-12.1h34l20.4-20.4 8.5 8.6zM356.2 607l-20.4-20.3h-34v-12.2h39l23.9 23.9z"
        fill={color}
      />
      <circle className="spr" cx={746.5} cy={711.5} r={59.7} fill={color} />
      <circle className="spr" cx={746.5} cy={656.1} r={21.4} fill={color} />
      <path
        className="spr"
        d="m706.4 683.1-27.6-27.6v-33.8h12.1v28.8l24.1 24.1zM690.9 795.1h-12.1v-39l23.9-23.9 8.6 8.6-20.4 20.3zM703.2 698.5h-39l-23.9-23.9 8.6-8.5 20.3 20.3h34zM648.9 748.9l-8.6-8.5 23.9-23.9h39v12.1h-34zM786.6 683.1l-8.6-8.5 24.1-24.1v-28.8h12.1v33.8zM814.2 795.1h-12.1v-34l-20.4-20.3 8.6-8.6 23.9 23.9zM828.8 698.5h-39.1v-12.1h34.1l20.3-20.3 8.6 8.5zM844.1 748.9l-20.3-20.3h-34.1v-12.1h39.1l23.9 23.9z"
        fill={color}
      />
    </g>
  </svg>
);

export default SpreadingIcon;
