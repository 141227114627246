import * as React from "react"

const Mdb = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M297.042 273.691c0-7.968-6.431-12.105-17.674-11.96-5.312.077-8.358.417-10.433.757v24.084l8.01.032c13.018.044 20.097-5.087 20.097-12.913zM277.272 303.417l-8.337-.132v29.152c2.394.39 5.281.495 9.468.643 11.225.396 21.292-3.312 21.292-14.438.005-10.652-10.067-15.034-22.423-15.225zM175.701 263.952c-4.725.058-7.771.527-9.563.976v62.859c1.793.501 4.696.601 7.333.696 19.473.822 32.492-9.867 32.492-33.497.139-20.548-11.732-31.267-30.262-31.034z" fill={color} />
    <path  d="M11.228 46.417v494.564L359.031 588.6v-50.814c.781.053 1.551.115 2.342.115h178.2c20.841 0 37.8-16.964 37.8-37.8V86.999c0-20.838-16.959-37.8-37.8-37.8h-178.2c-.791 0-1.561.076-2.342.124V0L11.228 46.417zm99.591 295.175-1.263-35.295c-.374-11.069-.762-24.445-.762-37.821l-.367.005c-2.652 11.729-6.157 24.77-9.402 35.438l-10.109 35.164-14.396-.654-8.559-34.831c-2.586-10.457-5.282-23.09-7.148-34.642h-.233c-.477 11.939-.817 25.576-1.405 36.577l-1.392 33.486-16.158-.765 4.88-87.199 23.583-.567 7.868 29.742c2.521 10.397 5.055 21.631 6.863 32.231l.361.011c2.318-10.399 5.115-22.304 7.81-32.381l8.894-30.375 25.271-.615 4.807 93.393-19.143-.902zm102.471-6.998c-10.391 8.232-25.906 11.597-44.4 10.721-10.831-.521-18.404-1.582-23.501-2.525v-92.91c7.522-1.453 17.442-2.399 28.076-2.663 18.045-.448 30.014 2.618 39.517 9.635 10.404 7.549 17.009 19.859 17.009 37.628.005 19.253-7.053 32.315-16.701 40.114zm101.529 8.011c-8.269 5.795-21.9 8.616-43.648 7.577-11.889-.563-20.648-1.745-25.919-2.742v-99.895c6.193-1.36 18.905-2.586 31.055-2.884 15.125-.372 24.553.793 32.764 5.173 7.952 3.797 13.753 11.011 13.753 20.714 0 9.548-5.97 18.426-18.789 22.75v.316c12.988 3.448 22.755 12.793 22.755 26.673 0 9.735-4.814 17.213-11.971 22.318zm46.554-271.801h178.2c8.933 0 16.2 7.269 16.2 16.2v413.097c0 8.934-7.268 16.2-16.2 16.2h-178.2c-.802 0-1.571-.11-2.342-.232V393.62h40.315c-.817-4.883-1.018-9.851-.532-14.766h-39.783v-28.703h51.358c1.35-1.714 2.779-3.386 4.361-4.968 4.867-4.872 10.684-8.712 16.98-11.443h-72.7v-25.438h95.418V291.89H359.03v-22.573h141.086v24.878a18.803 18.803 0 0 1 5.933-4.016 18.967 18.967 0 0 1 4.52-6.951l4.313-4.324V226.23H359.031V71.046c.771-.118 1.54-.242 2.342-.242z" fill={color} />
    <path  d="m512.024 293.731-.284 8.089h-7.193c-.796 0-1.529.316-2.035.844a2.886 2.886 0 0 0-.844 1.931l-.254 7.546h-6.381c-.791 0-1.519.332-2.03.844a2.828 2.828 0 0 0-.838 1.936l-.232 6.697h-6.528c-1.561.063-2.226-.138-3.021.653l-.195.19c-.411.527-.696 1.582-.681 2.89l-.231 6.939h-7.715c-.771 0-1.498.307-2.036.844l-11.844 11.839c-14.513-5.864-31.356-2.51-42.515 8.654-15.309 15.313-15.309 40.221 0 55.534 15.314 15.319 40.231 15.319 55.545 0 10.404-10.409 14.086-25.824 9.624-39.756l58.33-58.341a5.233 5.233 0 0 0 1.387-2.452l5.584-22.436a5.33 5.33 0 0 0-1.45-5.07 5.314 5.314 0 0 0-5.115-1.295l-21.178 5.917a5.423 5.423 0 0 0-2.31 1.36l-4.704 4.701a2.813 2.813 0 0 0-.856 1.942zm-63.966 110.531c-4.197 4.197-13.689 1.793-20.735-5.253-7.04-7.045-9.455-16.548-5.252-20.735 4.188-4.197 13.689-1.793 20.729 5.253 7.061 7.045 9.456 16.537 5.258 20.735zm89.96-99.605-55.883 55.878-.886-.886 55.883-55.889.886.897zm-5.458-5.463 1.909 1.903-55.889 55.894-1.908-1.914 55.888-55.883z" fill={color} />
  </svg>
)

export default Mdb
