import React from "react";

const CompressedFileCount = (props) => {
  const compressedFileCount = props.row.original.compressedFileCount;
  return (
      <div className="dashboard__table__compressedFileCount">{compressedFileCount}</div>
  );
};

export default CompressedFileCount;
