import AvDefenceIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/AvDefenceIcon";
import BankerIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/BankerIcon";
import BotIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/BotIcon";
import CredentialsIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/CredentialsIcon";
import DiscoveryIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/DiscoveryIcon";
import EncryptionIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/EncryptionIcon";
import ExecutionIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/ExecutionIcon";
import ExploitIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/ExploitIcon";
import GenericIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/GenericIcon";
import InfoStealerIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/InfoStealerIcon";
import InjectionIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/InjectionIcon";
import NetworkIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/NetworkIcon";
import OfficeIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/OfficeIcon";
import OtherIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/OtherIcon";
import RansomwareIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/RansomwareIcon";
import SpreadingIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/SpreadingIcon";
import StaticIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/StaticIcon";
import StealthIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/StealthIcon";
import TrojanIcon from "../../../../../../Components/Icon-SVG/AnalysisIcons/TrojanIcon";
import { loadFromLocalStorage } from "../../../../../../utils/localStorage";

import * as GROUPS from "../../../../../../utils/charts/RadarChart";

import useResizeWindow from "../../../../../../utils/hook/useResizeWindow";

const isThemeDataflow = loadFromLocalStorage("theme") === "dataflowx";

const ICONS = [
  {
    id: "AV Defence",
    group: "avDefenseGroup",
    renderIcon: (width, height) => (
      <AvDefenceIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Banker",
    group: "bankerGroup",
    renderIcon: (width, height) => (
      <BankerIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Bot",
    group: "botGroup",
    renderIcon: (width, height) => (
      <BotIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Credentials",
    group: "credentialsGroup",
    renderIcon: (width, height) => (
      <CredentialsIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Discovery",
    group: "discoveryGroup",
    renderIcon: (width, height) => (
      <DiscoveryIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Encryption",
    group: "encryptionGroup",
    renderIcon: (width, height) => (
      <EncryptionIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Execution",
    group: "executionGroup",
    renderIcon: (width, height) => (
      <ExecutionIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Exploit",
    group: "exploitGroup",
    renderIcon: (width, height) => (
      <ExploitIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Generic",
    group: "genericGroup",
    renderIcon: (width, height) => (
      <GenericIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Infostealer",
    group: "infostealerGroup",
    renderIcon: (width, height) => (
      <InfoStealerIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Injection",
    group: "injectionGroup",
    renderIcon: (width, height) => (
      <InjectionIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Network",
    group: "networkGroup",
    renderIcon: (width, height) => (
      <NetworkIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Office",
    group: "officeGroup",
    renderIcon: (width, height) => (
      <OfficeIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Other",
    group: "otherGroup",
    renderIcon: (width, height) => (
      <OtherIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Ransomware",
    group: "ransomwareGroup",
    renderIcon: (width, height) => (
      <RansomwareIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Spreading",
    group: "spreadingGroup",
    renderIcon: (width, height) => (
      <SpreadingIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Static",
    group: "staticGroup",
    renderIcon: (width, height) => (
      <StaticIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Stealth",
    group: "stealthGroup",
    renderIcon: (width, height) => (
      <StealthIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "Trojan",
    group: "trojanGroup",
    renderIcon: (width, height) => (
      <TrojanIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
];

const checkBelongsToGroup = (group, categories) => {
  return group.some((groupItem) => categories.includes(groupItem));
};

const ICON_WIDTH_LG = 24;
const ICON_HEIGHT_LG = 24;

const ICON_WIDTH_SM = 18;
const ICON_HEIGHT_SM = 18;

const Categories = ({ value }) => {
  const width = useResizeWindow();
  const iconWidth = width > 1350 ? ICON_WIDTH_LG : ICON_WIDTH_SM;
  const iconHeight = width > 1350 ? ICON_HEIGHT_LG : ICON_HEIGHT_SM;

  return (
    <div className="overview__table-block-categories__icons">
      {ICONS.map((icon) =>
        checkBelongsToGroup(GROUPS[icon.group], value) ? (
          <div title={icon.id} key={icon.id}>
            {icon.renderIcon(iconWidth, iconHeight)}
          </div>
        ) : null,
      )}
    </div>
  );
};

export default Categories;
