import { useState } from "react";

import ArrowBottomIcon from "../../../Icon-SVG/ArrowBottomIcon";

const AttackMapFilter = ({
  filterLabel = null,
  onInputChange,
  filterValue,
  filteredCount,
  filterInfo = null,
  checkboxLabel,
  checkboxValue = null,
  onCheckboxChange,
  totalCount,
  styleProps,
  dropdownLabel = null,
  dropdownData,
  dropdownValue,
  onDropdownChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const inputChangeHandler = (e) => {
    onInputChange(e);
  };
  const checkboxChangeHandler = () => {
    onCheckboxChange();
  };
  const dropdownChangeHandler = (e, item) => {
    e.stopPropagation();
    setDropdownOpen(false);
    onDropdownChange(item);
  };
  const dropdownToggleHandler = () => {
    setDropdownOpen((prevValue) => !prevValue);
  };
  return (
    <div className="attack-map__filter-container" style={styleProps}>
      <div className="attack-map__filters">
        {dropdownLabel ? (
          <div className="attack-map__dropdown">
            <label className="attack-map__dropdown-label">{dropdownLabel}</label>
            <div className="attack-map__dropdown-value" onClick={dropdownToggleHandler}>
              {dropdownValue} <ArrowBottomIcon width={12} height={12} />
              {dropdownOpen ? (
                <div className="attack-map__dropdown__options">
                  {dropdownData.map((data) => (
                    <p
                      key={data}
                      className="attack-map__dropdown__option"
                      onClick={(e) => dropdownChangeHandler(e, data)}>
                      {data}
                    </p>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {filterLabel ? (
          <div className="attack-map__filter">
            <label className="attack-map__filter-label">{filterLabel}</label>
            <input
              className="attack-map__filter-input"
              onChange={inputChangeHandler}
              value={filterValue}
            />
          </div>
        ) : null}
        {checkboxValue !== null ? (
          <div className="attack-map__checkbox">
            <label className="attack-map__checkbox-label">{checkboxLabel}</label>
            <input
              className="attack-map__checkbox-input"
              type="checkbox"
              checked={checkboxValue}
              onChange={checkboxChangeHandler}
            />
          </div>
        ) : null}
      </div>
      <p className="attack-map__filter-info">
        {filterInfo
          ? filterInfo
          : `Showing ${filteredCount} ${
              filteredCount <= 1 ? "entry" : "entries"
            } (filtered from ${totalCount} total entries)`}
      </p>
    </div>
  );
};

export default AttackMapFilter;
