import * as React from "react"

const Uue = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.335c.776.052 1.542.114 2.329.114h177.39c20.756 0 37.627-16.883 37.627-37.627V86.604c0-20.746-16.871-37.628-37.627-37.628h-177.39c-.787 0-1.553.074-2.329.124V0L11.176 46.208v492.308l346.22 47.402v-50.583zm0-464.615c.766-.113 1.532-.241 2.329-.241h56.127v3.884h32.604v-3.884h88.651c8.889 0 16.127 7.232 16.127 16.125v411.218c0 8.892-7.238 16.127-16.127 16.127H359.721c-.792 0-1.563-.117-2.335-.231V70.72h.01zM109.97 302.597c0 33.196-15.422 48.378-40.473 47.359-23.297-.944-36.588-15.597-36.588-47.879v-56.471l20.462-.892v59.237c0 17.712 6.016 26.887 16.821 27.159 11.286.289 17.575-8.347 17.575-27.008v-60.888l22.208-.968v60.35h-.005zm110.033.315c0 36.021-18.132 52.432-47.508 51.244-27.221-1.111-42.728-16.986-42.728-51.837v-60.93l23.872-1.04v64.021c0 19.14 7.024 29.062 19.662 29.387 13.218.341 20.599-8.998 20.599-29.229v-65.941l26.098-1.146v65.47h.005zm105.781 55.422-82.274-3.233V236.42l79.443-3.468v23.224l-51.212 1.451v25.146l48.195-.539v23.003l-48.195-.2v28.725l54.043 1.291v23.281z" fill={color} />
    <path  d="M448.457 82.518h32.604v13.448h-32.604zM415.853 102.66h32.604v13.448h-32.604zM448.457 124.684h32.604v13.459h-32.604zM449.532 164.503h32.61v13.46h-32.61zM415.853 145.587h32.604v13.46h-32.604zM416.925 190.616h32.603v13.45h-32.603zM449.532 210.743h32.61v13.459h-32.61zM416.925 232.783h32.603v13.459h-32.603zM448.457 257.494c-16.016 0-23.627 12.974-23.627 28.998l-5.365 48.588c0 16.022 12.977 29.01 28.998 29.01s28.997-12.987 28.997-29.01l-5.354-48.588c-.001-16.024-7.623-28.998-23.649-28.998zm9.606 93.86h-19.197v-40.568h19.197v40.568z" fill={color} />
  </svg>
)

export default Uue
