const QueueIcon = ({ width = 22, height = 22, color = "var(--text-color-card)" }) => {
  return (
    <svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}>
      <g>
        <g>
          <path
            d="M27,3H5C4.4477539,3,4,2.5527344,4,2s0.4477539-1,1-1h22c0.5522461,0,1,0.4472656,1,1S27.5522461,3,27,3z"
            fill={color}
          />
        </g>
        <g>
          <path
            d="M27,31H5c-0.5522461,0-1-0.4472656-1-1s0.4477539-1,1-1h22c0.5522461,0,1,0.4472656,1,1S27.5522461,31,27,31z"
            fill={color}
          />
        </g>
        <g>
          <path
            d="M24.3999023,31H7.6000977c-0.5507813,0-0.9980469-0.4453125-1-0.9970703    C6.5996094,29.8125,6.6049805,25.2753906,8.3828125,21.4013672C9.5849609,18.78125,10.9506836,16.9355469,11.7231445,16    c-0.7724609-0.9355469-2.1381836-2.78125-3.340332-5.4013672C6.6049805,6.7246094,6.5996094,2.1875,6.6000977,1.9970703    C6.6020508,1.4453125,7.0493164,1,7.6000977,1h16.7998047c0.5507813,0,0.9980469,0.4453125,1,0.9970703    c0.0004883,0.1904297-0.0048828,4.7275391-1.7827148,8.6015625C22.4150391,13.21875,21.0493164,15.0644531,20.2768555,16    c0.7724609,0.9355469,2.1381836,2.78125,3.340332,5.4013672c1.777832,3.8740234,1.7832031,8.4111328,1.7827148,8.6015625    C25.3979492,30.5546875,24.9506836,31,24.3999023,31z M8.6420898,29h14.7158203    c-0.0996094-1.4716797-0.4345703-4.3154297-1.5581055-6.7646484c-1.6044922-3.4960938-3.550293-5.5166016-3.5693359-5.5371094    c-0.3779297-0.3876953-0.3779297-1.0087891,0-1.3964844c0.019043-0.0205078,1.9648438-2.0410156,3.5693359-5.5371094    C22.9233398,7.3154297,23.2583008,4.4716797,23.3579102,3H8.6420898c0.0996094,1.4716797,0.4345703,4.3154297,1.5581055,6.7646484    c1.6044922,3.4960938,3.550293,5.5166016,3.5693359,5.5371094c0.3779297,0.3876953,0.3779297,1.0087891,0,1.3964844    c-0.019043,0.0205078-1.9648438,2.0410156-3.5693359,5.5371094C9.0766602,24.6845703,8.7416992,27.5283203,8.6420898,29z"
            fill={color}
          />
        </g>
        <g>
          <path
            d="M11.2239609,26.980032c0,0-0.0082922-2.5372696,0.9618406-4.6516628S14.7377195,19.019968,16,19.019968    s2.8440647,1.1940098,3.8141975,3.3084011s0.9618416,4.6516628,0.9618416,4.6516628H11.2239609z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default QueueIcon;
