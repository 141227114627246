import React from "react";

const LogoutIcon = ({ color = "var(--border)", width, height, padding }) => {
  return (
    <div style={{ padding }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none">
        <path
          d="M14.2582 11.8672H13.0225C12.9381 11.8672 12.859 11.9041 12.8063 11.9692C12.6832 12.1186 12.5514 12.2627 12.4125 12.3998C11.8445 12.9684 11.1718 13.4214 10.4314 13.734C9.66443 14.058 8.84004 14.2242 8.00742 14.2227C7.16543 14.2227 6.3498 14.0574 5.5834 13.734C4.84306 13.4214 4.17031 12.9684 3.60234 12.3998C3.03337 11.8332 2.57968 11.1616 2.26641 10.4223C1.94121 9.65589 1.77773 8.84202 1.77773 8.00003C1.77773 7.15803 1.94297 6.34417 2.26641 5.57776C2.5793 4.83772 3.0293 4.17151 3.60234 3.60022C4.17539 3.02893 4.8416 2.57893 5.5834 2.26604C6.3498 1.9426 7.16543 1.77737 8.00742 1.77737C8.84941 1.77737 9.66504 1.94085 10.4314 2.26604C11.1732 2.57893 11.8395 3.02893 12.4125 3.60022C12.5514 3.73909 12.6814 3.88323 12.8063 4.03089C12.859 4.09593 12.9398 4.13284 13.0225 4.13284H14.2582C14.3689 4.13284 14.4375 4.00979 14.376 3.91663C13.0277 1.82132 10.6687 0.434402 7.98809 0.441433C3.77637 0.45198 0.39961 3.87093 0.441797 8.07737C0.483985 12.217 3.85547 15.5586 8.00742 15.5586C10.6811 15.5586 13.0295 14.1735 14.376 12.0834C14.4357 11.9903 14.3689 11.8672 14.2582 11.8672ZM15.8209 7.88929L13.3266 5.92054C13.2334 5.84671 13.098 5.9135 13.098 6.03128V7.36721H7.57852C7.50117 7.36721 7.43789 7.4305 7.43789 7.50784V8.49221C7.43789 8.56956 7.50117 8.63284 7.57852 8.63284H13.098V9.96878C13.098 10.0866 13.2352 10.1533 13.3266 10.0795L15.8209 8.11077C15.8377 8.09761 15.8513 8.08081 15.8606 8.06162C15.87 8.04243 15.8749 8.02137 15.8749 8.00003C15.8749 7.97868 15.87 7.95762 15.8606 7.93843C15.8513 7.91925 15.8377 7.90244 15.8209 7.88929Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default LogoutIcon;
