import React from "react";
import Tooltip from "../../../../Components/common/Tooltip/Tooltip";

const Field = ({
  width,
  value,
  onChange,
  tooltipText,
  label,
  className,
  parentClassName,
  placeholder,
  tooltipWidth,
  type = "text",
}) => {
  return (
    <div className={parentClassName}>
      <p className="settings__label">
        {label}
        {tooltipText && <Tooltip tooltipWidth={tooltipWidth} text={tooltipText} />}
      </p>
      <input
        className={`settings__field ${className}`}
        value={value}
        onChange={onChange}
        style={{ width }}
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Field;
