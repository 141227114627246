import React from "react";
import "./styles.scss";

const Card = ({ name, mail, about, profileImg, iconBackground }) => {
  return (
    <div className="user__card">
      <div className="user__card-inner">
        <div className="user__card-inner__img" style={{ background: iconBackground }}>
          {profileImg}
        </div>
        <div className="user__card-inner__text">
          <p className="user__card-inner__text-name">{name}</p>
          <p className="user__card-inner__text-mail">{mail}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
