import * as React from "react";

const PublicApiIcon = ({ color = "var(--text-color-card)", width = 15, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path d="M2079 4746 c-83 -18 -183 -58 -263 -105 -80 -47 -212 -180 -256 -258 l-35 -62 -55 7 c-30 4 -111 7 -180 6 -268 -2 -482 -95 -671 -291 -137 -142 -220 -295 -271 -501 -29 -113 -31 -350 -5 -454 74 -296 254 -518 536 -659 112 -55 235 -88 381 -100 80 -6 681 -9 1675 -7 l1550 3 58 23 c162 66 252 199 252 372 0 74 -4 93 -32 151 -36 78 -89 140 -150 176 -28 16 -45 34 -49 51 -23 104 -62 180 -127 249 -54 57 -162 119 -227 129 l-37 6 5 127 c5 143 -12 230 -69 345 -184 370 -658 498 -1005 270 -37 -24 -68 -43 -69 -42 -2 2 -17 37 -34 79 -97 235 -284 400 -531 469 -97 28 -296 35 -391 16z m139 -1191 c30 -16 67 -46 83 -66 57 -72 59 -90 59 -454 0 -310 -1 -334 -19 -356 -25 -31 -83 -32 -114 -1 -21 21 -22 32 -25 192 l-3 170 -160 0 -159 0 0 -166 0 -166 -30 -30 c-26 -25 -36 -29 -63 -24 -67 13 -67 11 -67 386 l0 338 33 63 c23 45 45 71 81 96 67 46 96 53 221 50 101 -2 115 -5 163 -32z m748 8 c204 -109 204 -388 0 -496 -35 -19 -61 -22 -183 -25 l-143 -4 0 -165 0 -165 -29 -29 c-16 -16 -37 -29 -46 -29 -24 0 -72 27 -79 45 -3 9 -6 203 -6 431 l0 415 25 25 26 25 197 -3 c181 -3 200 -5 238 -25z m409 -8 l25 -24 0 -415 0 -415 -23 -27 c-17 -20 -33 -27 -57 -27 -24 0 -40 7 -57 27 l-23 27 0 415 0 415 25 24 c15 16 36 25 55 25 19 0 40 -9 55 -25z" />
      <path d="M1932 3410 c-41 -25 -52 -54 -52 -141 l0 -69 160 0 160 0 0 78 c0 86 -11 112 -60 137 -44 23 -167 20 -208 -5z" />
      <path d="M2640 3315 l0 -115 115 0 c158 0 205 26 205 115 0 89 -47 115 -205 115 l-115 0 0 -115z" />
      <path d="M880 2182 c0 -106 11 -116 143 -123 86 -4 114 -10 160 -33 102 -50 161 -143 161 -256 0 -88 -33 -159 -113 -240 -52 -53 -61 -67 -61 -98 0 -33 14 -50 198 -234 238 -238 226 -233 333 -132 90 84 142 108 234 108 117 0 210 -57 261 -159 21 -44 27 -75 33 -159 9 -157 -15 -146 328 -146 l284 0 24 25 c23 22 25 32 25 108 0 109 20 172 71 231 62 70 127 100 219 100 95 1 144 -21 240 -110 50 -46 78 -64 97 -64 22 0 66 39 230 203 164 164 203 208 203 230 0 19 -19 47 -67 99 -87 96 -108 141 -107 238 0 86 28 152 86 206 62 59 112 77 231 83 99 5 111 8 128 30 10 13 19 34 19 47 l0 24 -436 -2 -436 -3 -32 -81 c-42 -103 -93 -183 -170 -262 -104 -108 -218 -178 -361 -221 -209 -63 -480 -34 -663 71 -169 96 -304 255 -368 433 l-22 60 -268 7 c-323 7 -442 24 -586 83 -16 6 -18 0 -18 -63z" />
      <path d="M1064 1019 l-131 -131 -47 16 c-97 33 -201 11 -276 -60 -67 -63 -93 -127 -88 -218 4 -81 26 -133 75 -182 158 -159 431 -76 473 144 9 48 4 100 -15 167 -5 16 22 49 121 153 l127 134 -54 54 -54 54 -131 -131z" />
      <path d="M3867 1093 l-57 -58 131 -131 130 -131 -17 -52 c-44 -136 38 -299 174 -345 101 -35 217 -8 289 66 65 68 78 100 78 198 0 75 -3 91 -27 132 -72 121 -208 175 -334 132 l-47 -16 -131 131 -131 131 -58 -57z" />
    </g>
  </svg>
);
export default PublicApiIcon;
