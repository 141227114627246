import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Button } from "@mui/material";
import FolderIcon from "../../Icon-SVG/FolderIcon";
import StorageIcon from "../../Icon-SVG/StorageIcon";
import FileTypeIcon from "../../Icon-SVG/FileTypeIcon";
import Warning from "../../common/Warning/Warning";
import { logoutRequest, timeoutCheckRequest } from "../../../store/actions/usersActions";
import "./styles.scss";

const DragAndDrop = ({ selectedFiles = null, setSelectedFiles }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const users = useSelector((state) => state?.users.user);
  const fileListRef = useRef(null);

  const onUploadFiles = (files) => {
    const maxSize = 1024 * 1024 * 1024; // 1024 MB
    if (files.size > maxSize) {
      setError("File size is too big");
      setShow(true);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        setError("File size is bigger than 1024 MB");
        setShow(true);
        return;
      }
      // console.log(files[i].size);
    }

    const fileList = Array.from(files);

    const newFiles = fileList.map((file) => ({
      file: file,
      fileType: file.name.split(".").pop(),
      type: ["high_evasion"],
      command_line: [""],
      enviromental_variable: [""],
      related_password: [""],
      runtime_duration: [120],
      enforse_runtime: [false],
      enforse_submission: [false],
      memory_dump: ["advanced"],
      network_traffic: ["simulate"],
      win7: true,
      win10: false,
      action_script: ["default"],
      machine: ["win7"],
    }));

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDrop = (e) => {
    dispatch(timeoutCheckRequest());
    if (!users.username) {
      dispatch(logoutRequest("timeout"));
    }
    e.preventDefault();
    if (!e.dataTransfer) return;
    const files = e.dataTransfer.files;
    onUploadFiles(files);
  };

  const browseFiles = (e) => {
    dispatch(timeoutCheckRequest());
    if (!users.username) {
      dispatch(logoutRequest("timeout"));
    }
    if (!e.target.files) return;
    const files = e.target.files;
    onUploadFiles(files);
  };

  const removeFile = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  return (
    <Grid container className="drag-and-drop">
      <div className="drag-and-drop__main">
        <Grid item xs={12} md={12} style={{ height: "100%" }}>
          <Paper
            elevation={4}
            style={{
              background: "transparent",
              boxShadow: "0 0 0 0 rgba(1, 1, 1, 0)",
              height: "100%",
            }}>
            <div
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(e)}
              className="drag-and-drop__dropzone"
              style={{ width: "100%" }}>
              {!selectedFiles || selectedFiles.length === 0 ? (
                <div className="drag-and-drop__dropzone__wrapper">
                  <Button
                    component="label"
                    style={{ backgroundColor: "transparent" }}
                    className="drag-and-drop__dropzone__browse">
                    <div className="drag-and-drop__image">
                      <FolderIcon width="24" height="24" />
                    </div>

                    <h3 className="drag-and-drop__text">
                      Drag & Drop your file here or{" "}
                      <b style={{ color: "var(--color-blue)" }}>browse</b> to upload
                    </h3>

                    <input
                      type="file"
                      accept="*"
                      multiple
                      onChange={(e) => browseFiles(e)}
                      style={{ display: "none" }}
                    />
                  </Button>
                </div>
              ) : (
                <>
                  <table style={{ width: "100%" }} className="drag-and-drop__files-table">
                    {Object.keys(selectedFiles).map((file, index) => (
                      <tbody
                        key={index}
                        className="drag-and-drop__text--file"
                        style={{ width: "100%" }}>
                        <tr>
                          <td className="drag-and-drop__text--file--name">
                            <b className="drag-and-drop__text--file--name--icon">
                              <FolderIcon width="24" height="24" />
                            </b>
                            <b className="drag-and-drop__text--file--name--file">
                              {selectedFiles[file].file.name}
                            </b>
                          </td>
                          <td className="drag-and-drop__text--file--size">
                            <StorageIcon width="24" height="24" />
                            <b className="drag-and-drop__text--file--size--text">
                              {selectedFiles[file].file.size + " Byte"}
                            </b>
                          </td>
                          <td className="drag-and-drop__text--file--type">
                            <FileTypeIcon width="24" height="24" />
                            <b className="drag-and-drop__text--file--type--text">
                              {selectedFiles[file].file.type.split("/").length > 1
                                ? selectedFiles[file].file.type.split("/")[1]
                                : selectedFiles[file].file.type || "n/a"}
                            </b>
                          </td>
                          <td className="drag-and-drop__text--file--button">
                            <div
                              className="drag-and-drop__text--file--button--remove"
                              onClick={(e) => {
                                removeFile(e, index);
                              }}>
                              x
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  <div className="drag-and-drop__button-wrapper">
                    <Button
                      component="label"
                      className="drag-and-drop__button"
                      style={{
                        color: "var(--color-blue)",
                        boxShadow:
                          "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 10px 50px 0 rgba(0, 0, 0, 0.19)",
                        backgroundColor: "transparent",
                      }}>
                      BROWSE
                      <input
                        type="file"
                        multiple
                        accept="*"
                        style={{ display: "none" }}
                        onChange={(e) => browseFiles(e)}
                      />
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Paper>
        </Grid>
      </div>
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </Grid>
  );
};
export default DragAndDrop;
