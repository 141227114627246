import { useState } from "react";
import { useSelector } from "react-redux";
import FileCardButton from "../../../../../UI/FileCardButton/FileCardButton";
import Modal from "../../../../../UI/Modal/Modal";
import CheckRingRoundDuotone from "../../../../../Icon-SVG/CheckRingRoundDuotone";
import SubmitPopup from "../../../../../common/SubmitPopup/SubmitPopup";
import Warning from "../../../../../common/Warning/Warning";

const Advanced = ({ selectedFiles, setSelectedFiles }) => {
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const vms = useSelector((state) => state.dashboard.vms);

  const emptyFile = () => {
    setActive(false);
    setError("Please select at least one file");
    setShow(true);
  };

  const noVm = () => {
    setActive(false);
    setError("No vm available. Refresh the page and try again.");
    setShow(true);
  };

  return (
    <>
      <FileCardButton setActive={setActive}>
        <CheckRingRoundDuotone />
        <p>Advanced</p>
      </FileCardButton>
      {active ? (selectedFiles.length === 0 ? emptyFile() : null) : null}
      {active ? (vms.length === 0 ? noVm() : null) : null}
      {active && (
        <Modal active={active} setActive={setActive} type="submit-popup">
          <SubmitPopup
            setActive={setActive}
            active={active}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </Modal>
      )}

      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </>
  );
};

export default Advanced;
