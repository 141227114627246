import * as React from "react"

const Ogg = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="M359.036 537.775c.771.053 1.551.116 2.342.116h178.2c20.84 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.071-2.342.119V0L11.222 46.417v494.564L359.036 588.6v-50.825zm0-466.739c.771-.113 1.54-.237 2.342-.237h178.2c8.933 0 16.199 7.27 16.199 16.2v413.103c0 8.928-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.121-2.342-.242V71.036zM81.466 339.388c-26.401-.718-41.515-22.612-41.515-49.961 0-28.769 16.675-50.709 42.979-51.405 28.171-.751 43.896 21.945 43.896 49.663 0 32.945-18.616 52.42-45.36 51.703zm143.055-2.737c-7.246 2.315-20.817 5.385-34.225 5.025-18.225-.475-31.216-5.511-40.165-14.771-8.873-8.886-13.7-22.144-13.577-36.964.158-33.565 23.53-53.352 56.133-54.195 13.133-.34 23.361 2.038 28.437 4.406l-4.925 19.145c-5.664-2.415-12.656-4.316-23.812-4.126-18.857.298-32.877 11.443-32.877 33.57 0 21.062 12.701 33.708 31.229 34.014 5.255.101 9.471-.442 11.285-1.339v-21.832l-15.623-.096v-18.277l38.119-.127v55.567zm111.249 2.615c-8.047 2.442-23.129 5.664-38.011 5.258-20.208-.521-34.604-5.848-44.508-15.614-9.819-9.355-15.158-23.298-15.008-38.892.158-35.295 26.029-56.139 62.185-57.074 14.555-.377 25.924 2.117 31.562 4.619l-5.469 20.163c-6.286-2.539-14.06-4.532-26.431-4.337-20.915.345-36.46 12.076-36.46 35.377 0 22.162 14.078 35.482 34.636 35.835 5.821.101 10.504-.475 12.519-1.424v-23.003l-17.342-.095v-19.237l42.327-.155v58.579z" fill={color} />
    <path  d="M82.399 255.656c-13.7.237-21.6 14.494-21.6 33.352 0 19.014 8.166 32.545 21.734 32.782 13.901.248 21.956-14.054 21.956-33.36.006-17.821-7.914-33.016-22.09-32.774zM395.359 379.466c12.683-3.144 21.506-12.741 21.568-22.528h.021l.343-103.291 74.503-13.618v83.038c-5.416-1.92-11.902-2.247-18.483-.623-14.575 3.649-24.11 15.637-21.315 26.837 2.795 11.169 16.854 17.271 31.43 13.658 13.279-3.354 21.779-12.466 21.779-23.867V201.248l-101.682 16.49V340.01c-5.326-1.867-11.676-2.193-18.162-.569-14.428 3.606-23.867 15.482-21.104 26.546 1.614 10.241 16.706 17.096 31.102 13.479z" fill={color} />
  </svg>
)

export default Ogg
