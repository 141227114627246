import * as React from "react";

const ExploitIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".exp1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path
        className="exp"
        d="M987 866.6H37l110.2-68.8h729.6zM597.4 866.6c0 12.2-9.9 22.1-22.1 22.1H450.7c-12.2 0-22.1-9.9-22.1-22.1H37v20.1c0 19 15.4 34.4 34.4 34.4h881.1c19 0 34.4-15.4 34.4-34.4v-20.1H597.4zM298.7 256.3h-63.9c-48.3 0-87.6 39.3-87.6 87.6v422.7h57V343.9c0-16.9 13.7-30.6 30.6-30.6h63.9v-57zM725.3 256.3h63.9c48.3 0 87.6 39.3 87.6 87.6v422.7h-57V343.9c0-16.9-13.7-30.6-30.6-30.6h-63.9v-57z"
        fill={color}
      />
      <path
        className="exp1"
        d="M344.2 731.4h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM472.4 731.4h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM600.6 731.4h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM728.8 731.4h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM640.9 155.5c-70.2-70.2-184-70.2-254.3 0s-70.2 184 0 254.3 184 70.2 254.3 0 70.2-184.1 0-254.3zm-35.1 219.1c-50.8 50.8-133.2 50.8-184 0s-50.8-133.2 0-184 133.2-50.8 184 0c50.8 50.8 50.8 133.2 0 184z"
      />
      <path
        transform="rotate(-45.001 643.874 412.746)"
        className="exp1"
        d="M621.5 391.2h44.7v43h-44.7z"
      />
      <path
        className="exp1"
        d="m784.1 523.6-29.3 29.3c-5 5-13 5-18 0l-78.6-78.6c-5-5-5-13 0-18l29.3-29.3c5-5 13-5 18 0l78.6 78.6c4.9 5 4.9 13 0 18z"
      />
      <path
        className="exp"
        d="m531 198.9-14.1 57.3 27.2 28.7-39.4 84.8c5.5 2.8 9 4.4 9 4.4s70.7-30.8 70.7-70.4v-80.3L531 198.9z"
        fill={color}
      />
      <path
        className="exp"
        d="M522.4 286.1 494.6 261l13.8-67.5-65.3 30v80.3c0 28.8 37.4 53 57.8 64l21.5-81.7z"
        fill={color}
      />
    </g>
  </svg>
);

export default ExploitIcon;
