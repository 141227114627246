import React from "react";

const Id = (props) => {
  const id = props.row.original.id;
  return (
    <div>
      <span className="dashboard__table__id">{id?.length > 30
            ? `${id?.substring(0, 30)}.........${id.substring(id.length - 5)}`
            : id}</span>
      
    </div>
  );
};

export default Id;
