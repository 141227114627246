import React from "react";

const EditIcon = ({ color = "var(--color-blue)", width = 18, height = 18 }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none">
        <path
          d="M15.4688 14.6953H2.53125C2.22012 14.6953 1.96875 14.9467 1.96875 15.2578V15.8906C1.96875 15.968 2.03203 16.0312 2.10938 16.0312H15.8906C15.968 16.0312 16.0312 15.968 16.0312 15.8906V15.2578C16.0312 14.9467 15.7799 14.6953 15.4688 14.6953ZM4.52988 13.2188C4.56504 13.2188 4.6002 13.2152 4.63535 13.21L7.59199 12.6914C7.62715 12.6844 7.66055 12.6686 7.68516 12.6422L15.1365 5.19082C15.1528 5.17456 15.1657 5.15524 15.1746 5.13398C15.1834 5.11271 15.1879 5.08992 15.1879 5.06689C15.1879 5.04387 15.1834 5.02108 15.1746 4.99981C15.1657 4.97855 15.1528 4.95923 15.1365 4.94297L12.215 2.01973C12.1816 1.98633 12.1377 1.96875 12.0902 1.96875C12.0428 1.96875 11.9988 1.98633 11.9654 2.01973L4.51406 9.47109C4.4877 9.49746 4.47187 9.5291 4.46484 9.56426L3.94629 12.5209C3.92919 12.6151 3.9353 12.712 3.96409 12.8033C3.99288 12.8945 4.04349 12.9774 4.11152 13.0447C4.22754 13.1572 4.37344 13.2188 4.52988 13.2188Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default EditIcon;
