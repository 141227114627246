import * as React from "react"

const Asp = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.595 588.595"
    xmlSpace="preserve">
    <path  d="M281.424 256.294c-5.468.124-9.146.723-11.068 1.274v33.652c2.272.52 5.07.686 8.943.686 14.449.01 23.507-7.069 23.507-18.998 0-10.718-7.8-16.928-21.382-16.614zM101.767 261.127l-.274.005c-1.355 5.993-2.71 13.613-4.195 19.445l-5.379 21.231 20.266.179-5.777-21.486c-1.649-5.981-3.279-13.428-4.641-19.374z" fill={color} />
    <path  d="M539.573 49.204h-178.2c-.791 0-1.561.076-2.342.124V0L11.227 46.417V540.98l347.804 47.62v-50.814c.781.053 1.551.116 2.342.116h178.2c20.841 0 37.8-16.965 37.8-37.8V86.999c0-20.839-16.959-37.795-37.8-37.795zM122.499 345.637l-7.27-26.414-26.253-.586-6.363 25.308-20.503-.876 26.866-98.505 27.231-.994 29.742 103.075-23.45-1.008zm61.63 4.214c-11.768-.517-23.177-4.146-28.816-7.646l4.601-19.491c6.12 3.449 15.638 7.009 25.608 7.31 10.897.316 16.735-4.183 16.735-11.274 0-6.761-5.05-10.7-17.658-15.473-17.06-6.289-27.957-15.997-27.957-31.264 0-17.917 14.359-32.168 38.831-33.096 12.021-.454 21.012 1.819 27.503 4.524l-5.519 20.229c-4.358-2.024-12.036-4.936-22.449-4.69-10.254.237-15.18 5.102-15.18 10.642 0 6.827 5.843 9.79 19.425 14.974 19.013 7.101 28.15 17.279 28.15 32.867-.005 18.534-14.14 33.674-43.274 32.388zm135.078-49.861c-9.624 8.453-23.66 12.061-39.725 11.828-3.52-.053-6.679-.264-9.121-.644v40.759l-25.861-1.098V240.39c7.95-1.614 19.261-3.035 35.511-3.634 16.804-.622 29.027 2.046 37.35 8.042 8.031 5.717 13.5 15.438 13.5 27.074 0 11.639-4.156 21.486-11.654 28.118zm236.566 200.112c0 8.933-7.268 16.199-16.2 16.199h-178.2c-.802 0-1.571-.11-2.342-.231V71.046c.771-.114 1.54-.243 2.342-.243h178.2c8.933 0 16.2 7.27 16.2 16.2v413.099z" fill={color} />
    <path  d="M363.213 374.414h20.719l43.897-165.153h-20.714zM439.747 268.254l76.085 36.224v.454l-76.085 36.217v24.153l98.444-50.13v-20.943l-98.444-50.125z" fill={color} />
  </svg>
)

export default Asp
