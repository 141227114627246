import * as React from "react"

const Cad = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="M159.215 249.987h-.33c-1.571 6.571-3.153 14.932-4.883 21.313l-6.273 23.337 23.56.101-6.695-23.512c-1.896-6.547-3.807-14.71-5.379-21.239zM262.965 247.891c-5.885.103-9.692.675-11.939 1.231v75.305c2.247.543 5.875.606 9.163.664 24.176.597 40.218-12.382 40.218-40.489.179-24.432-14.45-37.103-37.442-36.711z" fill={color} />
    <path  d="M359.036 537.775c.771.053 1.551.116 2.342.116h178.2c20.84 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.071-2.342.119V0L11.222 46.417v494.564L359.036 588.6v-50.825zm0-466.739c.771-.113 1.54-.237 2.342-.237h178.2c8.933 0 16.199 7.27 16.199 16.2v413.103c0 8.928-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.121-2.342-.242V71.036zM81.2 321.342c6.784.127 14.333-1.34 18.815-3.175l3.438 19.037c-4.185 2.12-13.563 4.261-25.629 3.939-33.539-.896-50.383-23.636-50.383-53.146 0-35.343 23.087-55.638 52.576-56.416 11.665-.311 20.596 2.009 24.641 4.148l-4.638 19.377c-4.63-1.993-11.011-3.792-18.956-3.662-17.613.298-31.092 11.867-31.092 35.176.002 20.99 11.442 34.395 31.228 34.722zm101.981 20.957-8.367-28.751-30.52-.375-7.457 27.923-24.176-.617 31.628-108.788 31.638-.799 34.09 112.113-26.836-.706zm126.243-10.748c-12.791 10.025-31.954 14.407-54.946 13.801-13.545-.358-23.037-1.461-29.426-2.468V231.145c9.439-1.758 21.863-2.932 35.142-3.271 22.399-.572 37.159 3.04 48.866 11.356 12.735 8.894 20.814 23.438 20.814 44.476 0 22.796-8.638 38.396-20.45 47.845z" fill={color} />
    <path  d="m397.69 353.163 13.278-12.451 37.252-95.352 37.268 95.352 13.268 12.451 18.479 32.379-8.211-36.593 1.402-16.901-22.074-60.027h12.256v9.682h5.979v-25.354h-5.979v9.69h-14.46l-14.302-38.886c1.529-3.446 2.404-7.23 2.404-11.243 0-14.109-10.525-25.645-24.12-27.49v-33.064h-7.488v33.064c-13.616 1.846-24.132 13.381-24.132 27.49a27.565 27.565 0 0 0 4.609 15.304l-12.825 34.826h-15.029v-9.69h-5.979v25.354h5.979v-9.682h12.836l-22.075 60.027 1.403 16.901-8.216 36.593 18.477-32.38zm49.128-156.469c10.642 0 19.269 8.63 19.269 19.27 0 10.636-8.627 19.261-19.269 19.261-10.652 0-19.28-8.63-19.28-19.261 0-10.64 8.628-19.27 19.28-19.27z" fill={color} />
    <path  d="M446.818 206.735c5.247 0 9.502 4.25 9.502 9.497s-4.255 9.498-9.502 9.498c-5.242 0-9.498-4.25-9.498-9.498s4.256-9.497 9.498-9.497z" fill={color} />
  </svg>
)

export default Cad
