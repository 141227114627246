import React, { useState } from "react";
import SearchIcon from "../../../Icon-SVG/SearchIcon";
import IconXMarkIcon from "../../../Icon-SVG/IconXMarkIcon";

const FilterTable = ({ column }) => {
  const [state, setState] = useState(false);
  const { filterValue, setFilter } = column;

  return (
    <div style={{ marginBottom: state ? "35px" : "0" }} className="filter">
      <div
        onClick={() => {
          setState(!state);
          if (state) setFilter("");
        }}>
        {!state ? <SearchIcon width={10} height={10} /> : <IconXMarkIcon />}
      </div>
      {state && (
        <span className="filter__search">
          <input
            className="search-input"
            type="text"
            value={filterValue || ""}
            onChange={(e) => setFilter(e.target.value)}
          />
        </span>
      )}
    </div>
  );
};

export default FilterTable;
