import * as React from "react"

const Aspx = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.595 588.595"
    xmlSpace="preserve">
    <path  d="M200.868 272.51c-4.293.108-7.183.588-8.688 1.042v27.73c1.793.4 3.997.538 7.038.538 11.316-.032 18.391-5.862 18.391-15.646-.006-8.799-6.097-13.912-16.741-13.664zM56.694 276.404l-.211.011c-1.118 5.055-2.225 11.465-3.452 16.355l-4.416 17.888 16.622.079-4.735-18.035c-1.339-5.023-2.69-11.288-3.808-16.298z" fill={color} />
    <path  d="M539.573 49.198h-178.2c-.791 0-1.561.077-2.342.124V0L11.227 46.419V540.98l347.804 47.62v-50.814c.781.053 1.551.116 2.342.116h178.2c20.851 0 37.8-16.96 37.8-37.8V86.999c0-20.839-16.95-37.801-37.8-37.801zM73.648 347.319l-5.927-22.117-21.526-.348-5.242 21.368-16.941-.56 22.178-83.207 22.319-.772 24.213 86.289-19.074-.653zm49.951 3c-9.482-.332-18.689-3.264-23.246-6.144l3.721-16.305c4.936 2.826 12.604 5.737 20.638 5.916 8.732.19 13.402-3.575 13.402-9.471 0-5.621-4.037-8.859-14.151-12.783-13.743-5.168-22.531-13.246-22.531-26.003 0-14.963 11.588-26.823 31.261-27.53 9.618-.345 16.79 1.551 21.956 3.808l-4.406 16.762c-3.473-1.685-9.595-4.11-17.927-3.916-8.234.19-12.192 4.245-12.192 8.867 0 5.677 4.701 8.145 15.588 12.438 15.19 5.84 22.455 14.247 22.455 27.156.009 15.366-11.254 28.002-34.568 27.205zm106.792-41.992c-7.502 6.972-18.457 9.999-31.04 9.877-2.777-.026-5.245-.194-7.172-.479v33.57l-20.417-.675v-91.28c6.297-1.318 15.228-2.458 28.002-2.911 13.168-.459 22.708 1.772 29.173 6.708 6.252 4.693 10.492 12.661 10.492 22.161-.005 9.516-3.219 17.577-9.038 23.029zm74.134 46.744-9.729-18.604c-3.952-7.088-6.452-12.361-9.439-18.193l-.306-.011c-2.194 5.774-4.833 10.947-8.09 17.824l-8.608 17.771-26.104-.876 29.333-49.264-28.308-48.094 26.314-.913 9.073 17.906c3.108 6.022 5.452 10.876 7.952 16.487h.319c2.513-6.436 4.559-10.945 7.238-16.833l9.067-18.721 28.408-.994-30.949 50.517 32.632 52.956-28.803-.958zm251.248 145.031c0 8.933-7.268 16.199-16.2 16.199h-178.2c-.802 0-1.571-.115-2.342-.231V71.041c.771-.113 1.54-.242 2.342-.242h178.2c8.933 0 16.2 7.269 16.2 16.2v413.103z" fill={color} />
    <path  d="M372.996 392.544h20.724l43.885-165.156h-20.703zM449.523 286.39l76.096 36.217v.455l-76.096 36.217v24.148l98.445-50.124v-20.952l-98.445-50.116z" fill={color} />
  </svg>
)

export default Aspx
