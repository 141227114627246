const StaticAnalysisIcon = ({
  inactive = false,
  color1 = "var(--text-color-card)",
  color2 = "var(--sidebar-background)",
  width = 22,
  height = 22,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="2 1 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: "1px" }}>
      <path
        opacity="1"
        d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167V11.9914C21 17.6294 16.761 20.3655 14.1014 21.5273C13.38 21.8424 13.0193 22 12 22C10.9807 22 10.62 21.8424 9.89856 21.5273C7.23896 20.3655 3 17.6294 3 11.9914V10.4167Z"
        fill={color1}
        style={{ stroke: "none" }}
      />
      <path
        d="M10.0303 8.96967C9.73744 8.67678 9.26256 8.67678 8.96967 8.96967C8.67678 9.26256 8.67678 9.73744 8.96967 10.0303L10.9394 12L8.96969 13.9697C8.6768 14.2626 8.6768 14.7374 8.96969 15.0303C9.26258 15.3232 9.73746 15.3232 10.0304 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9696L13.0607 12L15.0303 10.0303C15.3232 9.73746 15.3232 9.26258 15.0303 8.96969C14.7374 8.6768 14.2626 8.6768 13.9697 8.96969L12 10.9394L10.0303 8.96967Z"
        fill={color2}
        style={{ stroke: "none" }}
      />
    </svg>
    //<svg
    //  width={width}
    //  height={height}
    //  xmlns="http://www.w3.org/2000/svg"
    //  viewBox="0 0 32 32"
    //  id="analysis">
    //  <path
    //    fill={color}
    //    d="M10,8c0-.552,.447-1,1-1h10c.553,0,1,.448,1,1s-.447,1-1,1H11c-.553,0-1-.448-1-1Zm1,5h10c.553,0,1-.448,1-1s-.447-1-1-1H11c-.553,0-1,.448-1,1s.447,1,1,1Zm6,2h-6c-.553,0-1,.448-1,1s.447,1,1,1h6c.553,0,1-.448,1-1s-.447-1-1-1Zm-3,4h-3c-.553,0-1,.448-1,1s.447,1,1,1h3c.553,0,1-.448,1-1s-.447-1-1-1Zm15.707,10.707c-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.679-2.679c-.886,.612-1.959,.972-3.114,.972-1.173,0-2.258-.372-3.152-1H9c-1.654,0-3-1.346-3-3V5c0-1.654,1.346-3,3-3h14c1.654,0,3,1.346,3,3v13.261c1.221,1.01,2,2.535,2,4.239,0,1.156-.36,2.228-.972,3.114l2.679,2.679c.391,.391,.391,1.023,0,1.414Zm-12.707-7.207c0-3.033,2.468-5.5,5.5-5.5,.521,0,1.022,.077,1.5,.213V5c0-.551-.448-1-1-1H9c-.552,0-1,.449-1,1V24c0,.551,.448,1,1,1h8.607c-.385-.751-.607-1.599-.607-2.5Zm9,0c0-1.93-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5,1.57,3.5,3.5,3.5,3.5-1.57,3.5-3.5Z"></path>
    //</svg>
    // <svg
    //   width={width}
    //   height={height}
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 64 64"
    //   id="antivirus-file">
    //   <g>
    //     <path
    //       fill={inactive ? "#ffffff" : color}
    //       d="M55 56H21v2a4 4 0 0 1-4 4 4 4 0 0 1-4-4V6a4 4 0 0 0-4-4h40a6 6 0 0 1 6 6Z"></path>
    //     <path
    //       fill={inactive ? "#ffffff" : "var(--color-blue)"}
    //       d="M48 11.5A38.117 38.117 0 0 0 46.8 2H9a4 4 0 0 1 4 4v43.8a38.7 38.7 0 0 0 35-38.3Z"></path>
    //     <path
    //       fill={inactive ? "#ffffff" : "#95aabd"}
    //       d="M55 56H21v2a4 4 0 0 1-4 4h38a4 4 0 0 0 4-4v-2zM9 2a4 4 0 0 1 4 4v8H5V6a4 4 0 0 1 4-4z"></path>
    //     <ellipse cx="34" cy="33" fill={inactive ? "#000000" : "#ffffff"} rx="7" ry="9"></ellipse>
    //     <path d="M59 55h-3V8a7.009 7.009 0 0 0-7-7H9a5.006 5.006 0 0 0-5 5v8a1 1 0 0 0 1 1h7v43a5.006 5.006 0 0 0 5 5h38a5.006 5.006 0 0 0 5-5v-2a1 1 0 0 0-1-1ZM12 13H6V6a3 3 0 0 1 6 0Zm8 43v2a3 3 0 0 1-6 0V6a4.948 4.948 0 0 0-1.026-3H49a5.006 5.006 0 0 1 5 5v47H21a1 1 0 0 0-1 1Zm38 2a3 3 0 0 1-3 3H21a4.973 4.973 0 0 0 1-3v-1h36Z"></path>
    //     <path d="M22 34h4.041a11.9 11.9 0 0 0 .7 3.182 4.82 4.82 0 0 0-3.44 3.486l-.273 1.09a1 1 0 0 0 .728 1.212A1.017 1.017 0 0 0 24 43a1 1 0 0 0 .969-.758l.272-1.09a2.833 2.833 0 0 1 2.392-2.121A7.588 7.588 0 0 0 34 43a7.588 7.588 0 0 0 6.366-3.969 2.833 2.833 0 0 1 2.392 2.121l.272 1.09A1 1 0 0 0 44 43a1.017 1.017 0 0 0 .243-.03 1 1 0 0 0 .728-1.212l-.273-1.09a4.82 4.82 0 0 0-3.44-3.486 11.9 11.9 0 0 0 .7-3.182H46a1 1 0 0 0 0-2h-4.041a12 12 0 0 0-.422-2.32c-.01-.029-.013-.059-.025-.086a11.36 11.36 0 0 0-.255-.776 4.82 4.82 0 0 0 3.44-3.486l.273-1.09a1 1 0 1 0-1.94-.484l-.272 1.09a2.833 2.833 0 0 1-2.392 2.121 8.425 8.425 0 0 0-3.629-3.353A5.874 5.874 0 0 0 38 20a1 1 0 0 0-2 0 3.853 3.853 0 0 1-1.121 2.707l-.328.328C34.368 23.019 34.186 23 34 23s-.368.019-.551.035l-.328-.328A3.853 3.853 0 0 1 32 20a1 1 0 0 0-2 0 5.874 5.874 0 0 0 1.263 3.616 8.425 8.425 0 0 0-3.629 3.353 2.833 2.833 0 0 1-2.392-2.121l-.272-1.09a1 1 0 0 0-1.94.484l.273 1.09a4.82 4.82 0 0 0 3.44 3.486q-.141.381-.256.779c-.01.022-.012.048-.021.071A11.972 11.972 0 0 0 26.041 32H22a1 1 0 0 0 0 2Zm6-1a10.507 10.507 0 0 1 .2-2H33v9.88c-2.833-.638-5-3.924-5-7.88Zm7 7.88V31h4.8a10.507 10.507 0 0 1 .2 2c0 3.956-2.167 7.242-5 7.88ZM34 25c2.215 0 4.149 1.613 5.188 4H28.812c1.039-2.387 2.973-4 5.188-4Z"></path>
    //   </g>
    // </svg>
  );
};

export default StaticAnalysisIcon;
