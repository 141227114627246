import { formatBytes } from "../../../Pages/Settings/Content/Tabs/DumpAndRestore/components/Dump";

import "./styles.scss";

const DownloadMessage = ({ fileName, loaded, total, progress }) => {
  return (
    <div className="download-message">
      <p className="download-message__info">Downloading. This may take a while, please wait...</p>
      <div className="download-message__header">
        <span className="download-message__file-name">{fileName}</span>
        <div className="download-message__info">
          <span className="download-message__loaded">{formatBytes(loaded, 2)} </span>/
          <span className="download-message__total"> {formatBytes(total, 2)}</span>
        </div>
      </div>
      <div className="download-item__progress">{progress.toFixed(0)}%</div>
    </div>
  );
};

export default DownloadMessage;
