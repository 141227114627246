import React from "react";

const UserIcon = ({ color = "var(--border)", width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none">
      <path
        d="M4 4.02632C4 6.24616 5.79467 8.05263 8 8.05263C10.2053 8.05263 12 6.24616 12 4.02632C12 1.80647 10.2053 0 8 0C5.79467 0 4 1.80647 4 4.02632ZM15.1111 17H16V16.1053C16 12.6525 13.208 9.8421 9.77778 9.8421H6.22222C2.79111 9.8421 0 12.6525 0 16.1053V17H15.1111Z"
        fill={color || "var(--settings-sidebar-icon)"}
      />
    </svg>
  );
};

export default UserIcon;
