import React from "react";
import UserIcon from "../../../../Icon-SVG/UserIcon";
const Senderimap = (props) => {
  const sender = props.row.original.sender;
  return (
    <div className="dashboard__table__sender-imap">
      <span
        className="dashboard__table__user--icon"
        style={sender.email ? { paddingTop: "5px" } : null}>
        <UserIcon width={15} height={15} color={"var(--text-color-card)"} />
      </span>
      <span style={sender.email ? { paddingTop: "5px" } : null}>{sender.email}</span>
    </div>
  );
};

export default Senderimap;
