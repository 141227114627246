import React from "react";

const BackArrowIcon = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => {
  return (
    <svg
      fill={color}
      height={width}
      width={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.801 511.801">
      <g>
        <g>
          <g>
            <path
              d="M271.067,255.84l237.76-237.76c4.053-4.267,3.947-10.987-0.213-15.04c-4.16-3.947-10.667-3.947-14.827,0L248.453,248.373
				c-4.16,4.16-4.16,10.88,0,15.04l245.333,245.333c4.267,4.053,10.987,3.947,15.04-0.213c3.947-4.16,3.947-10.667,0-14.827
				L271.067,255.84z"
            />
            <path
              d="M25.733,255.84l237.76-237.76c4.053-4.267,3.947-10.987-0.213-15.04c-4.16-3.947-10.667-3.947-14.827,0L3.12,248.267
				c-4.16,4.16-4.16,10.88,0,15.04L248.453,508.64c4.267,4.053,10.987,3.947,15.04-0.213c3.947-4.16,3.947-10.667,0-14.827
				L25.733,255.84z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BackArrowIcon;
