import React from "react";

const PaginationRightIcon = ({ width = 7, height = 12, color = "var(--text-color-card)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 7 12"
      fill="none">
      <path
        d="M1.62774 0.750038C1.73979 0.749657 1.8505 0.774386 1.95173 0.822407C2.05297 0.870428 2.14215 0.940523 2.21274 1.02754L5.83524 5.52754C5.94555 5.66174 6.00586 5.83007 6.00586 6.00379C6.00586 6.17751 5.94555 6.34584 5.83524 6.48004L2.08524 10.98C1.95794 11.1332 1.77501 11.2295 1.57669 11.2478C1.37837 11.2661 1.18091 11.2048 1.02774 11.0775C0.874581 10.9502 0.778264 10.7673 0.759979 10.569C0.741694 10.3707 0.80294 10.1732 0.930244 10.02L4.28274 6.00004L1.04274 1.98004C0.951031 1.86995 0.892773 1.73589 0.874864 1.59373C0.856954 1.45157 0.880141 1.30725 0.941683 1.17786C1.00323 1.04846 1.10055 0.939402 1.22213 0.863585C1.34371 0.787767 1.48447 0.748364 1.62774 0.750038Z"
        fill={color}
      />
    </svg>
  );
};

export default PaginationRightIcon;
