import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TabButton from "../../../../../Components/common/TabButton/TabButton";
import MailContents from "./Tabs/MailContent";
import MailingConfigs from "./Tabs/MailingConfigs";
import ToggleButton from "../../../../../Components/common/ToggleButton/ToggleButton";
import Backdrop from "../../../../../Components/UI/Backdrop/Backdrop";

import {
  requestGetMailingConfigs,
  requestUpdateMailingActive,
} from "../../../../../store/actions/settingsActions";

import "./styles.scss";
import MailRecipients from "./Tabs/Recipients";

const Mailing = () => {
  const dispatch = useDispatch();

  const mailingConfigs = useSelector((state) => state.settings.mailingConfigs);
  const mailingActive = useSelector((state) => state.settings.mailingActive);

  const [activeTab, setActive] = useState("Configs");
  const [isMailingActive, setIsMailingActive] = useState(mailingActive);

  useEffect(() => {
    dispatch(requestGetMailingConfigs());
  }, []);

  useEffect(() => {
    if (
      isMailingActive !== mailingActive &&
      mailingConfigs.server &&
      mailingConfigs.port &&
      mailingConfigs.security &&
      mailingConfigs.senderEmail
    ) {
      dispatch(requestUpdateMailingActive({ active: isMailingActive }));
    }
  }, [isMailingActive]);

  useEffect(() => {
    setIsMailingActive(mailingActive);
  }, [mailingActive]);

  return (
    <div className="mailing">
      <div className="mailing__header">
        <h1>Mailing</h1>
        <ToggleButton active={isMailingActive} onClick={setIsMailingActive} />
      </div>
      <div className="mailing__body">
        <div className="button-block">
          <TabButton title="Configs" onClick={setActive} active={activeTab} />
          <TabButton title="Mail Contents" onClick={setActive} active={activeTab} />
          <TabButton title="Recipients" onClick={setActive} active={activeTab} />
        </div>
        <div style={{ background: "var(--card-background-color)" }}>
          {activeTab === "Configs" ? (
            <MailingConfigs />
          ) : activeTab === "Recipients" ? (
            <MailRecipients />
          ) : (
            <MailContents />
          )}
        </div>
        <Backdrop
          spinner={false}
          absolute
          show={!isMailingActive}
          message="Mail notification is inactive! Please click on the button at the top of the page to activate mail notifications."
          messageStyle={{ paddingTop: 0 }}
        />
      </div>
    </div>
  );
};

export default Mailing;
