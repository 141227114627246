import SeverityStatus from "./SeverityStatus";
import SeverityStatusStatic from "./SeverityStatusStatic";
import SeverityStatusStaticPopup from "./SeverityStatusStaticPopup";

const SeverityStatusWrapper = ({ statusType = "", ...props }) => {
  const isStatic = props?.row?.original.isStatic;

  return (
    <>
      {statusType === "staticPopup" ? (
        <SeverityStatusStaticPopup {...props} />
      ) : isStatic ? (
        <SeverityStatusStatic {...props} />
      ) : (
        <SeverityStatus {...props} />
      )}
    </>
  );
};

export default SeverityStatusWrapper;
