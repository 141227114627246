import React from "react";

const ArrowRightIcon = ({ width = 5, height = 10, color = "var(--text-color-card)", margin = 0 }) => {
  return (
    <div style={{ cursor: "pointer", margin }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 12 7"
        fill="none"
        transform="rotate(270)">
        <path
          d="M11.2509 1.6275C11.2513 1.73955 11.2266 1.85025 11.1786 1.95149C11.1305 2.05272 11.0605 2.14191 10.9734 2.2125L6.47344 5.835C6.33924 5.94531 6.17091 6.00562 5.99719 6.00562C5.82347 6.00562 5.65514 5.94531 5.52094 5.835L1.02094 2.085C0.867777 1.9577 0.771458 1.77476 0.753174 1.57644C0.734889 1.37812 0.796135 1.18066 0.923439 1.0275C1.05074 0.874337 1.23368 0.778019 1.432 0.759735C1.63032 0.74145 1.82778 0.802696 1.98094 0.93L6.00094 4.2825L10.0209 1.0425C10.131 0.950788 10.2651 0.89253 10.4072 0.874621C10.5494 0.856711 10.6937 0.879898 10.8231 0.94144C10.9525 1.00298 11.0616 1.1003 11.1374 1.22188C11.2132 1.34347 11.2526 1.48422 11.2509 1.6275Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default ArrowRightIcon;
