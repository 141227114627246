import React from "react";

const UploadIcon = ({ color = "var(--border)", width = 24, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className="icon"
      viewBox="0 0 1024 1024"
      fill={color}>
      <path d="M550.4 213.184L778.688 448 832 393.152 512 64 192 393.152 245.312 448 473.6 213.184V832h76.8V213.184zM192 972.8h640V896H192v76.8z"></path>
    </svg>
  );
};

export default UploadIcon;
