import React from "react";

const CheckIcon = ({ color = "var(--text-color-card)", width = 78, height = 78 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 78 78"
      fill="none">
      <path
        d="M29 38.5L36.5 46L49 31"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 64C52.8071 64 64 52.8071 64 39C64 25.1929 52.8071 14 39 14C25.1929 14 14 25.1929 14 39C14 52.8071 25.1929 64 39 64Z"
        stroke={color}
        strokeWidth="4"
      />
    </svg>
  );
};

export default CheckIcon;
