import * as React from "react"

const Cdr = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.596 588.595"
    xmlSpace="preserve">
    <path  d="M359.023 537.786c.791.053 1.551.115 2.353.115h178.2c20.835 0 37.8-16.97 37.8-37.8v-413.1c0-20.841-16.965-37.8-37.8-37.8h-178.2c-.791 0-1.562.074-2.353.114V0L11.22 46.419v494.562l347.804 47.614v-50.809zm2.353-466.98h178.2c8.922 0 16.199 7.267 16.199 16.2v413.101c0 8.928-7.277 16.199-16.199 16.199h-178.2c-.802 0-1.571-.126-2.353-.237V363.013c1.762 1.439 4.04 2.024 6.281 1.508l58.382-13.468a7.415 7.415 0 0 0 3.56-1.962c.059-.063.111-.127.158-.19l.032.043 102.468-102.471-6.739-6.729-95.729 95.721-41.93-41.935 95.729-95.729-6.739-6.721-102.452 102.45.063.074c-1.061 1.002-1.856 2.294-2.178 3.702l-10.906 47.25V71.043c.782-.113 1.54-.237 2.353-.237zm7.082 264.944 8.495-36.819s.021-.063.048-.095l.074-.042a.191.191 0 0 1 .158.042l44.903 44.908c.042.042.068.116.042.147l.332.105.132.138-.585-.116-36.814 8.501-16.785-16.769zM78.92 308.686c7.151.127 15.114-1.387 19.828-3.301l3.642 19.986c-4.406 2.225-14.289 4.45-27.011 4.103-35.366-1.002-53.138-24.891-53.138-55.888 0-37.115 24.355-58.379 55.447-59.158 12.285-.3 21.698 2.139 25.969 4.391l-4.904 20.337c-4.87-2.102-11.588-3.997-19.984-3.871C60.205 235.57 46 247.699 46 272.183c-.01 22.023 12.071 36.123 32.92 36.503zm119.897 8.527c-12.171 10.004-30.441 14.364-52.376 13.748-12.922-.359-21.998-1.467-28.107-2.474V216.799c9.02-1.732 20.894-2.872 33.552-3.18 21.355-.525 35.445 3.132 46.575 11.454 12.126 8.899 19.817 23.425 19.817 44.416 0 22.749-8.208 38.29-19.461 47.724zm101.693 16.775c-2.067-3.522-5.07-13.626-8.796-28.804-3.354-15.316-8.744-19.54-20.166-19.804l-8.416-.061v47.635l-27.18-.749V214.051c8.765-1.632 21.919-3.019 36.682-3.385 18.512-.454 31.641 1.969 40.732 8.735 7.626 5.743 11.812 14.338 11.812 25.771 0 15.863-11.633 26.739-22.602 30.562v.546c8.88 3.515 13.816 11.815 17.054 23.261 4.019 14.06 8.048 30.396 10.558 35.259l-29.678-.812z" fill={color} />
    <path  d="M154.541 233.616c-5.622.087-9.25.654-11.377 1.205v75.23c2.133.564 5.58.623 8.722.681 23.058.606 38.351-12.34 38.351-40.387.16-24.392-13.782-37.069-35.696-36.729zM275.767 231.013c-6.795.114-10.623.712-12.635 1.103v33.437l10.977-.019c14.062-.016 22.465-6.953 22.465-17.711.004-11.307-7.847-16.83-20.807-16.81zM502.208 218.777l-85.946 85.954-13.838-13.838 85.947-85.946 13.837 13.83z" fill={color} />
  </svg>
)

export default Cdr
