import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    theme: null,
  },
  reducers: {
    themeRequest: () => {},
    themeSuccess: (state, payload) => {
      state.theme = payload.payload;
    },
    themeFailure: (state) => {
      state.theme = "dark";
    },
  },
});

export default themeSlice;
