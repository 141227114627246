import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  requestDeleteSyslogConfig,
  requestGetSyslogConfig,
  requestSetSyslogConfig,
} from "../../../../../store/actions/settingsActions";
import Field from "../../Components/Field";

import "./styles.scss";
import Spinner from "../../../../../Components/common/Spinner/Spinner";

const SPINNER_SIZE = { width: 22, height: 22 };

const Syslog = () => {
  const serverFromDB = useSelector((state) => state.settings.syslogServer);
  const portFromDB = useSelector((state) => state.settings.syslogPort);
  const connectionFromDB = useSelector((state) => state.settings.syslogConnection);
  const syslogConfigLoading = useSelector((state) => state.settings.syslogConfigLoading);

  const dispatch = useDispatch();

  const [server, setServer] = useState("");
  const [port, setPort] = useState("");
  const [connection, setConnection] = useState("");

  useEffect(() => {
    dispatch(requestGetSyslogConfig());
  }, [dispatch]);

  useEffect(() => {
    setServer(serverFromDB);
    setPort(portFromDB);
    setConnection(connectionFromDB);
  }, [serverFromDB, portFromDB, connectionFromDB]);

  const updateSyslogHandler = () => {
    if (server === "" || port === "" || connection === "") return;
    dispatch(requestSetSyslogConfig({ server, port, connection }));
  };

  const deleteSyslogHandler = () => {
    if (server === "" || port === "" || connection === "") return;
    dispatch(requestDeleteSyslogConfig());
  };

  return (
    <div className="syslog">
      <h1 className="syslog__header">Syslog</h1>
      <div className="syslog__body">
        <div className="syslog__body__header">
          <h3>Syslog</h3>
          {/* <p>NOTE: Syslog only supports UDP connection for now.</p> */}
        </div>
        <div className="syslog__body__content">
          <div className="syslog__body__content__row">
            <div className="syslog__body__content__row__connection-type">
              <p className="syslog__body__content__row__connection-type__header">Connection Type</p>
              <div className="syslog__body__content__row__connection-type__labels">
                <label className="align-items">
                  <input
                    className="syslog__body__content__row__connection-type__input"
                    type="radio"
                    value="TCP"
                    name="tcp"
                    checked={connection === "TCP"}
                    onChange={(event) => setConnection(event.target.value)}
                  />
                  <p className="margin-left-12">TCP</p>
                </label>
                <label className="align-items">
                  <input
                    className="syslog__body__content__row__connection-type__input"
                    type="radio"
                    value="UDP"
                    name="udp"
                    checked={connection === "UDP"}
                    onChange={(event) => setConnection(event.target.value)}
                  />
                  <p className="margin-left-12">UDP</p>
                </label>
              </div>
            </div>
          </div>
          <div className="syslog__body__content__row">
            <Field
              label="Server"
              width="131px"
              value={server}
              onChange={(e) => setServer(e.target.value)}
              //   parentClassName="p-r-20"
            />
            <Field
              label="Port"
              onChange={(e) => setPort(e.target.value)}
              value={port}
              width="131px"
              maxLength="15"
            />
          </div>
          <div className="syslog__body__content__row">
            <button className="default-button" onClick={deleteSyslogHandler}>
              Reset
            </button>
            <button
              className="primary-button"
              onClick={updateSyslogHandler}
              disabled={
                server === "" ||
                port === "" ||
                connection === "" ||
                (server === serverFromDB && port === portFromDB && connection === connectionFromDB)
              }>
              {syslogConfigLoading ? (
                <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Syslog;
