import React from "react";

const FolderIcon = ({ color = "var(--text-color-card)", subColor = "#646C85", width = 26, height = 26 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8655 6.11553H11.0193L9.05777 4.15399H4.15392C3.07508 4.15399 2.19238 5.03668 2.19238 6.11553V17.8848C2.19238 18.9636 3.07508 19.8463 4.15392 19.8463H19.3558C20.1895 19.8463 20.827 19.2088 20.827 18.3751V8.07707C20.827 6.99822 19.9443 6.11553 18.8655 6.11553Z"
        fill={subColor}
      />
      <path
        d="M20.9245 9.0578H7.73313C6.8014 9.0578 5.96774 9.74434 5.82063 10.6761L4.15332 19.8463H19.6985C20.6302 19.8463 21.4639 19.1597 21.611 18.228L22.837 11.3626C23.0822 10.1857 22.1504 9.0578 20.9245 9.0578Z"
        fill={color}
      />
    </svg>
  );
};

export default FolderIcon;
