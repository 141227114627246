import React from 'react';
import "./styles.scss";

const ErrorPage = ({ error }) => {
  return (
    <div className="maintenance">
      <h1>Page Under Maintenance</h1>
      <p>We apologize for the inconvenience. This page is currently undergoing maintenance.</p>
    </div>
  );
};

export default ErrorPage;
