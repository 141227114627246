import React from "react";

const IconXMarkIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none">
        <path
          d="M9.24264 0.757289C9.38329 0.897941 9.46231 1.08871 9.46231 1.28762C9.46231 1.48653 9.38329 1.6773 9.24264 1.81795L6.06066 4.99993L9.24264 8.18191C9.38329 8.32256 9.46231 8.51333 9.46231 8.71224C9.46231 8.91115 9.38329 9.10192 9.24264 9.24257C9.10199 9.38322 8.91122 9.46224 8.71231 9.46224C8.5134 9.46224 8.32263 9.38322 8.18198 9.24257L5 6.06059L1.81802 9.24257C1.67737 9.38322 1.4866 9.46224 1.28769 9.46224C1.08878 9.46224 0.898012 9.38322 0.757359 9.24257C0.616707 9.10192 0.537689 8.91115 0.537689 8.71224C0.537689 8.51333 0.616707 8.32256 0.757359 8.18191L3.93934 4.99993L0.757359 1.81795C0.616707 1.6773 0.537689 1.48653 0.537689 1.28762C0.537689 1.08871 0.616707 0.897941 0.757359 0.757289C0.898012 0.616637 1.08878 0.537619 1.28769 0.53762C1.4866 0.537619 1.67737 0.616637 1.81802 0.757289L5 3.93927L8.18198 0.757289C8.32263 0.616637 8.5134 0.537619 8.71231 0.53762C8.91122 0.537619 9.10199 0.616637 9.24264 0.757289Z"
          fill="var(--text-color-card)"
        />
      </svg>
    </div>
  );
};

export default IconXMarkIcon;
