import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  RequestGeneralAnalysis,
  SuccessGeneralAnalysis,
  FailureGeneralAnalysis,
  RequestGetSignatures,
  SuccessGetSignatures,
  FailureGetSignatures,
  RequestFilesInfo,
  SuccessFilesInfo,
  FailureFilesInfo,
  RequestGetVm,
  SuccessGetVm,
  FailureGetVm,
  RequestAttackMapTree,
  SuccessAttackMapTree,
  FailureAttackMapTree,
  RequestMitreAttack,
  SuccessMitreAttack,
  FailureMitreAttack,
  RequestAddComment,
  SuccessAddComment,
  FailureAddComment,
  RequestGetComment,
  SuccessGetComment,
  FailureGetComment,
  RequestP2signatureList,
  SuccessP2signatureList,
  FailureP2signatureList,
  RequestDeleteComment,
  SuccessDeleteComment,
  FailureDeleteComment,
  RequestStaticInfo,
  SuccessStaticInfo,
  FailureStaticInfo,
  RequestChatGPT,
  SuccessChatGPT,
  FailureChatGPT,
  RequestFileDetails,
  SuccessFileDetails,
  FailureFileDetails,
  RequestExtractedStrings,
  SuccessExtractedStrings,
  FailureExtractedStrings,
  RequestAttackMapCalls,
  SuccessAttackMapCalls,
  FailureAttackMapCalls,
  RequestAttackMapSummary,
  SuccessAttackMapSummary,
  FailureAttackMapSummary,
  RequestAttackMapEnhanced,
  SuccessAttackMapEnhanced,
  FailureAttackMapEnhanced,
  RequestAttackMapCallsByCategory,
  SuccessAttackMapCallsByCategory,
  FailureAttackMapCallsByCategory,
  RequestStopMouseEmulation,
  SuccessStopMouseEmulation,
  FailureStopMouseEmulation,
  RequestLiveUploadFile,
  SuccessLiveUploadFile,
  FailureLiveUploadFile,
  RequestFinishAnalysis,
  SuccessFinishAnalysis,
  FailureFinishAnalysis,
  RequestMemoryAddressMapping,
  SuccessMemoryAddressMapping,
  FailureMemoryAddressMapping,
  RequestReadyStatus,
  SuccessReadyStatus,
  RequestStartAnalysis,
  SuccessStartAnalysis,
  FailureStartAnalysis,
  RequestTakeScreenshot,
  SuccessTakeScreenshot,
  FailureTakeScreenshot,
  RequestDownloadFile,
  SuccessDownloadFile,
  FailureDownloadFile,
  RequestCheckNetworkConnection,
  SuccessCheckNetworkConnection,
  FailureCheckNetworkConnection,
  RequestIncreaseRuntime,
  SuccessIncreaseRuntime,
  FailureIncreaseRuntime,
  RequestStartMouseEmulation,
  SuccessStartMouseEmulation,
  FailureStartMouseEmulation,
  RequestEditComment,
  SuccessEditComment,
  RequestGetHtmlReport,
  SuccessGetHtmlReport,
  FailureGetHtmlReport,
  RequestArtifactSubmission,
  SuccessArtifactSubmission,
  FailureArtifactSubmission,
  RequestGetReportLogo,
  SuccessGetReportLogo,
  FailureGetReportLogo,
  RequestSetReportLogo,
  SuccessSetReportLogo,
  FailureSetReportLogo,
  RequestEnableClipboard,
  SuccessEnableClipboard,
  FailureEnableClipboard,
  RequestNetwork,
  SuccessNetwork,
  FailureNetwork,
  RequestExtractedPayloads,
  SuccessExtractedPayloads,
  FailureExtractedPayloads,
  RequestDroppedFiles,
  SuccessDroppedFiles,
  FailureDroppedFiles,
  RequestMemoryDumps,
  SuccessMemoryDumps,
  FailureMemoryDumps,
  RequestPcapFile,
  SuccessPcapFile,
  FailurePcapFile,
  RequestIocUrls,
  SuccessIocUrls,
  FailureIocUrls,
  RequestIocDomainsAndIps,
  SuccessIocDomainsAndIps,
  FailureIocDomainsAndIps,
  convertNewSystemRequest,
  convertNewSystemSuccess,
  convertNewSystemFailure,
  RequestStaticFindings,
  SuccessStaticFindings,
  FailureStaticFindings,
  RequestHostsAndDomains,
  SuccessHostsAndDomains,
  FailureHostsAndDomains,
  RequestScreenshots,
  SuccessScreenshots,
  FailureScreenshots,
  RequestGetPreStaticResults,
  SuccessGetPreStaticResults,
  FailureGetPreStaticResults,
} from "../actions/analysisReportActions";
import axiosApi from "../../utils/axios";
import { addNotification } from "../actions/notifierActions";
import { historyPush } from "../actions/historyActions";
import { customFormData } from "../../utils/customFormData";
import { updateLocalStorageForRemoteConnection } from "../../utils/function";

export function* generalAnalysis({ payload }) {
  const { parentID, clickedFileID } = payload;
  try {
    const response = yield axiosApi.get(
      `/api/completed/generalAnalysis/${parentID}/${clickedFileID}`,
    );
    yield put(SuccessGeneralAnalysis(response.data));
  } catch (error) {
    yield put(FailureGeneralAnalysis(error));
  }
}

export function* getSignatures({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/getSignatures/${id}`);
    yield put(SuccessGetSignatures({ data: response.data }));
  } catch (error) {
    yield put(FailureGetSignatures(error));
  }
}

export function* p2signatureList({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/overview/p2signatures/${id}`);
    yield put(SuccessP2signatureList(response.data));
  } catch (error) {
    yield put(FailureP2signatureList(error));
  }
}

export function* filesInfo({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/files/info/${id}`);
    yield put(SuccessFilesInfo(response.data));
  } catch (error) {
    yield put(FailureFilesInfo(error));
  }
}

export function* getVm({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/files/getVm/${id}`);
    yield put(SuccessGetVm(response.data));
  } catch (error) {
    yield put(FailureGetVm(error));
  }
}

export function* attackMapTree({ payload: fileId }) {
  try {
    const response = yield axiosApi.get(`/api/completed/attackMap/tree/${fileId}`);
    yield put(SuccessAttackMapTree(response.data));
  } catch (error) {
    yield put(FailureAttackMapTree(error));
  }
}

export function* mitreAttack({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/mitre/${id}`);
    yield put(SuccessMitreAttack(response.data));
  } catch (error) {
    yield put(FailureMitreAttack(error));
  }
}

export function* addComment({ payload: data }) {
  try {
    const formData = customFormData(data);
    const response = yield axiosApi.post("/api/completed/comments/add", formData);
    yield put(SuccessAddComment(response.data));
    yield put(addNotification({ message: "Added your comment", options: { variant: "success" } }));
  } catch (error) {
    yield put(FailureAddComment(error));
  }
}

export function* editComment({ payload }) {
  const id = payload.id.$oid;
  const updatedText = payload.updatedText;
  const fileId = payload.fileId;
  const formData = customFormData({ updated_text: updatedText });
  try {
    const response = yield axiosApi.put(`/api/completed/comments/edit/${id}`, formData);
    yield put(SuccessEditComment());
    yield put(
      addNotification({ message: response?.data?.message, options: { variant: "success" } }),
    );
    yield call(getComment, { payload: fileId });
  } catch (error) {
    console.log("error", error);
    yield put(FailureDeleteComment(error));
    yield put(
      addNotification({ message: "Error: Edit comment failed", options: { variant: "error" } }),
    );
  }
}

export function* getComment({ payload: fileId }) {
  try {
    const response = yield axiosApi.get(`/api/completed/comments/get/${fileId}`);
    yield put(SuccessGetComment(response.data));
  } catch (error) {
    yield put(FailureGetComment(error));
  }
}

export function* deleteComment({ payload: data }) {
  const id = data.$oid;
  try {
    const response = yield axiosApi.delete(`/api/completed/comments/delete/${id}`);
    yield put(SuccessDeleteComment(response.data));
    yield put(addNotification({ message: "Delete your comment", options: { variant: "success" } }));
  } catch (error) {
    yield put(FailureDeleteComment(error));
    yield put(
      addNotification({ message: "Error: Delete your comment", options: { variant: "error" } }),
    );
  }
}

export function* extractedPayloads({ payload }) {
  const { parentID, clickedFileID } = payload;
  try {
    const response = yield axiosApi.get(
      `/api/completed/extracted-payloads/${parentID}/${clickedFileID}`,
    );
    yield put(SuccessExtractedPayloads(response.data?.extracted_payloads));
  } catch (error) {
    yield put(FailureExtractedPayloads(error));
  }
}

export function* droppedFiles({ payload }) {
  const { parentID, clickedFileID } = payload;
  try {
    const response = yield axiosApi.get(
      `/api/completed/dropped-files/${parentID}/${clickedFileID}`,
    );
    yield put(SuccessDroppedFiles(response.data?.dropped_files));
  } catch (error) {
    yield put(FailureDroppedFiles(error));
  }
}

export function* memoryDumps({ payload }) {
  const { parentID, clickedFileID } = payload;
  try {
    const response = yield axiosApi.get(`/api/completed/memory-dumps/${parentID}/${clickedFileID}`);
    yield put(SuccessMemoryDumps(response.data?.memory_dumps));
  } catch (error) {
    yield put(FailureMemoryDumps(error));
  }
}

export function* pcapFile({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/pcap/${id}`);
    yield put(SuccessPcapFile(response.data?.pcap));
  } catch (error) {
    yield put(FailurePcapFile(error));
  }
}

export function* network({ payload }) {
  const { parentID, clickedFileID } = payload;
  try {
    const response = yield axiosApi.get(`/api/completed/network/${parentID}/${clickedFileID}`);
    if (typeof response.data === "string") {
      try {
        const cleanedData = response.data.replace(/NaN/g, "null");
        const jsonData = JSON.parse(cleanedData);
        yield put(SuccessNetwork(jsonData));
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      yield put(SuccessNetwork(response.data));
    }
  } catch (error) {
    yield put(FailureNetwork(error));
  }
}

export function* staticInfoList({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/others/static/${id}`);
    yield put(SuccessStaticInfo(response.data));
  } catch (error) {
    yield put(FailureStaticInfo(error));
  }
}

export function* chatGPTList({ payload: data }) {
  try {
    const response = yield axiosApi.get(
      `/api/completed/chatgpt/${data.id}?Refresh=${data.refresh}`,
    );
    yield put(SuccessChatGPT(response.data));
  } catch (error) {
    yield put(FailureChatGPT(error));
  }
}

export function* fileDetails({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/others/file-details/${id}`);
    yield put(SuccessFileDetails(response.data));
  } catch (error) {
    yield put(FailureFileDetails(error));
  }
}

export function* extractedStrings({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/extracted/${id}`);
    yield put(SuccessExtractedStrings(response.data));
  } catch (error) {
    yield put(FailureExtractedStrings(error));
  }
}

export function* attackMapCalls({ payload }) {
  const { originalId, processId } = payload;
  try {
    const response = yield axiosApi.get(
      `/api/completed/attackMap/calls/${originalId}/${processId}`,
    );
    yield put(SuccessAttackMapCalls(response.data));
  } catch (error) {
    yield put(FailureAttackMapCalls(error));
  }
}

export function* attackMapCallsByCategory({ payload }) {
  const { originalId, processId } = payload;
  try {
    const response = yield axiosApi.get(
      `/api/completed/attackMap/category-calls/${originalId}/${processId}`,
    );
    yield put(SuccessAttackMapCallsByCategory(response.data));
  } catch (error) {
    yield put(FailureAttackMapCallsByCategory(error));
  }
}

export function* attackMapSummary({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/attackMap/summary/${id}`);
    yield put(SuccessAttackMapSummary(response.data));
  } catch (error) {
    yield put(FailureAttackMapSummary(error));
  }
}

export function* attackMapEnhanced({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/attackMap/enhanced/${id}`);
    yield put(SuccessAttackMapEnhanced(response.data));
  } catch (error) {
    yield put(FailureAttackMapEnhanced(error));
  }
}

export function* stopMouseEmulation({ payload }) {
  const { vm, fileID } = payload;
  try {
    yield axiosApi.post(`/api/files/stop-mouse-emulation/${vm}`);
    updateLocalStorageForRemoteConnection(fileID, "stopMouseEmulation");
    yield put(SuccessStopMouseEmulation());
    yield put(
      addNotification({ message: "Stop mouse emulation success", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(FailureStopMouseEmulation());
    yield put(
      addNotification({ message: "Stop mouse emulation failed", options: { variant: "error" } }),
    );
  }
}

export function* liveUploadFile({ payload }) {
  const formData = customFormData({ file: payload.file });
  try {
    yield axiosApi.post(`/api/files/live-upload-file/${payload.vm}`, formData);
    yield put(SuccessLiveUploadFile());
    yield put(addNotification({ message: "Upload file success", options: { variant: "success" } }));
  } catch (error) {
    yield put(FailureLiveUploadFile());
    yield put(addNotification({ message: "File upload failed", options: { variant: "error" } }));
  }
}

export function* finishAnalysis({ payload: vm }) {
  try {
    const response = yield axiosApi.post(`/api/files/finish-analysis/${vm}`);
    yield put(SuccessFinishAnalysis(response.data));
    yield put(
      addNotification({ message: "Finish analysis success", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(FailureFinishAnalysis(error));
    yield put(
      addNotification({ message: "Finish analysis failed", options: { variant: "error" } }),
    );
  }
}

export function* memoryAddresMapping({ payload }) {
  try {
    const { originalId, processId } = payload;
    const response = yield axiosApi.get(
      `/api/completed/attackMap/memory-address-mapping/${originalId}/${processId}`,
    );
    yield put(SuccessMemoryAddressMapping(response.data.procmemory.address_space));
  } catch (error) {
    yield put(FailureMemoryAddressMapping(error));
  }
}

export function* readyStatus({ payload }) {
  try {
    const response = yield axiosApi.get(`/api/files/ready-status/${payload}`);
    yield put(SuccessReadyStatus(response.data));
  } catch (error) {}
}

export function* startAnalysis({ payload }) {
  const { vm, fileID } = payload;
  try {
    yield axiosApi.post(`/api/files/start-analysis/${vm}`);
    updateLocalStorageForRemoteConnection(fileID, "startAnalysis");
    yield put(SuccessStartAnalysis());
    yield put(
      addNotification({ message: "Start analysis success", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(FailureStartAnalysis());
    yield put(addNotification({ message: "Start analysis failed", options: { variant: "error" } }));
  }
}

export function* startMouseEmulation({ payload }) {
  const { vm, fileID } = payload;
  try {
    yield axiosApi.post(`/api/files/start-mouse-emulation/${vm}`);
    updateLocalStorageForRemoteConnection(fileID, "startMouseEmulation");
    yield put(SuccessStartMouseEmulation());
    yield put(
      addNotification({
        message: "Start mouse emulation success",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(FailureStartMouseEmulation());
    yield put(
      addNotification({ message: "Start mouse emulation failed", options: { variant: "error" } }),
    );
  }
}

export function* takeScreenshot({ payload: vm }) {
  try {
    yield axiosApi.post(`/api/files/take-screenshot/${vm}`);
    yield put(SuccessTakeScreenshot());
    yield put(
      addNotification({ message: "Take screenshot success", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(FailureTakeScreenshot());
    yield put(
      addNotification({ message: "Take screenshot failed", options: { variant: "error" } }),
    );
  }
}

export function* downloadFile({ payload: vm }) {
  try {
    const response = yield axiosApi.post(
      `/api/files/download-file/${vm}`,
      {},
      {
        responseType: "blob",
      },
    );

    const fileName = response.headers["content-disposition"].split("filename=")[1];
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers["content-type"] }),
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    yield put(SuccessDownloadFile());
    yield put(
      addNotification({ message: "Download file success", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(FailureDownloadFile());
    yield put(addNotification({ message: "Download file failed", options: { variant: "error" } }));
  }
}

export function* checkNetworkConnection({ payload: vm }) {
  try {
    yield axiosApi.post(`/api/files/check-network-connection/${vm}`);
    yield put(SuccessCheckNetworkConnection());
    yield put(
      addNotification({
        message: "Check network connection success",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(FailureCheckNetworkConnection());
    yield put(
      addNotification({
        message: "Check network connection failed",
        options: { variant: "error" },
      }),
    );
  }
}

export function* enableClipboard({ payload }) {
  const { vm, fileID } = payload;
  // console.log(vm, fileID);
  try {
    yield axiosApi.post(`/api/files/enable-clipboard/${vm}`);
    updateLocalStorageForRemoteConnection(fileID, "enableClipboard");
    yield put(SuccessEnableClipboard());
    yield put(
      addNotification({
        message: "Enable clipboard success",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(FailureEnableClipboard());
    yield put(
      addNotification({
        message: "Enable clipboard failed",
        options: { variant: "error" },
      }),
    );
  }
}

export function* increaseRuntime({ payload: vm }) {
  try {
    const response = yield axiosApi.post(`/api/files/increase-runtime/${vm}`);
    yield put(SuccessIncreaseRuntime(response.data));
    yield put(
      addNotification({
        message: "Increase runtime success",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(FailureIncreaseRuntime(error));
    yield put(
      addNotification({
        message: "Increase runtime failed",
        options: { variant: "error" },
      }),
    );
  }
}

export function* getHtmlReport({ payload: id }) {
  try {
    const response = yield axiosApi.get(`/api/completed/report/html/${id}`);
    yield put(SuccessGetHtmlReport(response.data.html));
  } catch (error) {
    yield put(FailureGetHtmlReport(error));
  }
}

export function* submitArtifact({ payload: data }) {
  const formData = customFormData({});

  formData.append("type", JSON.stringify(data[0].type));
  formData.append("command_line", JSON.stringify(data[0].command_line));
  formData.append("enviromental_variable", JSON.stringify(data[0].enviromental_variable));
  formData.append("related_password", JSON.stringify(data[0].related_password));
  formData.append("runtime_duration", JSON.stringify(data[0].runtime_duration));
  formData.append("enforse_runtime", JSON.stringify(data[0].enforse_runtime));
  formData.append("enforse_submission", JSON.stringify(data[0].enforse_submission));
  formData.append("memory_dump", JSON.stringify(data[0].memory_dump));
  formData.append("network_traffic", JSON.stringify(data[0].network_traffic));
  formData.append("action_script", JSON.stringify(data[0].action_script));
  formData.append("machine", JSON.stringify(data[0].machine));
  try {
    yield axiosApi.post(
      `/api/files/submit/artifact/${data[0].fileIDfull}/${data[0].source}/${data[0].fileName}/${data[0].fileHash}`,
      formData,
    );
    yield put(SuccessArtifactSubmission());
    yield put(
      addNotification({ message: "Submitted artifact file", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(FailureArtifactSubmission(error));
    yield put(
      addNotification({ message: "Artifact submission failed", options: { variant: "error" } }),
    );
  }
}

export function* getReportLogo() {
  try {
    const response = yield axiosApi.get("/api/reports/getreportlogo");

    yield put(SuccessGetReportLogo(response.data.report_img));
  } catch (error) {
    yield put(FailureGetReportLogo(error));
  }
}

export function* setReportLogo({ payload: img }) {
  try {
    const formData = new FormData();
    formData.append("img", img);

    const response = yield axiosApi.post("/api/reports/setreportlogo", formData);

    yield put(SuccessSetReportLogo(response));
    yield put(
      addNotification({
        message: response.data.message,
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(FailureSetReportLogo(error));
  }
}

export function* iocUrls({ payload: timeFilter }) {
  try {
    const response = yield call(
      axiosApi.get,
      timeFilter ? `/api/reports/ioc/urls?filterByLast=${timeFilter.id}` : "/api/reports/ioc/urls",
    );
    if (typeof response.data === "string") {
      try {
        const cleanedData = response.data.replace(/NaN/g, "null");
        const jsonData = JSON.parse(cleanedData);
        yield put(SuccessIocUrls(jsonData.ioc_urls));
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      yield put(SuccessIocUrls(response.data.ioc_urls));
    }
  } catch (error) {
    console.log(error);
    yield put(FailureIocUrls());
  }
}

export function* iocDomainsAndIps({ payload: timeFilter }) {
  try {
    const response = yield call(
      axiosApi.get,
      timeFilter
        ? `/api/reports/ioc/hosts-and-domains?filterByLast=${timeFilter.id}`
        : "/api/reports/ioc/hosts-and-domains",
    );
    if (typeof response.data === "string") {
      try {
        const cleanedData = response.data.replace(/NaN/g, "null");
        const jsonData = JSON.parse(cleanedData);
        yield put(SuccessIocDomainsAndIps(jsonData.ioc_hosts_and_domains));
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      yield put(SuccessIocDomainsAndIps(response.data.ioc_hosts_and_domains));
    }
  } catch (error) {
    console.log(error);
    yield put(FailureIocDomainsAndIps());
  }
}

export function* convertNewSystem() {
  try {
    const response = yield axiosApi.get("/api/reports/converttonewsystem");

    yield put(convertNewSystemSuccess(response));
    yield put(
      addNotification({
        message: "Convert data success !",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(convertNewSystemFailure(error));
    yield put(
      addNotification({
        message: "Convert data error !",
        options: { variant: "error" },
      }),
    );
  }
}

export function* staticFindings({ payload: id }) {
  try {
    const response = yield call(axiosApi.get, `/api/completed/static-findings/${id}`);
    yield put(SuccessStaticFindings(response.data.static_findings));
  } catch (e) {
    yield put(FailureStaticFindings(e));
  }
}

export function* hostsAndDomains({ payload }) {
  const { parentID, clickedFileID } = payload;
  try {
    const response = yield call(
      axiosApi.get,
      `/api/completed/hosts-and-domains/${parentID}/${clickedFileID}`,
    );
    if (typeof response.data === "string") {
      try {
        const cleanedData = response.data.replace(/NaN/g, "null");
        const jsonData = JSON.parse(cleanedData);
        yield put(SuccessHostsAndDomains(jsonData.hosts_and_domains));
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      yield put(SuccessHostsAndDomains(response.data.hosts_and_domains));
    }
  } catch (e) {
    yield put(FailureHostsAndDomains());
  }
}

export function* screenshots({ payload: id }) {
  try {
    const response = yield call(axiosApi.get, `/api/completed/screenshots/${id}`);
    yield put(SuccessScreenshots(response.data));
  } catch (e) {
    yield put(FailureScreenshots());
  }
}

export function* getPreStaticResults({ payload: hash }) {
  try {
    const response = yield call(axiosApi.get, `/api/completed/pre-static-results/${hash}`);
    yield put(SuccessGetPreStaticResults({ hash, data: response.data.static_pre_result }));
  } catch (e) {
    yield put(FailureGetPreStaticResults({ hash, error: e }));
  }
}

const analysisReportsSagas = [
  takeEvery(RequestGeneralAnalysis, generalAnalysis),
  takeEvery(RequestGetSignatures, getSignatures),
  takeEvery(RequestFilesInfo, filesInfo),
  takeEvery(RequestGetVm, getVm),
  takeEvery(RequestAttackMapTree, attackMapTree),
  takeEvery(RequestMitreAttack, mitreAttack),
  takeEvery(RequestAddComment, addComment),
  takeEvery(RequestGetComment, getComment),
  takeEvery(RequestP2signatureList, p2signatureList),
  takeEvery(RequestDeleteComment, deleteComment),
  takeEvery(RequestStaticInfo, staticInfoList),
  takeLatest(RequestChatGPT, chatGPTList),
  takeEvery(RequestFileDetails, fileDetails),
  takeEvery(RequestExtractedStrings, extractedStrings),
  takeEvery(RequestAttackMapCalls, attackMapCalls),
  takeEvery(RequestAttackMapCallsByCategory, attackMapCallsByCategory),
  takeEvery(RequestAttackMapSummary, attackMapSummary),
  takeEvery(RequestAttackMapEnhanced, attackMapEnhanced),
  takeLatest(RequestStopMouseEmulation, stopMouseEmulation),
  takeEvery(RequestLiveUploadFile, liveUploadFile),
  takeLatest(RequestFinishAnalysis, finishAnalysis),
  takeEvery(RequestMemoryAddressMapping, memoryAddresMapping),
  takeEvery(RequestReadyStatus, readyStatus),
  takeLatest(RequestStartAnalysis, startAnalysis),
  takeLatest(RequestStartMouseEmulation, startMouseEmulation),
  takeLatest(RequestTakeScreenshot, takeScreenshot),
  takeLatest(RequestDownloadFile, downloadFile),
  takeLatest(RequestCheckNetworkConnection, checkNetworkConnection),
  takeLatest(RequestEnableClipboard, enableClipboard),
  takeLatest(RequestIncreaseRuntime, increaseRuntime),
  takeLatest(RequestEditComment, editComment),
  takeLatest(RequestGetHtmlReport, getHtmlReport),
  takeEvery(RequestArtifactSubmission, submitArtifact),
  takeEvery(RequestGetReportLogo, getReportLogo),
  takeEvery(RequestSetReportLogo, setReportLogo),
  takeLatest(RequestNetwork, network),
  takeLatest(RequestExtractedPayloads, extractedPayloads),
  takeLatest(RequestDroppedFiles, droppedFiles),
  takeLatest(RequestMemoryDumps, memoryDumps),
  takeLatest(RequestPcapFile, pcapFile),
  takeLatest(RequestIocUrls, iocUrls),
  takeLatest(RequestIocDomainsAndIps, iocDomainsAndIps),
  takeEvery(convertNewSystemRequest, convertNewSystem),
  takeLatest(RequestStaticFindings, staticFindings),
  takeLatest(RequestHostsAndDomains, hostsAndDomains),
  takeLatest(RequestScreenshots, screenshots),
  takeEvery(RequestGetPreStaticResults, getPreStaticResults),
];

export default analysisReportsSagas;
