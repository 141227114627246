import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FileAnalysis from "./FileAnalysis/FileAnalysis";
import ScriptAnalysis from "./ScriptAnalysis/ScriptAnalysis";
import UrlAnalysis from "./UrlAnalysis/UrlAnalysis";
import TabButton from "../../../common/TabButton/TabButton";
import InformationPopup from "../../../common/InformationPopup/InformationPopup";
import { informationPopupContent } from "../../../common/InformationPopup/utils/informationPopupUtil";

import "./styles.scss";

const FileCard = () => {
  const [active, setActive] = useState("File Analysis");
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);

  return (
    <div className="file-card">
      {!licenceCheck?.key && (
        <div className="file-card__content--licence">
          <p>Your license is not active, please update your license key.</p>
          <p>
            Click
            <Link to="/system-info" className="file-card__content--licence-font">
              <span> Here </span>
            </Link>
            to update your license key.
          </p>
        </div>
      )}
      <div
        className={`file-card__content border ${
          licenceCheck?.key ? "file-card__content--spudrapis8o" : "file-card__content--frastlci4lw"
        }`}>
        <div className="button-block">
          <TabButton onClick={setActive} title="File Analysis" active={active} />
          <TabButton onClick={setActive} title="Script Analysis" active={active} />
          <TabButton onClick={setActive} title="URL Analysis" active={active} />
        </div>
        {active === "File Analysis" ? (
          <FileAnalysis />
        ) : active === "Script Analysis" ? (
          <ScriptAnalysis />
        ) : (
          <UrlAnalysis />
        )}
        <span className="file-card__information-popup">
          <InformationPopup
            content={informationPopupContent.fileAnalysis}
            iconWidth={32}
            iconHeight={32}
          />
        </span>
      </div>
    </div>
  );
};

export default FileCard;
