import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestNewFile, RequestQuickSubmit } from "../../../../../store/actions/dashboardActions";
import FileCardButton from "../../../../UI/FileCardButton/FileCardButton";
import TumerLight from "../../../../Icon-SVG/TumerLight";
import Warning from "../../../../common/Warning/Warning";
import { addNotification } from "../../../../../store/actions/notifierActions";

const Quick = ({ selectedFiles, setSelectedFiles }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const licenceCheck = useSelector((state) => state?.users?.licenceCheck || {});
  const [licenceErrorCalled, setLicenceErrorCalled] = useState(false);

  const submitFiles = () => {
    if (selectedFiles.length === 0) {
      setError("Please select at least one file");
      setShow(true);
    } else {
      const quickSubmitFiles = selectedFiles.map((data) => ({
        file: data.file,
        enforceSubmission: false,
      }));
      dispatch(RequestQuickSubmit(quickSubmitFiles));
      setSelectedFiles([]);
    }
  };

  const licenceError = () => {
    setLicenceErrorCalled(false);
    setActive(false);
    dispatch(
      addNotification({
        message: "Your license key has expired. Please contact CyberFortress Technology Team.",
        options: { variant: "error" },
      }),
    );
  };

  useEffect(() => {
    if (active && licenceCheck.licenceStatus === "Unavailable" && !licenceErrorCalled) {
      setLicenceErrorCalled(true);
      licenceError();
    }
  }, [active, licenceCheck.licenceStatus, licenceErrorCalled]);
  return (
    <>
      <FileCardButton setActive={setActive}>
        <TumerLight />
        <p>Quick</p>
        <Warning
          onClickOk={() => setShow(false)}
          show={show}
          setShow={setShow}
          text={error}
          article="Warning"
          cancelDisable={true}
          textOK="Okay"
        />
      </FileCardButton>
      {active &&
        (licenceCheck.licenceStatus === "Unavailable" ? null : (submitFiles(), setActive(false)))}
    </>
  );
};

export default Quick;
