import { createSlice } from "@reduxjs/toolkit";

export const name = "networkDefender";

export const networkDefenderSlice = createSlice({
  name,
  initialState: {
    distinctHosts: [],
    distinctHostsLoading: false,
    networkDetections: [],
    networkDetectionsLoading: false,
  },
  reducers: {
    RequestDistinctHosts: (state) => {
      state.distinctHostsLoading = true;
    },
    SuccessDistinctHosts: (state, action) => {
      state.distinctHosts = action.payload;
      state.distinctHostsLoading = false;
    },
    FailureDistinctHosts: (state) => {
      state.distinctHosts = [];
      state.distinctHostsLoading = false;
    },
    RequestNetworkDetections: (state) => {
      state.networkDetectionsLoading = true;
    },
    SuccessNetworkDetections: (state, action) => {
      state.networkDetections = action.payload;
      state.networkDetectionsLoading = false;
    },
    FailureNetworkDetections: (state) => {
      state.networkDetections = [];
      state.networkDetectionsLoading = false;
    },
  },
});

export default networkDefenderSlice;
