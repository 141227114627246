import * as React from "react"

const Csv = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.325c.776.052 1.542.114 2.329.114h177.39c20.75 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.07-2.329.118V0L11.176 46.206v492.311l346.22 47.401v-50.593zm0-174.113h57.407v21.511h-57.407v-21.511zm0-2.688v-21.905h57.407v21.905h-57.407zm0-24.593v-25.13h57.407v25.13h-57.407zm0-27.818v-28.131h57.407v28.131h-57.407zm0-30.425V248.89h57.407v26.798h-57.407zm0-29.097v-26.798h57.408v26.798h-57.408zm2.33-176.115h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.121-2.329-.243V385.018h59.706v-.005h130.669V214.811h-.117v-26.409H413.658v.005h-56.262V70.711c.766-.117 1.533-.235 2.33-.235zm185.357 149.312V382.32h-25.092V227.635h-17.228v154.684H488.41V243.525h-17.228v138.794H458.54V259.223h-17.229v123.096h-24.21V219.788h127.982zm-63.276-2.289v-26.796h63.55v24.108h-.273v2.688h-63.277zm-2.299 0h-63.549v-26.796h63.544v26.796h.005zM98.663 321.536c7.126.127 15.097-1.407 19.812-3.327l3.614 19.927c-4.399 2.226-14.268 4.462-26.974 4.121-35.293-.94-53.019-24.741-53.019-55.626 0-36.998 24.302-58.227 55.329-59.043 12.263-.329 21.67 2.097 25.933 4.328l-4.882 20.282c-4.869-2.092-11.588-3.969-19.969-3.827-18.528.309-32.715 12.422-32.715 36.824-.001 21.957 12.051 35.991 32.871 36.341zm66.1 22.583c-12.567-.341-24.829-4.03-30.909-7.711l4.956-21.087c6.595 3.634 16.809 7.36 27.425 7.537 11.556.2 17.722-4.683 17.722-12.225 0-7.219-5.321-11.366-18.707-16.394-18.228-6.667-29.961-17.096-29.961-33.555 0-19.32 15.455-34.518 41.512-35.2 12.683-.339 22.111 2.179 28.903 5.134l-5.771 21.399c-4.569-2.176-12.633-5.315-23.625-5.134-10.884.178-16.125 5.364-16.125 11.31 0 7.307 6.212 10.482 20.609 16.042 19.99 7.52 29.523 18.271 29.523 34.784-.008 19.635-14.79 35.919-45.552 35.1zm123.29 1.491-32.086-.829-37.772-118.466 29.103-.748 14.551 50.26c4.128 14.224 7.895 27.957 10.772 42.97l.548.01c3.063-14.436 6.874-28.725 11.058-42.528l15.772-52.055 30.277-.769-42.223 122.155z" fill={color} />
  </svg>
)

export default Csv
