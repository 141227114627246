import * as React from "react"

const Wmv = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="M359.036 537.775c.771.053 1.551.116 2.342.116h178.2c20.84 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.071-2.342.113V0L11.222 46.417v494.564L359.036 588.6v-50.825zm0-276.054c11.138-33.025 42.335-56.906 79.06-56.906 46.026 0 83.479 37.444 83.479 83.468 0 46.026-37.452 83.468-83.479 83.468-36.725 0-67.922-23.878-79.06-56.905v-53.125zm2.342-190.923h178.2c8.933 0 16.199 7.27 16.199 16.2v413.103c0 8.934-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.127-2.342-.242V352.572c18.7 23.019 47.155 37.784 79.06 37.784 56.289 0 102.072-45.789 102.072-102.067s-45.783-102.065-102.072-102.065c-31.904 0-60.359 14.763-79.06 37.784V71.031c.771-.114 1.54-.233 2.342-.233zM108.92 346.18l-19.884-.633-6.76-37.367c-1.519-8.718-2.813-16.78-3.752-26.623h-.245c-1.402 9.713-2.7 17.879-4.566 26.533l-7.446 36.729-19.111-.617-17.921-84.182 18.249-.356 5.798 34.889c1.706 10.083 3.312 21.067 4.58 29.668l.222.011c1.263-9.202 3.108-19.417 5.065-29.921l6.616-35.074 19.045-.366 6.452 36.531c1.793 10.226 3.132 19.544 4.327 29.268h.243c1.205-9.692 3.019-19.907 4.714-30.274l6.684-35.952 19.143-.367-21.453 88.103zm107.913 3.459-1.371-35.711c-.422-11.201-.823-24.749-.823-38.296l-.409.005c-2.879 11.892-6.708 25.117-10.238 35.949l-11.077 35.785-15.81-.495-9.439-35.433c-2.869-10.642-5.854-23.538-7.923-35.327h-.264c-.506 12.195-.896 26.12-1.55 37.362l-1.537 34.23-17.951-.58 5.432-89.332 26.138-.509 8.678 30.309c2.776 10.568 5.545 22.001 7.549 32.77h.401c2.531-10.589 5.608-22.702 8.564-32.94l9.745-30.818 27.454-.53 5.203 94.22-20.772-.659zm87.075 2.795-26.558-.854-31.24-95.766 24.068-.458 12.037 40.621c3.406 11.496 6.539 22.602 8.92 34.73l.432.006c2.566-11.628 5.708-23.146 9.166-34.262l13.057-41.95 25.091-.485-34.973 98.418z" fill={color} />
    <path  d="m407.594 352.397 1.234.301c.633 0 1.244-.221 1.751-.622l93.825-59.906a2.926 2.926 0 0 0 1.064-2.228c0-.842-.4-1.659-1.064-2.223l-93.825-59.888a2.803 2.803 0 0 0-2.985-.319c-.98.453-1.592 1.463-1.592 2.534v119.831c0 1.071.611 2.073 1.592 2.52z" fill={color} />
  </svg>
)

export default Wmv
