export const getHours = () => {
  const hours = Array.from({ length: 12 }, (_, i) => (i < 10 ? "0" + i : i));
  return hours.map((number, index) => ({
    id: index,
    value: number,
  }));
};

export const getMinutes = () => {
  const minutes = Array.from({ length: 59 }, (_, i) => (i + 1 < 10 ? "0" + (i + 1) : i + 1));
  return minutes.map((number, index) => ({
    id: index,
    value: number,
  }));
};

export const getYears = () => {
  const years = Array.from({ length: 100 }, (_, i) => i + 1970);
  return years.map((year, index) => ({
    id: index,
    value: year,
  }));
};

export const getDays = (year, month) => {
  const length = new Date(year, month, 0).getDate();
  const days = Array.from({ length }, (_, i) => (i < 10 ? "0" + (i + 1) : i));
  return days.map((day, index) => ({
    id: index,
    value: day,
  }));
};

export const getDateOfISOWeek = (w, y) => {
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
};

export const truncateString = (string, length = 45) => {
  if (string.length <= length) return string;

  const lastIndex = string.lastIndexOf(".");
  const fileName = string;
  let extension = "";
  let howManyChars = length;

  if (lastIndex !== -1) {
    extension = fileName.slice(lastIndex + 1, fileName.length);
    howManyChars = length - extension.length;
  }

  return `${fileName.slice(0, howManyChars)}....${extension}`;
};

export const isCompressedItself = (compressedID, compressedIDfull, fileIDfull) => {
  return compressedID !== "" && compressedIDfull === fileIDfull;
};

export const isNotCompressedItself = (compressedID, compressedIDfull, fileIDfull) => {
  return compressedID !== "" && compressedIDfull !== fileIDfull;
};

export const isRelatedToCompressed = (compressedID) => {
  return compressedID !== "";
};

export const isNotRelatedToCompressed = (compressedID) => {
  return compressedID === "";
};

export const getCompressedItemsOfCompressed = (compressedIDfull, submissionList) => {
  return submissionList.filter(
    (submissionListItem) =>
      submissionListItem.compressedIDfull === compressedIDfull &&
      isNotCompressedItself(
        submissionListItem.compressedID,
        submissionListItem.compressedIDfull,
        submissionListItem.fileIDfull,
      ),
  );
};

export const checkSubmissionisFavorite = (usersFav, email) =>
  usersFav ? usersFav.includes(email) : false;

export const filterByDistinctGroupID = (item, i, arr) =>
  i === arr.findIndex((elem) => elem.groupID === item.groupID);

export const selectGroupSeverity = (submissionList, groupID) => {
  const submissionGroup = submissionList.filter((submission) => submission.groupID === groupID);
  const isAnyLoading = submissionGroup.some((submission) => submission.decision === 99);
  const isAnyNone = submissionGroup.some((submission) => submission.decision === 100);
  const isAnyMalicious = submissionGroup.some((submission) => submission.decision === 2);
  const isAllSuspicious = submissionGroup.every((submission) => submission.decision === 1);
  const isAnyClean = submissionGroup.some((submission) => submission.decision === 0);

  if (isAnyLoading) {
    return "loading";
  } else if (isAnyNone) {
    return "n/a";
  } else if (isAnyMalicious) {
    return "malicious";
  } else if (isAllSuspicious) {
    return "suspicious";
  } else if (isAnyClean) {
    return "not suspicious";
  }
};

export const getStaticLink = (hash, compressedIDfull, compressedID, pageName) => {
  if (isRelatedToCompressed(compressedID)) {
    return `/${pageName}?data=${hash}&table=compressed-static-results&compressed=${compressedIDfull}`;
  } else {
    return `/${pageName}?data=${hash}&table=static-results`;
  }
};

export const calculateSubmissionsPercentage = (
  completedIntegrationSubmissionGroups,
  integrationSubmissions,
  totalSubmissionsCount,
  severity,
) => {
  return (
    (completedIntegrationSubmissionGroups
      ?.map((item) => selectGroupSeverity(integrationSubmissions, item.groupID))
      .filter((elem) => elem === severity).length /
      totalSubmissionsCount) *
    100
  );
};

export const getAllIntegrationSubmissionsFromList = (list) => {
  if (!Array.isArray(list)) {
    return [];
  }
  return list?.filter(
    (submission, i, arr) =>
      submission.integration !== "advanced" &&
      submission.integration !== "" &&
      filterByDistinctGroupID(submission, i, arr) &&
      (isCompressedItself(
        submission.compressedID,
        submission.compressedIDfull,
        submission.fileIDfull,
      ) ||
        isNotRelatedToCompressed(submission.compressedID)),
  );
};

export const getDailyIntegrationSubmissionsFromList = (list) => {
  if (!Array.isArray(list)) {
    return [];
  }
  const submissions = getAllIntegrationSubmissionsFromList(list);
  return submissions?.filter(
    (submission) =>
      submission.run_time.split("  ")[0] ===
      new Date().toLocaleString("zh-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
  );
};

export const updateLocalStorageForRemoteConnection = (fileID, field) => {
  // fileID: fileIDfull
  // field: one of "startAnalysis", "startMouseEmulation", "stopMouseEmulation", "enableClipboard"
  // -------------------------------------------
  // this function helps tracking state(disabled or not) of above buttons
  // on remote connection page by adding/updating/removing items in local storage.

  if (!field) {
    localStorage.removeItem(fileID);
    return;
  }

  const storedData = localStorage.getItem(fileID);

  if (storedData) {
    const parsedData = JSON.parse(storedData);
    parsedData[field] = true;

    const updatedData = JSON.stringify(parsedData);
    localStorage.setItem(fileID, updatedData);
  } else {
    const newData = {
      [field]: true,
    };
    const newDataString = JSON.stringify(newData);
    localStorage.setItem(fileID, newDataString);
  }
};
