import * as React from "react"

const Odc = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M171.949 244.649c-5.333.097-8.767.629-10.782 1.147v71.048c2.016.514 5.299.571 8.272.631 21.84.535 36.337-11.712 36.337-38.195.155-23.035-13.05-34.986-33.827-34.631z" fill={color} />
    <path  d="M357.4 535.33c.767.047 1.543.109 2.33.109h177.39c20.745 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.882-37.628-37.627-37.628H359.73c-.782 0-1.563.077-2.33.113V0L11.17 46.206v492.311l346.23 47.401V535.33zm2.33-464.854h177.39c8.892 0 16.125 7.236 16.125 16.126v411.22c0 8.893-7.233 16.127-16.125 16.127H359.73c-.792 0-1.563-.127-2.33-.232v-74h99.309V423.59H357.4v-28.823h99.309V378.64H357.4V205.281h45.187v-16.127H357.4v-33.021h92.895v-16.127H357.4v-69.29c.767-.117 1.533-.24 2.33-.24zM74.121 334.807c-28.197-.756-44.323-23.99-44.323-52.998 0-30.509 17.809-53.806 45.888-54.533 30.061-.796 46.859 23.275 46.859 52.685-.001 34.951-19.891 55.606-48.424 54.846zm139.784-11.276c-11.53 9.46-28.84 13.592-49.628 13.039-12.228-.33-20.83-1.343-26.622-2.288V228.818c8.546-1.64 19.791-2.729 31.79-3.041 20.242-.528 33.573 2.907 44.137 10.74 11.497 8.401 18.77 22.097 18.77 41.912-.006 21.48-7.783 36.189-18.447 45.102zm90.906 17.281c-40.577-1.093-60.899-25.9-60.899-58.041 0-38.509 27.887-60.673 63.557-61.617 14.132-.367 24.987 2.128 29.89 4.459l-5.638 21.197c-5.606-2.162-13.354-4.129-23.003-3.968-21.331.354-37.614 13.008-37.614 38.423 0 22.879 13.858 37.521 37.793 37.936 8.211.131 17.396-1.443 22.824-3.448l4.179 20.828c-5.094 2.311-16.463 4.619-31.089 4.231z" fill={color} />
    <path  d="M75.095 245.981c-14.614.255-23.035 15.357-23.035 35.382 0 20.169 8.704 34.524 23.184 34.776 14.848.263 23.455-14.908 23.455-35.383 0-18.915-8.462-35.031-23.604-34.775zM382.902 333.794h147.602v16.127H382.902zM385.988 237.037h18.248v79.906h-18.248zM428.53 201.377h18.246v115.571H428.53zM470.86 218.848h18.248v98.101H470.86zM509.297 259.163h18.246v57.785h-18.246z" fill={color} />
  </svg>
)

export default Odc
