import React from "react";
import "./styles.scss";

const FileCardButton = ({ children, setActive }) => {
  return (
    <div className="file-card-button" onClick={() => setActive(true)}>
      {children}
    </div>
  );
};
export default FileCardButton;
