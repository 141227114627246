import * as React from "react";

const OtherIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".other{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <circle
        cx={512}
        cy={512}
        r={421.2}
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 56,
          strokeMiterlimit: 10,
        }}
      />
      <circle className="other" cx={284} cy={512} r={79} />
      <circle className="other" cx={512} cy={512} r={79} />
      <circle className="other" cx={740} cy={512} r={79} />
    </g>
  </svg>
);

export default OtherIcon;
