import * as React from "react";
const EndpointMainIcon = ({ color = "var(--text-color-card)", width = 60, height = 20 }) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M7.5 15.5v-3.741H9V14h8v-2.241h1.5V15.5h-11z"
    ></path>
    <path
      fill={color}
      d="M10.99 10.113l1.26-1.26V12h1.5V8.854l1.26 1.26 1.06-1.061L13 5.983l-3.07 3.07 1.06 1.06z"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M5 3h16v16H5V3zm1.5 1.5h13v13h-13v-13z"
      clipRule="evenodd"
    ></path>
    <path fill={color} d="M2 6v16h16v-1.5H3.5V6H2z"></path>
  </svg>
);
export default EndpointMainIcon;
