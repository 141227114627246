import React from "react";
import TextField from "../../../../../Components/common/TextField/TextField";
import CustomSelect from "../../../../../Components/common/CustomSelect/CustomSelect";
import { roleConstants } from "../../../../../utils/constants";

const ChangeFields = ({
  userName,
  setUserName,
  setRole,
  role,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  button = false,
  error = null,
}) => {
  return (
    <>
      <div className="flex flex-align-items-center">
        <div className="flex-column m-t-20 m-r-15">
          <p className="label">User Name</p>
          <TextField
            placeholder={"Type User Name"}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="flex-column m-t-20 m-l-15">
          <p className="label">Role</p>
          <CustomSelect width={"246px"} data={roleConstants} setSelected={setRole} select={role} />
        </div>
      </div>
      <div className="flex flex-align-items-center">
        <div className="flex-column m-t-20 m-r-15">
          <p className="label">Create Password</p>
          <TextField
            placeholder={"Type Password"}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error}
          />
        </div>
        <div className="flex-column m-t-20 m-l-15 relative">
          <p className="label">Confirm Password</p>
          <TextField
            placeholder={"Type Password"}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={error}
          />
        </div>
      </div>
      <div className="flex-column m-t-20 m-r-15">
        {button && (
          <button className="users-accounts__update-button primary-button " onClick={button}>
            Update User
          </button>
        )}
      </div>
    </>
  );
};

export default ChangeFields;
