const RemoteConnectionIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 -3 64.06 64.06" xmlns="http://www.w3.org/2000/svg">
      <path d="m 561.16106,571.25413 c -2.21807,0 -4.00374,1.7857 -4.00374,4.0037 l 0,38.0355 c 0,2.2181 1.78567,4.0038 4.00374,4.0038 l 20.01869,0 0,8.0075 -8.00747,0 0,2.0018 -4.00374,0 0,2.0019 40.03738,0 0,-2.0019 -4.00373,0 0,-2.0018 -8.00748,0 0,-8.0075 20.01869,0 c 2.21807,0 4.00374,-1.7857 4.00374,-4.0038 l 0,-38.0355 c 0,-2.218 -1.78567,-4.0037 -4.00374,-4.0037 l -56.05234,0 z m 0,4.0037 56.05234,0 0,38.0355 -56.05234,0 0,-38.0355 z m 29.02711,7.5696 -10.88517,5.5051 10.88517,6.5061 11.13539,-6.5061 -11.13539,-5.5051 z m -10.88517,7.507 0,12.0112 10.00935,6.0056 0,-12.0112 -10.00935,-6.0056 z m 22.02056,0.062 -10.00934,6.0056 0,12.0112 10.00934,-6.0056 0,-12.0112 z"
        fill="var(--text-color-card)"
        transform="translate(-557.157 -571.254)"
      />
    </svg>
  )
};

export default RemoteConnectionIcon;
