import React from "react";

const ClipIcon = ({ color = "var(--text-color-card)", width = 15, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4142 16.2426L11.2929 18.3639C9.7308 19.926 7.19814 19.926 5.63604 18.3639V18.3639C4.07395 16.8019 4.07395 14.2692 5.63604 12.7071L7.75736 10.5858"
        stroke={color}
      />
      <path d="M14.8282 9.17151L9.17139 14.8284" stroke={color} />
      <path
        d="M16.2428 13.4142L18.3641 11.2929C19.9262 9.7308 19.9262 7.19814 18.3641 5.63604V5.63604C16.802 4.07395 14.2693 4.07395 12.7072 5.63604L10.5859 7.75736"
        stroke={color}
      />
    </svg>
  );
};

export default ClipIcon;
