import React, { useState } from "react";
import CardForm from "../../../Components/common/CardForm/CardForm";
import TextField from "../../../Components/common/TextField/TextField";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../../Components/UI/Backdrop/Backdrop";
import { resetPasswordRequest } from "../../../store/actions/usersActions";

const NewPassword = ({ setTabs }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.users.loginLoading);
  const [inputs, setInputs] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(false);

  const handleChanges = (e) => {
    setError(false);
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetPassword = () => {
    dispatch(resetPasswordRequest(inputs));
    setTabs("reset-password");
  };

  return (
    <CardForm
      title="Set new password"
      subTitle="Your new password must be different to previously used passwords."
      error={error}>
      <TextField
        placeholder="Enter your password"
        value={inputs.password}
        onChange={handleChanges}
        labelText="Password"
        name="password"
        required={true}
        error={error}
        type="password"
      />
      <TextField
        placeholder="Confirm your password"
        value={inputs.confirmPassword}
        onChange={handleChanges}
        labelText="Confirm password"
        name="confirmPassword"
        required={true}
        error={error}
        type="password"
      />
      <button className="card__form-btn" onClick={resetPassword}>
        Reset password
      </button>
      <p style={{ textAlign: "center" }}>
        Go back to <Link to="/login">Login</Link>
      </p>
      {loading && <Backdrop show={loading} />}
    </CardForm>
  );
};

export default NewPassword;
