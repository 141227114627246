import * as React from "react"

const Pub = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="M303.296 258.541c0-9.305-7.277-14.098-20.06-13.9-6.064.098-9.537.509-11.928.907v28.244l9.176-.034c14.793-.053 22.812-6.107 22.812-15.217zM280.834 293.435l-9.526-.042v34.183c2.755.411 6.043.475 10.832.559 12.761.253 24.173-4.282 24.173-17.233-.001-12.415-11.413-17.403-25.479-17.467zM71.012 248.695c-4.714.077-7.913.612-9.574 1.128v32.611c1.97.498 4.396.653 7.742.653 12.427.011 20.155-6.792 20.155-18.246-.001-10.292-6.648-16.333-18.323-16.146z" fill={color} />
    <path  d="M359.036 537.775c.771.053 1.551.116 2.342.116h178.2c20.84 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.071-2.342.119V0L11.222 46.417v494.564L359.036 588.6v-50.825zm0-466.739c.771-.113 1.54-.237 2.342-.237h178.2c8.933 0 16.199 7.27 16.199 16.2v413.103c0 8.928-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.121-2.342-.242V71.036zM103.253 290.791c-8.142 8.118-20.102 11.673-33.916 11.546-3.04-.031-5.777-.221-7.9-.564v39.493l-22.615-.617V232.825c6.993-1.463 16.875-2.676 30.973-3.029 14.429-.356 24.841 2.384 31.881 8.227 6.784 5.556 11.367 14.871 11.367 25.956.006 11.076-3.482 20.46-9.79 26.812zm117.543 2.718c0 36.618-18.204 53.536-48.06 52.692-28.107-.786-44.318-17.129-44.318-53.457v-63.537l24.898-.599V294.9c0 19.834 7.259 30.049 20.26 30.291 13.479.253 20.97-9.355 20.97-30.059v-67.519l26.249-.644v66.54zm102.539 44.983c-9.302 6.929-24.711 10.525-49.451 9.84-13.635-.38-23.712-1.55-29.787-2.615V228.016c7.161-1.595 21.769-3.019 35.651-3.365 17.205-.422 27.862.96 37.123 6.064 8.933 4.406 15.451 12.759 15.451 24.023 0 11.063-6.697 21.423-21.126 26.589v.358c14.607 3.86 25.545 14.597 25.545 30.681-.001 11.275-5.38 20.04-13.406 26.126z" fill={color} />
    <path  d="M375.416 358.889c.158-.348 3.522-5.849 9.629-10.99 6.181-5.184 14.761-9.961 26.293-9.982 11.871 0 20.926 5.121 27.285 10.531 3.122 2.689 5.579 5.39 7.15 7.404.797 1.018 1.371 1.846 1.762 2.373.163.279.306.495.385.638.032.053.068.101.068.121h.032l11.527-.11.011-.021v-.011c.264-.453 3.607-5.869 9.619-10.952 6.196-5.185 14.808-9.962 26.314-9.988 11.87 0 20.893 5.126 27.284 10.536a52.341 52.341 0 0 1 7.119 7.404c.807 1.018 1.393 1.846 1.767 2.373.2.279.322.495.401.638l.079.101.01.021 12.383-3.153v-99.241l-.887-3.154c-.242-.366-4.187-6.953-12.097-13.708-7.837-6.724-20.019-13.716-36.06-13.716-16.048 0-28.107 7.014-35.833 13.774a66.967 66.967 0 0 0-6.007 6.028 66.967 66.967 0 0 0-6.212-6.086c-7.868-6.724-20.039-13.716-36.103-13.716-16.036 0-28.086 7.014-35.796 13.774-7.746 6.776-11.522 13.386-11.744 13.766l-.827 3.038v99.251l12.413 3.037.035.02zm84.944-100.522c1.371-1.975 4.345-5.933 8.817-9.729 6.201-5.173 14.808-9.951 26.314-9.951 11.875 0 20.893 5.124 27.29 10.513 3.153 2.685 5.531 5.421 7.113 7.431.569.69 1.023 1.318 1.371 1.793v80.291c-7.878-6.655-19.938-13.479-35.774-13.479-15.567-.021-27.386 6.629-35.132 13.179v-80.048zm-84.132 0c1.36-1.99 4.346-5.933 8.817-9.729 6.186-5.173 14.766-9.951 26.293-9.951 11.876 0 20.926 5.124 27.285 10.513 3.122 2.685 5.579 5.421 7.15 7.431.564.69 1.019 1.318 1.329 1.793v80.301c-7.825-6.649-19.901-13.489-35.765-13.489h-.01c-15.578 0-27.38 6.629-35.101 13.179v-80.048z" fill={color} />
  </svg>
)

export default Pub
