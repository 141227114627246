export const trojanGroup = ["dropper", "trojan", "trojandl", "rat"];
export const executionGroup = [
  "wmi",
  "rop",
  "javascript",
  "lolbin",
  "ipc",
  "crash",
  "meterpreter",
  "script",
  "powershell",
  "downloader",
  "shellcode",
  "downloder",
  "execution",
  "commands",
  "command",
];
export const otherGroup = [
  "c&c",
  "c2",
  "impact",
  "wiper",
  "clickfraud",
  "dotnet",
  "loader",
  "escalation",
  "tampering",
  "ads",
  "fraud",
  "fraudtool",
  "hacktool",
  "hooking",
  "misc",
  "ocr",
  "privilege_escalation",
  "tool",
  "vertex",
  "vir",
  "work",
  "pdf",
  "adware",
  "collection",
  "allocation",
  "*Missing Category*",
  "service",
  "bootkit",
  "chinese",
];
export const stealthGroup = [
  "anonimity",
  "decoy",
  "persistence",
  "persistance",
  "evasion",
  "apt",
  "stealth",
  "deception",
];
export const networkGroup = [
  "lateral_movement",
  "filesharing",
  "tor",
  "irc",
  "lateral",
  "bind",
  "freehosting",
  "p2p",
  "udp",
  "icmp",
  "cloud",
  "im",
  "expdom",
  "access",
  "proxy",
  "browser",
  "network",
  "http",
  "cnc",
  "dns",
  "smtp",
  "urlshort",
  "martian",
  "martians",
];
export const infostealerGroup = [
  "exfiltration",
  "phishing",
  "phish",
  "infostealer",
  "keylogger",
  "C24_Stealer",
  "filetransfer",
  "isrstealer",
  "istealer",
  "sharpstealer",
  "stealer",
];
export const ransomwareGroup = ["locker", "ransom", "ransomware"];
export const genericGroup = ["spam", "malware", "generic", "general"];
export const exploitGroup = [
  "silverlight",
  "java",
  "privileges",
  "backdoor",
  "exploit",
  "exploitation",
  "exploit_kit",
];
export const bankerGroup = [
  "blackpos",
  "jackpos",
  "miners",
  "pos",
  "banker",
  "banking",
  "cryptocurrency",
  "cryptomining",
  "mining",
  "bitcoin",
  "crypto",
];
export const officeGroup = ["rtf", "macro", "vba", "office", "macros"];
export const encryptionGroup = ["encryption", "packer", "obuscation", "obfuscation"];
export const spreadingGroup = ["targeted", "virus", "worm", "spreading"];
export const injectionGroup = ["Webshell", "injector", "injection"];
export const botGroup = [
  "athena",
  "madness",
  "bot",
  "ddos",
  "betabot",
  "ponybot",
  "solarbot",
  "warbot",
];
export const credentialsGroup = ["ssh", "account", "credential_access", "credential_dumping"];
export const discoveryGroup = [
  "sniffer",
  "process_discovery",
  "spyware",
  "Cloudflare",
  "discovery",
  "recon",
];
export const staticGroup = ["origin", "static", "static]"];
export const avDefenseGroup = [
  "rootkit",
  "masquerading",
  "applocker",
  "amsi",
  "clamav",
  "antiav",
  "anti-av",
  "uac_bypass",
  "anti-analysis",
  "mlchecker",
  "yarachecker",
  "bypass",
  "antivirus",
  "ddgchecker",
  "unpacking",
  "avdetect",
  "anti-emulation",
  "antisandbox",
  "anti-sandbox",
  "anti-vm",
  "antivm",
  "AntiVM",
  "anti-debug",
];

export const CategoryCheck = (signatures) => {
  if (signatures.length !== 0) {
    const groupConstants = [
      trojanGroup,
      executionGroup,
      otherGroup,
      stealthGroup,
      networkGroup,
      infostealerGroup,
      ransomwareGroup,
      genericGroup,
      exploitGroup,
      bankerGroup,
      officeGroup,
      encryptionGroup,
      spreadingGroup,
      injectionGroup,
      discoveryGroup,
      botGroup,
      staticGroup,
      credentialsGroup,
    ];

    const groupNames = [
      "Trojan",
      "Execution",
      "Other",
      "Stealth",
      "Network",
      "Infostealer",
      "Ransomware",
      "Generic",
      "Exploit",
      "Banker",
      "Office",
      "Encryption",
      "Spreading",
      "Injection",
      "Discovery",
      "Bot",
      "Static",
      "Credentials",
    ];

    const bigCounter = groupConstants.reduce((max, group) => {
      let counter = 0;
      group.forEach((item) => {
        signatures.forEach((key) => {
          if (item === key.categories) {
            counter += key.severity * 0.25 > 1 ? 1 : key.severity * 0.25;
          }
        });
      });
      return Math.max(max, counter);
    }, 0);

    const signaturesData = groupConstants
      .map((group, i) => {
        let counter = 0;
        group.forEach((item) => {
          signatures.forEach((key) => {
            if (item === key.categories) {
              counter += key.severity * 0.25 > 1 ? 1 : key.severity * 0.25;
            }
          });
        });
        const value = Number((100 * counter) / bigCounter) + 12;
        return { header: groupNames[i], value };
      })
      .filter((item) => item.value !== 12)
      .sort((a, b) => b.value - a.value);

    return signaturesData;
  }
};
