import React from "react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ScrollToTop from "./utils/ScrollToTop";
import SessionTimeout from "./Components/common/SessionTimeout/SessionTimeout";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
        },
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      fullWidth: true,
    },
  },
});

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <SnackbarProvider preventDuplicate={false} maxSnack={1} autoHideDuration={1000}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </SnackbarProvider>
    </BrowserRouter>
    <SessionTimeout />
  </Provider>,
);
