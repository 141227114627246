import { createSlice } from "@reduxjs/toolkit";

const integrationsSlice = createSlice({
  name: "integrations",
  initialState: {
    loading: false,
    deployInt: "",
    getConfig: "",
    deleteConfig: "",
    updateConfig: "",
    deleteInt: "",
    setConfig: "",
    error: "",
    checkConfig: false,
    action: false,
    loadingUpdate: false,
    agentStatus: [],
  },
  reducers: {
    RequestDeployInt: (state) => {
      state.loading = true;
      state.action = true;
    },
    SuccessDeployInt: (state, payload) => {
      state.loading = false;
      state.deployInt = payload.payload;
      state.action = false;
    },
    FailureDeployInt: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
      state.action = false;
    },
    RequestGetConfig: (state) => {
      state.loading = true;
    },
    SuccessGetConfig: (state, payload) => {
      state.loading = false;
      state.getConfig = payload.payload;
    },
    FailureGetConfig: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestDeleteInt: (state) => {
      state.loading = true;
      state.action = true;
    },
    SuccessDeleteInt: (state, payload) => {
      state.loading = false;
      state.deleteInt = payload.payload;
      state.action = false;
    },
    FailureDeleteInt: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
      state.action = false;
    },
    RequestSetConfig: (state) => {
      state.loading = true;
    },
    SuccessSetConfig: (state, payload) => {
      state.loading = false;
      state.setConfig = payload.payload;
    },
    FailureSetConfig: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestCheckConfig: (state) => {
      state.loading = true;
    },
    SuccessCheckConfig: (state, payload) => {
      state.loading = false;
      state.checkConfig = payload.payload;
    },
    FailureCheckConfig: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    ClearGetConfig: (state) => {
      state.getConfig = "";
      state.deleteConfig = "";
      state.updateConfig = "";
      state.setConfig = "";
    },
    RequestDeleteConfig: (state) => {
      state.loading = true;
    },
    SuccessDeleteConfig: (state, payload) => {
      state.loading = false;
      state.deleteConfig = payload.payload;
    },
    FailureDeleteConfig: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestUpdateConfig: (state) => {
      state.loadingUpdate = true;
    },
    SuccessUpdateConfig: (state, payload) => {
      state.loadingUpdate = false;
      state.updateConfig = payload.payload;
    },
    FailureUpdateConfig: (state, payload) => {
      state.loadingUpdate = false;
      state.error = payload.payload;
    },
    RequestAgentStatus: (state) => {
      state.loading = true;
    },
    SuccessAgentStatus: (state, payload) => {
      state.loading = false;
      state.agentStatus = payload.payload;
    },
    FailureAgentStatus: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
  },
});

export default integrationsSlice;
