import * as React from "react"

const Mim = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.335c.776.052 1.542.114 2.329.114h177.39c20.756 0 37.627-16.883 37.627-37.627V86.604c0-20.746-16.871-37.628-37.627-37.628h-177.39c-.787 0-1.553.074-2.329.124V0L11.176 46.208v492.308l346.22 47.402v-50.583zm113.125-288.067h41.522l.011 167.394H357.396v-220.2h108.421v48.113a4.691 4.691 0 0 0 4.704 4.693zM359.726 70.479h177.39c8.893 0 16.125 7.232 16.125 16.125v411.218c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.117-2.329-.231v-88.874h154.646c5.196 0 9.408-4.22 9.408-9.396V243.047c0-.175-.021-.354-.042-.524-.012-1.103-.357-2.194-1.113-3.042l-46.248-52.819c0-.01-.021-.01-.031-.031a4.347 4.347 0 0 0-.94-.78c-.099-.076-.198-.123-.31-.188a4.676 4.676 0 0 0-.924-.386c-.094-.034-.161-.065-.252-.086a4.533 4.533 0 0 0-1.081-.133H357.396V70.72c.766-.113 1.533-.241 2.33-.241zM109.739 354.53l-1.491-41.141c-.44-12.903-.879-28.509-.879-44.097l-.44.005c-3.098 13.701-7.205 28.911-10.982 41.337l-11.79 40.909-16.732-.805-9.903-40.388c-3.003-12.111-6.121-26.744-8.271-40.109l-.275.005c-.538 13.833-.945 29.625-1.617 42.361l-1.609 38.762-18.604-.914 5.607-100.767 27.221-.919 9.113 34.429c2.939 12.052 5.879 25.089 7.977 37.394l.431.011c2.688-12.084 5.939-25.94 9.079-37.662l10.385-35.42 29.597-.992 5.648 109.099-22.465-1.098zm65.266 3.228-24.576-1.224V245.729l24.576-.826v112.855zm127.393 6.277-1.948-47.264c-.589-14.831-1.171-32.741-1.171-50.653l-.582.009c-4.09 15.743-9.486 33.195-14.468 47.447l-15.486 46.825-21.911-1.046-12.934-46.21c-3.919-13.821-7.966-30.521-10.783-45.77l-.354.005c-.693 15.787-1.22 33.785-2.102 48.31l-2.092 44.207-24.134-1.192 7.268-114.697 35.413-1.2 11.906 39.324c3.824 13.775 7.678 28.71 10.446 42.799l.56.01c3.504-13.826 7.787-29.688 11.916-43.115l13.627-40.645 39.082-1.312 7.491 125.629-29.744-1.461z" fill={color} />
    <path  d="M371.318 269.958c-.055.052-.086.113-.138.176a2.297 2.297 0 0 0-.263.367c-.021.031-.041.053-.062.084-.041.087-.064.157-.111.244-.052.108-.109.218-.141.339-.054.123-.09.252-.116.375a3.076 3.076 0 0 0-.114.769c-.011.087-.021.155-.021.231v79.849c0 2.101 1.616 3.81 3.601 3.81h121.031c1.994 0 3.61-1.709 3.61-3.81v-79.854c0-.076-.021-.146-.021-.231-.021-.147-.021-.296-.052-.44-.011-.108-.042-.21-.073-.328-.036-.124-.067-.252-.116-.375-.042-.116-.083-.231-.146-.341a2.623 2.623 0 0 0-.106-.242c-.021-.031-.041-.053-.062-.084a2.17 2.17 0 0 0-.268-.368c-.042-.062-.083-.124-.132-.175-.66-.759-1.595-1.236-2.634-1.236H373.952c-1.039-.001-1.973.482-2.634 1.24zm110.604 6.413-47.454 30.049-47.443-30.049h94.897zm-104.359 2.908 55.058 34.866c.556.354 1.195.546 1.847.546s1.291-.189 1.849-.546l55.055-34.866v69.271H377.563v-69.271z" fill={color} />
  </svg>
)

export default Mim
