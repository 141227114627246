import React from "react";

const Server = (props) => {
  const server = props.row.original.server;
  return (
    <div>
      <span className="dashboard__table__server">{server}</span>
    </div>
  );
};

export default Server;
