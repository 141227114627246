import React, { useEffect, useState } from "react";
import TextField from "../../../../../Components/common/TextField/TextField";
import CustomScrollbar from "../../../../../Components/common/CustomScrollbar/CustomScrollbar";
import ChangeFields from "./ChangeFields";
import AddUser from "./AddUser";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserRequest,
  getAllUsersRequest,
  updateUserRequest,
  deleteUserRequest,
  getAdminRoleRequest,
  setUserChanged,
} from "../../../../../store/actions/usersActions";
import Backdrop from "../../../../../Components/UI/Backdrop/Backdrop";
import useResizeWindow from "../../../../../utils/hook/useResizeWindows";
import EditIcon from "../../../../../Components/Icon-SVG/EditIcon";
import TrashBucketIcon from "../../../../../Components/Icon-SVG/TrashBucketIcon";
import Warning from "../../../../../Components/common/Warning/Warning";

import "./styles.scss";

const UserAccounts = ({ collapsed }) => {
  const dispatch = useDispatch();
  const width = useResizeWindow();
  const users = useSelector((state) => state?.users.users);
  const findAdminRole = useSelector((state) => state.users.role);
  const userChanged = useSelector((state) => state.users.userChanged);

  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [userNameUpdate, setUserNameUpdate] = useState("");
  const [roleUpdate, setRoleUpdate] = useState("");
  const [passwordUpdate, setPasswordUpdate] = useState("");
  const [confirmPasswordUpdate, setConfirmPasswordUpdate] = useState("");
  const [targetUser, setTargetUser] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [sortUsers, setSortUsers] = useState([""]);

  const [error, setError] = useState("");

  const [edit, setEdit] = useState("false");

  const [editUser, setEditUser] = useState("emtpy");

  const [show, setShow] = useState(null);

  useEffect(() => {
    dispatch(getAdminRoleRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUsersRequest());
  }, [dispatch]);

  useEffect(() => {
    if (searchValue)
      setSortUsers(
        users.filter((user) => user.username.toLowerCase().includes(searchValue.toLowerCase())),
      );
    else setSortUsers([]);
  }, [searchValue, users]);

  useEffect(() => {
    if (userChanged) {
      dispatch(getAllUsersRequest());
      dispatch(setUserChanged(false));
    }
  }, [userChanged]);

  if (!users) {
    return <Backdrop show={!users} />;
  }

  const editUserHandler = (user) => {
    if (edit !== user.username) {
      setEdit(user.username);
      setUserNameUpdate(user.username);
      setEmail(user.email);
      setEditUser(user.username);
      setTargetUser(user);
    } else {
      setEdit("");
      setEditUser(" ");
    }
  };

  const returnAddUserComponent = () => {
    setEditUser(" ");
  };

  const createUser = async () => {
    if (email && userName && role && password && confirmPassword) {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const usernameRegex = /^[a-z0-9]+$/;
      if (emailRegex.test(email)) {
        if (password === confirmPassword) {
          if (usernameRegex.test(userName)) {
            if (userName.length > 3 && userName.length < 16) {
              const roleValue = role.value;
              const user = { username: userName, email, password };
              const userSpace = {
                userValue: user,
                role: roleValue,
              };
              dispatch(createUserRequest(userSpace));
              setError(null);
            } else {
              setError("Please enter a username between 3 and 15 characters long.");
              setShow(true);
            }
          } else {
            setError(
              "Please enter a valid username.Username can only contain lowercase letters and numbers.",
            );
            setShow(true);
          }
        } else {
          setError("Make sure your passwords are the same");
          setShow(true);
        }
      } else {
        setError("Please enter a valid email");
        setShow(true);
      }
    } else {
      setError("Please fill in all fields!");
      setShow(true);
    }
  };

  const updateUser = async () => {
    if (email && userNameUpdate && roleUpdate && passwordUpdate && confirmPasswordUpdate) {
      if (passwordUpdate !== confirmPasswordUpdate) {
        setError("Make sure your passwords are the same");
        setShow(true);
      } else {
        const user = {
          id: targetUser?._id?.["$oid"],
          username: userNameUpdate,
          email: email,
        };

        if (passwordUpdate) user.password = passwordUpdate;
        dispatch(updateUserRequest(user));
      }
    } else {
      setError("Please fill in all fields!");
      setShow(true);
    }
  };

  const deleteUser = async (user) => {
    if (user._id.$oid === "62e0dcc5f1892e7b41284687") {
      setError("Initial user cannot be deleted!");
      setShow(true);
    } else {
      const userSpace = {
        id: user._id.$oid,
      };

      dispatch(deleteUserRequest(userSpace));
    }
  };

  return (
    <div className="users-accounts">
      <div className="flex flex-align-items-center flex-justify-content-between">
        <h1>User Accounts</h1>
      </div>
      <div className="users-accounts__body">
        <div className="users-accounts__body-header">
          <div className="flex flex-align-items-center flex-justify-content-start flex-wrap">
            <h3>User accounts</h3>
            <p className="analysis-images__description">
              <span> NOTE:</span> When setting up for the first time, please update passwords for
              the built-in “admin” , “analyst”, “operator”, and “auditor” accounts These accounts
              cannot be removed. Add/remove users or reset account passwords below.
            </p>
          </div>
        </div>
        <div className={`users-accounts__create flex-1`}>
          <h3>Add New User</h3>
          <p className="label">Email</p>
          <TextField onChange={(e) => setEmail(e.target.value)} placeholder={"Type Email"} />
          <AddUser
            role={role}
            setRole={setRole}
            userName={userName}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            setUserName={setUserName}
            password={password}
            confirmPassword={confirmPassword}
            error={error}
          />
          <div className="m-t-20 m-b-20">
            <button onClick={() => createUser()} className="primary-button">
              Add User
            </button>
          </div>
        </div>
        <div className="users-accounts__update">
          <div className="p-l-20 m-r-20">
            <h3 className="settings-article">User List</h3>
            <div
              className={`${
                width > 1270 ? "flex flex-align-items-stretch flex-justify-content-between " : ""
              }`}>
              <div
                className="users-accounts__update-item"
                style={{ width: !collapsed && width < 1500 ? "50%" : "100%" }}>
                <div className="users-accounts__user-list">
                  <TextField
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    placeholder="Search User"
                  />
                </div>
                <div className="users-accounts__user">
                  <p className="users-accounts__user-name users-accounts__user-header">User Name</p>
                  <p className="users-accounts__user-email users-accounts__user-header">Email</p>
                  <p className="users-accounts__user-role users-accounts__user-header">Role</p>
                  <p className="users-accounts__user-actions users-accounts__user-header">
                    Actions
                  </p>
                </div>
                <CustomScrollbar
                  maxHeight={420}
                  className={`p-r-20 ${width < 1270 ? "border" : ""} `}>
                  {searchValue &&
                    users.length &&
                    sortUsers.map((user, index) => (
                      <div key={index}>
                        <div className="users-accounts__user">
                          <p className="users-accounts__user-name">{user.username}</p>
                          <p className="users-accounts__user-email">{user.email}</p>
                          {findAdminRole &&
                            findAdminRole
                              .filter((roleName) => roleName.name === "admin")
                              .map((findName, i) =>
                                findName._id.$oid === user.roles[0].$oid ? (
                                  <p key={findName} className="users-accounts__user-role">
                                    Admin
                                  </p>
                                ) : (
                                  <p key={i} className="users-accounts__user-role">
                                    User
                                  </p>
                                ),
                              )}
                          <div className="flex flex-align-items-center users-accounts__user-actions">
                            <div
                              className="flex flex-align-items-center m-r-15"
                              onClick={() => editUserHandler(user)}
                              style={{ cursor: "pointer" }}>
                              <EditIcon />
                              <p className="actions__button m-l-10">Edit</p>
                            </div>
                            <div
                              className="flex flex-align-items-center"
                              onClick={() => deleteUser(user)}
                              style={{ cursor: "pointer" }}>
                              <TrashBucketIcon />
                              <p className="actions__button m-l-10">Delete</p>
                            </div>
                          </div>
                        </div>
                        {editUser === user.username ? (
                          <div id={user.username} className={`users-accounts__update-user flex-1`}>
                            <h3>Update User</h3>
                            <p className="label">Email</p>
                            <div className="users-accounts__email">
                              <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={"Type Email"}
                              />
                            </div>
                            <ChangeFields
                              role={roleUpdate}
                              setRole={setRoleUpdate}
                              userName={userNameUpdate}
                              setPassword={setPasswordUpdate}
                              setConfirmPassword={setConfirmPasswordUpdate}
                              setUserName={setUserNameUpdate}
                              password={passwordUpdate}
                              confirmPassword={confirmPasswordUpdate}
                              button={updateUser}
                            />
                            <button
                              className="users-accounts__update-cancel primary-button"
                              onClick={() => returnAddUserComponent()}>
                              Cancel
                            </button>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  {!searchValue &&
                    users.length &&
                    users.map((user, index) => (
                      <div key={index}>
                        <div className="users-accounts__user">
                          <p className="users-accounts__user-name">{user.username}</p>
                          <p className="users-accounts__user-email">{user.email}</p>
                          {findAdminRole &&
                            findAdminRole
                              .filter((roleName) => roleName.name === "admin")
                              .map((findName, j) =>
                                findName._id.$oid === user.roles[0].$oid ? (
                                  <p key={findName} className="users-accounts__user-role">
                                    Admin
                                  </p>
                                ) : (
                                  <p key={j} className="users-accounts__user-role">
                                    User
                                  </p>
                                ),
                              )}
                          <div className="flex flex-align-items-center users-accounts__user-actions">
                            <div
                              className="flex flex-align-items-center m-r-15"
                              onClick={() => editUserHandler(user)}
                              style={{ cursor: "pointer" }}>
                              <EditIcon />
                              <p className="actions__button m-l-10">Edit</p>
                            </div>
                            <div
                              className="flex flex-align-items-center"
                              onClick={() => deleteUser(user)}
                              style={{ cursor: "pointer" }}>
                              <TrashBucketIcon />
                              <p className="actions__button m-l-10">Delete</p>
                            </div>
                          </div>
                        </div>
                        {editUser === user.username ? (
                          <div id={user.username} className={`users-accounts__update-user flex-1`}>
                            <h3>Update User</h3>
                            <p className="label">Email</p>
                            <div className="users-accounts__email">
                              <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={"Type Email"}
                              />
                            </div>
                            <ChangeFields
                              role={roleUpdate}
                              setRole={setRoleUpdate}
                              userName={userNameUpdate}
                              setPassword={setPasswordUpdate}
                              setConfirmPassword={setConfirmPasswordUpdate}
                              setUserName={setUserNameUpdate}
                              password={passwordUpdate}
                              confirmPassword={confirmPasswordUpdate}
                              button={updateUser}
                            />
                            <button
                              className="users-accounts__update-cancel primary-button"
                              onClick={() => returnAddUserComponent()}>
                              Cancel
                            </button>
                          </div>
                        ) : null}
                      </div>
                    ))}
                </CustomScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </div>
  );
};

export default UserAccounts;
