import * as React from "react"

const SvgComponent = ({ color = "var(--border)", width = 24, height = 24 }) => (
  <svg
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    width={width}
    height={height}
  >
    <style>
      {
        ".st0{fill:none;stroke:#000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <path
      className="st0"
      d="M26.5 3h.5c2.6.3 3.9 3.4 2.4 5.6L24.5 16M26 3H9.5M11.3 10.3l-8.7 13C1.2 25.5 2.4 28.7 5 29h15.4"
    />
    <path
      className="st0"
      d="M9 3C6.8 3 5 4.8 5 7c0 .5.2 1 .4 1.4.6 1 1.8 1.6 3 1.6H24c-1 0-3.1-2.8-.9-5.5.7-.9 1.8-1.5 2.9-1.5 2.2 0 4 1.8 4 4M13 15h5M11 18h3"
    />
    <circle className="st0" cx={24} cy={23} r={7} />
    <path className="st0" d="M24 30V20M27 23l-3-3-3 3" />
  </svg>
)

export default SvgComponent
