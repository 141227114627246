import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { doughnutChart } from "../../../utils/charts/doughnutChart";
import { RadarChart } from "../../../utils/charts/RadarChart";
import { loadFromLocalStorage } from "../../../utils/localStorage";
import { barChart } from "../../../utils/charts/barChart";
import { informationPopupContent } from "../InformationPopup/utils/informationPopupUtil";
import Popup from "../InformationPopup/components/popup/Popup";
import Backdrop from "../InformationPopup/components/backdrop/Backdrop";

import {
  classificationIcons,
  iconWrapperClassName,
} from "../../../utils/constants/classificationIcons";

import "./styles.scss";

/*report: this variable belongs to the pdf page */
const Chart = ({ name, type, fileState = "", report, decision_score = 0 }) => {
  const theme = loadFromLocalStorage("theme");
  const signatureList = useSelector((state) => state.analysisReports.signatureList);
  const [decisionScoreExplanationOpen, setDecisionScoreExplanationOpen] = useState(false);

  useEffect(() => {
    switch (type) {
      case "bar":
        barChart(name, theme);
        break;
      case "doughnut":
        doughnutChart(name, theme, decision_score, fileState);
        break;
      case "radar":
        RadarChart(name, theme, signatureList);
        break;
    }
  }, [name, type, theme, signatureList]);

  const decisionScoreExplanationHandler = () => setDecisionScoreExplanationOpen((prev) => !prev);

  return (
    <div className={report && type === "radar" ? `report-chart-${type}` : `chart-${type}`}>
      {type === "bar" ? (
        <canvas id={`chart-${name}`} width="100" height="50" />
      ) : (
        <>
          {type === "radar" ? (
            <div>
              {classificationIcons.map((icon) => (
                <div
                  key={icon.id}
                  title={icon.title}
                  className={`${iconWrapperClassName}__${icon.id}`}>
                  {icon.renderIcon(30, 30)}
                </div>
              ))}
            </div>
          ) : null}
          <canvas id={`chart-${name}`} width="100" height="100" />
        </>
      )}
      {type === "doughnut" && (
        <>
          <div className={`chart-${type}__value`} onClick={decisionScoreExplanationHandler}>
            {decision_score || decision_score === 0 ? (
              <div>
                <div className={`chart-${type}__value`}>
                  <div>
                    {!decision_score && decision_score !== 0
                      ? "None"
                      : fileState === "suspicious"
                      ? "-"
                      : `${decision_score}%`}
                  </div>
                </div>
              </div>
            ) : (
              "None"
            )}
          </div>
          {decisionScoreExplanationOpen && <Backdrop onClick={decisionScoreExplanationHandler} />}
          {decisionScoreExplanationOpen && (
            <Popup
              content={informationPopupContent.decisionScore}
              onClick={decisionScoreExplanationHandler}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Chart;
