import { useState } from "react";
import FolderIcon from "../../../../../../Components/Icon-SVG/FolderIcon";
import { formatBytes } from "./Dump";

const RestoreFileUpload = ({ file, setFile, setRestoreFileError }) => {
  const [dragActive, setDragActive] = useState(false);

  const isExtensionAllowed = (selectedFile) => {
    if (selectedFile.name.split(".")[1] !== "gz") {
      setRestoreFileError("Please select .gz file that you download from our system.");
      setFile(null);
      return false;
    }
    return true;
  };

  const fileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (!isExtensionAllowed(selectedFile)) return;
      setRestoreFileError("");
      setFile(selectedFile);
    }
  };

  const dragEnterHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const dragExitHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const dropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const selectedFile = e.dataTransfer.files[0];
      if (!isExtensionAllowed(selectedFile)) return;
      setRestoreFileError("");
      setFile(selectedFile);
    }
  };

  return (
    <form
      className="restore__form"
      onSubmit={(e) => e.preventDefault()}
      onDragEnter={dragEnterHandler}>
      <input
        id="file-for-restore"
        className="restore__form__input"
        type="file"
        onChange={fileChangeHandler}
      />
      <label
        htmlFor="file-for-restore"
        className={`restore__form__label ${dragActive ? "restore__form__label--active" : ""}`}>
        {file ? (
          <div className="restore__uploaded-file">
            <span>{file.name}</span>
            <span>{formatBytes(file.size, 2)}</span>
          </div>
        ) : (
          <>
            <div className="restore__form__icon">
              <FolderIcon />
            </div>
            <p className="restore__form__info-text">
              Drag & Drop your file here or <b style={{ color: "var(--color-blue)" }}>browse</b> to upload
            </p>
          </>
        )}
      </label>
      {dragActive && (
        <div
          className="restore__form__drag-file-element"
          onDragEnter={dragEnterHandler}
          onDragLeave={dragExitHandler}
          onDragOver={dragEnterHandler}
          onDrop={dropHandler}></div>
      )}
    </form>
  );
};

export default RestoreFileUpload;
