import * as React from "react"

const Dmp = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M281.117 254.762c-5.111.113-8.557.65-10.346 1.168v30.874c2.123.462 4.74.62 8.365.614 13.486-.018 21.929-6.517 21.929-17.454-.001-9.806-7.26-15.493-19.948-15.202zM64.613 260.066c-4.087.097-6.709.549-8.258.979v58.403c1.549.439 4.058.504 6.333.562 16.788.551 27.974-9.518 27.974-31.431.124-19.05-10.078-28.883-26.049-28.513z" fill={color} />
    <path  d="M537.115 48.977h-177.39c-.787 0-1.553.074-2.329.124V0L11.176 46.208v492.308l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.745 0 37.627-16.883 37.627-37.627V86.604c0-20.745-16.882-37.627-37.627-37.627zM96.967 325.221c-8.929 7.76-22.28 11.065-38.223 10.479-9.349-.346-15.896-1.26-20.294-2.069v-86.415c6.499-1.399 15.074-2.354 24.245-2.682 15.557-.555 25.851 2.21 34.026 8.632 8.921 6.906 14.593 18.255 14.593 34.714-.006 17.835-6.058 30.002-14.347 37.341zm108.791 14.914-1.493-38.263c-.44-11.998-.892-26.512-.892-41.014l-.441.011c-3.107 12.75-7.229 26.935-11.04 38.535l-11.868 38.186-16.87-.6-9.998-37.58c-3.031-11.273-6.183-24.906-8.37-37.365l-.275.011c-.541 12.913-.951 27.646-1.632 39.533l-1.631 36.188-18.834-.687 5.687-94.173 27.534-.966 9.197 32.053c2.955 11.224 5.923 23.363 8.047 34.816l.431.005c2.703-11.291 5.987-24.203 9.152-35.149l10.447-33.026 29.714-1.038 5.672 101.345-22.537-.822zm110.588-45.396c-8.956 7.754-22.042 11.112-37.045 10.945-3.294-.036-6.247-.23-8.53-.578v37.408l-24.227-.883V240.152c7.455-1.467 18.05-2.774 33.25-3.312 15.688-.56 27.076 1.903 34.818 7.386 7.481 5.242 12.563 14.142 12.563 24.774s-3.859 19.654-10.829 25.739zm41.05-94.162H481.35v82.175h-47.775v53.62h-76.178V200.577zm174.606 109.297v109.302h-92.601V288.589h71.955v21.285h20.646zm-14.819-5.829v-10.829l11.26 10.829h-11.26zm-83.609 51.225v15.938h-14.803V355.27h14.803zM553.24 497.823c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.117-2.329-.231V386.555h76.178v38.447h104.254V304.991l-23.107-22.239h-14.479V181.695H357.396V70.72c.766-.113 1.532-.241 2.329-.241h177.39c8.893 0 16.125 7.232 16.125 16.125v411.219z" fill={color} />
    <path  d="M449.532 325.034h63.162v5.374h-63.162zM449.532 352.813h63.162v5.376h-63.162zM449.532 382.146h63.162v5.375h-63.162z" fill={color} />
  </svg>
)

export default Dmp
