import React from "react";

const MessageIcon = ({ color = "var(--text-color-card)", width = 60, height = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 52"
      fill="none">
      <path
        d="M58 3.25H6C4.20507 3.25 2.75 4.70507 2.75 6.5V45.5C2.75 47.2949 4.20507 48.75 6 48.75H58C59.7949 48.75 61.25 47.2949 61.25 45.5V6.5C61.25 4.70507 59.7949 3.25 58 3.25Z"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M2.75 8.125L32 26L61.25 8.125"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MessageIcon;
