import * as React from "react"

const Dat = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="m179.173 266.538-.34.005c-1.69 6.966-3.386 15.805-5.236 22.567l-6.703 24.627 25.284.095-7.214-24.864c-2.055-6.921-4.095-15.551-5.791-22.43zM67.967 269.377c-4.904.13-8.058.71-9.909 1.237v69.976c1.851.517 4.87.591 7.596.664 20.187.665 33.697-11.433 33.697-37.768.151-22.907-12.16-34.664-31.384-34.109z" fill={color} />
    <path  d="M577.373 86.999c0-20.838-16.954-37.8-37.8-37.8h-178.2c-.786 0-1.561.076-2.342.124V0L11.228 46.417v494.564L359.031 588.6v-50.814c.781.053 1.551.115 2.342.115h178.2c20.846 0 37.8-16.964 37.8-37.8V86.999zM359.031 275.535c7.046-12.227 20.214-20.498 35.327-20.498 22.507 0 40.812 18.333 40.812 40.813 0 22.513-18.305 40.812-40.812 40.812-15.108 0-28.271-8.253-35.327-20.482v-40.645zm-252.072 72.006c-10.795 9.323-26.887 13.258-46.04 12.514-11.222-.433-19.056-1.523-24.316-2.499V254.198c7.776-1.727 18.046-2.958 29.051-3.428 18.705-.789 31.116 2.434 41.004 10.104 10.795 8.245 17.674 21.874 17.674 41.694.005 21.485-7.341 36.145-17.373 44.973zm98.091 16.896-9.065-30.628-32.74-.575-7.918 29.305-25.45-.966 33.362-114.098 33.953-1.424 37.233 119.494-29.375-1.108zm84.283 3.211-29.444-1.123v-99.723l-33.444.896V244.83l99.818-4.184v24.384l-36.936.994v101.624h.006zm266.44 132.453c0 8.928-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.116-2.342-.232V359.522c2.806.242 5.559.791 8.174 1.872 6.882 2.853 12.393 8.453 15.093 15.394.232.643.896 1.075 1.582 1.075h20.968c.686 0 1.318-.422 1.571-1.075 2.71-6.93 8.194-12.53 15.082-15.394 6.871-2.843 14.755-2.753 21.558.222.453.211 1.05.453 1.877-.343l14.829-14.851c.48-.495.633-1.239.349-1.855-2.99-6.835-3.059-14.703-.228-21.568 2.854-6.872 8.459-12.361 15.404-15.082.622-.254 1.075-.865 1.075-1.562v-20.978c0-.686-.453-1.337-1.075-1.593a27.646 27.646 0 0 1-15.404-15.071c-2.831-6.874-2.763-14.736.228-21.545.273-.611.126-1.395-.349-1.88l-14.829-14.839c-.822-.799-1.444-.551-1.908-.367-6.75 2.985-14.671 3.099-21.526.232-6.888-2.835-12.372-8.456-15.082-15.391a1.702 1.702 0 0 0-1.571-1.062H383.88c-.696 0-1.35.433-1.582 1.062-2.7 6.929-8.211 12.551-15.061 15.391-2.621 1.097-5.4 1.619-8.206 1.856V71.042c.771-.114 1.54-.243 2.342-.243h178.2c8.933 0 16.2 7.27 16.2 16.2v413.102z" fill={color} />
    <path  d="M545.569 351.612a13.878 13.878 0 0 1-7.736-7.557 13.885 13.885 0 0 1 .11-10.821.904.904 0 0 0-.174-.954l-7.451-7.436c-.406-.406-.723-.285-.955-.19-3.385 1.498-7.372 1.562-10.805.116a13.838 13.838 0 0 1-7.578-7.721.846.846 0 0 0-.786-.537h-10.53a.835.835 0 0 0-.791.537 13.89 13.89 0 0 1-7.567 7.721c-3.444 1.445-7.415 1.393-10.827-.116-.211-.084-.517-.216-.938.19l-7.456 7.436a.948.948 0 0 0-.18.975c1.519 3.407 1.54 7.347.116 10.801s-4.24 6.196-7.715 7.557a.87.87 0 0 0-.554.812v10.536c0 .348.221.653.554.78a13.792 13.792 0 0 1 7.715 7.572 13.773 13.773 0 0 1-.116 10.821c-.132.306-.053.686.18.938l7.456 7.457c.422.4.723.264.938.158a13.905 13.905 0 0 1 5.548-1.16c1.814 0 3.597.358 5.269 1.055a13.875 13.875 0 0 1 7.578 7.721.835.835 0 0 0 .791.537h10.53a.846.846 0 0 0 .786-.537 13.838 13.838 0 0 1 7.578-7.721c3.454-1.435 7.403-1.382 10.821.105.227.105.532.232.938-.158l7.451-7.457a.875.875 0 0 0 .174-.938 13.885 13.885 0 0 1-.11-10.821 13.775 13.775 0 0 1 7.736-7.572.845.845 0 0 0 .532-.78v-10.536a.905.905 0 0 0-.532-.813zm-40.631 26.556c-11.312 0-20.482-9.196-20.482-20.492s9.171-20.492 20.482-20.492c11.295 0 20.481 9.196 20.481 20.492s-9.187 20.492-20.481 20.492z" fill={color} />
  </svg>
)

export default Dat
