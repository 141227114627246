import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  TriggerHealthCheck,
  FinishHealthCheck,
  SuccessInternetStatus,
  FailureInternetStatus,
  SuccessVpnStatus,
  FailureVpnStatus,
  SuccessAnalyserStatus,
  FailureAnalyserStatus,
  SuccessReporterStatus,
  FailureReporterStatus,
  SuccessBrokerStatus,
  FailureBrokerStatus,
  SuccessDiskStatus,
  FailureDiskStatus,
  SuccessFtiStatus,
  FailureFtiStatus,
  SuccessMlStatus,
  FailureMlStatus,
} from "../actions/healthCheckActions";

import axiosApi from "../../utils/axios";

export function* internetStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/health-check/internet");
    yield put(SuccessInternetStatus(response.data["internet-status"] ? "open" : "close"));
  } catch (err) {
    yield put(FailureInternetStatus());
  }
}

export function* vpnStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/health-check/vpn");
    yield put(SuccessVpnStatus(response.data["vpn-status"] ? "open" : "close"));
  } catch (err) {
    yield put(FailureVpnStatus());
  }
}

export function* analyserStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/health-check/analyser");
    yield put(SuccessAnalyserStatus(response.data["analyser-status"] ? "open" : "close"));
  } catch (err) {
    yield put(FailureAnalyserStatus());
  }
}

export function* reporterStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/health-check/reporter");
    yield put(SuccessReporterStatus(response.data["reporter-status"] ? "open" : "close"));
  } catch (err) {
    yield put(FailureReporterStatus());
  }
}

export function* brokerStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/health-check/broker");
    yield put(SuccessBrokerStatus(response.data["broker-status"] ? "open" : "close"));
  } catch (err) {
    yield put(FailureBrokerStatus());
  }
}

export function* diskStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/health-check/disk");
    yield put(SuccessDiskStatus(response.data["disk-status"] ? "open" : "close"));
  } catch (err) {
    yield put(FailureDiskStatus());
  }
}

export function* ftiStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/health-check/fti");
    yield put(SuccessFtiStatus(response.data["fti-status"] ? "open" : "close"));
  } catch (err) {
    yield put(FailureFtiStatus());
  }
}

export function* mlStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/health-check/ml");
    yield put(SuccessMlStatus(response.data["ml-status"] ? "open" : "close"));
  } catch (err) {
    yield put(FailureMlStatus());
  }
}

function* rootSaga() {
  try {
    yield all([
      call(internetStatus),
      call(vpnStatus),
      call(analyserStatus),
      call(reporterStatus),
      call(brokerStatus),
      call(diskStatus),
      call(ftiStatus),
      call(mlStatus),
    ]);
  } finally {
    yield put(FinishHealthCheck());
  }
}

const healthCheckSagas = [takeLatest(TriggerHealthCheck, rootSaga)];

export default healthCheckSagas;
