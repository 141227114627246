import * as React from "react"
const IcapIcon = ({ color = "var(--text-color-card)", width = 60, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M9 12v2h7v2H0v-2h7v-2H2V0h12v12H9zM4 2v2h8V2H4zm0 4v4h8V6H4zm1 2c0-.552.444-1 1-1 .552 0 1 .444 1 1 0 .552-.444 1-1 1-.552 0-1-.444-1-1z"
      fill={color}
    />
  </svg>
)
export default IcapIcon
