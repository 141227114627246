import * as React from "react"

const Odt = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M172.597 252.363c-5.719.108-9.408.686-11.588 1.237v75.958c2.181.549 5.706.612 8.899.665 23.501.585 39.074-12.509 39.074-40.833.179-24.624-14.042-37.393-36.385-37.027z" fill={color} />
    <path  d="M359.031 537.78c.781.048 1.551.111 2.342.111h178.2c20.846 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.954-37.8-37.8-37.8h-178.2c-.786 0-1.561.077-2.342.113V0L11.228 46.417v494.564L359.031 588.6v-50.82zm2.342-466.982h178.2c8.933 0 16.2 7.27 16.2 16.2v413.103c0 8.934-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.127-2.342-.232V408.876h173.902c5.965 0 10.8-4.836 10.8-10.801 0-5.964-4.835-10.8-10.8-10.8H359.031v-49.56h173.902c5.965 0 10.8-4.836 10.8-10.8 0-5.965-4.835-10.801-10.8-10.801H359.031v-49.443h76.855c5.964 0 10.8-4.833 10.8-10.8 0-5.962-4.836-10.8-10.8-10.8h-76.855v-49.497h76.855c5.964 0 10.8-4.833 10.8-10.8 0-5.961-4.836-10.8-10.8-10.8h-76.855V71.042c.771-.12 1.54-.244 2.342-.244zM67.4 348.743c-30.291-.812-47.619-25.649-47.619-56.646 0-32.611 19.122-57.489 49.309-58.293 32.324-.852 50.38 24.877 50.38 56.323 0 37.364-21.379 59.439-52.07 58.616zm150.335-12.044c-12.414 10.114-31.029 14.538-53.391 13.953-13.165-.348-22.41-1.45-28.635-2.457V235.457c9.184-1.772 21.281-2.932 34.193-3.267 21.777-.562 36.115 3.098 47.482 11.486 12.35 8.973 20.194 23.625 20.194 44.803.006 22.976-8.368 38.695-19.843 48.22zm85.081 16.337-28.537-.738v-98.764l-32.909.53v-22.837l96.715-2.468v23.772l-35.27.559v99.946z" fill={color} />
    <path  d="M68.473 253.811c-15.725.264-24.775 16.421-24.775 37.811 0 21.558 9.353 36.903 24.933 37.178 15.944.273 25.197-15.937 25.197-37.821.005-20.222-9.078-37.456-25.355-37.168z" fill={color} />
  </svg>
)

export default Odt
