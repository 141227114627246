import React from "react";

const WarningIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83"
        height="83"
        viewBox="0 0 83 83"
        fill="none">
        <circle cx="42" cy="42" r="26" stroke="var(--text-color-card)" strokeWidth="4" />
        <path
          d="M40.9989 28.4365H44.4208L43.8097 47.7604H41.5692L40.9989 28.4365ZM42.7098 56.2392C42.1123 56.2392 41.5964 56.042 41.1618 55.6477C40.7545 55.227 40.5508 54.7406 40.5508 54.1885C40.5508 53.6101 40.7545 53.1369 41.1618 52.7688C41.5692 52.3744 42.0852 52.1773 42.7098 52.1773C43.3073 52.1773 43.8097 52.3744 44.2171 52.7688C44.6244 53.1369 44.8281 53.6101 44.8281 54.1885C44.8281 54.7406 44.6244 55.227 44.2171 55.6477C43.8097 56.042 43.3073 56.2392 42.7098 56.2392Z"
          fill="var(--text-color-card)"
        />
      </svg>
    </div>
  );
};

export default WarningIcon;
