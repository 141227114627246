import * as React from "react"

const Obj = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.913 585.913"
    xmlSpace="preserve">
    <path  d="M209.128 279.408c0-9.467-7.176-14.3-19.696-13.987-5.908.149-9.283.596-11.578 1.016v28.488l8.895-.031c14.471-.048 22.379-6.203 22.379-15.486zM187.099 314.807l-9.249-.105v34.479c2.654.457 5.853.577 10.501.745 12.498.463 23.743-3.968 23.743-17.176.006-12.652-11.238-17.796-24.995-17.943zM83.315 268.775c-15.554.385-24.446 16.83-24.446 38.51 0 21.843 9.181 37.569 24.598 38.133 15.958.571 25.281-15.96 25.281-38.522.001-20.84-9.164-38.525-25.433-38.121z" fill={color} />
    <path  d="M537.112 48.974h-177.39c-.781 0-1.553.076-2.329.123V0L11.173 46.2v492.311l346.22 47.401V535.33c.776.058 1.542.109 2.329.109h177.39c20.756 0 37.627-16.882 37.627-37.627V86.597c0-20.738-16.871-37.623-37.627-37.623zM357.394 238.063l53.208-21.421v58.14l-53.208-21.606v-15.113zm0 116.267v-69.458l44.493 17.904v31.969l-44.493 19.585zm44.493-10.268v62.126l-44.493-17.911v-24.629l44.493-19.586zM82.253 365.785c-29.823-1.561-46.616-26.966-46.616-58.138 0-32.814 18.539-58.105 48.292-59.316 32.51-1.327 50.97 25.153 50.97 57.835 0 38.82-21.835 61.219-52.646 59.619zm146.77-4.574c-9.268 6.888-24.507 10.217-48.696 8.967-13.176-.683-22.851-2.079-28.656-3.261V249.111c6.837-1.683 20.887-3.32 34.354-3.864 16.841-.671 27.376.575 36.558 5.653 8.918 4.408 15.443 12.912 15.443 24.476 0 11.368-6.713 22.003-21.081 27.247v.374c14.556 3.999 25.522 15.075 25.522 31.61.001 11.602-5.414 20.516-13.444 26.604zm92.915-34.891c0 38.541-19.643 50.954-50.182 49.364-7.031-.362-16.183-1.989-22.075-4.204l3.333-23.397c4.145 1.487 9.514 2.63 15.527 2.841 12.987.462 21.223-4.945 21.223-25.564v-83.285l32.173-1.269v85.514zm231.299 171.503c0 8.887-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.117-2.329-.231v-113.84l52.557 21.155c.23.094.482.146.739.146a1.9 1.9 0 0 0 1.093-.341c.546-.366.865-.981.865-1.633V296.829c0-.794-.493-1.521-1.228-1.817l-54.032-21.737v-8.483l57.696 23.42a1.931 1.931 0 0 0 1.449.011l105.912-40.998a1.954 1.954 0 0 0 1.249-1.827c0-.812-.498-1.533-1.249-1.837l-104.622-40.843a1.98 1.98 0 0 0-1.454.011l-58.976 23.732V70.717c.766-.113 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.127v411.221zm-134.09-222.147v-60.881l78.3 30.564-78.3 30.317z" fill={color} />
    <path  d="m523.794 254.019-101.881 40.998a1.95 1.95 0 0 0-1.233 1.816v122.385c0 .651.33 1.26.86 1.633.332.221.714.343 1.104.343.241 0 .493-.06.725-.148l101.89-40.997a1.988 1.988 0 0 0 1.234-1.828V255.83c0-.651-.33-1.257-.871-1.627a1.923 1.923 0 0 0-1.828-.184zm-92.353 152.169V344.14l76.703 31.191-76.703 30.857zm84.289-37.003-84.3-34.252v-32.157l84.3-33.915v100.324z" fill={color} />
  </svg>
)

export default Obj
