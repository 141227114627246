import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TabButton from "../../../common/TabButton/TabButton";
import Info from "./Tabs/Info";
import Environment from "./Tabs/Environment";
import StaticInfo from "./Tabs/StaticInfo";
import DemoPopup from "../../../../Components/common/DemoPopup/DemoPopup";
import "./styles.scss";
import {
  RequestFilesInfo,
  RequestExtractedStrings,
  RequestStaticFindings,
} from "../../../../store/actions/analysisReportActions";
import ExtractedInfo from "./Tabs/ExtractedInfo";
import StaticFindings from "./Tabs/StaticFindings";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
/*report: this variable belongs to the pdf page */
const FilesInfo = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("Additional Details");
  const filesInfo = useSelector((state) => state.analysisReports.filesInfo);
  const extractedStrings = useSelector((state) => state.analysisReports.extractedStrings);
  const [showDelete, setShowDelete] = useState(false);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  useEffect(() => {
    dispatch(RequestFilesInfo(query.get("data")));
    dispatch(RequestExtractedStrings(query.get("data")));
    dispatch(RequestStaticFindings(query.get("data")));
  }, [dispatch, query.get("data")]);

  return (
    <div className="files-info">
      <div className="files-info__tabs">
        <TabButton title="Additional Details" onClick={setActive} active={active} />
        <TabButton title="Extracted Strings" onClick={setActive} active={active} />
        <TabButton title="Static Findings" onClick={setActive} active={active} />
        {/*<TabButton
          title="Other static info"
          onClick={() => setShowDelete(true)}
          active={active}
    />*/}
      </div>
      <div className="files-info__body">
        {active === "Additional Details" ? (
          <Info
            md5={filesInfo[0]?.target.file.md5}
            sha1={filesInfo[0]?.target.file.sha1}
            vhash={filesInfo[0]?.target.file.virustotal.vhash}
            ssdeep={filesInfo[0]?.target.file.ssdeep}
            file_type={filesInfo[0]?.target.file.type.substring(0, 75) + "..."}
            magic={filesInfo[0]?.target.file.virustotal.magic}
            interestingStrings={filesInfo[0]?.target.file.strings_ranked}
          />
        ) : active === "Extracted Strings" ? (
          <div>
            <ExtractedInfo content={extractedStrings[0]?.strings} />
          </div>
        ) : active === "Static Findings" ? (
          <StaticFindings />
        ) : (
          <StaticInfo />
        )}
      </div>
      <DemoPopup
        onClickOk={() => setShowDelete(false)}
        show={showDelete}
        setShow={setShowDelete}
        text="This feature is not available in the demo version."
        article="DEMO"
      />
    </div>
  );
};

export default FilesInfo;
