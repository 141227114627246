export const isSelectAllChecked = (options) => options.every((option) => option.selected);

export const isAnyChecked = (options) => options.some((option) => option.selected);

const truncateString = (stringToTruncate, maxLength) =>
  stringToTruncate.length > maxLength
    ? stringToTruncate.slice(0, maxLength) + "..."
    : stringToTruncate;

export const getSelectedOptionsFieldString = (options, field, maxLength) => {
  const selectedOptionsUsernameString = options
    .filter((option) => option.selected)
    .map((option) => option[field])
    .join(", ");

  return truncateString(selectedOptionsUsernameString, maxLength);
};
