import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import LogoIcon from "../../Icon-SVG/LogoIcon";
import TrashBucketIcon from "../../Icon-SVG/TrashBucketIcon";
import EditIcon from "../../Icon-SVG/EditIcon";
import ReplyIcon from "../../Icon-SVG/ReplyIcon";
import {
  RequestDeleteComment,
  RequestEditComment,
  RequestGetComment,
} from "../../../store/actions/analysisReportActions";
import AddNewComment from "../../PagesComponents/AnalysisReports/MemoryBlock/Tabs/Comments/AddNewComment";
import { useOnClickOutside } from "../../../utils/hook/useOnClickOutside";

import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Message = ({
  username,
  time,
  date,
  avatar,
  text,
  edited,
  current_user,
  commentID,
  replyTo,
}) => {
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [commentText, setCommentText] = useState(text);
  const [replyMode, setReplyMode] = useState(false);

  const addNewCommentRef = useRef();

  useOnClickOutside(addNewCommentRef, () => setReplyMode(false));

  useEffect(() => {
    setCommentText(text);
  }, [text]);

  useEffect(() => {
    const scrollTarget = document.getElementById("new-comments__block__scroll-target");

    if (scrollTarget) {
      scrollTarget.scrollIntoView({ behavior: "smooth" });
    }
  }, [replyMode]);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();
  let counter = 0;
  const deleteComment = () => {
    dispatch(RequestDeleteComment(commentID));
    setInterval(() => {
      if (counter === 0) {
        counter++;
        dispatch(RequestGetComment(query.get("data")));
      }
    }, 100);
  };

  const editModeUpdateHandler = () => {
    setCommentText(text);
    setEditMode((prevState) => !prevState);
  };

  const commentTextChangeHandler = (e) => {
    setCommentText(e.target.value);
  };

  const editComment = () => {
    dispatch(
      RequestEditComment({
        id: commentID,
        updatedText: commentText.trim(),
        fileId: query.get("data"),
      }),
    );
    setEditMode(false);
    setCommentText(text);
  };

  return (
    <>
      <div className="message">
        <div className="message__header">
          {avatar ? <LogoIcon width={24} height={24} /> : <LogoIcon width={24} height={24} />}
          <p className="message__header__author">{username}</p>
          <p className="message__header__datetime">
            {time} <span>{date}</span>
          </p>
          <div className="message__actions">
            {current_user === username ? (
              <>
                <button
                  className="message__actions__action message__actions__action--delete"
                  onClick={deleteComment}>
                  <span style={{ lineHeight: 1 }}>
                    <TrashBucketIcon />
                  </span>
                  Delete
                </button>
                <button
                  className="message__actions__action message__actions__action--edit"
                  onClick={editModeUpdateHandler}>
                  <span style={{ lineHeight: 1 }}>
                    <EditIcon width={16} height={16} />
                  </span>
                  Edit
                </button>
              </>
            ) : !replyMode ? (
              <button
                className="message__actions__action message__actions__action--reply"
                onClick={() => setReplyMode(true)}>
                <span style={{ lineHeight: 1 }}>
                  <ReplyIcon width={16} height={16} />
                </span>
                Reply
              </button>
            ) : null}
          </div>
        </div>
        {editMode ? (
          <div className="message__edit">
            <textarea
              id="text"
              name="text"
              value={commentText}
              onChange={commentTextChangeHandler}
              className="new-comments__block-textarea"
              placeholder="Type updated comment..."
            />
            <button
              className="primary-button"
              onClick={editComment}
              disabled={commentText.trim() === "" || commentText === text}>
              Update
            </button>
          </div>
        ) : (
          <div className="message__body">
            <p className="message__body__text">
              {text}
              {edited ? <span style={{ color: "var(--toggle-background)" }}> (edited)</span> : null}
            </p>
          </div>
        )}
      </div>
      {replyMode ? (
        <AddNewComment
          username={username}
          replyTo={replyTo ? replyTo : commentID.$oid}
          setReplyMode={setReplyMode}
          ref={addNewCommentRef}
        />
      ) : null}
    </>
  );
};

export default Message;
