import * as React from "react";

const InjectionIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".inject1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path
        className="inject"
        d="M987 789.9H37l110.2-68.8h729.6zM597.4 789.9c0 12.2-9.9 22.1-22.1 22.1H450.7c-12.2 0-22.1-9.9-22.1-22.1H37V810c0 19 15.4 34.4 34.4 34.4h881.1c19 0 34.4-15.4 34.4-34.4v-20.1H597.4zM789.2 179.6H234.8c-48.3 0-87.6 39.3-87.6 87.6v422.7h57V267.2c0-16.9 13.7-30.6 30.6-30.6h554.4c16.9 0 30.6 13.7 30.6 30.6v422.7h57V267.2c0-48.3-39.3-87.6-87.6-87.6z"
        fill={color}
      />
      <circle className="inject1" cx={455.4} cy={471.9} r={55.2} />
      <circle className="inject1" cx={455.4} cy={420.8} r={19.7} />
      <path
        className="inject1"
        d="m418.4 445.7-25.5-25.5V389h11.2v26.6l22.2 22.2zM404.1 549.2h-11.2v-36.1l22-22 7.9 7.9-18.7 18.8zM415.4 460h-36l-22.1-22.1 7.9-7.9 18.8 18.8h31.4zM365.2 506.5l-7.9-7.9 22.1-22.1h36v11.2H384zM492.4 445.7l-7.9-7.9 22.2-22.2V389h11.2v31.2zM517.9 549.2h-11.2v-31.4L487.9 499l7.9-7.9 22.1 22zM531.4 460h-36.1v-11.2h31.4l18.8-18.8 7.9 7.9zM545.5 506.5l-18.8-18.8h-31.4v-11.2h36.1l22 22.1z"
      />
      <path
        className="inject1"
        d="m284.6 460 90.1-90.2-22.1-22L240.5 460l112.1 112.1 22.1-22zM739.4 460l-90.1 90.1 22.1 22L783.5 460 671.4 347.8l-22.1 22z"
      />
      <path
        transform="rotate(-75.398 596.508 451.474)"
        className="inject1"
        d="M468.9 435.9h255.2v31.2H468.9z"
      />
    </g>
  </svg>
);

export default InjectionIcon;
