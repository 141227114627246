import { useState, useEffect, useRef } from "react";

const useCalculateMenuPosition = (isOpen) => {
  const [position, setPosition] = useState("");
  const menuRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      setPosition("");
    }

    if (isOpen && menuRef.current) {
      const viewportWidth = window.innerWidth;

      const { right } = menuRef.current.getBoundingClientRect();

      if (right > viewportWidth) {
        setPosition("left");
      } else {
        setPosition("right");
      }
    }
  }, [isOpen]);

  return { position, menuRef };
};

export default useCalculateMenuPosition;
