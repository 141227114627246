import * as React from "react"

const Jsp = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M268.35 253.019c-6.622.118-11.095.826-13.418 1.503v41.334c2.754.64 6.156.861 10.849.892 17.525.111 28.539-8.543 28.539-23.197-.001-13.175-9.474-20.861-25.97-20.532z" fill={color} />
    <path  d="M537.115 48.979h-177.39c-.787 0-1.553.077-2.329.124V0L11.176 46.206v492.311l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.756 0 37.627-16.888 37.627-37.627V86.602c0-20.743-16.871-37.623-37.627-37.623zM98.075 315.893c0 36.175-15.854 47.985-40.654 46.716-5.743-.294-13.242-1.771-18.079-3.822l2.742-22.194c3.402 1.391 7.788 2.457 12.702 2.624 10.596.384 17.291-4.755 17.291-24.204v-78.534l25.999-.858v80.272zm52.523 51.292c-14.208-.729-27.969-5.259-34.765-9.585l5.533-23.858c7.392 4.267 18.867 8.708 30.909 9.139 13.163.473 20.21-5.013 20.21-13.712 0-8.294-6.1-13.144-21.323-19.087-20.588-7.81-33.723-19.773-33.723-38.47 0-21.942 17.316-39.336 46.87-40.355 14.52-.499 25.378 2.323 33.253 5.688l-6.688 24.774c-5.268-2.515-14.541-6.121-27.129-5.874-12.402.231-18.332 6.176-18.332 12.971 0 8.347 7.058 12.014 23.455 18.465 22.979 8.827 34.037 21.379 34.037 40.492 0 22.745-17.104 41.209-52.307 39.412zm163.648-60.241c-11.707 10.325-28.738 14.683-48.245 14.283-4.273-.089-8.105-.379-11.069-.867v50.059l-31.338-1.563V233.307c9.617-1.953 23.352-3.645 43.048-4.307 20.399-.693 35.237 2.651 45.337 10.058 9.785 7.074 16.431 19.066 16.431 33.37-.001 14.305-5.052 26.405-14.164 34.516zM553.24 497.823c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.231V382.273c12.072 1.669 28.859 2.646 51.969 2.646 64.21 0 92.394-11.779 97.019-16.746 4.631-5.008-1.438-8.935-1.438-8.935s2.138 2.504-.691 5.355c-2.856 2.839-28.908 9.967-70.653 12.104-28.992 1.501-59.69-.977-76.215-4.415v-11.984c5.575-1.533 10.949-2.478 10.949-2.478-.943.116-5.417.283-10.949.787v-58.642c9.266 4.573 28.462 7.229 57.512 6.211 50.782-1.817 56.758-11.381 62.059-15.977-29.418 8.013-107.528 7.499-108.09 1.606-.52-5.885 24.079-10.706 24.079-10.706s-23.838.021-35.549 4.729V70.722c.766-.112 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.126v411.217z" fill={color} />
    <path  d="M452.357 179.983c12.818-20.874-1.617-41.191-1.617-41.191s3.212 14.982-5.343 30.493c-8.557 15.515-40.163 24.625-52.452 51.363-12.283 26.759 27.291 57.263 27.291 57.263s-14.452-27.84-13.922-48.174c.395-14.535 33.156-28.897 46.043-49.754z" fill={color} />
    <path  d="M474.3 193.368s-49.229 18.721-49.229 40.115c0 21.413 13.375 28.363 15.518 35.313 2.142 6.966-3.737 18.733-3.737 18.733s19.264-13.368 16.031-28.904c-3.213-15.519-18.194-20.33-9.627-35.843 5.721-10.391 31.044-29.414 31.044-29.414zM484.453 282.184s21.941 0 24.073 13.376c2.132 13.368-27.811 33.157-27.811 33.157s47.16-9.495 42.771-33.68c-5.343-29.424-39.033-12.853-39.033-12.853zM379.559 317.479c-2.104-3.744 3.77-5.874 3.77-5.874-26.783 6.436-12.152 17.644 19.218 19.812 26.889 1.826 56.94-8.042 56.94-8.042l9.261-7.481s-21.198 2.945-37.827 4.814c-22.28 2.503-49.215.524-51.362-3.229zM388.692 343.186c-1.092-2.505 1.056-3.916 1.056-3.916s-12.132.335-12.836 6.761c-.712 6.388 3.771 9.627 33.786 8.925 35.733-.852 41.69-5.103 45.37-8.201l8.705-6.562c-23.356 3.03-25.161 3.854-38.4 4.23-13.307.389-36.625 1.234-37.681-1.237zM481.418 389.191c-28.188 5.716-113.754 2.121-113.754 2.121s55.611 13.19 119.104 2.141c30.347-5.279 32.121-19.983 32.121-19.983s-9.297 10.005-37.471 15.721z" fill={color} />
  </svg>
)

export default Jsp
