import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  RequestDroppedFiles,
  RequestExtractedPayloads,
  RequestMemoryDumps,
  RequestPcapFile,
} from "../../../../../store/actions/analysisReportActions";

import DownloadIcon from "../../../../Icon-SVG/DownloadIcon";
import TypeCard from "../TypeCard/TypeCard";
import Backdrop from "../../../../UI/Backdrop/Backdrop";
import { addNotification } from "../../../../../store/actions/notifierActions";

import axiosApi from "../../../../../utils/axios";
import DownloadMessage from "../../../DownloadMessage/DownloadMessage";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DroppedFileListSidebar = ({ selectedFile, setSelectedFile, loading }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [active, setActive] = useState("Dropped Files");
  const [droppedFilesData, setDroppedFilesData] = useState([]);
  const [memoryDumpsData, setMemoryDumpsData] = useState([]);
  const [pcapFilesData, setPcapFilesData] = useState([]);
  const [extractedPayloadsData, setExtractedPayloadsData] = useState([]);
  const [downloadOptions, setDownloadOptions] = useState({
    loaded: 0,
    total: 0,
    progress: 0,
    downloadStarted: false,
  });

  const extractedPayloads = useSelector((state) => state.analysisReports.extractedPayloads);
  const droppedFiles = useSelector((state) => state.analysisReports.droppedFiles);
  const memoryDumps = useSelector((state) => state.analysisReports.memoryDumps);
  const pcapFile = useSelector((state) => state.analysisReports.pcapFile);
  const generalInfo = useSelector((state) => state.analysisReports.general);

  const downloadFileName = `art_${generalInfo?.sha1}.gz`;

  let counter = 0;

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  const clickedFileID = location.state?.clickedFileID;

  useEffect(() => {
    dispatch(
      RequestExtractedPayloads({
        parentID: query.get("data"),
        clickedFileID: clickedFileID || query.get("data"),
      }),
    );
    dispatch(
      RequestDroppedFiles({
        parentID: query.get("data"),
        clickedFileID: clickedFileID || query.get("data"),
      }),
    );
    dispatch(
      RequestMemoryDumps({
        parentID: query.get("data"),
        clickedFileID: clickedFileID || query.get("data"),
      }),
    );
    dispatch(RequestPcapFile(query.get("data")));
  }, [dispatch, query.get("data"), clickedFileID]);

  useEffect(() => {
    const re = /.py/gi;
    setExtractedPayloadsData([]);
    setDroppedFilesData([]);
    setMemoryDumpsData([]);
    setPcapFilesData([]);
    if (extractedPayloads) {
      const payloads = extractedPayloads.map((data) => {
        return {
          id: counter++,
          type: "Extracted Payload",
          value: data.name,
          details: data.type,
          yara: data.yara,
          virustotal: data.virustotal ? data.virustotal : {},
          fileNames: data.name,
          fileSize: data.size,
          crc32: data.crc32,
          ssdeep: data.ssdeep,
          tlsh: data.tlsh,
          sha3_384: data.sha3_384,
          path: data.path,
          mds: data.md5,
          sha1: data.sha1,
          sha256: data.sha256,
          sha512: data.sha512,
          clamav: data.clamav,
          cdecision: data.cdecision,
          mlcheck: data.mlcheck,
          safebrowsing: data.safebrowsing,
          metadefender: data.metadefender,
        };
      });
      setExtractedPayloadsData(payloads);
    }
    if (droppedFiles) {
      const files = droppedFiles.map((data) => {
        return {
          id: counter++,
          type: "Dropped File",
          value: data.name[0].replace(re, ""),
          details: data.type,
          yara: data.yara,
          // virustotal: "0", // ??
          virustotal: data.virustotal ? data.virustotal : {},
          fileNames: data.name[0].replace(re, ""),
          fileSize: data.size,
          crc32: data.crc32,
          ssdeep: data.ssdeep,
          tlsh: data.tlsh,
          sha3_384: data.sha3_384,
          path: data.path,
          mds: data.md5,
          sha1: data.sha1,
          sha256: data.sha256,
          sha512: data.sha512,
          clamav: data.clamav,
          cdecision: data.cdecision,
          mlcheck: data.mlcheck,
          safebrowsing: data.safebrowsing,
          metadefender: data.metadefender,
        };
      });
      setDroppedFilesData(files);
    }
    if (memoryDumps) {
      const dumps = memoryDumps.map((data) => {
        return {
          id: counter++,
          type: "Memory Dump",
          value: `${data.name} - ${data.pid}`,
          details: data.type,
          yara: data.yara,
          virustotal: data.virustotal ? data.virustotal : {},
          fileNames: `${data.name} - ${data.pid}`,
          fileSize: data.size,
          crc32: data.crc32,
          ssdeep: data.ssdeep,
          tlsh: data.tlsh,
          sha3_384: data.sha3_384,
          path: data.proc_path,
          mds: data.md5,
          sha1: data.sha1,
          sha256: data.sha256,
          sha512: data.sha512,
          clamav: data.clamav,
          cdecision: data.cdecision,
          mlcheck: data.mlcheck,
        };
      });
      setMemoryDumpsData(dumps);
      // const memoryDumps = [];
      // dropFileList.procmemory.forEach((procmemoryData) => {
      //   if (procmemoryData.extracted_pe) {
      //     const childData = procmemoryData.extracted_pe.map((data) => {
      //       return {
      //         id: counter++,
      //         type: "Memory Dump",
      //         value: `${data.name} - ${data.file.slice(57)}`,
      //         details: data.type,
      //         yara: data.yara,
      //         virustotal: "0", // ??
      //         fileNames: data.name,
      //         fileSize: data.size,
      //         crc32: data.crc32,
      //         ssdeep: data.ssdeep,
      //         tlsh: data.tlsh,
      //         sha3_384: data.sha3_384,
      //         path: data.path,
      //         mds: data.md5,
      //         sha1: data.sha1,
      //         sha256: data.sha256,
      //         sha512: data.sha512,
      //         clamav: data.clamav,
      //         cdecision: data.cdecision,
      //         mlcheck: data.mlcheck,
      //       };
      //     });
      //     memoryDumps.push(...childData);
      //   }
      // });
      // setMemoryDumpsData(memoryDumps);
    }
    if (Object.keys(pcapFile).length) {
      const pcap = {
        id: counter++,
        type: "PCAP file",
        value: pcapFile.name,
        details: pcapFile.type,
        yara: pcapFile.yara,
        virustotal: pcapFile.virustotal ? pcapFile.virustotal : {},
        fileNames: pcapFile.name,
        fileSize: pcapFile.size,
        crc32: pcapFile.crc32,
        ssdeep: pcapFile.ssdeep,
        tlsh: pcapFile.tlsh,
        sha3_384: pcapFile.sha3_384,
        path: pcapFile.path,
        mds: pcapFile.md5,
        sha1: pcapFile.sha1,
        sha256: pcapFile.sha256,
        sha512: pcapFile.sha512,
        clamav: pcapFile.clamav,
        cdecision: pcapFile.cdecision,
        mlcheck: pcapFile.mlcheck,
        safebrowsing: pcapFile.safebrowsing,
        metadefender: pcapFile.metadefenfer,
      };
      setPcapFilesData([pcap]);
    }
  }, [extractedPayloads, droppedFiles, memoryDumps, pcapFile]);

  useEffect(() => {
    if (extractedPayloadsData.length) {
      setSelectedFile(extractedPayloadsData[0]);
      setActive("Extracted Payloads");
    } else if (droppedFilesData.length) {
      setSelectedFile(droppedFilesData[0]);
      setActive("Dropped Files");
    } else if (memoryDumpsData.length) {
      setSelectedFile(memoryDumpsData[0]);
      setActive("Memory Dumps");
    } else if (pcapFilesData.length) {
      setSelectedFile(pcapFilesData[0]);
      setActive("PCAP Files");
    } else {
      setSelectedFile(null);
    }
  }, [droppedFilesData, memoryDumpsData, pcapFilesData, extractedPayloadsData]);

  const downloadArtifactFilesHandler = async () => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total, progress } = progressEvent;
        setDownloadOptions({ loaded, total, progress: progress * 100, downloadStarted: true });
      },
    };
    try {
      const response = await axiosApi.get(
        `/api/completed/artefacts/download/${query.get("data")}`,
        {
          responseType: "blob",
          ...options,
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      dispatch(addNotification({ message: "Error", options: { variant: "error" } }));
    } finally {
      setTimeout(() => {
        setDownloadOptions((options) => ({ ...options, downloadStarted: false }));
      }, 2000);
    }
  };

  if (loading) return;

  return (
    <div className="dropped-files__sidebar">
      <button className="dropped-files__sidebar__download" onClick={downloadArtifactFilesHandler}>
        Download Artifact Files <DownloadIcon width={18} />
      </button>
      <TypeCard
        value={extractedPayloadsData}
        onClick={setActive}
        active={active}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        title="Extracted Payloads"
      />
      <TypeCard
        value={droppedFilesData}
        onClick={setActive}
        active={active}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        title="Dropped Files"
      />
      <TypeCard
        value={memoryDumpsData}
        onClick={setActive}
        active={active}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        title="Memory Dumps"
      />
      <TypeCard
        value={pcapFilesData}
        onClick={setActive}
        active={active}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        title="PCAP Files"
      />
      <Backdrop
        show={downloadOptions.downloadStarted}
        message={
          <DownloadMessage
            fileName={downloadFileName}
            loaded={downloadOptions.loaded}
            total={downloadOptions.total}
            progress={downloadOptions.progress}
          />
        }
      />
    </div>
  );
};

export default DroppedFileListSidebar;
