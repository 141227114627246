import React from "react";

const SidebarClose = ({ color = "var(--text-color-card)", width = 18, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.8285 10.8285L1 8L3.8285 5.1715M1 1.5H17H1ZM1 8H17H1ZM1 14.5H17H1Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SidebarClose;
