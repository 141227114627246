import Dump from "./components/Dump";
import Restore from "./components/Restore";

import "./styles.scss";

const DumpAndRestore = () => {
  return (
    <div className="dump-and-restore-container">
      <Dump />
      <Restore />
    </div>
  );
};

export default DumpAndRestore;
