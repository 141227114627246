import React, { useEffect } from "react";
import Input from "../Input/Input";
import ToggleButton from "../../ToggleButton/ToggleButton";
import "../styles.scss";

const Content = ({
  currentFileIndex,
  activeOs,
  type = ["high_evasion"],
  setType,
  commandLine,
  setCommandLine,
  environmentalVariable,
  setEnvironmentalVariable,
  relatedPasswords,
  setRelatedPasswords,
  range,
  setRange,
  actionScript,
  setActionScript,
  enforceRuntime,
  setEnforceRuntime,
  memoryDump,
  setMemoryDump,
  radio = ["vpn"],
  setRadio,
  configSave,
}) => {
  useEffect(() => {
    configSave(currentFileIndex);
    if (activeOs !== "Linux" && type.length === 0) {
      setType(["high_evasion"]);
    }
    if (radio.length === 0) {
      setRadio(["vpn"]);
    }
  }, [type, commandLine, environmentalVariable, relatedPasswords, radio]);

  const typeSettings = async (confType) => {
    let typeOps;

    try {
      type?.map((data) => data === confType && (typeOps = "delete"));
      typeOps === "delete"
        ? setType((selectedType) => selectedType.filter((data) => data !== confType))
        : setType([...type, confType]);
    } catch (error) {
      console.log(error);
    }
  };

  const radioSettings = async (confRadio) => {
    let radioOps;

    try {
      radio?.map((data) => data === confRadio && (radioOps = "delete"));
      radioOps === "delete"
        ? setRadio((selectedRadio) => selectedRadio.filter((data) => data !== confRadio))
        : setRadio([...radio, confRadio]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <section
        className="submit-popup-container__type"
        style={{ marginTop: "5px", marginBottom: "5px" }}>
        <p className="light margin-top">Type</p>
        {activeOs === "Linux" ? (
          <p className="submit-popup-container__type--labels">
            Automatic Evasive Analysis selected
          </p>
        ) : (
          <div className="submit-popup-container__type--labels">
            <label className="align-items">
              <input
                type="checkbox"
                value="full_triage"
                name="full_triage"
                className="submit-popup-container__memory-dump--input"
                onChange={(e) => typeSettings(e.target.value, activeOs)}
                checked={
                  type[0] === "full_triage" ||
                  type[1] === "full_triage" ||
                  type[2] === "full_triage"
                }
              />
              <p className="margin-left-12">Full Triage</p>
            </label>
            <label className="align-items">
              <input
                type="checkbox"
                value="high_evasion"
                name="high_evasion"
                className="submit-popup-container__memory-dump--input"
                checked={
                  type[0] === "high_evasion" ||
                  type[1] === "high_evasion" ||
                  type[2] === "high_evasion"
                }
                onChange={(e) => typeSettings(e.target.value, activeOs)}
              />
              <p className="margin-left-12">High Evasion</p>
            </label>

            <label className="align-items">
              <input
                type="checkbox"
                value="basic_evasion"
                name="basic_evasion"
                className="submit-popup-container__memory-dump--input"
                checked={
                  type[0] === "basic_evasion" ||
                  type[1] === "basic_evasion" ||
                  type[2] === "basic_evasion"
                }
                onChange={(e) => typeSettings(e.target.value)}
              />
              <p className="margin-left-12">Basic Evasion</p>
            </label>
          </div>
        )}
        <div
          className="submit-popup-container__action-script__inputs"
          style={{ marginTop: "10px" }}>
          <div>
            <p className="light margin-top">Command Line</p>
            <Input
              onChange={(e) => setCommandLine(e.target.value)}
              value={commandLine}
              placeholder="e.g /r /t"
            />
          </div>
          <div>
            <p className="light margin-top">Environmental Variable</p>
            <Input
              onChange={(e) => setEnvironmentalVariable(e.target.value)}
              value={environmentalVariable}
              placeholder="e.g ALLPROFILE=C:\Data"
            />
          </div>
        </div>
        <div className="submit-popup-container__action-script__inputs">
          <div>
            <p className="light margin-top-14">Related Passwords</p>
            <Input
              onChange={(e) => setRelatedPasswords(e.target.value)}
              value={relatedPasswords}
              placeholder="e.g abv123.test123"
            />
          </div>
          <div>
            <p className="light margin-top-14">Runtime Duration</p>
            <div className="submit-popup-container__range-container">
              <div className="submit-popup-container__range-container__data">
                <div></div>
                <div>{range}</div>
                <div></div>
              </div>
              <input
                type="range"
                max="360"
                onChange={(e) => setRange(Number(e.target.value))}
                value={range}
              />
            </div>
          </div>
        </div>
        <div className="submit-popup-container__action-script__enforce">
          <div className="submit-popup-container__action-script__selector">
            <p className="light">Action Script</p>
            <select
              id="standard-select"
              value={actionScript}
              onChange={(e) => setActionScript(e.target.value)}>
              <option value="default">Default</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
            </select>
            <p className="bleached font-size-12">Runtime action script</p>
          </div>
          <div className="margin-top-bottom-8" style={{ marginLeft: "85px" }}>
            <p className="light">Enforce Runtime</p>
            <div className="submit-popup-container__action-script__enforce--toggle">
              <ToggleButton
                active={enforceRuntime}
                rightText={enforceRuntime ? "Enable" : "Disable"}
                onClick={setEnforceRuntime}
              />
            </div>
            <p className="bleached font-size-12">Force VM to fit above time</p>
          </div>
        </div>
      </section>
      <section className="submit-popup-container__memory-dump">
        <p className="light margin-top">Artifact Analysis</p>
        <div className="submit-popup-container__memory-dump--labels">
          <label className="align-items">
            <input
              type="radio"
              value="basic"
              name="basic"
              className="submit-popup-container__memory-dump--input"
              checked={"basic" === memoryDump}
              onChange={(e) => setMemoryDump(e.target.value)}
            />
            <p className="margin-left-12">Basic</p>
          </label>
          <label className="align-items">
            <input
              type="radio"
              value="advanced"
              name="advanced"
              className="submit-popup-container__memory-dump--input"
              checked={"advanced" === memoryDump}
              onChange={(e) => setMemoryDump(e.target.value)}
            />
            <p className="margin-left-12">Advanced</p>
          </label>
          <label className="align-items">
            <input
              type="radio"
              value="full"
              name="full"
              className="submit-popup-container__memory-dump--input"
              checked={"full" === memoryDump}
              onChange={(e) => setMemoryDump(e.target.value)}
            />
            <p className="margin-left-12">Full</p>
          </label>
        </div>
      </section>
      <section className="submit-popup-container__network-traffic">
        <p className="light margin-top">Network Traffic</p>
        <div className="submit-popup-container__network-traffic--labels margin-top-bottom-8">
          <div className="align-items">
            <input
              type="checkbox"
              id="internet"
              value="internet"
              className="submit-popup-container__network-traffic--labels--input"
              onChange={(e) => radioSettings(e.target.value)}
              checked={
                radio[0] === "internet" ||
                radio[1] === "internet" ||
                radio[2] === "internet" ||
                radio[3] === "internet"
              }
            />
            <label htmlFor="internet">
              <p className="margin-left-12">Internet</p>
            </label>
          </div>
          <div className="align-items">
            <input
              type="checkbox"
              id="vpn"
              value="vpn"
              className="submit-popup-container__network-traffic--labels--input"
              onChange={(e) => radioSettings(e.target.value)}
              checked={
                radio[0] === "vpn" || radio[1] === "vpn" || radio[2] === "vpn" || radio[3] === "vpn"
              }
            />
            <label htmlFor="vpn">
              <p className="margin-left-12">VPN</p>
            </label>
          </div>
          <div className="align-items">
            <input
              type="checkbox"
              id="simulate"
              value="simulate"
              className="submit-popup-container__network-traffic--labels--input"
              onChange={(e) => radioSettings(e.target.value)}
              checked={
                radio[0] === "simulate" ||
                radio[1] === "simulate" ||
                radio[2] === "simulate" ||
                radio[3] === "simulate"
              }
            />
            <label htmlFor="simulate">
              <p className="margin-left-12">Simulate</p>
            </label>
          </div>
          <div className="align-items">
            <input
              type="checkbox"
              id="disable"
              value="disable"
              className="submit-popup-container__network-traffic--labels--input"
              onChange={(e) => radioSettings(e.target.value)}
              checked={
                radio[0] === "disable" ||
                radio[1] === "disable" ||
                radio[2] === "disable" ||
                radio[3] === "disable"
              }
            />
            <label htmlFor="disable">
              <p className="margin-left-12">Disable</p>
            </label>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Content;
