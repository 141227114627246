import React, { useRef, useState } from "react";
import InformationIcon from "../../Icon-SVG/InformationIcon";
import CloseIcon from "../../Icon-SVG/CloseIcon";
import { useOnClickOutside } from "../../../utils/hook/useOnClickOutside";
import "./styles.scss";

const Tooltip = ({ text, tooltipWidth }) => {
  const [active, setActive] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setActive(false));
  return (
    <span className="m-l-10 tooltip" onClick={() => setActive(!active)} ref={ref}>
      <InformationIcon color={active ? "var(--color-blue)" : "var(--tooltip-background)"} />
      {active && (
        <span className="tooltip__modal" style={{ width: tooltipWidth }}>
          {text}
          <button className="tooltip__modal-button" onClick={() => setActive(false)}>
            <CloseIcon color="var(--settings-article-value)" />
          </button>
        </span>
      )}
    </span>
  );
};

export default Tooltip;
