import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: null,
  rememberMe: false,
};

const name = "users";

const usersSlice = createSlice({
  name,
  initialState: {
    ...initialState,
    registerLoading: false,
    registerError: null,
    loginLoading: false,
    loginError: null,
    userChanged: false,
    users: [],
    role: [],
    loading: false,
    error: false,
    timeoutCheck: "",
    licenceCheck: [],
    licenceReg: false,
    publicKey: "",
    apiLimiter: 100,
  },
  reducers: {
    registerRequest: (state) => {
      state.registerLoading = true;
    },
    registerSuccess: (state, { payload: user }) => {
      state.registerLoading = false;
      state.user = user;
    },
    registerFailure: (state, { payload: error }) => {
      state.registerLoading = false;
      state.registerError = error;
    },
    loginRequest: (state) => {
      state.loginLoading = true;
    },
    loginSuccess: (state, payload) => {
      state.loginLoading = false;
      state.rememberMe = payload?.payload.rememberMe;
      state.user = payload?.payload.user;
      state.loginError = null;
    },
    loginFailure: (state, payload) => {
      state.loginLoading = false;
      state.loginError = payload?.payload;
    },
    logoutRequest: () => {},
    logoutSuccess: (state) => {
      state.user = null;
    },
    forgotPasswordRequest: (state) => {
      state.loginLoading = true;
    },
    forgotPasswordSuccess: (state) => {
      state.loginLoading = false;
    },
    forgotPasswordFailure: (state, payload) => {
      state.loginLoading = false;
      state.loginError = payload?.payload;
    },
    resetPasswordRequest: (state) => {
      state.loginLoading = true;
    },
    resetPasswordSuccess: (state) => {
      state.loginLoading = false;
    },
    resetPasswordFailure: (state, payload) => {
      state.loginLoading = false;
      state.loginError = payload?.payload;
    },
    getAllUsersRequest: (state) => {
      state.loading = true;
    },
    getAllUsersSuccess: (state, payload) => {
      state.loading = false;
      state.users = payload?.payload;
    },
    getAllUsersFailure: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
    createUserRequest: (state) => {
      state.loading = true;
    },
    createUserSuccess: (state) => {
      state.loading = false;
    },
    createUserFailure: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
    updateUserRequest: (state) => {
      state.loading = true;
    },
    updateUserSuccess: (state) => {
      state.loading = false;
    },
    updateUserFailure: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
    deleteUserRequest: (state) => {
      state.loading = true;
    },
    deleteUserSuccess: (state) => {
      state.loading = false;
    },
    deleteUserFailure: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
    getAdminRoleRequest: (state) => {
      state.loading = true;
    },
    getAdminRoleSuccess: (state, payload) => {
      state.loading = false;
      state.role = payload?.payload;
    },
    getAdminRoleFailure: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
    setUserChanged: (state, payload) => {
      state.userChanged = payload?.payload;
    },
    timeoutCheckRequest: (state) => {
      state.loading = true;
    },
    timeoutCheckSuccess: (state, payload) => {
      state.loading = false;
      state.timeoutCheck = payload?.payload;
    },
    timeoutCheckFailure: (state, payload) => {
      state.loading = false;
      state.timeoutCheck = payload?.payload;
    },
    licenceCheckRequest: (state) => {
      state.loading = true;
    },
    licenceCheckSuccess: (state, payload) => {
      state.loading = false;
      state.licenceCheck = payload?.payload;
    },
    licenceCheckFailure: (state, payload) => {
      state.loading = false;
      state.licenceCheck = payload?.payload;
    },
    licenceRegRequest: (state) => {
      state.loading = true;
    },
    licenceRegSuccess: (state, payload) => {
      state.loading = false;
      state.licenceReg = payload?.payload;
    },
    licenceRegFailure: (state, payload) => {
      state.loading = false;
      state.licenceReg = payload?.payload;
    },
    //** THIS SECTION CREATE SUPERADMIN FOR OLD SYSTEM ****/
    createSuperAdminRequest: (state) => {
      state.loading = true;
    },
    createSuperAdminSuccess: (state) => {
      state.loading = false;
    },
    createSuperAdminFailure: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
    //** THIS SECTION CREATE SUPERADMIN FOR OLD SYSTEM ****/
    publicKeyRegRequest: (state) => {
      state.loading = true;
    },
    publicKeyRegSuccess: (state, payload) => {
      state.loading = false;
      state.publicKey = payload?.payload;
    },
    publicKeyRegFailure: (state) => {
      state.loading = false;
    },
    publicKeyCheckRequest: (state) => {
      state.loading = true;
    },
    publicKeyCheckSuccess: (state, payload) => {
      state.loading = false;
      state.publicKey = payload?.payload;
    },
    publicKeyCheckFailure: (state) => {
      state.loading = false;
    },
    apiLimitterGetRequest: (state) => {
      state.loading = true;
    },
    apiLimitterGetSuccess: (state, payload) => {
      state.loading = false;
      state.apiLimiter = payload?.payload;
    },
    apiLimitterGetFailure: (state) => {
      state.loading = false;
    },
    apiLimitterSetRequest: (state) => {
      state.loading = true;
    },
    apiLimitterSetSuccess: (state) => {
      state.loading = false;
    },
    apiLimitterSetFailure: (state) => {
      state.loading = false;
    },
  },
});

export default usersSlice;
