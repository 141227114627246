import React from "react";

const Secret = (props) => {
  const secret = props.row.original.secret;
  return (
    <div>
      <span className="dashboard__table__secret">{`${secret?.substring(
        0,
        15,
      )}.........${secret.substring(secret.length - 10)}`}</span>
    </div>
  );
};

export default Secret;
