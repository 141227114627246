import React from "react";
import Chart from "../../../common/Chart/Chart";
import InformationPopup from "../../../common/InformationPopup/InformationPopup";
import { informationPopupContent } from "../../../common/InformationPopup/utils/informationPopupUtil";
/*report: this variable belongs to the pdf page */
const Classification = ({ theme, report = false }) => {
  return (
    <div
      className={`analysis-reports__body-block__classification ${
        report ? "analysis-reports__body-block__classification--report" : ""
      } ${!report && "border"}`}>
      {!report && <h3 className="article">Classification</h3>}
      {theme && <Chart name={`radar-${theme}`} type="radar" report={report} />}
      {!report && (
        <span className="analysis-reports__body-block__classification__information-popup">
          <InformationPopup content={informationPopupContent.classification} />
        </span>
      )}
    </div>
  );
};

export default Classification;
