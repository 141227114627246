import * as React from "react"

const Vb = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M262.589 271.418c0-11.239-8.785-16.916-24.095-16.471-7.223.221-11.351.775-14.163 1.287v33.793l10.869-.098c17.701-.138 27.389-7.49 27.389-18.511zM235.641 313.559l-11.31-.075v40.904c3.241.535 7.16.661 12.848.846 15.286.486 29.031-4.819 29.031-20.5 0-15.008-13.75-21.066-30.569-21.175z" fill={color} />
    <path  d="M11.176 46.206v492.311l346.22 47.401v-50.583c.776.053 1.542.115 2.329.115h177.39c20.745 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.882-37.628-37.627-37.628h-177.39c-.787 0-1.553.076-2.329.123V0L11.176 46.206zm470.137 187.301v8.966H370.225v-8.966h111.088zm-111.088-13.439v-8.956h111.094v8.956H370.225zm111.088 38.885v8.956H370.225v-8.956h111.088zm0 22.394v8.967H370.225v-8.967h111.088zm0 23.602v8.965H370.225v-8.965h111.088zm-16.441 72.787c-1.764 2.846-2.997 6.3-2.997 10.426 0 4.031 1.182 7.412 2.87 10.184H357.396v-20.609h107.476zm-332.208-4.346-33.434-1.548-38.649-132.107 29.698-1.299 15.039 56.144c4.273 15.969 8.187 31.418 11.198 48.337l.564.021c3.205-16.226 7.182-32.319 11.57-47.874l16.608-59.042 32.311-1.419-44.905 138.787zm154.269-4.935c-11.341 8.222-29.989 12.231-59.58 10.836-16.09-.763-27.909-2.384-35.001-3.77V235.846c8.349-2.045 25.518-4.082 41.977-4.814 20.599-.918 33.479.497 44.72 6.457 10.927 5.176 18.924 15.244 18.924 28.979 0 13.505-8.229 26.161-25.816 32.476v.441c17.819 4.666 31.266 17.778 31.266 37.416-.001 13.787-6.626 24.4-16.49 31.654zM359.726 70.48h177.39c8.893 0 16.125 7.236 16.125 16.127v411.215c0 8.893-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.232V408.972h116.11c-.005.042-.005.083-.016.12h24.757c7.674 0 13.911-7.134 13.911-15.927V189.422c.067-7.488 8.021-7.278 8.913-7.278h.042c3.926 0 7.128 3.655 7.128 8.16 0 4.494-3.196 8.161-7.128 8.161-2.562 0-4.641 2.37-4.641 5.31 0 2.929 2.079 5.31 4.641 5.31 9.05 0 16.409-8.428 16.409-18.78 0-10.365-7.359-18.782-16.267-18.782h-163.86V70.722c.766-.118 1.533-.242 2.33-.242z" fill={color} />
  </svg>
)

export default Vb
