import * as React from "react"

const Acc = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="m74.041 241.893-.308.01c-1.529 6.616-3.056 15.019-4.725 21.445l-6.075 23.477 22.795.098-6.476-23.636c-1.836-6.592-3.684-14.824-5.211-21.394z" fill={color} />
    <path  d="M359.036 537.775c.771.053 1.551.116 2.342.116h178.2c20.84 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.071-2.342.119V0L11.222 46.417v494.564L359.036 588.6v-50.825zm2.342-466.977h178.2c8.933 0 16.199 7.27 16.199 16.2v413.103c0 8.928-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.121-2.342-.242V347.731c18.31 25.438 48.1 42.061 81.833 42.061 55.693 0 100.85-45.151 100.85-100.85 0-55.7-45.151-100.859-100.85-100.859-33.733 0-63.523 16.632-81.833 42.077V71.036c.771-.119 1.54-.238 2.342-.238zm57.006 187.105-.264 80.352h-.011c-.053 7.609-6.919 15.076-16.78 17.518-11.19 2.822-22.939-2.515-24.184-10.488-2.162-8.596 5.188-17.846 16.411-20.646 5.041-1.255 9.987-1.013 14.127.453V229.97l79.086-12.819v107.198c0 8.881-6.613 15.957-16.949 18.567-11.321 2.812-22.254-1.935-24.437-10.626-2.173-8.711 5.241-18.035 16.579-20.872 5.121-1.266 10.157-1.002 14.387.479v-64.583l-57.965 10.589zm-321.16 76.845-8.09-28.909-29.534-.38-7.214 28.129-23.414-.606L59.6 223.483l30.618-.77 32.951 112.709-25.945-.674zm107.578 2.784-8.91-30.333-32.487-.422-7.932 29.479-25.716-.664 33.642-114.748 33.674-.865 36.284 118.302-28.555-.749zm103.229-18.494c8.665.143 18.362-1.529 24.09-3.654l4.419 22.054c-5.368 2.446-17.382 4.894-32.833 4.482-42.844-1.14-64.301-27.433-64.301-61.476 0-40.798 29.444-64.272 67.118-65.267 14.919-.388 26.383 2.26 31.562 4.715l-5.964 22.465c-5.917-2.31-14.086-4.38-24.279-4.206-22.525.377-39.714 13.774-39.714 40.698-.007 24.241 14.611 39.756 39.902 40.189z" fill={color} />
    <path  d="M179.286 240.131h-.346c-1.669 6.929-3.354 15.757-5.189 22.496l-6.673 24.619 25.054.098-7.105-24.796c-2.042-6.902-4.067-15.524-5.741-22.417z" fill={color} />
  </svg>
)

export default Acc
