import * as React from "react";

const AvDefenceIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    xmlSpace="preserve">
    <style>{".av1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path
        className="av"
        d="M512 163.1 213.4 300.2v339.3C213.4 806.8 512 937 512 937s298.6-130.1 298.6-297.5V300.2L512 163.1zm230.7 476.4c0 44.5-42.3 99.6-119.2 155-42 30.3-84.7 53.6-111.5 67.1-26.7-13.5-69.5-36.9-111.5-67.1-76.9-55.4-119.2-110.5-119.2-155V343.8L512 237.9l230.7 105.9v295.7z"
        fill={color}
      />
      <path
        className="av"
        d="m325 131.2-111.6 74.5v98.7H325zM699 131.2l111.6 74.5v98.7H699zM435.1 87h153.8v152.2H435.1z"
        fill={color}
      />
      <circle className="av1" cx={515} cy={537} r={120.4} />
      <circle className="av1" cx={515} cy={425.4} r={43.1} />
      <path
        className="av1"
        d="m434.2 479.9-55.6-55.7v-68H403v57.9l48.5 48.5zM403 705.6h-24.4v-78.7l48.1-48.1L444 596l-41 41zM427.8 510.9h-78.6L301 462.7l17.3-17.2 41 41h68.5zM318.3 612.5 301 595.2l48.2-48.1h78.6v24.4h-68.5zM595.7 479.9l-17.2-17.3 48.4-48.5v-57.9h24.4v68zM651.3 705.6h-24.4V637l-41-41 17.3-17.2 48.1 48.1zM680.8 510.9h-78.7v-24.4h68.6l41-41 17.2 17.2zM711.7 612.5l-41-41h-68.6v-24.4h78.7l48.1 48.1z"
      />
    </g>
  </svg>
);

export default AvDefenceIcon;
