import * as React from "react"

const Sql = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M170.398 259.672c-17.877.748-27.977 19.158-27.977 41.387-.167 22.577 10.266 40.062 27.801 40.397 18.184.354 29.005-17.29 29.005-41.568.001-22.643-10.466-40.979-28.829-40.216z" fill={color} />
    <path  d="M11.176 46.206v492.311l346.22 47.401v-50.583c.776.053 1.542.115 2.329.115h177.39c20.756 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.871-37.628-37.627-37.628h-177.39c-.787 0-1.553.076-2.329.123V0L11.176 46.206zm51.959 313.09c-11.258-.4-22.174-4.036-27.562-7.622l4.399-20.363c5.847 3.507 14.948 7.108 24.493 7.287 10.437.189 16.032-4.583 16.032-11.979 0-7.065-4.85-11.097-16.911-15.906-16.326-6.32-26.743-16.282-26.743-32.187 0-18.663 13.732-33.732 37.155-35.087 11.518-.667 20.145 1.56 26.381 4.286l-5.299 21.2c-4.176-2.056-11.546-4.974-21.522-4.564-9.838.405-14.541 5.555-14.541 11.336 0 7.103 5.596 10.113 18.604 15.336 18.228 7.134 27 17.634 27 33.916 0 19.364-13.573 35.338-41.486 34.347zm158.246 25.413c-17.788-6.059-32.263-12.017-48.203-19.545-2.611-1.176-5.396-1.815-8.171-2.095-25.764-2.672-48.953-22.824-48.953-60.856 0-34.924 20.874-62.402 55.035-64.381 36.912-2.129 58.027 25.985 58.027 60.576 0 28.766-13.342 48.772-29.711 55.759v.734c9.519 3.051 20.283 5.628 30.281 8.017l-8.305 21.791zm114.082-17.828-87.121-2.96V235.472l30.48-1.708v106.278l56.641.955v25.884zM359.726 70.48h177.39c8.893 0 16.125 7.236 16.125 16.127v411.215c0 8.893-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.232V399.438h16.126v-11.77h-16.126v-29.345h9.164v-11.764h-9.164v-62.725h15.841v-11.767h-15.841V210.01h48.938v-11.767h-48.938V70.722c.76-.118 1.532-.242 2.329-.242z" fill={color} />
    <path  d="M367.548 234.596h38.153v11.766h-38.153zM412.855 234.596h38.157v11.766h-38.157zM380.603 272.069h43.703v11.767h-43.703zM432.504 272.069H476.2v11.767h-43.696zM365.732 310.261h18.54v11.769h-18.54zM387.743 310.261h18.54v11.769h-18.54zM409.407 310.261h18.54v11.769h-18.54zM431.418 310.261h18.53v11.769h-18.53zM454.353 310.261h18.541v11.769h-18.541zM476.369 310.261h18.539v11.769h-18.539zM498.027 310.261h18.541v11.769h-18.541zM520.044 310.261h18.529v11.769h-18.529zM371.506 346.555h26.363v11.77h-26.363zM402.777 346.555h26.361v11.77h-26.361zM434.084 346.555h26.368v11.77h-26.368zM464.903 346.555h26.374v11.77h-26.374zM496.222 346.555h26.356v11.77h-26.356zM377.423 387.669h21.018v11.765h-21.018zM402.383 387.669h21.004v11.765h-21.004zM426.924 387.669h21.009v11.765h-21.009zM451.885 387.669h21.009v11.765h-21.009z" fill={color} />
  </svg>
)

export default Sql
