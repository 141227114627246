import * as React from "react";

const SyslogIcon = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 404.05 512"
    id="log-file">
    <g data-name="Layer 2">
      <g data-name="Log File">
        <circle cx="171.54" cy="284.81" r="33.51"></circle>
        <path
          fill={color}
          d="M0 407.29a17 17 0 0017 17.09H33.65a10.52 10.52 0 011.26.09V390.38H17A17 17 0 000 407.29zM66.14 390.38H54.91v77.33A44.33 44.33 0 0099.19 512H359.76a44.34 44.34 0 0044.29-44.29v-77.4l-337.76.06zM348.91 75.76h48.75c-.38-.39-.77-.78-1.19-1.15L326.33 12.27V53.19A22.6 22.6 0 00348.91 75.76z"></path>
        <path
          fill={color}
          d="M348.91 95.76a42.62 42.62 0 01-42.58-42.57V.14A20.83 20.83 0 00304 0H110.05A55.2 55.2 0 0054.91 55.14V176.92l349.14-.07V95.76zM44.91 196.92H17.61a17 17 0 00-17 17L.21 374.44A36.67 36.67 0 0117 370.38H28.43l375.62-.07h0V196.85l-359 .07zm256.5 34.39a53.25 53.25 0 0129.92 9.14A10 10 0 11320.14 257a33.51 33.51 0 10-18.73 61.3c16 0 23.9-11.2 26.53-23.51h-15a10 10 0 010-20h26a10 10 0 0110 10c0 31.51-19.56 53.51-47.56 53.51a53.51 53.51 0 010-107zm-129.87 0a53.51 53.51 0 11-53.5 53.5A53.56 53.56 0 01171.54 231.31zm-116.6 10a10 10 0 0120 0v77c5.76 0 13.34 0 20.41-.07h.06a10 10 0 010 20c-9.92.05-16.68.08-21.33.08-9.58 0-10.16-.1-10.87-.23a10 10 0 01-8.32-9.85z"></path>
      </g>
    </g>
  </svg>
);

export default SyslogIcon;
