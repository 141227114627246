import { useEffect, useRef, useState } from "react";

import { isSelectAllChecked } from "../utils/customMultiSelectUtils";
import "./styles.scss";

const CustomMultiSelectOptions = ({ options, onToggleSelectAll, onToggleSelectSingle }) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const optionsRef = useRef();

  useEffect(() => {
    const optionsElement = optionsRef.current;

    let sumHeight = 0;
    for (let i = 0; i < optionsElement.children.length; i++) {
      if (i < 5) {
        sumHeight += optionsElement.children[i].offsetHeight;
      }
    }

    setMaxHeight(sumHeight);
  }, []);

  const toggleSelectAllHandler = (e) => onToggleSelectAll(e.target.checked);
  const toggleSelectSingleHandler = (e, optionId) =>
    onToggleSelectSingle(optionId, e.target.checked);

  return (
    <div className="multiselect__options">
      <div className="multiselect__option multiselect__select-all">
        <label>
          <input
            type="checkbox"
            onChange={toggleSelectAllHandler}
            checked={isSelectAllChecked(options)}
          />
          Select All
        </label>
      </div>
      <div
        className="multiselect__single-option-area"
        style={{ maxHeight: maxHeight }}
        ref={optionsRef}>
        {options?.map((option) => (
          <div key={option?.value} className="multiselect__option">
            <label>
              <input
                type="checkbox"
                onChange={(e) => toggleSelectSingleHandler(e, option?.value)}
                checked={option?.selected}
              />
              {option?.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomMultiSelectOptions;
