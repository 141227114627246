const DomainIcon = ({ width = 24, height = 24, color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      fill={color}
      height={height}
      width={width}>
      <path d="m9.914,13.747l-.363-4.221h1.042l.249,3.158,1.209-2.327,1.209,2.327.247-3.158h1.044l-.363,4.221c-.031.44-.337.779-.706.779-.244,0-.471-.151-.601-.4l-.831-1.6-.831,1.6c-.129.249-.356.4-.601.4-.368,0-.675-.339-.706-.779Zm6.654.779c.244,0,.471-.151.601-.4l.831-1.6.831,1.6c.129.249.356.4.601.4.368,0,.675-.339.706-.779l.363-4.221h-1.044l-.247,3.158-1.209-2.327-1.209,2.327-.249-3.158h-1.042l.363,4.221c.031.44.337.779.706.779Zm-12.706-.779c.031.44.337.779.706.779.244,0,.471-.151.601-.4l.831-1.6.831,1.6c.129.249.356.4.601.4.368,0,.675-.339.706-.779l.363-4.221h-1.044l-.247,3.158-1.209-2.327-1.209,2.327-.249-3.158h-1.042l.363,4.221Zm20.137-1.747c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12ZM14.604,2.356c.769.955,1.638,2.195,2.362,3.644h3.015c-1.321-1.753-3.201-3.056-5.377-3.644Zm-2.604.056c-.679.721-1.76,1.986-2.692,3.588h5.383c-.932-1.602-2.012-2.867-2.692-3.588Zm-7.981,3.588h3.015c.725-1.449,1.593-2.689,2.362-3.644-2.176.588-4.056,1.891-5.377,3.644Zm-2.019,6c0,1.422.303,2.774.841,4h18.318c.538-1.226.841-2.578.841-4s-.303-2.774-.841-4H2.841c-.538,1.226-.841,2.578-.841,4Zm7.308,6c.932,1.602,2.012,2.867,2.692,3.588.679-.721,1.76-1.986,2.692-3.588h-5.383Zm.088,3.644c-.769-.955-1.638-2.195-2.362-3.644h-3.015c1.321,1.753,3.201,3.056,5.377,3.644Zm10.585-3.644h-3.015c-.725,1.449-1.593,2.689-2.362,3.644,2.176-.588,4.056-1.891,5.377-3.644Z" />
    </svg>
  );
};

export default DomainIcon;
