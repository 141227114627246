import React, { useState } from "react";
import TabButton from "../../../common/TabButton/TabButton";
import Map from "../../../common/Map/Map";
import SnortSuricata from "../../../../Pages/AnalysisReports/Content/Tabs/SnortSuricata/SnortSuricata";
import HostsAndDomains from "../../../../Pages/AnalysisReports/Content/Tabs/HostsAndDomains/HostsAndDomains";

import "./styles.scss";

const NetworkActivityMap = () => {
  const [active, setActive] = useState("Network Activity");

  return (
    <div className="network-activity-map ">
      <div id="network-activity-map-scroll-element" />
      <div className="network-activity-map_tabs button-block">
        <TabButton title="Network Activity" onClick={setActive} active={active} />
        <TabButton title="Network Alerts " onClick={setActive} active={active} />
        <TabButton title="Hosts and Domains" onClick={setActive} active={active} />
      </div>
      {active === "Network Activity" ? <Map /> : ""}
      {active === "Network Alerts " ? <SnortSuricata /> : ""}
      {active === "Hosts and Domains" ? <HostsAndDomains /> : ""}
    </div>
  );
};

export default NetworkActivityMap;
