import * as React from "react"

const Vcd = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="M269.146 264.779c-6.172.206-10.146.87-12.48 1.469v77.496c2.334.569 6.12.658 9.574.732 25.526.754 42.673-12.725 42.673-42.056.2-25.507-15.447-38.458-39.767-37.641z" fill={color} />
    <path  d="M359.031 537.785c.781.053 1.551.116 2.342.116h178.2c20.851 0 37.8-16.96 37.8-37.8v-413.1c0-20.841-16.949-37.8-37.8-37.8h-178.2c-.791 0-1.561.074-2.342.124V0L11.227 46.419V540.98l347.804 47.62v-50.815zm2.342-466.984h178.2c8.933 0 16.2 7.267 16.2 16.2v413.101c0 8.933-7.268 16.199-16.2 16.199h-178.2c-.802 0-1.571-.115-2.342-.231V361.12h31.019a84.287 84.287 0 0 1-2.9-9.392h-28.118v-11.955h3.702a1.28 1.28 0 0 0 1.287-1.281V322.26a1.28 1.28 0 0 0-1.287-1.276h-3.702v-7.678h27.096c1.635-8.354 4.472-16.279 8.279-23.617-6.846 1.571-14.396 2.457-22.37 2.457-4.493 0-8.844-.313-13.005-.841v-6.212c4.061.627 8.343.983 12.805.983 10.378 0 19.917-1.825 27.537-4.849a89.05 89.05 0 0 1 17.382-19.246c-1.002-12.316-20.693-22.151-44.919-22.151-4.462 0-8.744.351-12.805.984v-4.86c4.166-.535 8.512-.844 13.005-.844 25.323 0 46.617 8.796 53.167 20.791 11.169-7.203 24.015-11.984 37.857-13.556l-3.765-24.033a1.278 1.278 0 0 0-1.261-1.086h-99.004V71.044c.77-.113 1.54-.243 2.342-.243zM91.815 358.077l-24.5-.865L38.841 255.83l21.908-1.031 11.043 43.076c3.132 12.234 6.009 24.058 8.208 37.01h.411c2.352-12.456 5.263-24.807 8.456-36.756l12.14-45.236 23.488-1.112-32.68 106.296zm96.051-15.978c7.522.158 15.934-1.36 20.957-3.291l3.85 20.345c-4.693 2.221-15.159 4.383-28.537 3.893-36.63-1.351-54.741-25.229-54.741-55.962 0-36.806 24.867-58.509 57.164-60.086 12.938-.627 22.929 1.608 27.475 3.784l-5.21 20.779c-5.179-2.035-12.295-3.828-21.115-3.535-19.385.641-34.056 13.027-34.056 37.48-.001 22.012 12.46 36.139 34.213 36.593zm130.75 9.391c-13.732 10.373-34.151 14.708-58.348 13.827-14.099-.512-23.931-1.74-30.52-2.832V248.342c9.737-2.015 22.631-3.501 36.503-4.163 23.648-1.142 39.393 2.289 51.985 10.702 13.785 9.081 22.581 24.239 22.581 46.375 0 24.015-9.387 40.389-22.201 50.234z" fill={color} />
    <path  d="M395.661 330.359c0 41.513 33.649 75.168 75.157 75.168 41.512 0 75.156-33.655 75.156-75.168 0-41.496-33.645-75.138-75.156-75.138-41.502 0-75.157 33.642-75.157 75.138zm97.79-65.964-16.326 33.317c-3.164-1.224-6.307-.564-6.307-.564l-9.439-34.994c-.001-.007 12.513-5.628 32.072 2.241zm-22.633 36.396c16.331 0 29.573 13.236 29.573 29.568s-13.236 29.574-29.573 29.574c-16.327 0-29.579-13.236-29.579-29.574.005-16.332 13.252-29.568 29.579-29.568z" fill={color} />
    <path  d="M470.818 356.526c14.428 0 26.166-11.738 26.166-26.167 0-14.428-11.738-26.166-26.166-26.166-14.418 0-26.162 11.738-26.162 26.166 0 14.429 11.744 26.167 26.162 26.167zm0-49.306c12.767 0 23.15 10.378 23.15 23.15 0 12.757-10.384 23.135-23.15 23.135-12.752 0-23.141-10.378-23.141-23.135 0-12.782 10.389-23.15 23.141-23.15z" fill={color} />
    <path  d="M470.818 342.863c6.902 0 12.514-5.611 12.514-12.504 0-6.887-5.611-12.498-12.514-12.498-6.898 0-12.498 5.611-12.498 12.498 0 6.893 5.599 12.504 12.498 12.504zm0-21.99c5.241 0 9.502 4.25 9.502 9.486 0 5.242-4.261 9.492-9.502 9.492-5.237 0-9.482-4.25-9.482-9.492-.005-5.236 4.245-9.486 9.482-9.486z" fill={color} />
  </svg>
)

export default Vcd
