// eslint-disable-line no-unused-expressions
import React from "react";
import ExeIcon from "../../../Icon-SVG/ExeIcon";

/*report: this variable belongs to the pdf page */
const GeneralInformationCard = ({
  fileName,
  size,
  sha1,
  started,
  reportDate,
  fileID,
  linkDisabled,
  setShowPopup,
}) => {
  let listTypeTags = "";
  let listNetworkTags = "";
  let machine = "";
  listTypeTags = fileID.includes("full_triage") ? " Full Triage" : "";
  listTypeTags = fileID.includes("high_evasion") ? " High Evasion" : listTypeTags;
  listTypeTags = fileID.includes("basic_evasion") ? " Basic Evasion" : listTypeTags;
  listNetworkTags = fileID.includes("internet") ? " Internet" : "";
  listNetworkTags = fileID.includes("vpn") ? " Vpn" : listNetworkTags;
  listNetworkTags = fileID.includes("simulate") ? " Simulate" : listNetworkTags;
  listNetworkTags = fileID.includes("disable") ? " Disable" : listNetworkTags;
  machine = fileID.includes("win7")
    ? "Windows 7 x64"
    : fileID.includes("win10")
    ? "Windows 10 x64"
    : fileID.includes("win11")
    ? "Windows 11 x64"
    : fileID.includes("linux22")
    ? "Linux x64"
    : "";

  const reportDateObj = new Date(reportDate);
  const formattedDate = reportDateObj.toLocaleDateString("zh-TW").replace(/\./g, "/");
  const formattedTime = reportDateObj.toLocaleTimeString("zh-TW", { hour12: false });

  return (
    <div className=" analysis-reports__body-block__information border">
      <h3 className="article">General Information</h3>
      <div className="analysis-reports__body-block__information-exe">
        <ExeIcon width={14} height={14} />
        <span>{fileName}</span>
      </div>
      <p>
        File Size (Kb):
        <span className="analysis-reports__body-block__information-value">{size}</span>
      </p>
      <p>
        SHA256:<span className="analysis-reports__body-block__information-value">{sha1}</span>
      </p>
      <p>
        Submission Date:
        <span className="analysis-reports__body-block__information-value">{started}</span>
      </p>
      <p>
        Report Date:
        <span className="analysis-reports__body-block__information-value">{`${formattedDate} (${formattedTime})`}</span>
      </p>
      <p>
        Operating System:
        <span className="analysis-reports__body-block__information-value">{machine}</span>
      </p>
      <p>
        Network Traffic Mode:
        <span className="analysis-reports__body-block__information-value">{listNetworkTags}</span>
      </p>
      <p>
        Analysis Type:
        <span className="analysis-reports__body-block__information-value">{listTypeTags}</span>
      </p>
      <span className="analysis-reports__body-block__information__view-related">
        <button
          onClick={() => setShowPopup(true)}
          className="analysis-reports__body-block__information__button"
          disabled={linkDisabled}>
          View Related
        </button>
      </span>
    </div>
  );
};

export default GeneralInformationCard;
