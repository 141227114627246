import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestSetTelegramConfig,
  requestGetTelegramConfig,
} from "../../../../../../store/actions/settingsActions";

import Field from "../../../Components/Field";
import Warning from "../../../../../../Components/common/Warning/Warning";
import LogoIcon from "../../../../../../Components/Icon-SVG/LogoIcon";

import "../styles.scss";

const TelegramSettings = () => {
  const dispatch = useDispatch();

  const [channelId, setChannelId] = useState("");

  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  const tconfig = useSelector((state) => state.settings.tconfig);

  useEffect(() => {
    dispatch(requestGetTelegramConfig());
  }, []);

  useEffect(() => {
    setChannelId(tconfig);
  }, [tconfig]);

  const saveConfigHandler = () => {
    dispatch(requestSetTelegramConfig(channelId));
  };

  const deleteConfigHandler = () => {};

  return (
    <div style={{ backgroundColor: "var(--layout-bg)" }}>
      <div className="border mailing__content">
        <div className="mailing__content__body">
          <h3 className="m-b-20">Channel Configs</h3>
          <div className="flex flex-align-items-center m-b-10">
            <Field
              label="Group / Channel ID"
              width="131px"
              value={channelId}
              onChange={(e) => setChannelId(e.target.value)}
              parentClassName="p-r-20"
              tooltipText="Please add the '@' symbol next to the channel name. For example: @channelid"
            />
          </div>
          <div className="flex flex-align-items-center m-b-10">
            {/* <button className="m-r-15 default-button" onClick={deleteConfigHandler}>
              Delete
            </button> */}
            <button className="m-l-4 primary-button" onClick={saveConfigHandler}>
              Save
            </button>
          </div>
        </div>
        <Warning
          onClickOk={() => setShow(false)}
          show={show}
          setShow={setShow}
          text={error}
          article="Warning"
          cancelDisable={true}
          textOK="Okay"
        />
      </div>
      <div className="border mailing__content" style={{ marginTop: "20px" }}>
        <div className="mailing__content__body">
          <h3 className="m-b-20">How to Configure Telegram Settings?</h3>
          <div className="flex flex-align-items-center">
            <table style={{ color: "white" }}>
              <tbody>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>Log in to your account as the group administrator.</td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    Include the bot in your own account via{" "}
                    <a
                      style={{ fontWeight: "bolder", color: "var(--color-blue)" }}
                      href="https://t.me/CFnotfiTr_Bot">
                      CFnotfiTr_Bot
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    From the Bot info section, use the "Add to Group" option to add it to the group.
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    Navigate to the Group Info page. Click on the "3 dots" in the top right corner
                    and select "Manage Group."
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>
                    In the opened section, click on "Administrators" and grant admin rights to the
                    user @CFnotfiTr_Bot.
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>{<LogoIcon width={20} height={20} />}</td>
                  <td>Save your Group / Channel ID from the Channel Configs section.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelegramSettings;
