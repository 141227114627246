import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import LoadingIcon from "../../../../Icon-SVG/LoadingIcon";
import Spinner from "../../../../common/Spinner/Spinner";

import { RequestSubmissionsList } from "../../../../../store/actions/dashboardActions";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SeverityStatusStatic = (props) => {
  const dispatch = useDispatch();
  const type = props.row.original.severity || props.type;
  const compressedIDfull = props?.row?.original.compressedIDfull;
  const fileHash = props?.row?.original.file.hash;
  const preStaticResults = useSelector((state) => state.analysisReports.preStaticResults);

  const [isStaticLoading, setIsStaticLoading] = useState(false);

  let query = useQuery();

  const tableName = query.get("table");
  const queryCompressedID = query.get("compressed");
  const queryData = query.get("data");

  useEffect(() => {
    const checkIsStaticResultLoading = () => {
      if (type !== "n/a" || !tableName) {
        setIsStaticLoading(false);
      }

      if (
        tableName === "compressed-static-results" &&
        queryCompressedID &&
        compressedIDfull === queryCompressedID
      ) {
        const isAnyLoading = Object.values(preStaticResults).any(
          (preStaticResultValue) => preStaticResultValue.loading,
        );
        if (isAnyLoading) {
          setIsStaticLoading(true);
        } else {
          if (isStaticLoading) {
            let parsedData = {};

            const storedData = localStorage.getItem("tableTimeFilter");
            if (storedData) {
              parsedData = JSON.parse(storedData);
            }

            dispatch(RequestSubmissionsList(parsedData));
          }
          setIsStaticLoading(false);
        }
      } else if (tableName === "static-results" && fileHash === queryData) {
        if (preStaticResults[fileHash]?.loading) {
          setIsStaticLoading(true);
        } else {
          if (isStaticLoading) {
            let parsedData = {};

            const storedData = localStorage.getItem("tableTimeFilter");
            if (storedData) {
              parsedData = JSON.parse(storedData);
            }

            dispatch(RequestSubmissionsList(parsedData));
          }
          setIsStaticLoading(false);
        }
      } else {
        setIsStaticLoading(false);
      }
    };

    checkIsStaticResultLoading();
  }, [preStaticResults, tableName, queryCompressedID, queryData]);

  return (
    <div
      className={`severity-status severity-status__${
        isStaticLoading
          ? "none"
          : type === "n/a"
          ? "none"
          : type === "not suspicious"
          ? "success"
          : type === "malicious"
          ? "secondary"
          : type === "suspicious"
          ? "suspicious"
          : "loading"
      }`}>
      {type === "loading" ? (
        <LoadingIcon color={"var(--text-color-card)"} />
      ) : isStaticLoading ? (
        <Spinner width={16} height={16} />
      ) : (
        type
      )}
    </div>
  );
};

export default SeverityStatusStatic;
