import React from "react";

const BackArrowAfterIcon = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.004 512.004">
      <path d="M268.068 256.002 509.502 14.568a8.533 8.533 0 0 0 1.852-9.301 8.538 8.538 0 0 0-7.885-5.265H256.002c-2.27 0-4.437.896-6.033 2.5L2.502 249.969a8.525 8.525 0 0 0 0 12.066l247.467 247.467a8.497 8.497 0 0 0 6.033 2.5h247.467a8.53 8.53 0 0 0 6.033-14.566L268.068 256.002z" />
    </svg>
  );
};

export default BackArrowAfterIcon;
