import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest, timeoutCheckRequest } from "../../../store/actions/usersActions";

const SessionTimeout = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.users.user || "");

  useEffect(() => {
    dispatch(timeoutCheckRequest());
  }, [dispatch]);

  useEffect(() => {
    //This block allows logins to be rechecked when backend connectivity is lost.
    if (users && !users.username) {
      dispatch(logoutRequest("timeout"));
    }
  }, [dispatch, users]);
};

export default SessionTimeout;
