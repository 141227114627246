import * as React from "react"

const Ppt = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.33c.776.042 1.542.109 2.329.109h177.39c20.75 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.077-2.329.113V0L11.176 46.206v492.311l346.22 47.401V535.33zm2.33-464.854h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.127-2.329-.243V319.212c9.92 37.738 44.168 65.606 85.018 65.606 48.611 0 88.013-39.401 88.013-88.007h-88.013v-88.017c-40.854 0-75.103 27.872-85.018 65.612V70.711c.765-.112 1.532-.235 2.329-.235zm-243.299 226.63c-8.17 8.126-20.147 11.705-33.982 11.596-3.055-.026-5.795-.21-7.918-.551v39.438l-22.657-.571v-107.69c7.003-1.467 16.906-2.708 31.021-3.081 14.475-.381 24.903 2.338 31.956 8.166 6.806 5.532 11.401 14.833 11.401 25.888.005 11.062-3.488 20.453-9.821 26.805zm95.623.167c-8.89 8.489-21.942 12.211-36.998 12.1-3.318-.021-6.302-.214-8.601-.572v41.136l-24.651-.631V237.053c7.622-1.542 18.384-2.838 33.741-3.245 15.75-.409 27.108 2.402 34.796 8.475 7.401 5.774 12.422 15.483 12.422 27.021 0 11.544-3.821 21.355-10.709 27.969zm112.841-43.1-34.302.56v98.364l-27.779-.709v-97.209l-32.043.527V233.22l94.124-2.441v23.394z" fill={color} />
    <path  d="M84.122 255.121c-4.738.086-7.93.63-9.596 1.155v32.57c1.963.488 4.396.65 7.756.64 12.438-.023 20.191-6.833 20.191-18.266.001-10.282-6.656-16.304-18.351-16.099zM176.879 253.491c-5.145.087-8.622.662-10.422 1.205v33.966c2.139.512 4.779.672 8.428.672 13.546-.022 21.984-7.126 21.984-19.066 0-10.729-7.265-17.002-19.99-16.777zM454.362 282.922v.026h88.018c0-24.753-10.269-47.092-26.725-63.08l-61.293 63.054zM453.428 267.457l62.228-62.231c-17.501-17.512-39.339-26.047-62.29-25.72l.042 87.93.02.021z" fill={color} />
  </svg>
)

export default Ppt
