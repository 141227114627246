import { forwardRef, useState, useEffect, useRef } from "react";
import {
  RequestAddComment,
  RequestGetComment,
} from "../../../../../../store/actions/analysisReportActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Warning from "../../../../../common/Warning/Warning";
import LogoIcon from "../../../../../Icon-SVG/LogoIcon";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AddNewComment = forwardRef(function AddNewComment(
  { username = "", replyTo = "", setReplyMode = null },
  ref,
) {
  const users = useSelector((state) => state?.users.user);

  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [show, setShow] = useState();
  const [error, setError] = useState("");

  const textAreaRef = useRef();

  let counter = 0;
  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  useEffect(() => {
    if (username) {
      setText(`@${username} `);
      textAreaRef.current.focus();
    }
    return () => setText("");
  }, [username]);

  const textChange = (event) => {
    setText(event.target.value);
  };

  const addComment = () => {
    if (text.length < 250) {
      const commentData = {
        fileId: query.get("data"),
        user: users.username,
        text: text,
        replyTo,
      };
      dispatch(RequestAddComment(commentData));
      if (setReplyMode) {
        setReplyMode(false);
      }
      setText("");
      setInterval(() => {
        if (counter === 0) {
          counter++;
          dispatch(RequestGetComment(query.get("data")));
        }
      }, 100);
    } else {
      setError("Please do not exceed 250 characters!");
      setShow(true);
    }
  };

  return (
    <>
      <div className="new-comments__block" ref={ref}>
        {ref ? <div id="new-comments__block__scroll-target" /> : null}
        <LogoIcon width={32} height={32} />
        <textarea
          ref={textAreaRef}
          id="text"
          name="text"
          value={text}
          onChange={textChange}
          className="new-comments__block-textarea"
          placeholder="Add a comment..."
        />
        <button onClick={() => addComment()} className="primary-button">
          {ref ? "REPLY" : "SEND"}
        </button>
      </div>
      {show ? (
        <Warning
          onClickOk={() => setShow(false)}
          show={show}
          setShow={setShow}
          text={error}
          article="Warning"
          cancelDisable={true}
          textOK="Okay"
        />
      ) : null}
    </>
  );
});

export default AddNewComment;
