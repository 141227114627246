import * as React from "react"

const Iso = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M258.812 246.462c-20.213.344-31.846 19.599-31.846 45.069 0 25.658 12.021 43.948 32.054 44.304 20.546.353 32.462-18.965 32.462-45.081-.001-24.118-11.701-44.651-32.67-44.292z" fill={color} />
    <path  d="M357.396 535.335c.776.042 1.542.115 2.329.115h177.39c20.75 0 37.627-16.883 37.627-37.628V86.604c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.069-2.329.113V0L11.176 46.207v492.31l346.22 47.401v-50.583zm2.33-464.856h177.39c8.893 0 16.125 7.233 16.125 16.125v230.014c-11.092-18.147-31.028-30.303-53.863-30.303-34.884 0-63.161 28.287-63.161 63.158 0 34.888 28.277 63.16 63.161 63.16 22.83 0 42.767-12.146 53.863-30.288v115.482c0 8.887-7.232 16.125-16.125 16.125h-177.39c-.792 0-1.563-.12-2.329-.24V406.209h95.675c-17.176-13.019-28.337-33.57-28.337-56.735 0-34.719 24.982-63.68 57.917-69.935v-65.006H357.396v-7.321H468.31V176.55l-110.914 24.846V70.709c.766-.107 1.533-.23 2.33-.23zm173.908 278.995c0 18.924-15.343 34.259-34.257 34.259-18.915 0-34.258-15.34-34.258-34.259 0-18.924 15.343-34.258 34.258-34.258 18.914 0 34.257 15.34 34.257 34.258zm-452.641 3.402-25.942-.673v-122.19l25.942-.667v123.53zm52.067 3.223c-13.704-.368-27.087-4.42-33.719-8.472l5.396-23.192c7.202 3.999 18.341 8.089 29.94 8.284 12.623.221 19.342-5.145 19.342-13.449 0-7.937-5.817-12.499-20.41-18.027-19.905-7.339-32.704-18.813-32.704-36.921 0-21.25 16.862-37.957 45.303-38.704 13.837-.36 24.113 2.404 31.538 5.653l-6.302 23.541c-4.987-2.393-13.783-5.855-25.775-5.651-11.894.2-17.603 5.897-17.603 12.439 0 8.031 6.805 11.536 22.501 17.649 21.798 8.267 32.188 20.105 32.188 38.258-.007 21.574-16.109 39.49-49.695 38.592zm124.384 3.516c-38.938-1.044-61.187-30.641-61.187-67.518 0-38.803 24.543-68.455 63.349-69.48 41.662-1.09 64.96 29.58 64.96 67.129 0 44.609-27.58 70.931-67.122 69.869z" fill={color} />
    <path  d="M478.179 349.474c0 11.687 9.518 21.191 21.198 21.191 11.679 0 21.191-9.505 21.191-21.191 0-11.686-9.513-21.196-21.191-21.196-11.681-.001-21.198 9.51-21.198 21.196zm37.024 0c0 8.726-7.098 15.816-15.82 15.816-8.726 0-15.823-7.091-15.823-15.816 0-8.724 7.098-15.822 15.823-15.822 8.722-.001 15.82 7.098 15.82 15.822z" fill={color} />
  </svg>
)

export default Iso
