import React from "react";
import "./styles.scss";

const ProcessesTree = ({ data }) => {
  const renderTree = (treeData) => {
    return (
      <ul className="tree">
        {treeData.map((node) => (
          <li key={node?.pid}>
            <input type="checkbox" readOnly checked id={`c${node?.pid}`} />
            <label className="tree_label" htmlFor={`c${node?.pid}`}>
              "{node?.name}" - "{node?.module_path}" - "{node?.environ?.Begin}" - "
              {node?.environ?.End}" - "{node?.environ?.CommandLine}" - "
              {node?.environ?.ComputerName}" - "{node?.environ?.Hash}" - "{node?.environ?.TempPath}"
              - "{node?.environ?.WindowsPath}"
            </label>
            <span>PID: {node?.pid}</span>
            {node?.children?.length > 0 && renderTree(node?.children)}
          </li>
        ))}
      </ul>
    );
  };

  return <div>{renderTree(data)}</div>;
};

export default ProcessesTree;
