import React from "react";
import Spinner from "../../common/Spinner/Spinner";
import "./styles.scss";

const Backdrop = ({
  show,
  absolute = false,
  message = "",
  spinner = true,
  messageStyle = {},
  color,
}) =>
  show ? (
    <div
      className={`Backdrop ${absolute ? "Backdrop--absolute" : ""}`}
      style={{ backgroundColor: color ? color : null }}>
      {spinner && <Spinner />}
      {message && (
        <div className="Backdrop__message" style={messageStyle}>
          {message}
        </div>
      )}
    </div>
  ) : null;

export default Backdrop;
