import * as React from "react"

const Wav = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.325c.776.052 1.542.114 2.329.114h177.39c20.75 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.07-2.329.113V0L11.176 46.206v492.311l346.22 47.401v-50.593zm0-464.618c.766-.113 1.532-.231 2.329-.231h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.893-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.127-2.329-.243V70.707zM93.287 350.251l-7.968-43.592c-1.806-10.162-3.326-19.569-4.439-31.052h-.275c-1.662 11.352-3.181 20.906-5.386 31.026l-8.787 42.94-22.541-.562-21.103-98.143 21.512-.57 6.833 40.686c2.015 11.759 3.908 24.579 5.386 34.605l.275.016c1.478-10.751 3.654-22.704 5.95-34.978l7.788-41.016 22.47-.596 7.612 42.625c2.113 11.912 3.688 22.793 5.1 34.137H106c1.42-11.328 3.559-23.274 5.564-35.401l7.885-42.051 22.583-.596-25.303 103.12-23.442-.598zm113.721 2.846-8.129-27.582-29.648-.351-7.236 26.809-23.476-.593 30.712-104.375 30.729-.808 33.124 107.56-26.076-.66zm87.039 2.178-29.905-.751-35.208-109.094 27.118-.716 13.573 46.287c3.843 13.107 7.349 25.749 10.037 39.58l.514.011c2.855-13.307 6.404-26.479 10.299-39.192l14.709-47.985 28.252-.743-39.389 112.603z" fill={color} />
    <path  d="m183.722 264.528-.308.005c-1.529 6.31-3.073 14.33-4.746 20.464l-6.102 22.387 22.879.084-6.499-22.552c-1.834-6.283-3.687-14.126-5.224-20.388zM371.843 327.532h18.966c6.048 0 10.94-4.893 10.94-10.94v11.643l107.45 46.583V210.931l-107.45 47.318v11.643c0-6.037-4.893-10.939-10.94-10.939h-18.966c-6.048 0-10.94 4.903-10.94 10.939v46.699c-.011 6.049 4.892 10.941 10.94 10.941z" fill={color} />
  </svg>
)

export default Wav
