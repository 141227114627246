import * as React from "react"

const Xlr = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="M539.573 49.198h-178.2c-.791 0-1.561.077-2.342.124V0L11.227 46.419V540.98l347.804 47.62v-50.814c.781.053 1.551.116 2.342.116h178.2c20.851 0 37.8-16.96 37.8-37.8V86.999c0-20.839-16.95-37.801-37.8-37.801zM359.031 395.455v-22.18h142.436v-12.836H359.031v-19.406h142.436v-12.825H359.031v-26.346h46.929v-12.838h-46.929v-24.1h46.929V252.1h-46.929v-26.347h46.929v-12.825h-46.929v-16.78h157.386v226.296H359.031v-14.153h142.436v-12.836H359.031zM95.272 363.329l-9.45-21.083c-3.839-8.037-6.275-13.985-9.166-20.588l-.308-.01c-2.12 6.549-4.68 12.382-7.823 20.197l-8.332 20.081-25.175-1.002 28.284-55.698-27.295-54.121 25.363-1.273 8.796 20.163c3.008 6.782 5.276 12.274 7.702 18.624l.309-.011c2.434-7.312 4.417-12.435 7.024-19.122l8.831-21.302 27.707-1.392-30.198 57.581 31.823 60.075-28.092-1.119zm115.612 4.604-74.028-2.953V246.02l25.979-1.308v98.273l48.049 1.081v23.867zm87.429 3.486c-2.312-3.85-5.646-14.829-9.798-31.282-3.72-16.559-9.658-21.109-22.219-21.357l-9.166-.042v51.036l-29.331-1.171V243.135c9.384-1.97 23.59-3.839 39.711-4.659 20.384-1.031 35 1.191 45.188 8.298 8.622 6.054 13.379 15.354 13.379 27.864 0 17.355-13.168 29.345-25.46 33.579v.596c9.956 3.76 15.514 12.793 19.185 25.271 4.54 15.393 9.117 33.296 11.965 38.665l-33.454-1.33zm257.46 128.683c0 8.933-7.268 16.199-16.2 16.199h-178.2c-.802 0-1.571-.115-2.342-.231v-85.071H524.97V187.595H359.031V71.041c.771-.113 1.54-.242 2.342-.242h178.2c8.933 0 16.2 7.269 16.2 16.2v413.103z" fill={color} />
    <path  d="M270.922 260.273c-7.436.266-11.62.994-13.798 1.458v35.817l11.984-.111c15.47-.163 24.806-7.765 24.806-19.419-.004-12.252-8.703-18.061-22.992-17.745zM418.078 212.928h18.536v82.516h-18.536zM450.863 242.85h18.531v52.595h-18.531zM482.936 222.188h18.53v73.256h-18.53z" fill={color} />
  </svg>
)

export default Xlr
