import * as React from "react"

const Crx = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="M162.896 253.826c-5.898.166-9.218.765-10.963 1.145v30.623l9.532-.063c12.248-.076 19.602-6.518 19.602-16.445-.006-10.421-6.875-15.429-18.171-15.26z" fill={color} />
    <path  d="M539.573 49.204h-178.2c-.791 0-1.561.076-2.342.124V0L11.227 46.417V540.98l347.804 47.62v-50.814c.781.053 1.551.116 2.342.116h178.2c20.841 0 37.8-16.965 37.8-37.8V86.999c0-20.839-16.959-37.795-37.8-37.795zM91.642 329.39c6.636.169 14.08-1.229 18.501-3.032l3.407 19.105c-4.153 2.078-13.382 4.092-25.197 3.602-32.432-1.334-48.497-23.862-48.497-52.821 0-34.691 22.069-54.983 50.646-56.257 11.422-.509 20.229 1.656 24.237 3.726l-4.596 19.482c-4.569-1.943-10.842-3.673-18.646-3.451-17.134.498-30.127 12.081-30.127 35.1 0 20.74 11.045 34.06 30.272 34.546zm92.88 21.942c-1.825-3.264-4.448-12.619-7.728-26.636-2.932-14.117-7.628-17.999-17.571-18.235l-7.301-.053v43.621l-23.343-.928V241.534c7.488-1.625 18.808-3.088 31.596-3.66 16.129-.707 27.656 1.324 35.667 7.457 6.755 5.223 10.491 13.144 10.491 23.767 0 14.734-10.325 24.859-20.002 28.429v.506c7.836 3.243 12.21 10.916 15.077 21.526 3.565 13.073 7.146 28.255 9.374 32.812l-26.26-1.039zm107.449 4.277-11.625-22.476c-4.72-8.569-7.718-14.913-11.259-21.943l-.375-.005c-2.597 6.95-5.746 13.142-9.622 21.421L248.885 353.9l-30.833-1.234 34.646-58.994-33.426-57.509 31.065-1.34 10.784 21.437c3.7 7.222 6.474 13.065 9.456 19.815l.372-.014c2.998-7.765 5.435-13.207 8.641-20.318l10.861-22.646 34.125-1.477-37.199 61.259 39.214 64.111-34.62-1.381zm263.802 144.493c0 8.933-7.268 16.199-16.2 16.199h-178.2c-.802 0-1.571-.11-2.342-.231v-97.775c12.478 6.592 26.156 11.196 40.68 13.2 11.87-19.254 22.918-39.023 34.783-59.653-3.586.189-5.357.2-7.108.39-30.501 3.207-52.818-8.005-68.354-33.744v-61.62c12.604-23.316 35.807-35.411 63.197-34.565 33.813 1.031 67.689.221 101.524.108-22.006-35.931-61.636-59.967-106.782-59.967-20.914 0-40.61 5.208-57.939 14.333V71.046c.771-.114 1.54-.243 2.342-.243h178.2c8.933 0 16.2 7.27 16.2 16.2v413.099z" fill={color} />
    <path  d="M526.378 257.001c-14.955.983-30.017.253-45.035.253h-17.719c23.92 30.198 24.068 58.783 5.342 89.211-16.774 27.269-31.909 55.54-47.666 83.437 66.999-2.289 120.814-57.48 120.814-125.028a124.43 124.43 0 0 0-9.34-47.387c-1.776-.486-3.875-.655-6.396-.486z" fill={color} />
    <path  d="M464.205 304.504c-.211-25.64-21.979-47.081-47.598-46.87-25.629.222-47.071 21.998-46.865 47.604.211 25.629 21.99 47.07 47.604 46.859 25.649-.211 47.07-21.964 46.859-47.593z" fill={color} />
  </svg>
)

export default Crx
