import * as React from "react"

const Db = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="m233.935 300.375-9.028-.027v33.286c2.603.396 5.729.448 10.259.533 12.095.211 22.918-4.219 22.918-16.844.006-12.086-10.817-16.922-24.149-16.948zM255.237 266.345c0-9.057-6.906-13.729-19.024-13.508-5.756.098-9.049.515-11.306.902v27.514l8.688-.053c14.041-.086 21.642-5.985 21.642-14.855z" fill={color} />
    <path  d="M359.031 537.775c.781.053 1.551.116 2.342.116h178.2c20.846 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.954-37.8-37.8-37.8h-178.2c-.786 0-1.561.071-2.342.113V0L11.228 46.417v494.564L359.031 588.6v-50.825zm0-466.744c.771-.113 1.54-.232 2.342-.232h178.2c8.933 0 16.2 7.27 16.2 16.2v413.103c0 8.934-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.127-2.342-.242V71.031zM163.756 337.352c-11.583 9.793-28.97 14.086-49.853 13.553-12.306-.332-20.946-1.381-26.768-2.357v-109.02c8.585-1.706 19.894-2.83 31.957-3.148 20.348-.546 33.729 3.014 44.329 11.113 11.541 8.667 18.847 22.816 18.847 43.282.001 22.183-7.809 37.37-18.512 46.577zm110.458 6.793c-8.796 6.771-23.414 10.314-46.884 9.703-12.933-.344-22.486-1.461-28.252-2.469V236.698c6.792-1.566 20.662-2.969 33.827-3.317 16.313-.425 26.433.907 35.197 5.854 8.467 4.282 14.65 12.416 14.65 23.366 0 10.784-6.352 20.867-20.018 25.922v.351c13.84 3.729 24.202 14.159 24.202 29.811-.005 10.974-5.107 19.517-12.722 25.46z" fill={color} />
    <path  d="M121.608 255.883c-5.355.098-8.796.653-10.842 1.194v73.43c2.046.532 5.336.591 8.322.633 21.945.543 36.492-12.118 36.492-39.482.174-23.791-13.104-36.136-33.972-35.775zM380.969 244.999h127.687a5.216 5.216 0 0 0 5.21-5.207V202.16a5.214 5.214 0 0 0-5.21-5.21H380.969a5.211 5.211 0 0 0-5.21 5.21v37.632a5.205 5.205 0 0 0 5.21 5.207zM380.969 304.836h127.687a5.211 5.211 0 0 0 5.21-5.21v-37.624a5.214 5.214 0 0 0-5.21-5.213H380.969a5.21 5.21 0 0 0-5.21 5.213v37.624a5.2 5.2 0 0 0 5.21 5.21zM380.969 364.294h127.687a5.214 5.214 0 0 0 5.21-5.21v-37.637c0-2.863-2.336-5.199-5.21-5.199H380.969a5.209 5.209 0 0 0-5.21 5.199v37.637c0 2.874 2.32 5.21 5.21 5.21z" fill={color} />
  </svg>
)

export default Db
