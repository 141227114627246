import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Chart from "../../Components/common/Chart/Chart";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";
import GeneralInformationCard from "../../Components/PagesComponents/AnalysisReports/GeneralInformationCard/GeneralInformationCard";
import Classification from "../../Components/PagesComponents/AnalysisReports/Classification/Classification";
import Screenshots from "../../Components/PagesComponents/AnalysisReports/Screenshots/Screenshots";
import ClamCloudMl from "../../Components/PagesComponents/AnalysisReports/ClamCloudMlResults/ClamCloudMl";
import ListPopup from "../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";
import ClassificationTags from "../../Components/PagesComponents/AnalysisReports/Classification/ClassificationTags";
import Content from "./Content/Content";
import PdfIcon from "../../Components/Icon-SVG/PdfIcon";
import { Link } from "react-router-dom";
import {
  RequestGeneralAnalysis,
  RequestGetSignatures,
  RequestChatGPT,
  RequestNetwork,
  RequestHostsAndDomains,
  RequestScreenshots,
} from "../../store/actions/analysisReportActions";
import { RequestSearchGroupSubmissions } from "../../store/actions/dashboardActions";
import { loadFromLocalStorage } from "../../utils/localStorage";

import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AnalysisReports = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const theme = loadFromLocalStorage("theme");
  const general = useSelector((state) => state.analysisReports.general);
  const groupSubmissionsLoading = useSelector((state) => state.dashboard.groupSubmissionsLoading);

  const loadingSignatureList = useSelector((state) => state.analysisReports.loadingSignatureList);
  const loadingGeneral = useSelector((state) => state.analysisReports.loadingGeneral);

  const [fileNameHover, setFileNameHover] = useState(false);
  const [staticResultsActive, setStaticResultsActive] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  const clickedFileID = location.state?.clickedFileID;
  const linkDisabled = groupSubmissionsLoading || !clickedFileID;

  useEffect(() => {
    setShowPopup(false);
    // if (general._id !== query.get("data")) {
    dispatch(
      RequestGeneralAnalysis({
        parentID: query.get("data"),
        clickedFileID: clickedFileID || query.get("data"),
      }),
    );
    dispatch(RequestGetSignatures(query.get("data")));
    dispatch(RequestChatGPT({ id: query.get("data"), refresh: "False" }));
    dispatch(
      RequestNetwork({
        parentID: query.get("data"),
        clickedFileID: clickedFileID || query.get("data"),
      }),
    );
    dispatch(
      RequestHostsAndDomains({
        parentID: query.get("data"),
        clickedFileID: clickedFileID || query.get("data"),
      }),
    );
    dispatch(RequestScreenshots(query.get("data")));
    // }
    if (clickedFileID) {
      dispatch(RequestSearchGroupSubmissions(clickedFileID));
    }
  }, [query.get("data"), clickedFileID]);

  return (
    <div>
      {loadingGeneral && loadingSignatureList ? (
        <Backdrop absolute={true} show={loadingSignatureList} message="Loading report page..." />
      ) : (
        <div className="analysis-reports">
          <div className="analysis-reports__header">
            <div className="analysis-reports__header-doughnutChart">
              {!loadingGeneral && (
                <Chart
                  name={`doughnut-${theme}`}
                  type="doughnut"
                  fileState={query.get("state")}
                  decision_score={general.decision_score}
                />
              )}
            </div>
            <div className="analysis-reports__header-inner">
              <h1>
                <Link
                  to={{
                    pathname: "/report-export",
                    search: `?data=${query.get("data")}&state=${query.get("state")}`,
                    state: { clickedFileID },
                  }}>
                  <PdfIcon />
                </Link>
                <b>|</b>
                <b
                  style={{ cursor: "default" }}
                  onMouseOver={() => setFileNameHover(false)}
                  onMouseOut={() => setFileNameHover(false)}>
                  {general &&
                    (fileNameHover
                      ? general?.file_name
                      : general?.file_name?.length > 20
                      ? `${general?.file_name?.substring(
                          0,
                          10,
                        )}..........${general?.file_name?.substring(
                          general?.file_name?.length - 6,
                        )}`
                      : general?.file_name)}
                </b>
                <span
                  className={
                    query.get("state") === "malicious"
                      ? "analysis-reports__header-verdict analysis-reports__header-verdict--malicious"
                      : query.get("state") === "not suspicious"
                      ? "analysis-reports__header-verdict analysis-reports__header-verdict--not-suspicious"
                      : "analysis-reports__header-verdict analysis-reports__header-verdict--suspicious"
                  }>
                  {query.get("state")}
                </span>
              </h1>
              <p className="analysis-reports__header-explanation">
                You can generate pdf report by clicking the pdf icon.
              </p>
            </div>

            <div style={{ flex: 1, marginLeft: "20px" }}>
              <ClamCloudMl setStaticResultsActive={setStaticResultsActive} />
            </div>
          </div>
          <div className="analysis-reports__body">
            <div className="analysis-reports__body-block">
              <GeneralInformationCard
                key={general}
                fileName={general?.file_name}
                size={general?.size}
                sha1={general?.sha1}
                started={general?.analysis_started}
                reportDate={general?.report_date}
                fileID={query.get("data")}
                linkDisabled={linkDisabled}
                setShowPopup={setShowPopup}
              />
              <Screenshots />
              {theme && <Classification theme={theme} />}
              <ClassificationTags />
            </div>
            <Content
              staticResultsActive={staticResultsActive}
              setStaticResultsActive={setStaticResultsActive}
            />
          </div>
        </div>
      )}

      <ListPopup
        show={showPopup}
        setShow={setShowPopup}
        tableType={"analysisReportsPopup"}
        tableType2={"analysisReportsPopup"}
      />
    </div>
  );
};

export default AnalysisReports;
