import * as React from "react"

const Doc = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M181.285 256.98c-15.915.274-25.088 15.952-25.088 36.698 0 20.903 9.471 35.807 25.239 36.08 16.158.29 25.537-15.445 25.537-36.713-.006-19.633-9.224-36.344-25.688-36.065zM68.262 259.221c-4.736.076-7.781.577-9.574 1.062v66.564c1.793.484 4.704.543 7.338.585 19.388.485 32.234-10.99 32.234-35.78.145-21.549-11.572-32.75-29.998-32.431z" fill={color} />
    <path  d="M359.031 537.78c.781.042 1.551.111 2.342.111h178.2c20.846 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.954-37.8-37.8-37.8h-178.2c-.786 0-1.561.077-2.342.113V0L11.228 46.417v494.564L359.031 588.6v-50.82zm2.342-466.982h178.2c8.933 0 16.2 7.27 16.2 16.2v413.103c0 8.928-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.127-2.342-.242V383.991h160.145c3.955 0 7.161-3.201 7.161-7.15a7.152 7.152 0 0 0-7.161-7.151H359.031v-43.886h160.145c3.955 0 7.161-3.206 7.161-7.15 0-3.955-3.206-7.15-7.161-7.15H359.031V268.98h160.145c3.955 0 7.161-3.199 7.161-7.151s-3.206-7.148-7.161-7.148H359.031v-42.926h75.252a7.153 7.153 0 0 0 7.151-7.151 7.154 7.154 0 0 0-7.151-7.153h-75.252V71.036c.771-.114 1.54-.238 2.342-.238zM105.464 333.06c-10.223 8.87-25.574 12.762-44.01 12.276-10.887-.295-18.512-1.239-23.67-2.12V244.35c7.604-1.54 17.595-2.544 28.242-2.813 17.961-.472 29.787 2.764 39.15 10.102 10.18 7.868 16.611 20.692 16.611 39.227.001 20.088-6.884 33.841-16.323 42.194zm74.714 16.063c-30.649-.817-48.157-24.928-48.157-54.986 0-31.622 19.322-55.764 49.884-56.576 32.74-.862 51.057 24.113 51.057 54.662 0 36.302-21.679 57.728-52.784 56.9zm128.092-18.162c8.068.137 17.107-1.424 22.465-3.392l4.103 20.53c-5.01 2.277-16.189 4.556-30.586 4.166-39.92-1.055-59.927-25.534-59.927-57.244 0-37.966 27.433-59.827 62.543-60.75 13.9-.367 24.574 2.102 29.415 4.39l-5.548 20.909c-5.526-2.144-13.152-4.076-22.645-3.921-20.975.351-37.009 12.825-37.009 37.9 0 22.573 13.624 37.006 37.189 37.412z" fill={color} />
    <path  d="m477.394 226.081 6.629-26.935c1.693-6.544 2.806-12.062 3.829-18.183h.163c.675 6.199 1.692 11.639 3.138 18.183l5.959 26.935h11.222l15.367-57.268H513.08l-5.948 25.917c-1.529 6.963-2.89 13.505-3.907 19.796h-.18c-.839-6.204-2.035-12.577-3.476-19.377l-5.695-26.336h-10.958l-6.196 25.917c-1.624 7.219-3.254 14.017-4.171 20.049h-.158c-.938-5.693-2.3-13.004-3.75-19.965l-5.437-25.995h-11.127l14.022 57.267h11.295v-.005z" fill={color} />
  </svg>
)

export default Doc
