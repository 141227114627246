import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Backdrop from "../../Components/UI/Backdrop/Backdrop";
import Card from "../../Components/common/Card/Card";
import DashBoardTable from "../../Components/PagesComponents/Dashboard/DashBoardTable/DashBoardTable";
import DemoPopup from "../../Components/common/DemoPopup/DemoPopup";
import FileCard from "../../Components/PagesComponents/Dashboard/FileCard/FileCard";
import ListPopup from "../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";
import MalwareDistribution from "../../Components/PagesComponents/Dashboard/MalwareDistribution/MalwareDistribution";
import SystemHealth from "../../Components/PagesComponents/Dashboard/SystemHealth/SystemHealth";
import FreeRun from "../../Components/PagesComponents/Dashboard/FreeRun/FreeRun";

import ChartLineIcon from "../../Components/Icon-SVG/ChartLineIcon";
import MenIcon from "../../Components/Icon-SVG/MenIcon";

import {
  RequestDailySubmissions,
  RequestAllSubmissions,
  RequestIntegrationSubmissionCount,
  RequestDailyIntegrationSubmissionCount,
  RequestCleanSubmissionsState,
  RequestSuspiciousSubmissionsState,
  RequestMaliciousSubmissionsState,
  RequestIntegrationSubmissionState,
  RequestVms,
} from "../../store/actions/dashboardActions";
import { licenceCheckRequest } from "../../store/actions/usersActions";

import "./styles.scss";

const selectState = (activeTab, submissionsState, integrationsState) => {
  if (activeTab === "all" || activeTab === "all_daily") {
    return submissionsState;
  } else if (activeTab === "integrations" || activeTab === "integrations_daily") {
    return integrationsState;
  } else {
    return 0;
  }
};

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const allSubmissionsCount = useSelector((state) => state.dashboard.totalSubmission);
  const dailySubmissionsCount = useSelector((state) => state.dashboard.dailyCounter);
  const integrationSubmissionsCount = useSelector(
    (state) => state.dashboard.integrationSubmissionCount,
  );
  const dailyIntegrationSubmissionsCount = useSelector(
    (state) => state.dashboard.dailyIntegrationSubmissionCount,
  );
  const submissionsCleanState = useSelector((state) => state.dashboard.cleanState);
  const submissionsSuspiciousState = useSelector((state) => state.dashboard.suspiciousState);
  const submissionsMaliciousState = useSelector((state) => state.dashboard.maliciousState);
  const integrationsCleanState = useSelector((state) => state.dashboard.integrationCleanState);
  const integrationsSuspiciousState = useSelector(
    (state) => state.dashboard.integrationSuspiciousState,
  );
  const integrationsMaliciousState = useSelector(
    (state) => state.dashboard.integrationMaliciousState,
  );
  const submissionsLoading = useSelector((state) => state.dashboard.submissionsLoading);
  const currentFile = useSelector((state) => state.dashboard.currentFile);

  const [showDelete, setShowDelete] = useState(false);
  const [listPopupActive, setListPopupActive] = useState(false);
  const [allSubmissionsActive, setAllSubmissionsActive] = useState("all");
  const [freeRunActive, setFreeRunActive] = useState(false);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  useEffect(() => {
    dispatch(licenceCheckRequest());
    dispatch(RequestVms());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(RequestDailySubmissions());
      dispatch(RequestAllSubmissions());
      dispatch(RequestIntegrationSubmissionCount("all"));
      dispatch(RequestDailyIntegrationSubmissionCount("all"));
      dispatch(RequestCleanSubmissionsState(allSubmissionsActive));
      dispatch(RequestSuspiciousSubmissionsState(allSubmissionsActive));
      dispatch(RequestMaliciousSubmissionsState(allSubmissionsActive));
      dispatch(
        RequestIntegrationSubmissionState({
          timeFilter: allSubmissionsActive,
          integrationType: "all",
        }),
      );
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch, allSubmissionsActive]);

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  return (
    <div>
      <div className="dashboard">
        <div className="dashboard__header">
          <h1>Dashboard</h1>
        </div>
        <div className="dashboard__body">
          <div className="dashboard__body__item">
            <FileCard />
          </div>
          <div className="dashboard__body__item">
            <MalwareDistribution
              allSubmissionsCount={allSubmissionsCount}
              integrationSubmissionsCount={integrationSubmissionsCount}
              cleanSubmissionsState={selectState(
                allSubmissionsActive,
                submissionsCleanState,
                integrationsCleanState,
              )}
              suspiciousSubmissionsState={selectState(
                allSubmissionsActive,
                submissionsSuspiciousState,
                integrationsSuspiciousState,
              )}
              maliciousSubmissionsState={selectState(
                allSubmissionsActive,
                submissionsMaliciousState,
                integrationsMaliciousState,
              )}
              allSubmissionsActive={allSubmissionsActive}
              setAllSubmissionsActive={setAllSubmissionsActive}
            />
            <div className="dashboard__body__item-card">
              <Card
                onClick={() => setFreeRunActive(true)}
                title="Free Run"
                icon={<MenIcon width={18} height={17} color={"white"} />}
                iconBackground="#E6A355"
              />
              <Card
                onClick={() => dailySubmissionsCount !== 0 && setAllSubmissionsActive("all_daily")}
                title="Daily Submissions"
                style={{ cursor: "pointer" }}
                value={dailySubmissionsCount}
                selected={allSubmissionsActive === "all_daily" ? true : false}
                icon={<ChartLineIcon width={18} height={17} color={"white"} />}
              />
              <Card
                onClick={() =>
                  dailyIntegrationSubmissionsCount !== 0 &&
                  setAllSubmissionsActive("integrations_daily")
                }
                title="Daily Int. Submissions"
                value={dailyIntegrationSubmissionsCount}
                selected={allSubmissionsActive === "integrations_daily" ? true : false}
                icon={<ChartLineIcon width={18} height={17} color={"white"} />}
                iconBackground="#E6A355"
              />
            </div>
          </div>
          <DashBoardTable />
          <SystemHealth />
        </div>
      </div>
      <ListPopup
        onClickOk={() => setShowDelete(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="dashboard"
      />
      <DemoPopup
        onClickOk={() => setShowDelete(false)}
        show={showDelete}
        setShow={setShowDelete}
        text="This feature is not available in the demo version."
        article="DEMO"
      />
      <Backdrop
        absolute={false}
        show={submissionsLoading}
        message={`Uploading file "${currentFile}", please wait...`}
      />
      <FreeRun active={freeRunActive} setActive={setFreeRunActive} />
    </div>
  );
};
export default Dashboard;
