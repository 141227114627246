import React from "react";
import "./styles.scss";

const Card = ({
  hostName,
  userName,
  macAdress,
  cpuId,
  status,
  updateTime,
  icon,
  icon1,
  minHeight,
  customMargin,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        height: minHeight || "auto",
        margin: customMargin || "30px 0 0 0 0",
      }}
      className="agent__card border">
      <div className="agent__card-inner">
        <div className="agent__card-inner__icon">
          <div>{icon}</div>
          <div>{icon1}</div>
        </div>
        <div className="agent__card-inner__text">
          {hostName && (
            <p className="agent__card-inner__text-value">
              <b>Host Name: </b>
              {hostName}
            </p>
          )}
          {userName && (
            <p className="agent__card-inner__text-value">
              <b>User: </b>
              {userName}
            </p>
          )}
          {macAdress && (
            <p className="agent__card-inner__text-value">
              <b>Mac: </b>
              {macAdress}
            </p>
          )}
          {cpuId && (
            <p className="agent__card-inner__text-value">
              <b>CPU Id: </b>
              {cpuId}
            </p>
          )}
          {status && (
            <p className="agent__card-inner__text-value">
              <b>Status: </b>
              <span style={status === "Online" ? { color: "green" } : { color: "red" }}>
                {status}
              </span>
              <span style={{ marginLeft: "10px" }}>({updateTime})</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
