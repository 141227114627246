import * as React from "react"

const Asx = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.595 588.595"
    xmlSpace="preserve">
    <path  d="m75.354 259.674-.285.011c-1.411 6.328-2.813 14.365-4.364 20.509l-5.571 22.396 21.038-.043-6.001-22.546c-1.71-6.259-3.4-14.085-4.817-20.327z" fill={color} />
    <path  d="M11.225 46.412v494.564l347.804 47.619V537.78c.781.059 1.551.111 2.342.111h178.2c20.846 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.954-37.8-37.8-37.8h-178.2c-.786 0-1.561.077-2.342.124V0L11.225 46.412zm85.656 301.967-7.544-27.729-27.245-.332-6.597 26.668-21.229-.717 27.82-103.842 28.255-1.438L121.278 349.2l-24.397-.821zm64.238 3.855c-12.284-.422-24.192-4.15-30.074-7.783l4.796-20.604c6.389 3.56 16.319 7.229 26.731 7.431 11.393.221 17.484-4.599 17.484-12.087 0-7.151-5.276-11.243-18.457-16.137-17.808-6.429-29.16-16.535-29.16-32.64 0-18.889 14.969-34.127 40.542-35.456 12.566-.654 21.985 1.617 28.788 4.39l-5.782 21.455c-4.564-2.08-12.604-5.055-23.501-4.648-10.721.388-15.865 5.598-15.865 11.451 0 7.198 6.106 10.249 20.303 15.56 19.904 7.267 29.473 17.916 29.473 34.401-.005 19.596-14.818 35.726-45.278 34.667zm128.828 2.652-11.702-22.649c-4.751-8.643-7.766-15.029-11.341-22.133h-.377c-2.616 7.051-5.78 13.337-9.671 21.732l-10.278 21.59-31.011-1.045 34.85-59.863-33.637-57.932 31.256-1.582 10.85 21.61c3.72 7.27 6.528 13.16 9.519 19.963l.379-.011c3.014-7.879 5.469-13.402 8.693-20.606l10.919-22.998 34.377-1.74-37.459 62.229 39.48 64.61-34.847-1.175zm69.081-149.14h101.656v86.74H359.028v-86.74zm2.342-134.948h178.2c8.933 0 16.2 7.27 16.2 16.2v413.103c0 8.928-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.116-2.342-.232V411.507h75.653c-5.084-4.261-8.659-9.692-10.009-15.915h-65.645v-88.52h101.656v42.81a54.321 54.321 0 0 1 5.332-.644v-115.29c0-.599.042-1.203.121-1.803 1.049-7.755 7.789-16.762 20.118-18.215a13.65 13.65 0 0 1 10.146 3.059v-28.93H359.028V71.042c.771-.12 1.54-.244 2.342-.244z" fill={color} />
    <path  d="M484.009 228.883c-7.546.886-8.321 6.615-8.321 6.615v130.389c-5.663-1.957-12.439-2.315-19.374-.596-15.367 3.828-25.418 16.526-22.476 28.328 1.719 10.885 17.803 18.199 33.175 14.328 13.506-3.343 22.929-13.547 22.981-23.979h.043l.348-123.836s26.952-1.447 53.352 39.936c0 0 .058-21.359-20.239-36.993-39.595-27.685-39.489-34.192-39.489-34.192z" fill={color} />
  </svg>
)

export default Asx
