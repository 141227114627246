import * as React from "react"

const Odx = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.33c.776.042 1.542.109 2.329.109h177.39c20.75 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.077-2.329.113V0L11.176 46.206v492.311l346.22 47.401V535.33zm2.33-464.854h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.127-2.329-.243V367.764h45.979v-27.771H392.86l26.82-28.834c4.514 3.428 10.078 5.537 16.184 5.537 14.845 0 26.876-12.032 26.876-26.876 0-3.129-.639-6.087-1.621-8.877l38.809-18h41.351v-27.769h-61.817v27.769h7.69l-28.312 13.126c-4.703-7.832-13.186-13.126-22.976-13.126-2.514 0-4.903.457-7.208 1.103l-19.428-41.017h12.756V195.26h-61.817v27.769h43.114l20.352 42.977c-8.655 4.462-14.656 13.397-14.656 23.814 0 6.797 2.604 12.935 6.772 17.669l-30.238 32.504h-28.114V70.711c.765-.112 1.532-.235 2.329-.235zM60.072 331.438c-28.39-.799-44.633-24.301-44.633-53.589 0-30.821 17.921-54.313 46.208-55.011 30.289-.759 47.199 23.581 47.199 53.279 0 35.294-20.021 56.123-48.774 55.321zm140.797-11.203c-11.623 9.533-29.058 13.69-49.985 13.106-12.325-.34-20.987-1.395-26.825-2.355V224.491c8.623-1.653 19.938-2.74 32.021-3.032 20.389-.504 33.817 2.99 44.455 10.914 11.567 8.491 18.9 22.334 18.9 42.348-.004 21.687-7.836 36.526-18.566 45.514zm91.516 15.831L281.55 314.68c-4.42-8.188-7.236-14.257-10.575-21.007h-.344c-2.446 6.677-5.417 12.649-9.063 20.65l-9.69 20.641-29.575-.803 33.188-57.369-32.011-56.134 29.775-.719 10.223 20.846c3.472 6.992 6.103 12.643 8.903 19.157l.343-.005c2.821-7.43 5.1-12.638 8.105-19.404l10.134-21.502 31.431-.763-34.286 57.877 36.129 60.805-31.852-.884z" fill={color} />
    <path  d="M158.614 240.528c-5.375.083-8.822.627-10.864 1.144v71.738c2.048.525 5.344.588 8.336.646 21.998.582 36.591-11.774 36.591-38.51.16-23.265-13.141-35.346-34.063-35.018zM62.491 243.042c-14.729.226-23.218 15.475-23.218 35.688 0 20.366 8.775 34.88 23.368 35.164 14.942.283 23.614-15.028 23.614-35.712-.005-19.1-8.519-35.387-23.764-35.14z" fill={color} />
  </svg>
)

export default Odx
