const Type = (props) => {
  const type = props?.row?.original.typeTag;
  return (
    <div className="dashboard__table__link">
      <span style={{ color: "white" }}>{type}</span>
    </div>
  );
};

export default Type;
