import { isCompressedItself } from "../../../../../utils/function";

const ProgressLine = (props) => {
  const progress = props?.row?.original.progress || props.progress;
  const { compressedID, compressedIDfull, fileIDfull } = props.row.original.file;
  const compressedItself =
    compressedID &&
    compressedIDfull &&
    fileIDfull &&
    isCompressedItself(compressedID, compressedIDfull, fileIDfull);
  const isError = progress === "Error Reporter" || progress === "Error Analyser";
  return (
    <>
      <p>{isError && compressedItself ? "Error" : progress}</p>
      <div className="dashboard__table__progress">
        <div
          className={
            isError
              ? "dashboard__table__progress-line__error"
              : "dashboard__table__progress-line__success"
          }
          style={
            progress === "Completed"
              ? { width: "100%" }
              : progress === "Reporting"
              ? { width: "50%" }
              : progress === "Running"
              ? { width: "10%" }
              : progress === "In Queue"
              ? { width: "2%" }
              : { width: props.width }
          }
        />
      </div>
    </>
  );
};

export default ProgressLine;
