import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../../../Components/common/Table/CustomTable";
import DownloadIcon from "../../../Components/Icon-SVG/DownloadIcon";

import { urlsHeader } from "../../../utils/constants/tableHeaders";

import { RequestIocUrls } from "../../../store/actions/analysisReportActions";

import "./styles.scss";

const Urls = () => {
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);

  const iocUrls = useSelector((state) => state.analysisReports.iocUrls);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTimeFilter) {
      dispatch(RequestIocUrls(selectedTimeFilter));
    }
  }, [dispatch, selectedTimeFilter]);

  const downloadUrlsHandler = () => {
    const blob = new Blob([iocUrls.map((el) => el.url).join("\n")], { type: "plain/text" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "CFSBOX_IOC_URLs.txt";
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const isDisabled = iocUrls.length === 0;

  return (
    <div className="urls">
      <div className="urls__header">
        <h1>Urls</h1>
        <div>
          <p>Below list is displaying urls visited by malicious files:</p>
          <button onClick={downloadUrlsHandler} disabled={isDisabled}>
            Download URLs <DownloadIcon width={18} color={isDisabled ? "var(--card-background-color)" : undefined} />
          </button>
        </div>
      </div>
      <div className="urls__table border">
        <CustomTable
          exportFile={true}
          dataTable={iocUrls}
          tableName="urls"
          column={urlsHeader}
          paginationButtons={true}
          className=""
          customPageSize={10}
          minHeight="585px"
          minBottom="5px"
          defaultPosition="relative"
          filterByTime={true}
          selectedTimeFilter={selectedTimeFilter}
          setSelectedTimeFilter={setSelectedTimeFilter}
          defaultFilterIndex={2}
        />
      </div>
    </div>
  );
};

export default Urls;
