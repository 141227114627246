import React from "react";

const TabHeader = ({ active, setActive }) => {
  return (
    <div className="dropped-files__table-button-block">
      <button
        onClick={() => setActive(true)}
        className={`dropped-files__tab-button  ${
          active ? `dropped-files__tab-button__active` : ""
        } `}>
        Details
      </button>

    </div>
  );
};

export default TabHeader;
