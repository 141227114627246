import React, { useState } from "react";
import NewPassword from "./Tabs/NewPassword";
import PasswordReset from "./Tabs/PasswordReset";

const ResetPassword = () => {
  const [tabs, setTabs] = useState("new-password");

  return (
    <>
      {tabs === "new-password" ? (
        <NewPassword setTabs={setTabs} />
      ) : (
        tabs === "reset-password" && <PasswordReset setTabs={setTabs} />
      )}
    </>
  );
};

export default ResetPassword;
