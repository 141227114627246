import { useHistory } from "react-router-dom";

const StaticResultsRunning = (props) => {
  const history = useHistory();

  const progress = props?.row?.original.progress;
  const staticResultLink = props.row.original.staticResultLink;

  const staticResultsOnClickHandler = (event) => {
    event.stopPropagation();

    if (!progress || progress === "In Queue") {
      return;
    }

    history.push(staticResultLink);
  };

  return (
    <div className="dashboard__table__staticResultsRunning">
      <button
        className={`severity-status__check ${
          !progress || progress === "In Queue" ? "severity-status__check--disabled" : ""
        }`}
        onClick={staticResultsOnClickHandler}>
        Static
      </button>
    </div>
  );
};

export default StaticResultsRunning;
