import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  ClearReadyStatus,
  RequestGetVm,
  RequestReadyStatus,
} from "../../store/actions/analysisReportActions";
import { addNotification } from "../../store/actions/notifierActions";

import TableSpinnerIcon from "../../Components/Icon-SVG/TableSpinnerIcon";

import RemoteConnectionActions from "./RemoteConnectionActions";

import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

let navigatingToDashboard = false;

const RunningAnalysis = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getVm = useSelector((state) => state.analysisReports.getVm);
  const readyStatus = useSelector((state) => state.analysisReports.readyStatus);

  const getVmLoading = useSelector((state) => state.analysisReports.loading);

  const [canvasLoading, setCanvasLoading] = useState(true);
  const iframeRef = useRef(null);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  useEffect(() => {
    const spiceDisconnectHandler = (e) => {
      if (e.detail.code === 1011 || e.detail.code === 1000) {
        if (!navigatingToDashboard) {
          navigatingToDashboard = true;
          dispatch(
            addNotification({
              message: "Analysis has completed.",
              options: { variant: "success" },
            }),
          );
          dispatch(ClearReadyStatus());
          history.replace({ pathname: "/dashboard", state: { fromRemoteConnection: true } });
        }
      }
    };
    window.addEventListener("spice-disconnect", spiceDisconnectHandler);

    return () => window.removeEventListener("spice-disconnect", spiceDisconnectHandler);
  }, [dispatch, history]);

  useEffect(() => {
    if (query.get("data")) {
      dispatch(RequestGetVm(query.get("data")));
    }
    return () => dispatch(ClearReadyStatus());
  }, [dispatch, query.get("data")]);

  useEffect(() => {
    let intervalId;
    if (getVm && readyStatus === "False") {
      intervalId = setInterval(() => {
        dispatch(RequestReadyStatus(getVm));
      }, 4000);
    }
    return () => clearInterval(intervalId);
  }, [getVm, readyStatus]);

  useEffect(() => {
    const canvasCreatedHandler = () => {
      setCanvasLoading(false);

      const iframe = iframeRef.current;
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const eventLayerCanvas = iframeDocument.getElementById("eventLayer");
      const inputManager = iframeDocument.getElementById("inputmanager");

      const focusEventHandler = () => {
        iframe.focus();
        inputManager.focus();
      };

      eventLayerCanvas.addEventListener("click", focusEventHandler);

      return () => {
        eventLayerCanvas.removeEventListener("click", focusEventHandler);
      };
    };

    window.addEventListener("canvas-created", canvasCreatedHandler);

    return () => window.removeEventListener("canvas-created", canvasCreatedHandler);
  }, [iframeRef]);

  const refreshConnectionHandler = () => {
    setCanvasLoading(true);
    dispatch(RequestGetVm(query.get("data")));
  };

  const buttonDisabled = !getVm || readyStatus === "False" || canvasLoading;

  return (
    <div className="remote-connection">
      <div className="remote-connection__header">
        <h1>Remote Connection</h1>
        <p>Virtual machine monitoring area.</p>
        <RemoteConnectionActions
          buttonDisabled={buttonDisabled}
          getVm={getVm}
          fileID={query.get("data")}
        />
      </div>
      <div className="remote-connection__iframe-container">
        {getVm ? (
          <>
            <iframe
              src={`/remoter/index.html?host=${window.location.hostname}&port=${
                window.location.port ? window.location.port : "443"
              }&vmInfoToken=${getVm}&protocol=wss&token=123`}
              id="remote-connection-iframe"
              width="100%"
              height="770"
              ref={iframeRef}
              frameBorder="0"
              title="CfsBox"></iframe>
          </>
        ) : null}
      </div>
      <div className="remote-connection__refresh" onClick={refreshConnectionHandler}>
        <TableSpinnerIcon className="spinner" spin={getVmLoading} />
      </div>
    </div>
  );
};

export default RunningAnalysis;
