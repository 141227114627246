import * as React from "react";
const UsbIcon = ({ color = "var(--text-color-card)", width = 60, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="_x32_"
    viewBox="0 0 512 512"
    width={width}
    height={height}>
    <path
      d="M10.459 295.598c-14.015 14.021-13.934 36.907.183 51.024l154.733 154.735c6.862 6.862 15.982 10.643 25.679 10.643h.004c9.592 0 18.59-3.714 25.338-10.462L407.64 310.292 201.703 104.359 10.459 295.598zm90.018 1.384a12.31 12.31 0 0 1-8.764-3.629l-1.778-1.781a12.303 12.303 0 0 1-3.631-8.762c0-3.314 1.289-6.425 3.631-8.766l97.638-97.638a12.325 12.325 0 0 1 8.766-3.633c3.313 0 6.425 1.292 8.768 3.633l1.774 1.774c4.833 4.833 4.833 12.698 0 17.531l-97.638 97.638a12.306 12.306 0 0 1-8.766 3.633zM508.677 153.909 358.086 3.318A11.225 11.225 0 0 0 350.102 0a11.241 11.241 0 0 0-7.997 3.318L240.69 104.733l23.598 23.594 85.807-85.806 119.382 119.38-85.809 85.809 23.597 23.594 101.413-101.411a11.251 11.251 0 0 0 3.322-7.966 11.244 11.244 0 0 0-3.323-8.018z"
      fill={color}
    />
    <path
      d="m335.785 156.913-34.379-34.379 34.156-34.156 34.378 34.379zM423.562 176.051 389.407 141.9l-34.158 34.151 34.158 34.156z"
      fill={color}
    />
  </svg>
);
export default UsbIcon;
