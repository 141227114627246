import * as React from "react"

const Rar = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M359.031 537.78c.781.042 1.551.111 2.342.111h178.2c20.846 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.954-37.8-37.8-37.8h-178.2c-.786 0-1.561.077-2.342.113V0L11.228 46.417v494.564L359.031 588.6v-50.82zm2.342-466.982h178.2c8.933 0 16.2 7.27 16.2 16.2v413.103c0 8.928-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.127-2.342-.242V368.761h104.583v-20.414h81.802v-27.743H499.7V309.62h18.052v-8.137H499.7v-9.461h18.052v-8.14H499.7v-9.244h18.052v-8.132H499.7v-7.14h45.716v-27.744h-81.802v27.744h27.665v61.238h-27.665v26.515H359.031v-9.893h83.542v-94.458h-83.542v-11.14h104.583v-21.666H359.031V71.036c.771-.114 1.54-.238 2.342-.238zM89.119 360.75c-1.708-3.201-4.187-12.393-7.291-26.214-2.776-13.938-7.235-17.782-16.73-18.024l-6.982-.053v43.41l-22.594-.633V251.451c7.27-1.469 18.214-2.705 30.489-3.003 15.348-.356 26.211 1.88 33.739 8.058 6.296 5.242 9.795 13.057 9.795 23.461 0 14.428-9.637 24.3-18.71 27.781v.495c7.349 3.201 11.42 10.748 14.12 21.152 3.322 12.788 6.655 27.627 8.711 32.057l-24.547-.702zm106.32 3.001-8.883-30.291-32.326-.485-7.889 29.395-25.626-.733 33.515-114.415 33.519-.783 36.12 118.104-28.43-.792zm108.907 3.064c-2.066-3.527-5.062-13.632-8.796-28.814-3.354-15.318-8.754-19.532-20.166-19.807l-8.417-.074v47.636l-27.179-.771V246.833c8.754-1.626 21.919-3.003 36.682-3.354 18.513-.438 31.641 1.993 40.722 8.77 7.636 5.74 11.833 14.341 11.833 25.774 0 15.87-11.633 26.729-22.617 30.541v.554c8.886 3.522 13.832 11.817 17.075 23.267 3.992 14.069 8.031 30.396 10.536 35.269l-29.673-.839z" fill={color} />
    <path  d="M170.032 266.506h-.33c-1.69 6.916-3.354 15.712-5.189 22.431l-6.642 24.542 24.954.158-7.08-24.753c-2.019-6.897-4.034-15.499-5.713-22.378zM279.603 263.828c-6.805.103-10.624.696-12.635 1.083v33.439l10.977-.006c14.051-.01 22.468-6.939 22.468-17.697-.001-11.296-7.851-16.83-20.81-16.819zM68.613 266.997c-5.632.087-8.828.628-10.492.979v30.47h9.121c11.654-.011 18.626-6.317 18.626-16.1 0-10.3-6.508-15.344-17.255-15.349z" fill={color} />
  </svg>
)

export default Rar
