export const time = [
  {
    id: 1,
    value: "AM",
  },
  {
    id: 2,
    value: "PM",
  },
];

export const mouths = [
  {
    id: 1,
    value: "January",
  },
  {
    id: 2,
    value: "February",
  },
  {
    id: 3,
    value: "March",
  },
  {
    id: 4,
    value: "April",
  },
  {
    id: 5,
    value: "May",
  },
  {
    id: 6,
    value: "June",
  },
  {
    id: 7,
    value: "July",
  },
  {
    id: 8,
    value: "August",
  },
  {
    id: 9,
    value: "September",
  },
  {
    id: 10,
    value: "October",
  },
  {
    id: 11,
    value: "November",
  },
  {
    id: 12,
    value: "December",
  },
];

export const roleConstants = [{ id: 1, value: "user" }];

export const supRoleConstants = [
  { id: 1, value: "user" },
  { id: 2, value: "admin" },
];

export const operatingSystem = [
  {
    id: "win7",
    text: "win7",
  },
  {
    id: "x86",
    text: "x86",
  },
  {
    id: "x64",
    text: "x64",
  },
  {
    id: "win10",
    text: "win10",
  },
];

export const operatingSystemSelect = [
  {
    id: "win7",
    value: "win7",
  },
  {
    id: "x86",
    value: "x86",
  },
  {
    id: "x64",
    value: "x64",
  },
  {
    id: "win10",
    value: "win10",
  },
];

export const run_mode = [
  {
    id: "cli",
    value: "cli",
  },
  {
    id: "socket",
    value: "socket",
  },
];

export const machinesVariant = [
  {
    id: "win7",
    value: "win7",
  },
  {
    id: "mlchecker",
    value: "mlchecker",
  },
];
