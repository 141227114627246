import React, { useState } from "react";
import EyeIcon from "../../Icon-SVG/EyeIcon";
import EyeCloseIcon from "../../Icon-SVG/EyeCloseIcon";
import "./styles.scss";

const TextField = ({ placeholder, labelText, value, required, onChange, name, error, type }) => {
  const [hidden, setHidden] = useState(true);

  return (
    <div className="label" style={{ color: error ? "#FF4C4C" : "var(--text-color-label)" }}>
      {labelText}
      <input
        style={{ border: error ? "1px solid #FF4C4C" : null }}
        className="field"
        placeholder={placeholder}
        value={value}
        required={required}
        onChange={onChange}
        name={name}
        type={type === "password" && hidden ? "password" : "text"}
        autoComplete={type ? "current-password" : ""}
      />
      {type === "password" && hidden ? (
        <div className="password__eye" onClick={() => setHidden(!hidden)}>
          <EyeIcon />
        </div>
      ) : (
        type === "password" &&
        !hidden && (
          <div className="password__eye" onClick={() => setHidden(!hidden)}>
            <EyeCloseIcon />
          </div>
        )
      )}
    </div>
  );
};

export default TextField;
