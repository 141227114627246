import React from "react";

const MaliciousSubmissionsIcon = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000"
      fill="none">
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path
          d="M2305 4789 c-705 -96 -1250 -697 -1334 -1471 -24 -213 -8 -339 58
                        -473 48 -97 111 -170 191 -224 82 -54 155 -80 277 -96 117 -15 161 -37 207
                        -103 42 -62 49 -91 57 -262 9 -184 28 -276 83 -396 105 -225 282 -382 516
                        -456 63 -19 96 -23 205 -22 119 0 138 3 221 32 219 75 390 230 489 446 54 115
                        76 221 85 401 8 166 14 195 56 257 44 64 94 89 209 104 120 15 193 41 275 95
                        160 107 259 307 260 522 1 634 -361 1244 -895 1506 -232 114 -356 141 -665
                        146 -124 2 -256 -1 -295 -6z m598 -342 c254 -67 483 -224 650 -445 169 -225
                        268 -502 284 -796 7 -133 -8 -198 -61 -268 -47 -61 -101 -86 -211 -97 -157
                        -16 -283 -81 -377 -195 -101 -121 -147 -260 -148 -446 0 -58 -7 -136 -15 -175
                        -58 -274 -292 -459 -529 -418 -94 16 -163 52 -239 123 -119 111 -176 263 -177
                        470 -1 186 -47 325 -148 446 -94 114 -220 179 -377 195 -109 11 -167 38 -212
                        98 -51 67 -67 136 -60 266 28 521 310 967 741 1171 180 85 289 104 571 100
                        188 -3 220 -6 308 -29z"
          fill={color}
        />
        <path
          d="M1961 3984 c-253 -68 -410 -331 -346 -579 67 -256 329 -415 578 -350
                        85 22 154 61 219 123 63 59 124 166 134 234 4 21 10 38 14 38 4 0 10 -17 14
                        -38 10 -68 71 -175 134 -234 196 -187 491 -182 675 11 182 190 182 472 0 662
                        -184 193 -479 198 -675 11 -63 -59 -124 -166 -134 -234 -8 -49 -20 -49 -28 0
                        -3 21 -21 70 -41 109 -98 197 -334 304 -544 247z m182 -318 c43 -17 85 -68 93
                        -111 10 -56 -4 -102 -44 -144 -64 -67 -157 -68 -223 -2 -124 123 13 325 174
                        257z m960 0 c43 -17 85 -68 93 -111 10 -56 -4 -102 -44 -144 -64 -67 -157 -68
                        -223 -2 -124 123 13 325 174 257z"
          fill={color}
        />
        <path
          d="M2484 2856 c-42 -19 -59 -45 -158 -245 -58 -116 -86 -184 -86 -207 0
                        -52 23 -98 66 -130 l37 -29 218 0 c204 0 219 1 245 21 45 33 67 70 71 121 5
                        45 -1 60 -93 243 -66 129 -108 201 -126 214 -35 25 -131 32 -174 12z"
          fill={color}
        />
        <path
          d="M1055 1906 c-16 -8 -186 -171 -377 -362 -375 -377 -370 -371 -353
                        -461 5 -33 52 -84 363 -395 386 -386 379 -380 469 -363 49 9 109 69 118 118
                        17 88 16 90 -288 395 l-282 282 282 283 c243 243 282 287 288 320 25 132 -101
                        237 -220 183z"
          fill={color}
        />
        <path
          d="M3935 1906 c-41 -18 -83 -69 -91 -111 -16 -86 -14 -89 289 -392 l282
                        -283 -282 -282 c-304 -305 -305 -307 -288 -395 9 -49 69 -109 118 -118 90 -17
                        83 -23 469 363 311 311 358 362 363 394 17 91 22 85 -353 462 -194 194 -361
                        354 -379 362 -40 17 -89 17 -128 0z"
          fill={color}
        />
        <path
          d="M1535 626 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
                        353 -10 1034 -8 989 3 997 3 1024 24 53 39 69 71 69 134 0 63 -16 95 -69 134
                        -27 21 -35 21 -1034 23 -822 2 -1013 0 -1037 -11z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default MaliciousSubmissionsIcon;
