import * as React from "react"

const Class = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.33c.776.047 1.542.109 2.329.109h177.39c20.75 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.077-2.329.113V0L11.176 46.206v492.311l346.22 47.401V535.33zm0-464.619c.766-.113 1.532-.236 2.329-.236h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.893-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.127-2.329-.243V70.711zM63.135 312.229c4.375.079 9.251-.904 12.139-2.127l2.212 12.756c-2.698 1.423-8.735 2.852-16.522 2.641-21.66-.582-32.559-15.836-32.559-35.653 0-23.728 14.926-37.31 33.973-37.793 7.515-.2 13.282 1.365 15.883 2.795l-2.993 12.987c-2.984-1.343-7.097-2.56-12.231-2.473-11.368.182-20.061 7.938-20.061 23.575.001 14.081 7.392 23.066 20.159 23.292zm66.623 14.048-42.518-1.129V252.59l15.158-.378v59.413l27.36.452v14.2zm53.733 1.416-5.795-19.968-21.125-.288-5.168 19.401-16.732-.441 21.9-75.539 21.898-.549 23.593 77.867-18.571-.483zm48.388 2.478c-9.11-.252-18.018-2.839-22.426-5.411l3.591-14.678c4.79 2.541 12.202 5.134 19.916 5.266 8.402.151 12.874-3.238 12.874-8.505 0-5.033-3.876-7.941-13.585-11.447-13.238-4.657-21.743-11.936-21.743-23.4 0-13.462 11.203-24.056 30.129-24.533 9.221-.242 16.074 1.51 21.008 3.57l-4.199 14.937c-3.315-1.527-9.176-3.714-17.163-3.59-7.919.128-11.73 3.745-11.73 7.89 0 5.098 4.538 7.312 14.982 11.191 14.531 5.257 21.459 12.759 21.459 24.282.006 13.699-10.734 25.043-33.113 24.428zm67.154 1.833c-9.922-.268-19.597-2.986-24.387-5.669l3.908-15.309c5.197 2.646 13.25 5.365 21.654 5.514 9.145.168 14.016-3.371 14.016-8.872 0-5.25-4.22-8.268-14.805-11.947-14.392-4.852-23.64-12.437-23.64-24.392 0-14.035 12.192-25.086 32.791-25.62 10.021-.257 17.501 1.559 22.887 3.698l-4.583 15.599c-3.621-1.585-10.01-3.868-18.703-3.724-8.614.134-12.756 3.908-12.756 8.229 0 5.313 4.939 7.635 16.305 11.672 15.816 5.488 23.386 13.326 23.386 25.353-.009 14.307-11.716 26.136-36.073 25.468z" fill={color} />
    <path  d="m166.897 263.578-.21.005c-1.092 4.556-2.184 10.362-3.381 14.803l-4.349 16.2 16.302.074-4.627-16.324c-1.323-4.551-2.633-10.233-3.735-14.758zM437.28 270.699s-11.674-22.502-11.243-38.938c.315-11.753 26.809-23.362 37.218-40.231 10.374-16.871-1.302-33.299-1.302-33.299s2.604 12.115-4.313 24.651c-6.931 12.546-32.475 19.911-42.421 41.536-9.917 21.619 22.061 46.281 22.061 46.281z" fill={color} />
    <path  d="M480.999 202.352s-39.803 15.137-39.803 32.428c0 17.315 10.815 22.937 12.548 28.562 1.732 5.627-3.024 15.147-3.024 15.147s15.565-10.816 12.966-23.373c-2.603-12.547-14.72-16.444-7.78-28.979 4.629-8.402 25.093-23.785 25.093-23.785z" fill={color} />
    <path  d="M432.977 293.557c41.061-1.459 45.901-9.199 50.186-12.908-23.791 6.478-86.952 6.058-87.404 1.297-.419-4.758 19.466-8.651 19.466-8.651s-31.14 0-33.733 7.785c-2.606 7.782 14.738 13.768 51.485 12.477zM486.186 311.788s38.141-7.675 34.583-27.226c-4.316-23.798-31.561-10.396-31.561-10.396s17.742 0 19.466 10.811c1.732 10.816-22.488 26.811-22.488 26.811zM445.933 305.306c-18.017 2.025-39.79.426-41.534-2.604-1.699-3.028 3.045-4.756 3.045-4.756-21.647 5.207-9.815 14.269 15.549 16.016 21.733 1.476 46.038-6.498 46.038-6.498l7.474-6.048c-.001.005-17.134 2.378-30.572 3.89zM412.641 320.313s-9.816.277-10.389 5.475c-.562 5.167 3.045 7.79 27.317 7.214 28.894-.693 33.706-4.127 36.685-6.631l7.059-5.308c-18.893 2.446-20.357 3.119-31.044 3.429-10.772.315-29.628.997-30.488-1.014-.873-2.02.86-3.165.86-3.165zM428.505 357.229c51.905-.006 74.698-9.528 78.436-13.551 3.749-4.041-1.164-7.217-1.164-7.217s1.731 2.016-.546 4.32c-2.321 2.305-23.382 8.072-57.124 9.789-33.744 1.744-70.374-3.158-71.529-7.49-1.124-4.326 18.752-7.774 18.752-7.774-2.306.288-29.986.861-30.867 8.372-.853 7.492 12.114 13.551 64.042 13.551z" fill={color} />
    <path  d="M486.751 360.688c-22.777 4.603-91.975 1.695-91.975 1.695s44.973 10.671 96.316 1.737c24.531-4.273 25.964-16.157 25.964-16.157s-7.511 8.094-30.305 12.725z" fill={color} />
  </svg>
)

export default Class
