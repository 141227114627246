import React from "react";
import DragAndDrop from "../../../../../UI/DragAndDrop/DragAndDrop";
import Compare from "../../FileCardButtons/Compare";

const Comparison = () => {
  return (
    <div>
      <DragAndDrop />
      <div className="flex m-t-20 m-b-20">
        <Compare />
      </div>
    </div>
  );
};

export default Comparison;
