import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import TableSpinnerIcon from "../../../../../Icon-SVG/TableSpinnerIcon";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import { RequestChatGPT } from "../../../../../../store/actions/analysisReportActions";

import "./styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ChatGPT = () => {
  const dispatch = useDispatch();
  const chatGPT = useSelector((state) => state.analysisReports.chatGPT);
  const chatGPTError = useSelector((state) => state.analysisReports.chatGPTError);
  const chatGPTLoading = useSelector((state) => state.analysisReports.chatGPTLoading);

  const [refreshButton, setRefreshButton] = useState(false);

  let query = useQuery();

  const refreshP = async () => {
    setRefreshButton(true);
    dispatch(RequestChatGPT({ id: query.get("data"), refresh: "True" }));
  };

  useEffect(() => {
    if (!chatGPTLoading) {
      setRefreshButton(false);
    }
  }, [chatGPTLoading]);

  return (
    <div className="chatgpt-container">
      <div className="chatgpt-container__button-block">
        <button
          style={{ backgroundColor: "transparent", border: "none" }}
          onClick={() => refreshP()}>
          <TableSpinnerIcon className="spinner" spin={chatGPTLoading && refreshButton} />
        </button>
      </div>
      <div className="chatgpt-container__content">
        {chatGPTLoading ? (
          <Backdrop show={chatGPTLoading} absolute={true} />
        ) : !chatGPT || !chatGPT.message || chatGPTError ? (
          <p className="chatgpt-container__content__no-data">
            Retrieving ChatGPT result failed. Please try again a few minutes later. If the problem
            continues, contact CyberFortress Support Team.
          </p>
        ) : (
          chatGPT.message.split("\n\n").map((text, index) =>
            text.split('":').length > 1 ? (
              <p key={index}>
                <span style={{ color: "var(--text-color-card)", fontWeight: "bold" }}>
                  {text.split('":')[0]}":
                </span>
                {text.split('":')[1]}
              </p>
            ) : (
              <p key={index}>{text}</p>
            ),
          )
        )}
      </div>
    </div>
  );
};

export default ChatGPT;
