import * as React from "react"

const Odp = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="M359.036 537.78c.771.048 1.551.111 2.342.111h178.2c20.84 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.077-2.342.113V0L11.222 46.417v494.564L359.036 588.6v-50.82zm2.342-466.982h178.2c8.933 0 16.199 7.27 16.199 16.2v413.103c0 8.934-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.127-2.342-.232v-136.65h4.693v-16.2h-4.693v-31.556h4.693v-16.2h-4.693v-33.244h4.693v-16.2h-4.693v-31.902h73.544v-16.2h-73.544v-33.172h121.469v-16.2H359.036V71.042c.771-.12 1.54-.244 2.342-.244zM72.615 336.361c-29.963-.802-47.105-25.502-47.105-56.333 0-32.432 18.935-57.186 48.769-57.963 31.947-.844 49.805 24.743 49.805 56.001-.001 37.148-21.126 59.097-51.469 58.295zm148.576-11.991c-12.258 10.045-30.652 14.459-52.737 13.863-13.015-.343-22.161-1.439-28.308-2.431V223.705c9.083-1.748 21.033-2.897 33.795-3.23 21.513-.559 35.677 3.09 46.915 11.412 12.203 8.931 19.941 23.49 19.941 44.54.001 22.841-8.273 38.471-19.606 47.943zm74.395-27.149c-3.704-.031-7.003-.231-9.584-.622v43.485l-27.38-.707V220.762c8.445-1.638 20.43-3.02 37.526-3.452 17.516-.454 30.161 2.513 38.735 8.933 8.259 6.106 13.849 16.366 13.849 28.593 0 12.237-4.251 22.615-11.929 29.603-9.913 8.985-24.448 12.909-41.217 12.782z" fill={color} />
    <path  d="M176.597 240.542c-5.674.098-9.313.675-11.454 1.216v75.513c2.141.538 5.635.602 8.799.665 23.203.569 38.609-12.445 38.609-40.587.177-24.49-13.866-37.188-35.954-36.807zM297.622 238.122c-5.74.098-9.595.699-11.62 1.276v35.91c2.392.529 5.334.717 9.405.717 15.071-.021 24.479-7.538 24.479-20.174.001-11.335-8.089-17.972-22.264-17.729zM73.678 241.954c-15.546.264-24.498 16.321-24.498 37.6 0 21.444 9.25 36.695 24.646 36.969 15.768.274 24.909-15.857 24.909-37.612.004-20.107-8.982-37.235-25.057-36.957zM462.037 245.284c-40.953 0-74.145 33.199-74.145 74.139 0 40.954 33.191 74.145 74.145 74.145 40.943 0 74.134-33.19 74.134-74.145h-74.134v-74.139z" fill={color} />
    <path  d="M472.088 307.737v.031h74.155c0-20.861-8.659-39.68-22.518-53.148l-51.637 53.117zM471.297 294.701l.011.011 52.418-52.421c-14.733-14.752-33.138-21.945-52.471-21.674l.042 74.084z" fill={color} />
  </svg>
)

export default Odp
