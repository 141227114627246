import * as React from "react";

const StealthIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <g id="Layer_2">
      <path
        className="ste"
        d="M839.3 536.8s-11.2 73-91.8 126c-87.3 57.4-187.8-31.9-232.1-31.9-44.4 0-149.4 91.2-235.4 31.9s-95.1-126-95.1-126-4.7 39.5 16.8 84.3 58.5 48 58.5 48c0 44.8-.7 103.3 66.5 173.8 67.2 70.4 155 94.1 185.5 94.1s118.3-23.7 185.5-94.1c67.2-70.4 66.5-129 66.5-173.8 0 0 37-3.3 58.5-48 21.3-44.8 16.6-84.3 16.6-84.3z"
        fill={color}
      />
      <path
        d="M728.3 427.5H303.1c-95.4 0-108.4 23-108.4 59.2s66.9 167.8 151.2 165.9c84.3-2 129.7-50.7 169.8-50.7 40.2 0 85.6 48.7 169.8 50.7 84.3 2 151.2-129.7 151.2-165.9 0-36.1-13-59.2-108.4-59.2zM377.1 596.7c-45.4 0-82.3-26.8-82.3-59.9s36.8-59.9 82.3-59.9c45.4 0 82.3 26.8 82.3 59.9s-36.8 59.9-82.3 59.9zm277.1 0c-45.4 0-82.3-26.8-82.3-59.9s36.8-59.9 82.3-59.9c45.4 0 82.3 26.8 82.3 59.9s-36.8 59.9-82.3 59.9z"
        style={{
          fill: "var(--header-search-text-color)",
        }}
      />
      <path
        className="ste"
        d="M821.9 283.2v90.2c-2.7-1.2-5.3-2.3-8-3.4-170.4-70.9-298.2-67.4-298.2-67.4S387.9 299 217.5 370c-2.7 1.1-5.3 2.2-8 3.4v-90.2s6.5-3.2 18.5-8.3c41.8-17.5 150.8-56.9 287.7-56.9 136.9 0 245.9 39.3 287.7 56.9 12 5 18.5 8.3 18.5 8.3z"
        fill={color}
      />
      <path
        className="ste"
        d="M815.1 347c0 7.8-.4 15.4-1.2 23-170.4-70.9-298.2-67.4-298.2-67.4S387.9 299 217.5 370c-.8-7.5-1.2-15.2-1.2-23 0-25 4.1-49.2 11.7-72.1C263.9 166.4 379.2 87 515.7 87s251.7 79.4 287.7 187.9c7.6 22.9 11.7 47.1 11.7 72.1z"
        fill={color}
      />
      <path
        className="ste"
        d="M261.9 393.9h517s-87.1-55.7-258.5-55.7-258.5 55.7-258.5 55.7z"
        fill={color}
      />
    </g>
  </svg>
);

export default StealthIcon;
