import * as React from "react"

const Wma = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="m286.135 249.36-.308.005c-1.43 5.574-2.845 12.659-4.42 18.079l-5.69 19.78 21.368.076-6.081-19.926c-1.72-5.549-3.442-12.476-4.869-18.014z" fill={color} />
    <path  d="M357.4 535.335c.767.042 1.543.115 2.33.115h177.39c20.745 0 37.627-16.883 37.627-37.628V86.604c0-20.743-16.882-37.628-37.627-37.628H359.73c-.782 0-1.563.069-2.33.113V0L11.17 46.207v492.31l346.23 47.401v-50.583zm0-464.626c.767-.113 1.532-.23 2.33-.23h177.39c8.892 0 16.125 7.233 16.125 16.125v411.218c0 8.893-7.233 16.127-16.125 16.127H359.73c-.792 0-1.563-.127-2.33-.243V70.709zM111.497 322.718l-19.108-.478-6.499-35.982c-1.459-8.405-2.722-16.166-3.624-25.649l-.221.005c-1.354 9.376-2.59 17.257-4.386 25.62l-7.16 35.471-18.384-.462-17.218-81.075 17.546-.462 5.586 33.607c1.637 9.714 3.181 20.291 4.386 28.581l.221.005c1.221-8.877 2.981-18.749 4.858-28.882l6.354-33.87 18.307-.49 6.205 35.189c1.719 9.833 3.005 18.818 4.155 28.184h.231c1.154-9.351 2.897-19.214 4.538-29.229l6.422-34.714 18.394-.488-20.603 85.119zm103.696 2.597-1.333-34.42c-.397-10.8-.785-23.855-.785-36.908l-.386.005c-2.775 11.483-6.447 24.26-9.848 34.728l-10.628 34.58-15.202-.372-9.063-34.092c-2.761-10.245-5.628-22.662-7.612-34.015h-.252c-.494 11.767-.871 25.201-1.488 36.043l-1.478 33.009-17.263-.436 5.221-86.167 25.124-.661 8.338 29.163c2.667 10.181 5.344 21.168 7.258 31.543h.386c2.425-10.225 5.386-21.929 8.231-31.824l9.352-29.769 26.383-.698 4.987 90.807-19.942-.516zm92.684 2.327-7.601-24.388-27.656-.332-6.762 23.665-21.869-.553 28.631-92.144 28.685-.758 30.95 95.112-24.378-.602z" fill={color} />
    <path  d="M362.177 253.714h45.755v13.455h-45.755zM362.177 275.242h45.755v13.454h-45.755zM362.177 295.688h45.755v13.46h-45.755zM362.177 317.223h45.755v13.447h-45.755zM362.177 338.071h45.755v13.449h-45.755zM362.177 359.595h45.755v13.459h-45.755zM432.812 211.328h45.744v13.462h-45.744zM432.812 232.858h45.744v13.455h-45.744zM432.812 253.714h45.744v13.455h-45.744zM432.812 275.242h45.744v13.454h-45.744zM432.812 295.688h45.744v13.46h-45.744zM432.812 317.223h45.744v13.447h-45.744zM432.812 338.071h45.744v13.449h-45.744zM432.812 359.595h45.744v13.459h-45.744zM500.93 317.223h45.742v13.447H500.93zM500.93 338.071h45.742v13.449H500.93zM500.93 359.595h45.742v13.459H500.93z" fill={color} />
  </svg>
)

export default Wma
