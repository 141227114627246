import React from "react";
import WarningIcon from "../../Icon-SVG/WarningIcon";
import "./styles.scss";

const Warning = ({
  okDisable = false,
  onClickOk,
  text,
  article = "Warning",
  textOK = "Yes",
  textCancel = "No",
  icon,
  show,
  setShow,
  cancelDisable,
}) => {
  return (
    <div className={`${show && "warning-backdrop"}`} onClick={() => setShow(false)}>
      <div onClick={(e) => e.stopPropagation()}>
        {show ? (
          <div className="warning">
            <div className="warning__icon">{!icon ? <WarningIcon /> : icon}</div>
            <h3 className="article">{article}</h3>
            <p className="warning__description">{text}</p>
            <div className="flex">
              {okDisable ? null : (
                <button className="primary-button" onClick={onClickOk}>
                  {textOK}
                </button>
              )}
              {cancelDisable ? null : (
                <button className="danger-button" onClick={() => setShow(false)}>
                  {textCancel}
                </button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Warning;
