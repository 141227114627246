import * as React from "react";

const TrojanIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    xmlSpace="preserve">
    <g id="Layer_2">
      <path
        className="st2"
        d="M260 769.5s18.6-59.3 18.6-109.3l-28.5-39.5 19.8-48.3s-33.7-88.4-56.4-144.8 78.5-153.5 107-157 70.9 23.3 113.4 23.3 87.2-7 104.7-7c17.4 0 43.6-29.7 45.9-33.7 2.3-4.1-3.5-66.9 47.7-121 51.2-54.1 149.4-68 234.9 5.2 0 0 22.1-11 23.8-9.3 1.7 1.7-9.3 43-14 52.9-4.7 9.9-10.5 64-10.5 69.2s-14 14.6-15.1 22.7c-1.2 8.1-9.3 73.3-16.9 79.1s-47.1-3.5-52.3-7.6-27.3-77.9-27.3-77.9-20.4 33.4-13.4 61.8c7 28.3 28.5 49.3 28.5 59.7s-79.1 125.6-81.4 133.2-11 74.4-12.8 75.6C674 598 692 624.1 692 627.6s-12.2 23.3-14 36.1c-1.7 12.8 1.7 58.2 6.4 59.9 4.7 1.7 24.4 29.7 29.1 45.9h-95.9s5.2-26.2 20.4-42.4c0 0 5.2-50.6 2.3-60.5-2.9-9.9-27.3-30.8-27.9-37.2-.6-6.4 9.9-30.2 9.9-34.3s-25-82-28.5-82.6-41.9 14-108.7 14-129.1-20.9-134.9-20.9-20.9 66.9-20.9 68.6 14.5 39 12.2 45.4c-2.3 6.4-21.5 34.3-21.5 39 0 4.7 16.9 55.2 16.9 60.5s26.7 36.1 28.5 50.6H260z"
        fill={color}
      />
      <path
        className="st2"
        d="M226.2 587.5s-9-116.7-8-147.9 43.5-112 63.2-130.1c19.8-18 16.4-27.9 16.4-27.9s-35.8-39.6-87.7-4.7-70 103.5-65.9 162.2C148.3 498 160 587.5 160 587.5h66.2zM227.5 821.1c-32 0-57.9 25.9-57.9 57.9s25.9 57.9 57.9 57.9 57.9-25.9 57.9-57.9-25.9-57.9-57.9-57.9zm0 81.7-23.7-23.7 23.7-23.7 23.7 23.7-23.7 23.7zM397.4 821.1c-32 0-57.9 25.9-57.9 57.9s25.9 57.9 57.9 57.9 57.9-25.9 57.9-57.9-25.9-57.9-57.9-57.9zm0 81.7-23.7-23.7 23.7-23.7 23.7 23.7-23.7 23.7zM567.4 821.1c-32 0-57.9 25.9-57.9 57.9s25.9 57.9 57.9 57.9 57.9-25.9 57.9-57.9-25.9-57.9-57.9-57.9zm0 81.7-23.7-23.7 23.7-23.7 23.7 23.7-23.7 23.7z"
        fill={color}
      />
      <path
        d="M132.9 781.7V879h20.9c0-40.6 33.1-73.7 73.7-73.7s73.7 33.1 73.7 73.7h22.5c0-40.6 33.1-73.7 73.7-73.7s73.7 33.1 73.7 73.7h22.5c0-40.6 33.1-73.7 73.7-73.7S641 838.4 641 879h22.5c0-40.6 33.1-73.7 73.7-73.7s73.7 33.1 73.7 73.7h20.9v-97.3H132.9z"
        style={{
          fill: "var(--header-search-text-color)",
        }}
      />
      <path
        className="st2"
        d="M737.3 821.1c-32 0-57.9 25.9-57.9 57.9s25.9 57.9 57.9 57.9 57.9-25.9 57.9-57.9-25.9-57.9-57.9-57.9zm0 81.7-23.7-23.7 23.7-23.7 23.7 23.7-23.7 23.7z"
        fill={color}
      />
    </g>
  </svg>
);

export default TrojanIcon;
