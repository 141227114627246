import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import LoadingIcon from "../../../../Icon-SVG/LoadingIcon";
import RemoteConnectionIcon from "../../../../Icon-SVG/RemoteConnectionIcon";
import RefreshIcon from "../../../../Icon-SVG/RefreshIcon";
import ErrorIcon from "../../../../Icon-SVG/ErrorIcon";
import QueueIcon from "../../../../Icon-SVG/QueueIcon";

import { RequestResubmit } from "../../../../../store/actions/dashboardActions";
import { isCompressedItself } from "../../../../../utils/function";
import { addNotification } from "../../../../../store/actions/notifierActions";
import axiosApi from "../../../../../utils/axios";
import Spinner from "../../../../common/Spinner/Spinner";

const SeverityStatus = (props) => {
  const dispatch = useDispatch();
  const fileName = props.row.original.file.name;
  const type = props.row.original.severity || props.type;
  const vm = props.row.original.VmDashboard;
  const progress = props?.row?.original.progress || props.progress;
  const fileID = props.row.original.id;
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);

  const [refreshHover, setRefreshHover] = useState(false);
  const [isRemoteConnectionReady, setIsRemoteConnectionReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const checkRemoteConnection = async () => {
    const { compressedID, compressedIDfull, fileIDfull } = props.row.original.file;
    if (
      (compressedID &&
        compressedIDfull &&
        fileIDfull &&
        isCompressedItself(compressedID, compressedIDfull, fileIDfull)) ||
      loading
    )
      return;

    setLoading(true);
    try {
      const response = await axiosApi.get(`/api/files/getVm/${fileID}`);
      if (response.data === "False" || response.data === false) {
        setIsRemoteConnectionReady(false);
        dispatch(
          addNotification({
            message: "Remote connection is not ready",
            options: { variant: "error" },
          }),
        );
      } else {
        setIsRemoteConnectionReady(true);
      }
    } catch (error) {
      setIsRemoteConnectionReady(false);
      dispatch(
        addNotification({
          message: "Remote connection is not ready",
          options: { variant: "error" },
        }),
      );
    }
    setLoading(false);
  };

  const reSubmit = (e) => {
    if (licenceCheck?.key) {
      e.stopPropagation();
      dispatch(RequestResubmit({ id: fileID, fileName }));
      history.replace("dashboard");
    } else {
      dispatch(
        addNotification({
          message: "Your license is not active, please update your license key.",
          options: { variant: "error" },
        }),
      );
    }
  };

  const remoteConnectionHandler = async () => {
    const { compressedID, compressedIDfull, fileIDfull } = props.row.original.file;
    if (
      compressedID &&
      compressedIDfull &&
      fileIDfull &&
      isCompressedItself(compressedID, compressedIDfull, fileIDfull)
    )
      return;

    await checkRemoteConnection();

    if (isRemoteConnectionReady) {
      history.push(vm.url, { isFreeRun: fileName === "freerun.txt" });
    } else {
      return;
    }
  };

  return (
    <div
      className={`severity-status severity-status__${
        progress === "Error Analyser"
          ? "error"
          : progress === "Error Reporter"
          ? "error"
          : type === "not suspicious"
          ? "success"
          : type === "malicious"
          ? "secondary"
          : type === "suspicious"
          ? "suspicious"
          : "loading"
      }`}>
      {progress === "Error Reporter" || progress === "Error Analyser" ? (
        <ErrorIcon color="var(--network-blocks-sbox-border)" width={25} height={25} />
      ) : progress === "In Queue" ? (
        <QueueIcon />
      ) : progress === "Running" && !isRemoteConnectionReady ? (
        <button className="severity-status__check" onClick={checkRemoteConnection}>
          {loading ? <Spinner width={14} height={14} /> : "Check Remote"}
        </button>
      ) : progress === "Running" && isRemoteConnectionReady ? (
        <span className="severity-status__icon" onClick={remoteConnectionHandler}>
          <RemoteConnectionIcon />
        </span>
      ) : type === "loading" ? (
        <LoadingIcon color={"var(--text-color-card)"} />
      ) : (
        type
      )}
      {progress === "Error Reporter" || progress === "Error Analyser" ? (
        <a onClick={(e) => reSubmit(e)} style={{ pointerEvents: "all" }}>
          <span
            style={{ marginLeft: "10px" }}
            onMouseOver={() => setRefreshHover(true)}
            onMouseOut={() => setRefreshHover(false)}>
            <RefreshIcon
              color={
                refreshHover
                  ? "var(--dashboard-table-resubmit-icon-hover)"
                  : "var(--dashboard-table-resubmit-icon)"
              }
              width={25}
              height={25}
            />
          </span>
        </a>
      ) : null}
    </div>
  );
};

export default SeverityStatus;
