const ArrowLeft = ({width = 14, height = 14, color = "var(--text-color-card)",  margin = 0}) => {
  return(
    <div style={{cursor: "pointer", margin}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 12 15"
        fill="none">
        <path d="M1.17032 0.00021318C1.31972 -0.000295142 1.46733 0.0326772 1.60231 0.0967059C1.73729 0.160735 1.8562 0.254194 1.95032 0.370214L6.78032 6.37021C6.92741 6.54915 7.00781 6.77359 7.00781 7.00521C7.00781 7.23684 6.92741 7.46128 6.78032 7.64021L1.78032 13.6402C1.61059 13.8444 1.36667 13.9729 1.10225 13.9972C0.837822 14.0216 0.574541 13.94 0.370325 13.7702C0.166108 13.6005 0.0376839 13.3566 0.0133042 13.0921C-0.0110756 12.8277 0.0705856 12.5644 0.240324 12.3602L4.71032 7.00021L0.390324 1.64021C0.268041 1.49343 0.190364 1.31469 0.166484 1.12514C0.142605 0.93559 0.173521 0.743166 0.255577 0.570638C0.337633 0.398111 0.467393 0.252699 0.629503 0.151609C0.791614 0.0505186 0.97929 -0.0020184 1.17032 0.00021318Z"
        fill={color}/>
      </svg>
    </div>
  )
};
  
const ArrowBottom = ({width = 14, height = 14, color = "var(--text-color-card)",  margin = 0}) => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none">
      <path d="M11.2509 1.6275C11.2513 1.73955 11.2266 1.85025 11.1786 1.95149C11.1305 2.05272 11.0605 2.14191 10.9734 2.2125L6.47344 5.835C6.33924 5.94531 6.17091 6.00562 5.99719 6.00562C5.82347 6.00562 5.65514 5.94531 5.52094 5.835L1.02094 2.085C0.867777 1.9577 0.771458 1.77476 0.753174 1.57644C0.734889 1.37812 0.796135 1.18066 0.923439 1.0275C1.05074 0.874337 1.23368 0.778019 1.432 0.759735C1.63032 0.74145 1.82778 0.802696 1.98094 0.93L6.00094 4.2825L10.0209 1.0425C10.131 0.950788 10.2651 0.89253 10.4072 0.874621C10.5494 0.856711 10.6937 0.879898 10.8231 0.94144C10.9525 1.00298 11.0616 1.1003 11.1374 1.22188C11.2132 1.34347 11.2526 1.48422 11.2509 1.6275Z"
      fill={color} />
    </svg>
  )
};
  
const ExpandButton = ({ row }) => {
  return (
  <span>
    {row.isExpanded ? <ArrowBottom /> : <ArrowLeft />}
  </span>
)
};

export default ExpandButton;
