import * as React from "react"

const Pif = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M93.741 262.947c-5.386.15-9.01.833-10.895 1.454v37.671c2.237.566 4.992.75 8.798.74 14.223-.016 23.132-7.954 23.132-21.311 0-12.007-7.677-18.941-21.035-18.554z" fill={color} />
    <path  d="M357.396 535.335c.776.053 1.542.115 2.329.115h177.39c20.745 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.882-37.628-37.627-37.628h-177.39c-.787 0-1.553.076-2.329.123V0L11.176 46.206v492.311l346.22 47.401v-50.583zm2.33-464.855h177.39c8.893 0 16.125 7.236 16.125 16.127v411.215c0 8.893-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.232V384.509c19.422 14.341 43.412 22.846 69.365 22.846 64.536 0 117.046-52.51 117.046-117.052 0-64.536-52.51-117.052-117.046-117.052-25.953 0-49.943 8.499-69.365 22.846V70.722c.765-.118 1.532-.242 2.329-.242zm-2.33 152.888c17.558-18.194 42.143-29.57 69.365-29.57 53.207 0 96.505 43.287 96.505 96.505 0 53.219-43.291 96.516-96.505 96.516-27.223 0-51.808-11.381-69.365-29.574V223.368zm-226.468 88.394c-9.473 9.491-23.292 13.571-39.106 13.335-3.472-.049-6.574-.285-8.976-.693v45.606l-25.449-1.176V245.229c7.822-1.827 18.955-3.441 34.94-4.155 16.546-.729 28.57 2.226 36.753 8.919 7.917 6.386 13.302 17.266 13.302 30.279s-4.083 24.057-11.464 31.49zm59.571 63.24-29.816-1.376V239.027l29.816-1.302v137.277zM306.85 260.027l-57.535 1.553v32.587l53.644-.348v27.119l-53.644-.54v57.333l-32.144-1.496V236.56l89.678-3.935v27.402z" fill={color} />
    <path  d="M411.028 261.168h31.461v104.138h-31.461zM427.606 253.533c10.541 0 19.113-8.578 19.113-19.118 0-10.544-8.572-19.121-19.113-19.121s-19.124 8.577-19.124 19.121c0 10.541 8.583 19.118 19.124 19.118z" fill={color} />
  </svg>
)

export default Pif
