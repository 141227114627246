import * as React from "react";

const RansomwareIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>
      {
        ".ransom1{fill:var(--header-search-text-color)}.ransom2,.ransom3{fill:none;stroke:var(--header-search-text-color);stroke-width:11;stroke-miterlimit:10}.ransom3{stroke-linecap:round;stroke-linejoin:round}"
      }
    </style>
    <g id="Layer_2">
      <path
        className="ransom"
        d="M713.7 962H310.3l46.4-136.4h310.6zM889.5 337.9v241.3h-755V117.1h417.7V62H149.9c-37.2 0-67.4 30.2-67.4 67.4v569.2c0 37.2 30.2 67.4 67.4 67.4h724.3c37.2 0 67.4-30.2 67.4-67.4V337.9h-52.1zM512 710.6c-20.2 0-36.7-16.4-36.7-36.7 0-20.2 16.4-36.7 36.7-36.7 20.2 0 36.7 16.4 36.7 36.7 0 20.3-16.5 36.7-36.7 36.7z"
        fill={color}
      />
      <path
        className="ransom"
        d="M874.1 62H471.8l40.6 55.1h377.1v462.1l52.1 119.4V129.4c0-37.2-30.2-67.4-67.5-67.4z"
        fill={color}
      />
      <path
        className="ransom1"
        d="M556.9 264.8h-20.2v-26.1c0-15.1-12.3-27.3-27.3-27.3-15.1 0-27.3 12.3-27.3 27.3v26.1h-20.2v-26.1c0-26.2 21.3-47.5 47.5-47.5s47.5 21.3 47.5 47.5v26.1z"
      />
      <path
        className="ransom2"
        d="m200.1 204.7-30.8-17.5c-10.5-6-14.2-19.3-8.2-29.8s19.3-14.2 29.8-8.2l30.8 17.5c10.5 6 14.2 19.3 8.2 29.8s-19.3 14.2-29.8 8.2zM289.7 255.5 258.9 238c-10.5-6-14.2-19.3-8.2-29.8S270 194 280.5 200l30.8 17.5c10.5 6 14.2 19.3 8.2 29.8s-19.3 14.2-29.8 8.2zM380.6 307.1l-30.8-17.5c-10.5-6-14.2-19.3-8.2-29.8s19.3-14.2 29.8-8.2l30.8 17.5c10.5 6 14.2 19.3 8.2 29.8s-19.3 14.1-29.8 8.2z"
      />
      <path className="ransom3" d="m203.3 181.5 69.1 39.1M299.5 236l69.1 39.1" />
      <path
        className="ransom2"
        d="m818.8 204.7 30.8-17.5c10.5-6 14.2-19.3 8.2-29.8s-19.3-14.2-29.8-8.2l-30.8 17.5c-10.5 6-14.2 19.3-8.2 29.8 5.9 10.5 19.3 14.2 29.8 8.2zM729.2 255.5 760 238c10.5-6 14.2-19.3 8.2-29.8s-19.3-14.2-29.8-8.2l-30.8 17.5c-10.5 6-14.2 19.3-8.2 29.8 5.9 10.5 19.3 14.2 29.8 8.2zM638.2 307.1l30.8-17.5c10.5-6 14.2-19.3 8.2-29.8s-19.3-14.2-29.8-8.2l-30.8 17.5c-10.5 6-14.2 19.3-8.2 29.8s19.3 14.1 29.8 8.2z"
      />
      <path className="ransom3" d="m815.5 181.5-69.1 39.1M719.3 236l-69 39.1" />
      <path
        className="ransom2"
        d="m818.8 496 30.8 17.5c10.5 6 14.2 19.3 8.2 29.8s-19.3 14.2-29.8 8.2L797.2 534c-10.5-6-14.2-19.3-8.2-29.8 5.9-10.4 19.3-14.1 29.8-8.2zM729.2 445.2l30.8 17.5c10.5 6 14.2 19.3 8.2 29.8s-19.3 14.2-29.8 8.2l-30.8-17.5c-10.5-6-14.2-19.3-8.2-29.8 5.9-10.4 19.3-14.1 29.8-8.2zM638.2 393.7l30.8 17.5c10.5 6 14.2 19.3 8.2 29.8s-19.3 14.2-29.8 8.2l-30.8-17.5c-10.5-6-14.2-19.3-8.2-29.8s19.3-14.2 29.8-8.2z"
      />
      <path className="ransom3" d="m815.5 519.3-69.1-39.2M719.3 464.8l-69-39.2" />
      <path
        className="ransom2"
        d="m200.1 496-30.8 17.5c-10.5 6-14.2 19.3-8.2 29.8s19.3 14.2 29.8 8.2l30.8-17.5c10.5-6 14.2-19.3 8.2-29.8-6-10.4-19.3-14.1-29.8-8.2zM289.7 445.2l-30.8 17.5c-10.5 6-14.2 19.3-8.2 29.8s19.3 14.2 29.8 8.2l30.8-17.5c10.5-6 14.2-19.3 8.2-29.8-6-10.4-19.3-14.1-29.8-8.2zM380.6 393.7l-30.8 17.5c-10.5 6-14.2 19.3-8.2 29.8s19.3 14.2 29.8 8.2l30.8-17.5c10.5-6 14.2-19.3 8.2-29.8s-19.3-14.2-29.8-8.2z"
      />
      <path className="ransom3" d="m203.3 519.3 69.1-39.2M299.5 464.8l69.1-39.2" />
      <path
        className="ransom1"
        d="M549.4 283h-80c-17.6 0-31.8 14.2-31.8 31.8v66.9c0 17.6 14.2 31.8 31.8 31.8h80c17.6 0 31.8-14.2 31.8-31.8v-66.9c0-17.6-14.2-31.8-31.8-31.8zm-29.6 75.8v24.1H499v-24.1c-6.2-3.6-10.3-10.2-10.3-17.9 0-11.4 9.3-20.7 20.7-20.7s20.7 9.3 20.7 20.7c0 7.7-4.1 14.3-10.3 17.9z"
      />
    </g>
  </svg>
);

export default RansomwareIcon;
