import React from "react";

const InterestingExtractedStrings = () => {
  return (
    <div className="dropped-files border" style={{ minHeight: "600px" }}>
      InteresingExtractedStrings
    </div>
  );
};

export default InterestingExtractedStrings;
