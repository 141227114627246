import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestNewFile } from "../../../store/actions/dashboardActions";
import { RequestArtifactSubmission } from "../../../store/actions/analysisReportActions";
import TabButton from "../TabButton/TabButton";
import Backdrop from "../../UI/Backdrop/Backdrop";
import ClipIcon from "../../Icon-SVG/ClipIcon";
import Content from "./Content/Content";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import ToggleButton from "../ToggleButton/ToggleButton";
import "./styles.scss";
import Warning from "../Warning/Warning";
import InformationPopup from "../InformationPopup/InformationPopup";
import { informationPopupContent } from "../InformationPopup/utils/informationPopupUtil";
import { addNotification } from "../../../store/actions/notifierActions";
import { truncateString } from "../../../utils/function";

const WIN7_DEFAULT = {
  machine: "win7",
  type: ["high_evasion"],
  command_line: "",
  enviromental_variable: "",
  related_password: "",
  runtime_duration: 120,
  enforse_submission: false,
  network_traffic: ["simulate"],
  enforse_runtime: false,
  memory_dump: "advanced",
  action_script: "default",
  win7: true,
};

const WIN10_DEFAULT = {
  machine: "win10",
  type: ["full_triage"],
  command_line: "",
  enviromental_variable: "",
  related_password: "",
  runtime_duration: 120,
  enforse_submission: false,
  network_traffic: ["vpn"],
  enforse_runtime: false,
  memory_dump: "advanced",
  win10: true,
  action_script: "default",
};

const WIN11_DEFAULT = {
  machine: "win11",
  type: ["full_triage"],
  command_line: "",
  enviromental_variable: "",
  related_password: "",
  runtime_duration: 120,
  enforse_submission: false,
  network_traffic: ["vpn"],
  enforse_runtime: false,
  memory_dump: "advanced",
  action_script: "default",
  win11: true,
};

const LINUX_DEFAULT = {
  machine: "linux22",
  type: ["full_triage"],
  command_line: "",
  enviromental_variable: "",
  related_password: "",
  runtime_duration: 120,
  enforse_submission: false,
  network_traffic: ["vpn"],
  enforse_runtime: false,
  memory_dump: "advanced",
  action_script: "default",
  win11: true,
};

const truncateArtifactFileName = (stringToTruncate, maxLength) =>
  stringToTruncate.length > maxLength
    ? stringToTruncate.slice(0, maxLength) + "..."
    : stringToTruncate;

const getOSName = (shortName) => {
  switch (shortName) {
    case "win7":
      return "Windows 7";
    case "win10":
      return "Windows 10";
    case "win11":
      return "Windows 11";
    case "linux22":
      return "Linux";
    default:
      return "Unknown OS";
  }
};

const SubmitPopup = ({ selectedFiles, setSelectedFiles, setActive, isArtifact = false }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.dashboard.loading);
  const [typeWin7, setTypeWin7] = useState(["high_evasion"]);
  const [typeWin10, setTypeWin10] = useState(["full_triage"]);
  const [typeWin11, setTypeWin11] = useState(["full_triage"]);
  const [typeLinux, setTypeLinux] = useState(["full_triage"]);
  const [commandLineWin7, setCommandLineWin7] = useState("");
  const [commandLineWin10, setCommandLineWin10] = useState("");
  const [commandLineWin11, setCommandLineWin11] = useState("");
  const [commandLineLinux, setCommandLineLinux] = useState("");
  const [environmentalVariableWin7, setEnvironmentalVariableWin7] = useState("");
  const [environmentalVariableWin10, setEnvironmentalVariableWin10] = useState("");
  const [environmentalVariableWin11, setEnvironmentalVariableWin11] = useState("");
  const [environmentalVariableLinux, setEnvironmentalVariableLinux] = useState("");
  const [relatedPasswordsWin7, setRelatedPasswordsWin7] = useState("");
  const [relatedPasswordsWin10, setRelatedPasswordsWin10] = useState("");
  const [relatedPasswordsWin11, setRelatedPasswordsWin11] = useState("");
  const [relatedPasswordsLinux, setRelatedPasswordsLinux] = useState("");
  const [currentFileType, setCurrentFileType] = useState("");
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [rangeWin7, setRangeWin7] = useState(120);
  const [rangeWin10, setRangeWin10] = useState(120);
  const [rangeWin11, setRangeWin11] = useState(120);
  const [rangeLinux, setRangeLinux] = useState(120);
  const [forceWin7, setForceWin7] = useState(false);
  const [forceWin10, setForceWin10] = useState(false);
  const [forceWin11, setForceWin11] = useState(false);
  const [forceLinux, setForceLinux] = useState(false);
  const [radioWin7, setRadioWin7] = useState(["simulate"]);
  const [radioWin10, setRadioWin10] = useState(["vpn"]);
  const [radioWin11, setRadioWin11] = useState(["vpn"]);
  const [radioLinux, setRadioLinux] = useState(["vpn"]);
  const [enforceRuntimeWin7, setEnforceRuntimeWin7] = useState(false);
  const [enforceRuntimeWin10, setEnforceRuntimeWin10] = useState(false);
  const [enforceRuntimeWin11, setEnforceRuntimeWin11] = useState(false);
  const [enforceRuntimeLinux, setEnforceRuntimeLinux] = useState(false);
  const [memoryDumpWin7, setMemoryDumpWin7] = useState("advanced");
  const [memoryDumpWin10, setMemoryDumpWin10] = useState("advanced");
  const [memoryDumpWin11, setMemoryDumpWin11] = useState("advanced");
  const [memoryDumpLinux, setMemoryDumpLinux] = useState("advanced");
  const [win7, setWin7] = useState(true);
  const [win10, setWin10] = useState(false);
  const [win11, setWin11] = useState(false);
  const [linux, setLinux] = useState(false);
  const [actionScriptWin7, setActionScriptWin7] = useState("default");
  const [actionScriptWin10, setActionScriptWin10] = useState("default");
  const [actionScriptWin11, setActionScriptWin11] = useState("default");
  const [actionScriptLinux, setActionScriptLinux] = useState("default");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [activeOs, setActiveOs] = useState("Windows 7");
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const vms = useSelector((state) => state.dashboard.vms);

  const onSubmitForm = () => {
    if (licenceCheck.licenceStatus === "Unavailable") {
      dispatch(
        addNotification({
          message: "Your license key has expired. Please contact CyberFortress Technology Team.",
          options: { variant: "error" },
        }),
      );
    } else if (!isArtifact) {
      dispatch(RequestNewFile(selectedFiles));
    } else if (isArtifact) {
      dispatch(RequestArtifactSubmission(selectedFiles));
    }

    setSelectedFiles([]);
    setActive(false);
  };

  useEffect(() => {
    if (!isArtifact) {
      setCurrentFileType(selectedFiles[0].file.type);
    }
    setCurrentFileIndex(0);
  }, []);

  useEffect(() => {
    if (vms.length) {
      const osName = getOSName(vms[0]);
      setActiveOs(osName);
      if (osName !== "Windows 7") {
        setWin7(false);
      }
    }
  }, [vms]);

  useEffect(() => {
    if (!win7 && !win10 && !win11 && !linux) {
      if (activeOs === "Windows 7") {
        setWin7(true);
      } else if (activeOs === "Windows 10") {
        setWin10(true);
      } else if (activeOs === "Windows 11") {
        setWin11(true);
      } else {
        setLinux(true);
      }
    }
  }, [win7, win10, win11, linux]);

  useEffect(() => {
    configSave(currentFileIndex);
  }, [
    typeWin7,
    typeWin10,
    typeWin11,
    typeLinux,
    commandLineWin10,
    commandLineWin7,
    commandLineWin11,
    commandLineLinux,
    radioWin7,
    radioWin10,
    radioWin11,
    radioLinux,
    enforceRuntimeWin7,
    enforceRuntimeWin10,
    enforceRuntimeWin11,
    enforceRuntimeLinux,
    forceWin7,
    forceWin10,
    forceWin11,
    forceLinux,
    environmentalVariableWin7,
    environmentalVariableWin10,
    environmentalVariableWin11,
    environmentalVariableLinux,
    win7,
    win10,
    win11,
    linux,
    memoryDumpWin7,
    memoryDumpWin10,
    memoryDumpWin11,
    memoryDumpLinux,
    relatedPasswordsWin7,
    relatedPasswordsWin10,
    relatedPasswordsWin11,
    relatedPasswordsLinux,
    rangeWin7,
    rangeWin10,
    rangeWin11,
    rangeLinux,
    actionScriptWin7,
    actionScriptWin10,
    actionScriptWin11,
    actionScriptLinux,
  ]);

  const unSelectFile = async (index) => {
    if (currentFileIndex === selectedFiles.length - 1) {
      const currentIndex = currentFileIndex - 1;
      setCurrentFileIndex(currentIndex);
      configFile(currentIndex);
    }
    setSelectedFiles((selectedFiles) => selectedFiles.filter((file, i) => i !== index));
  };

  const configFile = async (index) => {
    try {
      const file = selectedFiles.find((_, i) => i === index);

      if (!file) {
        return;
      }

      if (file.win7) {
        setCurrentFileIndex(index);
        setCurrentFileType(file.file.type);
        setTypeWin7(typeof file.type[0] === "string" ? [...file.type] : file.type[0]);
        setCommandLineWin7(file.command_line[0]);
        setEnforceRuntimeWin7(file.enforse_runtime[0]);
        setForceWin7(file.enforse_submission[0]);
        setEnvironmentalVariableWin7(file.enviromental_variable[0]);
        setWin7(file.win7);
        setWin10(file.win10);
        setWin11(file.win11);
        setLinux(file.linux);
        setMemoryDumpWin7(file.memory_dump[0]);
        setRadioWin7(
          typeof file.network_traffic[0] === "string"
            ? [...file.network_traffic]
            : file.network_traffic[0],
        );
        setRelatedPasswordsWin7(file.related_password[0]);
        setRangeWin7(file.runtime_duration[0]);
        setActionScriptWin7(file.action_script[0]);
      }
      if (file.win10) {
        setCurrentFileIndex(index);
        setCurrentFileType(file.file.type);
        setTypeWin10(typeof file.type[0] === "string" ? [...file.type] : file.type[1]);
        setCommandLineWin10(file.win7 ? file.command_line[1] : file.command_line[0]);
        setEnforceRuntimeWin10(file.win7 ? file.enforse_runtime[1] : file.enforse_runtime[0]);
        setForceWin10(file.win7 ? file.enforse_submission[1] : file.enforse_submission[0]);
        setEnvironmentalVariableWin10(
          file.win7 ? file.enviromental_variable[1] : file.enviromental_variable[0],
        );
        setWin7(file.win7);
        setWin10(file.win10);
        setWin11(file.win11);
        setLinux(file.linux);
        setMemoryDumpWin10(file.win7 ? file.memory_dump[1] : file.memory_dump[0]);
        setRadioWin10(
          typeof file.network_traffic[0] === "string"
            ? [...file.network_traffic]
            : file.network_traffic[1],
        );
        setRelatedPasswordsWin10(file.win7 ? file.related_password[1] : file.related_password[0]);
        setRangeWin10(file.win7 ? file.runtime_duration[1] : file.runtime_duration[0]);
        setActionScriptWin10(file.win7 ? file.action_script[1] : file.action_script[0]);
      }
      if (file.win11) {
        setCurrentFileIndex(index);
        setCurrentFileType(file.file.type);
        setTypeWin11(
          typeof file.type[0] === "string"
            ? [...file.type]
            : file.win7 && file.win10
            ? file.type[2]
            : file.win7 || file.win10
            ? file.type[1]
            : file.type[0],
        );
        setCommandLineWin11(
          file.win7 && file.win10
            ? file.command_line[2]
            : file.win7 || file.win10
            ? file.command_line[1]
            : file.command_line[0],
        );
        setEnforceRuntimeWin11(
          file.win7 && file.win10
            ? file.enforse_runtime[2]
            : file.win7 || file.win10
            ? file.enforse_runtime[1]
            : file.enforse_runtime[0],
        );
        setForceWin11(
          file.win7 && file.win10
            ? file.enforse_submission[2]
            : file.win7 || file.win10
            ? file.enforse_submission[1]
            : file.enforse_submission[0],
        );
        setEnvironmentalVariableWin11(
          file.win7 && file.win10
            ? file.enviromental_variable[2]
            : file.win7 || file.win10
            ? file.enviromental_variable[1]
            : file.enviromental_variable[0],
        );
        setWin7(file.win7);
        setWin10(file.win10);
        setWin11(file.win11);
        setLinux(file.linux);
        setMemoryDumpWin11(
          file.win7 && file.win10
            ? file.memory_dump[2]
            : file.win7 || file.win10
            ? file.memory_dump[1]
            : file.memory_dump[0],
        );
        setRadioWin11(
          typeof file.network_traffic[0] === "string"
            ? [...file.network_traffic]
            : file.win7 && file.win10
            ? file.network_traffic[2]
            : file.win7 || file.win10
            ? file.network_traffic[1]
            : file.network_traffic[0],
        );
        setRelatedPasswordsWin11(
          file.win7 && file.win10
            ? file.related_password[2]
            : file.win7 || file.win10
            ? file.related_password[1]
            : file.related_password[0],
        );
        setRangeWin11(
          file.win7 && file.win10
            ? file.runtime_duration[2]
            : file.win7 || file.win10
            ? file.runtime_duration[1]
            : file.runtime_duration[0],
        );
        setActionScriptWin11(
          file.win7 && file.win10
            ? file.action_script[2]
            : file.win7 || file.win10
            ? file.action_script[1]
            : file.action_script[0],
        );
      }
      if (file.linux) {
        setCurrentFileIndex(index);
        setCurrentFileType(file.file.type);
        setTypeLinux(["full_triage"]);
        setCommandLineLinux(
          file.win7 && file.win10 && file.win11
            ? file.command_line[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.command_line[2]
            : file.win7 || file.win10 || file.win11
            ? file.command_line[1]
            : file.command_line[0],
        );
        setEnforceRuntimeLinux(
          file.win7 && file.win10 && file.win11
            ? file.enforse_runtime[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.enforse_runtime[2]
            : file.win7 || file.win10 || file.win11
            ? file.enforse_runtime[1]
            : file.enforse_runtime[0],
        );
        setForceLinux(
          file.win7 && file.win10 && file.win11
            ? file.enforse_submission[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.enforse_submission[2]
            : file.win7 || file.win10 || file.win11
            ? file.enforse_submission[1]
            : file.enforse_submission[0],
        );
        setEnvironmentalVariableLinux(
          file.win7 && file.win10 && file.win11
            ? file.enviromental_variable[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.enviromental_variable[2]
            : file.win7 || file.win10 || file.win11
            ? file.enviromental_variable[1]
            : file.enviromental_variable[0],
        );
        setWin7(file.win7);
        setWin10(file.win10);
        setWin11(file.win11);
        setLinux(file.linux);
        setMemoryDumpLinux(
          file.win7 && file.win10 && file.win11
            ? file.memory_dump[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.memory_dump[2]
            : file.win7 || file.win10 || file.win11
            ? file.memory_dump[1]
            : file.memory_dump[0],
        );
        setRadioLinux(
          typeof file.network_traffic[0] === "string"
            ? [...file.network_traffic]
            : file.win7 && file.win10 && file.win11
            ? file.network_traffic[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.network_traffic[2]
            : file.win7 || file.win10 || file.win11
            ? file.network_traffic[1]
            : file.network_traffic[0],
        );
        setRelatedPasswordsLinux(
          file.win7 && file.win10 && file.win11
            ? file.related_password[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.related_password[2]
            : file.win7 || file.win10 || file.win11
            ? file.related_password[1]
            : file.related_password[0],
        );
        setRangeLinux(
          file.win7 && file.win10 && file.win11
            ? file.runtime_duration[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.runtime_duration[2]
            : file.win7 || file.win10 || file.win11
            ? file.runtime_duration[1]
            : file.runtime_duration[0],
        );
        setActionScriptLinux(
          file.win7 && file.win10 && file.win11
            ? file.action_script[3]
            : (file.win7 && file.win10) || (file.win7 && file.win11) || (file.win10 && file.win11)
            ? file.action_script[2]
            : file.win7 || file.win10 || file.win11
            ? file.action_script[1]
            : file.action_script[0],
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const configSave = (currentFile) => {
    try {
      const selectedFile = selectedFiles.find((_, i) => i === currentFile);

      if (selectedFile && !selectedFile.win10 && win10) {
        setTypeWin10(WIN10_DEFAULT.type);
        setCommandLineWin10(WIN10_DEFAULT.command_line);
        setEnforceRuntimeWin10(WIN10_DEFAULT.enforse_runtime);
        setForceWin10(WIN10_DEFAULT.enforse_submission);
        setEnvironmentalVariableWin10(WIN10_DEFAULT.enviromental_variable);
        setMemoryDumpWin10(WIN10_DEFAULT.memory_dump);
        setRadioWin10(WIN10_DEFAULT.network_traffic);
        setRelatedPasswordsWin10(WIN10_DEFAULT.related_password);
        setRangeWin10(WIN10_DEFAULT.runtime_duration);
        setActionScriptWin10(WIN10_DEFAULT.action_script);
      } else if (selectedFile && !selectedFile.win7 && win7) {
        setTypeWin7(WIN7_DEFAULT.type);
        setCommandLineWin7(WIN7_DEFAULT.command_line);
        setEnforceRuntimeWin7(WIN7_DEFAULT.enforse_runtime);
        setForceWin7(WIN7_DEFAULT.enforse_submission);
        setEnvironmentalVariableWin7(WIN7_DEFAULT.enviromental_variable);
        setMemoryDumpWin7(WIN7_DEFAULT.memory_dump);
        setRadioWin7(WIN7_DEFAULT.network_traffic);
        setRelatedPasswordsWin7(WIN7_DEFAULT.related_password);
        setRangeWin7(WIN7_DEFAULT.runtime_duration);
        setActionScriptWin7(WIN7_DEFAULT.action_script);
      } else if (selectedFile && !selectedFile.win11 && win11) {
        setTypeWin11(WIN11_DEFAULT.type);
        setCommandLineWin11(WIN11_DEFAULT.command_line);
        setEnforceRuntimeWin11(WIN11_DEFAULT.enforse_runtime);
        setForceWin11(WIN11_DEFAULT.enforse_submission);
        setEnvironmentalVariableWin11(WIN11_DEFAULT.enviromental_variable);
        setMemoryDumpWin11(WIN11_DEFAULT.memory_dump);
        setRadioWin11(WIN11_DEFAULT.network_traffic);
        setRelatedPasswordsWin11(WIN11_DEFAULT.related_password);
        setRangeWin11(WIN11_DEFAULT.runtime_duration);
        setActionScriptWin11(WIN11_DEFAULT.action_script);
      } else if (selectedFile && !selectedFile.linux && linux) {
        setTypeLinux(LINUX_DEFAULT.type);
        setCommandLineLinux(LINUX_DEFAULT.command_line);
        setEnforceRuntimeLinux(LINUX_DEFAULT.enforse_runtime);
        setForceLinux(LINUX_DEFAULT.enforse_submission);
        setEnvironmentalVariableLinux(LINUX_DEFAULT.enviromental_variable);
        setMemoryDumpLinux(LINUX_DEFAULT.memory_dump);
        setRadioLinux(LINUX_DEFAULT.network_traffic);
        setRelatedPasswordsLinux(LINUX_DEFAULT.related_password);
        setRangeLinux(LINUX_DEFAULT.runtime_duration);
        setActionScriptLinux(LINUX_DEFAULT.action_script);
      }

      const updatedSelectedFiles = selectedFiles.map((file, i) => {
        if (i !== currentFile) {
          return file;
        }
        const machine = [];
        let type = [];
        const command_line = [];
        const enviromental_variable = [];
        const related_password = [];
        const runtime_duration = [];
        const enforse_runtime = [];
        const enforse_submission = [];
        const memory_dump = [];
        let network_traffic = [];
        const action_script = [];
        let updWin7 = false;
        let updWin10 = false;
        let updWin11 = false;
        let updLinux = false;

        if (win7) {
          machine.push("win7");
          type.push(typeWin7);
          command_line.push(commandLineWin7);
          enviromental_variable.push(environmentalVariableWin7);
          related_password.push(relatedPasswordsWin7);
          runtime_duration.push(rangeWin7);
          enforse_runtime.push(enforceRuntimeWin7);
          enforse_submission.push(forceWin7);
          memory_dump.push(memoryDumpWin7);
          network_traffic.push(radioWin7);
          action_script.push(actionScriptWin7);
          updWin7 = true;
        }
        if (win10) {
          machine.push("win10");
          type.push(typeWin10);
          command_line.push(commandLineWin10);
          enviromental_variable.push(environmentalVariableWin10);
          related_password.push(relatedPasswordsWin10);
          runtime_duration.push(rangeWin10);
          enforse_runtime.push(enforceRuntimeWin10);
          enforse_submission.push(forceWin10);
          memory_dump.push(memoryDumpWin10);
          network_traffic.push(radioWin10);
          action_script.push(actionScriptWin10);
          updWin10 = true;
        }
        if (win11) {
          machine.push("win11");
          type.push(typeWin11);
          command_line.push(commandLineWin11);
          enviromental_variable.push(environmentalVariableWin11);
          related_password.push(relatedPasswordsWin11);
          runtime_duration.push(rangeWin11);
          enforse_runtime.push(enforceRuntimeWin11);
          enforse_submission.push(forceWin11);
          memory_dump.push(memoryDumpWin11);
          network_traffic.push(radioWin11);
          action_script.push(actionScriptWin11);
          updWin11 = true;
        }
        if (linux) {
          machine.push("linux22");
          type.push(typeLinux);
          command_line.push(commandLineLinux);
          enviromental_variable.push(environmentalVariableLinux);
          related_password.push(relatedPasswordsLinux);
          runtime_duration.push(rangeLinux);
          enforse_runtime.push(enforceRuntimeLinux);
          enforse_submission.push(forceLinux);
          memory_dump.push(memoryDumpLinux);
          network_traffic.push(radioLinux);
          action_script.push(actionScriptLinux);
          updLinux = true;
        }
        if (type.length === 1) {
          type = type[0];
        }
        if (network_traffic.length === 1) {
          network_traffic = network_traffic[0];
        }

        if (isArtifact) {
          return {
            fileName: file.fileName,
            fileHash: file.fileHash,
            source: file.source,
            fileIDfull: file.fileIDfull,
            machine,
            type,
            command_line,
            enviromental_variable,
            related_password,
            runtime_duration,
            enforse_runtime,
            enforse_submission,
            memory_dump,
            network_traffic,
            action_script,
            win7: updWin7,
            win10: updWin10,
            win11: updWin11,
            linux: updLinux,
          };
        } else {
          return {
            file: file?.file,
            fileType: file?.file.name.split(".").pop(),
            machine,
            type,
            command_line,
            enviromental_variable,
            related_password,
            runtime_duration,
            enforse_runtime,
            enforse_submission,
            memory_dump,
            network_traffic,
            action_script,
            win7: updWin7,
            win10: updWin10,
            win11: updWin11,
            linux: updLinux,
          };
        }
      });

      setSelectedFiles(updatedSelectedFiles);
    } catch (error) {
      console.log(error);
    }
  };

  const onAllApplyConfig = () => {
    try {
      const { file, fileType, ...optionsToApplyAll } = selectedFiles[currentFileIndex];

      setSelectedFiles((selectedFiles) => {
        return selectedFiles.map((selectedFile, i) => {
          if (i !== currentFileIndex) {
            return {
              file: selectedFile.file,
              fileType: selectedFile.fileType,
              ...optionsToApplyAll,
            };
          } else {
            return { ...selectedFile };
          }
        });
      });

      dispatch(
        addNotification({
          message: "Configuration applied successfully!",
          options: { variant: "success" },
        }),
      );
    } catch (error) {
      dispatch(
        addNotification({
          message: "Configuration applied error!",
          options: { variant: "error" },
        }),
      );
    }
  };

  return (
    <div className="submit-popup">
      <CustomScrollbar maxHeight={"90vh"}>
        <div className="submit-popup-container">
          <div className="close-button__outer">
            <div onClick={() => setActive(false)} className="close-button__inner">
              <label className="close-button__close">Close</label>
            </div>
          </div>
          {!isArtifact ? (
            <div className="submit-popup-container__buttons__container--apply">
              <button onClick={onAllApplyConfig}>Apply All</button>
            </div>
          ) : null}
          <div
            className="submit-popup-container__chosen-files-data"
            style={{ paddingTop: "10px", marginBottom: "10px" }}>
            <div
              className="submit-popup-container__chosen-files-data__clip"
              style={{ position: "absolute" }}>
              <ClipIcon width="24" height="24" />
            </div>
            <p className="light" style={{ paddingTop: "5px", paddingLeft: "30px" }}>
              {isArtifact
                ? `File: ${truncateArtifactFileName(selectedFiles[currentFileIndex].fileName, 37)}`
                : `Chosen File: ${truncateString(selectedFiles[currentFileIndex].file.name, 22)}`}
            </p>
          </div>
          {!isArtifact ? (
            <section className="submit-popup-container__choosen-files-block">
              {selectedFiles.length !== 0
                ? selectedFiles.map((selectedFile, i) => (
                    <div className="submit-popup-container__choosen-files-item" key={i}>
                      <p>
                        <b
                          className="submit-popup-container__choosen-files-item__icon"
                          onClick={() => unSelectFile(i)}>
                          <b className={"submit-popup-container__choosen-files-item__icon__active"}>
                            X
                          </b>
                        </b>
                        <b
                          className="submit-popup-container__choosen-files-item__file"
                          style={{ paddingLeft: "20px", margin: "auto" }}>
                          <button onClick={() => configFile(i)}>
                            {truncateString(selectedFile.file.name, 35)}
                          </button>
                        </b>
                      </p>
                    </div>
                  ))
                : setActive(false)}
            </section>
          ) : null}
          <section className="submit-popup-container__vm" style={{ marginTop: "5px" }}>
            <p className="light margin-top" style={{ display: "flex" }}>
              Select VMS
            </p>
            <div className="submit-popup-container__vm--labels m-t-45 relative">
              <div className="button-block">
                {vms.includes("win7") ? (
                  <TabButton
                    title="Windows 7"
                    os="win7"
                    osSettings={setWin7}
                    onClick={setActiveOs}
                    osActive={win7}
                    active={activeOs}
                    submitOs={true}
                  />
                ) : null}
                {vms.includes("win10") ? (
                  <TabButton
                    title="Windows 10"
                    os="win10"
                    osSettings={setWin10}
                    onClick={setActiveOs}
                    osActive={win10}
                    active={activeOs}
                    submitOs={true}
                  />
                ) : null}
                {vms.includes("win11") ? (
                  <TabButton
                    title="Windows 11"
                    os="win11"
                    osSettings={setWin11}
                    onClick={setActiveOs}
                    osActive={win11}
                    active={activeOs}
                    submitOs={true}
                  />
                ) : null}
                {vms.includes("linux22") ? (
                  <TabButton
                    title="Linux"
                    os="linux22"
                    osSettings={setLinux}
                    onClick={setActiveOs}
                    osActive={linux}
                    active={activeOs}
                    submitOs={true}
                  />
                ) : null}
              </div>
            </div>
          </section>
          {(activeOs === "Windows 7" && win7) ||
          (activeOs === "Windows 10" && win10) ||
          (activeOs === "Windows 11" && win11) ||
          (activeOs === "Linux" && linux) ? (
            activeOs === "Windows 7" ? (
              <Content
                currentFileIndex={currentFileIndex}
                activeOs={activeOs}
                type={typeWin7}
                setType={setTypeWin7}
                commandLine={commandLineWin7}
                setCommandLine={setCommandLineWin7}
                environmentalVariable={environmentalVariableWin7}
                setEnvironmentalVariable={setEnvironmentalVariableWin7}
                relatedPasswords={relatedPasswordsWin7}
                setRelatedPasswords={setRelatedPasswordsWin7}
                range={rangeWin7}
                setRange={setRangeWin7}
                actionScript={actionScriptWin7}
                setActionScript={setActionScriptWin7}
                enforceRuntime={enforceRuntimeWin7}
                setEnforceRuntime={setEnforceRuntimeWin7}
                memoryDump={memoryDumpWin7}
                setMemoryDump={setMemoryDumpWin7}
                radio={radioWin7}
                setRadio={setRadioWin7}
                configSave={configSave}
              />
            ) : activeOs === "Windows 10" ? (
              <Content
                currentFileIndex={currentFileIndex}
                activeOs={activeOs}
                type={typeWin10}
                setType={setTypeWin10}
                commandLine={commandLineWin10}
                setCommandLine={setCommandLineWin10}
                environmentalVariable={environmentalVariableWin10}
                setEnvironmentalVariable={setEnvironmentalVariableWin10}
                relatedPasswords={relatedPasswordsWin10}
                setRelatedPasswords={setRelatedPasswordsWin10}
                range={rangeWin10}
                setRange={setRangeWin10}
                actionScript={actionScriptWin10}
                setActionScript={setActionScriptWin10}
                enforceRuntime={enforceRuntimeWin10}
                setEnforceRuntime={setEnforceRuntimeWin10}
                memoryDump={memoryDumpWin10}
                setMemoryDump={setMemoryDumpWin10}
                radio={radioWin10}
                setRadio={setRadioWin10}
                configSave={configSave}
              />
            ) : activeOs === "Windows 11" ? (
              <Content
                currentFileIndex={currentFileIndex}
                activeOs={activeOs}
                type={typeWin11}
                setType={setTypeWin11}
                commandLine={commandLineWin11}
                setCommandLine={setCommandLineWin11}
                environmentalVariable={environmentalVariableWin11}
                setEnvironmentalVariable={setEnvironmentalVariableWin11}
                relatedPasswords={relatedPasswordsWin11}
                setRelatedPasswords={setRelatedPasswordsWin11}
                range={rangeWin11}
                setRange={setRangeWin11}
                actionScript={actionScriptWin11}
                setActionScript={setActionScriptWin11}
                enforceRuntime={enforceRuntimeWin11}
                setEnforceRuntime={setEnforceRuntimeWin11}
                memoryDump={memoryDumpWin11}
                setMemoryDump={setMemoryDumpWin11}
                radio={radioWin11}
                setRadio={setRadioWin11}
                configSave={configSave}
              />
            ) : activeOs === "Linux" ? (
              <Content
                currentFileIndex={currentFileIndex}
                activeOs={activeOs}
                type={typeLinux}
                setType={setTypeLinux}
                commandLine={commandLineLinux}
                setCommandLine={setCommandLineLinux}
                environmentalVariable={environmentalVariableLinux}
                setEnvironmentalVariable={setEnvironmentalVariableLinux}
                relatedPasswords={relatedPasswordsLinux}
                setRelatedPasswords={setRelatedPasswordsLinux}
                range={rangeLinux}
                setRange={setRangeLinux}
                actionScript={actionScriptLinux}
                setActionScript={setActionScriptLinux}
                enforceRuntime={enforceRuntimeLinux}
                setEnforceRuntime={setEnforceRuntimeLinux}
                memoryDump={memoryDumpLinux}
                setMemoryDump={setMemoryDumpLinux}
                radio={radioLinux}
                setRadio={setRadioLinux}
                configSave={configSave}
              />
            ) : null
          ) : (
            <div className="submit-popup-container__content">
              <p className="submit-popup-container__content--text">
                Please tick the "{activeOs}" checkbox.
              </p>
            </div>
          )}
          <span
            className={`submit-popup-container__information-popup submit-popup-container__information-popup--${
              isArtifact ? "artifact" : "dashboard"
            }`}>
            <InformationPopup
              content={informationPopupContent.submitPopup}
              iconWidth={32}
              iconHeight={32}
            />
          </span>
        </div>
        <section className="submit-popup-container__buttons">
          <div className="submit-popup-container__buttons__container" style={{ marginLeft: "0px" }}>
            <div className="submit-popup-container__buttons__container--force">
              {(activeOs === "Windows 7" && win7) ||
              (activeOs === "Windows 10" && win10) ||
              (activeOs === "Windows 11" && win11) ||
              (activeOs === "Linux" && linux) ? (
                activeOs === "Windows 7" ? (
                  <ToggleButton
                    active={forceWin7}
                    rightText={"Force Submit"}
                    onClick={setForceWin7}
                  />
                ) : activeOs === "Windows 10" ? (
                  <ToggleButton
                    active={forceWin10}
                    rightText={"Force Submit"}
                    onClick={setForceWin10}
                  />
                ) : activeOs === "Windows 11" ? (
                  <ToggleButton
                    active={forceWin11}
                    rightText={"Force Submit"}
                    onClick={setForceWin11}
                  />
                ) : activeOs === "Linux" ? (
                  <ToggleButton
                    active={forceLinux}
                    rightText={"Force Submit"}
                    onClick={setForceLinux}
                  />
                ) : null
              ) : null}
            </div>
            <button
              className="submit-popup-container__buttons__container--cancel"
              onClick={() => setActive(false)}>
              Cancel
            </button>
            <button
              className="submit-popup-container__buttons__container--submit"
              onClick={onSubmitForm}>
              Submit
            </button>
          </div>
        </section>
        {/* {loading && <Backdrop show={loading} />} */}
      </CustomScrollbar>
      <Warning
        onClickOk={() => setPasswordWarning(false)}
        show={passwordWarning}
        setShow={setPasswordWarning}
        text={passwordError}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </div>
  );
};
export default SubmitPopup;
