import React from "react";

const SuspiciousStates = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000">
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path
          d="M1485 4789 c-959 -301 -1052 -332 -1078 -360 l-28 -30 4 -1017 c3
	-980 4 -1022 24 -1148 80 -491 265 -913 562 -1284 342 -427 825 -747 1351
	-893 216 -61 227 -62 343 -37 210 45 492 152 692 263 225 124 408 261 591 442
	417 412 669 910 767 1509 20 126 21 169 24 1148 l4 1017 -28 30 c-26 29 -113
	58 -1082 361 -580 182 -1064 330 -1075 329 -12 0 -493 -149 -1071 -330z m2073
	-186 l983 -308 -4 -960 c-3 -1046 -2 -1017 -64 -1280 -82 -349 -259 -709 -487
	-992 -322 -398 -793 -700 -1292 -829 l-132 -33 -99 24 c-888 215 -1586 910
	-1808 1800 -70 279 -68 248 -72 1310 l-4 960 978 307 c538 168 987 307 998
	307 11 1 462 -137 1003 -306z"
          fill={color}
        />
        <path
          d="M1760 4289 c-689 -216 -768 -243 -793 -270 l-28 -31 3 -796 c4 -780
	4 -800 27 -921 40 -217 98 -393 191 -583 250 -507 690 -889 1230 -1068 73 -25
	144 -42 170 -42 148 0 679 249 775 364 35 42 34 91 -4 129 -47 47 -85 41 -191
	-30 -124 -83 -236 -142 -370 -194 -196 -77 -198 -77 -336 -31 -697 229 -1202
	861 -1283 1606 -7 59 -11 365 -11 780 l0 683 711 221 710 222 707 -222 707
	-221 3 -665 c2 -422 -1 -707 -8 -780 -20 -212 -83 -442 -176 -639 -59 -127
	-58 -173 8 -207 38 -20 87 -12 116 19 57 61 177 382 217 582 41 205 45 292 45
	1061 l0 734 -27 29 c-25 27 -108 55 -797 271 -423 132 -783 240 -800 239 -17
	0 -375 -109 -796 -240z"
          fill={color}
        />
        <path
          d="M2708 3053 l-328 -328 -138 137 c-137 137 -174 163 -216 152 -13 -3
	-111 -94 -220 -203 -188 -189 -196 -199 -196 -236 0 -39 9 -49 363 -402 l362
	-363 45 0 44 0 538 538 c362 362 539 546 544 565 3 15 3 39 -1 55 -7 30 -342
	374 -387 398 -64 33 -59 37 -410 -313z m470 -16 l92 -92 -448 -448 -447 -447
	-263 263 -262 262 100 100 100 99 143 -142 c141 -139 144 -142 187 -142 l44 0
	321 320 c176 176 325 320 330 320 6 0 52 -42 103 -93z"
          fill={color}
        />
        <path
          d="M3555 1401 c-37 -16 -60 -51 -60 -93 0 -33 6 -45 33 -69 44 -40 93
	-40 133 0 76 76 -7 202 -106 162z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default SuspiciousStates;
