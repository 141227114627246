import React, { useState } from "react";
import styles from "./info.module.css";
import InfoIcon from "../../Icon-SVG/InfoIcon";

const Info = ({ message = "Test Message.." }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <span className={styles["info-icon-container"]}>
      <span
        className={`${styles["info-icon"]} ${isHovered ? styles.active : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <InfoIcon color={isHovered && "var(--dark-theme-element)"} />
      </span>
      {isHovered && <span className={styles["info-popup"]}>{message}</span>}
    </span>
  );
};

export default Info;
