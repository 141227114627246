import Chart from "chart.js/auto";

const useQuery = () => {
  const search = window.location.search;
  return new URLSearchParams(search);
};

export const trojanGroup = ["dropper", "trojan", "trojandl", "rat"];
export const executionGroup = [
  "wmi",
  "rop",
  "javascript",
  "lolbin",
  "ipc",
  "crash",
  "meterpreter",
  "script",
  "powershell",
  "downloader",
  "shellcode",
  "downloder",
  "execution",
  "commands",
  "command",
];
export const otherGroup = [
  "c&c",
  "c2",
  "impact",
  "wiper",
  "clickfraud",
  "dotnet",
  "loader",
  "escalation",
  "tampering",
  "ads",
  "fraud",
  "fraudtool",
  "hacktool",
  "hooking",
  "misc",
  "ocr",
  "privilege_escalation",
  "tool",
  "vertex",
  "vir",
  "work",
  "pdf",
  "adware",
  "collection",
  "allocation",
  "*Missing Category*",
  "service",
  "bootkit",
  "chinese",
];
export const stealthGroup = [
  "anonimity",
  "decoy",
  "persistence",
  "persistance",
  "evasion",
  "apt",
  "stealth",
  "deception",
];
export const networkGroup = [
  "lateral_movement",
  "filesharing",
  "tor",
  "irc",
  "lateral",
  "bind",
  "freehosting",
  "p2p",
  "udp",
  "icmp",
  "cloud",
  "im",
  "expdom",
  "access",
  "proxy",
  "browser",
  "network",
  "http",
  "cnc",
  "dns",
  "smtp",
  "urlshort",
  "martian",
  "martians",
];
export const infostealerGroup = [
  "exfiltration",
  "phishing",
  "phish",
  "infostealer",
  "keylogger",
  "C24_Stealer",
  "filetransfer",
  "isrstealer",
  "istealer",
  "sharpstealer",
  "stealer",
];
export const ransomwareGroup = ["locker", "ransom", "ransomware"];
export const genericGroup = ["spam", "malware", "generic", "general"];
export const exploitGroup = [
  "silverlight",
  "java",
  "privileges",
  "backdoor",
  "exploit",
  "exploitation",
  "exploit_kit",
];
export const bankerGroup = [
  "blackpos",
  "jackpos",
  "miners",
  "pos",
  "banker",
  "banking",
  "cryptocurrency",
  "cryptomining",
  "mining",
  "bitcoin",
  "crypto",
];
export const officeGroup = ["rtf", "macro", "vba", "office", "macros"];
export const encryptionGroup = ["encryption", "packer", "obuscation", "obfuscation"];
export const spreadingGroup = ["targeted", "virus", "worm", "spreading"];
export const injectionGroup = ["Webshell", "injector", "injection"];
export const botGroup = [
  "athena",
  "madness",
  "bot",
  "ddos",
  "betabot",
  "ponybot",
  "solarbot",
  "warbot",
];
export const credentialsGroup = ["ssh", "account", "credential_access", "credential_dumping"];
export const discoveryGroup = [
  "sniffer",
  "process_discovery",
  "spyware",
  "Cloudflare",
  "discovery",
  "recon",
];
export const staticGroup = ["origin", "static", "static]"];
export const avDefenseGroup = [
  "rootkit",
  "masquerading",
  "applocker",
  "amsi",
  "clamav",
  "antiav",
  "anti-av",
  "uac_bypass",
  "anti-analysis",
  "mlchecker",
  "yarachecker",
  "bypass",
  "antivirus",
  "ddgchecker",
  "unpacking",
  "avdetect",
  "anti-emulation",
  "antisandbox",
  "anti-sandbox",
  "anti-vm",
  "antivm",
  "AntiVM",
  "anti-debug",
];

export const RadarChart = (name, theme, signatureList) => {
  const signatures = [];

  for (const signature of signatureList) {
    for (const categorie of signature.categories) {
      signatures.push({ categories: categorie, severity: signature.severity });
    }
  }

  let counter = 0;
  const query = useQuery();

  if (signatures.length === 0) {
    signatures.push({
      severity: 0,
      categories: [],
    });
  }

  setTimeout(() => {
    if (counter >= 0) {
      if (signatures.length !== 0) {
        let trojanCounter = 0;
        let executionCounter = 0;
        let otherCounter = 0;
        let stealthCounter = 0;
        let networkCounter = 0;
        let infostealerCounter = 0;
        let ransomwareCounter = 0;
        let genericCounter = 0;
        let exploitCounter = 0;
        let bankerCounter = 0;
        let officeCounter = 0;
        let encryptionCounter = 0;
        let spreadingCounter = 0;
        let injectionCounter = 0;
        let botCounter = 0;
        let credentialsCounter = 0;
        let discoveryCounter = 0;
        let staticCounter = 0;
        let bigCounter = 0;

        if (signatures) {
          trojanGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (trojanCounter =
                    trojanCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          executionGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (executionCounter =
                    executionCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          otherGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (otherCounter =
                    otherCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          stealthGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (stealthCounter =
                    stealthCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          infostealerGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (infostealerCounter =
                    infostealerCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          ransomwareGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (ransomwareCounter =
                    ransomwareCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          genericGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (genericCounter =
                    genericCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          exploitGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (exploitCounter =
                    exploitCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          bankerGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (bankerCounter =
                    bankerCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          officeGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (officeCounter =
                    officeCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          encryptionGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (encryptionCounter =
                    encryptionCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          spreadingGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (spreadingCounter =
                    spreadingCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          injectionGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (injectionCounter =
                    injectionCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          botGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (botCounter = botCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          credentialsGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (credentialsCounter =
                    credentialsCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          discoveryGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (discoveryCounter =
                    discoveryCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          staticGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (staticCounter =
                    staticCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
          networkGroup.map((item) =>
            signatures.map((key) =>
              item === key.categories
                ? (networkCounter =
                    networkCounter + (key.severity * 0.25 > 1 ? 1 : key.severity * 0.25))
                : null,
            ),
          );
        }

        const signaturesCounter = [
          trojanCounter,
          stealthCounter,
          networkCounter,
          executionCounter,
          infostealerCounter,
          ransomwareCounter,
          genericCounter,
          otherCounter,
          exploitCounter,
          officeCounter,
          bankerCounter,
          encryptionCounter,
          spreadingCounter,
          injectionCounter,
          discoveryCounter,
          botCounter,
          staticCounter,
          credentialsCounter,
        ];
        let signaturesData = [
          12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        ];
        if (signatures) {
          signaturesCounter.map((index) => (bigCounter < index ? (bigCounter = index) : null));
          signaturesCounter.map(
            (index, i) => (signaturesData[i] = Number((100 * index) / bigCounter) + 12),
          );
        }

        if (query.get("state") === "not suspicious") {
          signaturesCounter.map((index) => (bigCounter < index ? (bigCounter = index) : null));
          signaturesCounter.map(
            (index, i) => (signaturesData[i] = Number((100 * index) / bigCounter) / 4 + 12),
          );
        }

        let ctx = document.getElementById(`chart-${name}`)?.getContext("2d");
        let myChart;

        try {
          if (!ctx) return;
          const data = {
            labels: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            datasets: [
              {
                data: signaturesData,
                fill: true,
                backgroundColor: "rgba(236, 55, 74, 0.2)",
                borderColor: "#ec374a",
              },
              {
                data: [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12],
                fill: true,
                backgroundColor: "rgba(255, 255,255, 1)",
                borderColor: "#ec374a",
              },
              {
                data: [65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65],
                fill: false,
                borderColor: "#ec374a",
                borderDash: [10, 5],
              },
              {
                data: [40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40],
                fill: false,
                borderColor: "#ffdd00",
                borderDash: [10, 5],
              },
            ],
          };
          const chartStatus = Chart.getChart(`chart-radar-${theme}`);
          if (chartStatus) chartStatus.destroy();
          myChart = new Chart(ctx, {
            type: "radar",
            data,
            options: {
              elements: {
                line: {
                  borderWidth: 2,
                },
                point: {
                  borderWidth: 0,
                  radius: 0,
                },
              },
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  display: false,
                },
              },

              scales: {
                r: {
                  beginAtZero: true,
                  grid: {
                    display: true,
                    lineWidth: 40,
                    color: function (context) {
                      if (context.index === 0) {
                        return theme === "dark"
                          ? "rgba(250,250,250,0.17)"
                          : "rgba(91, 91, 91, 0.17)";
                      } else if (context.index === 1) {
                        return null;
                      } else if (context.index === 2) {
                        return theme === "dark"
                          ? "rgba(250,250,250,0.14)"
                          : "rgba(91, 91, 91, 0.14)";
                      } else if (context.index === 3) {
                        return null;
                      } else if (context.index === 4) {
                        return theme === "dark"
                          ? "rgba(250,250,250,0.11)"
                          : "rgba(91, 91, 91, 0.11)";
                      } else if (context.index === 5) {
                        return null;
                      } else if (context.index === 6) {
                        return theme === "dark"
                          ? "rgba(250,250,250,0.08)"
                          : "rgba(91, 91, 91, 0.08)";
                      } else if (context.index === 7) {
                        return null;
                      } else if (context.index === 8) {
                        return theme === "dark"
                          ? "rgba(250,250,250,0.05)"
                          : "rgba(91, 91, 91, 0.05)";
                      }
                    },
                  },
                  angleLines: {
                    display: true,
                    color: "#a6b0cf",
                  },
                  ticks: {
                    display: false,
                  },
                  pointLabels: {
                    color: "#7c849d",
                    padding: 10,
                    font: {
                      size: 14,
                    },
                  },
                },
              },
            },
          });
        } catch (e) {
          myChart?.destroy();
        }
        counter++;
      }
    }
  }, 100);
};
