import React from "react";
import { CSVLink } from "react-csv";
import CsvIcon from "../Components/Icon-SVG/CSVIcon";

const ExportCSV = ({ csvData, headers, fileName, selected }) => {
  let newCsvData = csvData;
  if (selected) {
    newCsvData = newCsvData.map((csv) => csv.original);
  }
  return (
    <CSVLink data={newCsvData} headers={headers} filename={fileName}>
      <button style={{ background: "none", border: "none", outline: "none", cursor: "pointer" }}>
        <CsvIcon />
      </button>
    </CSVLink>
  );
};

export default ExportCSV;
