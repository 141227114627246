import * as React from "react"

const Ged = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M574.742 86.602c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.076-2.329.123V0L11.176 46.206v492.311l346.22 47.401v-50.583c.776.053 1.542.115 2.329.115h177.39c20.75 0 37.627-16.888 37.627-37.628V86.602zM119.71 350.57c-6.686 2.189-19.158 5.014-31.392 4.479-16.523-.714-28.221-5.765-36.229-14.825-7.918-8.65-12.207-21.458-12.083-35.736.124-32.326 21.005-51.592 50.502-52.726 11.998-.457 21.394 1.785 26.071 4.062l-4.538 18.804c-5.209-2.333-11.622-4.155-21.808-3.918-17.116.404-29.73 11.255-29.73 32.674 0 20.389 11.433 32.747 28.231 33.229 4.79.136 8.638-.353 10.299-1.218v-21.328l-14.234-.153v-17.801l34.919-.109v54.566h-.008zm83.594 8.321-66.331-2.823V251.084l64.064-2.405v20.423l-41.208.935v22.198l38.793-.242v20.23l-38.793-.335v25.354l43.475 1.208v20.441zm102.375-9.705c-13.152 9.848-32.725 13.879-55.959 12.876-13.552-.587-22.99-1.831-29.344-2.93V249.444c9.375-1.843 21.766-3.15 35.076-3.659 22.699-.867 37.804 2.572 49.859 10.755 13.197 8.841 21.613 23.437 21.613 44.66.005 23.003-8.983 38.637-21.245 47.986zM553.24 497.822c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.116-2.329-.232V410.346h53.527v-37.627h-53.527v-9.408h1.459v-37.627h-1.459v-8.735h53.527v-37.627h-53.527v-5.816h1.459v-6.278h126.521v6.278h5.374v-11.654H358.866v-76.148h-1.465v-8.956h37.076V139.12h-37.076V70.717c.771-.113 1.532-.241 2.335-.241h177.39c8.887 0 16.125 7.236 16.125 16.126v411.22h-.011z" fill={color} />
    <path  d="M258.258 265.591c-5.935.142-9.745.748-11.987 1.302v74.423c2.247.566 5.879.671 9.187.771 24.503.894 40.929-11.906 40.929-40.026.189-24.465-14.8-37.039-38.129-36.47zM457.827 372.719h76.599v37.627h-76.599zM457.827 279.321h76.599v37.627h-76.599zM485.377 325.684h5.374v37.627h-5.374z" fill={color} />
  </svg>
)

export default Ged
