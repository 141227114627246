import * as React from "react"
const  MarketIcon = ({ color = "var(--border)", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path d="M6 6H2V2h4v4zm8-4h-4v4h4V2zm8 0h-4v4h4V2zM6 10H2v4h4v-4zm8 0h-4v4h4v-4zm8 0h-4v4h4v-4zM6 18H2v4h4v-4zm8 0h-4v4h4v-4zm8 0h-4v4h4v-4z" 
    fill={color || "var(--text-color-card)"}/>
  </svg>
)
export default MarketIcon
