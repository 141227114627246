import * as React from "react"

const Rm = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M94.647 258.614c-6.118.179-9.559.806-11.355 1.232v33.43l9.858-.018c12.709-.016 20.357-7.019 20.357-17.872-.005-11.417-7.143-16.916-18.86-16.772z" fill={color} />
    <path  d="M537.115 48.974h-177.39c-.781 0-1.553.076-2.329.123V0L11.176 46.206v492.311l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.75 0 37.627-16.888 37.627-37.627V86.602c0-20.743-16.877-37.628-37.627-37.628zm-98.731 298.841a13.507 13.507 0 0 0-11.632-5.229c-4.085.283-7.918.432-11.718.432-21.471 0-41.501-4.21-57.638-11.118v-82.763c16.137-6.911 36.167-11.111 57.638-11.111 50.957 0 94.001 23.98 94.001 52.379 0 9.34-2.041 22.04-17.927 37.494l-57.606 50.227c3.134-7.633 5.601-13.681 6.972-17.732a13.525 13.525 0 0 0-2.09-12.579zm-321.28 14.615c-1.898-3.597-4.623-13.848-8.03-29.219-3.05-15.454-7.929-19.738-18.239-20.068l-7.548-.111v47.635l-24.145-1.26V242.17c7.729-1.75 19.436-3.331 32.688-3.924 16.732-.745 28.719 1.496 37.068 8.222 7.034 5.729 10.937 14.43 10.937 26.073 0 16.15-10.767 27.188-20.845 31.031v.557c8.154 3.591 12.709 12.031 15.701 23.686 3.722 14.352 7.462 31.029 9.785 36.043l-27.372-1.428zm158.102 8.252-2.155-53.46c-.65-16.747-1.297-36.998-1.297-57.237l-.64.016c-4.504 17.812-10.47 37.558-15.958 53.683l-17.05 52.842-24.035-1.218-14.152-51.959c-4.263-15.517-8.698-34.258-11.762-51.358l-.383.005c-.77 17.722-1.339 37.956-2.289 54.272l-2.291 49.616-26.247-1.376 7.903-128.554 38.583-1.688 13.023 44.137c4.2 15.491 8.42 32.284 11.457 48.143l.601.011c3.866-15.581 8.565-33.479 13.095-48.655l15.018-45.969 43.224-1.898 8.32 142.358-32.965-1.711zM553.24 497.823c0 8.887-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.117-2.329-.231V346.366c16.713 6.383 36.419 10.143 57.638 10.143 4.283 0 8.503-.168 12.655-.451-3.438 10.131-15.59 36.882-26.408 68.062l98.967-86.277c20.151-19.477 22.283-36.453 22.283-47.437 0-36.453-47.443-65.881-107.497-65.881-21.219 0-40.925 3.756-57.638 10.142V70.717c.766-.113 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.127v411.221z" fill={color} />
    <path  d="M455.602 291.927c0-.475-.221-.937-.598-1.249l-52.894-33.761a1.58 1.58 0 0 0-1.681-.179c-.556.265-.896.83-.896 1.431v67.546c0 .61.341 1.177.896 1.429l.698.168c.358 0 .699-.126.992-.357l52.889-33.759c.373-.323.594-.785.594-1.269z" fill={color} />
  </svg>
)

export default Rm
