import React from "react";
import { useSelector } from "react-redux";
import TextField from "../../../../../Components/common/TextField/TextField";
import CustomSelect from "../../../../../Components/common/CustomSelect/CustomSelect";
import { roleConstants, supRoleConstants } from "../../../../../utils/constants";
import Info from "../../../../../Components/UI/Info/Info";

const AddUser = ({
  userName,
  setUserName,
  setRole,
  role,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  error = null,
}) => {
  const userRole = useSelector((state) => state?.users.user.role);

  return (
    <>
      <div className="flex flex-align-items-center">
        <div className="flex-column m-t-20 m-r-15">
          <p className="label">User Name</p>
          <TextField placeholder={"Type Username"} onChange={(e) => setUserName(e.target.value)} />
        </div>
        <div className="flex-column m-t-20 m-l-15">
          <p className="label">
            Role
            {userRole !== "SUPERADMIN" && (
              <span>
                <Info message="Only users with the Admin role can add users with the User role. If you want to create a new admin, please contact your administrator." />
              </span>
            )}
          </p>
          <span
            style={
              userRole === "SUPERADMIN" ? { visibility: "visible" } : { pointerEvents: "none" }
            }>
            <CustomSelect
              placeholder={"Select Role"}
              width={"246px"}
              data={userRole === "SUPERADMIN" ? supRoleConstants : roleConstants}
              setSelected={setRole}
              select={role}
            />
          </span>
        </div>
      </div>
      <div className="flex flex-align-items-center">
        <div className="flex-column m-t-20 m-r-15">
          <p className="label">Create Password</p>
          <TextField
            placeholder={"Type Password"}
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            error={error}
          />
        </div>
        <div className="flex-column m-t-20 m-l-15 relative">
          <p className="label">Confirm Password</p>
          <TextField
            placeholder={"Type Password"}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={error}
          />
        </div>
      </div>
    </>
  );
};

export default AddUser;
