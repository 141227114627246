import React from "react";

const XlsxIcon = () => {
  return (
    <div className="m-r-15 m-l-15">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M3 12.1429V2.7143C3 2.25964 3.1724 1.82361 3.47928 1.50212C3.78616 1.18063 4.20237 1.00001 4.63636 1.00001H14.4545C14.6722 0.998728 14.8816 1.08725 15.0375 1.24644L20.7648 7.24644C20.9167 7.40972 21.0012 7.62911 21 7.85715V12.1429C21 12.3702 20.9138 12.5882 20.7603 12.7489C20.6069 12.9097 20.3988 13 20.1818 13H3.81818C3.60119 13 3.39308 12.9097 3.23964 12.7489C3.0862 12.5882 3 12.3702 3 12.1429ZM14.4545 7.85715H18.9545L14.4545 3.14287V7.85715Z"
          fill="var(--text-color-card)"
        />
        <path
          d="M8.16338 21.9202H6.82528L5.54011 19.7126L4.25493 21.9202H3L4.83326 18.9102L3.11718 16.0838H4.40991L5.60059 18.1836L6.76858 16.0838H8.03108L6.29609 18.978L8.16338 21.9202Z"
          fill="var(--text-color-card)"
        />
        <path
          d="M8.53633 21.9202V16.0838H9.70811V20.8982H11.9496V21.9202H8.53633Z"
          fill="var(--text-color-card)"
        />
        <path
          d="M15.853 20.2994C15.853 20.6454 15.7736 20.9461 15.6149 21.2016C15.4561 21.4571 15.2243 21.654 14.9194 21.7924C14.617 21.9308 14.2491 22 13.8156 22C13.6241 22 13.4364 21.9867 13.2524 21.9601C13.071 21.9335 12.8958 21.8949 12.727 21.8443C12.5607 21.7911 12.4019 21.7259 12.2507 21.6487V20.499C12.5128 20.6214 12.785 20.7319 13.0672 20.8303C13.3494 20.9288 13.6291 20.978 13.9063 20.978C14.0979 20.978 14.2516 20.9514 14.3675 20.8982C14.4859 20.845 14.5716 20.7718 14.6245 20.6786C14.6774 20.5855 14.7039 20.479 14.7039 20.3593C14.7039 20.2129 14.6573 20.0878 14.564 19.984C14.4708 19.8802 14.3423 19.7831 14.1785 19.6926C14.0172 19.6021 13.8345 19.505 13.6304 19.4012C13.5019 19.3373 13.362 19.2601 13.2108 19.1697C13.0596 19.0765 12.916 18.9634 12.7799 18.8303C12.6438 18.6973 12.5317 18.5363 12.4435 18.3473C12.3578 18.1557 12.315 17.9268 12.315 17.6607C12.315 17.312 12.3906 17.014 12.5418 16.7665C12.693 16.519 12.9084 16.33 13.1882 16.1996C13.4704 16.0665 13.803 16 14.1861 16C14.4733 16 14.7467 16.0359 15.0063 16.1078C15.2684 16.177 15.5418 16.2781 15.8265 16.4112L15.4486 17.3733C15.194 17.2641 14.966 17.1803 14.7644 17.1218C14.5628 17.0605 14.3574 17.0299 14.1483 17.0299C14.0021 17.0299 13.8774 17.0552 13.774 17.1058C13.6707 17.1537 13.5926 17.2229 13.5397 17.3134C13.4868 17.4012 13.4603 17.5037 13.4603 17.6208C13.4603 17.7591 13.4981 17.8762 13.5737 17.9721C13.6518 18.0652 13.7677 18.1557 13.9215 18.2435C14.0777 18.3313 14.2717 18.4338 14.5036 18.5509C14.7858 18.6919 15.0265 18.8397 15.2255 18.994C15.4271 19.1457 15.5821 19.3253 15.6905 19.5329C15.7988 19.7379 15.853 19.9933 15.853 20.2994Z"
          fill="var(--text-color-card)"
        />
        <path
          d="M21 21.9202H19.6619L18.3767 19.7126L17.0916 21.9202H15.8366L17.6699 18.9102L15.9538 16.0838H17.2465L18.4372 18.1836L19.6052 16.0838H20.8677L19.1327 18.978L21 21.9202Z"
          fill="var(--text-color-card)"
        />
      </svg>
    </div>
  );
};

export default XlsxIcon;
