import React from "react";

const SortArrowIcon = ({ asc = "var(--sort-color)", desc = "var(--sort-color)" }) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
        <path d="M4 10L0 6H8L4 10Z" fill={asc} />
        <path d="M4 3.49691e-07L8 4L0 4L4 3.49691e-07Z" fill={desc} />
      </svg>
    </>
  );
};

export default SortArrowIcon;
