import React from "react";
import TextField from "../TextField/TextField";
import "./styles.scss";

const ProfileAccount = ({
  setUserNameUpdate,
  userNameUpdate,
  setEmailUpdate,
  emailUpdate,
  setPasswordUpdate,
  setConfirmPasswordUpdate,
  button = false,
}) => {
  return (
    <div className="profile__card">
      <div className="profile__card-inner">
        <div className="profile__card-header">
          <p>Account Settings</p>
        </div>
        <div className="flex flex-align-items-center flex-justify-content-center flex-wrap gap">
          <div className="flex-column profile__card-username">
            <p className="label profile__card-inner__text">User Name</p>
            <TextField
              placeholder={userNameUpdate + " " + " (Enter to change)"}
              onChange={(e) => setUserNameUpdate(e.target.value)}
            />
          </div>
          <div className="flex-column">
            <p className="label profile__card-inner__text">Email</p>
            <TextField
              placeholder={emailUpdate + " " + " (Enter to change)"}
              onChange={(e) => setEmailUpdate(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-align-items-center flex-justify-content-center flex-wrap gap">
          <div className="flex-column profile__card-username">
            <p className="label profile__card-inner__text">Password</p>
            <TextField
              placeholder={"Enter Password"}
              type="password"
              onChange={(e) => setPasswordUpdate(e.target.value)}
            />
          </div>
          <div className="flex-column">
            <p className="label profile__card-inner__text">Confirm Password</p>
            <TextField
              placeholder={"Enter Password"}
              type="password"
              onChange={(e) => setConfirmPasswordUpdate(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-justify-content-center m-t-20 m-b-40">
          <button className="users-accounts__update-button primary-button" onClick={button}>
            Update Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileAccount;
