import * as React from "react"

const Py = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M94.764 238.557c-7.343.227-12.284 1.129-14.85 1.97v50.074c3.035.735 6.795.962 12.005.946 19.358-.098 31.493-10.687 31.493-28.411 0-15.933-10.444-25.13-28.648-24.579z" fill={color} />
    <path  d="M539.573 49.201h-178.2c-.791 0-1.561.074-2.342.124V0L11.228 46.419v494.562L359.031 588.6v-50.814c.781.053 1.551.115 2.342.115h178.2c20.841 0 37.8-16.959 37.8-37.8v-413.1c0-20.84-16.959-37.8-37.8-37.8zM145.347 303.244c-12.864 12.636-31.644 18.13-53.18 17.909-4.741-.053-8.971-.333-12.253-.876v60.65l-34.733-1.371V215.093c10.691-2.447 25.876-4.617 47.677-5.59 22.528-1.002 38.876 2.905 49.995 11.778 10.76 8.456 18.066 22.857 18.066 40.128 0 17.268-5.545 31.936-15.572 41.835zm117.645 7.804v77.034l-41.447-1.624v-74.261l-55.526-104.643 45.154-1.98 18.436 42.928c5.669 13.144 9.481 22.826 14.109 34.744l.551-.01c4.11-11.475 8.501-22.172 14.024-35.419l18.963-45.143 49.165-2.154-63.429 110.528zm292.781 189.053c0 8.934-7.268 16.2-16.2 16.2h-178.2c-.802 0-1.571-.116-2.342-.232V351.337c.232.011.443.063.676.079 5.115.312 10.262-.042 15.393.132 3.054.11 3.897-1.191 3.834-4.05-.147-6.328.053-12.678-.068-19.011-.127-6.128 1.767-11.597 5.089-16.585 7.245-10.879 17.761-15.361 30.518-15.361 16.764.01 33.522.29 50.271-.153 12.856-.348 22.792-11.069 23.003-23.946.137-8.301.021-16.611.026-24.912.005-8.308.053-16.608-.026-24.909-.021-1.927-.211-3.941-.781-5.777-3.533-11.53-12.107-17.972-23.351-19.343-13.41-1.637-27.058-2.004-40.573-1.761-7.394.134-14.908 2.102-22.069 4.239-9.94 2.975-13.833 8.709-14.218 19.024-.216 5.885.011 11.794-.332 17.663-.253 4.261 1.413 5.345 5.506 5.287 13.89-.222 27.78.021 41.67-.114 3.26-.032 4.066 1.181 4.066 4.24 0 4.195-2.854 3.541-5.311 3.552-21.737.066-43.49.422-65.222 0-2.863-.058-5.537.346-8.11.946V71.043c.77-.113 1.539-.242 2.341-.242h178.2c8.934 0 16.2 7.267 16.2 16.2v413.1h.01zM418.474 219.539c.138 5.632-4.566 10.367-10.268 10.367-5.521-.01-10.125-4.482-10.209-9.935-.09-5.569 4.725-10.547 10.209-10.547 5.194-.01 10.136 4.865 10.268 10.115z" fill={color} />
    <path  d="M537.242 265.027c-4.313-7.744-10.821-12.526-19.87-13.046-5.126-.298-10.272.053-15.398-.135-3.059-.108-3.902 1.205-3.834 4.05.143 6.339-.058 12.69.063 19.014.121 6.128-1.767 11.609-5.089 16.598-7.246 10.866-17.761 15.348-30.518 15.348-16.765 0-33.523-.279-50.271.164-12.856.342-22.792 11.068-23.003 23.936-.137 8.301-.026 16.611-.031 24.912-.006 8.306-.053 16.605.026 24.917.016 1.93.216 3.939.774 5.769 3.533 11.539 12.119 17.973 23.361 19.344 13.411 1.635 27.053 2.004 40.574 1.761 7.394-.137 14.908-2.099 22.069-4.239 9.94-2.975 13.832-8.712 14.212-19.027.222-5.885-.011-11.791.338-17.655.253-4.271-1.424-5.357-5.511-5.294-13.886.221-27.786-.011-41.671.126-3.265.021-4.066-1.191-4.066-4.239.006-4.209 2.858-3.555 5.316-3.555 21.736-.063 43.484-.422 65.222-.011 16.596.321 28.729-11.021 32.057-25.207 2.421-10.347 4.256-20.735 3.328-31.335-.974-11.102-2.509-22.216-8.078-32.196zm-68.375 108.474c5.526 0 10.125 4.473 10.209 9.925.084 5.569-4.725 10.547-10.209 10.558-5.2 0-10.136-4.867-10.263-10.125-.136-5.621 4.568-10.368 10.263-10.358z" fill={color} />
  </svg>
)

export default Py
