import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import Spinner from "../../Components/common/Spinner/Spinner";

import {
  RequestCheckNetworkConnection,
  RequestDownloadFile,
  RequestEnableClipboard,
  RequestFinishAnalysis,
  RequestIncreaseRuntime,
  RequestLiveUploadFile,
  RequestStartAnalysis,
  RequestStartMouseEmulation,
  RequestStopMouseEmulation,
  RequestTakeScreenshot,
} from "../../store/actions/analysisReportActions";
import { addNotification } from "../../store/actions/notifierActions";

const MAX_SIZE = 1024 * 1024 * 1024; // 1024 MB
const SPINNER_SIZE = { width: 22, height: 22 };

const RemoteConnectionActions = ({ buttonDisabled, getVm, fileID }) => {
  const [startedAnalysis, setStartedAnalysis] = useState(false);
  const [startedMouseEmulation, setStartedMouseEmulation] = useState(false);
  const [stoppedMouseEmulation, setStoppedMouseEmulation] = useState(false);
  const [enabledClipboard, setEnabledClipboard] = useState(false);

  const startAnalysisLoading = useSelector((state) => state.analysisReports.startAnalysisLoading);
  const startMouseEmulationLoading = useSelector(
    (state) => state.analysisReports.startMouseEmulationLoading,
  );
  const stopMouseEmulationLoading = useSelector(
    (state) => state.analysisReports.stopMouseEmulationLoading,
  );
  const enableClipboardLoading = useSelector(
    (state) => state.analysisReports.enableClipboardLoading,
  );
  const takeScreenshotLoading = useSelector((state) => state.analysisReports.takeScreenshotLoading);
  const downloadFileLoading = useSelector((state) => state.analysisReports.downloadFileLoading);
  const checkNetworkConnectionLoading = useSelector(
    (state) => state.analysisReports.checkNetworkConnectionLoading,
  );
  const increaseRuntimeLoading = useSelector(
    (state) => state.analysisReports.increaseRuntimeLoading,
  );
  const uploadFileLoading = useSelector((state) => state.analysisReports.liveUploadFileLoading);
  const finishAnalysisLoading = useSelector((state) => state.analysisReports.finishAnalysisLoading);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isFreeRun = location.state?.isFreeRun;

  useEffect(() => {
    const storedData = localStorage.getItem(fileID);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData["startAnalysis"]) setStartedAnalysis(true);
      if (parsedData["startMouseEmulation"]) setStartedMouseEmulation(true);
      if (parsedData["stopMouseEmulation"]) setStoppedMouseEmulation(true);
      if (parsedData["enableClipboard"]) setEnabledClipboard(true);
    }
  }, [
    startAnalysisLoading,
    startMouseEmulationLoading,
    stopMouseEmulationLoading,
    enableClipboardLoading,
  ]);

  const backToDashboardHandler = () =>
    history.replace({ pathname: "/dashboard", state: { fromRemoteConnection: true } });

  const startAnalysisHandler = () => dispatch(RequestStartAnalysis({ vm: getVm, fileID }));

  const startMouseEmulationHandler = () =>
    dispatch(RequestStartMouseEmulation({ vm: getVm, fileID }));

  const stopMouseEmulationHandler = () =>
    dispatch(RequestStopMouseEmulation({ vm: getVm, fileID }));

  const enableClipboardHandler = () => dispatch(RequestEnableClipboard({ vm: getVm, fileID }));

  const takeScreenshotHandler = () => dispatch(RequestTakeScreenshot(getVm));

  const downloadFileHandler = () => dispatch(RequestDownloadFile(getVm));

  const checkNetworkConnectionHandler = () => dispatch(RequestCheckNetworkConnection(getVm));

  const increaseRuntimeHandler = () => dispatch(RequestIncreaseRuntime(getVm));

  const finishAnalysisHandler = () => dispatch(RequestFinishAnalysis(getVm));

  const uploadFileHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (selectedFile.size > MAX_SIZE) {
        dispatch(
          addNotification({
            message: "File is too big.",
            options: { variant: "error" },
          }),
        );
      } else {
        dispatch(RequestLiveUploadFile({ file: selectedFile, vm: getVm }));
      }
    }
  };

  return (
    <div className="remote-connection__actions">
      {isFreeRun ? (
        <button
          className="button"
          onClick={startAnalysisHandler}
          disabled={buttonDisabled || startedAnalysis}>
          {startAnalysisLoading ? (
            <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
          ) : (
            "Start Analysis"
          )}
        </button>
      ) : null}
      {isFreeRun ? (
        <button
          className="button"
          onClick={startMouseEmulationHandler}
          disabled={buttonDisabled || startedMouseEmulation}>
          {startMouseEmulationLoading ? (
            <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
          ) : (
            "Start Mouse Emulation"
          )}
        </button>
      ) : (
        <button
          className="button"
          onClick={stopMouseEmulationHandler}
          disabled={buttonDisabled || stoppedMouseEmulation}>
          {stopMouseEmulationLoading ? (
            <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
          ) : (
            "Stop Mouse Emulation"
          )}
        </button>
      )}
      <button
        className="enable-clipboard button"
        onClick={enableClipboardHandler}
        disabled={buttonDisabled || enabledClipboard}>
        {enableClipboardLoading ? (
          <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
        ) : (
          "Enable Clipboard"
        )}
      </button>
      <button className="button" onClick={takeScreenshotHandler} disabled={buttonDisabled}>
        {takeScreenshotLoading ? (
          <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
        ) : (
          "Take Screenshot"
        )}
      </button>
      <button className="button" onClick={downloadFileHandler} disabled={buttonDisabled}>
        {downloadFileLoading ? (
          <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
        ) : (
          "Download File"
        )}
      </button>
      <button className="button" onClick={checkNetworkConnectionHandler} disabled={buttonDisabled}>
        {checkNetworkConnectionLoading ? (
          <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
        ) : (
          "Check Network Connection"
        )}
      </button>
      <button className="button" onClick={increaseRuntimeHandler} disabled={buttonDisabled}>
        {increaseRuntimeLoading ? (
          <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
        ) : (
          "Increase Runtime (40 sec)"
        )}
      </button>
      <label
        className={`button ${buttonDisabled ? "button--disabled" : ""}`}
        htmlFor="free-run-file">
        {uploadFileLoading ? (
          <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
        ) : (
          "Upload File"
        )}
      </label>
      <input
        type="file"
        id="free-run-file"
        disabled={buttonDisabled}
        style={{ display: "none" }}
        onChange={uploadFileHandler}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <button className="button" onClick={finishAnalysisHandler} disabled={buttonDisabled}>
        {finishAnalysisLoading ? (
          <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
        ) : (
          "Finish Analysis"
        )}
      </button>
      <button className="back-to-dashboard button" onClick={backToDashboardHandler}>
        <span>&larr;</span>Back to Dashboard
      </button>
    </div>
  );
};

export default RemoteConnectionActions;
