import React from "react";

const CloseIcon = ({ color = "FFFFFF", width = 18, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2426 4.75729C13.3833 4.89794 13.4623 5.08871 13.4623 5.28762C13.4623 5.48653 13.3833 5.6773 13.2426 5.81795L10.0607 8.99993L13.2426 12.1819C13.3833 12.3226 13.4623 12.5133 13.4623 12.7122C13.4623 12.9112 13.3833 13.1019 13.2426 13.2426C13.102 13.3832 12.9112 13.4622 12.7123 13.4622C12.5134 13.4622 12.3226 13.3832 12.182 13.2426L9 10.0606L5.81802 13.2426C5.67737 13.3832 5.4866 13.4622 5.28769 13.4622C5.08878 13.4622 4.89801 13.3832 4.75736 13.2426C4.61671 13.1019 4.53769 12.9112 4.53769 12.7122C4.53769 12.5133 4.61671 12.3226 4.75736 12.1819L7.93934 8.99993L4.75736 5.81795C4.61671 5.6773 4.53769 5.48653 4.53769 5.28762C4.53769 5.08871 4.61671 4.89794 4.75736 4.75729C4.89801 4.61664 5.08878 4.53762 5.28769 4.53762C5.4866 4.53762 5.67737 4.61664 5.81802 4.75729L9 7.93927L12.182 4.75729C12.3226 4.61664 12.5134 4.53762 12.7123 4.53762C12.9112 4.53762 13.102 4.61664 13.2426 4.75729Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
