import React from "react";

const SettingIcon = ({ color = "var(--text-color-card)", width = 15, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6486 9.61253L13.3174 8.84378C13.4517 8.11878 13.4517 7.37503 13.3174 6.65003L14.6486 5.88128C14.8017 5.79378 14.8705 5.61253 14.8205 5.44378C14.4736 4.33128 13.883 3.32503 13.1111 2.48753C12.9924 2.3594 12.7986 2.32815 12.6486 2.41565L11.3174 3.1844C10.758 2.70315 10.1142 2.33128 9.41737 2.08753V0.553154C9.41737 0.378154 9.2955 0.225029 9.12362 0.187529C7.97675 -0.0687207 6.80175 -0.0562207 5.71113 0.187529C5.53925 0.225029 5.41738 0.378154 5.41738 0.553154V2.09065C4.72363 2.33753 4.07988 2.7094 3.51738 3.18753L2.18925 2.41878C2.03613 2.33128 1.8455 2.3594 1.72675 2.49065C0.954878 3.32503 0.364254 4.33128 0.0173787 5.4469C-0.0357463 5.61565 0.0361287 5.7969 0.189254 5.8844L1.5205 6.65315C1.38613 7.37815 1.38613 8.1219 1.5205 8.8469L0.189254 9.61565C0.0361287 9.70315 -0.0326212 9.8844 0.0173787 10.0532C0.364254 11.1657 0.954878 12.1719 1.72675 13.0094C1.8455 13.1375 2.03925 13.1688 2.18925 13.0813L3.5205 12.3125C4.07988 12.7938 4.72363 13.1657 5.4205 13.4094V14.9469C5.4205 15.1219 5.54238 15.275 5.71425 15.3125C6.86113 15.5688 8.03612 15.5563 9.12675 15.3125C9.29862 15.275 9.4205 15.1219 9.4205 14.9469V13.4094C10.1142 13.1625 10.758 12.7907 11.3205 12.3125L12.6517 13.0813C12.8049 13.1688 12.9955 13.1407 13.1142 13.0094C13.8861 12.175 14.4767 11.1688 14.8236 10.0532C14.8705 9.88128 14.8017 9.70003 14.6486 9.61253ZM7.41738 10.2469C6.03925 10.2469 4.91738 9.12503 4.91738 7.7469C4.91738 6.36878 6.03925 5.2469 7.41738 5.2469C8.7955 5.2469 9.91737 6.36878 9.91737 7.7469C9.91737 9.12503 8.7955 10.2469 7.41738 10.2469Z"
        fill={color || "var(--text-color-card)"}
      />
    </svg>
  );
};

export default SettingIcon;
