import analysisReportsSlice from "../slices/analysisReportsSlice";

export const {
  RequestGeneralAnalysis,
  SuccessGeneralAnalysis,
  FailureGeneralAnalysis,
  RequestFilesInfo,
  SuccessFilesInfo,
  FailureFilesInfo,
  RequestGetVm,
  SuccessGetVm,
  FailureGetVm,
  RequestAttackMapTree,
  SuccessAttackMapTree,
  FailureAttackMapTree,
  RequestMitreAttack,
  SuccessMitreAttack,
  FailureMitreAttack,
  RequestAddComment,
  SuccessAddComment,
  FailureAddComment,
  RequestGetComment,
  SuccessGetComment,
  FailureGetComment,
  RequestGetSignatures,
  SuccessGetSignatures,
  FailureGetSignatures,
  RequestP2signatureList,
  SuccessP2signatureList,
  FailureP2signatureList,
  RequestDeleteComment,
  SuccessDeleteComment,
  FailureDeleteComment,
  RequestEditComment,
  SuccessEditComment,
  FailureEditComment,
  RequestStaticInfo,
  SuccessStaticInfo,
  FailureStaticInfo,
  RequestChatGPT,
  SuccessChatGPT,
  FailureChatGPT,
  RequestFileDetails,
  SuccessFileDetails,
  FailureFileDetails,
  RequestExtractedStrings,
  SuccessExtractedStrings,
  FailureExtractedStrings,
  RequestAttackMapCalls,
  SuccessAttackMapCalls,
  FailureAttackMapCalls,
  RequestAttackMapCallsByCategory,
  SuccessAttackMapCallsByCategory,
  FailureAttackMapCallsByCategory,
  RequestAttackMapSummary,
  SuccessAttackMapSummary,
  FailureAttackMapSummary,
  RequestAttackMapEnhanced,
  SuccessAttackMapEnhanced,
  FailureAttackMapEnhanced,
  RequestStopMouseEmulation,
  SuccessStopMouseEmulation,
  FailureStopMouseEmulation,
  RequestLiveUploadFile,
  SuccessLiveUploadFile,
  FailureLiveUploadFile,
  RequestFinishAnalysis,
  SuccessFinishAnalysis,
  FailureFinishAnalysis,
  RequestMemoryAddressMapping,
  SuccessMemoryAddressMapping,
  FailureMemoryAddressMapping,
  RequestReadyStatus,
  SuccessReadyStatus,
  ClearReadyStatus,
  RequestStartAnalysis,
  SuccessStartAnalysis,
  FailureStartAnalysis,
  RequestStartMouseEmulation,
  SuccessStartMouseEmulation,
  FailureStartMouseEmulation,
  RequestTakeScreenshot,
  SuccessTakeScreenshot,
  FailureTakeScreenshot,
  RequestDownloadFile,
  SuccessDownloadFile,
  FailureDownloadFile,
  RequestCheckNetworkConnection,
  SuccessCheckNetworkConnection,
  FailureCheckNetworkConnection,
  RequestEnableClipboard,
  SuccessEnableClipboard,
  FailureEnableClipboard,
  RequestIncreaseRuntime,
  SuccessIncreaseRuntime,
  FailureIncreaseRuntime,
  RequestGetHtmlReport,
  SuccessGetHtmlReport,
  FailureGetHtmlReport,
  RequestArtifactSubmission,
  SuccessArtifactSubmission,
  FailureArtifactSubmission,
  RequestGetReportLogo,
  SuccessGetReportLogo,
  FailureGetReportLogo,
  RequestSetReportLogo,
  SuccessSetReportLogo,
  FailureSetReportLogo,
  RequestNetwork,
  SuccessNetwork,
  FailureNetwork,
  RequestExtractedPayloads,
  SuccessExtractedPayloads,
  FailureExtractedPayloads,
  RequestDroppedFiles,
  SuccessDroppedFiles,
  FailureDroppedFiles,
  RequestMemoryDumps,
  SuccessMemoryDumps,
  FailureMemoryDumps,
  RequestPcapFile,
  SuccessPcapFile,
  FailurePcapFile,
  RequestIocUrls,
  SuccessIocUrls,
  FailureIocUrls,
  RequestIocDomainsAndIps,
  SuccessIocDomainsAndIps,
  FailureIocDomainsAndIps,
  convertNewSystemRequest,
  convertNewSystemSuccess,
  convertNewSystemFailure,
  RequestStaticFindings,
  SuccessStaticFindings,
  FailureStaticFindings,
  RequestHostsAndDomains,
  SuccessHostsAndDomains,
  FailureHostsAndDomains,
  RequestScreenshots,
  SuccessScreenshots,
  FailureScreenshots,
  RequestGetPreStaticResults,
  SuccessGetPreStaticResults,
  FailureGetPreStaticResults,
  ResetPreStaticResults,
} = analysisReportsSlice.actions;
