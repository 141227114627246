const PlayIcon = ({ width = 24, height = 24, color = "var(--header-search-text-color)" }) => {
  return (
    <svg
      fill={color}
      height={height}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg">
      <polygon points="5 3 19 12 5 21 5 3" />
    </svg>
  );
};

export default PlayIcon;
