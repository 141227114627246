import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import {
  RequestSetConfig,
  RequestDeleteConfig,
  RequestUpdateConfig,
} from "../../../../store/actions/integrationsActions";
import { useLocation } from "react-router-dom";
import Input from "../../../common/SubmitPopup/Input/Input";
import EyeCloseIcon from "../../../Icon-SVG/EyeCloseIcon";
import EyeIcon from "../../../Icon-SVG/EyeIcon";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import BackArrowIcon from "../../../Icon-SVG/BackArrowIcon";
import BackArrowAfterIcon from "../../../Icon-SVG/BackArrowAfterIcon";
import Info from "../../../UI/Info/Info";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const WinshareSettings = ({ show, setShow, dataTmp = 0, setActiveInt }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [ip, setIP] = useState("");
  const [path, setPath] = useState("");
  const [port, setPort] = useState("40445");
  const [type, setType] = useState("cifs");
  const [confType, setConfType] = useState(false);
  const [hiddenPw, setHiddenPw] = useState(true);

  const [ipError, setIPError] = useState(false);
  const [pathError, setPathError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const getConfig = useSelector((state) => state.integrations.getConfig);
  const setConfig = useSelector((state) => state.integrations.setConfig);
  const deleteConfig = useSelector((state) => state.integrations.deleteConfig);
  const loadingUpdate = useSelector((state) => state.integrations.loadingUpdate);
  const loading = useSelector((state) => state.integrations.loading);
  const [hoverBack, setHoverBack] = useState(false);
  let confTmp = parseInt(dataTmp);

  const closePopupHandler = () => {
    cleanData();
    setShow(false);
    setActiveInt("");
    history.push("/integration-market");
  };

  const cleanData = () => {
    setIPError(false);
    setPathError(false);
    setUsernameError(false);
    setPasswordError(false);
    setUsername("");
    setPassword("");
    setType("cifs");
    setIP("");
    setPath("");
    setPort("40445");
  };

  useEffect(() => {
    if (query.get("t") === "add") {
      setConfType(true);
    } else if (query.get("t") === null) {
      setHoverBack(false);
      cleanData();
    } else {
      setConfType(false);
    }
  }, [query]);

  useEffect(() => {
    if (deleteConfig === "Success" && query.get("conf") === "winshare" && !loadingUpdate) {
      cleanData();
    }
  }, [deleteConfig]);

  useEffect(() => {
    if (setConfig === "Success" && query.get("conf") === "winshare" && !loadingUpdate) {
      cleanData();
    }
  }, [setConfig]);

  useEffect(() => {
    if (query.get("conf") === "winshare") {
      if (getConfig) {
        const configData = getConfig.config[confTmp];
        if (configData) {
          const mountparams = configData.mountparams.split(",");
          const usernameValue = mountparams.reduce((obj, item) => {
            const [key, value] = item.split("=");
            if (key === "username") {
              return value;
            }
            return obj;
          }, "");
          const passwordValue = mountparams.reduce((obj, item) => {
            const [key, value] = item.split("=");
            if (key === "password") {
              return value;
            }
            return obj;
          }, "");
          const typeValue = configData.mounttype;
          const ipValue = configData.mountpoint.match(/\/\/([^\/]+)(\/.*)/)[1];
          const pathValue = configData.mountpoint.match(/\/\/([^\/]+)(\/.*)/)[2];
          const portValue = mountparams.reduce((obj, item) => {
            const [key, value] = item.split("=");
            if (key === "port") {
              return value;
            }
            return obj;
          }, "40445");

          setUsername(usernameValue);
          setPassword(passwordValue);
          setType(typeValue);
          setIP(ipValue);
          setPath(pathValue);
          setPort(portValue);
        }
      }
    }
  }, [getConfig, confTmp]);

  const addConfig = () => {
    if (isValidInput()) {
      const config = {
        bulk: 16,
        mounttype: type,
        mountparams: `vers=3.1.1,uid=1000,gid=1000,rw,username=${username},password=${password},port=${port}`,
        mountpoint: `//${ip}${path}`,
      };
      const configString = JSON.stringify(config);
      const data = { appname: "winshare", config: configString };
      dispatch(RequestSetConfig(data));
    } else {
      console.log("Geçersiz giriş!");
    }
  };

  const updateConfigData = () => {
    if (isValidInput()) {
      const config = {
        bulk: 16,
        mounttype: type,
        mountparams: `vers=3.1.1,uid=1000,gid=1000,rw,username=${username},password=${password},port=${port}`,
        mountpoint: `//${ip}${path}`,
      };
      const configString = JSON.stringify(config);
      const data = {
        appname: "winshare",
        config: configString,
        mountpoint: getConfig?.config[confTmp]?.mountpoint,
      };
      dispatch(RequestUpdateConfig(data));
    } else {
      console.log("Geçersiz giriş!");
    }
  };

  const delConfig = () => {
    dispatch(
      RequestDeleteConfig({
        inttype: "winshare",
        mountpoint: getConfig?.config[confTmp]?.mountpoint,
      }),
    );
  };

  const isValidInput = () => {
    const ipPattern = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
    const pathPattern = /^\/.*$/;

    if (ip.match(ipPattern) && path.match(pathPattern) && username && password && port && type) {
      return true;
    } else {
      if (!ip.match(ipPattern)) {
        setIPError(true);
      } else {
        setIPError(false);
      }
      if (!path.match(pathPattern)) {
        setPathError(true);
      } else {
        setPathError(false);
      }
      if (!username) {
        setUsernameError(true);
      } else {
        setUsernameError(false);
      }
      if (!password) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
      return false;
    }
  };

  return (
    <div className={`${show && "winshare-settings-backdrop"}`} onClick={closePopupHandler}>
      <div onClick={(e) => e.stopPropagation()}>
        {show ? (
          <div className="winshare-settings border">
            <div className="winshare-settings-buttons">
              <div
                onMouseEnter={() => setHoverBack(true)}
                onMouseLeave={() => setHoverBack(false)}
                onClick={() => {
                  history.push("/integration-market?bck=" + query.get("conf"));
                }}>
                <div className="integration-config-buttons-back">
                  {!hoverBack ? (
                    <BackArrowIcon color="var(--dark-theme-element)" width={20} height={20} />
                  ) : (
                    <BackArrowAfterIcon color="var(--dark-theme-element)" width={20} height={20} />
                  )}
                </div>
              </div>
              <div className="winshare-settings-close-button__outer">
                <div onClick={closePopupHandler} className="winshare-settings-close-button__inner">
                  <label className="winshare-settings-close-button__close">Close</label>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-align-items-center">
                <div className="flex-column m-r-10">
                  <p className={usernameError ? "label-error" : "label"}>Username*</p>
                  <Input
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    placeholder="Enter Username"
                  />
                  {usernameError && (
                    <div className="error-message">Please enter a valid value.</div>
                  )}
                </div>
                <div className="flex-column m-l-10 relative">
                  <p className={passwordError ? "label-error" : "label"}>Password*</p>
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Enter Password"
                    type={hiddenPw ? "password" : "text"}
                  />
                  {hiddenPw ? (
                    <span className="eye_pw" onClick={() => setHiddenPw(!hiddenPw)}>
                      <EyeIcon />
                    </span>
                  ) : (
                    !hiddenPw && (
                      <span className="eye_pw" onClick={() => setHiddenPw(!hiddenPw)}>
                        <EyeCloseIcon />
                      </span>
                    )
                  )}
                  {passwordError && (
                    <div className="error-message">Please enter a valid value.</div>
                  )}
                </div>
                <div className="flex-column m-l-10 relative">
                  <p className="label">Type</p>
                  <select
                    id="standard-select"
                    selected="default"
                    value={type}
                    onChange={(e) => setType(e.target.value)}>
                    <option value="cifs">CIFS</option>
                    <option value="nfs">NFS</option>
                    <option value="webdaw">Webdaw</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-align-items-center">
                <div className="flex-column m-r-10">
                  <p className={ipError ? "label-error" : "label"}>
                    IP*
                    <Info message="Please enter a valid IP address (e.g., 192.168.1.1)" />
                  </p>
                  <Input
                    onChange={(e) => setIP(e.target.value)}
                    value={ip}
                    placeholder="Enter IP"
                  />
                  {ipError && <div className="error-message">Please enter a valid value.</div>}
                </div>
                <div className="flex-column m-l-10">
                  <p className={pathError ? "label-error" : "label"}>
                    Path*
                    <Info message="Please enter a valid path starting with a forward slash '/' (e.g., /file/path)." />
                  </p>
                  <Input
                    onChange={(e) => setPath(e.target.value)}
                    value={path}
                    placeholder="Enter path"
                  />
                  {pathError && <div className="error-message">Please enter a valid value.</div>}
                </div>
                <div className="flex-column m-l-10">
                  <p className="label">Port</p>
                  <Input
                    onChange={(e) => setPort(e.target.value)}
                    value={port}
                    placeholder="Enter port"
                  />
                </div>
              </div>
              {confType ? (
                <div>
                  <button onClick={() => addConfig()} className="submit-button">
                    Add Config
                  </button>
                </div>
              ) : (
                <div>
                  <button onClick={() => updateConfigData()} className="submit-button">
                    Update
                  </button>
                  <button className="delete-button" onClick={() => delConfig()}>
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {(loading || loadingUpdate) && query.get("conf") === "winshare" && (
        <Backdrop absolute={true} show={loading || loadingUpdate} message="Please waiting.." />
      )}
    </div>
  );
};

export default WinshareSettings;
