import * as React from "react"

const Icns = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="M11.227 46.419V540.98l347.805 47.62v-50.814c.78.053 1.55.116 2.341.116h178.2c20.841 0 37.8-16.96 37.8-37.8V87.001c0-20.841-16.959-37.8-37.8-37.8h-178.2c-.791 0-1.561.074-2.341.124V0L11.227 46.419zM359.032 208.62h70.943v111.963h-6.544c-6.233-15.778-13.864-32.313-24.543-29.587-9.998 2.555-16.653 18.865-20.028 29.587h-4.915c-2.531-9.081-7.33-23.192-14.913-35.949V208.62zM49.106 345.362l-19.061-.859V250.09l19.061-.609v95.881zm63.461-14.475c6.465.201 13.711-1.081 18.012-2.763l3.309 18.183c-4.029 1.94-13.015 3.802-24.516 3.27-31.632-1.466-47.342-23.045-47.342-50.636 0-33.064 21.558-52.175 49.434-53.093 11.116-.364 19.688 1.77 23.585 3.773l-4.477 18.478c-4.438-1.901-10.549-3.596-18.138-3.462-16.701.319-29.381 11.232-29.381 33.154-.002 19.733 10.782 32.5 29.514 33.096zm123.678 22.929-26.309-1.182-22.858-42.13c-6.223-11.427-13.004-25.093-18.043-37.394l-.458.155c.612 13.867.912 28.69.912 45.86v31.667l-20.983-.949V246.312l26.794-.854 21.842 38.62c6.373 11.335 12.793 24.862 17.664 37.181l.48.005c-1.624-14.375-2.109-29.024-2.109-45.291v-31.72l23.068-.738v110.301zm51.558 4.029c-13.711-.633-27.011-4.588-33.565-8.363l5.345-20.725c7.13 3.702 18.22 7.562 29.84 7.941 12.72.401 19.532-4.361 19.532-11.918 0-7.235-5.896-11.464-20.611-16.622-19.883-6.76-32.558-17.157-32.558-33.417 0-19.071 16.719-34.214 45.265-35.168 14.054-.464 24.548 1.959 32.152 4.865l-6.46 21.589c-5.1-2.17-14.075-5.292-26.238-5.07-11.976.232-17.711 5.41-17.711 11.327 0 7.259 6.813 10.439 22.66 16.039 22.232 7.652 32.933 18.565 32.933 35.219-.001 19.812-16.554 35.885-50.584 34.303zm73.57-287.044h178.2c8.934 0 16.2 7.267 16.2 16.2v413.101c0 8.933-7.267 16.199-16.2 16.199h-178.2c-.801 0-1.571-.115-2.341-.231V392.634l153.984 31.287 33.713-165.913-94.732-19.258v16.73l75.416 15.314-22.291 109.727-56.188-11.422h-89.901v-7.552h87.328V192.23h-87.328V71.044c.768-.113 1.539-.243 2.34-.243z" fill={color} />
    <path  d="M392.75 257.829c2.584 1.107 5.717 1.771 9.129 1.771 8.717 0 15.783-4.261 15.783-9.526s-7.066-9.526-15.783-9.526c-3.275 0-6.308.601-8.844 1.638a5.604 5.604 0 0 0-3.966-1.638h-2.283c-2.927 0-5.247 2.226-5.579 5.065-7.082.73-12.313 3.277-12.313 6.341 0 3.649 7.377 6.603 16.484 6.603a36.96 36.96 0 0 0 7.372-.728zM460.756 330.106l13.004 2.933-15.825-30.354 13.447 3.038-13.02-24.965 8.274 1.877-10.004-19.185-4.635 3.85v89.891l25.929 5.854z" fill={color} />
  </svg>
)

export default Icns
