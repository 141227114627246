const ReplyIcon = ({ color = "var(--color-blue)", width = 14, height = 14 }) => {
  return (
    <svg
      width={width}
      height={width}
      fill={color}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g>
        <path d="M448,400c0,0-36.8-208-224-208v-80L64,256l160,134.4v-92.3C325.6,298.1,395,307,448,400z" />
      </g>
    </svg>
  );
};

export default ReplyIcon;
