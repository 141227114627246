import React from "react";
import ExeIcon from "../../Icon-SVG/FileIconSet/ExeIcon";
import _3dsIcon from "../../Icon-SVG/FileIconSet/_3dsIcon";
import _3g2Icon from "../../Icon-SVG/FileIconSet/_3g2Icon";
import _3gpIcon from "../../Icon-SVG/FileIconSet/_3gpIcon";
import AccIcon from "../../Icon-SVG/FileIconSet/AccIcon";
import ApkIcon from "../../Icon-SVG/FileIconSet/ApkIcon";
import AppIcon from "../../Icon-SVG/FileIconSet/AppIcon";
import AspIcon from "../../Icon-SVG/FileIconSet/AspIcon";
import AspxIcon from "../../Icon-SVG/FileIconSet/AspxIcon";
import AsxIcon from "../../Icon-SVG/FileIconSet/AsxIcon";
import AvIcon from "../../Icon-SVG/FileIconSet/AvIcon";
import CadIcon from "../../Icon-SVG/FileIconSet/CadIcon";
import CdrIcon from "../../Icon-SVG/FileIconSet/CdrIcon";
import CfgIcon from "../../Icon-SVG/FileIconSet/CfgIcon";
import CgiIcon from "../../Icon-SVG/FileIconSet/CgiIcon";
import ClassIcon from "../../Icon-SVG/FileIconSet/ClassIcon";
import CplIcon from "../../Icon-SVG/FileIconSet/CplIcon";
import CppIcon from "../../Icon-SVG/FileIconSet/CppIcon";
import CrxIcon from "../../Icon-SVG/FileIconSet/CrxIcon";
import CsrIcon from "../../Icon-SVG/FileIconSet/CsrIcon";
import CssIcon from "../../Icon-SVG/FileIconSet/CssIcon";
import CsvIcon from "../../Icon-SVG/FileIconSet/CsvIcon";
import CurIcon from "../../Icon-SVG/FileIconSet/CurIcon";
import DatIcon from "../../Icon-SVG/FileIconSet/DatIcon";
import DbIcon from "../../Icon-SVG/FileIconSet/DbIcon";
import DemIcon from "../../Icon-SVG/FileIconSet/DemIcon";
import DllIcon from "../../Icon-SVG/FileIconSet/DllIcon";
import DmgIcon from "../../Icon-SVG/FileIconSet/DmgIcon";
import DmpIcon from "../../Icon-SVG/FileIconSet/DmpIcon";
import DocIcon from "../../Icon-SVG/FileIconSet/DocIcon";
import DrvIcon from "../../Icon-SVG/FileIconSet/DrvIcon";
import ElfIcon from "../../Icon-SVG/FileIconSet/ElfIcon";
import EpsIcon from "../../Icon-SVG/FileIconSet/EpsIcon";
import FlaIcon from "../../Icon-SVG/FileIconSet/FlaIcon";
import FlvIcon from "../../Icon-SVG/FileIconSet/FlvIcon";
import FntIcon from "../../Icon-SVG/FileIconSet/FntIcon";
import FonIcon from "../../Icon-SVG/FileIconSet/FonIcon";
import GamIcon from "../../Icon-SVG/FileIconSet/GamIcon";
import GedIcon from "../../Icon-SVG/FileIconSet/GedIcon";
import GzIcon from "../../Icon-SVG/FileIconSet/GzIcon";
import IcnsIcon from "../../Icon-SVG/FileIconSet/IcnsIcon";
import IffIcon from "../../Icon-SVG/FileIconSet/IffIcon";
import IsoIcon from "../../Icon-SVG/FileIconSet/IsoIcon";
import JspIcon from "../../Icon-SVG/FileIconSet/JspIcon";
import KmzIcon from "../../Icon-SVG/FileIconSet/KmzIcon";
import LuaIcon from "../../Icon-SVG/FileIconSet/LuaIcon";
import M3uIcon from "../../Icon-SVG/FileIconSet/M3uIcon";
import M4vIcon from "../../Icon-SVG/FileIconSet/M4vIcon";
import MachIcon from "../../Icon-SVG/FileIconSet/MachIcon";
import MaxIcon from "../../Icon-SVG/FileIconSet/MaxIcon";
import MdbIcon from "../../Icon-SVG/FileIconSet/MdbIcon";
import MdfIcon from "../../Icon-SVG/FileIconSet/MdfIcon";
import MimIcon from "../../Icon-SVG/FileIconSet/MimIcon";
import Mp3Icon from "../../Icon-SVG/FileIconSet/Mp3Icon";
import Mp4Icon from "../../Icon-SVG/FileIconSet/Mp4Icon";
import MpaIcon from "../../Icon-SVG/FileIconSet/MpaIcon";
import MsgIcon from "../../Icon-SVG/FileIconSet/MsgIcon";
import MsiIcon from "../../Icon-SVG/FileIconSet/MsiIcon";
import NesIcon from "../../Icon-SVG/FileIconSet/NesIcon";
import ObjIcon from "../../Icon-SVG/FileIconSet/ObjIcon";
import OdcIcon from "../../Icon-SVG/FileIconSet/OdcIcon";
import OdgIcon from "../../Icon-SVG/FileIconSet/OdgIcon";
import OdiIcon from "../../Icon-SVG/FileIconSet/OdiIcon";
import OdpIcon from "../../Icon-SVG/FileIconSet/OdpIcon";
import OdsIcon from "../../Icon-SVG/FileIconSet/OdsIcon";
import OdtIcon from "../../Icon-SVG/FileIconSet/OdtIcon";
import OdxIcon from "../../Icon-SVG/FileIconSet/OdxIcon";
import OggIcon from "../../Icon-SVG/FileIconSet/OggIcon";
import OtfIcon from "../../Icon-SVG/FileIconSet/OtfIcon";
import PctIcon from "../../Icon-SVG/FileIconSet/PctIcon";
import PifIcon from "../../Icon-SVG/FileIconSet/PifIcon";
import PkgIcon from "../../Icon-SVG/FileIconSet/PkgIcon";
import PlIcon from "../../Icon-SVG/FileIconSet/PlIcon";
import PptIcon from "../../Icon-SVG/FileIconSet/PptIcon";
import PptxIcon from "../../Icon-SVG/FileIconSet/PptxIcon";
import PsIcon from "../../Icon-SVG/FileIconSet/PsIcon";
import PubIcon from "../../Icon-SVG/FileIconSet/PubIcon";
import PyIcon from "../../Icon-SVG/FileIconSet/PyIcon";
import RarIcon from "../../Icon-SVG/FileIconSet/RarIcon";
import RawIcon from "../../Icon-SVG/FileIconSet/RawIcon";
import RmIcon from "../../Icon-SVG/FileIconSet/RmIcon";
import RpmIcon from "../../Icon-SVG/FileIconSet/RpmIcon";
import RssIcon from "../../Icon-SVG/FileIconSet/RssIcon";
import SavIcon from "../../Icon-SVG/FileIconSet/SavIcon";
import SitxIcon from "../../Icon-SVG/FileIconSet/SitxIcon";
import SqlIcon from "../../Icon-SVG/FileIconSet/SqlIcon";
import SrtIcon from "../../Icon-SVG/FileIconSet/SrtIcon";
import SvgIcon from "../../Icon-SVG/FileIconSet/SvgIcon";
import SwfIcon from "../../Icon-SVG/FileIconSet/SwfIcon";
import TarIcon from "../../Icon-SVG/FileIconSet/TarIcon";
import TexIcon from "../../Icon-SVG/FileIconSet/TexIcon";
import TgaIcon from "../../Icon-SVG/FileIconSet/TgaIcon";
import ThmIcon from "../../Icon-SVG/FileIconSet/ThmIcon";
import TorrentIcon from "../../Icon-SVG/FileIconSet/TorrentIcon";
import TtfIcon from "../../Icon-SVG/FileIconSet/TtfIcon";
import UueIcon from "../../Icon-SVG/FileIconSet/UueIcon";
import VbIcon from "../../Icon-SVG/FileIconSet/VbIcon";
import VcdIcon from "../../Icon-SVG/FileIconSet/VcdIcon";
import VcfIcon from "../../Icon-SVG/FileIconSet/VcfIcon";
import VobIcon from "../../Icon-SVG/FileIconSet/VobIcon";
import WavIcon from "../../Icon-SVG/FileIconSet/WavIcon";
import WmaIcon from "../../Icon-SVG/FileIconSet/WmaIcon";
import WmvIcon from "../../Icon-SVG/FileIconSet/WmvIcon";
import WpsIcon from "../../Icon-SVG/FileIconSet/WpsIcon";
import WsfIcon from "../../Icon-SVG/FileIconSet/WsfIcon";
import XhtmlIcon from "../../Icon-SVG/FileIconSet/XhtmlIcon";
import XlrIcon from "../../Icon-SVG/FileIconSet/XlrIcon";
import XlsxIcon from "../../Icon-SVG/FileIconSet/XlsxIcon";
import YuvIcon from "../../Icon-SVG/FileIconSet/YuvIcon";
import ZipIcon from "../../Icon-SVG/FileIconSet/ZipIcon";
import OtherICon from "../../Icon-SVG/FileIconSet/OtherIcon";

const FileFormatIconSet = ({ width = "23", height = "23", color = "var(--border)", extension = "" }) => {
  const setIcon = () => {
    switch (extension) {
      case "exe":
        return <ExeIcon width={width + 6} height={height + 6} color={color} />;
      case "3ds":
        return <_3dsIcon width={width} height={height} color={color} />;
      case "3g2":
        return <_3g2Icon width={width} height={height} color={color} />;
      case "3gp":
        return <_3gpIcon width={width} height={height} color={color} />;
      case "acc":
        return <AccIcon width={width} height={height} color={color} />;
      case "apk":
        return <ApkIcon width={width} height={height} color={color} />;
      case "app":
        return <AppIcon width={width} height={height} color={color} />;
      case "asp":
        return <AspIcon width={width} height={height} color={color} />;
      case "aspx":
        return <AspxIcon width={width} height={height} color={color} />;
      case "asx":
        return <AsxIcon width={width} height={height} color={color} />;
      case "av":
        return <AvIcon width={width} height={height} color={color} />;
      case "cad":
        return <CadIcon width={width} height={height} color={color} />;
      case "cdr":
        return <CdrIcon width={width} height={height} color={color} />;
      case "cfg":
        return <CfgIcon width={width} height={height} color={color} />;
      case "cgi":
        return <CgiIcon width={width} height={height} color={color} />;
      case "class":
        return <ClassIcon width={width} height={height} color={color} />;
      case "cpl":
        return <CplIcon width={width} height={height} color={color} />;
      case "cpp":
        return <CppIcon width={width} height={height} color={color} />;
      case "crx":
        return <CrxIcon width={width} height={height} color={color} />;
      case "csr":
        return <CsrIcon width={width} height={height} color={color} />;
      case "css":
        return <CssIcon width={width} height={height} color={color} />;
      case "csv":
        return <CsvIcon width={width} height={height} color={color} />;
      case "cur":
        return <CurIcon width={width} height={height} color={color} />;
      case "dat":
        return <DatIcon width={width} height={height} color={color} />;
      case "db":
        return <DbIcon width={width} height={height} color={color} />;
      case "dem":
        return <DemIcon width={width} height={height} color={color} />;
      case "dll":
        return <DllIcon width={width} height={height} color={color} />;
      case "dmg":
        return <DmgIcon width={width} height={height} color={color} />;
      case "dmp":
        return <DmpIcon width={width} height={height} color={color} />;
      case "doc":
        return <DocIcon width={width} height={height} color={color} />;
      case "drv":
        return <DrvIcon width={width} height={height} color={color} />;
      case "elf":
        return <ElfIcon width={width} height={height} color={color} />;
      case "eps":
        return <EpsIcon width={width} height={height} color={color} />;
      case "fla":
        return <FlaIcon width={width} height={height} color={color} />;
      case "flv":
        return <FlvIcon width={width} height={height} color={color} />;
      case "fnt":
        return <FntIcon width={width} height={height} color={color} />;
      case "fon":
        return <FonIcon width={width} height={height} color={color} />;
      case "gam":
        return <GamIcon width={width} height={height} color={color} />;
      case "ged":
        return <GedIcon width={width} height={height} color={color} />;
      case "gz":
        return <GzIcon width={width} height={height} color={color} />;
      case "icns":
        return <IcnsIcon width={width} height={height} color={color} />;
      case "iff":
        return <IffIcon width={width} height={height} color={color} />;
      case "iso":
        return <IsoIcon width={width} height={height} color={color} />;
      case "jsp":
        return <JspIcon width={width} height={height} color={color} />;
      case "kmz":
        return <KmzIcon width={width} height={height} color={color} />;
      case "lua":
        return <LuaIcon width={width} height={height} color={color} />;
      case "m3u":
        return <M3uIcon width={width} height={height} color={color} />;
      case "m4v":
        return <M4vIcon width={width} height={height} color={color} />;
      case "mach":
        return <MachIcon width={width} height={height} color={color} />;
      case "max":
        return <MaxIcon width={width} height={height} color={color} />;
      case "mdb":
        return <MdbIcon width={width} height={height} color={color} />;
      case "mdf":
        return <MdfIcon width={width} height={height} color={color} />;
      case "mim":
        return <MimIcon width={width} height={height} color={color} />;
      case "mp3":
        return <Mp3Icon width={width} height={height} color={color} />;
      case "mp4":
        return <Mp4Icon width={width} height={height} color={color} />;
      case "mpa":
        return <MpaIcon width={width} height={height} color={color} />;
      case "msg":
        return <MsgIcon width={width} height={height} color={color} />;
      case "msi":
        return <MsiIcon width={width} height={height} color={color} />;
      case "nes":
        return <NesIcon width={width} height={height} color={color} />;
      case "obj":
        return <ObjIcon width={width} height={height} color={color} />;
      case "odc":
        return <OdcIcon width={width} height={height} color={color} />;
      case "odg":
        return <OdgIcon width={width} height={height} color={color} />;
      case "odi":
        return <OdiIcon width={width} height={height} color={color} />;
      case "odp":
        return <OdpIcon width={width} height={height} color={color} />;
      case "ods":
        return <OdsIcon width={width} height={height} color={color} />;
      case "odt":
        return <OdtIcon width={width} height={height} color={color} />;
      case "odx":
        return <OdxIcon width={width} height={height} color={color} />;
      case "ogg":
        return <OggIcon width={width} height={height} color={color} />;
      case "otf":
        return <OtfIcon width={width} height={height} color={color} />;
      case "pct":
        return <PctIcon width={width} height={height} color={color} />;
      case "pif":
        return <PifIcon width={width} height={height} color={color} />;
      case "pkg":
        return <PkgIcon width={width} height={height} color={color} />;
      case "pl":
        return <PlIcon width={width} height={height} color={color} />;
      case "ppt":
        return <PptIcon width={width} height={height} color={color} />;
      case "pptx":
        return <PptxIcon width={width} height={height} color={color} />;
      case "ps":
        return <PsIcon width={width} height={height} color={color} />;
      case "pub":
        return <PubIcon width={width} height={height} color={color} />;
      case "py":
        return <PyIcon width={width} height={height} color={color} />;
      case "rar":
        return <RarIcon width={width} height={height} color={color} />;
      case "raw":
        return <RawIcon width={width} height={height} color={color} />;
      case "rm":
        return <RmIcon width={width} height={height} color={color} />;
      case "rpm":
        return <RpmIcon width={width} height={height} color={color} />;
      case "rss":
        return <RssIcon width={width} height={height} color={color} />;
      case "sav":
        return <SavIcon width={width} height={height} color={color} />;
      case "sitx":
        return <SitxIcon width={width} height={height} color={color} />;
      case "sql":
        return <SqlIcon width={width} height={height} color={color} />;
      case "srt":
        return <SrtIcon width={width} height={height} color={color} />;
      case "svg":
        return <SvgIcon width={width} height={height} color={color} />;
      case "swf":
        return <SwfIcon width={width} height={height} color={color} />;
      case "tar":
        return <TarIcon width={width} height={height} color={color} />;
      case "tex":
        return <TexIcon width={width} height={height} color={color} />;
      case "tga":
        return <TgaIcon width={width} height={height} color={color} />;
      case "thm":
        return <ThmIcon width={width} height={height} color={color} />;
      case "torrent":
        return <TorrentIcon width={width} height={height} color={color} />;
      case "ttf":
        return <TtfIcon width={width} height={height} color={color} />;
      case "uue":
        return <UueIcon width={width} height={height} color={color} />;
      case "vb":
        return <VbIcon width={width} height={height} color={color} />;
      case "vcd":
        return <VcdIcon width={width} height={height} color={color} />;
      case "vcf":
        return <VcfIcon width={width} height={height} color={color} />;
      case "vob":
        return <VobIcon width={width} height={height} color={color} />;
      case "wav":
        return <WavIcon width={width} height={height} color={color} />;
      case "wma":
        return <WmaIcon width={width} height={height} color={color} />;
      case "wmv":
        return <WmvIcon width={width} height={height} color={color} />;
      case "wps":
        return <WpsIcon width={width} height={height} color={color} />;
      case "wsf":
        return <WsfIcon width={width} height={height} color={color} />;
      case "xhtml":
        return <XhtmlIcon width={width} height={height} color={color} />;
      case "xlr":
        return <XlrIcon width={width} height={height} color={color} />;
      case "xlsx":
        return <XlsxIcon width={width} height={height} color={color} />;
      case "yuv":
        return <YuvIcon width={width} height={height} color={color} />;
      case "zip":
        return <ZipIcon width={width} height={height} color={color} />;
      case "unknown":
        return <OtherICon width={width} height={height} color={color} name={"FILE"} />;
      default:
        return (
          <OtherICon
            width={width + 6}
            height={height + 6}
            color={color}
            name={extension.toUpperCase()}
          />
        );
    }
  };
  return setIcon();
};

export default FileFormatIconSet;
