import * as React from "react";

const NetworkIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".network{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter"
          filterUnits="userSpaceOnUse"
          x={37}
          y={305.7}
          width={950}
          height={618.4}>
          <feColorMatrix
            values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"
            colorInterpolationFilters="sRGB"
            result="source"
          />
          <feFlood
            style={{
              floodColor: "white",
              floodOpacity: 1,
            }}
            result="back"
          />
          <feBlend in="source" in2="back" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x={37} y={305.7} width={950} height={618.4} id="SVGID_1_">
        <circle
          className="network"
          cx={512}
          cy={291}
          r={242.9}
          style={{
            filter: "url(#Adobe_OpacityMaskFilter)",
          }}
        />
      </mask>
      <g
        style={{
          mask: "url(#SVGID_1_)",
        }}>
        <path
          className="network"
          d="m37 502.1 84.4 84.4c215.4-215.4 565.8-215.4 781.2 0l84.4-84.4c-261.9-261.9-688.1-261.9-950 0z"
        />
        <path
          className="network"
          d="m205.8 670.9 84.4 84.4c122.3-122.3 321.4-122.3 443.7 0l84.4-84.4C649.4 502 374.6 502 205.8 670.9z"
        />
        <path
          className="network1"
          d="m374.5 839.6 84.4 84.4c29.3-29.3 76.8-29.3 106.2 0l84.4-84.4c-75.8-75.8-199.2-75.8-275 0z"
          fill={color}
        />
      </g>
      <path
        className="network1"
        d="M512 100c-105.5 0-191 85.5-191 191s85.5 191 191 191 191-85.5 191-191-85.5-191-191-191zm0 71.1c18.9 0 33.4 16.6 30.9 35.4l-14.7 109.4h-32.3l-14.7-109.4c-2.6-18.8 11.9-35.4 30.8-35.4zm0 230.9c-17.2 0-31.2-14-31.2-31.2s14-31.2 31.2-31.2 31.2 14 31.2 31.2-14 31.2-31.2 31.2z"
        fill={color}
      />
    </g>
  </svg>
);

export default NetworkIcon;
