import React, { useState, useEffect } from "react";
import TextField from "../../Components/common/TextField/TextField";
import { Link } from "react-router-dom";
import CheckBox from "../../Components/common/CheckBox/CheckBox";
import CardForm from "../../Components/common/CardForm/CardForm";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest, createSuperAdminRequest } from "../../store/actions/usersActions";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";
import { licenceCheckRequest, licenceRegRequest } from "../../store/actions/usersActions";
import { loadFromLocalStorage } from "../../utils/localStorage";
import packageJson from "../../../package.json";

const SignIn = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state?.users.loginError);
  const loading = useSelector((state) => state?.users.loginLoading);
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);

  //** THIS SECTION CREATE SUPERADMIN FOR OLD SYSTEM ****/
  useEffect(() => {
    dispatch(createSuperAdminRequest());
  }, []);
  //** THIS SECTION CREATE SUPERADMIN FOR OLD SYSTEM ****/

  useEffect(() => {
    dispatch(licenceCheckRequest());
  }, []);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [checked, setChecked] = useState(false);
  const [inputsKey, setInputsKey] = useState({
    licencekey: "",
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangesKey = (e) => {
    const { name, value } = e.target;
    setInputsKey((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitFormHandler = (e) => {
    e.preventDefault();
    dispatch(loginRequest({ ...inputs, checked }));
  };

  const submitFormHandlerKey = (e) => {
    e.preventDefault(); // Don't forget to prevent default form submission
    dispatch(licenceRegRequest(inputsKey.licencekey));
  };

  return licenceCheck?.theme ? (
    licenceCheck?.theme && (
      <CardForm
        error={error}
        title="Welcome Back!"
        subTitle={
          "Sign in to continue to " +
          (licenceCheck?.theme === "dataflowx" ? "Control Panel" : "CyberFortress.")
        }
        errorText=" Invalid username or password, please try again.">
        <form onSubmit={submitFormHandler}>
          <TextField
            placeholder="Enter your email"
            value={inputs.email}
            onChange={handleChanges}
            labelText="Email"
            name="email"
            required={true}
            error={error}
          />
          <TextField
            placeholder="Enter your password"
            value={inputs.password}
            onChange={handleChanges}
            labelText="Password"
            name="password"
            required={true}
            error={error}
            type="password"
          />
          <CheckBox checked={checked} setChecked={setChecked} text={"Remember me"} />
          <button className="card__form-btn" type="submit">
            Sign In
          </button>
          <p style={{ textAlign: "center" }}>
            Forgot your <Link to="/forgot-password">password?</Link>
          </p>
          <p style={{ textAlign: "center" }}>v{packageJson.version}</p>
        </form>
        {loading && <Backdrop show={loading} />}
      </CardForm>
    )
  ) : (
    <CardForm
      error={error}
      title="Welcome!"
      subTitle="Please enter the license key you received from our team in the box below."
      errorText=" Invalid username or password, please try again.">
      <form onSubmit={submitFormHandlerKey}>
        <TextField
          placeholder="Enter your licence key"
          value={inputsKey.licencekey}
          onChange={handleChangesKey}
          labelText="Licence Key"
          name="licencekey"
          required={true}
          error={error}
        />
        <button className="card__form-btn" type="submit">
          Submit
        </button>
      </form>
      {loading && <Backdrop show={loading} />}
    </CardForm>
  );
};

export default SignIn;
