import React, { useState } from "react";
import Comparison from "./Components/Comparison";
import Submission from "./Components/Submission";
import DemoPopup from "../../../../../Components/common/DemoPopup/DemoPopup";
import "./styles.scss";

const FileAnalysis = () => {
  const [active, setActive] = useState("submission");
  const [showDelete, setShowDelete] = useState(false);
  return (
    <div className="file-analysis__content">
      <div className="file-analysis__tabs" style={{visibility:"hidden"}}>
        <div
          className={`file-analysis__tabs${active === "submission" ? "--active" : ""}`}
          onClick={() => setActive("submission")}>
          Submission
        </div>
        <div
          className={`file-analysis__tabs${active === "comparison" ? "--active" : ""}`}
          /*onClick={() => setActive("comparison")}*/
          onClick={() => setShowDelete(true)}>
          Comparison
        </div>
      </div>
      <div className="file-analysis__component">
        {active === "submission" ? <Submission /> : <Comparison />}
      </div>
      <DemoPopup
        onClickOk={() => setShowDelete(false)}
        show={showDelete}
        setShow={setShowDelete}
        text="This feature is not available in the demo version."
        article="DEMO"
      />
    </div>
  );
};

export default FileAnalysis;
