import * as React from "react"

const Pkg = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M69.497 259.208c-4.246.14-7.104.688-8.604 1.2v30.347c1.762.446 3.946.577 6.956.562 11.218-.082 18.245-6.512 18.245-17.244-.001-9.657-6.048-15.214-16.597-14.865z" fill={color} />
    <path  d="M357.396 535.335c.776.052 1.542.114 2.329.114h177.39c20.745 0 37.627-16.883 37.627-37.627V86.604c0-20.746-16.882-37.628-37.627-37.628h-177.39c-.787 0-1.553.074-2.329.124V0L11.176 46.208v492.308l346.22 47.402v-50.583zm2.33-464.856h177.39c8.893 0 16.125 7.232 16.125 16.125v411.218c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.117-2.329-.231V317.695l52.278 14.792 27.79-61.282-42.515-2.446 53.544-3.457 41.67 3.226-37.555 2.161 24.851 54.829 72.379-20.479-20.955-38.289.011-.2-.138-.01-.12-.241-1.695.107-20.95-1.617v-42.597H382.357v42.418l-24.951 1.601V70.72c.756-.113 1.523-.241 2.32-.241zm27.99 193.787v-36.694h112.885v36.812l-52.075-4.011-60.81 3.893zM98.789 298.319c-7.451 7.68-18.325 11.022-30.795 10.929-2.743-.016-5.197-.185-7.095-.509v36.757l-20.158-.746v-99.744c6.197-1.488 15.023-2.806 27.661-3.401 13.045-.614 22.513 1.75 28.945 7.105 6.216 5.118 10.439 13.824 10.439 24.275.001 10.449-3.207 19.328-8.997 25.334zm81.749 51.559-26.405-48.684-9.036 11.554v35.822l-22.958-.84V239.882l22.958-1.063v48.529l.47-.005c2.326-4.268 4.816-8.218 7.16-12.187l24.386-37.824 31.055-1.441-36.872 49.374 38.915 65.696-29.673-1.083zm138.458-.635c-9.254 2.62-26.467 6.012-43.286 5.376-22.625-.84-38.56-6.846-49.456-17.586-10.719-10.23-16.532-25.375-16.367-42.278.173-38.273 28.506-61.484 68.822-63.387 16.496-.779 29.467 1.722 35.946 4.371l-6.278 22.583c-7.191-2.709-16.038-4.801-30.045-4.354-23.425.742-40.648 13.836-40.648 39.278 0 24.229 15.583 38.858 38.59 39.373 6.573.147 11.875-.457 14.158-1.485v-25.491l-19.56-.073v-21.216l48.124-.52v65.409z" fill={color} />
    <path  d="m417.449 343.962-57.186-16.168v57.029l84.956 26.143V281.87l-12.016 27.351zM460.441 309.747l-10.309-26.237v127.456l84.972-26.143v-64.956l-62.09 17.565z" fill={color} />
  </svg>
)

export default Pkg
