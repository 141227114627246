import * as React from "react";
const EmailIntegrationIcon = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width}
    height={height}
    viewBox="0 0 512 512">
    <path
      fill={color}
      d="M170.591 239.406 1.584 429.263C1.151 427.352.5 425.504.5 423.411V88.589c0-2.541.71-4.837 1.356-7.144l168.735 157.961zM493.987 65.524c-1.238-.277-2.424-.876-3.79-.876H21.819c-1.297 0-2.456.599-3.769.876l237.961 222.734L493.987 65.524zM262.808 313.021c-1.954 1.869-4.372 2.777-6.797 2.777-2.419 0-4.842-.908-6.775-2.777l-62.21-58.217-170.37 191.341c1.698.523 3.364 1.207 5.163 1.207h468.378c1.89 0 3.534-.684 5.243-1.207L325.082 254.804l-62.274 58.217zm78.686-73.615 168.98 189.836c.459-1.911 1.025-3.759 1.025-5.831V88.589c0-2.499-.62-4.837-1.26-7.133l-168.745 157.95z"
      style={{
        display: "inline",
      }}
    />
  </svg>
);
export default EmailIntegrationIcon;
