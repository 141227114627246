import { flattenSubSection } from "../../utils/informationPopupUtil";
import CloseIcon from "../../../../Icon-SVG/CloseIcon";

import "./popup.scss";

const defaultContent = {
  header: "No content",
  exp: "No content",
  subSections: [],
};

const Popup = ({ content = defaultContent, onClick }) => {
  return (
    <div className="popup-container">
      <button onClick={onClick} className="popup-container__close-button">
        <CloseIcon color="var(--header-search-text-color)" />
      </button>
      {content.header && <h3 className="popup-container__header">{content.header}</h3>}
      {content.exp && <p className="popup-container__exp">{content.exp}</p>}
      {flattenSubSection(content.subSections).map((subSection, i) => (
        <div
          key={i}
          className={`popup-content__subsection-container popup-content__subsection-container--${subSection.flexDirection}`}
          style={{ margin: subSection.sectionMargin }}>
          <h5
            className="popup-content__subsection-container__header"
            style={{ fontStyle: subSection.headerFontStyle }}>
            {subSection.header}
          </h5>
          <p
            className="popup-content__subsection-container__exp"
            style={{
              fontSize: subSection.expFontSize,
              fontStyle: subSection.expFontStyle,
              fontWeight: subSection.expFontWeight,
              margin: subSection.expMargin,
            }}>
            {subSection.exp}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Popup;
