import React from "react";

const LogoIcon = ({ width = 56, height = 56, color = "#55a0e6" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23 30"
      fill="none">
      <path
        d="M0.572266 5.12891H22.9446V18.2891C22.9446 24.467 17.9364 29.4752 11.7584 29.4752V29.4752C5.58047 29.4752 0.572266 24.467 0.572266 18.2891V5.12891Z"
        fill={color}
      />
      <rect x="0.572266" y="0.523438" width="4.77056" height="4.77056" fill={color} />
      <rect x="18.1738" y="0.523438" width="4.77056" height="4.77056" fill={color} />
      <rect x="9.29102" y="0.523438" width="4.77056" height="4.77056" fill={color} />
      <circle cx="9.53543" cy="8.50125" r="1.23377" stroke="#E2FFFF" strokeWidth="0.658009" />
      <circle cx="7.23465" cy="14.7522" r="1.23377" stroke="#E2FFFF" strokeWidth="0.658009" />
      <circle cx="15.9534" cy="12.7786" r="1.23377" stroke="#E2FFFF" strokeWidth="0.658009" />
      <path
        d="M9.53711 10.0635V12.6011C9.53711 12.7675 9.60014 12.9277 9.71353 13.0495L11.5815 15.0558C11.6949 15.1776 11.7579 15.3378 11.7579 15.5042V23.0591"
        stroke="#E2FFFF"
        strokeWidth="0.658009"
        strokeLinecap="round"
      />
      <path
        d="M14.966 13.6826L14.0072 14.6414C13.8838 14.7648 13.8145 14.9322 13.8145 15.1067V21.7432"
        stroke="#E2FFFF"
        strokeWidth="0.658009"
        strokeLinecap="round"
      />
      <path
        d="M8.13867 15.6572L9.75959 17.3072C9.88047 17.4303 9.9482 17.5959 9.9482 17.7684V21.7438"
        stroke="#E2FFFF"
        strokeWidth="0.658009"
        strokeLinecap="round"
      />
      <line
        x1="11.7567"
        y1="10.7216"
        x2="11.7567"
        y2="12.8601"
        stroke="#E2FFFF"
        strokeWidth="0.658009"
        strokeLinecap="round"
      />
      <path
        d="M15.1431 17.6309C15.9004 18.4345 16.364 19.5148 16.364 20.7027C16.364 23.1853 14.3386 25.198 11.8402 25.198C9.34178 25.198 7.31641 23.1853 7.31641 20.7027C7.31641 19.5626 7.7435 18.5217 8.44736 17.7292"
        stroke="#E2FFFF"
        strokeWidth="0.658009"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LogoIcon;
