import { useSelector } from "react-redux";

import CustomTable from "../../../../../Components/common/Table/CustomTable";
import { hostsAndDomains } from "../../../../../utils/constants/tableHeaders";

import "./styles.scss";

const HostsAndDomains = () => {
  const hostsAndDomainsData = useSelector((state) => state.analysisReports.hostsAndDomains);

  if (hostsAndDomainsData.length === 0) {
    return (
      <div className="hosts-and-domains">
        <div
          className="map-footer"
          style={{
            background: "var(--card-background-color)",
            height: "100%",
          }}>
          <div className="hosts-and-domains__no-data__header">
            <p className="hosts-and-domains__no-data__item">Server IP</p>
            <p className="hosts-and-domains__no-data__item">Domain</p>
            <p className="hosts-and-domains__no-data__item">Country Name</p>
          </div>
          <div
            className="map-footer__header-item"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // fontFamily: "Nunito Sans",
              fontSize: "14px",
              fontWeight: "600",
              color: "#grey--text",
              textAlign: "center",
              textTransform: "uppercase",
              height: "100%",
              width: "100%",
            }}>
            No Data
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="hosts-and-domains">
      <CustomTable
        dataTable={hostsAndDomainsData}
        tableName={"hostsAndDomains"}
        column={hostsAndDomains}
        exportFile={true}
        paginationButtons={true}
        customPageSize={6}
        minHeight={418}
        borderRadius={4}
      />
    </div>
  );
};

export default HostsAndDomains;
