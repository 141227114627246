import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../../../Components/common/Table/CustomTable";
import DownloadIcon from "../../../Components/Icon-SVG/DownloadIcon";

import { domainsAndIpsHeader } from "../../../utils/constants/tableHeaders";

import { RequestIocDomainsAndIps } from "../../../store/actions/analysisReportActions";

import "./styles.scss";

const DomainsAndIps = () => {
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);

  const iocDomainsAndIps = useSelector((state) => state.analysisReports.iocDomainsAndIps);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTimeFilter) {
      dispatch(RequestIocDomainsAndIps(selectedTimeFilter));
    }
  }, [dispatch, selectedTimeFilter]);

  const downloadDomainsHandler = () => {
    const blob = new Blob(
      [
        iocDomainsAndIps
          .filter((el) => el.domain)
          .map((el) => el.domain)
          .join("\n"),
      ],
      {
        type: "plain/text",
      },
    );
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "CFSBOX_IOC_HOSTs_AND_DOMAINs.txt";
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const isDisabled = iocDomainsAndIps.length === 0;

  return (
    <div className="domains">
      <div className="domains__header">
        <h1>Hosts and Domains</h1>
        <div>
          <p>Below list is displaying hosts and domains visited by malicious files:</p>
          <button onClick={downloadDomainsHandler} disabled={isDisabled}>
            Download Hosts and Domains
            <DownloadIcon
              width={18}
              color={isDisabled ? "var(--card-background-color)" : undefined}
            />
          </button>
        </div>
      </div>
      <div className="domains__table border">
        <CustomTable
          exportFile={true}
          dataTable={iocDomainsAndIps}
          tableName="domainsAndIps"
          column={domainsAndIpsHeader}
          paginationButtons={true}
          className=""
          customPageSize={10}
          minHeight="585px"
          minBottom="5px"
          defaultPosition="relative"
          filterByTime={true}
          selectedTimeFilter={selectedTimeFilter}
          setSelectedTimeFilter={setSelectedTimeFilter}
          defaultFilterIndex={2}
        />
      </div>
    </div>
  );
};

export default DomainsAndIps;
