import React from "react";

const ChartLineIcon = ({ color = "var(--text-color-card)", width = "16", height = "16" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1231 15.7938H2.42961V0.653217C2.42961 0.546456 2.34226 0.459106 2.2355 0.459106H0.876728C0.769967 0.459106 0.682617 0.546456 0.682617 0.653217V17.3467C0.682617 17.4535 0.769967 17.5408 0.876728 17.5408H19.1231C19.2299 17.5408 19.3172 17.4535 19.3172 17.3467V15.988C19.3172 15.8812 19.2299 15.7938 19.1231 15.7938ZM4.17661 14.241H17.182C17.2888 14.241 17.3761 14.1536 17.3761 14.0468V3.46782C17.3761 3.29312 17.165 3.2082 17.0437 3.32952L11.941 8.4322L8.89835 5.42348C8.86186 5.38736 8.8126 5.3671 8.76126 5.3671C8.70992 5.3671 8.66065 5.38736 8.62417 5.42348L4.0383 10.0239C4.02049 10.0417 4.00639 10.0629 3.99681 10.0863C3.98723 10.1096 3.98237 10.1346 3.9825 10.1598V14.0468C3.9825 14.1536 4.06985 14.241 4.17661 14.241Z"
        fill={color}
      />
    </svg>
  );
};

export default ChartLineIcon;
