import * as React from "react"

const Xlsx = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M359.031 537.786c.781.053 1.551.115 2.342.115h178.2c20.841 0 37.8-16.964 37.8-37.8V86.999c0-20.838-16.959-37.8-37.8-37.8h-178.2c-.791 0-1.561.076-2.342.124V0L11.228 46.417v494.564L359.031 588.6v-50.814zm2.342-466.982h178.2c8.933 0 16.2 7.269 16.2 16.2v413.097c0 8.934-7.268 16.2-16.2 16.2h-178.2c-.802 0-1.571-.11-2.342-.232v-118.82H538.76V229.851H359.031V71.046c.771-.118 1.54-.242 2.342-.242zm82.445 285.945v35.1h-84.154v-35.1h84.154zm-84.154-45.901V275.75h84.154v35.098h-84.154zm173.697-35.098v35.098h-84.144V275.75h84.144zm-84.143-5.399V235.25h84.144v35.101h-84.144zm84.143 86.398v35.1h-84.144v-35.1h84.144zM77.688 339.167l-7.159-16.168c-2.906-6.181-4.77-10.758-6.961-15.853l-.232-.005c-1.616 5.01-3.57 9.486-5.967 15.467l-6.373 15.436-19.375-.817 21.756-42.837-20.994-42.124 19.514-.545 6.719 15.66c2.294 5.255 4.023 9.5 5.875 14.401h.221c1.859-5.576 3.365-9.481 5.342-14.573l6.684-16.179 20.849-.577-22.734 43.563 23.955 46.032-21.12-.881zm85.596 3.607-54.388-2.289v-90.337l19.251-.54v74.271l35.137 1.019v17.876zm35.208 2.91c-10.832-.464-21.347-3.744-26.552-6.908l4.24-17.598c5.647 3.117 14.423 6.355 23.588 6.64 10.017.301 15.356-3.739 15.356-10.136 0-6.085-4.628-9.635-16.206-13.975-15.709-5.732-25.758-14.528-25.758-28.326 0-16.176 13.242-28.938 35.754-29.592 11.017-.316 19.256 1.788 25.187 4.271l-5.05 18.151c-3.986-1.849-11.021-4.522-20.574-4.369-9.424.145-13.948 4.509-13.948 9.514 0 6.141 5.374 8.846 17.843 13.587 17.418 6.46 25.769 15.636 25.769 29.646.01 16.661-12.917 30.25-39.649 29.095zm111.819 3.28-10.035-19.195c-4.063-7.33-6.661-12.751-9.73-18.789h-.322c-2.251 5.911-4.986 11.185-8.34 18.246l-8.864 18.156-26.892-1.128 30.201-50.352-29.138-49.599 27.092-.752 9.368 18.457a312.664 312.664 0 0 1 8.192 17.004h.324c2.587-6.597 4.693-11.219 7.465-17.247l9.354-19.175 29.331-.817-31.957 51.706 33.677 54.729-29.726-1.244z" fill={color} />
  </svg>
)

export default Xlsx
