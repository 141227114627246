import networkDefenderSlice from "../slices/networkDefenderSlice";

export const {
  RequestDistinctHosts,
  SuccessDistinctHosts,
  FailureDistinctHosts,
  RequestNetworkDetections,
  SuccessNetworkDetections,
  FailureNetworkDetections,
} = networkDefenderSlice.actions;
