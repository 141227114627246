import * as React from "react";

const SvgComponent = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg width={width} height={height} fill={color} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 23h-2V9h6a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-4Zm0-7h4v-5h-4ZM28 19 24.32 9H22v14h2V13l3.68 10H30V9h-2v10zM8 9 6 22 4 9H2l2.52 14h2.96L10 9H8z" />
    <path
      data-name="&lt;Transparent Rectangle&gt;"
      transform="rotate(-180 16 16)"
      style={{
        fill: "none",
      }}
      d="M0 0h32v32H0z"
    />
  </svg>
);

export default SvgComponent;
