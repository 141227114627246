export const dashBoardLatestSubmissions = [
  {
    id: new Date() - 3,
    time: "12/26/2022, 10:49:59",
    file: {
      name: "CompanyFlashDrive",
      url: "/",
    },
    oS: "Windows 10",
    progress: "Completed",
    severity: "malicious",
  },
  {
    id: new Date() - 12,
    time: "12/26/2022, 09:23:53",
    file: {
      name: "SanDisk USB Drive",
      url: "/",
    },
    oS: "Windows 7",
    progress: "Completed",
    severity: "malicious",
  },
  {
    id: new Date(),
    time: "12/26/2022, 08:20:32",
    file: {
      name: "FileTransfer Drive",
      url: "/",
    },
    oS: "Windows 10",
    progress: "Completed",
    severity: "not suspicious",
  },
  {
    id: new Date() + 1,
    time: "12/25/2022, 21:10:23",
    file: {
      name: "ISO Files",
      url: "/",
    },
    oS: "Windows 7",
    progress: "Completed",
    severity: "suspicious",
  },
  {
    id: new Date() - 1121,
    time: "12/25/2022, 19:12:23",
    file: {
      name: "Confidential",
      url: "/",
    },
    oS: "Windows 10",
    progress: "Completed",
    severity: "not suspicious",
  },
  {
    id: new Date() - 12223,
    time: "12/25/2022, 18:34:11",
    file: {
      name: "NewVersion214566",
      url: "/",
    },
    oS: "Windows 7",
    progress: "Completed",
    severity: "malicious",
  },
  {
    id: new Date() - 1213,
    time: "12/25/2022, 17:11:33",
    file: {
      name: "EmployeeList",
      url: "/",
    },
    oS: "Windows 10",
    progress: "Completed",
    severity: "not suspicious",
  },
  {
    id: new Date() - 1233,
    time: "12/25/2022, 10:12:22",
    file: {
      name: "PersonalFiles",
      url: "/",
    },
    oS: "Windows 10",
    progress: "Completed",
    severity: "not suspicious",
  },
];
export const analysisReportsScreenshots = [
  {
    id: 1,
    src: "https://klike.net/uploads/posts/2019-05/1556708032_1.jpg",
  },
  {
    id: 2,
    src: "https://rozabox.com/wp-content/uploads/2019/01/man-5846064_1920-735x400.jpg",
  },
  {
    id: 3,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH70InUnFTQ4JyREKITynE1c5Chml1HENN6g&usqp=CAU",
  },
  {
    id: 4,
    src: "https://bipbap.ru/wp-content/uploads/2017/04/72fqw2qq3kxh.jpg",
  },
  {
    id: 5,
    src: "https://klike.net/uploads/posts/2019-05/1556708032_1.jpg",
  },
  {
    id: 6,
    src: "https://rozabox.com/wp-content/uploads/2019/01/man-5846064_1920-735x400.jpg",
  },
  {
    id: 7,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH70InUnFTQ4JyREKITynE1c5Chml1HENN6g&usqp=CAU",
  },
  {
    id: 8,
    src: "https://bipbap.ru/wp-content/uploads/2017/04/72fqw2qq3kxh.jpg",
  },
  {
    id: 9,
    src: "https://klike.net/uploads/posts/2019-05/1556708032_1.jpg",
  },
  {
    id: 22,
    src: "https://rozabox.com/wp-content/uploads/2019/01/man-5846064_1920-735x400.jpg",
  },
  {
    id: 33,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH70InUnFTQ4JyREKITynE1c5Chml1HENN6g&usqp=CAU",
  },
  {
    id: 44,
    src: "https://bipbap.ru/wp-content/uploads/2017/04/72fqw2qq3kxh.jpg",
  },
  {
    id: 15,
    src: "https://klike.net/uploads/posts/2019-05/1556708032_1.jpg",
  },
  {
    id: 25,
    src: "https://rozabox.com/wp-content/uploads/2019/01/man-5846064_1920-735x400.jpg",
  },
  {
    id: 323,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH70InUnFTQ4JyREKITynE1c5Chml1HENN6g&usqp=CAU",
  },
  {
    id: 412,
    src: "https://bipbap.ru/wp-content/uploads/2017/04/72fqw2qq3kxh.jpg",
  },
  {
    id: 1123,
    src: "https://klike.net/uploads/posts/2019-05/1556708032_1.jpg",
  },
  {
    id: 232,
    src: "https://rozabox.com/wp-content/uploads/2019/01/man-5846064_1920-735x400.jpg",
  },
  {
    id: 3123,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH70InUnFTQ4JyREKITynE1c5Chml1HENN6g&usqp=CAU",
  },
  {
    id: 4789,
    src: "https://bipbap.ru/wp-content/uploads/2017/04/72fqw2qq3kxh.jpg",
  },
  {
    id: 178,
    src: "https://klike.net/uploads/posts/2019-05/1556708032_1.jpg",
  },
  {
    id: 288,
    src: "https://rozabox.com/wp-content/uploads/2019/01/man-5846064_1920-735x400.jpg",
  },
  {
    id: 38787,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH70InUnFTQ4JyREKITynE1c5Chml1HENN6g&usqp=CAU",
  },
  {
    id: 4787,
    src: "https://bipbap.ru/wp-content/uploads/2017/04/72fqw2qq3kxh.jpg",
  },
];
export const analysisReportsOverview = [
  {
    id: 5,
    score: 5,
    category: "ASD Data Modification",
    operation: "Modifies content of user files",
    count: 12,
    classification: "Ransomware",
  },
  {
    id: 4,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 13,
    classification: "Ransomware",
  },
  {
    id: 3,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 2,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 60,
    score: 1,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 50,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 40,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 30,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 20,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 10,
    score: 1,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 5,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 4,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 3,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 21,
    classification: "Ransomware",
  },
  {
    id: 2,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 1,
    score: 12,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 13,
    classification: "Ransomware",
  },
  {
    id: 5,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 4,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 3,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 2,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 60,
    score: 1,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 50,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 12,
    classification: "Ransomware",
  },
  {
    id: 40,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 30,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 20,
    score: 52,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 16,
    classification: "Ransomware",
  },
  {
    id: 10,
    score: 1,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 5,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 4,
    score: 74,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 41,
    classification: "Ransomware",
  },
  {
    id: 3,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 2,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 1,
    score: 1,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 12,
    classification: "Ransomware",
  },
  {
    id: 5,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 4,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 3,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 341,
    classification: "Ransomware",
  },
  {
    id: 2,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 71,
    classification: "Ransomware",
  },
  {
    id: 60,
    score: 1,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 15,
    classification: "Ransomware",
  },
  {
    id: 50,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 40,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 30,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 20,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 10,
    score: 1,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 5,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 4,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 3,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 2,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 1,
    score: 17,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 5,
    score: 5,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 17,
    classification: "Ransomware",
  },
  {
    id: 4,
    score: 4,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 3,
    score: 3,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 134,
    classification: "Ransomware",
  },
  {
    id: 2,
    score: 2,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
  {
    id: 1,
    score: 1,
    category: "User Data Modification",
    operation: "Modifies content of user files",
    count: 1,
    classification: "Ransomware",
  },
];
export const analysisReportsComments = [
  {
    id: 1,
    date: "12 Jan 2022",
    text: "#malware #Trojan",
    author: "Remzi Zorpuzan",
    time: "12:03",
    avatar: "asd",
  },
  {
    id: 23,
    date: "12 Jan 2022",
    author: "Remzi Zorpuzan",
    text: `Detection: MAL    
        Score: 100/100
        Classification: mal100.evad.phis.spyw.troj.winEXE@20/10@2/3
        Domains: myexternalip.com
        Hosts: 78.47.139.102 94.112.52.197 92.53.77.219

        HTML Report: https://www.joesandbox.com/analysis/61724/0/html
        PDF Report: https://www.joesandbox.com/analysis/61724/0/pdf
        Executive Report: https://www.joesandbox.com/analysis/61724/0/executive
        Incident Report: https://www.joesandbox.com/analysis/61724/0/irxml
        IOCs: https://www.joesandbox.com/analysis/61724?idtype=analysisid`,
    time: "12:03",
    avatar: "asd",
  },
  {
    id: 112,
    date: "12 Jan 2022",
    text: "#malware #Trojan",
    author: "Remzi Zorpuzan",
    time: "12:03",
    avatar: "asd",
  },
  {
    id: 21,
    date: "12 Jan 2022",
    author: "Remzi Zorpuzan",
    text: `Detection: MAL    
        Score: 100/100
        Classification: mal100.evad.phis.spyw.troj.winEXE@20/10@2/3
        Domains: myexternalip.com
        Hosts: 78.47.139.102 94.112.52.197 92.53.77.219

        HTML Report: https://www.joesandbox.com/analysis/61724/0/html
        PDF Report: https://www.joesandbox.com/analysis/61724/0/pdf
        Executive Report: https://www.joesandbox.com/analysis/61724/0/executive
        Incident Report: https://www.joesandbox.com/analysis/61724/0/irxml
        IOCs: https://www.joesandbox.com/analysis/61724?idtype=analysisid`,
    time: "12:03",
    avatar: "asd",
  },
  {
    id: 12,
    date: "12 Jan 2022",
    text: "#malware #Trojan",
    author: "Remzi Zorpuzan",
    time: "12:03",
    avatar: "asd",
  },
  {
    id: 222,
    date: "12 Jan 2022",
    author: "Remzi Zorpuzan",
    text: `Detection: MAL    
        Score: 100/100
        Classification: mal100.evad.phis.spyw.troj.winEXE@20/10@2/3
        Domains: myexternalip.com
        Hosts: 78.47.139.102 94.112.52.197 92.53.77.219

        HTML Report: https://www.joesandbox.com/analysis/61724/0/html
        PDF Report: https://www.joesandbox.com/analysis/61724/0/pdf
        Executive Report: https://www.joesandbox.com/analysis/61724/0/executive
        Incident Report: https://www.joesandbox.com/analysis/61724/0/irxml
        IOCs: https://www.joesandbox.com/analysis/61724?idtype=analysisid`,
    time: "12:03",
    avatar: "asd",
  },
];
export const analysisReportsDroppedFileList = [
  {
    id: 1,
    type: "Proccess",
    value: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    details: "Embedded File, Binary",
    verdict: "clean",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "21 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 2,
    type: "Stream",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "13 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 3,
    type: "PCAP file",
    value: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    details: "Embedded File, Binary",
    verdict: "SUSPICION",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "12 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 4231,
    type: "File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    type: "PCAP File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "SUSPICION",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "2 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 6765,
    type: "Test",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "3 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 7567,
    type: "File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 8765,
    type: "File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 9567,
    type: "File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 99,
    type: "File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 19765,
    type: "File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 13128,
    type: "File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 17567,
    type: "File",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "MALICIOS",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
  {
    id: 13213216,
    type: "Filename",
    value: "-",
    details: "Embedded File, Binary",
    verdict: "clean",
    action: true,
    fileNames: new Date().toISOString(),
    fileSize: "1 Kb",
    mime: "application/octet-stream",
    category: "Modified File",
    operations: "Write, Read, Delete, Access, Create",
    processName: "msmpeng.exe\n",
    processCommand: '"C:\\Windows\\MsMpEng.exe"\n',
    parentFile: "",
    url: "",
    mds: "b2a1c1bb19682bb8f35e9ff116cfc819\n",
    sha1: "0fbea7d4ba097e2df25278d5a8d0c9468d0c3778\n",
    sha256: "d878d51e1f8e5a3709f39b259a58ec5f03ff30432e1c29655873dd15037e06b7\n",
    impHash: "",
    classifications: "Ransomware",
    threatNames: "",
  },
];

export const mitreMaxCardExecution = [
  {
    title: "Windows Management Instrumentation",
    status: "red",
    active: true,
  },
  {
    title: "Windows Management Instrumentation",
    status: "red",
    active: false,
  },
  {
    title: "Windows Management Instrumentation",
    status: "grey",
    active: true,
  },
  {
    title: "Windows Management Instrumentation",
    status: "orange",
    active: true,
  },
];

export const mitreMaxCardDefenseEvasion = [
  {
    title: "Windows Management Instrumentation",
    status: "grey",
    active: false,
  },
  {
    title: "Windows Management Instrumentation",
    status: "red",
    active: true,
  },
  {
    title: "Windows Management Instrumentation",
    status: "grey",
    active: false,
  },
  {
    title: "Windows Management Instrumentation",
    status: "orange",
    active: true,
  },
];

export const mitreMaxCardDiscovery = [
  {
    title: "Windows Management Instrumentation",
    status: "grey",
    active: true,
  },
  {
    title: "Windows Management Instrumentation",
    status: "red",
    active: false,
  },
  {
    title: "Windows Management Instrumentation",
    status: "grey",
    active: true,
  },
  {
    title: "Windows Management Instrumentation",
    status: "orange",
    active: false,
  },
];

export const mitreMaxCardImpact = [
  {
    title: "Windows Management Instrumentation",
    status: "orange",
    active: true,
  },
  {
    title: "Windows Management Instrumentation",
    status: "red",
    active: false,
  },
  {
    title: "Windows Management Instrumentation",
    status: "grey",
    active: true,
  },
  {
    title: "Windows Management Instrumentation",
    status: "orange",
    active: false,
  },
];

export const avYaraFixture = [
  {
    fileType: "Sample File",
    threatName: "Gen:Variant.Graftor.952042",
    fileName: "-",
    verdict: "MALICIOS",
    actions: "...",
  },
  {
    fileType: "Embedded File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "SUSPICION",
    actions: "...",
  },
  {
    fileType: "Dropped File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "MALICIOS",
    actions: "...",
  },
  {
    fileType: "Sample File",
    threatName: "Gen:Variant.Graftor.952042",
    fileName: "-",
    verdict: "MALICIOS",
    actions: "...",
  },
  {
    fileType: "Embedded File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "SUSPICION",
    actions: "...",
  },
  {
    fileType: "Dropped File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "SUSPICION",
    actions: "...",
  },
  {
    fileType: "Sample File",
    threatName: "Gen:Variant.Graftor.952042",
    fileName: "-",
    verdict: "MALICIOS",
    actions: "...",
  },
  {
    fileType: "Embedded File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "SUSPICION",
    actions: "...",
  },
  {
    fileType: "Dropped File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "Clean",
    actions: "...",
  },
  {
    fileType: "Sample File",
    threatName: "Gen:Variant.Graftor.952042",
    fileName: "-",
    verdict: "Clean",
    actions: "...",
  },
  {
    fileType: "Embedded File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "SUSPICION",
    actions: "...",
  },
  {
    fileType: "Dropped File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "Clean",
    actions: "...",
  },
  {
    fileType: "Sample File",
    threatName: "Gen:Variant.Graftor.952042",
    fileName: "-",
    verdict: "Clean",
    actions: "...",
  },
  {
    fileType: "Embedded File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "SUSPICION",
    actions: "...",
  },
  {
    fileType: "Dropped File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "Clean",
    actions: "...",
  },
  {
    fileType: "Sample File",
    threatName: "Gen:Variant.Graftor.952042",
    fileName: "-",
    verdict: "MALICIOS",
    actions: "...",
  },
  {
    fileType: "Embedded File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "SUSPICION",
    actions: "...",
  },
  {
    fileType: "Dropped File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "MALICIOS",
    actions: "...",
  },
  {
    fileType: "Sample File",
    threatName: "Gen:Variant.Graftor.952042",
    fileName: "-",
    verdict: "MALICIOS",
    actions: "...",
  },
  {
    fileType: "Embedded File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "SUSPICION",
    actions: "...",
  },
  {
    fileType: "Dropped File",
    threatName: "Gen:Variant.Bulz.471680",
    fileName: "-",
    verdict: "Clean",
    actions: "...",
  },
];
export const yaraMatches = [
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 5,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 2,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 2,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 2,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 3,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 1,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 3,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 5,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 1,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 2,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 3,
    actions: "...",
  },
  {
    rulesetName: "Ransomware",
    ruleName: "GenericRansomNote",
    ruleDescription: "Generic Ransomware Note",
    fileType: "Dropped File",
    fileName: "?C:1yg4ztc5-readme.txt",
    classification: "-",
    score: 4,
    actions: "...",
  },
];

export const settingYaraRulesFixture = [
  {
    id: 1,
    name: "Test1",
    fileType: "TEst1",
    contentType: "TEst1",
    modificationTime: "TEst1",
    size: "TEst1",
    delete: "TEst1",
  },
  {
    id: 2,
    name: "Test2",
    fileType: "TEst2",
    contentType: "TEst2",
    modificationTime: "TEst2",
    size: "TEst2",
    delete: "TEst2",
  },
  {
    id: 3,
    name: "Test23",
    fileType: "TEst23",
    contentType: "TEst1",
    modificationTime: "TEst1",
    size: "TEst1",
    delete: "TEst1",
  },
  {
    id: 4,
    name: "Test1",
    fileType: "123",
    contentType: "31",
    modificationTime: "TEst1",
    size: "TEst1",
    delete: "TEst1",
  },
  {
    id: 1,
    name: "Test1",
    fileType: "TEst1",
    contentType: "TEst1",
    modificationTime: "TEst1",
    size: "TEst1",
    delete: "TEst1",
  },
  {
    id: 2,
    name: "Test2",
    fileType: "safd",
    contentType: "TEst2",
    modificationTime: "TEst2",
    size: "TEst2",
    delete: "TEst2",
  },
  {
    id: 3,
    name: "Test23",
    fileType: "TEst23",
    contentType: "TEst1",
    modificationTime: "TEst1",
    size: "TEst1",
    delete: "TEst1",
  },
  {
    id: 4,
    name: "Test1",
    fileType: "TEst1",
    contentType: "TEst1",
    modificationTime: "TEst1",
    size: "TEst1",
    delete: "TEst1",
  },
  {
    id: 1,
    name: "Test1",
    fileType: "TEst1",
    contentType: "TEst1",
    modificationTime: "TEst1",
    size: "TEst1",
    delete: "TEst1",
  },
  {
    id: 2,
    name: "Test2",
    fileType: "TEst2",
    contentType: "TEst2",
    modificationTime: "TEst2",
    size: "TEst2",
    delete: "TEst2",
  },
];

export const settingGuestImagesFixture = [
  {
    name: "centos-7.3",
    version: "1.0107",
    type: "Analysis",
    status: "Enabled",
  },
  {
    name: "centos-7.4",
    version: "19.0107",
    type: "Analysis",
    status: "disabled",
  },
  {
    name: "centos-7.2",
    version: "18.0107",
    type: "Analysis",
    status: "disabled",
  },
  {
    name: "centos-7.6",
    version: "17.0112",
    type: "Analysis",
    status: "Enabled",
  },
  {
    name: "centos-7.7",
    version: "17.3107",
    type: "Analysis",
    status: "Enabled",
  },
  {
    name: "centos-8.0",
    version: "17.1107",
    type: "Analysis",
    status: "Enabled",
  },
  {
    name: "centos-7.2",
    version: "16.0107",
    type: "Analysis",
    status: "Enabled",
  },
];

export const settingsSummary = [
  {
    id: 1,
    value: "1Malware Object",
  },
  {
    id: 2,
    value: "2Malware Object",
  },
  {
    id: 3,
    value: "3Malware Object",
  },
  {
    id: 4,
    value: "4Malware Object",
  },
];

export const dateTimeList = [{ id: 1, server: "server 0.unbutu.pool.ntp.org", time: "02/02/2022" }];

export const backupFixture = [
  {
    id: "1",
    profile: "config",
    location: [{ id: 1, value: "Local" }],
    remote_url: "eg.scp | sftp://user[;pwd]@host/remote_path",
    file_name: "asd",
    encrypt: false,
    estimation: "estimate",
    backup: "Backup",
  },
  {
    id: "2",
    profile: "config",
    location: [
      { id: 2, value: "Local" },
      { id: 3, value: "Local 2" },
    ],
    remote_url: "eg.scp | sftp://user[;pwd]@host/remote_path",
    file_name: "asd",
    encrypt: true,
    estimation: "estimate",
    backup: "Backup",
  },
  {
    id: "3",
    profile: "config",
    location: [{ id: 3, value: "Local" }],
    remote_url: "eg.scp | sftp://user[;pwd]@host/remote_path",
    file_name: "asd",
    encrypt: false,
    estimation: "estimate",
    backup: "Backup",
  },
];

export const summaryFixture = [
  {
    id: 1,
    title: "Event Type",
    protocol: "Global",
    rsyslog: true,
    smtp: false,
    snmp: true,
    http: false,
  },
  {
    id: 2,
    title: "Malware object",
    protocol: "Global",
    rsyslog: false,
    smtp: false,
    snmp: true,
    http: false,
  },
];

export const settings_RSYSLOG_Fixture = [
  {
    id: 1,
    name: "Test",
    enabled: "Enabled",
    ip: "192.168.1.1",
    edit: "",
  },
  {
    id: 2,
    name: "Test2",
    enabled: "Disabled",
    ip: "192.168.21.1",
    edit: "",
  },
  {
    id: 3,
    name: "Test3",
    enabled: "Enabled",
    ip: "192.168.2.1",
    edit: "",
  },
];

export const settings_SMTP_SNMP_Fixture = [
  {
    id: 1,
    name: "Test",
    enabled: "Enabled",
    ip: "192.168.1.1",
    community: "Community",
    edit: "",
  },
];

export const settings_HTTP_Fixture = [
  {
    id: 1,
    name: "Test",
    enabled: "Enabled",
    url: "http://localhost:8080",
    auth: "auth",
    alerts: "alerts",
    ssl_enable: "ssl_enable",
    ssl_verify: "ssl_verify",
    edit: "",
  },
  {
    id: 2,
    name: "Test2",
    enabled: "Enabled",
    url: "http://localhost:8080",
    auth: "auth",
    alerts: "alerts",
    ssl_enable: "ssl_enable",
    ssl_verify: "ssl_verify",
    edit: "",
  },
  {
    id: 3,
    name: "Test3",
    enabled: "Enabled",
    url: "http://localhost:8080",
    auth: "auth",
    alerts: "alerts",
    ssl_enable: "ssl_enable3",
    ssl_verify: "ssl_verify",
    edit: "",
  },
];
export const dashBoardMaliciousSubmissions = [
  {
    id: new Date() - 3,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Ubuntu 20.04 LTS",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 12,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date(),
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "black listed",
  },
  {
    id: new Date() + 1,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "suspicious",
  },
  {
    id: new Date() - 1121,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "not suspicious",
  },
  {
    id: new Date() - 12223,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1213,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1233,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1223,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 3,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 12,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date(),
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "black listed",
  },
  {
    id: new Date() + 1,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "suspicious",
  },
  {
    id: new Date() - 1121,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "not suspicious",
  },
  {
    id: new Date() - 12223,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1213,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1233,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1223,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 3,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 12,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date(),
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "black listed",
  },
  {
    id: new Date() + 1,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "suspicious",
  },
  {
    id: new Date() - 1121,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Completed",
    severity: "not suspicious",
  },
  {
    id: new Date() - 12223,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1213,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1233,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
  {
    id: new Date() - 1223,
    time: "12:28",
    file: {
      name: "combine.exe",
      url: "https://www.figma.com/file/gD7E8EXrd84BWOWRuft1n6/Cyber-Fortress?node-id=1402%3A2824",
    },
    oS: "Windows Exe(x86.32)",
    progress: "Work in Progress",
    severity: "loading",
  },
];
