import Chart from "chart.js/auto";

export const barChart = (name, theme) => {
  const ctx = document.getElementById(`chart-${name}`).getContext("2d");
  let myChart;

  try {
    myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["Worms", "Adware", "Backdoors viruses", "My submission"],
        datasets: [
          {
            barThickness: 31,
            data: [2, 4, 6, 8],
            backgroundColor: ["#58E655", "#E6AC55", "#E65555", "#55BAE6"],
            borderColor: ["#58E655", "#E6AC55", "#E65555", "#55BAE6"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            plugins: {
              labels: {
                enabled: true,
              },
            },
            beginAtZero: true,
            grid: {
              color: theme === "dark" ? "var(--border)" : "#CCD5F1",
              drawBorder: false,
            },
            ticks: {
              color: theme === "dark" ? "white" : "black",
              padding: 8,
            },
          },
          x: {
            plugins: {
              labels: {
                enabled: true,
              },
            },
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        maintainAspectRatio: true,
        responsive: true,
      },
    });
  } catch (e) {
    myChart?.destroy();
  }
};
