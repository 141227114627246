import { put, takeEvery } from "redux-saga/effects";
import { themeSuccess, themeRequest, themeFailure } from "../actions/themeAction";

export function* changeTheme({ payload: theme }) {
  try {
    yield put(themeSuccess(theme));
  } catch (e) {
    yield put(themeFailure());
  }
}

const themeSagas = [takeEvery(themeRequest, changeTheme)];

export default themeSagas;
