import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import {
  publicKeyCheckRequest,
  publicKeyRegRequest,
  apiLimitterGetRequest,
  apiLimitterSetRequest,
} from "../../../../../store/actions/usersActions";

const PublicApi = () => {
  const userPublicKey = useSelector((state) => state?.users.publicKey);
  const apiLimiter = useSelector((state) => state?.users.apiLimiter);
  const [range, setRange] = useState(100);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(publicKeyCheckRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(apiLimitterGetRequest());
  }, [dispatch]);

  useEffect(() => {
    if (apiLimiter) {
      setRange(apiLimiter);
    }
  }, [apiLimiter]);

  useEffect(() => {
    if (range !== apiLimiter) {
      dispatch(apiLimitterSetRequest(range));
    }
  }, [range, apiLimiter, dispatch]);

  const createPublicApiKey = () => {
    dispatch(publicKeyRegRequest());
  };

  return (
    <div className="publicapi">
      <h1 className="publicapi__header">Public API</h1>
      <div className="publicapi__body">
        <div className="publicapi__body__header">
          <h3>Settings</h3>
          <p>
            On this page, you can view your public API key and adjust your public API usage per
            minute.
          </p>
        </div>
        <div className="publicapi__body__content">
          <div className="publicapi__body__content__row">
            <div className="publicapi__body__content__row__apikey">
              <p className="publicapi__body__content__row__apikey__header">Public API Key</p>
              <div className="publicapi__body__content__row__apikey__labels">
                {userPublicKey ? (
                  userPublicKey
                ) : (
                  <button onClick={createPublicApiKey}>Create Key</button>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className="light publicapi__body__content__row__range-container-header">
              Public API Limiter ( Per minutes )
            </p>
            <div className="publicapi__body__content__row__range-container">
              <div className="publicapi__body__content__row__range-container__data">
                <div></div>
                <input
                  type="number"
                  value={range}
                  onChange={(e) => setRange(Number(e.target.value))}
                  min="1"
                  max="2000"
                />
                <div></div>
              </div>
              <input
                type="range"
                max="2000"
                onChange={(e) => setRange(Number(e.target.value))}
                value={range}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicApi;
