import { useEffect } from "react";
import ReactDOM from "react-dom";

import IntegrationModalContent from "../IntegrationModalContent/IntegrationModalContent";
import CloseIcon from "../../../Icon-SVG/CloseIcon";

import "./styles.scss";

const IntegrationModal = ({ closeModalHandler, modalType }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return ReactDOM.createPortal(
    <div className="integration-backdrop" onClick={closeModalHandler}>
      <div className="integration-modal" onClick={(e) => e.stopPropagation()}>
        <div className="integration-modal__content">
          <div className="integration-modal__close" onClick={closeModalHandler}>
            <CloseIcon width="18" height="18" color="var(--header-search-text-color)" />
          </div>
          <IntegrationModalContent modalType={modalType} />
        </div>
      </div>
    </div>,
    document.getElementById("portal"),
  );
};

export default IntegrationModal;
