import * as React from "react"

const Fnt = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M537.115 48.979h-177.39c-.787 0-1.553.077-2.329.124V0L11.176 46.206v492.311l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.745 0 37.627-16.888 37.627-37.627V86.602c0-20.743-16.882-37.623-37.627-37.623zm-435.043 210.57-36.793.851v23.677l34.31-.144v19.599l-34.31-.379v41.681l-20.772-.93V241.982l57.565-2.226v19.793zm106.61 91.652-26.913-1.191-23.353-44.011c-6.346-11.917-13.255-26.185-18.388-39.019l-.462.165c.614 14.501.929 29.993.929 47.925v33.098l-21.365-.956V239.108l27.285-1.056 22.28 40.268c6.504 11.829 13.074 25.956 18.035 38.837l.501.011c-1.661-15.034-2.157-30.373-2.157-47.402V236.57l23.608-.914v115.545zm110.939-96.129-35.909.811v98.652l-28.581-1.265v-96.732l-32.396.744v-22.168l96.886-3.729v23.687zM553.24 497.823c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.231V70.722c.766-.112 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.126v411.217z" fill={color} />
    <path  d="m419.706 288.468-27.864 91.158h21.37l6.494-23.401h26.1l7.04 23.401h22.178l-28.272-91.158h-27.046zm2.972 52.345 5.406-19.345c1.491-5.264 2.846-12.174 4.194-17.581h.272c1.351 5.418 2.977 12.18 4.594 17.581l5.686 19.345h-20.152zM541.671 340.545c0-15.561-7.029-28.548-29.07-28.548-12.037 0-21.103 3.251-25.701 5.812l3.789 13.266c4.326-2.709 11.496-5.013 18.259-5.013 10.141 0 12.031 5.013 12.031 8.524v.95c-23.403-.136-38.826 8.115-38.826 25.291 0 10.552 7.99 20.279 21.377 20.279 7.849 0 14.613-2.835 18.939-8.115h.398l1.223 6.635h18.526c-.673-3.653-.945-9.748-.945-16.095v-22.986zm-20.015 15.013c0 1.224-.132 2.437-.405 3.517-1.354 4.184-5.544 7.57-10.688 7.57-4.592 0-8.115-2.577-8.115-7.849 0-7.973 8.388-10.551 19.209-10.413v7.175zM448.446 209.37l-75.702 10.541v58.888h75.702zM372.744 291.796v60.067l15.729 1.958 18.95-62.025z" fill={color} />
  </svg>
)

export default Fnt
