import { useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "../../../UI/Modal/Modal";
import InformationPopup from "../../../common/InformationPopup/InformationPopup";

import { informationPopupContent } from "../../../common/InformationPopup/utils/informationPopupUtil";

import { RequestSubmitFreerun } from "../../../../store/actions/dashboardActions";

import "./styles.scss";

const OPTIONS_INITIAL_STATE = {
  machine: "win7",
  type: "full_triage",
  memory_dump: "advanced",
  network_traffic: "simulate",
};

const getTxtFile = () => {
  const data = [
    "####################",
    "Welcome to FREE RUN",
    "####################",
    "",
    "Please use menu buttons on the left",
  ];
  const blob = new Blob([data.join("\n")], { type: "text/plain" });
  return blob;
};

const FreeRun = ({ active, setActive }) => {
  // const [active, setActive] = useState(false);
  const [options, setOptions] = useState(OPTIONS_INITIAL_STATE);

  const dispatch = useDispatch();

  const openPopupHandler = () => setActive(true);

  const optionsChangeHandler = (e) => {
    const group = e.target.name;
    const option = e.target.value;

    setOptions((prevState) => ({
      ...prevState,
      [group]: option,
    }));
  };

  const startHandler = () => {
    const txtFile = getTxtFile();
    dispatch(RequestSubmitFreerun({ file: txtFile, ...options }));
    setOptions(OPTIONS_INITIAL_STATE);
    setActive(false);
  };

  return (
    <>
      <div className="free-run free-run__btn" onClick={openPopupHandler} />
      {active ? (
        <Modal active={active} setActive={setActive} type="free-run-popup">
          <div className="free-run__popup">
            <div className="close-button__outer">
              <div onClick={() => setActive(false)} className="close-button__inner">
                <label className="close-button__close">Close</label>
              </div>
            </div>
            <span className="free-run__information-popup">
              <InformationPopup
                content={informationPopupContent.submitPopup}
                iconWidth={32}
                iconHeight={32}
              />
            </span>

            {/* VM */}
            <section className="free-run__option-group">
              <p className="free-run__option-name">VM</p>
              <div className="free-run__options">
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="win7"
                    name="machine"
                    id="free-run-vm-win7"
                    checked={options.machine === "win7"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-vm-win7">
                    Windows 7
                  </label>
                </div>
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="win10"
                    name="machine"
                    id="free-run-vm-win10"
                    checked={options.machine === "win10"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-vm-win10">
                    Windows 10
                  </label>
                </div>
              </div>
            </section>

            {/* Type */}
            <section className="free-run__option-group">
              <p className="free-run__option-name">Type</p>
              <div className="free-run__options">
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="full_triage"
                    name="type"
                    id="free-run-type-full_triage"
                    checked={options.type === "full_triage"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-type-full_triage">
                    Full Triage
                  </label>
                </div>
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="high_evasion"
                    name="type"
                    id="free-run-type-high_evasion"
                    checked={options.type === "high_evasion"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-type-high_evasion">
                    High Evasion
                  </label>
                </div>
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="basic_evasion"
                    name="type"
                    id="free-run-type-basic_evasion"
                    checked={options.type === "basic_evasion"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-type-basic_evasion">
                    Basic Evasion
                  </label>
                </div>
              </div>
            </section>

            {/* Artifact Analysis */}
            <section className="free-run__option-group">
              <p className="free-run__option-name">Artifact Analysis</p>
              <div className="free-run__options">
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="basic"
                    name="memory_dump"
                    id="free-run-artifact-analysis-basic"
                    checked={options.memory_dump === "basic"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-artifact-analysis-basic">
                    Basic
                  </label>
                </div>
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="advanced"
                    name="memory_dump"
                    id="free-run-artifact-analysis-advanced"
                    checked={options.memory_dump === "advanced"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-artifact-analysis-advanced">
                    Advanced
                  </label>
                </div>
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="full"
                    name="memory_dump"
                    id="free-run-artifact-analysis-full"
                    checked={options.memory_dump === "full"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-artifact-analysis-full">
                    Full
                  </label>
                </div>
              </div>
            </section>

            {/* Network Traffic */}
            <section className="free-run__option-group">
              <p className="free-run__option-name">Network Traffic</p>
              <div className="free-run__options">
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="internet"
                    name="network_traffic"
                    id="free-run-network-traffic-internet"
                    checked={options.network_traffic === "internet"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-network-traffic-internet">
                    Internet
                  </label>
                </div>
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="vpn"
                    name="network_traffic"
                    id="free-run-network-traffic-vpn"
                    checked={options.network_traffic === "vpn"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-network-traffic-vpn">
                    VPN
                  </label>
                </div>
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="simulate"
                    name="network_traffic"
                    id="free-run-network-traffic-simulate"
                    checked={options.network_traffic === "simulate"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-network-traffic-simulate">
                    Simulate
                  </label>
                </div>
                <div className="free-run__option">
                  <input
                    type="radio"
                    value="disable"
                    name="network_traffic"
                    id="free-run-network-traffic-disable"
                    checked={options.network_traffic === "disable"}
                    onChange={optionsChangeHandler}
                  />
                  <label className="free-run__label" htmlFor="free-run-network-traffic-disable">
                    Disable
                  </label>
                </div>
              </div>
            </section>
            <section className="free-run__btn-container">
              <button className="free-run__start-btn" onClick={startHandler}>
                Submit for Start
              </button>
            </section>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default FreeRun;
