import * as React from "react"

const M4v = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="m178.946 288.803-13.257 22.209v.311l22.388.169v-22.771c0-6.167.314-12.501.783-19.153l-.62.016c-3.267 6.702-5.908 12.732-9.294 19.219z" fill={color} />
    <path  d="M539.573 49.198h-178.2c-.786 0-1.561.077-2.342.124V0L11.227 46.417V540.98l347.804 47.62v-50.814c.781.053 1.551.116 2.342.116h178.2c20.846 0 37.8-16.965 37.8-37.8V86.999c0-20.839-16.955-37.801-37.8-37.801zM112.791 351.137l-1.387-38.586c-.417-12.092-.833-26.717-.833-41.341l-.414.006c-2.911 12.872-6.761 27.166-10.32 38.867l-11.108 38.513-15.773-.58-9.353-37.9c-2.829-11.364-5.772-25.099-7.815-37.652l-.259.005c-.511 13.013-.886 27.865-1.521 39.862l-1.523 36.481-17.616-.675 5.315-94.938 25.748-1.004 8.611 32.311c2.761 11.306 5.524 23.538 7.522 35.076l.396.011c2.531-11.37 5.608-24.408 8.564-35.446l9.774-33.307 27.793-1.073 5.297 102.178-21.098-.808zm110.787-20.893-12.686-.254v24.906l-22.82-.869v-24.512l-43.469-.896v-15.166l36.951-61.971 29.338-1.062v61.245l12.686.101v18.478zm41.681 26.746-36.595-111.113 28.129-1.097 14.225 47.218c4.044 13.429 7.752 26.423 10.594 40.64l.527.011c3.035-13.647 6.795-27.18 10.932-40.266l15.684-49.62 30.48-1.189-42.364 116.636-31.612-1.22zm290.514 143.112c0 8.928-7.268 16.199-16.2 16.199h-178.2c-.796 0-1.571-.115-2.342-.231V369.646c.723.854 1.382 1.762 2.131 2.595V236.076c-.749.83-1.408 1.74-2.131 2.592V71.041c.771-.113 1.54-.242 2.342-.242h178.2c8.933 0 16.2 7.269 16.2 16.2v413.103z" fill={color} />
    <path  d="M367.448 245.721h13.468v14.573h-13.468zM367.448 311.232h13.468v14.576h-13.468zM367.448 278.698h13.468v14.576h-13.468zM367.448 344.208h13.468v14.586h-13.468zM512.836 372.226c16.247-18.077 26.229-41.903 26.229-68.064 0-26.164-9.982-49.995-26.229-68.069v136.133zM436.994 406.224c21.257 0 40.996-6.545 57.354-17.688H379.65c16.358 11.143 36.097 17.688 57.344 17.688zM436.994 202.083c-20.366 0-39.318 6.064-55.261 16.39h110.531c-15.946-10.32-34.899-16.39-55.27-16.39zM385.799 231.934h99.837v66.016h-99.837zM385.799 309.055h99.837v67.363h-99.837zM492.465 245.721h13.479v14.573h-13.479zM492.465 311.232h13.479v14.576h-13.479zM492.465 278.698h13.479v14.576h-13.479zM492.465 344.208h13.479v14.586h-13.479z" fill={color} />
  </svg>
)

export default M4v
