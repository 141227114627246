import { createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    loading: false,
    mailingConfigsLoading: false,
    syslogConfigLoading: false,
    error: null,
    mailingConfigsError: null,
    syslogConfigError: null,
    timePeriod: 0,
    mailingConfigs: {
      server: "",
      port: "",
      security: "",
      senderEmail: "",
    },
    mailingActive: false,
    mailContent: {
      mailSubject: "",
      mailBody: "",
    },
    mailRecipientList: [],
    syslogServer: "",
    syslogPort: "",
    syslogConnection: "",
    dumpStatus: "",
    dumpDate: "",
    restoreStatus: "",
    tconfig: "",
    slackconf: {},
    // syslogServerIP: "",
    // syslogServerPort: "",
  },
  reducers: {
    requestTimePeriod: (state) => {
      state.loading = true;
    },
    successTimePeriod: (state, payload) => {
      state.loading = false;
      state.error = null;
      state.timePeriod = payload.payload;
    },
    failureTimePeriod: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    updateTimePeriodRequest: (state) => {
      state.loading = true;
    },
    updateTimePeriodSuccess: (state, payload) => {
      state.loading = false;
      state.error = null;
      state.timePeriod = payload.payload;
    },
    updateTimePeriodFailure: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    requestGetMailingConfigs: (state) => {
      state.mailingConfigsLoading = true;
    },
    successGetMailingConfigs: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigs = payload.payload;
      state.mailingActive = payload.payload.active;
    },
    failureGetMailingConfigs: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestSetMailingConfigs: (state) => {
      state.mailingConfigsLoading = true;
    },
    successSetMailingConfigs: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigs = payload.payload;
      state.mailingActive = payload.payload.active;
    },
    failureSetMailingConfigs: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestResetMailingConfigs: (state) => {
      state.mailingConfigsLoading = true;
    },
    successResetMailingConfigs: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigs = payload.payload;
      state.mailingActive = payload.payload.active;
    },
    failureResetMailingConfigs: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestGetMailRecipients: (state) => {
      state.mailingConfigsLoading = true;
      state.mailingConfigsError = false;
    },
    successGetMailRecipients: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = false;

      state.mailRecipientList = payload.payload.recipients;
    },
    failureGetMailRecipients: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestAddMailRecipients: (state) => {
      state.mailingConfigsLoading = true;
      state.mailingConfigsError = false;
    },
    successAddMailRecipients: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = false;

      state.mailRecipientList = payload.payload.recipients;
    },
    failureAddMailRecipients: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestDeleteMailRecipients: (state) => {
      state.mailingConfigsLoading = true;
      state.mailingConfigsError = false;
    },
    successDeleteMailRecipients: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = false;

      state.mailRecipientList = payload.payload.recipients;
    },
    failureDeleteMailRecipients: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestUpdateMailingActive: (state) => {
      state.mailingConfigsLoading = true;
    },
    successUpdateMailingActive: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingActive = payload.payload.active;
    },
    failureUpdateMailingActive: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestGetMaliciousSubmissionMailContent: (state) => {
      state.mailingConfigsLoading = true;
    },
    successGetMaliciousSubmissionMailContent: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailContent = payload.payload;
    },
    failureGetMaliciousSubmissionMailContent: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestSetMaliciousSubmissionMailContent: (state) => {
      state.mailingConfigsLoading = true;
    },
    successSetMaliciousSubmissionMailContent: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailContent = payload.payload;
    },
    failureSetMaliciousSubmissionMailContent: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    requestResetMaliciousSubmissionMailContent: (state) => {
      state.mailingConfigsLoading = true;
    },
    successResetMaliciousSubmissionMailContent: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailContent = payload.payload;
    },
    failureResetMaliciousSubmissionMailContent: (state, payload) => {
      state.mailingConfigsLoading = false;
      state.mailingConfigsError = payload.payload;
    },
    dumpDatabaseRequest: () => {},
    requestDumpStatus: () => {},
    updateDumpStatus: (state, action) => {
      state.dumpStatus = action.payload.dumpStatus;
      state.dumpDate = action.payload.dumpDate;
    },
    requestRestoreStatus: () => {},
    updateRestoreStatus: (state, action) => {
      state.restoreStatus = action.payload.restoreStatus;
    },
    requestGetSyslogConfig: (state) => {
      state.syslogConfigLoading = true;
    },
    successGetSyslogConfig: (state, action) => {
      state.syslogConfigLoading = false;

      state.syslogConfigError = false;
      state.syslogServer = action.payload.server;
      state.syslogPort = action.payload.port;
      state.syslogConnection = action.payload.connection;
    },
    failureGetSyslogConfig: (state, payload) => {
      state.syslogConfigLoading = false;
      state.syslogConfigError = payload.payload;
    },
    requestSetSyslogConfig: (state) => {
      state.syslogConfigLoading = true;
    },
    successSetSyslogConfig: (state, action) => {
      state.syslogConfigLoading = false;

      state.syslogConfigError = false;
      state.syslogServer = action.payload.server;
      state.syslogPort = action.payload.port;
      state.syslogConnection = action.payload.connection;
    },
    failureSetSyslogConfig: (state, payload) => {
      state.syslogConfigLoading = false;
      state.syslogConfigError = payload.payload;
    },
    requestDeleteSyslogConfig: (state, payload) => {
      state.syslogConfigLoading = true;
    },
    successDeleteSyslogConfig: (state, action) => {
      state.syslogConfigLoading = false;

      state.syslogConfigError = false;
      state.syslogServer = "";
      state.syslogPort = "";
      state.syslogConnection = "";
    },
    failureDeleteSyslogConfig: (state, payload) => {
      state.syslogConfigLoading = false;
      state.syslogConfigError = payload.payload;
    },
    requestSetTelegramConfig: (state) => {
      state.loading = true;
    },
    successSetTelegramConfig: (state) => {
      state.loading = false;
    },
    failureSetTelegramConfig: (state) => {
      state.loading = false;
    },
    requestGetTelegramConfig: (state) => {
      state.loading = true;
    },
    successGetTelegramConfig: (state, payload) => {
      state.loading = false;
      state.tconfig = payload.payload;
    },
    failureGetTelegramConfig: (state) => {
      state.loading = false;
    },
    requestSetSlackConfig: (state) => {
      state.loading = true;
    },
    successSetSlackConfig: (state) => {
      state.loading = false;
    },
    failureSetSlackConfig: (state) => {
      state.loading = false;
    },
    requestGetSlackConfig: (state) => {
      state.loading = true;
    },
    successGetSlackConfig: (state, payload) => {
      state.loading = false;
      state.slackconf = payload.payload;
    },
    failureGetSlackConfig: (state) => {
      state.loading = false;
    },
  },
});

export default settingsSlice;
