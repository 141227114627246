import { useState } from "react";

import TabButton from "../../../../../Components/common/TabButton/TabButton";

import "./styles.scss";
import TelegramSettings from "./Tabs/TelegramSettings";
import SlackSettings from "./Tabs/SlackSettings";

const SocialNotification = () => {
  const [activeTab, setActive] = useState("Slack");

  return (
    <div className="mailing">
      <div className="mailing__header">
        <h1>Social Notification</h1>
      </div>
      <div className="mailing__body">
        <div className="button-block">
          <TabButton title="Telegram" onClick={setActive} active={activeTab} />
          <TabButton title="Slack" onClick={setActive} active={activeTab} />
        </div>
        <div style={{ background: "var(--card-background-color)" }}>
          {activeTab === "Telegram" ? <TelegramSettings /> : <SlackSettings />}
        </div>
      </div>
    </div>
  );
};

export default SocialNotification;
