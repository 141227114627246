import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import CustomTable from "../../../common/Table/CustomTable";
import {
  compressedHeader,
  statisticsListPopupHeader,
} from "../../../../utils/constants/tableHeaders";
import { filterListCompressedPopupData } from "../utils/statisticsUtil";

import "./styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const StatisticsListPopup = ({ show, groupID, list = [], data = [] }) => {
  const history = useHistory();
  let query = useQuery();

  const tableType = query.get("table");

  return (
    <div
      className={`${show && "statisticsListGroup-backdrop"}`}
      onClick={() => history.push("/statistics?")}>
      <div onClick={(e) => e.stopPropagation()}>
        {show ? (
          <div
            className={`statisticsListGroup border ${
              tableType === "compressed" ? "statisticsListGroup--compressed" : ""
            }`}>
            <div className="statisticsListGroup-buttons">
              {tableType === "compressed" && (
                <div onClick={history.goBack} className="statisticsListGroup-buttons__go-back">
                  &larr;
                </div>
              )}
              <div className="statisticsListGroup-close-button__outer">
                <div
                  onClick={() => history.push("/statistics?")}
                  className="statisticsListGroup-close-button__inner">
                  <label className="statisticsListGroup-close-button__close">Close</label>
                </div>
              </div>
            </div>
            {tableType !== "compressed" && (
              <table
                style={{
                  width: "90%",
                  borderBottom: "1px solid white",
                  color: "white",
                  marginTop: "20px",
                }}>
                <tbody>
                  <tr style={{ width: "90%" }}>
                    <td style={{ paddingLeft: "20px" }}>Type</td>
                    <td style={{ paddingLeft: "20px" }}>Network Traffic</td>
                    <td style={{ paddingRight: "65px" }}>Os</td>
                    <td style={{ paddingRight: "35px" }}>Verdict</td>
                  </tr>
                </tbody>
              </table>
            )}
            <div
              className={`statisticsListGroupTable ${
                tableType === "compressed" ? "statisticsListGroupTable--compressed" : ""
              }`}>
              {tableType === "compressed" ? (
                <CustomTable
                  column={compressedHeader}
                  dataStatisticsListPopup={filterListCompressedPopupData(list, groupID)}
                  leftAction={false}
                  isSetUrl
                  paginationButtons={false}
                  className=""
                  tableName={"statisticsListCompressedPopup"}
                  refresh={false}
                  customPageSize={200}
                  minHeight={50}
                />
              ) : (
                <CustomTable
                  column={statisticsListPopupHeader}
                  dataStatisticsListPopup={data}
                  leftAction={false}
                  isSetUrl
                  paginationButtons={false}
                  className=""
                  tableName={"statisticsListPopup"}
                  refresh={false}
                  customPageSize={200}
                  minHeight={50}
                />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StatisticsListPopup;
