import { useState } from "react";

import ArrowBottomIcon from "../../Icon-SVG/ArrowBottomIcon";
import CustomMultiSelectOptions from "./CustomMultiSelectOptions/CustomMultiSelectOptions";
import { getSelectedOptionsFieldString, isAnyChecked } from "./utils/customMultiSelectUtils.js";
import "./styles.scss";

const CustomMultiSelect = ({ options, setOptions, placeholder }) => {
  const [active, setActive] = useState(false);

  const selectClickHandler = () => setActive((prevState) => !prevState);

  const onToggleSelectAll = (isChecked) =>
    setOptions((options) => options.map((option) => ({ ...option, selected: isChecked })));

  const onToggleSelectSingle = (optionId, isChecked) =>
    setOptions((options) =>
      options.map((option) =>
        option.value === optionId ? { ...option, selected: isChecked } : option,
      ),
    );

  return (
    <div className="multiselect__container">
      <div
        className={`multiselect__select ${active ? "multiselect__select--active" : ""}`}
        onClick={selectClickHandler}>
        {isAnyChecked(options) ? (
          <p className="multiselect__text--extralight">
            {getSelectedOptionsFieldString(options, "label", 18)}
          </p>
        ) : (
          <p className="multiselect__text--light">{placeholder}</p>
        )}
        <div className="multiselect__select__expand-arrow">
          <ArrowBottomIcon width={14} height={14} />
        </div>
      </div>
      {active && (
        <CustomMultiSelectOptions
          options={options}
          onToggleSelectAll={onToggleSelectAll}
          onToggleSelectSingle={onToggleSelectSingle}
        />
      )}
    </div>
  );
};

export default CustomMultiSelect;
