import * as React from "react";

const BankerIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".bk{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <circle className="bk" cx={512} cy={621.5} r={127} />
      <circle className="bk" cx={512} cy={503.8} r={45.4} />
      <path
        className="bk"
        d="m426.8 561.2-58.7-58.7v-71.8h25.8v61.2L445 543zM393.9 799.3h-25.8v-83l50.8-50.8 18.2 18.3-43.2 43.2zM420.1 593.9h-83l-50.8-50.7 18.2-18.3 43.3 43.3h72.3zM304.5 701.1l-18.2-18.2 50.8-50.8h83v25.8h-72.3zM597.2 561.2 579 543l51.1-51.1v-61.2h25.8v71.8zM655.9 799.3h-25.8V727l-43.2-43.2 18.2-18.3 50.8 50.8zM686.9 593.9h-83v-25.7h72.3l43.3-43.3 18.2 18.3zM719.5 701.1l-43.3-43.2h-72.3v-25.8h83l50.8 50.8z"
      />
      <path
        className="bk1"
        d="M103.7 404.8h161.7v42.5H103.7zM103.7 818.1h161.7v42.5H103.7zM121.6 447.3v370.8h125.8V447.3H121.6zm80.1 334.9c0 9.5-7.7 17.2-17.2 17.2s-17.2-7.7-17.2-17.2v-299c0-9.5 7.7-17.2 17.2-17.2s17.2 7.7 17.2 17.2v299zM758.6 404.8h161.7v42.5H758.6zM758.6 818.1h161.7v42.5H758.6zM776.6 447.3v370.8h125.8V447.3H776.6zm80 334.9c0 9.5-7.7 17.2-17.2 17.2s-17.2-7.7-17.2-17.2v-299c0-9.5 7.7-17.2 17.2-17.2s17.2 7.7 17.2 17.2v299zM495.5 189.8c0 5.6 5.4 8.6 13.4 10.9v-23.4c-8.6 1.2-13.4 6-13.4 12.5z"
        fill={color}
      />
      <path
        className="bk1"
        d="M512 70 62 293.8v80.9h900v-80.9L512 70zM204.6 302.9l193.2-96.1c-2 8.5-3 17.4-3 26.4 0 26.1 8.5 50.2 22.9 69.6H204.6zm322.5-18.3V302h-18.2v-17.2c-20.1-1.7-34.9-9.4-45.2-19.9l14.8-20.7c7.1 7.5 17.4 14.2 30.5 16.5v-30.3c-19.7-5-41.2-12.3-41.2-38.3 0-19.3 15.3-35.8 41.2-38.5v-17.8h18.2V154c15.5 1.7 28.5 7.7 38.9 17L551 190.9c-6.9-6.3-15.1-10.3-23.8-12.5v27c19.7 5 41.8 12.6 41.8 38.7-.1 21.3-13.9 37.6-41.9 40.5zm79.2 18.3c14.4-19.5 22.9-43.5 22.9-69.6 0-9.1-1-17.9-3-26.4L819.4 303H606.3z"
        fill={color}
      />
      <path
        className="bk1"
        d="M527.1 235v25.7c9.4-1.9 14.2-7.5 14.2-13.4 0-6.4-5.9-9.6-14.2-12.3zM62 890.3h900V954H62z"
        fill={color}
      />
    </g>
  </svg>
);

export default BankerIcon;
