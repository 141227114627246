import React from "react";

const EyeCloseIcon = ({ color = "var(--text-color-card)", width = 24, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 20"
      fill="none">
      <path
        d="M16.185 17.368L13.764 14.9455C12.8278 15.2802 11.8157 15.3422 10.8456 15.1243C9.87554 14.9063 8.98729 14.4173 8.28423 13.7143C7.58117 13.0112 7.09221 12.123 6.87424 11.1529C6.65628 10.1828 6.71827 9.17074 7.053 8.2345L3.963 5.1445C1.407 7.417 0 10 0 10C0 10 4.5 18.25 12 18.25C13.4406 18.245 14.8649 17.9448 16.185 17.368ZM7.815 2.632C9.13508 2.05514 10.5594 1.75496 12 1.75C19.5 1.75 24 10 24 10C24 10 22.5915 12.5815 20.0385 14.857L16.9455 11.764C17.2802 10.8278 17.3422 9.81572 17.1243 8.84563C16.9063 7.87554 16.4173 6.98729 15.7143 6.28423C15.0112 5.58117 14.123 5.09221 13.1529 4.87424C12.1828 4.65628 11.1707 4.71827 10.2345 5.053L7.815 2.6335V2.632Z"
        fill={color}
      />
      <path
        d="M8.28725 9.46899C8.20474 10.0455 8.25762 10.6332 8.4417 11.1857C8.62577 11.7382 8.93599 12.2402 9.34777 12.652C9.75954 13.0638 10.2616 13.374 10.814 13.5581C11.3665 13.7421 11.9543 13.795 12.5308 13.7125L8.28575 9.46899H8.28725ZM15.7123 10.531L11.4688 6.28599C12.0452 6.20348 12.633 6.25636 13.1855 6.44044C13.7379 6.62452 14.24 6.93473 14.6517 7.34651C15.0635 7.75829 15.3737 8.26031 15.5578 8.81279C15.7419 9.36527 15.7948 9.95303 15.7123 10.5295V10.531ZM20.4688 19.531L2.46875 1.53099L3.53075 0.468994L21.5308 18.469L20.4688 19.531Z"
        fill={color}
      />
    </svg>
  );
};

export default EyeCloseIcon;
