import React from "react";

const CompareIcon = ({ color = "var(--border)", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3809 16.1905L9.9566 15.7663L9.53233 16.1905L9.9566 16.6148L10.3809 16.1905ZM18.7618 7.20957C18.4304 7.20957 18.1618 7.4782 18.1618 7.80957C18.1618 8.14094 18.4304 8.40957 18.7618 8.40957V7.20957ZM13.7661 11.9567L9.9566 15.7663L10.8051 16.6148L14.6146 12.8053L13.7661 11.9567ZM9.9566 16.6148L13.7661 20.4243L14.6146 19.5758L10.8051 15.7663L9.9566 16.6148ZM10.3809 16.7905H18.3809V15.5905H10.3809V16.7905ZM23.1713 12V11.6191H21.9713V12H23.1713ZM23.1713 11.6191C23.1713 9.18378 21.1971 7.20957 18.7618 7.20957V8.40957C20.5344 8.40957 21.9713 9.84652 21.9713 11.6191H23.1713ZM18.3809 16.7905C21.0266 16.7905 23.1713 14.6458 23.1713 12H21.9713C21.9713 13.983 20.3638 15.5905 18.3809 15.5905V16.7905Z"
        fill={color}
      />
      <path
        d="M14.1904 7.80948L14.6147 8.23374L15.039 7.80948L14.6147 7.38521L14.1904 7.80948ZM5.80948 16.7904C6.14085 16.7904 6.40948 16.5218 6.40948 16.1904C6.40948 15.8591 6.14085 15.5904 5.80948 15.5904L5.80948 16.7904ZM10.8052 12.0433L14.6147 8.23374L13.7662 7.38521L9.95664 11.1947L10.8052 12.0433ZM14.6147 7.38521L10.8052 3.57569L9.95664 4.42422L13.7662 8.23374L14.6147 7.38521ZM14.1904 7.20948L6.19043 7.20947L6.19043 8.40947L14.1904 8.40948L14.1904 7.20948ZM1.39995 11.9999L1.39995 12.3809L2.59995 12.3809L2.59995 12L1.39995 11.9999ZM1.39995 12.3809C1.39995 14.8162 3.37416 16.7904 5.80948 16.7904L5.80948 15.5904C4.03691 15.5904 2.59995 14.1535 2.59995 12.3809L1.39995 12.3809ZM6.19043 7.20947C3.54472 7.20947 1.39995 9.35424 1.39995 11.9999L2.59995 12C2.59996 10.017 4.20746 8.40947 6.19043 8.40947L6.19043 7.20947Z"
        fill={color}
      />
    </svg>
  );
};

export default CompareIcon;
