import React, { useEffect, useMemo, useState } from "react";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import SortArrowIcon from "../../Icon-SVG/SortArrowIcon";
import ExportXLSX from "../../../utils/ExportXLSX";
import ExportReactCSV from "../../../utils/ExportCSV";
import PaginationLeftIcon from "../../Icon-SVG/PaginationLeftIcon";
import PaginationRightIcon from "../../Icon-SVG/PaginationRightIcon";
import TableSpinnerIcon from "../../Icon-SVG/TableSpinnerIcon";
import TrashBucketIcon from "../../Icon-SVG/TrashBucketIcon";
import Warning from "../Warning/Warning";
import Backdrop from "../../UI/Backdrop/Backdrop";
import Row from "./Components/Row/Row";
import CustomSelect from "../CustomSelect/CustomSelect";
import ExpandButton from "./Components/ExpandButton";
import CheckboxTable from "./Components/CheckboxTable";
import { useDispatch, useSelector } from "react-redux";
import {
  RequestCleanRunningTab,
  RequestRunningSubmissions,
  RequestSubmissionsList,
  SetSubmittedFile,
} from "../../../store/actions/dashboardActions";
import { RequestNetworkDetections } from "../../../store/actions/networkDefenderActions";
import {
  RequestIocDomainsAndIps,
  RequestIocUrls,
} from "../../../store/actions/analysisReportActions";
import {
  checkSubmissionisFavorite,
  filterByDistinctGroupID,
  getCompressedItemsOfCompressed,
  getStaticLink,
  isCompressedItself,
  isNotCompressedItself,
  isNotRelatedToCompressed,
  isRelatedToCompressed,
  selectGroupSeverity,
} from "../../../utils/function";

import "./styles.scss";

export const FILTER_BY_TIME = [
  {
    id: "1-hour",
    value: "1 hour",
  },
  { id: "3-hour", value: "3 hours" },
  { id: "1-day", value: "1 day" },
  { id: "7-day", value: "7 days" },
  { id: "30-day", value: "30 days" },
  { id: "all-all", value: "Show all" },
];

const progressOptions = {
  hour12: false,
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
const progressOptionsHours = {
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const parseDate = (date) => {
  if (date.split(" ").length > 1) return date.split("(")[1].split(")")[0];
  return new Date(Date.parse(date)).toLocaleString("zh-CN", progressOptionsHours);
};

const severitySelect = (decisionData) => {
  if (decisionData === 0) {
    return "not suspicious";
  } else if (decisionData === 1) {
    return "suspicious";
  } else if (decisionData === 2) {
    return "malicious";
  } else {
    return "loading";
  }
};

const CustomTable = ({
  column = [],
  datavariables = [],
  dataOverview = [],
  dataOverviewDetails = [],
  dataNetworkDetections = [],
  selectedHost = "",
  dataEmail = [],
  dataSnortSuricata = [],
  dataStatistics = [],
  dataStatisticsListPopup = [],
  tableName = "",
  tableName2 = "",
  setActive,
  exportFile = false,
  paginationButtons = true,
  leftAction = false,
  isSetUrl = false,
  className = "border",
  setCurrentFile = false,
  minHeight = "475px",
  minBottom = "0px",
  customPageSize = 10,
  defaultPosition = "absolute",
  refresh = true,
  checkbox = false,
  setCheckbox = null,
  noDataText = "No Data",
  renderRowSubComponent = null,
  minHeightRow = "55px",
  subTable = false,
  tableRef = null,
  listGroupData = "",
  paginationHeight = "",
  paginationBackColor = "transparent",
  paginationBorder = false,
  intName = "",
  reportPage = false,
  tableBorder = true,
  agentHostname = "",
  filterByTime = false,
  selectedTimeFilter = null,
  setSelectedTimeFilter = null,
  defaultFilterIndex = 0,
  dataTable = [],
}) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state?.dashboard?.list);
  const groupSubmissions = useSelector((state) => state.dashboard.groupSubmissions);
  const [dataSet, setDataset] = useState([]);
  const [dataListPopup, setDataListPopup] = useState([]);
  const [refreshButton, setRefreshButton] = useState(false);
  const [totalSubmisions, setTotalSubmisions] = useState();
  const [dataType, setDataType] = useState("");
  const [pageNumber, setPageNumber] = useState();
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [show, setShow] = useState(false);
  const submissionsListLoading = useSelector((state) => state?.dashboard?.submissionsListLoading);
  const networkDetectionsLoading = useSelector(
    (state) => state.networkDefender.networkDetectionsLoading,
  );
  const iocUrlsLoading = useSelector((state) => state.analysisReports.iocUrlsLoading);
  const iocDomainsAndIpsLoading = useSelector(
    (state) => state.analysisReports.iocDomainsAndIpsLoading,
  );
  const submittedFile = useSelector((state) => state?.dashboard?.submittedFile);
  const getConfig = useSelector((state) => state.integrations.getConfig);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    localStorage.setItem("tableTimeFilter", JSON.stringify(selectedTimeFilter));
  }, [selectedTimeFilter]);

  useEffect(() => {
    const storedData = localStorage.getItem(tableName);
    if (storedData && filterByTime) {
      const parsedData = JSON.parse(storedData);
      setSelectedTimeFilter(parsedData);
    }
  }, [filterByTime, tableName]);

  useEffect(() => {
    if (filterByTime && !selectedTimeFilter) return;
    if (
      tableName === "latest" ||
      tableName === "malicious" ||
      tableName === "imapemail" ||
      tableName === "fileshare" ||
      tableName === "winshare" ||
      tableName === "icapweb" ||
      tableName === "endpoint" ||
      tableName === "postfixemail" ||
      tableName === "agent" ||
      tableName === "outlook" ||
      tableName === "favorite"
    ) {
      dispatch(RequestSubmissionsList(selectedTimeFilter));
    } else if (tableName === "running") {
      dispatch(RequestRunningSubmissions());
    }
  }, [tableName, selectedTimeFilter, dispatch]);

  //     dispatch(
  //       RequestSubmissionsList({ pageNumber: paginationNumber, timeFilter: selectedTimeFilter }),
  //     );
  //   } else if (tableName === "running") {
  //     dispatch(RequestRunningSubmissions());
  //   }
  // }, [tableName, selectedTimeFilter, dispatch, paginationNumber]);

  useEffect(() => {
    if (tableName === "statistics") {
      gotoPage(0);
    }
  }, [tableName, dataStatistics]);

  useEffect(() => {
    //Refreshes every 20 seconds for update components
    let interval;
    if (
      tableName === "latest" ||
      tableName === "malicious" ||
      tableName === "imapemail" ||
      tableName === "fileshare" ||
      tableName === "winshare" ||
      tableName === "icapweb" ||
      tableName === "endpoint" ||
      tableName === "postfixemail" ||
      tableName === "agent" ||
      tableName === "outlook" ||
      tableName === "favorite"
    ) {
      interval = setInterval(() => {
        dispatch(RequestSubmissionsList(selectedTimeFilter));
      }, 15000);
    } else if (tableName === "networkDetections") {
      interval = setInterval(() => {
        dispatch(RequestNetworkDetections({ host: selectedHost, timeFilter: selectedTimeFilter }));
      }, 15000);
    } else if (tableName === "urls") {
      if (selectedTimeFilter?.id === "1-hour" || selectedTimeFilter?.id === "3-hour") {
        interval = setInterval(() => {
          dispatch(RequestIocUrls(selectedTimeFilter));
        }, 15000);
      }
    } else if (tableName === "domainsAndIps") {
      if (selectedTimeFilter?.id === "1-hour" || selectedTimeFilter?.id === "3-hour") {
        interval = setInterval(() => {
          dispatch(RequestIocDomainsAndIps(selectedTimeFilter));
        }, 15000);
      }
    } else if (tableName === "running") {
      interval = setInterval(() => {
        dispatch(RequestRunningSubmissions());
      }, 15000);
    }
    return () => clearInterval(interval);
  }, [dispatch, selectedTimeFilter, tableName, selectedHost]);

  useEffect(() => {
    if (submittedFile && submittedFile.state === true) {
      if (submittedFile.from === "staticSubmit") {
        dispatch(RequestSubmissionsList(selectedTimeFilter));
      } else {
        dispatch(RequestRunningSubmissions());
      }
      dispatch(SetSubmittedFile({ state: false, from: null }));
    }
  }, [submittedFile, selectedTimeFilter]);

  useEffect(() => {
    setDataType(tableName);

    const filterAndMapData = (filteredData, fileType = "") => {
      return filteredData.map((listData) => {
        let listTypeTags = "";
        let listNetworkTags = "";
        if (listData.fileID.includes("full_triage")) listTypeTags = " Full Tr.";
        if (listData.fileID.includes("high_evasion")) listTypeTags = " High Ev.";
        if (listData.fileID.includes("basic_evasion")) listTypeTags = " Basic Ev.";
        if (listData.fileID.includes("internet")) listNetworkTags = " Internet";
        if (listData.fileID.includes("vpn")) listNetworkTags = " Vpn";
        if (listData.fileID.includes("simulate")) listNetworkTags = " Simulate";
        if (listData.fileID.includes("disable")) listNetworkTags = " Disable";

        return {
          id: listData.fileID,
          networkTraffic: listNetworkTags,
          typeTag: listTypeTags,
          file: {
            name: listData.file_name,
            url: isCompressedItself(
              listData.compressedID,
              listData.compressedIDfull,
              listData.fileIDfull,
            )
              ? window.location.pathname +
                "?data=" +
                listData.compressedIDfull +
                "&table=compressed"
              : "/analysis-reports?data=" +
                listData.parentID +
                "&state=" +
                severitySelect(listData.decision),
            fileType: fileType,
            hash: listData.fileHash,
            type: listData.fileType,
          },
          hashIcon: {
            fileType: listData.file_name.split(".").pop(),
          },
          oS: listData.machine,
          progress: listData.status,
          severity: severitySelect(listData.decision),
          VmDashboard: {
            url: "/remote-connection?data=" + listData.parentID,
          },
        };
      });
    };

    let filteredData;
    let fileType = "";

    switch (tableName) {
      case "runningPopup":
        filteredData = list?.filter(
          (filterData) =>
            filterData.compressedIDfull === listGroupData &&
            isNotCompressedItself(
              filterData.compressedID,
              filterData.compressedIDfull,
              filterData.fileIDfull,
            ),
        );
        break;
      case "compressed":
        filteredData = list?.filter(
          (filterData) =>
            filterData.compressedIDfull === listGroupData &&
            isNotCompressedItself(
              filterData.compressedID,
              filterData.compressedIDfull,
              filterData.fileIDfull,
            ),
        );
        fileType = "compressed";
        break;
      case "compressedStaticResults":
        filteredData = list
          ?.filter(
            (filterData) =>
              filterData.compressedIDfull === listGroupData &&
              isNotCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ),
          )
          .map((listData) => {
            const fileUrl = `${window.location.pathname}?data=${listData.fileHash}&table=static-results&compressed=${listData.compressedIDfull}`;

            return {
              id: listData.fileID,
              file: {
                name: listData.file_name,
                url: fileUrl,
                urlFrom: "compressedStaticResults",
                fileType: "compressed",
                hash: listData.fileHash,
                type: listData.fileType,
              },
              hashIcon: {
                fileType: listData.file_name.split(".").pop(),
              },
              progress: listData.status,
              severity: severitySelect(listData.decision),
            };
          });
        break;
      case "listMaliciousPopup":
      case "listPopup":
      case "listOutlookPopup":
      case "listFilesharePopup":
      case "listWinsharePopup":
      case "listProxyPopup":
      case "listEndpointPopup":
      case "listPostfixEmailPopup":
      case "listImapEmailPopup":
      case "listNetworkDetectionsPopup":
        filteredData = list?.filter(
          (filterData) => filterData.status === "Completed" && filterData.groupID === listGroupData,
        );
        break;
      case "analysisReportsPopup":
        filteredData = [...groupSubmissions];
        break;
      default:
        filteredData = [];
        break;
    }

    if (tableName === "compressedStaticResults") {
      setDataListPopup(filteredData);
    } else {
      setDataListPopup(filterAndMapData(filteredData, fileType).reverse());
    }

    const latestGroupCounter = (id, osType) => {
      let counter = 0;

      list?.map((data) =>
        data.status === "Completed" && data.groupID === id
          ? data.machine === osType && (counter = counter + 1)
          : null,
      );

      return counter;
    };

    const setGroupCounterColumn = (groupID) => {
      return `${latestGroupCounter(groupID, "win7") ? "win7" : ""}${
        latestGroupCounter(groupID, "win7") && latestGroupCounter(groupID, "win10") ? "," : ""
      }${latestGroupCounter(groupID, "win10") ? "win10" : ""}${
        (latestGroupCounter(groupID, "win7") || latestGroupCounter(groupID, "win10")) &&
        latestGroupCounter(groupID, "win11")
          ? ","
          : ""
      }${latestGroupCounter(groupID, "win11") ? "win11" : ""}${
        (latestGroupCounter(groupID, "win7") ||
          latestGroupCounter(groupID, "win10") ||
          latestGroupCounter(groupID, "win11")) &&
        latestGroupCounter(groupID, "linux22")
          ? ","
          : ""
      }${latestGroupCounter(groupID, "linux22") ? "linux22" : ""}`;
    };

    if (tableName === "latest") {
      let extractedCount = 0;

      setDataset(
        list
          ?.filter(
            (filterData) =>
              filterData.status === "Completed" && filterData.file_name !== "freerun.txt",
          )
          .filter(
            (filterData, i, arr) =>
              filterByDistinctGroupID(filterData, i, arr) &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                user: listData.userName,
                time: {
                  time: listData.run_time,
                  startTime: listData.analysis_start_time,
                  completeTime: listData.complete_time,
                  submitType: listData.submitType,
                },
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                  submitType: listData.submitType,
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  url:
                    tableName2 === "historical"
                      ? "/historical-analysis?data=" + listData.groupID + "&table=latest"
                      : "/dashboard?data=" + listData.groupID + "&table=latest",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                progress: listData.status,
                severity: selectGroupSeverity(list, listData.groupID),
                compressedFileCount:
                  ((extractedCount = list.filter(
                    (extractFile) =>
                      extractFile.status === "Completed" &&
                      extractFile.groupID === listData.groupID,
                  ).length),
                  extractedCount === 0
                    ? 1
                    : listData.isStatic && isRelatedToCompressed(listData.compressedID)
                    ? extractedCount - 1
                    : extractedCount),
                usersFav: listData.usersFav,
                staticResultLink: getStaticLink(
                  listData.fileHash,
                  listData.compressedIDfull,
                  listData.compressedID,
                  tableName2 === "historical" ? "historical-analysis" : "dashboard",
                ),
                isStatic: listData.isStatic,
              },
          ),
      );
    } else if (tableName === "outlook") {
      let extractedCount = 0;
      setDataset([]);

      setDataset(
        list
          ?.filter(
            (filterData, i, arr) =>
              filterData.status === "Completed" &&
              filterByDistinctGroupID(filterData, i, arr) &&
              filterData.submitType === "AddIn" &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                user: listData.userName,
                time: {
                  time: listData.run_time,
                  submitType: listData.submitType,
                },
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                  submitType: listData.submitType,
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  url: "/outlook?data=" + listData.groupID + "&table=outlook",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                progress: listData.status,
                severity: selectGroupSeverity(list, listData.groupID),
                compressedFileCount:
                  ((extractedCount = list.filter(
                    (extractFile) =>
                      extractFile.status === "Completed" &&
                      extractFile.groupID === listData.groupID,
                  ).length),
                  extractedCount === 0 ? 1 : extractedCount),
                usersFav: listData.usersFav,
              },
          ),
      );
    } else if (tableName === "favorite") {
      let extractedCount = 0;

      setDataset(
        list
          ?.filter(
            (filterData) =>
              checkSubmissionisFavorite(filterData.usersFav, user.email) &&
              filterData.file_name !== "freerun.txt",
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                user: listData.userName,
                time: {
                  time: listData.run_time,
                  startTime: listData.analysis_start_time,
                  completeTime: listData.complete_time,
                  submitType: listData.submitType,
                },
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                  submitType: listData.submitType,
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  url:
                    tableName2 === "historical"
                      ? "/historical-analysis?data=" + listData.groupID + "&table=latest"
                      : "/dashboard?data=" + listData.groupID + "&table=latest",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                progress: listData.status,
                severity: selectGroupSeverity(list, listData.groupID),
                compressedFileCount:
                  ((extractedCount = list.filter(
                    (extractFile) =>
                      extractFile.status === "Completed" &&
                      extractFile.groupID === listData.groupID,
                  ).length),
                  extractedCount === 0
                    ? 1
                    : listData.isStatic && isRelatedToCompressed(listData.compressedID)
                    ? extractedCount - 1
                    : extractedCount),
                usersFav: listData.usersFav,
                isStatic: listData.isStatic,
              },
          ),
      );
    } else if (tableName === "malicious") {
      let extractedCount = 0;

      setDataset(
        list
          ?.filter(
            (filterData, i, arr) =>
              selectGroupSeverity(list, filterData.groupID) === "malicious" &&
              filterByDistinctGroupID(filterData, i, arr) &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)) &&
              filterData.file_name !== "freerun.txt",
          )
          .map((listData) => ({
            id: listData.fileID,
            user: listData.userName,
            type: listData.fileType,
            time: {
              time: listData.run_time,
              startTime: listData.analysis_start_time,
              completeTime: listData.complete_time,
              submitType: listData.submitType,
            },
            hashIcon: {
              fileType: listData.file_name.split(".").pop(),
              submitType: listData.submitType,
            },
            file: {
              name: listData.file_name,
              hash: listData.fileHash,
              type: listData.fileType,
              url: "/dashboard?data=" + listData.groupID + "&table=malicious",
            },
            groupCounter: setGroupCounterColumn(listData.groupID),
            progress: parseDate(listData.complete_time),
            severity: "malicious",
            compressedFileCount:
              ((extractedCount = list.filter(
                (extractFile) =>
                  extractFile.status === "Completed" && extractFile.groupID === listData.groupID,
              ).length),
              extractedCount === 0
                ? 1
                : listData.isStatic && isRelatedToCompressed(listData.compressedID)
                ? extractedCount - 1
                : extractedCount),
            isStatic: listData.isStatic,
          })),
      );
    } else if (tableName === "running") {
      let extractedCount = 0;
      setDataset([]);
      //Latest Submissions Running and reporting data convert to array
      setDataset(
        list
          ?.filter(
            (filterData) =>
              filterData.status !== "Completed" &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map((listData) => ({
            id: listData.fileID,
            user: listData.userName,
            time: {
              time: listData.run_time,
              startTime: listData.analysis_start_time,
              completeTime: listData.complete_time,
              submitType: listData.submitOptions,
            },
            file: {
              name: listData.file_name,
              hash: listData.fileHash,
              compressedID: listData.compressedID,
              compressedIDfull: listData.compressedIDfull,
              fileIDfull: listData.fileIDfull,
              url:
                isCompressedItself(
                  listData.compressedID,
                  listData.compressedIDfull,
                  listData.fileIDfull,
                ) &&
                !getCompressedItemsOfCompressed(listData.compressedIDfull, list).every(
                  (compressedItem) =>
                    compressedItem.status === "Error Analyser" ||
                    compressedItem.status === "Error Reporter",
                )
                  ? `/dashboard?data=${listData.compressedIDfull}&table=running`
                  : "",
            },
            VmDashboard: {
              url: "/remote-connection?data=" + listData.parentID,
            },
            oS: listData.machine,
            progress: listData.status,
            severity: "loading",
            compressedFileCount: 1,
            staticResultLink: getStaticLink(
              listData.fileHash,
              listData.compressedIDfull,
              listData.compressedID,
              "dashboard",
            ),
          })),
      );
    } else if (tableName === "imapemail") {
      setDataset([]);

      setDataset(
        list
          ?.filter(
            (filterData, i, arr) =>
              filterData.status === "Completed" &&
              filterByDistinctGroupID(filterData, i, arr) &&
              filterData.submitType === "IMAP" &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                sender: {
                  email: JSON.parse(listData.integration).sender.split("<")[1].split(">")[0],
                },
                time: listData.run_time,
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  submitType: listData.submitType,
                  url: "/imapemail?data=" + listData.groupID + "&table=imapemail",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                recipients: JSON.parse(listData.integration).recipients,
                progress:
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptions,
                  ) +
                  " (" +
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptionsHours,
                  ) +
                  ")",
                severity: selectGroupSeverity(list, listData.groupID),
              },
          ),
      );
    } else if (tableName === "fileshare") {
      setDataset([]);

      setDataset(
        list
          ?.filter(
            (filterData, i, arr) =>
              filterData.status === "Completed" &&
              filterByDistinctGroupID(filterData, i, arr) &&
              filterData.submitType === "ShareP." &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                sender: {
                  name: "Fileshare system",
                },
                path: JSON.parse(listData.integration).fullpath,
                time: listData.run_time,
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  submitType: listData.submitType,
                  url: "/sharepoint?data=" + listData.groupID + "&table=fileshare",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                progress:
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptions,
                  ) +
                  " (" +
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptionsHours,
                  ) +
                  ")",
                severity: selectGroupSeverity(list, listData.groupID),
              },
          ),
      );
    } else if (tableName === "winshare") {
      setDataset([]);

      setDataset(
        list
          ?.filter(
            (filterData, i, arr) =>
              filterData.status === "Completed" &&
              filterByDistinctGroupID(filterData, i, arr) &&
              filterData.submitType === "File Sh." &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                sender: {
                  name: "winshare system",
                },
                path: JSON.parse(listData.integration).fullpath,
                time: listData.run_time,
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  submitType: listData.submitType,
                  url: "/fileshare?data=" + listData.groupID + "&table=winshare",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                progress:
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptions,
                  ) +
                  " (" +
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptionsHours,
                  ) +
                  ")",
                severity: selectGroupSeverity(list, listData.groupID),
              },
          ),
      );
    } else if (tableName === "icapweb") {
      setDataset([]);

      setDataset(
        list
          ?.filter(
            (filterData, i, arr) =>
              filterData.status === "Completed" &&
              filterByDistinctGroupID(filterData, i, arr) &&
              filterData.submitType === "Proxy" &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                sender: {
                  name: JSON.parse(listData.integration).username,
                },
                path: JSON.parse(listData.integration).client_ip,
                time: listData.run_time,
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  submitType: listData.submitType,
                  url: "/proxy?data=" + listData.groupID + "&table=icapweb",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                progress:
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptions,
                  ) +
                  " (" +
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptionsHours,
                  ) +
                  ")",
                severity: selectGroupSeverity(list, listData.groupID),
              },
          ),
      );
    } else if (tableName === "endpoint") {
      setDataset([]);

      setDataset(
        list
          ?.filter(
            (filterData, i, arr) =>
              filterData.status === "Completed" &&
              filterByDistinctGroupID(filterData, i, arr) &&
              (filterData.submitType === "Endpoint" || filterData.submitType === "Agent") &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                sender: {
                  name:
                    listData?.submitType === "Endpoint"
                      ? JSON.parse(listData.integration).username
                      : listData?.userName,
                },
                path:
                  listData?.submitType === "Endpoint"
                    ? JSON.parse(listData.integration).hostname
                    : listData?.agenthost,
                time: listData.run_time,
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  submitType: listData.submitType,
                  url: "/submissions?data=" + listData.groupID + "&table=endpoint",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                progress:
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptions,
                  ) +
                  " (" +
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptionsHours,
                  ) +
                  ")",
                severity: selectGroupSeverity(list, listData.groupID),
              },
          ),
      );
    } else if (tableName === "postfixemail") {
      setDataset([]);

      setDataset(
        list
          ?.filter(
            (filterData, i, arr) =>
              filterData.status === "Completed" &&
              filterByDistinctGroupID(filterData, i, arr) &&
              filterData.submitType === "Postf." &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" && {
                id: listData.fileID,
                sender: {
                  name: JSON.parse(listData.integration).sender.split("<")[0],
                  email: JSON.parse(listData.integration).sender.split("<")[1],
                },
                time: listData.run_time,
                hashIcon: {
                  fileType: listData.file_name.split(".").pop(),
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  submitType: listData.submitType,
                  url: "/postfixemail?data=" + listData.groupID + "&table=postfixemail",
                },
                groupCounter: setGroupCounterColumn(listData.groupID),
                recipients: JSON.parse(listData.integration).recipients,
                progress:
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptions,
                  ) +
                  " (" +
                  new Date(Date.parse(listData.complete_time)).toLocaleString(
                    "zh-CN",
                    progressOptionsHours,
                  ) +
                  ")",
                severity: selectGroupSeverity(list, listData.groupID),
              },
          ),
      );
    } else if (tableName === "agent") {
      setDataset(
        list
          ?.filter(
            (filterData) =>
              filterData.status === "Completed" && filterData.agenthost === agentHostname,
          )
          .filter(
            (filterData, i, arr) =>
              filterByDistinctGroupID(filterData, i, arr) &&
              (isCompressedItself(
                filterData.compressedID,
                filterData.compressedIDfull,
                filterData.fileIDfull,
              ) ||
                isNotRelatedToCompressed(filterData.compressedID)),
          )
          .map(
            (listData) =>
              listData?.status === "Completed" &&
              listData.agenthost === agentHostname && {
                id: listData.fileID,
                user: listData.userName,
                time: {
                  time: listData.run_time,
                  startTime: listData.analysis_start_time,
                  completeTime: listData.complete_time,
                  submitType: "",
                },
                file: {
                  name: listData.file_name,
                  hash: listData.fileHash,
                  type: listData.fileType,
                  url: "/agentstatus?data=" + listData.groupID + "&table=agent",
                },
                severity: selectGroupSeverity(list, listData.groupID),
                usersFav: listData.usersFav,
              },
          ),
      );
    }
    if (tableName === "listConfigPopup") {
      if (intName === "winshare") {
        if (getConfig?.config) {
          setDataListPopup(
            getConfig?.config?.map((data, i) => {
              const mountpointMatch = data?.mountpoint?.match(/\/\/([^\/]+)(\/.*)/);
              const id = mountpointMatch?.[2] || "";
              const server = mountpointMatch?.[1] || "";

              return {
                id,
                server,
                file: {
                  name: data.mountparams?.split(",").reduce((obj, item) => {
                    const [key, value] = item.split("=");
                    obj[key] = value;
                    return obj;
                  }, {})?.username,
                  url: "integration-market?conf=winshare" + "&t=" + i,
                  listType: "listConfigPopup",
                },
                mountpoint: data?.mountpoint,
                inttype: "winshare",
              };
            }),
          );
        }
      }
      if (intName === "imapemail") {
        if (getConfig?.config) {
          setDataListPopup(
            getConfig.config.map((data, i) => ({
              id: "1",
              server: data?.server,
              file: {
                name: data?.username,
                url: "integration-market?conf=imapemail" + "&t=" + i,
                listType: "listConfigPopup",
              },
              inttype: "imapemail",
              authority: getConfig?.config[i]?.authority,
            })),
          );
        }
      }
      if (intName === "cstrike") {
        if (getConfig?.config) {
          setDataListPopup(
            getConfig.config.map((data, i) => ({
              id: "1",
              file: {
                name: data?.client_id,
                url: "integration-market?conf=cstrike" + "&t=" + i,
                listType: "listConfigPopup",
              },
              secret: data?.secret,
              inttype: "cstrike",
              authority: getConfig?.config[i]?.client_id,
            })),
          );
        }
      }
      if (intName === "rclshare") {
        if (getConfig?.config) {
          setDataListPopup(
            getConfig.config.map((data, i) => ({
              id: data?.authority,
              server: data?.server,
              file: {
                name: data?.type,
                url: "integration-market?conf=rclshare" + "&t=" + i,
                listType: "listConfigPopup",
              },
              inttype: "rclshare",
              authority: getConfig?.config[i]?.authority,
            })),
          );
        }
      }
    }
  }, [list, tableName, getConfig]);

  const data = useMemo(() => {
    if (tableName === "dropped") {
      return datavariables;
    } else if (tableName === "overview") {
      return dataOverview;
    } else if (tableName === "overviewDetails") {
      return dataOverviewDetails;
    } else if (tableName === "snortSuricata") {
      return dataSnortSuricata;
    } else if (tableName === "networkDetections") {
      return dataNetworkDetections;
    } else if (tableName === "usbtransfer") {
      return dataEmail;
    } else if (tableName === "statistics") {
      return dataStatistics;
    } else if (tableName === "statisticsListPopup") {
      return dataStatisticsListPopup;
    } else if (tableName === "statisticsListCompressedPopup") {
      return dataStatisticsListPopup;
    } else if (tableName === "urls") {
      return dataTable;
    } else if (tableName === "domainsAndIps") {
      return dataTable;
    } else if (tableName === "hostsAndDomains") {
      return dataTable;
    } else if (
      tableName === "listPopup" ||
      tableName === "listMaliciousPopup" ||
      tableName === "listImapEmailPopup" ||
      tableName === "listFilesharePopup" ||
      tableName === "listWinsharePopup" ||
      tableName === "listProxyPopup" ||
      tableName === "listEndpointPopup" ||
      tableName === "listPostfixEmailPopup" ||
      tableName === "compressed" ||
      tableName === "listConfigPopup" ||
      tableName === "runningPopup" ||
      tableName === "listOutlookPopup" ||
      tableName === "listNetworkDetectionsPopup" ||
      tableName === "analysisReportsPopup" ||
      tableName === "compressedStaticResults"
    ) {
      return dataListPopup;
    } else {
      return dataSet;
    }
  }, [
    dataSet,
    dataListPopup,
    datavariables,
    dataOverviewDetails,
    dataSnortSuricata,
    dataNetworkDetections,
    dataTable,
    dataEmail,
    dataOverview,
    dataStatistics,
  ]);

  const columns = useMemo(() => {
    return column;
  }, [column]);

  const pageSwitchHandler = (numPage) => {
    if (numPage >= 0) {
      toggleAllRowsExpanded(false);
      if (tableRef) {
        tableRef.current.scrollIntoView();
      }
      setPaginationNumber(numPage + 1);
      gotoPage(numPage);
    } else {
      setPaginationNumber(0);
      gotoPage(0);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    selectedFlatRows,
    visibleColumns,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: customPageSize,
      },
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      leftAction && !checkbox
        ? hooks.visibleColumns.push((columns) => [
            {
              id: "expander",
              Cell: ExpandButton,
            },
            ...columns,
          ])
        : checkbox &&
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <CheckboxTable id={new Date()} {...getToggleAllRowsSelectedProps()} />
              ),
              Cell: ({ row }) => <CheckboxTable id={row.id} {...row.getToggleRowSelectedProps()} />,
            },
            ...columns,
          ]);
    },
  );
  const { pageIndex } = state;

  let leftIconDisabled = false;
  let rightIconDisabled = false;
  if (tableName === "running") {
    leftIconDisabled = pageIndex === 0;
    rightIconDisabled = pageIndex === pageOptions.length - 1;
  } else {
    leftIconDisabled = pageNumber === 0;
    rightIconDisabled = pageNumber === totalSubmisions - 1;
  }

  useEffect(() => {
    if (setCheckbox) {
      selectedFlatRows.length && setCheckbox(selectedFlatRows.map((row) => row.original));
    }
  }, [selectedFlatRows, setCheckbox]);

  const refreshP = async () => {
    setRefreshButton(true);
    if (
      dataType === "latest" ||
      dataType === "malicious" ||
      dataType === "favorite" ||
      dataType === "postfixemail" ||
      dataType === "imapemail" ||
      dataType === "outlook" ||
      dataType === "fileshare" ||
      dataType === "winshare" ||
      dataType === "icapweb" ||
      dataType === "endpoint" ||
      dataType === "usbtransfer" ||
      dataType === "agent"
    ) {
      dispatch(RequestSubmissionsList(selectedTimeFilter));
    } else if (dataType === "running") {
      dispatch(RequestRunningSubmissions());
      setActive("Running");
    } else if (dataType === "networkDetections") {
      dispatch(RequestNetworkDetections({ host: selectedHost, timeFilter: selectedTimeFilter }));
    } else if (dataType === "urls") {
      dispatch(RequestIocUrls(selectedTimeFilter));
    } else if (dataType === "domainsAndIps") {
      dispatch(RequestIocDomainsAndIps(selectedTimeFilter));
    }
  };

  const onSelectItem = (selectedTimeFilter) => {
    setRefreshButton(true);
    localStorage.setItem(tableName, JSON.stringify(selectedTimeFilter));
  };

  const cleanListHandler = () => {
    setShow(false);
    dispatch(RequestCleanRunningTab());
  };

  useEffect(() => {
    if (submittedFile && submittedFile.state === true) {
      if (submittedFile.from === "staticSubmit") {
        tableName = "latest";
        setDataType("latest");
        setActive("Latest");
      } else {
        tableName = "running";
        setDataType("running");
        setActive("Running");
      }
      dispatch(SetSubmittedFile({ state: false, from: null }));
    }
  }, [submittedFile]);

  useEffect(() => {
    if (
      !submissionsListLoading &&
      !networkDetectionsLoading &&
      !iocUrlsLoading &&
      !iocDomainsAndIpsLoading
    ) {
      setRefreshButton(false);
    }
  }, [submissionsListLoading, networkDetectionsLoading, iocUrlsLoading, iocDomainsAndIpsLoading]);
  return (
    <>
      {data && data.length ? (
        <div style={tableName === "dropped" ? { position: "relative" } : {}}>
          <div
            className={`custom-table ${className} ${
              subTable ? "custom-table__sub-table" : "custom-table__main-table"
            }`}
            style={{ minHeight, height: "300px !important" }}
            ref={tableRef}>
            <div className={`custom-table-button-block custom-table-button-block--${tableName}`}>
              {exportFile && (
                <>
                  <ExportXLSX
                    csvData={selectedFlatRows.length ? selectedFlatRows : data}
                    fileName={tableName}
                    selected={selectedFlatRows.length}
                    columns={columns}
                  />
                  <ExportReactCSV
                    csvData={selectedFlatRows.length ? selectedFlatRows : data}
                    selected={selectedFlatRows.length}
                    fileName={`${tableName}.csv`}
                    headers={columns.map((column) => ({
                      label: column.Header,
                      key: column.accessor,
                    }))}
                  />
                </>
              )}
              {tableName === "running" ? (
                <button
                  className="custom-table-button-block__clean-list"
                  onClick={() => setShow(true)}>
                  <TrashBucketIcon color="var(--text-color-card)" width="20" height="20" />
                </button>
              ) : null}
              {refresh && (
                <button
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() => refreshP()}>
                  <TableSpinnerIcon
                    className="spinner"
                    spin={
                      (submissionsListLoading && refreshButton) ||
                      (networkDetectionsLoading && refreshButton) ||
                      (iocUrlsLoading && refreshButton) ||
                      (iocDomainsAndIpsLoading && refreshButton)
                    }
                  />
                </button>
              )}
              {filterByTime ? (
                <div className="custom-table-button-block-filter">
                  <p>Filter by last: </p>
                  <CustomSelect
                    data={FILTER_BY_TIME}
                    select={selectedTimeFilter}
                    setSelected={setSelectedTimeFilter}
                    width="120px"
                    height="26px"
                    defaultIndex={defaultFilterIndex}
                    onSelectItem={onSelectItem}
                  />
                </div>
              ) : null}
            </div>
            <table
              style={tableBorder ? { border: "none" } : {}}
              id={tableName}
              {...getTableProps()}
              className={`${className} ${reportPage && "custom-table-report"}`}>
              <thead>
                {tableName === "listPopup" ||
                tableName === "listMaliciousPopup" ||
                tableName === "listImapEmailPopup" ||
                tableName === "listFilesharePopup" ||
                tableName === "listWinsharePopup" ||
                tableName === "listProxyPopup" ||
                tableName === "listEndpointPopup" ||
                tableName === "statisticsListPopup" ||
                tableName === "listPostfixEmailPopup" ||
                tableName === "listOutlookPopup" ||
                tableName === "listNetworkDetectionsPopup" ||
                tableName === "analysisReportsPopup"
                  ? null
                  : headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th key={column.id} className={column.className}>
                            <div className="th-row">
                              <>
                                {column.Filter && column.canFilter ? column.render("Filter") : null}
                              </>
                              {column.sort ? (
                                <div
                                  className="th-sort"
                                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                                  <p>{column.render("Header")}</p>
                                  {column.Header && (
                                    <span className="m-l-5">
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <SortArrowIcon desc="var(--sort-color-asc)" />
                                        ) : (
                                          <SortArrowIcon asc="var(--sort-color-desc)" />
                                        )
                                      ) : column.id !== "action" ? (
                                        <SortArrowIcon />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <p>{column.render("Header")}</p>
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
              </thead>
              <tbody {...getTableBodyProps()} style={{ minHeight: "minHeightRow" }}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Row
                      arrow={leftAction}
                      key={index}
                      row={row}
                      setCurrentFile={setCurrentFile}
                      visibleColumns={visibleColumns}
                      renderRowSubComponent={renderRowSubComponent}
                      isSetUrl={isSetUrl}
                      minHeight={minHeightRow}
                      tableName={tableName}
                      reportPage={reportPage}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          {paginationButtons && (
            <div
              className={
                paginationBorder
                  ? "custom-table__footer-pagination border"
                  : "custom-table__footer-pagination"
              }
              style={{
                // bottom: minBottom,
                // position: defaultPosition,
                // backgroundColor: paginationBackColor, //"var(--card-background-color)",
                height: paginationHeight,
              }}>
              <div
                className="custom-table__footer-pagination-arrow"
                onClick={() => previousPage()}
                disabled={leftIconDisabled}>
                <PaginationLeftIcon
                  color={
                    leftIconDisabled
                      ? "var(--dropped--file-pagination-arrow-color)"
                      : "var(--dropped--file-pagination-arrow-active-color)"
                  }
                />
              </div>
              {pageOptions.map((number) =>
                number < 1 || pageOptions.length - 2 < number ? (
                  <p
                    className={`custom-table__footer-pagination__number${
                      pageIndex === number ? "__active" : ""
                    }`}
                    key={number}
                    onClick={() => pageSwitchHandler(number)}>
                    {number + 1}
                  </p>
                ) : (pageIndex < 3 && number < 5) ||
                  (pageOptions.length - 4 < pageIndex && pageOptions.length - 6 < number) ? (
                  <p
                    className={`custom-table__footer-pagination__number${
                      pageIndex === number ? "__active" : ""
                    }`}
                    key={number}
                    onClick={() => pageSwitchHandler(number)}>
                    {number + 1}
                  </p>
                ) : (pageIndex < 3 && number === 5) ||
                  (pageOptions.length - 4 < pageIndex && pageOptions.length - 6 === number) ? (
                  <p className={`custom-table__footer-pagination__number`} key={number}>
                    ...
                  </p>
                ) : pageIndex - 2 < number && pageIndex + 2 > number ? (
                  <p
                    className={`custom-table__footer-pagination__number${
                      pageIndex === number ? "__active" : ""
                    }`}
                    key={number}
                    onClick={() => pageSwitchHandler(number)}>
                    {number + 1}
                  </p>
                ) : pageIndex - 2 === number || pageIndex + 2 === number ? (
                  <p className={`custom-table__footer-pagination__number`} key={number}>
                    ....
                  </p>
                ) : null,
              )}
              <div
                className="custom-table__footer-pagination-arrow"
                onClick={() => nextPage()}
                disabled={rightIconDisabled}>
                <PaginationRightIcon
                  color={
                    rightIconDisabled
                      ? "var(--dropped--file-pagination-arrow-color)"
                      : "var(--dropped--file-pagination-arrow-active-color)"
                  }
                />
              </div>
              <div className="custom-table__footer-pagination__number-box">
                <input
                  className="border"
                  type="text"
                  value={
                    paginationNumber === 0
                      ? ""
                      : paginationNumber > pageOptions.length
                      ? pageOptions.length
                      : paginationNumber
                  }
                  onChange={(e) => pageSwitchHandler(Number(e.target.value) - 1)}
                />
                <span> | {pageOptions.length}</span>
              </div>
              <Warning
                onClickOk={cleanListHandler}
                show={show}
                setShow={setShow}
                text="Are you sure to delete error submissions from list?"
                article="Warning"
                cancelDisable={false}
                textOK="Yes"
              />
            </div>
          )}
          {refreshButton && (
            <Backdrop
              absolute={true}
              show={
                submissionsListLoading ||
                networkDetectionsLoading ||
                iocUrlsLoading ||
                iocDomainsAndIpsLoading
              }
            />
          )}
        </div>
      ) : (
        <>
          <div
            className={`custom-table__empty__buttons custom-table__empty__buttons--${tableName}`}>
            {refresh ? (
              <button
                style={{ backgroundColor: "transparent", border: "none" }}
                onClick={() => refreshP()}>
                <TableSpinnerIcon
                  className="spinner"
                  spin={
                    (submissionsListLoading && refreshButton) ||
                    (networkDetectionsLoading && refreshButton) ||
                    (iocUrlsLoading && refreshButton) ||
                    (iocDomainsAndIpsLoading && refreshButton)
                  }
                />
              </button>
            ) : null}
            {filterByTime ? (
              <div className="custom-table__empty__filter">
                <p>Filter by last: </p>
                <CustomSelect
                  data={FILTER_BY_TIME}
                  select={selectedTimeFilter}
                  setSelected={setSelectedTimeFilter}
                  width="120px"
                  height="26px"
                  defaultIndex={defaultFilterIndex}
                  onSelectItem={onSelectItem}
                />
              </div>
            ) : null}
          </div>
          <div className="custom-table__empty">
            {submissionsListLoading ||
            networkDetectionsLoading ||
            iocUrlsLoading ||
            iocDomainsAndIpsLoading
              ? ""
              : noDataText}
          </div>
          {refreshButton && (
            <Backdrop
              absolute={true}
              show={
                submissionsListLoading ||
                networkDetectionsLoading ||
                iocUrlsLoading ||
                iocDomainsAndIpsLoading
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default CustomTable;
