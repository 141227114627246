import React from "react";

const SearchIcon = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none">
      <circle cx="6.5" cy="6.5" r="5.75" stroke={color} strokeWidth="1.5" />
      <path d="M11 11L15 15" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default SearchIcon;
