const DumpAndRestoreIcon = ({ color = "var(--border)", width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    id="database-backup"
    fill={color || "var(--settings-sidebar-icon)"}
    width={width}
    height={height}>
    <path
      d="M16.5,24c-3.6,0-6.5-2.9-6.5-6.5s2.9-6.5,6.5-6.5c2.8,0,5.2,1.7,6.1,4.3c0.1,0.3,0,0.5-0.3,0.6c-0.3,0.1-0.5,0-0.6-0.3
			c-0.8-2.2-2.8-3.7-5.2-3.7c-3,0-5.5,2.5-5.5,5.5s2.5,5.5,5.5,5.5c2,0,3.8-1.1,4.8-2.7c0.1-0.2,0.4-0.3,0.7-0.2
			c0.2,0.1,0.3,0.4,0.2,0.7C21,22.8,18.8,24,16.5,24z"></path>
    <path d="M22.5 16h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H22v-2.5c0-.3.2-.5.5-.5s.5.2.5.5v3C23 15.8 22.8 16 22.5 16zM11 7C6.2 7 1 5.8 1 3.5S6.2 0 11 0s10 1.2 10 3.5S15.8 7 11 7zM11 1C5.4 1 2 2.5 2 3.5S5.4 6 11 6s9-1.5 9-2.5S16.6 1 11 1zM11 10C6 10 1 9 1 7c0-.3.2-.5.5-.5S2 6.7 2 7c0 .7 3.2 2 9 2 .5 0 1 0 1.4 0 .3 0 .5.2.5.5S12.7 10 12.5 10C12 10 11.5 10 11 10zM18.1 9.1c-.2 0-.4-.1-.5-.4-.1-.3.1-.5.3-.6 1.5-.4 2-.9 2-1.2 0-.3.2-.5.5-.5S21 6.7 21 7c0 .9-.9 1.6-2.7 2.1C18.2 9.1 18.2 9.1 18.1 9.1zM9.1 12.9C9.1 12.9 9 12.9 9.1 12.9 5.1 12.7 1 11.9 1 10c0-.3.2-.5.5-.5S2 9.7 2 10c0 .5 2.1 1.7 7.1 1.9.3 0 .5.3.5.5C9.5 12.7 9.3 12.9 9.1 12.9z"></path>
    <path d="M20.5 10C20.2 10 20 9.8 20 9.5v-6C20 3.2 20.2 3 20.5 3S21 3.2 21 3.5v6C21 9.8 20.8 10 20.5 10zM7.8 15.9C7.7 15.9 7.7 15.8 7.8 15.9 6.1 15.7 1 15 1 13V3.5C1 3.2 1.2 3 1.5 3S2 3.2 2 3.5V13c0 .4 1.5 1.4 5.8 1.9.3 0 .5.3.4.5C8.2 15.7 8 15.9 7.8 15.9z"></path>
  </svg>
);

export default DumpAndRestoreIcon;
