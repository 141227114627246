import React from "react";
import LogoIcon from "../../Icon-SVG/LogoIcon";
import DataFlowIcon from "../../Icon-SVG/DataFlowIcon";
import DataflowLogoShort from "../../assets/dataflowLogoShort.png";
import { loadFromLocalStorage } from "../../../utils/localStorage";
//import ToggleButtonChangeTheme from "../ToggleButtonChangeTheme/ToggleButtonChangeTheme";
import "./styles.scss";

const CardForm = ({ title, subTitle, children, error, errorText }) => {
  const theme = loadFromLocalStorage("theme");
  return (
    <div className="card">
      <div className="card__toggle-button">{/*<ToggleButtonChangeTheme />*/}</div>
      <div className="card__form border">
        {title && subTitle && (
          <div className="card__form-header">
            <div className="card__form-header__text">
              <h2>{title}</h2>
              <p>{subTitle}</p>
            </div>
            <div className="card__form-header__logo">
              {theme === "dataflowx" ? <DataFlowIcon /> : <LogoIcon />}
            </div>
          </div>
        )}
        {error && <div className="card__error">{errorText}</div>}
        {children}
      </div>
    </div>
  );
};

export default CardForm;
