export const getSignatureFields = (signatureList, p2SignatureList) => {
  console.log(signatureList);
  let signatureListWithFields = [];

  if (signatureList && Array.isArray(signatureList)) {
    signatureListWithFields = signatureList.map((signature) => {
      return {
        description: signature.description,
        categories: signature.categories,
        ttp: signature.ttp,
        severity: signature.severity,
        confidence: signature.confidence,
        weight: signature.weight,
      };
    });
  }

  let p2SignatureListWithFields = [];

  if (p2SignatureList && Array.isArray(p2SignatureList.signatures)) {
    p2SignatureListWithFields = p2SignatureList.signatures.map((signature) => {
      return {
        description: signature.description,
        categories: signature.categories,
        ttp: signature.ttp,
        severity: signature.severity,
        confidence: signature.confidence,
        weight: signature.weight,
      };
    });
  }

  return [...signatureListWithFields, ...p2SignatureListWithFields];
};

export const getProcessTreeFields = (tree) => {
  const processTreeWithFields = [];

  if (!tree || !Array.isArray(tree)) {
    return processTreeWithFields;
  }

  for (const node of tree) {
    // console.log(node);
    const newNodeWithFields = {
      node: `${node?.module_path}" - "${node?.environ?.Begin}" - "${node?.environ?.End}" - "${node?.environ?.CommandLine}" - "${node?.environ?.ComputerName}" - "${node?.environ?.Hash}" - "${node?.environ?.TempPath}" - "${node?.environ?.WindowsPath}`,
      pid: `${node?.pid}`,
    };

    if (node && node.children && node?.children?.length > 0) {
      newNodeWithFields["childNodes"] = getProcessTreeFields(node.children);
    }

    processTreeWithFields.push(newNodeWithFields);
  }
  // const processTreeWithFields = tree.map((process) => {
  // return {
  //   node: `${node?.module_path}" - "${node?.environ?.Begin}" - "${node?.environ?.End}" - "${node?.environ?.CommandLine}" - "${node?.environ?.ComputerName}" - "${node?.environ?.Hash}" - "${node?.environ?.TempPath}" - "${node?.environ?.WindowsPath}`,
  //   pid: `${node?.pin}`,
  // };
  // });
  return processTreeWithFields;
};

// const getMitreFields = (mitre) => {
//   if (mitre && mitre.mitre_attck) {
//     console.log(mitre);

//     const mitreWithFields = {};
//     for (const category in mitre.mitre_attck) {
//       if (Object.hasOwnProperty.call(mitre.mitre_attck, category)) {
//         const element = object[category];
//       }
//     }
//     Object.keys(mitre.mitre_attck).map((category) => {
//       const values = category.map((value) => value.ttp_name);
//     });
//   }
// };
