import { useState } from "react";
import { useSelector } from "react-redux";

import DroppedFileListSidebar from "./components/DroppedFileListSidebar/DroppedFileListSidebar";
import DroppedFileListContent from "./components/DroppedFileListContent/DroppedFileListContent";
import Backdrop from "../../UI/Backdrop/Backdrop";

import "./styles.scss";

const DroppedFileList = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const extractedPayloadsLoading = useSelector(
    (state) => state.analysisReports.extractedPayloadsLoading,
  );
  const droppedFilesLoading = useSelector((state) => state.analysisReports.droppedFilesLoading);
  const memoryDumpsLoading = useSelector((state) => state.analysisReports.memoryDumpsLoading);
  const pcapFileLoading = useSelector((state) => state.analysisReports.pcapFileLoading);

  const extractedPayloadsError = useSelector(
    (state) => state.analysisReports.extractedPayloadsError,
  );
  const droppedFilesError = useSelector((state) => state.analysisReports.droppedFilesError);
  const memoryDumpsError = useSelector((state) => state.analysisReports.memoryDumpsError);
  const pcapFileError = useSelector((state) => state.analysisReports.pcapFileError);

  const loading =
    extractedPayloadsLoading || droppedFilesLoading || memoryDumpsLoading || pcapFileLoading;
  const error = extractedPayloadsError && droppedFilesError && memoryDumpsError && pcapFileError;

  return (
    <div className="dropped-files border">
      <DroppedFileListSidebar
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        loading={loading}
      />
      <DroppedFileListContent selectedFile={selectedFile} loading={loading} error={error} />
      <Backdrop show={loading} absolute={true} />
    </div>
  );
};

export default DroppedFileList;
