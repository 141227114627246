import { useSelector } from "react-redux";

import Backdrop from "../../../UI/Backdrop/Backdrop";

const PreStaticResultsTableDetails = ({ hash }) => {
  const preStaticResult = useSelector((state) => state.analysisReports.preStaticResults[hash]);

  if (!preStaticResult) {
    return (
      <div className="pre-static-table">
        <div className="pre-static-table__header-wrapper">
          <p>No Data Found.</p>
        </div>
      </div>
    );
  }

  if (preStaticResult.loading) {
    return (
      <div className="pre-static-table">
        <div className="pre-static-table__header-wrapper">
          <Backdrop absolute={true} color="transparent" show={preStaticResult.loading} />
        </div>
      </div>
    );
  }

  if (preStaticResult.error) {
    return (
      <div className="pre-static-table">
        <div className="pre-static-table__header-wrapper">
          <p>Something Went Wrong, Please Try Again Later.</p>
        </div>
      </div>
    );
  }

  if (
    !preStaticResult.data ||
    Object.keys(preStaticResult.data).length === 0 ||
    !Object.hasOwn(preStaticResult.data, "results") ||
    Object.prototype.toString.apply(preStaticResult.data.results) !== "[object Object]" ||
    Object.keys(preStaticResult.data.results).length === 0
  ) {
    return (
      <div className="pre-static-table">
        <div className="pre-static-table__header-wrapper">
          <p>No Data Found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pre-static-table">
      <div className="pre-static-table__header-wrapper">
        <div className="pre-static-table__header-wrapper__header">
          <h2>Static Results</h2>
          <span
            className={`${
              preStaticResult.data.actual_decision === "Malicious"
                ? "pre-static-table__malicious"
                : preStaticResult.data.actual_decision === "Not Suspicious"
                ? "pre-static-table__clean"
                : ""
            } pre-static-table__header-wrapper__header__verdict`}
            style={{ borderRadius: "73px" }}>
            {preStaticResult.data.actual_decision}
          </span>
        </div>
      </div>
      <div className="pre-static-table__container">
        {Object.entries(preStaticResult.data.results).map((data, i) => (
          <div key={i} className="pre-static-table__container__line">
            <div className="pre-static-table__container__line__header">{data[0]}</div>
            <div className="pre-static-table__container__line__data">
              {data[1] ? (
                data[1] === "Clean" ||
                data[1] === "0" ||
                data[1] === "None" ||
                data[1].startsWith("0/") ? (
                  <span className="pre-static-table__clean">{data[1]}</span>
                ) : (
                  <span className="pre-static-table__malicious">{data[1]}</span>
                )
              ) : (
                "-"
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreStaticResultsTableDetails;
