import { useEffect, useState } from "react";

import AttackMapInfo from "../../../../common/AnalysisReportsModal/Tab&Content/AttackMapInfo";
import AttackMapFilter from "../../../../common/AnalysisReportsModal/Tab&Content/AttackMapFilter";
import DownloadIcon from "../../../../Icon-SVG/DownloadIcon";

import "./styles.scss";

const ExtractedInfo = ({ content = [] }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredContent, setFilteredContent] = useState([...content]);

  useEffect(() => {
    const filtered = content.filter((row) =>
      row.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
    );
    setFilteredContent(filtered);
  }, [searchTerm, content]);

  const searchTermChangeHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const infoText = `Showing ${filteredContent.length} entries (filtered from ${content.length} total entries)`;

  const handleDownload = () => {
    const blob = new Blob([filteredContent.join("\n")], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "CFSBOX_extracted_strings.txt";
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const filterInfo = (
    <button className="extracted-strings__download-button" onClick={handleDownload}>
      Download Strings <DownloadIcon width={18} />
    </button>
  );

  return (
    <div className="extracted-strings">
      <AttackMapInfo infoText={infoText} />
      <AttackMapFilter
        filterLabel="Filter: "
        onInputChange={searchTermChangeHandler}
        filterValue={searchTerm}
        filteredCount={filteredContent.length}
        filterInfo={filterInfo}
        totalCount={content.length}
      />
      <div className="extracted-strings__rows">
        {filteredContent.length > 0 ? (
          filteredContent.map((row, i) => (
            <p key={i} className="extracted-strings__row">
              {row}
            </p>
          ))
        ) : (
          <p className="extracted-strings__no-data">No string found.</p>
        )}
      </div>
    </div>
  );
};

export default ExtractedInfo;
