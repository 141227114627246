import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Chart from "../../Components/common/Chart/Chart";
import {
  RequestFilesInfo,
  RequestAttackMapTree,
  RequestMitreAttack,
  RequestGetHtmlReport,
  RequestNetwork,
  RequestGeneralAnalysis,
  RequestGetSignatures,
} from "../../store/actions/analysisReportActions";
import ClamCloudMl from "../../Components/PagesComponents/AnalysisReports/ClamCloudMlResults/ClamCloudMl";
import ArrowBottomIcon from "../../Components/Icon-SVG/ArrowBottomIcon";
import ArrowRightIcon from "../../Components/Icon-SVG/ArrowRightIcon";
import Overview from "../AnalysisReports/Content/Tabs/Overview/Overview";
import ProcessesTree from "../../Components/PagesComponents/ReportExport/ProcessesTree/ProcessesTree";
import Map from "../../Components/common/Map/Map";
import Matrix from "../AnalysisReports/Content/Tabs/Matrix/Matrix";
import Classification from "../../Components/PagesComponents/AnalysisReports/Classification/Classification";
import ClassCategories from "../../Components/PagesComponents/ReportExport/ClassCategories/ClassCategories";
import createTypography from "@mui/material/styles/createTypography";
import DownloadIcon from "../../Components/Icon-SVG/DownloadIcon";
import LogoIcon from "../../Components/Icon-SVG/LogoIcon";
import SelectLogo from "../../Components/PagesComponents/ReportExport/SelectLogo/SelectLogo";
import { getProcessTreeFields, getSignatureFields } from "./utils/dataSanitization";

import "./styles.scss";
import Spinner from "../../Components/common/Spinner/Spinner";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ReportExport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const general = useSelector((state) => state.analysisReports.general);
  const filesInfo = useSelector((state) => state.analysisReports.filesInfo);
  const attackMapTree = useSelector((state) => state.analysisReports.attackMapTree);
  const signatureList = useSelector((state) => state.analysisReports.signatureList);
  const p2signatureList = useSelector((state) => state.analysisReports.p2signatureList);
  const networkActivityMap = useSelector((state) => state.analysisReports.networkActivityMap);
  const mitre = useSelector((state) => state.analysisReports.mitre);
  const htmlReport = useSelector((state) => state.analysisReports.htmlReport);
  const isHtmlReportLoading = useSelector((state) => state.analysisReports.htmlReportLoading);

  const [generalTab, setGeneralTab] = useState(true);
  const [classificationTab, setClassificationTab] = useState(true);
  const [signaturesTab, setSignatures] = useState(true);
  const [processesTab, setProcessesTab] = useState(true);
  const [networkTab, setNetworkTab] = useState(true);
  const [mitreTab, setMitreTab] = useState(true);

  const [tree, setTree] = useState([]);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  const clickedFileID = location.state?.clickedFileID;

  useEffect(() => {
    dispatch(
      RequestGeneralAnalysis({
        parentID: query.get("data"),
        clickedFileID: clickedFileID || query.get("data"),
      }),
    );
    dispatch(RequestFilesInfo(query.get("data")));
    dispatch(RequestAttackMapTree(query.get("data")));
    dispatch(RequestGetSignatures(query.get("data")));
    dispatch(RequestMitreAttack(query.get("data")));
    dispatch(RequestGetHtmlReport(clickedFileID));
    dispatch(
      RequestNetwork({
        parentID: query.get("data"),
        clickedFileID: clickedFileID || query.get("data"),
      }),
    );
  }, [dispatch, query.get("data"), clickedFileID]);

  useEffect(() => {
    attackMapTree.map((data) => setTree(data.behavior.processtree));
  }, [attackMapTree]);

  const handleGeneratePdf = () => {
    history.push(
      `report-pdf?data=${query.get("data")}&state=${query.get(
        "state",
      )}&g=${generalTab}&c=${classificationTab}&s=${signaturesTab}&p=${processesTab}&n=${networkTab}&m=${mitreTab}`,
    );
  };

  const handleGenerateJson = () => {
    const jsonData = {
      general: {
        target: general?.file_name,
        size: general?.size,
        md5: filesInfo[0]?.target.file.md5,
        sha1: filesInfo[0]?.target.file.sha1,
        sha256: general?.sha1,
        ssdeep: filesInfo[0]?.target.file.ssdeep,
      },
      classification: {},
      signatures: getSignatureFields(signatureList, p2signatureList),
      processes: getProcessTreeFields(tree),
      network: networkActivityMap,
    };

    // JSON verilerini bir dosyaya yaz
    const jsonDataString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonDataString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Dosyayı indirme işlemi
    const a = document.createElement("a");
    a.href = url;
    a.download = "Report -" + general?.file_name + ".json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleGenerateCsv = () => {
    const csvData = `File Name, File Size, MD5, SHA1, SHA256, SSDEEP\n${general?.file_name},${general?.size},${filesInfo[0]?.target.file?.md5},${filesInfo[0]?.target.file?.sha1},${general?.sha1},${filesInfo[0]?.target.file?.ssdeep}`;

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Report -" + general?.file_name + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleGenerateHtml = () => {
    if (isHtmlReportLoading || !clickedFileID) {
      return;
    }

    const blob = new Blob([htmlReport], { type: "text/html" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Report -" + general?.file_name + ".html";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (general) {
    return (
      <div className="report">
        <div className="report-header">
          <table>
            <tbody>
              <tr>
                <td>
                  <h1>Report</h1>
                </td>
              </tr>
            </tbody>
          </table>
          <p>This section allows you to preview the report and export it in various formats.</p>
        </div>
        <div className="report-body">
          <div className="report-actions__buttons__container">
            <div className="report-actions__buttons__container--export" onClick={handleGeneratePdf}>
              <DownloadIcon color="var(--header-search-text-color)" width={20} height={20} />
              <div>Export to PDF</div>
            </div>
            <div
              className="report-actions__buttons__container--export"
              onClick={handleGenerateJson}>
              <DownloadIcon color="var(--header-search-text-color)" width={20} height={20} />
              Export to JSON
            </div>
            <div className="report-actions__buttons__container--export" onClick={handleGenerateCsv}>
              <DownloadIcon color="var(--header-search-text-color)" width={20} height={20} />
              Export to CSV
            </div>
            <div
              className={`report-actions__buttons__container--export ${
                !clickedFileID ? "report-actions__buttons__container--export--disabled" : ""
              }`}
              onClick={handleGenerateHtml}>
              <DownloadIcon
                color={`${
                  clickedFileID ? "var(--header-search-text-color)" : "var(--card-background-color)"
                }`}
                width={20}
                height={20}
              />
              {isHtmlReportLoading ? <Spinner width={22} height={22} /> : "Export to HTML"}
            </div>
          </div>

          <hr className="report-divider" />
          <div className="report-main">
            <div className="report-main__content">
              <SelectLogo />
              <div className="report-main__content--general">
                <h2 onClick={() => setGeneralTab(!generalTab)}>
                  General
                  <span>
                    {generalTab ? (
                      <ArrowBottomIcon width={20} height={20} />
                    ) : (
                      <ArrowRightIcon width={20} height={20} />
                    )}
                  </span>
                </h2>
                <div
                  className={`report-main__content--general-content ${
                    generalTab ? "visible" : "hidden"
                  }`}>
                  <table>
                    <tbody>
                      <tr>
                        <td>Target</td>
                        <td>: {general?.file_name}</td>
                      </tr>
                      <tr>
                        <td>Size</td>
                        <td>: {general?.size}</td>
                      </tr>
                      <tr>
                        <td>MD5</td>
                        <td>: {filesInfo[0]?.target.file.md5}</td>
                      </tr>
                      <tr>
                        <td>SHA1</td>
                        <td>: {filesInfo[0]?.target.file.sha1}</td>
                      </tr>
                      <tr>
                        <td>SHA256</td>
                        <td>: {general?.sha1}</td>
                      </tr>
                      <tr>
                        <td>SSDEEP</td>
                        <td>: {filesInfo[0]?.target.file.ssdeep}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="report-main__content--general-content_score">
                    <div className="report-main__content--general-content_score-verdict">
                      <span
                        className={
                          query.get("state") === "malicious"
                            ? "analysis-reports__header-verdict analysis-reports__header-verdict--malicious"
                            : query.get("state") === "not suspicious"
                            ? "analysis-reports__header-verdict analysis-reports__header-verdict--not-suspicious"
                            : "analysis-reports__header-verdict analysis-reports__header-verdict--suspicious"
                        }>
                        {query.get("state")}
                      </span>
                    </div>
                    <div className="report-main__content--general-content_score-chart analysis-reports__header-doughnutChart">
                      <Chart
                        name={`doughnut-dark`}
                        type="doughnut"
                        fileState={query.get("state")}
                        decision_score={general.decision_score}
                      />
                    </div>
                    <ClamCloudMl report={true} />
                  </div>
                </div>
              </div>
              <div className="report-main__content--classification">
                <h2 onClick={() => setClassificationTab(!classificationTab)}>
                  Classification
                  <span>
                    {classificationTab ? (
                      <ArrowBottomIcon width={20} height={20} />
                    ) : (
                      <ArrowRightIcon width={20} height={20} />
                    )}
                  </span>
                </h2>
                <div
                  className={`report-main__content--classification-content ${
                    classificationTab ? "visible" : "hidden"
                  }`}>
                  <Classification theme="dark" report={true} />
                  <div className="report-main__content--classification-content_score">
                    <ClassCategories />
                  </div>
                </div>
              </div>
              <div className="report-main__content--signatures">
                <h2 onClick={() => setSignatures(!signaturesTab)}>
                  Signatures
                  <span>
                    {signaturesTab ? (
                      <ArrowBottomIcon width={20} height={20} />
                    ) : (
                      <ArrowRightIcon width={20} height={20} />
                    )}
                  </span>
                </h2>
                <div
                  className={`report-main__content--signatures-content ${
                    signaturesTab ? "visible" : "hidden"
                  }`}>
                  <Overview report={true} />
                </div>
              </div>
              <div className="report-main__content--processes">
                <h2 onClick={() => setProcessesTab(!processesTab)}>
                  Processes
                  <span>
                    {processesTab ? (
                      <ArrowBottomIcon width={20} height={20} />
                    ) : (
                      <ArrowRightIcon width={20} height={20} />
                    )}
                  </span>
                </h2>
                <div
                  className={`report-main__content--processes-content ${
                    processesTab ? "visible" : "hidden"
                  }`}>
                  <ProcessesTree data={tree} />
                </div>
              </div>
              <div className="report-main__content--network">
                <h2 onClick={() => setNetworkTab(!networkTab)}>
                  Network
                  <span>
                    {networkTab ? (
                      <ArrowBottomIcon width={20} height={20} />
                    ) : (
                      <ArrowRightIcon width={20} height={20} />
                    )}
                  </span>
                </h2>
                <div
                  className={`report-main__content--network-content ${
                    networkTab ? "visible" : "hidden"
                  }`}>
                  <Map report={true} />
                </div>
              </div>
              <div className="report-main__content--mitre">
                <h2 onClick={() => setMitreTab(!mitreTab)}>
                  Mitre
                  <span>
                    {mitreTab ? (
                      <ArrowBottomIcon width={20} height={20} />
                    ) : (
                      <ArrowRightIcon width={20} height={20} />
                    )}
                  </span>
                </h2>
                <div
                  className={`report-main__content--mitre-content ${
                    mitreTab ? "visible" : "hidden"
                  }`}>
                  <Matrix report={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ReportExport;
