import * as React from "react"

const Gam = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M11.176 46.206v492.311l346.22 47.401v-50.583c.776.053 1.542.115 2.329.115h177.39c20.756 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.871-37.628-37.627-37.628h-177.39c-.787 0-1.553.076-2.329.123V0L11.176 46.206zm88.607 302.748c-6.078 2.089-17.428 4.82-28.599 4.457-15.087-.493-25.785-5.066-33.121-13.397-7.257-7.957-11.202-19.82-11.089-33.091.113-30.033 19.255-48.036 46.206-49.216 10.939-.486 19.495 1.545 23.758 3.643l-4.125 17.417c-4.748-2.128-10.596-3.784-19.874-3.503-15.62.467-27.185 10.615-27.185 30.489 0 18.924 10.457 30.288 25.819 30.577 4.36.09 7.875-.409 9.386-1.222v-19.744l-12.987-.031v-16.498l31.811-.354v50.473zm71.542 6.703-7.5-26.084-27.131-.342-6.572 25.104-21.184-.683 27.756-97.715 28.127-1.22 30.709 101.711-24.205-.771zm132.9 4.264-1.737-42.584c-.53-13.343-1.05-29.48-1.05-45.617l-.524.016c-3.659 14.223-8.497 30.021-12.97 42.95l-13.911 42.483-19.717-.603-11.677-41.634c-3.536-12.463-7.202-27.543-9.75-41.321l-.315.01c-.63 14.297-1.113 30.618-1.903 43.799l-1.892 40.074-21.89-.703 6.595-104.178 32.053-1.38 10.74 35.462c3.446 12.415 6.909 25.859 9.407 38.557l.507.005c3.16-12.546 7.002-26.912 10.706-39.1l12.252-36.806 34.951-1.51 6.688 112.928-26.563-.848zM359.726 70.48h177.39c8.893 0 16.125 7.236 16.125 16.127v411.215c0 8.893-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.232V407.91h174.311V213.879l-35.789-41.583H357.396V70.722c.761-.118 1.533-.242 2.33-.242zM522.16 220.422v177.934H366.74v-216.51h123.314v38.576h32.106zm-22.547-9.553v-19.641l16.903 19.641h-16.903z" fill={color} />
    <path  d="m149.913 272.213-.283.011c-1.41 5.945-2.801 13.504-4.339 19.276l-5.562 21.06h20.942l-5.974-21.207c-1.687-5.901-3.374-13.258-4.784-19.14zM446.488 280.507l-6.508-.074a10.275 10.275 0 0 1-.729-3.359c-.242-6.908 6.388-12.879 9.253-15.14 3.802-2.981 5.78-6.023 6.83-10.506.703-2.974-.961-6.312-2.856-9.472l-1.727-2.888c-4.431-7.293-8.604-14.194-10.739-23.21-.169-.749-.521-1.51-1.797-1.51-.901 0-1.852.441-2.371 1.089-.421.528-.526 1.146-.337 1.942 1.617 6.804 4.425 13.48 8.865 21.016l.563.938c3.106 5.229 8.325 13.963-1.786 20.585-2.125 1.399-4.13 2.711-5.485 4.482-2.509 3.276-3.49 7.991-2.698 12.904.174 1.103.463 2.17.793 3.202h-27.317c-17.418 0-31.529 14.122-31.529 31.538 0 17.407 14.111 31.528 31.529 31.528 16.929 0 30.75-13.343 31.49-30.09h9.034c.746 16.742 14.563 30.09 31.493 30.09 17.415 0 31.526-14.121 31.526-31.528 0-17.416-14.111-31.538-31.526-31.538h-33.971zm-36.01 15.424c3.223-1.177 6.793.482 7.964 3.695a6.195 6.195 0 0 1-3.69 7.968 6.186 6.186 0 0 1-7.964-3.695 6.21 6.21 0 0 1 3.69-7.968zm-18.946 13.563a6.208 6.208 0 0 1 3.701-7.968 6.195 6.195 0 0 1 7.964 3.695c1.17 3.229-.482 6.797-3.702 7.968-3.218 1.177-6.77-.472-7.963-3.695zm13.46 18.656c-3.228 1.177-6.793-.473-7.964-3.685-1.19-3.229.473-6.81 3.685-7.98 3.225-1.174 6.804.483 7.98 3.697 1.171 3.214-.483 6.782-3.701 7.968zm15.244-5.595a6.195 6.195 0 0 1-7.963-3.686 6.211 6.211 0 1 1 11.664-4.272c1.187 3.213-.472 6.781-3.701 7.958zm76.458-14.676v8.323h-10.888v10.889h-8.315v-10.889h-10.897v-8.323h10.897v-10.884h8.315v10.884h10.888z" fill={color} />
  </svg>
)

export default Gam
