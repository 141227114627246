import MalwareChart from "../../../common/MalwareChart/MalwareChart";

import MaliciousSubmissionsIcon from "../../../Icon-SVG/MaliciousSubmissionsIcon";
import SuspiciousStatesIcon from "../../../Icon-SVG/SuspiciousStatesIcon";
import CleanSubmissionsIcon from "../../../Icon-SVG/CleanSubmissionsIcon";

import "./styles.scss";

const TOTAL_SUBMISSIONS_MAX_LIMIT = 99999;
const TOTAL_INTEGTRATED_MAX_LIMIT = 99999;

const MalwareDistribution = ({
  allSubmissionsCount = 0,
  integrationSubmissionsCount = 0,
  cleanSubmissionsState = 0,
  suspiciousSubmissionsState = 0,
  maliciousSubmissionsState = 0,
  allSubmissionsActive,
  setAllSubmissionsActive,
  isIntegration = false,
}) => {
  return (
    <div className="malware border">
      {!isIntegration && (
        <div className="malware__counter">
          <div
            onClick={() => allSubmissionsCount !== 0 && setAllSubmissionsActive("all")}
            style={{
              cursor: "pointer",
              color: allSubmissionsActive === "all" && allSubmissionsCount !== 0 && "white",
              borderColor: allSubmissionsActive === "all" && allSubmissionsCount !== 0 && "white",
            }}
            className="malware__counter-item">
            <div className="malware__counter-item-text">
              <p>
                All
                <br />
                Submissions
              </p>
            </div>
            <div className="malware__counter-item-icon">
              <span
                className="malware__counter-item-number"
                style={{
                  cursor: "pointer",
                  color: allSubmissionsActive === "all" && allSubmissionsCount !== 0 && "white",
                }}
                title={
                  allSubmissionsCount && allSubmissionsCount > TOTAL_SUBMISSIONS_MAX_LIMIT
                    ? allSubmissionsCount
                    : undefined
                }>
                <br />
                {!allSubmissionsCount
                  ? "0"
                  : allSubmissionsCount > TOTAL_SUBMISSIONS_MAX_LIMIT
                  ? `${TOTAL_SUBMISSIONS_MAX_LIMIT}+`
                  : allSubmissionsCount}
              </span>
            </div>
          </div>
          <div
            onClick={() =>
              integrationSubmissionsCount !== 0 && setAllSubmissionsActive("integrations")
            }
            style={{
              cursor: "pointer",
              color: allSubmissionsActive === "integrations" && "white",
              borderColor: allSubmissionsActive === "integrations" && "white",
            }}
            className="malware__counter-item">
            <div className="malware__counter-item-text">
              <p>
                Integration <br />
                Submissions
              </p>
            </div>
            <div className="malware__counter-item-icon">
              <span
                className="malware__counter-item-number"
                style={{
                  cursor: "pointer",
                  color: allSubmissionsActive === "integrations" && "white",
                }}
                title={
                  integrationSubmissionsCount &&
                  integrationSubmissionsCount > TOTAL_INTEGTRATED_MAX_LIMIT
                    ? integrationSubmissionsCount
                    : undefined
                }>
                <br />
                {!integrationSubmissionsCount
                  ? "0"
                  : integrationSubmissionsCount > TOTAL_INTEGTRATED_MAX_LIMIT
                  ? `${TOTAL_INTEGTRATED_MAX_LIMIT}+`
                  : integrationSubmissionsCount}
              </span>
            </div>
          </div>
          <div className="malware__counter-item">
            <div className="malware__counter-item-text">
              <p>
                APT <br />
                Attacks
              </p>
            </div>
            <div className="malware__counter-item-icon">
              <span className="malware__counter-item-number">
                <br />0
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        className="malware__chart"
        style={isIntegration ? { marginTop: "0" } : { marginTop: "5%" }}>
        <div className="malware__chart-item">
          <MalwareChart
            chartIcon={<CleanSubmissionsIcon width={32} height={32} />}
            chartValue={cleanSubmissionsState?.toFixed(2)}
            chartName={"Clean Submissions"}
            chartColor={"#28c30f"}
          />
        </div>
        <div className="malware__chart-item">
          <MalwareChart
            chartIcon={<SuspiciousStatesIcon width={32} height={32} />}
            chartValue={suspiciousSubmissionsState?.toFixed(2)}
            chartName={"Suspicious States"}
            chartColor={"var(--network-blocks-snort-url-border)"}
          />
        </div>
        <div className="malware__chart-item">
          <MalwareChart
            chartIcon={<MaliciousSubmissionsIcon width={32} height={32} />}
            chartValue={maliciousSubmissionsState?.toFixed(2)}
            chartName={"Malicious Submissions"}
            chartColor={"var(--network-blocks-sbox-border)"}
          />
        </div>
      </div>
    </div>
  );
};

export default MalwareDistribution;
