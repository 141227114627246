import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";

const Modal = ({ active, setActive, children, type = "submit-popup" }) => {
  return ReactDOM.createPortal(
    <div className={active ? "backdrop modal_active" : "backdrop"} onClick={() => setActive(false)}>
      <div className={active ? `${type} modal_active` : ""} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    document.getElementById("portal"),
  );
};

export default Modal;
