import React from "react";
import "./styles.scss";
import { loadFromLocalStorage } from "../../../utils/localStorage";

const ToggleButton = ({ rightText, leftText, onClick, active }) => {
  const theme = loadFromLocalStorage("theme");
  return (
    <div className="flex flex-align-items-center">
      {leftText && <p className="toggle-button__text">{leftText}</p>}
      <div
        className={`toggle-button ${active ? "toggle-button__active" : ""}`}
        onClick={() => onClick(!active)}>
        <div className={`toggle-button-icon ${active && "toggle-button-icon__active"}`} />
      </div>
      {rightText && <p className="toggle-button__text">{rightText}</p>}
    </div>
  );
};

export default ToggleButton;
