import * as React from "react"

const Iff = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.335c.776.053 1.542.115 2.329.115h177.39c20.75 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.076-2.329.123V0L11.176 46.206v492.311l346.22 47.401v-50.583zm2.33-464.859h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.116-2.329-.232V223.326c15.401-17.137 37.701-27.948 62.51-27.948 44.094 0 80.315 34.081 83.865 77.284-8.315.638-14.883 7.512-14.883 16v80.634c0 8.902 7.217 16.127 16.126 16.127h14.336c8.907 0 16.127-7.225 16.127-16.127v-80.634c0-8.725-6.941-15.782-15.581-16.071-3.623-52.055-47.023-93.334-99.99-93.334-23.665 0-45.376 8.289-62.51 22.056V70.717c.765-.118 1.532-.241 2.329-.241zM77.348 373.716l-29.962-1.543V227.772l29.962-1.339v147.283zm116.941-123.083-57.828 1.541v34.939l53.904-.252v29.089l-53.904-.691v61.491l-32.284-1.658V225.233l90.111-4.005v29.405zm131.62-3.528-67.21 1.8v37.623l62.644-.308v31.404l-62.644-.802v66.216l-37.386-1.922v-161.09l104.597-4.656v31.735z" fill={color} />
    <path  d="M445.434 286.964c-28.903-20.21-28.825-24.958-28.825-24.958-5.511.65-6.079 4.826-6.079 4.826v95.183c-4.135-1.444-9.081-1.686-14.141-.441-11.219 2.799-18.558 12.062-16.41 20.679 1.26 7.952 12.998 13.291 24.217 10.466 9.857-2.436 16.729-9.895 16.781-17.506h.031l.252-90.392s19.675-1.048 38.951 29.147c0-.001.036-15.591-14.777-27.004z" fill={color} />
  </svg>
)

export default Iff
