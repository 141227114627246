import axiosApi from "../../utils/axios";
import { put, takeEvery } from "redux-saga/effects";
import { historyPush } from "../actions/historyActions";
import {
  createUserFailure,
  createUserRequest,
  createUserSuccess,
  forgotPasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  getAllUsersFailure,
  getAllUsersRequest,
  getAllUsersSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  updateUserFailure,
  updateUserRequest,
  updateUserSuccess,
  deleteUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  getAdminRoleFailure,
  getAdminRoleRequest,
  getAdminRoleSuccess,
  setUserChanged,
  timeoutCheckRequest,
  timeoutCheckSuccess,
  timeoutCheckFailure,
  licenceCheckRequest,
  licenceCheckSuccess,
  licenceCheckFailure,
  licenceRegRequest,
  licenceRegSuccess,
  licenceRegFailure,
  //** THIS SECTION CREATE SUPERADMIN FOR OLD SYSTEM ***
  createSuperAdminRequest,
  createSuperAdminSuccess,
  createSuperAdminFailure,
  //** THIS SECTION CREATE SUPERADMIN FOR OLD SYSTEM ***
  publicKeyRegRequest,
  publicKeyRegSuccess,
  publicKeyRegFailure,
  publicKeyCheckRequest,
  publicKeyCheckSuccess,
  publicKeyCheckFailure,
  apiLimitterGetRequest,
  apiLimitterGetSuccess,
  apiLimitterGetFailure,
  apiLimitterSetRequest,
  apiLimitterSetSuccess,
  apiLimitterSetFailure,
} from "../actions/usersActions";
import { customFormData } from "../../utils/customFormData";
import { addNotification } from "../actions/notifierActions";

export function* loginUser({ payload: data }) {
  const formData = customFormData(data);
  try {
    const response = yield axiosApi.post("/api/users/login", formData);
    yield put(
      loginSuccess({
        user: {
          access_token: response?.data.access_token,
          refresh_token: response?.data.refresh_token,
          role: response?.data.role,
          username: response?.data.username,
          email: response?.data.email,
        },
        rememberMe: data.checked,
      }),
    );
    yield put(addNotification({ message: "Login successful", options: { variant: "success" } }));
  } catch (error) {
    yield put(addNotification({ message: "Login failed", options: { variant: "error" } }));
    yield put(loginFailure(error));
  }
}

export function* logout({ payload: data }) {
  try {
    if (data === "timeout") {
      yield put(historyPush("/"));
      yield put(logoutSuccess());
      yield put(
        addNotification({ message: "Logged out by timeout", options: { variant: "success" } }),
      );
    } else {
      yield axiosApi.post("/api/users/logout");
      yield put(historyPush("/"));
      yield put(logoutSuccess());
      yield put(addNotification({ message: "Logged out", options: { variant: "success" } }));
    }
  } catch (e) {
    yield put(addNotification({ message: "Could not logout", options: { variant: "error" } }));
    yield put(logoutSuccess());
  }
}

export function* sendEmailForgotPassword({ payload: data }) {
  try {
    yield axiosApi.post("/api/users/login", data);
    yield put(forgotPasswordSuccess());
  } catch (error) {
    yield put(forgotPasswordFailure(error));
  }
}

export function* resetPassword({ payload: data }) {
  try {
    yield axiosApi.post("/api/users/reset-password", data);
    yield put(resetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

export function* getAllUsers() {
  try {
    const response = yield axiosApi.get("/api/users/all");
    yield put(getAllUsersSuccess(response?.data));
  } catch (error) {
    yield put(getAllUsersFailure(error));
  }
}

export function* getAdminRole() {
  try {
    const response = yield axiosApi.get("/api/users/role");
    yield put(getAdminRoleSuccess(response?.data));
  } catch (error) {
    yield put(getAdminRoleFailure(error));
  }
}

export function* createUsers({ payload: data }) {
  const formData = customFormData(data.userValue);
  const roleValue = data.role;
  var response = "";

  try {
    if (roleValue === "user") {
      response = yield axiosApi.post("/api/users/create/user", formData);
    } else if (roleValue === "admin") {
      response = yield axiosApi.post("/api/users/create/admin", formData);
    }
    yield put(createUserSuccess());
    yield put(setUserChanged(true));
    if (response?.data.message === "email exists") {
      yield put(
        addNotification({
          message: "User with this email already exists.",
          options: { variant: "error" },
        }),
      );
    } else if (response?.data.message === "username exists") {
      yield put(
        addNotification({
          message: "User with this username already exists.",
          options: { variant: "error" },
        }),
      );
    } else {
      yield put(
        addNotification({ message: response?.data.message, options: { variant: "success" } }),
      );
    }
  } catch (error) {
    yield put(createUserFailure(error));
    yield put(
      addNotification({ message: error?.response?.data?.message, options: { variant: "error" } }),
    );
  }
}

export function* updateUsers({ payload: data }) {
  const id = data.id;
  const formData = customFormData(data);
  try {
    const response = yield axiosApi.put(`/api/users/${id}/edit`, formData);
    yield put(updateUserSuccess());
    yield put(setUserChanged(true));
    yield put(
      addNotification({ message: response?.data.message, options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(updateUserFailure(error));
    yield put(addNotification({ message: "Error", options: { variant: "error" } }));
  }
}

export function* deleteUsers({ payload: data }) {
  const id = data.id;

  try {
    const response = yield axiosApi.delete(`/api/users/${id}/delete`);
    yield put(deleteUserSuccess());
    yield put(setUserChanged(true));
    yield put(addNotification({ message: "User was deleted", options: { variant: "success" } }));
  } catch (error) {
    yield put(deleteUserFailure(error));
    yield put(
      addNotification({ message: "Deleting a user failed", options: { variant: "error" } }),
    );
  }
}

export function* timeoutCheck() {
  try {
    const response = yield axiosApi.get("/api/users/timeoutCheck");
    yield put(timeoutCheckSuccess(response?.data));
  } catch (error) {
    yield put(timeoutCheckFailure(error));
  }
}

export function* licenceCheck() {
  try {
    const response = yield axiosApi.get("/api/users/licenceCheck");
    yield put(licenceCheckSuccess(response?.data));
  } catch (error) {
    yield put(licenceCheckFailure(error));
  }
}

export function* licenceReg({ payload: key }) {
  try {
    const response = yield axiosApi.put(`/api/users/licenceReg/${key}`);

    if (response?.status === 210) {
      yield put(
        addNotification({
          message: response?.data.message,
          options: { variant: "error" },
          time: 3000,
        }),
      );
    } else if (response?.status === 200) {
      yield put(licenceRegSuccess(true));
      window.location.reload();
      yield put(
        addNotification({ message: response?.data.message, options: { variant: "success" } }),
      );
    }
  } catch (error) {
    yield put(licenceRegFailure(error));
  }
}

export function* superAdminCreator() {
  try {
    yield axiosApi.get("/api/users/create/superadmin");
    yield put(createSuperAdminSuccess(true));
  } catch (error) {
    yield put(createSuperAdminFailure(error));
  }
}

export function* publicKeyReg() {
  try {
    const response = yield axiosApi.get("/api/reg/JdNjTCws");
    yield put(publicKeyRegSuccess(response?.data));
  } catch (error) {
    yield put(publicKeyRegFailure(error));
  }
}

export function* publicKeyCheck() {
  try {
    const response = yield axiosApi.get("/api/reg/getpublickey");
    yield put(publicKeyCheckSuccess(response?.data));
  } catch (error) {
    yield put(publicKeyCheckFailure(error));
  }
}

export function* getApiLimiter() {
  try {
    const response = yield axiosApi.get("/api/users/getApiLimiter");
    yield put(apiLimitterGetSuccess(response?.data));
  } catch (error) {
    yield put(apiLimitterGetFailure(error));
  }
}

export function* setApiLimiter({ payload: limitCount }) {
  try {
    yield axiosApi.put(`/api/users/setApiLimiter/${limitCount}`);
    yield put(apiLimitterSetSuccess(true));
  } catch (error) {
    yield put(apiLimitterSetFailure(error));
  }
}

const usersSagas = [
  takeEvery(loginRequest, loginUser),
  takeEvery(logoutRequest, logout),
  takeEvery(forgotPasswordRequest, sendEmailForgotPassword),
  takeEvery(resetPasswordRequest, resetPassword),
  takeEvery(getAllUsersRequest, getAllUsers),
  takeEvery(createUserRequest, createUsers),
  takeEvery(updateUserRequest, updateUsers),
  takeEvery(deleteUserRequest, deleteUsers),
  takeEvery(getAdminRoleRequest, getAdminRole),
  takeEvery(timeoutCheckRequest, timeoutCheck),
  takeEvery(licenceCheckRequest, licenceCheck),
  takeEvery(licenceRegRequest, licenceReg),
  takeEvery(createSuperAdminRequest, superAdminCreator),
  takeEvery(publicKeyRegRequest, publicKeyReg),
  takeEvery(publicKeyCheckRequest, publicKeyCheck),
  takeEvery(apiLimitterGetRequest, getApiLimiter),
  takeEvery(apiLimitterSetRequest, setApiLimiter),
];

export default usersSagas;
