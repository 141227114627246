import Chart from "chart.js/auto";

export const doughnutChart = (name, theme, decisionScore, state = null) => {
  const ctx = document.getElementById(`chart-${name}`).getContext("2d");
  let myChart;

  let color = "var(--card-background-color)";

  if (state) {
    if (state === "malicious") {
      color = "#EC374A";
    } else if (state === "not suspicious") {
      color = "#58e655";
    } else if (state === "suspicious") {
      color = "#e69b55";
    }
  }

  try {
    const chartStatus = Chart.getChart(`chart-doughnut-${theme}`);
    if (chartStatus) chartStatus.destroy();
    myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [
              state === "suspicious" ? 100 : !decisionScore ? 0 : decisionScore,
              state === "suspicious" ? 0 : !decisionScore ? 100 : 100 - decisionScore,
            ],
            backgroundColor:
              theme === "dark"
                ? [color, "transparent"]
                : ["#EC374A", "transparent"],
            borderColor:
              theme === "dark"
                ? [color, "transparent"]
                : ["#EC374A", "transparent"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        cutout: 25,
        plugins: {
          tooltip: {
            enabled: false,
          },
          responsive: true,
          maintainAspectRatio: false,
        },
        scales: {
          y: {
            beginAtZero: false,
            display: false,
          },
        },
      },
    });
  } catch (e) {
    myChart?.destroy();
  }
};
