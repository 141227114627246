const StatisticsIcon = ({ color = "var(--color-blue)", width = 22, height = 22 }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={color || "var(--text-color-card)"}
        d="M30.92 1.62a1 1 0 0 0-.54-.54A1 1 0 0 0 30 1H28a1 1 0 0 0-1 1 1 1 0 0 0 .66.93l-.32.31L19.89 8.83 12.45 5.11a1 1 0 0 0-1 .09l-8 6a1 1 0 0 0-.2 1.4A1 1 0 0 0 4 13a1 1 0 0 0 .6-.2l7.51-5.63 7.44 3.72a1 1 0 0 0 1.05-.09l8-6 .11-.09.36-.37A1 1 0 0 0 30 5a1 1 0 0 0 1-1V2A1 1 0 0 0 30.92 1.62zM4 15a3 3 0 0 0-3 3V28a3 3 0 0 0 6 0V18A3 3 0 0 0 4 15z"
      />
      <path
        fill={color || "var(--text-color-card)"}
        d="M12 10a3 3 0 0 0-3 3V28a3 3 0 0 0 6 0V13A3 3 0 0 0 12 10zM20 13a3 3 0 0 0-3 3V28a3 3 0 0 0 6 0V16A3 3 0 0 0 20 13zM28 9a3 3 0 0 0-3 3V28a3 3 0 0 0 6 0V12A3 3 0 0 0 28 9z"
      />
    </svg>
  );
};

export default StatisticsIcon;
