import * as React from "react"

const Thm = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.595 588.595"
    xmlSpace="preserve">
    <path  d="M539.57 49.198h-178.2c-.791 0-1.561.077-2.342.124V0L11.225 46.414V540.98l347.804 47.614V537.78c.781.058 1.551.11 2.342.11h178.2c20.841 0 37.8-16.959 37.8-37.8V86.993c-.001-20.833-16.96-37.795-37.801-37.795zM96.491 271.814l-24.248.567v78.714l-19.467-.765v-77.49l-22.265.535v-17.837l65.984-2.537v18.813h-.004zm91.681 83.795-23.053-.896v-43.664l-36.413-.232v42.467l-21.255-.817v-99.881l21.255-.82v39.016l36.413-.319v-40.102l23.053-.886v106.134zm119.578 4.657-1.814-44.187c-.554-13.854-1.092-30.599-1.092-47.348l-.543.016c-3.808 14.737-8.849 31.1-13.492 44.473l-14.478 43.971-20.503-.775-12.129-43.216c-3.667-12.941-7.473-28.582-10.115-42.865h-.335c-.659 14.813-1.144 31.717-1.969 45.359l-1.962 41.507-22.708-.886 6.848-107.823 33.26-1.281 11.146 36.803c3.591 12.894 7.203 26.855 9.779 40.023l.525.01c3.293-12.978 7.293-27.861 11.14-40.465l12.743-38.119 36.413-1.405 6.961 117.272-27.675-1.064zm51.278-102.039c3.507 4.714 9.081 7.815 15.398 7.815 10.59 0 19.217-8.63 19.217-19.219 0-10.6-8.622-19.219-19.217-19.219-6.317 0-11.892 3.101-15.398 7.81v-40.252h162.391v177.696H359.028V258.227zm5.844-11.399c0-5.255 4.281-9.526 9.555-9.526 5.253 0 9.524 4.271 9.524 9.526s-4.282 9.527-9.524 9.527c-5.263-.005-9.555-4.277-9.555-9.527zm190.899 253.274c0 8.933-7.268 16.199-16.2 16.199h-178.2c-.802 0-1.571-.115-2.342-.231v-98.561h171.967l.116-232.034H359.028V71.041c.771-.113 1.54-.242 2.342-.242h178.2c8.933 0 16.2 7.269 16.2 16.2v413.103z" fill={color} />
    <path  d="M461.576 275.26c2.51 0 5.047-.201 7.53-.612 3.195 1.16 6.787 1.772 10.431 1.772 12.366 0 22.049-6.697 22.049-15.227 0-8.543-9.683-15.229-22.049-15.229-3.059 0-6.111.422-8.88 1.242a10.86 10.86 0 0 0-5.062-1.242h-2.5c-4.108 0-7.853 2.354-9.719 5.967-9.187 1.627-14.623 5.79-14.623 11.319.005 8.256 11.822 12.01 22.823 12.01zm-4.04-14.122 3.85-.411.454-3.839c.084-.738.591-1.237 1.255-1.237h2.5c.343 0 .669.148.938.412l2.268 2.191 2.932-1.181c2.289-.928 4.989-1.427 7.805-1.427 7.668 0 12.355 3.597 12.355 5.535 0 1.951-4.688 5.537-12.355 5.537-2.911 0-5.685-.541-8.026-1.54l-1.371-.578-1.466.288c-2.331.453-4.72.686-7.098.686-6.265 0-10.294-1.318-12.182-2.323 1.371-.721 3.965-1.681 8.141-2.113zM469.528 298.993c-1.234 0-2.479.158-3.729.475-10.868 2.784-18.336 16.231-23.145 29.289-4.799-15.293-13.711-37.202-28.435-51.954-3.491-3.483-7.299-5.242-11.327-5.242-20.867 0-36.45 51.068-42.246 73.406l-.849 8.754h144.697l-5.663-17.613c-7.334-18.784-15.655-37.115-29.303-37.115zm-98.666 45.035c9.903-36.534 23.245-62.775 32.031-62.775 1.392 0 2.857.789 4.472 2.402 18.161 18.186 26.979 50.059 29.446 60.373h-65.949zm77.151 0c4.124-15.999 11.887-33.043 20.192-35.174 8.685-2.31 17.755 20.936 21.51 30.496l1.498 4.673h-43.2v.005z" fill={color} />
  </svg>
)

export default Thm
