import React from "react";

const Os = (props) => {
  const os = props.row.original.oS;
  return (
    <div className="dashboard__table__os">
      <p>{os === "linux22" ? "linux" : os}</p>
    </div>
  );
};

export default Os;
