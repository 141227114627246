import { useState } from "react";

import Popup from "./components/popup/Popup";
import Backdrop from "./components/backdrop/Backdrop";
import QuestionMarkIcon from "../../Icon-SVG/QuestionMarkIcon";

import "./informationPopup.scss";

const InformationPopup = ({ content, iconWidth, iconHeight }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const togglePopupHandler = () => setIsOpen((prevState) => !prevState);

  const mouseEnterHandler = () => setHovered(true);
  const mouseLeaveHandler = () => setHovered(false);

  return (
    <>
      <span
        className="information-popup-container"
        onClick={togglePopupHandler}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}>
        <QuestionMarkIcon
          color={isOpen || hovered ? "var(--color-blue)" : "var(--border)"}
          width={iconWidth}
          height={iconHeight}
        />
      </span>
      {isOpen && <Backdrop onClick={togglePopupHandler} />}
      {isOpen && <Popup content={content} onClick={togglePopupHandler} />}
    </>
  );
};

export default InformationPopup;
