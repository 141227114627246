import usersSlice from "../slices/usersSlice";

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutRequest,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordRequest,
  resetPasswordFailure,
  updateUserFailure,
  updateUserRequest,
  updateUserSuccess,
  deleteUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  createUserRequest,
  createUserFailure,
  createUserSuccess,
  getAllUsersFailure,
  getAllUsersRequest,
  getAllUsersSuccess,
  getAdminRoleFailure,
  getAdminRoleRequest,
  getAdminRoleSuccess,
  setUserChanged,
  timeoutCheckRequest,
  timeoutCheckSuccess,
  timeoutCheckFailure,
  licenceCheckRequest,
  licenceCheckSuccess,
  licenceCheckFailure,
  licenceRegRequest,
  licenceRegSuccess,
  licenceRegFailure,
  //** THIS SECTION CREATE SUPERADMIN FOR OLD SYSTEM ***
  createSuperAdminRequest,
  createSuperAdminSuccess,
  createSuperAdminFailure,
  //** THIS SECTION CREATE SUPERADMIN FOR OLD SYSTEM ***
  publicKeyRegRequest,
  publicKeyRegSuccess,
  publicKeyRegFailure,
  publicKeyCheckRequest,
  publicKeyCheckSuccess,
  publicKeyCheckFailure,
  apiLimitterGetRequest,
  apiLimitterGetSuccess,
  apiLimitterGetFailure,
  apiLimitterSetRequest,
  apiLimitterSetSuccess,
  apiLimitterSetFailure,
} = usersSlice.actions;
