import BankerIcon from "../../Components/Icon-SVG/AnalysisIcons/BankerIcon";
import BotIcon from "../../Components/Icon-SVG/AnalysisIcons/BotIcon";
import CredentialsIcon from "../../Components/Icon-SVG/AnalysisIcons/CredentialsIcon";
import DiscoveryIcon from "../../Components/Icon-SVG/AnalysisIcons/DiscoveryIcon";
import EncryptionIcon from "../../Components/Icon-SVG/AnalysisIcons/EncryptionIcon";
import ExecutionIcon from "../../Components/Icon-SVG/AnalysisIcons/ExecutionIcon";
import ExploitIcon from "../../Components/Icon-SVG/AnalysisIcons/ExploitIcon";
import GenericIcon from "../../Components/Icon-SVG/AnalysisIcons/GenericIcon";
import InfoStealerIcon from "../../Components/Icon-SVG/AnalysisIcons/InfoStealerIcon";
import InjectionIcon from "../../Components/Icon-SVG/AnalysisIcons/InjectionIcon";
import NetworkIcon from "../../Components/Icon-SVG/AnalysisIcons/NetworkIcon";
import OfficeIcon from "../../Components/Icon-SVG/AnalysisIcons/OfficeIcon";
import OtherIcon from "../../Components/Icon-SVG/AnalysisIcons/OtherIcon";
import RansomwareIcon from "../../Components/Icon-SVG/AnalysisIcons/RansomwareIcon";
import SpreadingIcon from "../../Components/Icon-SVG/AnalysisIcons/SpreadingIcon";
import StaticIcon from "../../Components/Icon-SVG/AnalysisIcons/StaticIcon";
import StealthIcon from "../../Components/Icon-SVG/AnalysisIcons/StealthIcon";
import TrojanIcon from "../../Components/Icon-SVG/AnalysisIcons/TrojanIcon";
import { loadFromLocalStorage } from "../localStorage";

export const iconWrapperClassName = "analysis-reports__body-block__classification__iconSet";
const isThemeDataflow = loadFromLocalStorage("theme") === "dataflowx";

export const classificationIcons = [
  {
    id: "trojan",
    title: "Trojan",
    renderIcon: (width, height) => (
      <TrojanIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "stealth",
    title: "Stealth",
    renderIcon: (width, height) => (
      <StealthIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "network",
    title: "Network",
    renderIcon: (width, height) => (
      <NetworkIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "execution",
    title: "Execution",
    renderIcon: (width, height) => (
      <ExecutionIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "infostealer",
    title: "Infostealer",
    renderIcon: (width, height) => (
      <InfoStealerIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "ransomware",
    title: "Ransomware",
    renderIcon: (width, height) => (
      <RansomwareIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "generic",
    title: "Generic",
    renderIcon: (width, height) => (
      <GenericIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "other",
    title: "Other",
    renderIcon: (width, height) => (
      <OtherIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "exploit",
    title: "Exploit",
    renderIcon: (width, height) => (
      <ExploitIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "office",
    title: "Office",
    renderIcon: (width, height) => (
      <OfficeIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "banker",
    title: "Banker",
    renderIcon: (width, height) => (
      <BankerIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "encrypt",
    title: "Encryption",
    renderIcon: (width, height) => (
      <EncryptionIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "spreading",
    title: "Spreading",
    renderIcon: (width, height) => (
      <SpreadingIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "injection",
    title: "Injection",
    renderIcon: (width, height) => (
      <InjectionIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "discovery",
    title: "Discovery",
    renderIcon: (width, height) => (
      <DiscoveryIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "bot",
    title: "Bot",
    renderIcon: (width, height) => (
      <BotIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "static",
    title: "Static",
    renderIcon: (width, height) => (
      <StaticIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
  {
    id: "credentials",
    title: "Credentials",
    renderIcon: (width, height) => (
      <CredentialsIcon
        width={width}
        height={height}
        color={isThemeDataflow ? "var(--color-blue)" : undefined}
      />
    ),
  },
];
