import React from "react";

const PaginationLeftIcon = ({ width = 7, height = 12, color = "var(--text-color-card)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 7 12"
      fill="none">
      <path
        d="M5.37226 11.25C5.26021 11.2503 5.1495 11.2256 5.04827 11.1776C4.94703 11.1296 4.85785 11.0595 4.78726 10.9725L1.16476 6.47246C1.05445 6.33826 0.994141 6.16993 0.994141 5.99621C0.994141 5.82249 1.05445 5.65416 1.16476 5.51996L4.91476 1.01996C5.04206 0.8668 5.22499 0.770482 5.42331 0.752197C5.62163 0.733912 5.81909 0.795159 5.97226 0.922463C6.12542 1.04977 6.22174 1.2327 6.24002 1.43102C6.25831 1.62934 6.19706 1.8268 6.06976 1.97996L2.71726 5.99996L5.95726 10.02C6.04897 10.1301 6.10723 10.2641 6.12514 10.4063C6.14305 10.5484 6.11986 10.6927 6.05832 10.8221C5.99677 10.9515 5.89945 11.0606 5.77787 11.1364C5.65629 11.2122 5.51553 11.2516 5.37226 11.25Z"
        fill={color}
      />
    </svg>
  );
};

export default PaginationLeftIcon;
