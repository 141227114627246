import * as React from "react"
const EndpointIcon = ({ color = "var(--text-color-card)", width = 60, height = 20 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 48 48">
     <path fill={color} fillOpacity={0.01} d="M0 0h48v48H0z" />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M42 24H26"
    />
    <circle
      cx={22}
      cy={24}
      r={3}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M42 40H22c-8.837 0-16-7.163-16-16S13.163 8 22 8h20"
    />
  </svg>
)
export default EndpointIcon
