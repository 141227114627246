import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersRequest,
  updateUserRequest,
  publicKeyCheckRequest,
} from "../../store/actions/usersActions";
import { convertNewSystemRequest } from "../../store/actions/analysisReportActions";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";
import ProfileAccount from "../../Components/common/ProfileAccount/ProfileAccount";
import UserCard from "../../Components/common/UserCard/UserCard";
import ProfilePicture from "../../Components/Icon-SVG/ProfilePicture";
import Warning from "../../Components/common/Warning/Warning";
import "./styles.scss";
import LogoSettings from "../../Components/common/LogoSettings/LogoSettings";

const UserProfile = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.users.users);
  const loadingConvert = useSelector((state) => state?.analysisReports.loadingConvert);
  const defaultEmail = useSelector((state) => state?.users?.user?.email);
  const defaultUserName = useSelector((state) => state?.users?.user?.username);
  const role = useSelector((state) => state?.users?.user?.role) || "USER";
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  const [userNameUpdate, setUserNameUpdate] = useState("");
  const [passwordUpdate, setPasswordUpdate] = useState("");
  const [confirmPasswordUpdate, setConfirmPasswordUpdate] = useState("");
  const [emailUpdate, setEmailUpdate] = useState("");

  useEffect(() => {
    dispatch(getAllUsersRequest());
    dispatch(publicKeyCheckRequest());
  }, [dispatch]);

  const convertToNewSystem = () => {
    dispatch(convertNewSystemRequest());
  };

  if (!users) {
    return <Backdrop show={!users} />;
  }

  const userSelect = users.filter((user) => (defaultEmail === user.email ? user : null));

  const updateUser = async () => {
    if (emailUpdate && userNameUpdate && passwordUpdate && confirmPasswordUpdate) {
      if (passwordUpdate === confirmPasswordUpdate) {
        const pushUser = {
          id: userSelect[0]._id.$oid,
          username: userNameUpdate,
          email: emailUpdate,
          password: passwordUpdate,
        };
        dispatch(updateUserRequest(pushUser));
      } else {
        setError("Make sure your passwords are the same");
        setShow(true);
      }
    } else {
      setError("Please fill in all fields!");
      setShow(true);
    }
  };

  return (
    <div>
      <div className="userProfile">
        <div className="userProfile__header">
          <h1>User Profile</h1>
          <p>Welcome {defaultUserName}</p>
        </div>
        <div className="userProfile__body border">
          <div>
            <UserCard
              name={defaultUserName}
              mail={defaultEmail}
              profileImg={<ProfilePicture width={150} height={150} />}
            />
          </div>
          <hr className="userProfile__body__divider" />
          <div className="userProfile__body__content">
            <ProfileAccount
              setUserNameUpdate={setUserNameUpdate}
              userNameUpdate={defaultUserName}
              setEmailUpdate={setEmailUpdate}
              emailUpdate={defaultEmail}
              setPasswordUpdate={setPasswordUpdate}
              setConfirmPasswordUpdate={setConfirmPasswordUpdate}
              button={updateUser}
            />
          </div>
          <hr className="userProfile__body__divider" />
          {role === "SUPERADMIN" && (
            <div className="userProfile__body__content">
              <div className="profile__card-header">
                <p>Convert to New System</p>
                <p>Please do not use without permission !!</p>
              </div>
              <div className="profile__card-header">
                <button onClick={convertToNewSystem}>Convert</button>
              </div>
            </div>
          )}
        </div>
        {role === "SUPERADMIN" && (
          <div className="userProfile__body userProfile__body border">
            <div className="userProfile__body__content m-t-200">
              <LogoSettings button={updateUser} />
            </div>
          </div>
        )}
      </div>
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
      {loadingConvert && (
        <Backdrop
          absolute={true}
          show={loadingConvert}
          message="Please do not refresh the page or close the window!"
        />
      )}
    </div>
  );
};

export default UserProfile;
