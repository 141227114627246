const EmptyStar = ({ color = "var(--dark-theme-element)", width = 28, height = 28 }) => {
  return (
    <svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}>
      <g>
        <g id="Icon-Star" transform="translate(126.000000, 225.000000)">
          <path
            fill={color}
            d="M-79.9-170.1c-0.2,0-0.5-0.1-0.7-0.2l-13.4-9.7l-13.4,9.7c-0.4,0.3-1,0.3-1.4,0     c-0.4-0.3-0.6-0.9-0.4-1.3l5.1-15.7l-13.4-9.7c-0.4-0.3-0.6-0.9-0.4-1.3c0.2-0.5,0.6-0.8,1.1-0.8h16.5l5.1-15.7     c0.2-0.5,0.6-0.8,1.1-0.8c0.5,0,1,0.3,1.1,0.8l5.1,15.7h16.5c0.5,0,1,0.3,1.1,0.8c0.2,0.5,0,1-0.4,1.3l-13.4,9.7l5.1,15.7     c0.2,0.5,0,1-0.4,1.3C-79.4-170.2-79.7-170.1-79.9-170.1L-79.9-170.1z M-94-182.8c0.2,0,0.5,0.1,0.7,0.2l11.1,8l-4.2-13     c-0.2-0.5,0-1,0.4-1.3l11.1-8h-13.7c-0.5,0-1-0.3-1.1-0.8l-4.2-13l-4.2,13c-0.2,0.5-0.6,0.8-1.1,0.8H-113l11.1,8     c0.4,0.3,0.6,0.9,0.4,1.3l-4.2,13l11.1-8C-94.5-182.7-94.2-182.8-94-182.8L-94-182.8z"
            id="Fill-5"
          />
        </g>
      </g>
    </svg>
  );
};

export default EmptyStar;
