import * as React from "react";

const OfficeIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".office{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <circle className="office" cx={461.1} cy={424.2} r={68.2} />
      <circle className="office" cx={461.1} cy={361} r={24.4} />
      <path
        className="office"
        d="m415.4 391.8-31.5-31.5v-38.5h13.8v32.8l27.5 27.4zM397.7 519.6h-13.8v-44.5l27.3-27.3 9.8 9.8-23.3 23.2zM411.8 409.4h-44.6L340 382.1l9.8-9.7 23.2 23.2h38.8zM349.8 466.9l-9.8-9.8 27.2-27.2h44.6v13.8H373zM506.9 391.8l-9.8-9.8 27.4-27.4v-32.8h13.9v38.5zM538.4 519.6h-13.9v-38.8l-23.2-23.2 9.8-9.8 27.3 27.3zM555 409.4h-44.5v-13.8h38.8l23.2-23.2 9.8 9.7zM572.5 466.9l-23.2-23.2h-38.8v-13.8H555l27.3 27.2z"
      />
      <path
        style={{
          fill: color,
        }}
        d="m138.3 785.4 162.2-60.8V282.8l317.2-78.2v647.5l-469.3-60.9L617.7 962l268-75.2v-743L620.6 62 138.3 243.9z"
      />
      <path
        className="office"
        d="M356 566.6h210.3v35.2H356zM356 644.7h210.3v35.2H356zM356 722.8h136.6V758H356z"
      />
    </g>
  </svg>
);

export default OfficeIcon;
