import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestDeployInt, RequestDeleteInt } from "../../../store/actions/integrationsActions";

import "./styles.scss";
import SettingIcon from "../../Icon-SVG/SettingIcon";
import TrashBucketIcon from "../../Icon-SVG/TrashBucketIcon";

const AppCard = ({
  cardIcon,
  cardName,
  cardDescription,
  intname,
  setActiveInt,
  intActive,
  openModalHandler,
}) => {
  const dispatch = useDispatch();

  const action = useSelector((state) => state.integrations.action);
  const checkConfig = useSelector((state) => state.integrations.checkConfig);
  const [loadingButton, setLoadingButton] = useState(false);
  const [activeInstall, setActiveInstall] = useState(true);
  const [activeConfig, setActiveConfig] = useState(false);
  const cardActive = intActive?.includes(cardName);
  const [hoverConfig, setHoverConfig] = useState(false);
  const [hoverDelete, setHoverDelete] = useState(false);

  useEffect(() => {
    Object.keys(checkConfig).map(
      (key) =>
        key === intname &&
        (checkConfig[key]
          ? (setActiveInstall(false), setActiveConfig(true))
          : (setActiveInstall(true), setActiveConfig(false))),
    );
  }, [checkConfig, action]);

  const installPod = () => {
    dispatch(RequestDeployInt(intname));
  };

  const deletePod = () => {
    dispatch(RequestDeleteInt(intname));
  };

  return (
    <div className="app-cart">
      <div className="app-cart__item-container">
        <div className="app-cart__item-icon">{cardIcon}</div>
        <p className="app-cart__item-name" onClick={() => openModalHandler(intname)}>
          {cardName}
        </p>
        <p className="app-cart__item-description">{cardDescription}</p>
      </div>
      <div className="app-cart__button-container">
        {!action && activeInstall && (
          <button
            disabled={!cardActive}
            style={
              intname === "usbtransfer"
                ? {
                    pointerEvents: "none",
                    color: "var(--text-color-card)",
                    background: "transparent",
                    border: "none",
                  }
                : !cardActive
                ? {
                    pointerEvents: "none",
                    color: "var(--text-color-card)",
                    background: "transparent",
                    border: "none",
                  }
                : {}
            }
            className={`app-cart__button ${loadingButton ? "loading" : ""}`}
            onClick={installPod}>
            {intname === "usbtransfer"
              ? "Coming Soon"
              : !cardActive
              ? "Not Available"
              : "Get it now"}
          </button>
        )}
        {action && <button className="app-cart__button waiting">Please Wait...</button>}
        {!action && !activeInstall && activeConfig && (
          <div className="app-cart__settings-button">
            {intname != "icapweb" && (
              <button
                title="Config"
                onMouseEnter={() => setHoverConfig(true)}
                onMouseLeave={() => setHoverConfig(false)}
                onClick={() => setActiveInt(intname)}
                className="app-cart__settings-button__config app-cart__settings-button-btn">
                <SettingIcon width={20} height={20} color={hoverConfig ? "var(--header-search-text-color)" : "var(--color-blue)"} />
                <span style={{ color: hoverConfig ? "var(--header-search-text-color)" : "var(--color-blue)" }}>Config</span>
              </button>
            )}
            <button
              title="Delete"
              onMouseEnter={() => setHoverDelete(true)}
              onMouseLeave={() => setHoverDelete(false)}
              onClick={deletePod}
              className="app-cart__settings-button__delete app-cart__settings-button-btn">
              <TrashBucketIcon width={20} height={20} color={hoverDelete ? "var(--header-search-text-color)" : "#E65555"} />
              <span style={{ color: hoverDelete ? "var(--header-search-text-color)" : "#E65555" }}>Delete</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppCard;
