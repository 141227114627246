import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestNewUrl, ResetUrlError } from "../../../../../../store/actions/dashboardActions";

import Warning from "../../../../../common/Warning/Warning";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import FileCardButton from "../../../../../UI/FileCardButton/FileCardButton";
import UrlIcon from "../../../../../Icon-SVG/UrlIcon";

import "./styles.scss";
import { set } from "date-fns";

const AnalyzeUrl = () => {
  const [urls, setUrls] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const urlError = useSelector((state) => state.dashboard.urlError);
  const urlLoading = useSelector((state) => state.dashboard.urlLoading);
  const [active, setActive] = useState(false);

  const handleUrlSubmit = () => {
    if (urls.length === 0) {
      setError("Please enter at least one URL");
      setShow(true);
      return;
    }
    dispatch(RequestNewUrl({ urls }));
    // console.log(urls);
    setUrls([]);
  };

  useEffect(() => {
    if (urlError) {
      setError(urlError.response?.data?.message || "URL submission failed.");
      setShow(true);
    }
    dispatch(ResetUrlError());
  }, [urlError]);

  const handleChange = (e) => {
    const { value } = e.target;
    const newUrls = value.split("\n").filter((url) => url.trim() !== "");
    setUrls(newUrls);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setUrls([...urls, ""]);
    }
  };

  return (
    <div className="analyze-url">
      <textarea
        className="analyze-url__input"
        value={urls.join("\n")} //
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="https://www.test.com/malicious"
      />
      <div className="analyze-url__button-block" onClick={handleUrlSubmit}>
        <FileCardButton setActive={setActive}>
          <UrlIcon />
          <p>Submit</p>
        </FileCardButton>
      </div>
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
      <Backdrop show={urlLoading} message="Submitting downloaded attachment file..." />
    </div>
  );
};

export default AnalyzeUrl;
