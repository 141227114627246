import * as React from "react";

const GenericIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>
      {".stgeneric{fill:var(--header-search-text-color)}.stgeneric2{fill:none;stroke-width:56;stroke-miterlimit:10}"}
    </style>
    <g id="Layer_2">
      <circle className="stgeneric" cx={512} cy={520.1} r={158.7} />
      <circle className="stgeneric" cx={512} cy={373} r={56.8} />
      <path
        className="stgeneric"
        d="m405.6 444.8-73.3-73.3v-89.8h32.1v76.4l63.9 63.9zM364.4 742.3h-32.1V638.6l63.4-63.5 22.8 22.8-54.1 54zM397.1 485.6H293.4L230 422.2l22.8-22.7 54 54h90.3zM252.8 619.5 230 596.8l63.4-63.5h103.7v32.2h-90.3zM618.4 444.8 595.7 422l63.9-63.9v-76.4h32.1v89.8zM691.7 742.3h-32.1v-90.4l-54.1-54 22.8-22.8 63.4 63.5zM730.6 485.6H626.9v-32.1h90.3l54-54 22.8 22.7zM771.2 619.5l-54-54h-90.3v-32.2h103.7l63.4 63.5z"
      />
      <circle className="stgeneric2" cx={512} cy={512} r={421.2} stroke={color} />
      <path
        className="stgeneric2"
        d="M512 87v110.7M512 826.3V937M937 512H826.3M197.7 512H87"
        stroke={color}
      />
    </g>
  </svg>
);

export default GenericIcon;
