import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Spinner from "../../../../common/Spinner/Spinner";
import AttackMapFilter from "../../../../common/AnalysisReportsModal/Tab&Content/AttackMapFilter";

import "./styles.scss";

const StaticFinding = ({ data }) => {
  return (
    <div className="static-findings__finding">
      <h5>{data.title}</h5>
      {data.matches.map((match, i) => (
        <div key={i} className="static-findings__finding__container">
          <span className="bullet-point">&#x2022;</span>
          <div>
            <p>
              <span>Offset:</span> {match.address}
            </p>
            <p>
              <span>Matched Rule:</span> {match.match}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const StaticFindings = () => {
  const [filteredStaticFindings, setFilteredStaticFindings] = useState([]);
  const [filterValue, setFilterValue] = useState("");

  const staticFindings = useSelector((state) => state.analysisReports.staticFindings);
  const loading = useSelector((state) => state.analysisReports.staticFindingsLoading);

  useEffect(() => {
    const filteredResults = staticFindings.filter((staticFinding) => {
      return staticFinding.matches.some((match) =>
        match.match.toLowerCase().includes(filterValue.toLowerCase()),
      );
    });
    setFilteredStaticFindings(filteredResults);
  }, [filterValue, staticFindings]);

  const filterValueChangeHandler = (e) => setFilterValue(e.target.value);

  if (loading) {
    return (
      <div className="static-findings__center">
        <Spinner />
      </div>
    );
  }

  if (staticFindings.length === 0) {
    return (
      <div className="static-findings__center">
        <p className="static-findings__no-data">No data</p>
      </div>
    );
  }

  return (
    <div className="static-findings">
      <div className="static-findings__filter">
        <AttackMapFilter
          filterLabel="Filter: "
          onInputChange={filterValueChangeHandler}
          filterValue={filterValue}
          filterInfo=" "
        />
      </div>
      <div className="static-findings__results">
        {filteredStaticFindings.map((filteredStaticFinding, i) => (
          <StaticFinding key={i} data={filteredStaticFinding} />
        ))}
      </div>
    </div>
  );
};

export default StaticFindings;
