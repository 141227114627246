import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestToggleFavorite } from "../../../../../store/actions/dashboardActions";

import FilledStar from "../../../../Icon-SVG/FilledStar";
import EmptyStar from "../../../../Icon-SVG/EmptyStar";

import { checkSubmissionisFavorite } from "../../../../../utils/function";

const Favorite = (props) => {
  const fileIDfull = props.row.original.id;
  const usersFav = props.row.original.usersFav;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.user);

  const [favorite, setFavorite] = useState(checkSubmissionisFavorite(usersFav, user.email));

  useEffect(() => {
    setFavorite(checkSubmissionisFavorite(usersFav, user.email));
  }, [checkSubmissionisFavorite, usersFav, user.email]);

  const toggleFavoriteHandler = (e) => {
    e.stopPropagation();
    setFavorite((prevState) => !prevState);
    dispatch(RequestToggleFavorite(fileIDfull));
  };

  return (
    <div className="favorite__icon" onClick={toggleFavoriteHandler}>
      {favorite ? <FilledStar /> : <EmptyStar />}
    </div>
  );
};

export default Favorite;
