import * as React from "react"

const Odi = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M198.826 237.753c-6.401.113-10.541.758-12.977 1.365v84.413c2.436.594 6.386.672 9.966.725 26.282.672 43.717-13.879 43.717-45.361.195-27.364-15.711-41.562-40.706-41.142zM82.384 239.359c-17.583.308-27.704 18.255-27.704 42.014 0 23.937 10.454 40.978 27.877 41.297 17.834.304 28.187-17.701 28.187-42.017-.005-22.468-10.186-41.6-28.36-41.294z" fill={color} />
    <path  d="M357.4 535.33c.767.047 1.543.109 2.33.109h177.39c20.745 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.882-37.628-37.627-37.628H359.73c-.782 0-1.563.077-2.33.113V0L11.17 46.206v492.311l346.23 47.401V535.33zm0-301.351c1.595.845 3.392 1.369 5.323 1.369 6.34 0 11.464-5.144 11.464-11.475a11.45 11.45 0 0 0-11.464-11.464c-1.932 0-3.729.525-5.323 1.365v-9.649h123.203v92.02h-10.258c-5.993-15.342-13.354-36.822-24.535-33.962-13.107 3.35-19.659 33.962-19.659 33.962s-6.708-34.541-25.202-53.048c-18.499-18.499-36.42 53.048-36.42 53.048h-7.118v-62.166h-.011zm2.33-163.503h177.39c8.892 0 16.125 7.236 16.125 16.126v411.22c0 8.893-7.233 16.127-16.125 16.127H359.73c-.792 0-1.563-.127-2.33-.232V365.915l148.064 33.165a3.42 3.42 0 0 0 4.095-2.581l30.678-136.939c.409-1.827-.746-3.654-2.603-4.073l-35.277-7.906v15.906l22.74 5.089-20.116 89.796-10.015-2.246c-.473-3.039-.935-6.326-1.459-9.617H357.406v-10.751h132.578a3.412 3.412 0 0 0 3.423-3.417V192.018a3.412 3.412 0 0 0-3.423-3.412H357.4V70.717c.767-.118 1.533-.241 2.33-.241zM81.174 344.823c-33.869-.903-53.237-28.499-53.237-62.922 0-36.218 21.389-63.875 55.129-64.767 36.15-.948 56.318 27.644 56.318 62.573-.005 41.521-23.886 66.04-58.21 65.116zm168.155-13.344c-13.891 11.234-34.731 16.147-59.736 15.485-14.719-.399-25.062-1.606-32.034-2.729V218.971c10.288-1.974 23.813-3.262 38.257-3.643 24.345-.62 40.402 3.444 53.124 12.756 13.827 9.969 22.594 26.247 22.594 49.796-.006 25.535-9.369 43.005-22.205 53.599zm73.855 17.606-31.467-.818v-134.43l31.467-.795v136.043z" fill={color} />
    <path  d="M439.679 231.969c2.579 0 4.988-.263 7.14-.704 2.5 1.082 5.533 1.719 8.84 1.719 8.442 0 15.303-4.138 15.303-9.228 0-5.092-6.851-9.231-15.303-9.231-3.182 0-6.121.588-8.555 1.585-1.01-.977-2.353-1.585-3.854-1.585h-2.227c-2.819 0-5.056 2.152-5.396 4.893-6.856.713-11.928 3.194-11.928 6.16.001 3.538 7.151 6.391 15.98 6.391zM511.388 285.626c.922-4.14-2.963-8.639-9.035-11.095V291.7c4.718-.61 8.299-2.794 9.035-6.074z" fill={color} />
  </svg>
)

export default Odi
