import ArrowBottomIcon from "../../Icon-SVG/ArrowBottomIcon";
import ArrowTopIcon from "../../Icon-SVG/ArrowTopIcon";

const ExpandOrShrinkIcon = ({ expanded, collapsed, color = "var(--text-color-card)" }) => {
  const arrowTopIconSize = collapsed ? 7 : 10;
  const arrowBottomIconSize = collapsed ? 8 : 12;

  return expanded ? (
    <ArrowTopIcon width={arrowTopIconSize} height={arrowTopIconSize} color={color} />
  ) : (
    <ArrowBottomIcon width={arrowBottomIconSize} height={arrowBottomIconSize} color={color} />
  );
};

export default ExpandOrShrinkIcon;
