import React, { useState, useEffect } from "react";
import TextField from "../../Components/common/TextField/TextField";
import { useDispatch, useSelector } from "react-redux";
import { licenceRegRequest } from "../../store/actions/usersActions";
import "./styles.scss";
import EyeIcon from "../../Components/Icon-SVG/EyeIcon";
import EyeCloseIcon from "../../Components/Icon-SVG/EyeCloseIcon";

const SystemInfo = () => {
  const dispatch = useDispatch();

  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const [licenceKey, setLicenceKey] = useState("");
  const [licenceInfo, setLicenceInfo] = useState([]);
  const [showKey, setShowKey] = useState(false);

  useEffect(() => {
    setLicenceKey("");
    setLicenceInfo(licenceCheck);
  }, [licenceCheck]);

  const sendKey = () => {
    dispatch(licenceRegRequest(licenceKey));
  };

  return (
    <div className="system-info">
      <div className="flex flex-align-items-center flex-justify-content-between">
        <h1>System Info</h1>
      </div>
      <div className="system-info__body">
        <div className="system-info__body-header">
          <div className="flex flex-align-items-center flex-justify-content-start flex-wrap">
            <h3>Licence</h3>
            <p className="analysis-images__description">
              <span> NOTE:</span> This section allows you to view and update system and license
              information.
            </p>
          </div>
        </div>
        {licenceInfo?.key && (
          <div className={`system-info__create flex-1`}>
            <table>
              <tbody>
                <tr>
                  <td>Key</td>
                  <td>
                    :
                    {showKey ? (
                      <>
                        {licenceInfo?.key}
                        <span
                          style={{ cursor: "pointer", marginLeft: "20px" }}
                          onClick={() => setShowKey(false)}>
                          <EyeCloseIcon />
                        </span>
                      </>
                    ) : (
                      <>
                        {
                          " *******************************************************************************************************"
                        }
                        <span
                          style={{ cursor: "pointer", marginLeft: "20px" }}
                          onClick={() => setShowKey(true)}>
                          <EyeIcon />
                        </span>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>: {licenceInfo?.licenceStatus}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>: {licenceInfo?.licenceDuration} Days</td>
                </tr>
                <tr>
                  <td>Licence Date</td>
                  <td>: {licenceInfo?.validFrom}</td>
                </tr>
                <tr>
                  <td>Expiration Date</td>
                  <td>: {licenceInfo?.validTo}</td>
                </tr>
                <tr>
                  <td>Accessible integrations</td>
                  <td>
                    :{" "}
                    {licenceInfo?.integrations?.map((key, i) => " " + (i + 1) + " ) " + key + " ")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className={`system-info__create flex-1`}>
          <h3>Add Licence Key</h3>
          <p className="label">Key</p>
          <TextField
            onChange={(e) => setLicenceKey(e.target.value)}
            value={licenceKey}
            placeholder={"Type Key"}
          />
          <div className="m-t-20 m-b-20">
            <button onClick={() => sendKey()} className="primary-button">
              Add Key
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemInfo;
