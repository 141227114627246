import * as React from "react"
const RefreshIcon = ({ color = "var(--text-color-card)", width = 20, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="-6 0 32 32"
  >
    <path d="M16.75 16.781h2.938c0 2.563-.969 5.156-2.875 7.063-3.844 3.844-10.094 3.844-13.938 0s-3.844-10.094 0-13.938c1.75-1.75 4.031-2.688 6.344-2.844v-2.75l7.531 4.344-7.531 4.375V10c-1.563.125-3.063.813-4.25 2a6.847 6.847 0 0 0 0 9.75 6.847 6.847 0 0 0 9.75 0 6.818 6.818 0 0 0 2.031-4.969z" fill={color}/>
  </svg>
)
export default RefreshIcon
