import React from "react";

const CsvIcon = () => {
  return (
    <div className="m-r-15 m-l-15">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M3 12.1429V2.7143C3 2.25964 3.1724 1.82361 3.47928 1.50212C3.78616 1.18063 4.20237 1.00001 4.63636 1.00001H14.4545C14.6722 0.998728 14.8816 1.08725 15.0375 1.24644L20.7648 7.24644C20.9167 7.40972 21.0012 7.62911 21 7.85715V12.1429C21 12.3702 20.9138 12.5882 20.7603 12.7489C20.6069 12.9097 20.3988 13 20.1818 13H3.81818C3.60119 13 3.39308 12.9097 3.23964 12.7489C3.0862 12.5882 3 12.3702 3 12.1429ZM14.4545 7.85715H18.9545L14.4545 3.14287V7.85715Z"
          fill="var(--text-color-card)"
        />
        <path
          d="M7.87612 17.0299C7.62736 17.0299 7.40655 17.0765 7.21369 17.1697C7.02363 17.2601 6.86291 17.3919 6.73154 17.5649C6.60297 17.7379 6.50514 17.9468 6.43806 18.1916C6.37098 18.4365 6.33744 18.7119 6.33744 19.018C6.33744 19.4305 6.39055 19.7831 6.49676 20.0758C6.60577 20.3659 6.77347 20.5882 6.99987 20.7425C7.22627 20.8942 7.51835 20.9701 7.87612 20.9701C8.12488 20.9701 8.37364 20.9434 8.62241 20.8902C8.87396 20.837 9.14648 20.7611 9.43996 20.6627V21.7006C9.16884 21.8071 8.90191 21.8829 8.63918 21.9281C8.37644 21.976 8.08156 22 7.75454 22C7.12285 22 6.60297 21.8762 6.19489 21.6287C5.78961 21.3786 5.48914 21.0299 5.29348 20.5828C5.09783 20.1331 5 19.6088 5 19.01C5 18.5682 5.06289 18.1637 5.18867 17.7964C5.31444 17.4291 5.49892 17.1111 5.74209 16.8423C5.98526 16.5735 6.28573 16.3659 6.6435 16.2196C7.00127 16.0732 7.41214 16 7.87612 16C8.18078 16 8.48545 16.0373 8.79011 16.1118C9.09757 16.1836 9.39105 16.2834 9.67055 16.4112L9.2513 17.4172C9.0221 17.3134 8.79151 17.2229 8.55952 17.1457C8.32753 17.0685 8.09973 17.0299 7.87612 17.0299Z"
          fill="var(--text-color-card)"
        />
        <path
          d="M14.2232 20.2994C14.2232 20.6454 14.1351 20.9461 13.959 21.2016C13.7829 21.4571 13.5258 21.654 13.1876 21.7924C12.8522 21.9308 12.4441 22 11.9633 22C11.7509 22 11.5427 21.9867 11.3387 21.9601C11.1374 21.9335 10.9431 21.8949 10.7559 21.8443C10.5714 21.7911 10.3953 21.7259 10.2276 21.6487V20.499C10.5183 20.6214 10.8202 20.7319 11.1332 20.8303C11.4463 20.9288 11.7565 20.978 12.064 20.978C12.2764 20.978 12.4469 20.9514 12.5755 20.8982C12.7068 20.845 12.8019 20.7718 12.8606 20.6786C12.9193 20.5855 12.9486 20.479 12.9486 20.3593C12.9486 20.2129 12.8969 20.0878 12.7935 19.984C12.6901 19.8802 12.5475 19.7831 12.3658 19.6926C12.187 19.6021 11.9843 19.505 11.7579 19.4012C11.6154 19.3373 11.4602 19.2601 11.2925 19.1697C11.1248 19.0765 10.9655 18.9634 10.8146 18.8303C10.6636 18.6973 10.5393 18.5363 10.4414 18.3473C10.3464 18.1557 10.2989 17.9268 10.2989 17.6607C10.2989 17.312 10.3827 17.014 10.5504 16.7665C10.7181 16.519 10.9571 16.33 11.2674 16.1996C11.5804 16.0665 11.9494 16 12.3742 16C12.6929 16 12.9961 16.0359 13.284 16.1078C13.5747 16.177 13.878 16.2781 14.1938 16.4112L13.7746 17.3733C13.4922 17.2641 13.2393 17.1803 13.0157 17.1218C12.7921 17.0605 12.5643 17.0299 12.3323 17.0299C12.1702 17.0299 12.0318 17.0552 11.9172 17.1058C11.8026 17.1537 11.716 17.2229 11.6573 17.3134C11.5986 17.4012 11.5692 17.5037 11.5692 17.6208C11.5692 17.7591 11.6112 17.8762 11.695 17.9721C11.7817 18.0652 11.9102 18.1557 12.0807 18.2435C12.254 18.3313 12.4693 18.4338 12.7264 18.5509C13.0394 18.6919 13.3064 18.8397 13.5272 18.994C13.7508 19.1457 13.9227 19.3253 14.0429 19.5329C14.1631 19.7379 14.2232 19.9933 14.2232 20.2994Z"
          fill="var(--text-color-card)"
        />
        <path
          d="M20 16.0838L17.9163 21.9202H16.4992L14.4197 16.0838H15.7319L16.8849 19.5569C16.9045 19.6154 16.9366 19.7259 16.9813 19.8882C17.0261 20.0479 17.0708 20.2182 17.1155 20.3992C17.163 20.5775 17.1952 20.7252 17.2119 20.8423C17.2287 20.7252 17.258 20.5775 17.3 20.3992C17.3447 20.2209 17.388 20.0519 17.4299 19.8922C17.4747 19.7299 17.5068 19.6181 17.5264 19.5569L18.6877 16.0838H20Z"
          fill="var(--text-color-card)"
        />
      </svg>
    </div>
  );
};

export default CsvIcon;
