import InfoIcon from "../../../Icon-SVG/InfoIcon";

const AttackMapInfo = ({ infoText, styleProps }) => {
  return (
    <div className="attack-map__info-container" style={styleProps}>
      <InfoIcon width={16} height={16} />
      <p className="attack-map__info">{infoText}</p>
    </div>
  );
};

export default AttackMapInfo;
