import React from "react";

const SuspiciousStates = ({ color = "var(--text-color-card)", width = 16, height = 16 }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512.000000 512.000000">
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path
          d="M1855 4786 c-16 -8 -114 -99 -217 -202 -269 -273 -269 -256 10 -536
            212 -212 224 -219 309 -203 49 9 109 69 118 118 15 79 2 103 -128 235 l-121
            122 121 123 c98 99 123 129 128 160 25 132 -101 237 -220 183z"
          fill={color}
        />
        <path
          d="M2660 4789 c-14 -5 -36 -20 -49 -32 -38 -35 -371 -711 -371 -753 0
            -136 158 -213 261 -127 22 19 80 124 206 377 167 336 175 353 170 399 -7 81
            -64 138 -144 143 -26 2 -59 -1 -73 -7z"
          fill={color}
        />
        <path
          d="M3135 4786 c-41 -18 -83 -69 -91 -111 -14 -77 0 -102 129 -232 l121
            -123 -121 -122 c-130 -132 -143 -156 -128 -235 9 -49 69 -109 118 -118 85 -16
            97 -9 309 203 279 280 279 263 10 536 -103 103 -201 194 -219 202 -40 17 -89
            17 -128 0z"
          fill={color}
        />
        <path
          d="M2495 3666 c-16 -7 -40 -24 -51 -37 -12 -13 -207 -433 -434 -932
            -391 -860 -412 -910 -407 -954 5 -49 27 -85 71 -117 27 -21 34 -21 886 -21
            852 0 859 0 886 21 44 32 66 68 71 117 5 44 -16 94 -407 954 -227 499 -422
            919 -434 932 -43 48 -121 64 -181 37z m153 -493 c14 -10 35 -32 46 -47 20 -27
            21 -39 21 -406 0 -367 -1 -379 -21 -406 -39 -53 -71 -69 -134 -69 -63 0 -95
            16 -134 69 -20 26 -21 42 -24 379 -2 194 0 366 3 384 7 40 49 91 90 109 40 19
            120 12 153 -13z m-14 -1113 c62 -31 81 -63 81 -140 0 -77 -19 -109 -81 -140
            -51 -25 -87 -25 -140 1 -114 55 -119 209 -9 275 49 29 96 30 149 4z"
          fill={color}
        />
        <path
          d="M885 3191 c-90 -22 -172 -90 -215 -176 l-25 -50 -3 -922 -2 -923
            1920 0 1921 0 -3 923 -3 922 -34 63 c-37 69 -79 109 -151 144 -45 23 -45 23
            -639 26 -481 2 -592 0 -588 -10 3 -7 141 -312 308 -678 l304 -665 -1 -85 c0
            -72 -4 -93 -26 -136 -39 -75 -70 -107 -140 -145 l-63 -34 -880 0 -880 0 -48
            22 c-75 35 -121 77 -158 145 -31 58 -34 70 -34 148 l0 85 304 665 c167 366
            305 671 308 678 4 9 -113 12 -568 11 -316 -1 -587 -4 -604 -8z"
          fill={color}
        />
        <path
          d="M415 946 c-87 -39 -114 -128 -80 -261 45 -172 179 -305 352 -350 50
            -13 296 -15 1873 -15 1598 0 1823 2 1875 15 186 49 328 205 357 393 13 84 5
            132 -29 173 -54 63 105 59 -2205 59 -1739 -1 -2118 -3 -2143 -14z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default SuspiciousStates;
