import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import useResizeWindow from "../../../utils/hook/useResizeWindows";

import "./Layout.scss";

const Layout = ({ children }) => {
  const width = useResizeWindow();
  const [collapsed, setCollapsed] = useState(false);
  const [fullyCollapsed, setFullyCollapsed] = useState(false);
  const collapsedWidth = "calc(100% - 80px)";
  const sidebarWidth = width <= 1350 ? "calc(100% - 200px)" : "calc(100% - 220px)";
  const fullyCollapsedWidth = "100%";
  const responsiveWidth = fullyCollapsed
    ? fullyCollapsedWidth
    : collapsed
    ? collapsedWidth
    : sidebarWidth;
  useEffect(() => {
    if (width <= 1590) {
      setCollapsed(true);
    } else {
      setFullyCollapsed(false);
      setCollapsed(false);
    }
  }, [width]);
  return (
    <div className="layout">
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} fullyCollapsed={fullyCollapsed} />
      <div
        className="main-block"
        style={{
          width: responsiveWidth,
        }}>
        <header className="header" style={{ width: responsiveWidth }}>
          <Header
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            fullyCollapsed={fullyCollapsed}
            setFullyCollapsed={setFullyCollapsed}
          />
        </header>
        <main className="content">
          {children}
          <div id="scroll-target"></div>
        </main>
      </div>
    </div>
  );
};
export default Layout;
