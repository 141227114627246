import React from "react";
import DemoIcon from "../../Icon-SVG/DemoIcon";
import "./styles.scss";

const Warning = ({ onClickOk, text, article = "Demo", textOK = "Okay", icon, show, setShow }) => {
  return (
    <div className={`${show && "warning-backdrop"}`} onClick={() => setShow(false)}>
      <div onClick={(e) => e.stopPropagation()}>
        {show ? (
          <div className="warning">
            <div style={{ marginLeft: "10px" }} className="warning__icon">
              {!icon ? <DemoIcon /> : icon}
            </div>
            <h3 className="article">{article}</h3>
            <p className="warning__description">{text}</p>
            <div className="flex">
              <button className="primary-button" onClick={onClickOk}>
                {textOK}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Warning;
