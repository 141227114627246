import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axiosApi from "../../utils/axios";
import {
  FailureDashboard,
  RequestDashboard,
  SuccessDashboard,
  FailureNewFile,
  RequestNewFile,
  SuccessNewFile,
  CurrentNewFile,
  FailureNewScript,
  RequestNewScript,
  SuccessNewScript,
  RequestDailySubmissions,
  SuccessDailySubmissions,
  FailureDailySubmissions,
  RequestSubmissionsList,
  SuccessSubmissionsList,
  FailureSubmissionsList,
  RequestAllSubmissions,
  SuccessAllSubmissions,
  FailureAllSubmissions,
  RequestCleanSubmissionsState,
  SuccessCleanSubmissionsState,
  FailureCleanSubmissionsState,
  RequestSuspiciousSubmissionsState,
  SuccessSuspiciousSubmissionsState,
  FailureSuspiciousSubmissionsState,
  RequestMaliciousSubmissionsState,
  SuccessMaliciousSubmissionsState,
  FailureMaliciousSubmissionsState,
  SetSubmittedFile,
  CopyClipboard,
  RequestGroupVerdict,
  SuccessGroupVerdict,
  FailureGroupVerdict,
  RequestResubmit,
  SuccessResubmit,
  FailureResubmit,
  RequestSubmitFreerun,
  SuccessSubmitFreerun,
  FailureSubmitFreerun,
  RequestToggleFavorite,
  RequestCleanRunningTab,
  RequestNewUrl,
  SuccessNewUrl,
  FailureNewUrl,
  RequestSetUILogo,
  SuccessSetUILogo,
  FailureSetUILogo,
  RequestGetUILogo,
  SuccessGetUILogo,
  FailureGetUILogo,
  RequestQuickSubmit,
  SuccessQuickSubmit,
  FailureQuickSubmit,
  RequestSubmissionsListForStats,
  SuccessSubmissionsListForStats,
  FailureSubmissionsListForStats,
  RequestRunningCount,
  SuccessRunningCount,
  RequestRunningSubmissions,
  SuccessRunningSubmissions,
  FailureRunningSubmissions,
  RequestSearchCompletedSubmissions,
  SuccessSearchCompletedSubmissions,
  FailureSearchCompletedSubmissions,
  RequestIntegrationSubmissionCount,
  SuccessIntegrationSubmissionCount,
  FailureIntegrationSubmissionCount,
  RequestDailyIntegrationSubmissionCount,
  SuccessDailyIntegrationSubmissionCount,
  FailureDailyIntegrationSubmissionCount,
  RequestIntegrationSubmissionState,
  SuccessIntegrationSubmissionState,
  FailureIntegrationSubmissionState,
  RequestSearchGroupSubmissions,
  SuccessSearchGroupSubmissions,
  FailureSearchGroupSubmissions,
  RequestStaticSubmit,
  SuccessStaticSubmit,
  FailureStaticSubmit,
  RequestVms,
  SuccessVms,
  FailureVms,
} from "../actions/dashboardActions";
import { customFormData } from "../../utils/customFormData";
import { addNotification } from "../actions/notifierActions";
import { updateLocalStorageForRemoteConnection } from "../../utils/function";

export function* DashboardPage() {
  try {
    const response = yield axiosApi.get("/api/dashboard/");
    yield put(SuccessDashboard(response?.data));
  } catch (error) {
    yield put(FailureDashboard(error));
  }
}

export function* addNewFile({ payload: data }) {
  for (let i = 0; i < data.length; i++) {
    const formData = customFormData(data[i].file);

    formData.append("file", data[i].file);
    formData.append("fileFormat", data[i].fileType);
    formData.append("type", JSON.stringify(data[i].type));
    formData.append("command_line", JSON.stringify(data[i].command_line));
    formData.append("enviromental_variable", JSON.stringify(data[i].enviromental_variable));
    formData.append("related_password", JSON.stringify(data[i].related_password));
    formData.append("runtime_duration", JSON.stringify(data[i].runtime_duration));
    formData.append("enforse_runtime", JSON.stringify(data[i].enforse_runtime));
    formData.append("enforse_submission", JSON.stringify(data[i].enforse_submission));
    formData.append("memory_dump", JSON.stringify(data[i].memory_dump));
    formData.append("network_traffic", JSON.stringify(data[i].network_traffic));
    formData.append("action_script", JSON.stringify(data[i].action_script));
    formData.append("machine", JSON.stringify(data[i].machine));

    yield put(CurrentNewFile(data[i].file.name));
    try {
      yield axiosApi.post("/api/files/submit", formData);
      if (i === data.length - 1) {
        yield put(SuccessNewFile());
        yield put(
          addNotification({ message: "Added your files", options: { variant: "success" } }),
        );
        yield put(SetSubmittedFile({ state: true, from: "advancedSubmit" }));
        yield call(allSubmissions);
        yield call(dailySubmissions);
        yield call(runningCount);
      }
    } catch (error) {
      yield put(FailureNewFile(error));
      yield put(addNotification({ message: "Error", options: { variant: "error" } }));
    }
  }
}

export function* quickSubmit({ payload: data }) {
  for (let i = 0; i < data.length; i++) {
    const formData = customFormData({});

    formData.append("file", data[i].file);
    formData.append("enforse_submission", JSON.stringify(data[i].enforceSubmission));

    yield put(CurrentNewFile(data[i].file.name));
    try {
      yield axiosApi.post("/api/files/quicksubmit", formData);
      if (i === data.length - 1) {
        yield put(SuccessQuickSubmit());
        yield put(
          addNotification({ message: "Submitted your files", options: { variant: "success" } }),
        );
        yield put(SetSubmittedFile({ state: true, from: "quickSubmit" }));
        yield call(allSubmissions);
        yield call(dailySubmissions);
        yield call(runningCount);
      }
    } catch (error) {
      yield put(FailureQuickSubmit());
      yield put(addNotification({ message: "Error", options: { variant: "error" } }));
    }
  }
}

export function* staticSubmit({ payload: data }) {
  for (let i = 0; i < data.length; i++) {
    const formData = customFormData({});

    formData.append("file", data[i].file);

    yield put(CurrentNewFile(data[i].file.name));
    try {
      yield axiosApi.post("/api/files/staticsubmit", formData);
      if (i === data.length - 1) {
        yield put(SuccessStaticSubmit());
        yield put(
          addNotification({ message: "Submitted your files", options: { variant: "success" } }),
        );
        yield put(SetSubmittedFile({ state: true, from: "staticSubmit" }));
        yield call(allSubmissions);
        yield call(dailySubmissions);
        yield call(runningCount);
      }
    } catch (error) {
      yield put(FailureStaticSubmit());
      yield put(addNotification({ message: "Error", options: { variant: "error" } }));
    }
  }
}

export function* addNewScript({ payload: data }) {
  const formData = customFormData(data.file);
  formData.append("file", data.file);
  formData.append("fileFormat", data.fileType);
  formData.append("type", JSON.stringify(data.type));
  formData.append("command_line", JSON.stringify(data.command_line));
  formData.append("enviromental_variable", JSON.stringify(data.enviromental_variable));
  formData.append("related_password", JSON.stringify(data.related_password));
  formData.append("runtime_duration", JSON.stringify(data.runtime_duration));
  formData.append("enforse_runtime", JSON.stringify(data.enforse_runtime));
  formData.append("enforse_submission", JSON.stringify(data.enforse_submission));
  formData.append("memory_dump", JSON.stringify(data.memory_dump));
  formData.append("network_traffic", JSON.stringify(data.network_traffic));
  formData.append("action_script", JSON.stringify(data.action_script));
  formData.append("machine", JSON.stringify(data.machine));

  try {
    yield axiosApi.post("/api/files/submit", formData);
    yield put(SuccessNewScript());
    yield put(addNotification({ message: "Added your files", options: { variant: "success" } }));
    yield put(SetSubmittedFile({ state: true, from: "scriptSubmit" }));
    yield call(allSubmissions);
    yield call(dailySubmissions);
    yield call(runningCount);
  } catch (error) {
    yield put(FailureNewScript(error));
    yield put(addNotification({ message: "Error", options: { variant: "error" } }));
  }
}

export function* dailySubmissions() {
  try {
    const response = yield axiosApi.get("/api/dashboard/chart/dailySubmissions");
    yield put(SuccessDailySubmissions(response?.data));
  } catch (error) {
    yield put(FailureDailySubmissions(error));
  }
}

export function* submissionsList({ payload: timeFilter }) {
  try {
    const response = yield axiosApi.get(
      timeFilter
        ? "/api/dashboard/list/submissionsList?filterByLast=" + timeFilter.id
        : "/api/dashboard/list/submissionsList",
    );
    yield put(SuccessSubmissionsList(response?.data));
  } catch (error) {
    yield put(FailureSubmissionsList(error));
  }
}

// export function* submissionsList({ payload }) {
//   const { pageNumber, timeFilter } = payload;
//   var filterTime = "";
//   var numberPage = "";
//   if (timeFilter) {
//     filterTime = timeFilter.id;
//   }
//   if (pageNumber) {
//     numberPage = pageNumber;
//   }
//   try {
//     const response = yield axiosApi.get(
//       "/api/dashboard/list/submissionsList?filterByLast=" +
//         filterTime +
//         "&pageNumber=" +
//         numberPage,
//     );
//     yield put(SuccessSubmissionsList(response?.data));
//   } catch (error) {
//     yield put(FailureSubmissionsList(error));
//   }
// }

export function* allSubmissions() {
  try {
    const response = yield axiosApi.get("/api/dashboard/chart/allSubmissions");
    yield put(SuccessAllSubmissions(response?.data));
  } catch (error) {
    yield put(FailureAllSubmissions(error));
  }
}

export function* cleanSubmissionsState({ payload: allOrDaily }) {
  const type = allOrDaily === "all_daily" ? "daily" : "all";
  try {
    const response = yield axiosApi.get(`/api/dashboard/chart/cleanSubmissionsState/${type}`);
    yield put(SuccessCleanSubmissionsState(response?.data));
  } catch (error) {
    yield put(FailureCleanSubmissionsState(error));
  }
}

export function* suspiciousSubmissionsState({ payload: allOrDaily }) {
  const type = allOrDaily === "all_daily" ? "daily" : "all";
  try {
    const response = yield axiosApi.get(`/api/dashboard/chart/suspiciousSubmissionsState/${type}`);
    yield put(SuccessSuspiciousSubmissionsState(response?.data));
  } catch (error) {
    yield put(FailureSuspiciousSubmissionsState(error));
  }
}

export function* maliciousSubmissionsState({ payload: allOrDaily }) {
  const type = allOrDaily === "all_daily" ? "daily" : "all";
  try {
    const response = yield axiosApi.get(`/api/dashboard/chart/maliciousSubmissionsState/${type}`);
    yield put(SuccessMaliciousSubmissionsState(response?.data));
  } catch (error) {
    yield put(FailureMaliciousSubmissionsState(error));
  }
}

export function* copyClipboard({ payload: data }) {
  try {
    navigator.clipboard.writeText(data);
    yield put(addNotification({ message: "Hash copied", options: { variant: "success" } }));
  } catch (error) {
    yield put(addNotification({ message: "Error Copy", options: { variant: "error" } }));
  }
}

export function* groupVerdict({ payload: cid }) {
  try {
    const response = yield axiosApi.get(`/api/files/groupresult/${cid}`);
    yield put(SuccessGroupVerdict(response?.data));
  } catch (error) {
    yield put(FailureGroupVerdict(error));
  }
}

export function* resubmit({ payload }) {
  try {
    const response = yield axiosApi.get(`/api/files/resubmit/${payload.id}`);
    yield put(addNotification({ message: "Resubmit your files", options: { variant: "success" } }));
    yield put(SetSubmittedFile({ state: true, from: "resubmit" }));
    yield put(SuccessResubmit(response?.data));
    yield call(runningCount);
  } catch (error) {
    yield put(addNotification({ message: "Resubmit error", options: { variant: "error" } }));
    yield put(FailureResubmit(error));
  }
}

export function* submitFreerun({ payload: data }) {
  const formData = new FormData();
  formData.append("file", data.file, "freerun.txt");
  formData.append("fileFormat", "txt");
  formData.append("machine", data.machine);
  formData.append("type", data.type);
  formData.append("memory_dump", data.memory_dump);
  formData.append("network_traffic", data.network_traffic);
  formData.append("command_line", "");
  formData.append("enviromental_variable", "");
  formData.append("related_password", "");
  formData.append("runtime_duration", "");
  formData.append("enforse_runtime", "");
  formData.append("enforse_submission", "");
  formData.append("action_script", "");

  try {
    const response = yield axiosApi.post("/api/files/submit/free-run", formData);
    yield put(addNotification({ message: "Success", options: { variant: "success" } }));
    yield put(SetSubmittedFile({ state: true, from: "freerunSubmit" }));
    yield put(SuccessSubmitFreerun(response.data));
    yield call(runningCount);
  } catch (error) {
    yield put(addNotification({ message: "Error", options: { variant: "error" } }));
    yield put(FailureSubmitFreerun(error));
  }
}

export function* toggleFavorite({ payload: fileIDfull }) {
  let parsedData = {};
  const storedData = localStorage.getItem("tableTimeFilter");
  if (storedData) {
    parsedData = JSON.parse(storedData);
  }
  try {
    const response = yield axiosApi.put(`/api/dashboard/submission/${fileIDfull}/toggle-favorite`);
    yield call(submissionsList, { payload: parsedData });
  } catch (error) {
    yield call(submissionsList, { payload: parsedData });
  }
}

export function* cleanRunningTab() {
  try {
    yield axiosApi.delete(`/api/dashboard/submission/clean-running-tab`);
    yield call(runningSubmissions);
    yield call(runningCount);
  } catch (error) {
    yield call(runningSubmissions);
    yield put(
      addNotification({
        message: "Cleaning running tab from errors failed.",
        options: { variant: "error" },
      }),
    );
  }
}

export function* newUrl({ payload: { urls } }) {
  try {
    for (let i = 0; i < urls.length; i++) {
      const formData = customFormData({});
      formData.append("url", urls[i]);
      yield axiosApi.post("/api/files/urlsubmit", formData);
    }
    yield put(SuccessNewUrl());
    yield put(
      addNotification({
        message: "URL submission success.",
        options: { variant: "success" },
      }),
    );
    yield put(SetSubmittedFile({ state: true, from: "urlSubmit" }));
    yield call(runningCount);
  } catch (error) {
    console.log(error);
    yield put(
      addNotification({
        message: "URL submission failed.",
        options: { variant: "error" },
      }),
    );
    yield put(FailureNewUrl(error));
  }
}

export function* getUILogo() {
  try {
    const response = yield axiosApi.get("/api/dashboard/getuilogo");

    yield put(SuccessGetUILogo(response.data.ui_img));
  } catch (error) {
    yield put(FailureGetUILogo(error));
  }
}

export function* setUILogo({ payload: img }) {
  try {
    const formData = new FormData();
    formData.append("img", img);

    const response = yield axiosApi.post("/api/dashboard/setuilogo", formData);

    yield put(SuccessSetUILogo(response));
    yield put(
      addNotification({
        message: response.data.message,
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(FailureSetUILogo(error));
  }
}

export function* submissionsListForStats() {
  try {
    const response = yield axiosApi.get("/api/dashboard/list/submissionsList");
    yield put(SuccessSubmissionsListForStats(response?.data));
    const filteredSubmissions = response?.data?.filter(
      (submission) => submission.status !== "In Queue" && submission.status !== "Running",
    );
    filteredSubmissions.forEach((submission) =>
      updateLocalStorageForRemoteConnection(submission.fileID),
    );
  } catch (error) {
    yield put(FailureSubmissionsListForStats());
  }
}

export function* runningCount() {
  try {
    const response = yield call(axiosApi.get, "/api/dashboard/running-count");
    yield put(SuccessRunningCount(response.data));
  } catch (e) {}
}

export function* runningSubmissions() {
  try {
    const response = yield call(axiosApi.get, "/api/dashboard/running-submissions");
    yield put(SuccessRunningSubmissions(response.data.result_list));
  } catch (e) {
    yield put(FailureRunningSubmissions());
  }
}

export function* searchCompletedSubmissions({ payload: searchValue }) {
  try {
    const response = yield call(axiosApi.get, "/api/completed/search/submissions/" + searchValue);
    yield put(SuccessSearchCompletedSubmissions(response.data));
  } catch (e) {
    yield put(FailureSearchCompletedSubmissions());
  }
}

export function* integrationSubmissionCount({ payload: integrationType }) {
  try {
    const response = yield call(
      axiosApi.get,
      `/api/dashboard/chart/integration-submission-count/all/${integrationType}`,
    );
    yield put(SuccessIntegrationSubmissionCount(response.data));
  } catch (e) {
    yield put(FailureIntegrationSubmissionCount());
  }
}

export function* dailyIntegrationSubmissionCount({ payload: integrationType }) {
  try {
    const response = yield call(
      axiosApi.get,
      `/api/dashboard/chart/integration-submission-count/daily/${integrationType}`,
    );
    yield put(SuccessDailyIntegrationSubmissionCount(response.data));
  } catch (e) {
    yield put(FailureDailyIntegrationSubmissionCount());
  }
}

export function* integrationSubmissionState({ payload }) {
  const { timeFilter, integrationType } = payload;
  const allOrDaily = timeFilter === "integrations_daily" ? "daily" : "all";
  try {
    const response = yield call(
      axiosApi.get,
      `/api/dashboard/chart/integration-submission-state/${allOrDaily}/${integrationType}`,
    );
    yield put(SuccessIntegrationSubmissionState(response.data));
  } catch (e) {
    console.log(e);
    yield put(FailureIntegrationSubmissionState());
  }
}

export function* searchGroupSubmissions({ payload: fileID }) {
  try {
    const response = yield call(axiosApi.get, `/api/completed/search/group/${fileID}`);
    yield put(SuccessSearchGroupSubmissions(response.data));
  } catch (e) {
    yield put(FailureSearchGroupSubmissions());
  }
}

export function* vms() {
  try {
    const response = yield call(axiosApi.get, "/api/dashboard/submitpopup/vms");
    yield put(SuccessVms(response.data["vms"] || []));
  } catch (e) {
    yield put(FailureVms());
  }
}

const dashboardSagas = [
  takeEvery(RequestDashboard, DashboardPage),
  takeEvery(RequestNewFile, addNewFile),
  takeEvery(RequestQuickSubmit, quickSubmit),
  takeEvery(RequestStaticSubmit, staticSubmit),
  takeEvery(RequestNewScript, addNewScript),
  takeEvery(RequestDailySubmissions, dailySubmissions),
  takeEvery(RequestSubmissionsList, submissionsList),
  takeEvery(RequestAllSubmissions, allSubmissions),
  takeEvery(RequestCleanSubmissionsState, cleanSubmissionsState),
  takeEvery(RequestSuspiciousSubmissionsState, suspiciousSubmissionsState),
  takeEvery(RequestMaliciousSubmissionsState, maliciousSubmissionsState),
  takeEvery(CopyClipboard, copyClipboard),
  takeEvery(RequestGroupVerdict, groupVerdict),
  takeEvery(RequestResubmit, resubmit),
  takeEvery(RequestSubmitFreerun, submitFreerun),
  takeLatest(RequestToggleFavorite, toggleFavorite),
  takeLatest(RequestCleanRunningTab, cleanRunningTab),
  takeEvery(RequestNewUrl, newUrl),
  takeEvery(RequestSetUILogo, setUILogo),
  takeEvery(RequestGetUILogo, getUILogo),
  takeEvery(RequestSubmissionsListForStats, submissionsListForStats),
  takeLatest(RequestRunningCount, runningCount),
  takeEvery(RequestRunningSubmissions, runningSubmissions),
  takeLatest(RequestSearchCompletedSubmissions, searchCompletedSubmissions),
  takeEvery(RequestIntegrationSubmissionCount, integrationSubmissionCount),
  takeEvery(RequestDailyIntegrationSubmissionCount, dailyIntegrationSubmissionCount),
  takeEvery(RequestIntegrationSubmissionState, integrationSubmissionState),
  takeLatest(RequestSearchGroupSubmissions, searchGroupSubmissions),
  takeLatest(RequestVms, vms),
];

export default dashboardSagas;
