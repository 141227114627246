import * as React from "react"

const Odg = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M166.763 245.462c-5.21.084-8.549.596-10.52 1.124v69.302c1.971.504 5.166.557 8.074.61 21.302.524 35.433-11.424 35.433-37.264.168-22.467-12.722-34.121-32.987-33.772z" fill={color} />
    <path  d="M357.4 535.33c.767.047 1.543.109 2.33.109h177.39c20.745 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.882-37.628-37.627-37.628H359.73c-.782 0-1.563.077-2.33.113V0L11.17 46.206v492.311l346.23 47.401V535.33zm0-464.613c.767-.113 1.532-.241 2.33-.241h177.39c8.892 0 16.125 7.236 16.125 16.126v411.22c0 8.893-7.233 16.127-16.125 16.127H359.73c-.792 0-1.563-.127-2.33-.232v-443zM71.305 333.422c-27.504-.734-43.244-23.424-43.244-51.71 0-29.774 17.372-52.494 44.777-53.205 29.333-.77 45.701 22.711 45.701 51.405 0 34.096-19.378 54.255-47.234 53.51zm136.38-11.014c-11.255 9.238-28.145 13.271-48.41 12.735-11.951-.321-20.336-1.313-25.985-2.253V230.019c8.336-1.609 19.307-2.678 31.021-2.984 19.748-.515 32.756 2.845 43.056 10.478 11.202 8.187 18.31 21.565 18.31 40.901.005 20.96-7.586 35.29-17.992 43.994zm131.184 10.983c-8.409 2.529-24.188 5.867-39.717 5.459-21.136-.568-36.17-6.09-46.52-16.21-10.255-9.707-15.84-24.148-15.675-40.318.157-36.581 27.205-58.206 64.978-59.174 15.233-.407 27.097 2.173 32.997 4.769l-5.716 20.917c-6.573-2.625-14.693-4.704-27.634-4.494-21.847.354-38.092 12.533-38.092 36.68 0 22.98 14.699 36.791 36.188 37.157 6.077.106 10.97-.48 13.081-1.468v-23.844l-18.105-.1v-19.969l44.226-.157v60.752h-.011z" fill={color} />
    <path  d="M72.273 246.762c-14.265.242-22.48 14.981-22.48 34.509 0 19.677 8.483 33.682 22.614 33.934 14.475.252 22.877-14.54 22.877-34.53 0-18.448-8.249-34.165-23.011-33.913zM391.49 431.816c1.605-14.236 25.019-24.021 25.019-24.021 26.793-10.761 17.093-51.592 17.093-51.592l-25.732-11.328c-25.471 3.202-37.765 31.97-37.765 31.97-16.442 43.486 21.385 54.971 21.385 54.971zm10.699-80.735 8.334 1.956s-13.405-3.39-36.882 38.752c.001 0-.671-23.89 28.548-40.708zM450.305 333.495c2.824-6.384 66.5-196.557 71.486-207.9 3.423-12.953-6.373-15.885-11.192-4.924-4.808 10.95-94.762 190.377-97.439 196.487-2.688 6.09-2.919 21.575-2.919 21.575l25.733 11.328c0 .001 11.538-10.172 14.331-16.566z" fill={color} />
  </svg>
)

export default Odg
