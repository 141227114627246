import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import CustomTable from "../../../Components/common/Table/CustomTable";
import ListPopup from "../../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";
import CustomSelect from "../../../Components/common/CustomSelect/CustomSelect";

import { networkDetectionsHeader } from "../../../utils/constants/tableHeaders";

import {
  RequestDistinctHosts,
  RequestNetworkDetections,
} from "../../../store/actions/networkDefenderActions";

import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Details = ({ row, history }) => {
  const original = row?.original;
  const snort = original?.SnortLog;
  const groupID = original?.groupID;
  const detection = original?.log?.detection;

  const sboxDetailsClickHandler = () => {
    if (original?.request) {
      history.push({ pathname: "/dashboard", state: { fromRemoteConnection: true } });
    } else if (groupID && groupID !== "-") {
      history.push(`/network-detections?data=${groupID}&table=network-detections`);
    }
  };

  if (snort) {
    return (
      <div className="network-detections__table__snort">
        <p>
          <span>Rule: </span>
          {snort?.log?.description}
        </p>
        <p>
          <span>Description: </span>
          {snort?.log?.importance}
        </p>
        <p>
          <span>Signature ID:</span>
          {snort?.log?.sigId}
        </p>
      </div>
    );
  } else if (groupID) {
    return (
      <div className="network-detections__table__sbox">
        <p>
          <span>File Name: </span>
          {original?.file_name || "-"}
        </p>
        <p>
          <span>File Hash: </span>
          {original?.hash || "-"}
        </p>
        <p>
          <span>Submit Time: </span>
          {original?.time_stamp
            ? `${original.time_stamp.split(" ")[0]} (${original.time_stamp.split(" ")[1]})`
            : "-"}
        </p>
        <button onClick={sboxDetailsClickHandler} disabled={groupID && groupID === "-"}>
          {detection === "SboxSubmitLog" && original?.request ? "Running" : "See Report"}
        </button>
      </div>
    );
  } else if (original?.log?.detection === "UrlBlock") {
    return <div className="network-detections__table__no-details">Known malicious url.</div>;
  } else {
    return <div className="network-detections__table__no-details">No details found.</div>;
  }
};

const NetworkDetections = () => {
  const [selectedHost, setSelectedHost] = useState(null);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);
  const [listPopupActive, setListPopupActive] = useState(false);

  const dispatch = useDispatch();

  const distinctHosts = useSelector((state) => state.networkDefender.distinctHosts);
  const distinctHostsLoading = useSelector((state) => state.networkDefender.distinctHostsLoading);
  const networkDetections = useSelector((state) => state.networkDefender.networkDetections);

  let query = useQuery();

  useEffect(() => {
    dispatch(RequestDistinctHosts());

    return () => {
      localStorage.setItem("networkDetectionsToggle", JSON.stringify({ ids: [] }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedHost?.id) {
      dispatch(RequestNetworkDetections({ host: selectedHost.id, timeFilter: selectedTimeFilter }));
    }
  }, [selectedHost, selectedTimeFilter, dispatch]);

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  return (
    <div className="network-detections">
      <div className="network-detections__header">
        <h1>Network Detections</h1>
        <p>Below list is displaying network detections of selected host:</p>
        <div className="network-detections__header__select">
          <p>Select host: </p>
          <CustomSelect
            data={distinctHosts.length ? distinctHosts : []}
            select={selectedHost}
            setSelected={setSelectedHost}
            width={200}
            disabled={distinctHosts.length === 0}
            placeholder={distinctHostsLoading ? "Fetching data..." : "No data."}
          />
        </div>
      </div>
      <div className="network-detections__table border">
        <CustomTable
          exportFile={true}
          dataNetworkDetections={networkDetections}
          selectedHost={selectedHost?.id}
          tableName="networkDetections"
          column={networkDetectionsHeader}
          leftAction={true}
          paginationButtons={true}
          className=""
          customPageSize={10}
          renderRowSubComponent={Details}
          minHeight="585px"
          minBottom="5px"
          defaultPosition="relative"
          filterByTime={true}
          selectedTimeFilter={selectedTimeFilter}
          setSelectedTimeFilter={setSelectedTimeFilter}
          defaultFilterIndex={2}
        />
      </div>
      <ListPopup
        onClickOk={() => setListPopupActive(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="network-detections"
      />
    </div>
  );
};

export default NetworkDetections;
