import * as React from "react";

const ExecutionIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".exe1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path className="exe" d="M644.4 962H298.2L338 845h266.5z" fill={color} />
      <path
        className="exe1"
        d="M869.1 62H610.3c-28.8 0-52.2 23.4-52.2 52.2v346.3c0 12.2 14.7 18.3 23.4 9.7l95-95h192.7c28.8 0 52.2-23.4 52.2-52.2V114.2c-.1-28.8-23.5-52.2-52.3-52.2zm-47.5 164.6c0 2.3-.1 4.6-.3 6.8h31.2l32.8 32.8-11.8 11.8-27.8-28h-27.5c-1.7 5.8-4.1 11.3-7 16.4l21.3 21.3v53.6h-16.6v-46.7l-14.4-14.4c-15 17.3-37.2 28.2-61.8 28.2s-46.8-10.9-61.8-28.2l-14.4 14.4v46.7h-16.6v-53.6l21.3-21.3c-2.9-5.2-5.2-10.7-7-16.4h-27.5l-27.9 27.9-11.8-11.7 32.8-32.8H658c-.2-2.3-.3-4.5-.3-6.8 0-6.1.7-12.1 2-17.8h-32.9L594 176l11.8-11.8 27.9 27.9h31.6c2.5-5.3 5.5-10.3 9-14.9l-27.4-27.4v-46.3h16.6v39.4l22.1 22.1c7.2-6.4 15.6-11.5 24.8-15 .3-15.9 13.3-28.8 29.3-28.8s29 12.8 29.3 28.8c9.2 3.5 17.5 8.6 24.8 15l22.1-22.1v-39.4h16.6v46.3l-27.4 27.4c3.5 4.6 6.5 9.6 9 14.9h31.6l27.9-27.9 11.8 11.8-32.8 32.8h-32.9c1.2 5.7 1.9 11.7 1.9 17.8z"
      />
      <path
        className="exe"
        d="M795.1 426.6v207H147.4V237.1h358.3v-47.2H160.6c-32 0-57.9 25.9-57.9 57.9v488.3c0 32 25.9 57.9 57.9 57.9H782c32 0 57.9-25.9 57.9-57.9V426.6h-44.8zM471.3 746.3c-17.4 0-31.5-14.1-31.5-31.5s14.1-31.5 31.5-31.5 31.5 14.1 31.5 31.5c-.1 17.5-14.2 31.5-31.5 31.5z"
        fill={color}
      />
      <path
        className="exe1"
        d="M364.9 358.5c0-3.9-.3-7.7-.8-11.5l17.4-12-5.5-16.9-21.1.5c-3.7-6.8-8.2-13-13.5-18.6l7-19.9-14.4-10.4-16.7 12.8c-6.8-3.3-14.2-5.7-21.9-7.1l-6-20.2h-17.8l-6 20.2c-7.7 1.4-15 3.8-21.9 7.1L227 269.7l-14.4 10.5 7 19.9c-5.3 5.5-9.9 11.8-13.5 18.6l-21.1-.5-5.5 16.9 17.4 12c-.5 3.8-.8 7.6-.8 11.5 0 3.9.3 7.7.8 11.5l-17.4 12L185 399l21.1-.5c3.7 6.8 8.2 13 13.5 18.6l-7 19.9 14.4 10.5 16.7-12.8c6.8 3.3 14.2 5.7 21.9 7.1l6 20.2h17.8l6-20.2c7.7-1.4 15-3.8 21.9-7.1l16.7 12.8 14.4-10.5-7-19.9c5.3-5.5 9.9-11.8 13.5-18.6l21.1.5 5.5-16.9-17.4-12c.5-3.9.8-7.8.8-11.6zm-84.4 40c-22.1 0-40.1-17.9-40.1-40.1 0-22.1 17.9-40.1 40.1-40.1 22.1 0 40.1 17.9 40.1 40.1 0 22.2-17.9 40.1-40.1 40.1zM518.3 505.7c0-3.9-.3-7.7-.8-11.5l17.4-12-5.5-16.9-21.1.5c-3.7-6.8-8.2-13-13.5-18.6l7-19.9-14.4-10.5-16.7 12.8c-6.8-3.3-14.2-5.7-21.9-7.1l-6-20.2H425l-6 20.2c-7.7 1.4-15 3.8-21.9 7.1l-16.7-12.8-14.4 10.5 7 19.9c-5.3 5.5-9.9 11.8-13.5 18.6l-21.1-.5-5.5 16.9 17.4 12c-.5 3.8-.8 7.6-.8 11.5 0 3.9.3 7.7.8 11.5l-17.4 12 5.5 16.9 21.1-.5c3.7 6.8 8.2 13 13.5 18.6l-7 19.9 14.4 10.5 16.7-12.8c6.8 3.3 14.2 5.7 21.9 7.1l6 20.2h17.8l6-20.2c7.7-1.4 15-3.8 21.9-7.1l16.7 12.8 14.4-10.5-7-19.9c5.3-5.5 9.9-11.8 13.5-18.6l21.1.5 5.5-16.9-17.4-12c.5-3.8.8-7.6.8-11.5zm-84.4 40.1c-22.1 0-40.1-17.9-40.1-40.1 0-22.1 17.9-40.1 40.1-40.1 22.1 0 40.1 17.9 40.1 40.1 0 22.1-17.9 40.1-40.1 40.1z"
      />
    </g>
  </svg>
);

export default ExecutionIcon;
