import * as React from "react";
const SharePointIcon = ({ color = "var(--text-color-card)", width = 60, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <path
      d="M15 3.89v5.67c1.09-.17 1.98-.96 2.28-2.02.92.38 1.7 1.04 2.23 1.87-1 .48-1.63 1.49-1.63 2.59 0 1.13.67 2.11 1.62 2.58-.54.83-1.32 1.47-2.23 1.85A2.821 2.821 0 0 0 15 14.45v5.67a2.835 2.835 0 0 0 2.39-2.71 5.865 5.865 0 0 0 3.06-2.53c.1.01.21.01.31.01a2.88 2.88 0 0 0 2.88-2.88c0-1.59-1.3-2.88-2.89-2.88-.09 0-.19 0-.28.01a5.856 5.856 0 0 0-3.09-2.58A2.812 2.812 0 0 0 15 3.89zM0 2.6v18.7L14 24V0L0 2.6zm9.74 12.52c-.19.34-.44.62-.78.81-.34.19-.66.34-1.1.43-.43.1-.87.15-1.35.15s-.96-.05-1.39-.15c-.38-.05-.75-.16-1.1-.33v-1.96c.34.29.73.52 1.16.66.43.14.81.24 1.24.24.24 0 .48 0 .67-.05.19-.05.34-.1.48-.2.14-.1.24-.19.29-.28.05-.1.09-.23.09-.33 0-.19-.04-.34-.13-.48-.1-.14-.25-.25-.39-.39-.19-.1-.39-.23-.63-.33s-.48-.2-.76-.34c-.67-.29-1.19-.62-1.53-1.05-.34-.43-.53-.91-.53-1.49 0-.48.09-.86.29-1.2.18-.33.42-.58.76-.82.34-.19.68-.38 1.11-.48.43-.1.85-.13 1.33-.13.48 0 .86.04 1.2.09.34.05.67.14.96.24V9.6c-.14-.1-.29-.19-.48-.28-.16-.09-.34-.16-.52-.2a2.26 2.26 0 0 0-.53-.09c-.17-.04-.35-.06-.52-.06-.24 0-.44.01-.63.06-.19.05-.34.08-.48.18-.14.1-.24.2-.29.3a.85.85 0 0 0-.11.38c0 .14.06.29.11.39.1.1.19.23.33.33s.33.19.53.28c.19.1.43.2.67.3.34.14.67.29.96.48.29.14.53.33.72.57.19.19.34.43.48.72.1.29.15.58.15.91-.05.53-.14.91-.28 1.25z"
      fill={color}
    />
  </svg>
);
export default SharePointIcon;
