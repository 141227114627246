import { getISOWeek } from "date-fns";

import {
  getDateOfISOWeek,
  isCompressedItself,
  isNotCompressedItself,
  isNotRelatedToCompressed,
} from "../../../../utils/function";

const fileGroupCounter = (id, osType, list) => {
  let counter = 0;

  list?.map((data) => (data.groupID === id ? data.oS === osType && (counter = counter + 1) : null));

  return counter;
};

export const severitySelect = (decisionData) => {
  if (decisionData === 0) {
    return "not suspicious";
  } else if (decisionData === 1) {
    return "suspicious";
  } else if (decisionData === 2) {
    return "malicious";
  } else {
    return "loading";
  }
};

export const filterCustomTableData = (list) => {
  let tempList;

  tempList = list
    .filter(
      (filterData) =>
        isCompressedItself(filterData.compressedID, filterData.compressedIDfull, filterData.id) ||
        isNotRelatedToCompressed(filterData.compressedID),
    )
    .filter(
      (filterData, index, filterArr) =>
        filterArr.findIndex((item) => item.groupID === filterData.groupID) === index,
    )
    .map((listData) => ({
      id: listData.id,
      user: listData.user,
      time: listData.time,
      submitType:
        listData.submitType === "UI"
          ? "UI"
          : listData.submitType === "imapemail"
          ? "Email"
          : listData.submitType === "postfixemail"
          ? "Postfix Email"
          : listData.submitType === "fileshare" || listData.submitType === "winshare"
          ? "File"
          : listData.submitType === "icapweb"
          ? "Proxy"
          : null,
      hashIcon: { fileType: listData.file.name.split(".").pop() },
      file: {
        name: listData.file.name,
        hash: listData.file.hash,
        type: listData.file.type,
        url: listData.file.url,
      },
      groupCounter: {
        win7: fileGroupCounter(listData.groupID, "win7", list),
        win10: fileGroupCounter(listData.groupID, "win10", list),
        win11: fileGroupCounter(listData.groupID, "win11", list),
        linux22: fileGroupCounter(listData.groupID, "linux22", list),
      },
      progress: listData.progress,
      severity: listData.severity,
    }))
    .sort((a, b) => b.time.localeCompare(a.time));

  return tempList;
};

export const filterListPopupData = (list, groupID) => {
  let tempList;
  let listTypeTags = "";
  let listNetworkTags = "";

  tempList = list
    .filter((filterData) => filterData.groupID === groupID)
    .map(
      (listData) => (
        (((listTypeTags = ""), (listNetworkTags = "")),
        listData.id.includes("full_triage") ? (listTypeTags = " Full Triage") : "",
        listData.id.includes("high_evasion") ? (listTypeTags = " High Evasion") : "",
        listData.id.includes("basic_evasion") ? (listTypeTags = " Basic Evasion") : "",
        listData.id.includes("internet") ? (listNetworkTags = " Internet") : "",
        listData.id.includes("vpn") ? (listNetworkTags = " Vpn") : "",
        listData.id.includes("simulate") ? (listNetworkTags = " Simulate") : "",
        listData.id.includes("disable") ? (listNetworkTags = " Disable") : ""),
        {
          id: listData.id,
          networkTraffic: listNetworkTags,
          file: {
            name: listTypeTags,
            url: isCompressedItself(listData.compressedID, listData.compressedIDfull, listData.id)
              ? "/statistics?data=" + listData.compressedIDfull + "&table=compressed"
              : "/analysis-reports?data=" + listData.parentID + "&state=" + listData.severity,
          },

          oS: listData.oS,
          progress: listData.progress,
          severity: listData.severity,
          groupID: listData.groupID,
          compressedID: listData.compressedID,
          compressedIDfull: listData.compressedIDfull,
        }
      ),
    );

  return tempList;
};

export const filterListCompressedPopupData = (list, groupID) => {
  let tempList;
  tempList = list
    .filter(
      (filterData) =>
        filterData.compressedIDfull === groupID &&
        isNotCompressedItself(
          filterData.compressedID,
          filterData.compressedIDfull,
          filterData.fileID,
        ),
    )
    .map((listData) => ({
      id: listData.fileID,
      file: {
        name: listData.filename,
        url: isCompressedItself(listData.compressedID, listData.compressedIDfull, listData.id)
          ? "/statistics?data=" + listData.compressedIDfull + "&table=compressed"
          : "/analysis-reports?data=" +
            listData.parentID +
            "&state=" +
            severitySelect(listData.decision),
        hash: listData.fileHash,
        type: listData.fileType,
      },

      oS: listData.oS,
      progress: listData.progress,
      severity: severitySelect(listData.decision),
    }));

  return tempList;
};

const getDateStringFromDateObject = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  return `${year}-${month}-${day}`;
};

export const getDayDifference = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));

  return diffDays;
};

const prepareMonthlyData = (sourceData) => {
  const outputData = [];
  const responseFirstDate = new Date(`${sourceData[0]._id.year}-${sourceData[0]._id.month}-01`);
  const responseLastDate = new Date(
    `${sourceData[sourceData.length - 1]._id.year}-${
      sourceData[sourceData.length - 1]._id.month
    }-01`,
  );
  const oneMonthBefore = new Date(responseFirstDate.getTime() - 2 * 86400000); // 1 month before
  const oneMonthAfter = new Date(responseLastDate.getTime() + 31 * 86400000); // 1 month after
  outputData.push({
    _id: {
      month: oneMonthBefore.getMonth() + 1,
      year: oneMonthBefore.getFullYear(),
    },
    files: [],
    monthlyTotalSubmissionCount: 0,
  });
  sourceData.forEach((monthData) => outputData.push(monthData));
  outputData.push({
    _id: {
      month: oneMonthAfter.getMonth() + 1,
      year: oneMonthAfter.getFullYear(),
    },
    files: [],
    monthlyTotalSubmissionCount: 0,
  });
  return outputData;
};

const prepareWeeklyData = (sourceData, userStartDate, userFinishDate) => {
  let outputData = [];
  const responseFirstDate = getDateOfISOWeek(sourceData[0]._id, sourceData[0].year);

  const responseLastDate = getDateOfISOWeek(
    sourceData[sourceData.length - 1]._id,
    sourceData[sourceData.length - 1].year,
  );

  const oneMonthBefore = new Date(responseFirstDate.getTime() - 2 * 86400000); // 1 month before
  const oneMonthAfter = new Date(responseLastDate.getTime() + 31 * 86400000); // 1 month after

  outputData.push({
    _id: getISOWeek(oneMonthBefore),
    year: oneMonthBefore.getFullYear(),
    files: [],
    monthlyTotalSubmissionCount: 0,
  });
  sourceData.forEach((monthData) => outputData.push(monthData));
  outputData.push({
    _id: getISOWeek(oneMonthAfter),
    year: oneMonthAfter.getFullYear(),
    files: [],
    monthlyTotalSubmissionCount: 0,
  });

  return outputData;
};

export const prepareData = (sourceData, userStartDate, userFinishDate, filteringType) => {
  if (!sourceData.length) {
    return [];
  }

  if (filteringType === "monthly") {
    return prepareMonthlyData(sourceData);
  }

  if (filteringType === "weekly") {
    return prepareWeeklyData(sourceData, userStartDate, userFinishDate);
  }
  const outputData = [];
  const responseFirstDate = new Date(sourceData[0]._id);
  const responseLastDate = new Date(sourceData[sourceData.length - 1]._id);
  const firstDay = userStartDate <= responseFirstDate ? userStartDate : responseFirstDate;
  const lastDay = userFinishDate >= responseLastDate ? userFinishDate : responseLastDate;
  // Convert api response to object
  const sourceObj = sourceData.reduce((acc, dayData) => {
    return {
      ...acc,
      [dayData._id]: dayData,
    };
  }, {});

  let currentDay = new Date(firstDay.getTime());
  currentDay.setHours(0, 0, 0, 0);
  while (currentDay <= lastDay) {
    const dateString = getDateStringFromDateObject(currentDay);
    if (sourceObj[dateString]) {
      outputData.push(sourceObj[dateString]);
    } else {
      outputData.push({
        _id: dateString,
        files: [],
        dailyTotalSubmissionCount: 0,
      });
    }
    currentDay = new Date(currentDay.getTime() + 86400000); // +1 day in ms
  }
  return outputData;
};

export const calculateTotalFileCount = (dataSet) => {
  return dataSet.reduce((accumulator, column) => {
    column.files.forEach((file) => {
      if (
        isCompressedItself(file.compressedID, file.compressedIDfull, file.fileID) ||
        isNotRelatedToCompressed(file.compressedID)
      )
        accumulator += 1;
    });
    return accumulator;
  }, 0);
};
