import healthCheckSlice from "../slices/healthCheckSlice";

export const {
  TriggerHealthCheck,
  FinishHealthCheck,
  SuccessInternetStatus,
  FailureInternetStatus,
  SuccessVpnStatus,
  FailureVpnStatus,
  SuccessAnalyserStatus,
  FailureAnalyserStatus,
  SuccessReporterStatus,
  FailureReporterStatus,
  SuccessBrokerStatus,
  FailureBrokerStatus,
  SuccessDiskStatus,
  FailureDiskStatus,
  SuccessFtiStatus,
  FailureFtiStatus,
  SuccessMlStatus,
  FailureMlStatus,
} = healthCheckSlice.actions;
