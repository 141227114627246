import * as React from "react"

const Gz = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="M539.573 49.201h-178.2c-.791 0-1.561.074-2.341.124V0L11.227 46.419V540.98l347.805 47.62v-50.814c.78.053 1.55.116 2.341.116h178.2c20.841 0 37.8-16.96 37.8-37.8V87.001c0-20.841-16.959-37.8-37.8-37.8zM170.77 360.134c-8.728 2.7-24.98 6.181-40.901 5.442-21.471-.992-36.632-7.383-47.013-18.764-10.239-10.848-15.789-26.847-15.633-44.676.169-40.36 27.2-64.455 65.485-65.924 15.612-.601 27.86 2.186 33.961 5.044l-5.922 23.586c-6.784-2.917-15.148-5.221-28.4-4.912-22.225.517-38.604 14.093-38.604 40.866 0 25.502 14.829 41.001 36.645 41.682 6.226.2 11.249-.401 13.413-1.477V314.27l-18.528-.232v-22.274l45.492-.098v68.468h.005zm120.619 11.464-106.294-4.836v-15.957l63.466-91.302v-.81l-57.736 1.274v-24.614l99.489-3.731v18.267l-65.27 92.757v.791l66.345 1.978v26.183zm264.384 128.504c0 8.933-7.267 16.199-16.2 16.199h-178.2c-.801 0-1.571-.115-2.341-.231v-142.9l79.719 24.531V268.02l-12.071 27.475-15.825 34.897-51.822-14.649v-9.324l44.001 12.445 27.918-61.559-42.715-2.455 53.789-3.473 41.86 3.238-37.721 2.172 24.965 55.073 72.71-20.566-21.047-38.464.011-.201-.137-.01-.127-.243-1.708.105-78.775-6.062-83.024 5.323V71.044c.77-.113 1.54-.243 2.341-.243h178.2c8.934 0 16.2 7.267 16.2 16.2v413.101z" fill={color} />
    <path  d="m454.043 296.019-10.357-26.359v128.041l85.356-26.262v-65.252l-62.375 17.644z" fill={color} />
  </svg>
)

export default Gz
