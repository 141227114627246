const ThreeDotsIcon = ({ color = "var(--dark-theme-element)", width = 28, height = 28 }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 48 48"
        id="three-dots">
        <path
          fill={color}
          d="M24 15C26.4853 15 28.5 12.9853 28.5 10.5 28.5 8.01472 26.4853 6 24 6 21.5147 6 19.5 8.01472 19.5 10.5 19.5 12.9853 21.5147 15 24 15zM24 28.5C26.4853 28.5 28.5 26.4853 28.5 24 28.5 21.5147 26.4853 19.5 24 19.5 21.5147 19.5 19.5 21.5147 19.5 24 19.5 26.4853 21.5147 28.5 24 28.5zM24 42C26.4853 42 28.5 39.9853 28.5 37.5 28.5 35.0147 26.4853 33 24 33 21.5147 33 19.5 35.0147 19.5 37.5 19.5 39.9853 21.5147 42 24 42z"></path>
      </svg>
    );
  };
  
  export default ThreeDotsIcon;
  