import React, { useState } from "react";
import CompareIcon from "../../../../Icon-SVG/CompareIcon";
import FileCardButton from "../../../../UI/FileCardButton/FileCardButton";
import Modal from "../../../../UI/Modal/Modal";

const Compare = () => {
  const [active, setActive] = useState(false);

  return (
    <>
      <FileCardButton setActive={setActive}>
        <CompareIcon />
        <p>Compare</p>
      </FileCardButton>
      {active && (
        <Modal active={active} setActive={setActive}>
          COMPARE MODAL
        </Modal>
      )}
    </>
  );
};

export default Compare;
