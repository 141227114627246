import * as React from "react";

const EncryptionIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".enc1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path className="enc" d="M601.6 202.9h140.9L601.6 62z" />
      <circle
        cx={742.5}
        cy={553.2}
        r={193.5}
        style={{
          fill: "none",
          stroke: "#3c82b4",
          strokeWidth: 30,
          strokeMiterlimit: 10,
        }}
        fill={color}
      />
      <path
        className="enc1"
        d="M796.1 503h-22.8v-29.5c0-17-13.8-30.8-30.8-30.8s-30.8 13.8-30.8 30.8V503h-22.8v-29.5c0-29.6 24-53.6 53.6-53.6s53.6 24 53.6 53.6V503zM787.6 523.4h-90.2c-19.8 0-35.9 16.1-35.9 35.9v75.5c0 19.8 16.1 35.9 35.9 35.9h90.2c19.8 0 35.9-16.1 35.9-35.9v-75.5c0-19.8-16.1-35.9-35.9-35.9zM754.3 609v27.2h-23.5V609c-6.9-4.1-11.6-11.6-11.6-20.2 0-12.9 10.5-23.4 23.4-23.4 12.9 0 23.4 10.5 23.4 23.4-.1 8.6-4.8 16.2-11.7 20.2z"
      />
      <path
        className="enc"
        d="M208 559c-15.6 0-28.3 12.7-28.3 28.3v55.1c0 15.6 12.7 28.3 28.3 28.3s28.3-12.7 28.3-28.3v-55.1c0-15.6-12.7-28.3-28.3-28.3zM293.5 764.8c-15.6 0-28.3 12.7-28.3 28.3v55.1c0 15.6 12.7 28.3 28.3 28.3 15.6 0 28.3-12.7 28.3-28.3V793c-.1-15.6-12.7-28.2-28.3-28.2zM387 259.2c15.6 0 28.3-12.7 28.3-28.3v-55.1c0-15.6-12.7-28.3-28.3-28.3-15.6 0-28.3 12.7-28.3 28.3V231c0 15.6 12.7 28.2 28.3 28.2zM395 353.3c-15.6 0-28.3 12.7-28.3 28.3v55.1c0 15.6 12.7 28.3 28.3 28.3 15.6 0 28.3-12.7 28.3-28.3v-55.1c0-15.6-12.7-28.3-28.3-28.3zM191.1 259.2c15.6 0 28.3-12.7 28.3-28.3v-55.1c0-15.6-12.7-28.3-28.3-28.3-15.6 0-28.3 12.7-28.3 28.3V231c.1 15.6 12.7 28.2 28.3 28.2zM271.6 465c15.6 0 28.3-12.7 28.3-28.3v-55.1c0-15.6-12.7-28.3-28.3-28.3s-28.3 12.7-28.3 28.3v55.1c0 15.6 12.7 28.3 28.3 28.3z"
        fill={color}
      />
      <path
        className="enc"
        d="M742.5 359.6V238H566.4V62H88v900h654.5V746.7c-106.9 0-193.5-86.6-193.5-193.5s86.6-193.6 193.5-193.6zM454.2 129.7h47.3v147.4h-17.9V147.6h-29.4v-17.9zM272.1 559v-17.9h47.3v147.4h-17.9V559h-29.4zm-16.9-429.3h47.3v147.4h-17.9V147.6h-29.4v-17.9zm16.4 205.7c25.4 0 46.1 20.7 46.1 46.1v55.1c0 25.4-20.7 46.1-46.1 46.1-25.4 0-46.1-20.7-46.1-46.1v-55.1c0-25.4 20.7-46.1 46.1-46.1zM145 175.8c0-25.4 20.7-46.1 46.1-46.1 25.4 0 46.1 20.7 46.1 46.1V231c0 25.4-20.7 46.1-46.1 46.1-25.4 0-46.1-20.7-46.1-46.1v-55.2zm-2 159.6h47.3v147.4h-17.9V353.3H143v-17.9zm69.2 558.9h-17.9V764.8h-29.4v-17.9h47.3v147.4zM208 688.6c-25.4 0-46.1-20.7-46.1-46.1v-55.1c0-25.4 20.7-46.1 46.1-46.1s46.1 20.7 46.1 46.1v55.1c.1 25.4-20.6 46.1-46.1 46.1zm131.6 159.6c0 25.4-20.7 46.1-46.1 46.1s-46.1-20.7-46.1-46.1V793c0-25.4 20.7-46.1 46.1-46.1s46.1 20.7 46.1 46.1v55.2zm1.2-672.4c0-25.4 20.7-46.1 46.1-46.1 25.4 0 46.1 20.7 46.1 46.1V231c0 25.4-20.7 46.1-46.1 46.1-25.4 0-46.1-20.7-46.1-46.1v-55.2zm59.1 718.5H382V764.8h-29.4v-17.9h47.3v147.4zm.1-205.7h-17.9V559h-29.4v-17.9H400v147.5zm-5-205.8c-25.4 0-46.1-20.7-46.1-46.1v-55.1c0-25.4 20.7-46.1 46.1-46.1 25.4 0 46.1 20.7 46.1 46.1v55.1c0 25.4-20.7 46.1-46.1 46.1zm86.5 411.5h-17.9V764.8h-29.4v-17.9h47.3v147.4zm3-205.7h-17.9V559h-29.4v-17.9h47.3v147.5zm18.9-205.8h-17.9V353.3h-29.4v-17.9h47.3v147.4z"
        fill={color}
      />
    </g>
  </svg>
);

export default EncryptionIcon;
