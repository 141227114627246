import * as React from "react";
const WebIcon = ({ color = "var(--text-color-card)", width = 60, height = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24">
    <g fill={color}>
      <path d="M15.322 10.383c.058.563.09 1.107.09 1.617 0 .954-.115 2.031-.291 3.12-1.09.177-2.167.293-3.121.293-.95 0-2.027-.116-3.12-.293-.177-1.089-.293-2.166-.293-3.12 0-.51.033-1.054.09-1.617 1.15.184 2.295.305 3.323.305 1.029 0 2.174-.121 3.322-.305ZM16.875 10.1c.071.658.113 1.299.113 1.9 0 .877-.088 1.839-.228 2.815a56.395 56.395 0 0 0 4.797-1.243c.317-.097.442-.136.609-.203a4.15 4.15 0 0 0 .26-.115 10.622 10.622 0 0 0-.428-4.47l-.085.026a57.003 57.003 0 0 1-5.038 1.29ZM21.402 7.32c-1.032.312-2.823.82-4.742 1.216-.442-2.687-1.217-5.366-1.59-6.58a10.53 10.53 0 0 1 6.332 5.363ZM15.112 8.822c-1.087.176-2.16.29-3.112.29-.951 0-2.025-.114-3.112-.29.417-2.542 1.16-5.139 1.54-6.379.097-.317.136-.442.203-.609.033-.083.067-.16.115-.26a10.609 10.609 0 0 1 2.563.007l.026.095c.025.087.063.21.128.415l.007.022c.323 1.03 1.182 3.901 1.642 6.71ZM7.34 8.536c.44-2.675 1.21-5.34 1.582-6.554l.008-.026a10.529 10.529 0 0 0-6.332 5.363c1.032.313 2.823.821 4.742 1.217ZM2.002 8.783a10.493 10.493 0 0 0-.423 4.513l.166.056h.003a55.357 55.357 0 0 0 5.492 1.463c-.14-.976-.228-1.938-.228-2.815 0-.601.042-1.242.113-1.9a57.01 57.01 0 0 1-5.039-1.29l-.007-.002-.077-.025ZM12 16.988c.877 0 1.839-.088 2.815-.228a56.38 56.38 0 0 1-1.243 4.797 7.148 7.148 0 0 1-.203.609 4.15 4.15 0 0 1-.115.26 10.622 10.622 0 0 1-2.508 0 4.15 4.15 0 0 1-.115-.26 7.143 7.143 0 0 1-.203-.61c-.301-.98-.83-2.813-1.243-4.796.973.14 1.935.227 2.815.227ZM5.038 15.926a60.56 60.56 0 0 1-3.082-.856 10.524 10.524 0 0 0 6.974 6.974l-.008-.026c-.325-1.06-.953-3.226-1.4-5.54a48.548 48.548 0 0 1-2.484-.552ZM22.018 15.078c-1.06.325-3.227.953-5.54 1.4-.447 2.313-1.075 4.48-1.4 5.54l-.008.026a10.524 10.524 0 0 0 6.974-6.974l-.026.008Z" />
      <path d="m1.61 13.323.076.012.027.007.01.003.007.002.006.002.005.001c-.003 0-.061-.015-.13-.027Z" />
    </g>
  </svg>
);
export default WebIcon;
