import * as React from "react"

const Sitx = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.335c.776.052 1.543.114 2.33.114h177.39c20.744 0 37.627-16.883 37.627-37.627V86.604c0-20.746-16.883-37.628-37.627-37.628h-177.39c-.787 0-1.554.074-2.33.124V0L11.176 46.208v492.308l346.22 47.402v-50.583zm2.33-464.856h177.39c8.892 0 16.125 7.232 16.125 16.125v411.218c0 8.892-7.233 16.127-16.125 16.127h-177.39c-.798 0-1.563-.117-2.33-.231V407.72h94.237v-24.513h78.987v-33.313h-44.132v-13.187h17.434v-9.759h-17.434v-11.359h17.434v-9.78h-17.434v-11.097h17.434v-9.751h-17.434v-8.57h44.132v-33.31h-78.978v33.31h26.73v73.513h-26.73v31.833h-94.247v-11.875h73.932V256.455h-73.932v-13.383h94.237v-26.02h-94.237V70.72c.767-.113 1.533-.241 2.331-.241zM53.216 351.143c-10.15-.456-20.003-3.863-24.862-7.17l3.967-18.531c5.286 3.254 13.499 6.614 22.089 6.866 9.391.278 14.409-4.005 14.409-10.73 0-6.414-4.346-10.141-15.205-14.645-14.714-5.941-24.115-15.15-24.115-29.659 0-17.014 12.396-30.549 33.489-31.407 10.349-.431 18.079 1.729 23.664 4.304l-4.753 19.158c-3.746-1.908-10.352-4.669-19.318-4.444-8.842.226-13.083 4.853-13.083 10.116 0 6.468 5.042 9.284 16.732 14.197 16.357 6.695 24.21 16.339 24.21 31.105.013 17.571-12.143 31.952-37.224 30.84zm74.418 1.723-22.476-.982V245.872l22.476-.882v107.876zm96.169-89.681-31.021.743v91.769l-24.759-1.077v-90.106l-28.161.667v-20.673l83.941-3.323v22zm82.935 97.46-11.706-22.551c-4.745-8.599-7.767-14.945-11.336-22.017h-.375c-2.614 6.938-5.785 13.133-9.688 21.406l-10.279 21.276-31.037-1.348 34.877-58.921-33.656-57.691 31.273-1.236 10.864 21.496c3.722 7.242 6.522 13.1 9.522 19.869h.37c3.016-7.769 5.473-13.212 8.696-20.325l10.921-22.636 34.384-1.365-37.47 61.211 39.495 64.348-34.855-1.516z" fill={color} />
  </svg>
)

export default Sitx
