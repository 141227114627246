import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import TabHeader from "../TabHeader";
import Details from "../../Tab/Details/Details";
import RelatedVtis from "../../Tab/RelatedVTIS/RelatedVTIS";
import ClamCloudInfo from "../../../../PagesComponents/AnalysisReports/ClamCloudMlResults/ClamCloudMlInfo";
import FileIcon from "../../../../Icon-SVG/FileIcon";
import StaticInfoCardItem from "../../../../../Pages/AnalysisReports/Content/Tabs/StaticInfo/components/StaticInfoCardItem";
import Modal from "../../../../UI/Modal/Modal";
import Backdrop from "../../../../UI/Backdrop/Backdrop";
import SubmitPopup from "../../../SubmitPopup/SubmitPopup";

import { prepareStaticInfoData } from "../../../../../Pages/AnalysisReports/Content/Tabs/StaticInfo/utils/staticInfoUtil";
import { addNotification } from "../../../../../store/actions/notifierActions";

const checkShowSubmitButton = (type) => type === "Extracted Payload" || type === "Dropped File";

const getSource = (type) => {
  if (type === "Extracted Payload") return "extractedPayloads";
  else if (type === "Dropped File") return "droppedFiles";
  else return null;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DroppedFileListContent = ({ selectedFile, loading, error }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(true);
  const [staticInfoDataList, setStaticInfoDataList] = useState([]);
  const [submitPopupActive, setSubmitPopupActive] = useState(false);
  // Even though it is just one file, we have to declare and use it as an array bc SubmitPopup works with arrays.
  const [submitFile, setSubmitFile] = useState([]);
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);

  const artifactSubmissionLoading = useSelector(
    (state) => state.analysisReports.artifactSubmissionLoading,
  );

  let query = useQuery();

  const collapseHandler = (title, subTitle) => {
    setStaticInfoDataList((prevState) =>
      prevState.map((cardData) => {
        if (cardData["title"] === title) {
          return {
            ...cardData,
            items: cardData["items"].map((cardDataItem) => {
              if (cardDataItem["subTitle"] === subTitle) {
                return {
                  ...cardDataItem,
                  collapsed: !cardDataItem["collapsed"],
                };
              }
              return cardDataItem;
            }),
          };
        }
        return cardData;
      }),
    );
  };

  useEffect(() => {
    setStaticInfoDataList([]);
    if (selectedFile) {
      const { safebrowsing, virustotal, yara, metadefender, clamav } = selectedFile;
      const subCategories = {
        safebrowsing,
        virustotal,
        yara,
        metadefender,
        clamav,
      };
      const subCategoryFields = Object.keys(subCategories);

      const staticInfoData = subCategoryFields.map((subCatField) =>
        prepareStaticInfoData(subCatField, subCategories),
      );
      setStaticInfoDataList(staticInfoData.filter((data) => data?.items?.length));
    }
  }, [selectedFile, prepareStaticInfoData]);

  const submitToSandboxHandler = () => {
    if (licenceCheck?.key) {
      setSubmitPopupActive(true);
      setSubmitFile([
        {
          fileName: selectedFile?.value,
          fileHash: selectedFile?.sha256,
          source: getSource(selectedFile?.type),
          fileIDfull: query.get("data"),
        },
      ]);
    } else {
      dispatch(
        addNotification({
          message: "Your license is not active, please update your license key.",
          options: { variant: "error" },
        }),
      );
    }
  };

  if (loading) return;

  if (error)
    return (
      <div className="dropped-files__content">
        <div className="dropped-files__content__error">Could not fetch data!</div>
      </div>
    );

  if (selectedFile === null)
    return (
      <div className="dropped-files__content">
        <div className="dropped-files__content__error">No data</div>
      </div>
    );

  return (
    <div className="dropped-files__content">
      <div className="dropped-files__content__header">
        <div className="dropped-files__content__file-name">
          <div className="dropped-files__content__icon-container">
            <FileIcon color="white" width={18} height={18} />
          </div>
          <p>{selectedFile?.value}</p>
          {checkShowSubmitButton(selectedFile.type) ? (
            <button
              className="dropped-files__content__submit"
              style={!licenceCheck?.key ? { cursor: "not-allowed" } : { cursor: "pointer" }}
              onClick={submitToSandboxHandler}>
              Submit to Sandbox
            </button>
          ) : null}
        </div>
        <div className="dropped-files__content__clamcloudml">
          <ClamCloudInfo
            clamav={
              selectedFile?.clamav?.length > 0
                ? "Detected"
                : selectedFile?.clamav?.length === 0
                ? "Clean"
                : null
            }
            cdecision={
              typeof selectedFile?.cdecision === "undefined"
                ? "Unknown"
                : selectedFile?.cdecision.malicious
                ? "Detected"
                : "Clean"
            }
            mlcheck={
              selectedFile?.mlcheck?.malicious === 0
                ? "Clean"
                : selectedFile?.mlcheck?.malicious > 0
                ? "Detected"
                : "Unknown"
            }
            yara={
              selectedFile?.yara?.length
                ? { status: "Detected", value: selectedFile?.yara.length }
                : "Clean"
            }
          />
        </div>
      </div>

      <div className="dropped-files__content__mid">
        {selectedFile?.details ? (
          <p className="dropped-files__content__key-value">
            <span>Details Preview:</span> {selectedFile.details}
          </p>
        ) : null}
        {selectedFile?.sha256 ? (
          <p className="dropped-files__content__key-value">
            <span>SHA256:</span> {selectedFile?.sha256}
          </p>
        ) : null}
      </div>

      <div className="dropped-files__content__bottom">
        <div className="dropped-files__content__table">
          <TabHeader active={activeTab} setActive={setActiveTab} />
          {activeTab && selectedFile ? <Details currentFile={selectedFile} /> : <RelatedVtis />}
        </div>
        {staticInfoDataList.length ? (
          <div className="dropped-files__content__static-results">
            {staticInfoDataList.map((data, i) => (
              <StaticInfoCardItem key={i} staticInfoCardData={data} onCollapse={collapseHandler} />
            ))}
          </div>
        ) : null}
      </div>
      {submitPopupActive ? (
        <Modal active={submitPopupActive} setActive={setSubmitPopupActive} type="submit-popup">
          <SubmitPopup
            setActive={setSubmitPopupActive}
            active={submitPopupActive}
            selectedFiles={submitFile}
            setSelectedFiles={setSubmitFile}
            isArtifact={true}
          />
        </Modal>
      ) : null}
      <Backdrop show={artifactSubmissionLoading} message="Submitting artifact file..." />
    </div>
  );
};

export default DroppedFileListContent;
