import * as React from "react"

const Swf = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.335c.776.053 1.542.115 2.329.115h177.39c20.75 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.076-2.329.123V0L11.176 46.206v492.311l346.22 47.401v-50.583zm0-464.618c.766-.113 1.532-.241 2.329-.241h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.116-2.329-.232v-443zM56.878 342.052c-11.578-.314-22.867-3.854-28.473-7.37l4.569-20.242c6.068 3.469 15.465 7.045 25.257 7.203 10.657.188 16.315-4.495 16.315-11.738 0-6.918-4.903-10.898-17.219-15.732-16.785-6.392-27.601-16.399-27.601-32.208 0-18.542 14.233-33.113 38.234-33.74 11.667-.308 20.336 2.11 26.583 4.944l-5.313 20.522c-4.197-2.092-11.608-5.113-21.732-4.944-10.024.165-14.856 5.144-14.856 10.857 0 7.013 5.741 10.069 18.979 15.4 18.384 7.215 27.152 17.525 27.152 33.346.008 18.824-13.583 34.447-41.895 33.702zm164.337 2.613-28.548-.735-9.704-50.42c-2.192-11.758-4.064-22.635-5.407-35.916h-.323c-2.026 13.131-3.875 24.168-6.562 35.875l-10.677 49.611-27.391-.703-25.615-113.343 26.108-.672 8.294 46.99c2.457 13.595 4.745 28.41 6.564 40l.315.011c1.808-12.431 4.441-26.239 7.247-40.412l9.473-47.4 27.349-.707 9.262 49.305c2.58 13.774 4.483 26.364 6.213 39.497h.344c1.732-13.102 4.338-26.918 6.785-40.956l9.617-48.661 27.548-.704-30.892 119.34zm122.742-98.52-49.312.784v27.946l45.996-.2v22.946l-45.996-.241v49.19l-28.2-.734V224.958l77.513-1.971v23.158z" fill={color} />
    <path  d="M406.462 390.882c13.48-5.648 29.092-18.259 38.232-33.849 3.548-6.11 8.985-18.656 14.891-32.567h41.099v-35.164h-26.068c4.011-8.934 7.644-16.501 10.394-20.818 10.006-15.6 34.746-15.203 34.746-15.203v-33.429s-20.835-4.779-46.882 14.761c-26.044 19.518-42.532 72.481-56.41 101.558-13.901 29.07-39.497 25.617-39.497 25.617v34.258c.01.001 16.056.442 29.495-5.164z" fill={color} />
  </svg>
)

export default Swf
