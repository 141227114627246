import { useState, useEffect } from "react";
import { saveToLocalStorage } from "../localStorage";
import { useSelector, useDispatch } from "react-redux";
import { licenceCheckRequest } from "../../store/actions/usersActions";

export const useTheme = () => {
  const dispatch = useDispatch();
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    dispatch(licenceCheckRequest());
  }, [theme]);

  useEffect(() => {
    setTheme("dark");
    document.documentElement.setAttribute("data-theme", theme);
    saveToLocalStorage("theme", theme);
  }, [licenceCheck]);

  return { theme, setTheme };
};
