const Severity = (props) => {
  const severity = props.value;

  return (
    <p style={{
      color: (props.value === 0 ? "#39FF14" : props.value < 3 ? 'var(--header-search-text-color)01F': props.value >= 3 ? '#FF3131': null)}}
      >
      {props.value}
    </p>
  );
};

export default Severity;
