import react, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";

import { agentHeader } from "../../../../utils/constants/tableHeaders";
import CloseIcon from "../../../Icon-SVG/CloseIcon";
import LineAltIcon from "../../../Icon-SVG/LineAltIcon";
import CustomTable from "../../../common/Table/CustomTable";

import "./styles.scss";

const Modal = ({ setModalActive, selectedAgent }) => {
  const history = useHistory();
  const [runningCounter, setRunningCounter] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const closePopupHandler = () => {
    setModalActive(false);
    history.push("/agentstatus");
  };

  return ReactDOM.createPortal(
    <div className="agent__backdrop" onClick={closePopupHandler}>
      <div className="agent__modal" onClick={(e) => e.stopPropagation()}>
        <div className="agent__content">
          <div className={`agent-reports-popup`}>
            <div className="agent-reports-popup__close" onClick={closePopupHandler}>
              <CloseIcon width="18" height="18" />
            </div>
            <div className="agent-reports-popup__files">
              <LineAltIcon />
              <p>{selectedAgent}</p>
            </div>
          </div>
          <div onClick={() => setModalActive(false)} className={`agent__modal__tabs`}>
            <CustomTable
              exportFile={true}
              column={agentHeader}
              setRunningCounter={setRunningCounter}
              agentHostname={selectedAgent}
              tableName={"agent"}
              leftAction={false}
              tableFolder={"dashboard"}
              isSetUrl
              paginationButtons={false}
              className=""
              minBottom="5px"
              customPageSize={9999}
              minHeightRow="76px"
            />
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal"),
  );
};

export default Modal;
