export const DETAILS_DROPPED_FILE_LIST_COLUMN_NAME = [
  {
    name: "Filename",
    accessor: "fileNames",
  },
  {
    name: "File Type",
    accessor: "type",
  },
  {
    name: "Filesize",
    accessor: "fileSize",
  },
  {
    name: "Crc32",
    accessor: "crc32",
  },
  {
    name: "SSDEEP",
    accessor: "ssdeep",
  },
  {
    name: "TLSH",
    accessor: "tlsh",
  },

  {
    name: "SHA3_384",
    accessor: "sha3_384",
  },
  {
    name: "Path",
    accessor: "path",
  },

  {
    name: "MD5",
    accessor: "mds",
  },
  {
    name: "SHA1",
    accessor: "sha1",
  },
  {
    name: "SHA256",
    accessor: "sha256",
  },
  {
    name: "SHA512",
    accessor: "sha512",
  },

];
