import React from "react";
import CardForm from "../../../Components/common/CardForm/CardForm";
import { Link } from "react-router-dom";
import CheckIcon from "../../../Components/Icon-SVG/CheckIcon";
import history from "../../../utils/history";

const PasswordReset = () => {
  return (
    <CardForm>
      <div className="card__body">
        <div className="card__body__icon" style={{ margin: 0 }}>
          <CheckIcon color="#65C469" width={78} height={78} />
        </div>
        <h2 style={{ textAlign: "center" }}>Password reset</h2>
        <p style={{ fontSize: "16px", lineHeight: "22px" }}>
          Your password has been successfully reset. Click below to log in magically.
        </p>
      </div>
      <button className="card__form-btn" onClick={() => history.push("/login")}>
        Continue
      </button>
      <p style={{ textAlign: "center" }}>
        Go back to <Link to="/login">Login</Link>
      </p>
    </CardForm>
  );
};

export default PasswordReset;
