import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RequestSearchCompletedSubmissions } from "../../../store/actions/dashboardActions";

import Spinner from "../Spinner/Spinner";

import "./styles.scss";

const getSeverity = (decisionData) => {
  if (decisionData === 0) {
    return "not suspicious";
  } else if (decisionData === 1) {
    return "suspicious";
  } else if (decisionData === 2) {
    return "malicious";
  } else {
    return "loading";
  }
};

const SearchInput = ({ uilogo = false }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchActive, setSearchActive] = useState(true);
  const searchWrapperRef = useRef(null);
  const searchResultsFromApi = useSelector((state) => state.dashboard.searchResults);
  const loading = useSelector((state) => state.dashboard.searchResultsLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSelectFile = (file) => {
    const severity = getSeverity(file.decision);
    const url = `/analysis-reports?data=${file.parentID}&state=${severity}`;
    history.push(url, { clickedFileID: file.fileID });
    setSearchResults([]);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!searchValue) {
        setSearchResults([]);
        return;
      }
      dispatch(RequestSearchCompletedSubmissions(searchValue));
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  useEffect(() => {
    setSearchResults(searchResultsFromApi);
  }, [searchResultsFromApi]);

  const handleClickOutside = (event) => {
    const searchWrapper = searchWrapperRef.current;
    if (searchWrapper && !searchWrapper.contains(event.target)) {
      setSearchValue("");
      setSearchResults([]);
    }
  };
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      className={`search-wrapper ${searchActive ? "active" : "active"} ${uilogo && "uilogosfix"}`}
      ref={searchWrapperRef}>
      <div className="search-input">
        {loading ? (
          <span className="search-input__loading">
            <Spinner width={14} height={14} />
          </span>
        ) : null}
        <input
          type="text"
          value={searchValue}
          onChange={handleChange}
          placeholder="&#128269; Search by file name/hash"
        />
      </div>
      {searchResults.length > 0 && (
        <ul>
          {searchResults.map((file, i) => (
            <li key={i} onClick={() => handleSelectFile(file)} title={file.file_name}>
              {file.file_name.length > 20
                ? `${file.file_name.substring(0, 5)}...${file.file_name.substring(
                    file.file_name.length - 4,
                  )}`
                : file.file_name}{" "}
              ({file.run_time})
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchInput;
