import React from "react";
import "./styles.scss";

const CheckBox = ({ id = 0, checked, setChecked, text, className }) => {
  return (
    <span className={`form-group ${className}`}>
      <input type="checkbox" id={id} checked={checked} onChange={() => setChecked(!checked)} />
      <label className="checkbox-label" htmlFor={id}>
        {text}
      </label>
    </span>
  );
};

export default CheckBox;
