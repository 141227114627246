import * as React from "react"

const Srt = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M537.115 48.974h-177.39c-.781 0-1.553.076-2.329.123V0L11.176 46.206v492.311l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.75 0 37.627-16.888 37.627-37.627V86.602c0-20.743-16.877-37.628-37.627-37.628zM357.396 303.332c3.453 5.218 7.133 10.483 11.307 15.8-3.506 3.828-7.339 7.612-11.307 11.393v-27.193zM69.715 365.533c-11.121-.573-21.887-4.311-27.221-7.901l4.339-19.82c5.787 3.526 14.787 7.19 24.2 7.526 10.299.357 15.819-4.199 15.819-11.422 0-6.893-4.779-10.92-16.701-15.823-16.105-6.456-26.399-16.378-26.399-31.918 0-18.233 13.561-32.709 36.693-33.602 11.362-.425 19.867 1.903 26.011 4.683l-5.218 20.588c-4.128-2.071-11.396-5.053-21.234-4.832-9.703.213-14.352 5.155-14.352 10.809 0 6.94 5.52 9.971 18.357 15.312 17.994 7.276 26.635 17.69 26.635 33.565.001 18.897-13.38 34.251-40.929 32.835zm115.862 4.204c-1.927-3.459-4.682-13.28-8.139-28.021-3.073-14.836-8.032-18.952-18.465-19.287l-7.648-.121v45.707l-24.507-1.228V254.184c7.848-1.648 19.727-3.105 33.149-3.611 16.953-.64 29.071 1.564 37.515 8.047 7.118 5.515 11.058 13.87 11.058 25.024 0 15.468-10.868 26.019-21.071 29.673v.524c8.24 3.475 12.856 11.569 15.883 22.739 3.748 13.755 7.533 29.744 9.881 34.547l-27.656-1.39zm135.031-99.356-37.646.77V374.63l-29.903-1.501V271.765l-33.843.687V249.25l101.382-3.741v24.872h.01zM553.24 497.823c0 8.887-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.117-2.329-.231V352.414c8.062-6.768 15.348-13.686 21.904-20.656 8.962 9.97 19.418 19.874 31.439 29.434l5.144-16.841c-9.674-8.178-18.193-16.603-25.605-25.05 42.72-52.068 48.484-102.904 48.788-106.012l-16.043-1.606c-.073.692-5.27 46.583-43.349 94.621a225.58 225.58 0 0 1-22.273-36.014v-64.515h101.57v-16.126h-67.864v-16.126h-15.455v16.126h-18.251V70.717c.771-.113 1.532-.242 2.335-.242h177.39c8.887 0 16.125 7.236 16.125 16.127v411.221h-.011z" fill={color} />
    <path  d="M162.834 270.124c-6.199.134-9.693.74-11.503 1.124v32.084l9.981.021c12.874.021 20.604-6.667 20.604-17.082.005-10.944-7.208-16.244-19.082-16.147zM446.395 262.954l-45.051 147.413h34.552l10.499-37.837h42.216l11.37 37.837h35.874l-45.712-147.413h-43.748zm4.818 84.642 8.746-31.277c2.404-8.536 4.588-19.685 6.777-28.428h.439c2.189 8.744 4.814 19.682 7.439 28.428l9.187 31.277h-32.588z" fill={color} />
  </svg>
)

export default Srt
