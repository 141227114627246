import * as React from "react"

const Pptx = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M577.373 86.999c0-20.838-16.949-37.8-37.8-37.8h-178.2c-.786 0-1.561.076-2.342.124V0L11.228 46.417v494.564L359.031 588.6v-50.814c.781.053 1.551.115 2.342.115h178.2c20.851 0 37.8-16.964 37.8-37.8V86.999zM83.571 315.352c-5.809 6.027-14.31 8.674-24.121 8.605-2.149-.01-4.092-.147-5.59-.4v29.109l-15.957-.485V272.91c4.925-1.131 11.907-2.101 21.876-2.457 10.249-.366 17.648 1.582 22.673 5.854 4.838 4.071 8.124 10.939 8.124 19.147.001 8.212-2.491 15.172-7.005 19.898zm68.782 0c-6.455 6.359-15.905 9.144-26.768 9.07-2.405-.011-4.548-.158-6.209-.422v30.639l-17.677-.538v-83.395c5.456-1.205 13.184-2.236 24.248-2.634 11.362-.406 19.585 1.635 25.165 6.128 5.376 4.282 9.018 11.527 9.018 20.173-.007 8.649-2.773 16.011-7.777 20.979zm82.948-32.799-25.428.586v74.226l-20.451-.616v-73.141l-23.445.543v-16.857l69.324-2.462v17.721zm66.904 77.602-9.345-17.861c-3.797-6.824-6.223-11.87-9.094-17.481h-.298c-2.099 5.553-4.648 10.526-7.771 17.176l-8.29 17.117-25.151-.765 28.242-47.523-27.253-46.267 25.331-.902 8.744 17.221c2.995 5.779 5.252 10.465 7.644 15.868l.319-.011c2.405-6.196 4.379-10.544 6.961-16.208l8.722-18.02 27.295-.973-29.742 48.626 31.335 50.825-27.649-.822zm253.568 139.946c0 8.928-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.116-2.342-.232V295.513c18.088 19.828 44.092 32.284 73.048 32.284 33.402 0 62.881-16.586 80.811-41.932l-81.264-50.97-72.595 31.838v-11.789l66.298-29.075v-95.713c-26.22 1.769-49.617 13.758-66.298 31.978V71.042c.771-.114 1.54-.243 2.342-.243h178.2c8.933 0 16.2 7.27 16.2 16.2v413.102z" fill={color} />
    <path  d="M60.645 284.386c-3.343.084-5.6.506-6.784.889v24.033c1.395.354 3.111.475 5.487.464 8.817-.042 14.32-5.094 14.32-13.562-.001-7.613-4.736-12.035-13.023-11.824zM126.9 282.738c-3.721.098-6.22.541-7.531.955v25.299c1.548.38 3.449.496 6.085.485 9.78-.042 15.881-5.368 15.881-14.286.007-8.026-5.259-12.674-14.435-12.453zM438.07 128.806v94.954l82.539 51.785c7.89-14.218 12.414-30.544 12.414-47.946 0-53.314-42.166-96.649-94.953-98.793zM374.346 365.597h108v16.115h-108zM374.346 406.625h108v16.126h-108zM374.346 447.019h108v16.126h-108z" fill={color} />
  </svg>
)

export default Pptx
