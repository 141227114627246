import * as React from "react"

const Avi = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="m110.734 250.771-.309.01c-1.561 6.689-3.122 15.201-4.833 21.714l-6.23 23.768 23.348.078-6.626-23.933c-1.883-6.658-3.768-14.98-5.35-21.637z" fill={color} />
    <path  d="M359.036 537.78c.771.042 1.551.111 2.342.111h178.2c20.84 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.077-2.342.113V0L11.222 46.417v494.564L359.036 588.6v-50.82zm0-238.276h129.252v88.516H359.036v-88.516zm0-14.596v-86.734h129.252v86.74H359.036v-.006zm2.342-214.11h178.2c8.933 0 16.199 7.27 16.199 16.2v413.103c0 8.928-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.127-2.342-.242v-112.13h164.975V180.483H359.036V71.036c.771-.114 1.54-.238 2.342-.238zm153.583 188.829v19.147h-17.708v-19.147h17.708zm-17.708-24.189V216.29h17.708v19.148h-17.708zm17.708 66.931v19.153h-17.708v-19.153h17.708zm0 43.337v19.153h-17.708v-19.153h17.708zm-380.446-.945-8.287-29.268-30.267-.353-7.404 28.46-23.981-.596 31.391-110.85 31.366-.831 33.784 114.106-26.602-.668zm88.656 2.221-30.438-.76-35.873-115.67 27.62-.736 13.829 49.072c3.905 13.883 7.48 27.288 10.212 41.942l.53.011c2.9-14.096 6.518-28.05 10.471-41.523l14.963-50.807 28.696-.762-40.01 119.233zm81.106 2.03-28.087-.701V227.404l28.087-.746v122.354z" fill={color} />
  </svg>
)

export default Avi
