import * as React from "react";

const DiscoveryIcon = ({ width = 18, height = 18, color = "#3c82b4" }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    style={{
      enableBackground: "new 0 0 1024 1024",
    }}
    xmlSpace="preserve">
    <style>{".disc1{fill:var(--header-search-text-color)}"}</style>
    <g id="Layer_2">
      <path
        className="disc"
        d="M987 866.6H37l110.2-68.8h729.6zM597.4 866.6c0 12.2-9.9 22.1-22.1 22.1H450.7c-12.2 0-22.1-9.9-22.1-22.1H37v20.1c0 19 15.4 34.4 34.4 34.4h881.1c19 0 34.4-15.4 34.4-34.4v-20.1H597.4zM298.7 256.3h-63.9c-48.3 0-87.6 39.3-87.6 87.6v422.7h57V343.9c0-16.9 13.7-30.6 30.6-30.6h63.9v-57zM725.3 256.3h63.9c48.3 0 87.6 39.3 87.6 87.6v422.7h-57V343.9c0-16.9-13.7-30.6-30.6-30.6h-63.9v-57z"
        fill={color}
      />
      <path
        className="disc1"
        d="M344.2 731.4h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM472.4 731.4h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM600.6 731.4h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM728.8 731.4h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM344.2 608.3h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM472.4 608.3h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM600.6 608.3h-49c-8.3 0-15-6.7-15-15v-49c0-8.3 6.7-15 15-15h49c8.3 0 15 6.7 15 15v49c0 8.3-6.7 15-15 15zM640.9 155.5c-70.2-70.2-184-70.2-254.3 0s-70.2 184 0 254.3 184 70.2 254.3 0 70.2-184.1 0-254.3zm-35.1 219.1c-50.8 50.8-133.2 50.8-184 0s-50.8-133.2 0-184 133.2-50.8 184 0c50.8 50.8 50.8 133.2 0 184z"
      />
      <path
        transform="rotate(-45.001 643.874 412.746)"
        className="disc1"
        d="M621.5 391.2h44.7v43h-44.7z"
      />
      <path
        className="disc1"
        d="m784.1 523.6-29.3 29.3c-5 5-13 5-18 0l-78.6-78.6c-5-5-5-13 0-18l29.3-29.3c5-5 13-5 18 0l78.6 78.6c4.9 5 4.9 13 0 18z"
      />
      <path
        className="disc"
        d="M598.1 282.6c0-3.9-.3-7.7-.8-11.5l17.4-12-5.5-16.9-21.1.5c-3.7-6.8-8.2-13-13.5-18.6l7-19.9-14.4-10.5-16.7 12.8c-6.8-3.3-14.2-5.7-21.9-7.1l-6-20.2h-17.8l-6 20.2c-7.7 1.4-15 3.8-21.9 7.1l-16.7-12.8-14.4 10.5 7 19.9c-5.3 5.5-9.9 11.8-13.5 18.6l-21.1-.5-5.5 16.9 17.4 12c-.5 3.8-.8 7.6-.8 11.5 0 3.9.3 7.7.8 11.5l-17.4 12 5.5 16.9 21.1-.5c3.7 6.8 8.2 13 13.5 18.6l-7 19.9 14.4 10.5 16.7-12.8c6.8 3.3 14.2 5.7 21.9 7.1l6 20.2h17.8l6-20.2c7.7-1.4 15-3.8 21.9-7.1l16.7 12.8 14.4-10.5-7-19.9c5.3-5.5 9.9-11.8 13.5-18.6l21.1.5 5.5-16.9-17.4-12c.6-3.7.8-7.6.8-11.5zm-84.3 40.1c-22.1 0-40.1-17.9-40.1-40.1 0-22.1 17.9-40.1 40.1-40.1 22.1 0 40.1 17.9 40.1 40.1 0 22.2-18 40.1-40.1 40.1z"
        fill={color}
      />
    </g>
  </svg>
);

export default DiscoveryIcon;
