import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  requestAddMailRecipients,
  requestDeleteMailRecipients,
  requestGetMailRecipients,
} from "../../../../../../store/actions/settingsActions";
import TrashBucketIcon from "../../../../../../Components/Icon-SVG/TrashBucketIcon";
import Field from "../../../Components/Field";

import "../styles.scss";

const MailRecipients = () => {
  const recipientListFromDB = useSelector((state) => state.settings.mailRecipientList);

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [search, setSearch] = useState("");
  const [recipientList, setRecipientList] = useState([]);

  useEffect(() => {
    dispatch(requestGetMailRecipients());
  }, [dispatch]);

  useEffect(() => {
    setRecipientList(recipientListFromDB);
  }, [recipientListFromDB]);

  const handleAddEmail = () => {
    if (email.trim() !== "" && !recipientList.includes(email)) {
      dispatch(requestAddMailRecipients({ recipient: email }));
      setEmail("");
    }
  };

  const handleRemoveEmail = (removedEmail) => {
    dispatch(requestDeleteMailRecipients({ recipient: removedEmail }));
  };

  const filteredEmailList = recipientListFromDB.filter((e) =>
    e.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <div className="border mailing__content">
      <div className="mailing__content__body">
        {/* <h3 className="m-b-20">Server Configs</h3> */}
        {/* <ToggleButton active={isSendMailTo} onClick={setIsMailingActive} />
        <hr className="mailing__content__body__divider" /> */}
        <h3>Add Recipient</h3>
        <div className="mailing__content__recipient-container">
          <div className="mailing__content__recipient-container__input-container">
            <Field
              type="text"
              value={email}
              width={"100%"}
              parentClassName={
                "m-b-10 mailing__content__recipient-container__input-container__input"
              }
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Type an email address"
            />
            <button className="primary-button" onClick={handleAddEmail}>
              Add
            </button>
          </div>
          <hr className="mailing__content__body__divider" />
          <h3>Recepients List</h3>
          <Field
            type="text"
            value={search}
            width={"100%"}
            parentClassName={"m-b-10"}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search email addresses"
          />
          <div
            className={`mailing__content__recipient-container__list ${
              !filteredEmailList || !filteredEmailList.length
                ? "mailing__content__recipient-container__list-no-data"
                : ""
            }`}>
            {filteredEmailList && filteredEmailList.length ? (
              filteredEmailList.map((e) => (
                <div className="mailing__content__recipient-container__list__item" key={e}>
                  <div
                    title={e}
                    className="mailing__content__recipient-container__list__item__email">
                    {e}
                  </div>
                  <div
                    className="mailing__content__recipient-container__list__item__remove-icon"
                    onClick={() => handleRemoveEmail(e)}>
                    <TrashBucketIcon />
                    {/* <p className="actions__button m-l-10">Delete</p> */}
                  </div>
                  {/* <button className="remove-button" onClick={() => handleRemoveEmail(e)}>
                  Remove
                </button> */}
                </div>
              ))
            ) : (
              <p>No Recipient Found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailRecipients;
