import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import MalwareDistribution from "../../../Components/PagesComponents/Dashboard/MalwareDistribution/MalwareDistribution";
import CustomTable from "../../../Components/common/Table/CustomTable";
import Card from "../../../Components/common/Card/Card";
import ChartLineIcon from "../../../Components/Icon-SVG/ChartLineIcon";
import ListPopup from "../../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";
import FolderIcon from "../../../Components/Icon-SVG/FolderIcon";

import { fileshareHeader } from "../../../utils/constants/tableHeaders";

import {
  RequestDailyIntegrationSubmissionCount,
  RequestIntegrationSubmissionCount,
  RequestIntegrationSubmissionState,
} from "../../../store/actions/dashboardActions";

import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const WinShare = () => {
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const submissionsCount = useSelector((state) => state.dashboard.integrationSubmissionCount);
  const dailySubmissionsCount = useSelector(
    (state) => state.dashboard.dailyIntegrationSubmissionCount,
  );
  const cleanState = useSelector((state) => state.dashboard.integrationCleanState);
  const suspiciousState = useSelector((state) => state.dashboard.integrationSuspiciousState);
  const maliciousState = useSelector((state) => state.dashboard.integrationMaliciousState);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();
  const [listPopupActive, setListPopupActive] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = () => {
      dispatch(RequestIntegrationSubmissionCount("fileshare"));
      dispatch(RequestDailyIntegrationSubmissionCount("fileshare"));
      dispatch(
        RequestIntegrationSubmissionState({ timeFilter: "all", integrationType: "fileshare" }),
      );
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  return (
    <div>
      {!licenceCheck?.key && (
        <div className="file-card__content--licence">
          <p>Your license is not active, please update your license key.</p>
          <p>
            Click
            <Link to="/system-info" className="file-card__content--licence-font">
              <span> Here </span>
            </Link>
            to update your license key.
          </p>
        </div>
      )}
      <div className="winshare">
        <div className="winshare__header">
          <h1>File Share </h1>
          <p>Detection of malware by file share folder.</p>
        </div>
        <div className="winshare__body">
          <div className="winshare__body__logo border ">
            <div className="winshare__body__logo--icon ">
              <FolderIcon width={150} height={150} />
            </div>
          </div>
          <div className="winshare__body__item">
            <MalwareDistribution
              cleanSubmissionsState={cleanState}
              suspiciousSubmissionsState={suspiciousState}
              maliciousSubmissionsState={maliciousState}
              isIntegration={true}
            />
          </div>
          <table className="border" style={{ height: "208px" }}>
            <thead>
              <tr>
                <th>Please put the file in the relevant folder</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Desktop/testshare</td>
              </tr>
            </tbody>
          </table>
          <div className="winshare__body__card">
            <Card
              title="Daily Upload"
              value={dailySubmissionsCount}
              icon={<ChartLineIcon width={18} height={17} color={"white"} />}
            />
            <Card
              title="Total Upload"
              value={submissionsCount}
              icon={<ChartLineIcon width={18} height={17} color={"white"} />}
            />
          </div>
        </div>
        <div className="winshare__table border" style={{ width: "100%", minHeight: "40rem" }}>
          <CustomTable
            exportFile={true}
            column={fileshareHeader}
            tableName={"winshare"}
            leftAction={false}
            tableFolder={"winshare"}
            isSetUrl
            paginationButtons={true}
            className=""
            minBottom="5px"
            customPageSize={8}
            minHeightRow="76px"
          />
        </div>
      </div>

      <ListPopup
        onClickOk={() => setListPopupActive(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="winshare"
      />
    </div>
  );
};
export default WinShare;
