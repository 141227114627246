import React from "react";

const Recipient = (props) => {
  const recipients = props.row.original.recipients;

  return (
    <div className="dashboard__table__recipient">
      <span>
        {recipients?.map(
          (data, i) =>
            i < 4 && (
              <span key={i}>
                {data} <br />
              </span>
            ),
        )}
      </span>
    </div>
  );
};

export default Recipient;
