import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../../store/actions/notifierActions";
import "./styles.scss";

const Card = ({
  title,
  value,
  icon,
  iconBackground,
  onClick,
  selected,
  minHeight,
  customMargin,
}) => {
  const dispatch = useDispatch();
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);

  const freeRunError = () => {
    dispatch(
      addNotification({
        message: "Your license is not active, please update your license key.",
        options: { variant: "error" },
      }),
    );
  };
  return (
    <div
      style={
        {
          cursor: "pointer",
          height: minHeight || "auto",
          margin: customMargin || "30px 0 0 0 0",
        } &&
        (!licenceCheck?.key && title === "Free Run"
          ? { cursor: "not-allowed" }
          : { cursor: "pointer" })
      }
      onClick={licenceCheck?.key ? onClick : freeRunError}
      className={
        title === "Analysis Free Run"
          ? "dashboard__card border analysisFreeRun"
          : "dashboard__card border"
      }>
      <div className="dashboard__card-inner">
        <div className="dashboard__card-inner__icon" style={{ background: iconBackground }}>
          {icon}
        </div>
        <div className="dashboard__card-inner__text">
          <p
            className={`dashboard__card-inner__text-title ${
              selected && "dashboard__card-inner__text-title-selected-color"
            } `}>
            {title}
          </p>
          <p className="dashboard__card-inner__text-value">{value}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
