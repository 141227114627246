import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";

import "./styles.scss";

const chartPart = 40;

const getChartData = (percentage) => {
  const totalFilledSticks = Math.round(chartPart * (percentage * 0.01));
  const strengthText = "%" + percentage;

  return { totalFilledSticks, strengthText };
};

const MalwareChart = ({ chartValue, chartName, chartIcon, chartColor }) => {
  const [color, setColor] = useState("");
  const [totalFilledSticks, setTotalFilledSticks] = useState(0);
  const [strengthText, setStrengthText] = useState("");

  useEffect(() => {
    setColor(chartColor);
  }, [chartColor]);

  useEffect(() => {
    const { totalFilledSticks, strengthText } = getChartData(chartValue, color);
    if (Number.isInteger(totalFilledSticks) && totalFilledSticks <= chartPart) {
      setTotalFilledSticks(totalFilledSticks);
    } else {
      setTotalFilledSticks(0);
    }
    setStrengthText(strengthText.toString());
  }, [chartValue, color]);

  return (
    <div>
      <div className="MalwareChart">
        <div className="MalwareChart__chartIcon">{chartIcon}</div>
        <GaugeChart
          nrOfLevels={chartPart}
          arcWidth={0.29}
          hideText={true}
          cornerRadius={30}
          id="gauge-chart1"
          arcPadding={0.019}
          needleColor="transparent"
          needleBaseColor="transparent"
          colors={[
            ...new Array(totalFilledSticks).fill(chartColor),
            ...new Array(chartPart - totalFilledSticks).fill("#f1f1f1"),
          ]}
        />
        <p>{chartName}</p>
        <h2>{strengthText}</h2>
      </div>
    </div>
  );
};

export default MalwareChart;
