import { useHistory } from "react-router-dom";

const UrlsFileHash = (props) => {
  const fileHash = props?.row?.original?.file_hash || "-";
  const reportID = props?.row?.original?.report_id || "";

  const history = useHistory();

  const navigationHandler = () => {
    if (reportID) {
      history.push(`/analysis-reports?data=${reportID}`);
    }
  };

  return (
    <span title={fileHash} style={{ textDecoration: "underline" }} onClick={navigationHandler}>
      {fileHash}
    </span>
  );
};

export default UrlsFileHash;
