import useCalculateMenuPosition from "../../../utils/hook/useCalculateMenuPosition";

import "./styles.scss";

const MiniMenu = ({ isOpen, className = "", children }) => {
  const { position, menuRef } = useCalculateMenuPosition(isOpen);

  if (!isOpen) return null;

  return (
    <>
      <div
        className={`miniMenu  ${position ? `miniMenu__${position}` : "miniMenu__hidden"} ${
          className ? className : ""
        }`}
        ref={menuRef}
        onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </>
  );
};

export default MiniMenu;
