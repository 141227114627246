import React, { useState, Suspense, useEffect } from "react";
import Spinner from "../../../Components/common/Spinner/Spinner";
import TabButton from "../../../Components/common/TabButton/TabButton";
import FilesInfo from "../../../Components/PagesComponents/AnalysisReports/FilesInfo/FilesInfo";
import NetworkActivityMap from "../../../Components/PagesComponents/AnalysisReports/NetworkActivityMap/NetworkActivityMap";
import MemoryBlock from "../../../Components/PagesComponents/AnalysisReports/MemoryBlock/MemoryBlock";
import DemoPopup from "../../../Components/common/DemoPopup/DemoPopup";
import InformationPopup from "../../../Components/common/InformationPopup/InformationPopup";
import { informationPopupContent } from "../../../Components/common/InformationPopup/utils/informationPopupUtil";

import "./styles.scss";

const AttackMap = React.lazy(() => import("./Tabs/AttackMap/AttackMap").then((r) => r));
const Overview = React.lazy(() => import("./Tabs/Overview/Overview").then((r) => r));

const Matrix = React.lazy(() => import("./Tabs/Matrix/Matrix").then((r) => r));
const DroppedFileList = React.lazy(() =>
  import("../../../Components/common/DroppedFileList/DroppedFileList").then((r) => r),
);
const StaticInfo = React.lazy(() => import("./Tabs/StaticInfo/StaticInfo").then((r) => r));
const FileDetails = React.lazy(() => import("./Tabs/FileDetails/FileDetails").then((r) => r));

const getInformationPopupContent = (active) => {
  let content = "";
  if (active === "Attack Map") {
    content = informationPopupContent.attackMap;
  } else if (active === "Signature Matches") {
    content = informationPopupContent.signatureMatches;
  } else if (active === "MITRE ATT&CK™ Matrix") {
    content = informationPopupContent.mitre;
  } else if (active === "Static Results") {
    content = informationPopupContent.staticResults;
  } else if (active === "File Details") {
    content = informationPopupContent.fileDetails;
  }
  return content;
};

const Content = ({ staticResultsActive, setStaticResultsActive }) => {
  const [active, setActive] = useState("Attack Map");
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (staticResultsActive) {
      setActive("Static Results");
    }
  }, [staticResultsActive]);

  const onTitleClicked = (title) => {
    if (title !== "Static Results") {
      setStaticResultsActive(false);
    }
    setActive(title);
  };

  return (
    <div className="analysis-reports__table ">
      <div className="button-block">
        <TabButton title="Attack Map" onClick={onTitleClicked} active={active} />
        <TabButton title="Signature Matches" onClick={onTitleClicked} active={active} />

        <TabButton title="MITRE ATT&CK™ Matrix" onClick={onTitleClicked} active={active} />
        {/*<TabButton title="Generated IOCs" onClick={() => setShowDelete(true)} active={active} />*/}
        <TabButton title="Static Results" onClick={onTitleClicked} active={active} />
        <TabButton title="File Details" onClick={onTitleClicked} active={active} />
      </div>
      {active === "Attack Map" ? (
        <Suspense
          fallback={
            <div className="spinner-block border">
              <Spinner />
            </div>
          }>
          <AttackMap />
        </Suspense>
      ) : active === "Signature Matches" ? (
        <Suspense
          fallback={
            <div className="spinner-block border">
              <Spinner />
            </div>
          }>
          <Overview />
        </Suspense>
      ) : active === "MITRE ATT&CK™ Matrix" ? (
        <Suspense
          fallback={
            <div className="spinner-block border">
              <Spinner />
            </div>
          }>
          <Matrix />
        </Suspense>
      ) : active === "Static Results" ? (
        <Suspense
          fallback={
            <div className="spinner-block border">
              <Spinner />
            </div>
          }>
          <StaticInfo />
        </Suspense>
      ) : active === "File Details" ? (
        <Suspense
          fallback={
            <div className="spinner-block border">
              <Spinner />
            </div>
          }>
          <FileDetails />
        </Suspense>
      ) : active === "Artifact File List" ? (
        <Suspense
          fallback={
            <div className="spinner-block border">
              <Spinner />
            </div>
          }>
          <DroppedFileList />
        </Suspense>
      ) : null}
      <div className="block-network-map">
        <FilesInfo />
        <NetworkActivityMap />
      </div>
      <div className="block-memory">
        <MemoryBlock />
      </div>
      <DemoPopup
        onClickOk={() => setShowDelete(false)}
        show={showDelete}
        setShow={setShowDelete}
        text="This feature is not available in the demo version."
        article="DEMO"
      />
      <span className="analysis-reports__table__information-popup">
        <InformationPopup
          content={getInformationPopupContent(active)}
          iconWidth={28}
          iconHeight={28}
        />
      </span>
    </div>
  );
};

export default Content;
