import * as React from "react"

const Cpl = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M168.33 247.438c-5.52.155-9.231.816-11.171 1.431v36.439c2.302.538 5.124.716 9.042.706 14.552-.066 23.672-7.769 23.672-20.686.001-11.606-7.862-18.299-21.543-17.89z" fill={color} />
    <path  d="M537.115 48.979h-177.39c-.787 0-1.553.077-2.329.124V0L11.176 46.206v492.311l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.756 0 37.627-16.888 37.627-37.627V86.602c0-20.743-16.871-37.623-37.627-37.623zM89.833 330.776c7.413.189 15.727-1.365 20.662-3.371l3.801 21.251c-4.628 2.309-14.927 4.545-28.125 3.994-36.213-1.485-54.145-26.535-54.145-58.736 0-38.578 24.63-61.145 56.539-62.565 12.743-.57 22.581 1.84 27.064 4.139l-5.134 21.67c-5.1-2.163-12.105-4.082-20.817-3.833-19.131.557-33.641 13.439-33.641 39.034-.004 23.046 12.331 37.871 33.796 38.417zm116.577-33.539c-9.703 9.192-23.846 13.172-40.043 13.014-3.556-.042-6.729-.258-9.208-.652v44.138l-26.07-1.039V233.061c8.021-1.79 19.422-3.377 35.812-4.092 16.942-.748 29.247 2.103 37.617 8.552 8.105 6.163 13.606 16.658 13.606 29.239.004 12.576-4.189 23.267-11.714 30.477zm117.169 63.125-86.723-3.461V226.867l30.436-1.31V332.95l56.287 1.343v26.069zM553.24 497.823c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.231V70.722c.766-.112 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.126v411.217z" fill={color} />
    <path  d="m361.05 195.178.031 218.092 167.803-.1-.031-218.239-167.803.247zm11.076 207.154-.127-196.245 145.536-.076.031 196.246-145.44.075z" fill={color} />
    <path  d="M454.604 330.004c.703-2.399 2.792-4.095 5.276-4.337.881-.088 1.695-.498 2.215-1.149.53-.645.771-1.507.66-2.341a6.194 6.194 0 0 1 3.15-6.131l5.827-3.161a6.107 6.107 0 0 1 4.614-.503l1.06.314 1.05.83c.82.691 1.628.892 2.415.792a3.197 3.197 0 0 0 2.163-1.187c1.475-1.896 3.843-2.667 6.131-2.152l1.239-4.174a1.244 1.244 0 0 0-.388-1.238 18.343 18.343 0 0 1-6.982-12.536 18.414 18.414 0 0 1 4.22-13.732c.294-.347.354-.861.143-1.26l-6.661-12.293c-.368-.683-.799-.627-1.15-.586-4.866.63-9.942-.806-13.789-3.944-3.854-3.108-6.29-7.747-6.721-12.702a1.112 1.112 0 0 0-.808-.969l-13.386-3.979a1.104 1.104 0 0 0-1.207.378c-3.056 3.924-7.622 6.467-12.558 6.971-4.914.538-9.952-1.016-13.732-4.242-.231-.175-.588-.473-1.26-.113l-12.294 6.656c-.421.231-.65.683-.588 1.145a18.365 18.365 0 0 1-3.947 13.803c-3.103 3.854-7.733 6.278-12.688 6.709-.462.021-.85.344-.991.806l-3.964 13.407c-.142.43.011.903.372 1.2a18.456 18.456 0 0 1 6.988 12.546c.504 4.924-1.041 9.951-4.254 13.732a1.14 1.14 0 0 0-.125 1.259l6.667 12.315c.363.672.803.604 1.135.558a18.258 18.258 0 0 1 7.489.618 17.867 17.867 0 0 1 6.331 3.35 18.384 18.384 0 0 1 6.708 12.677c.044.462.369.852.811.993l13.379 3.979c.463.13.936-.026 1.224-.379 3.051-3.922 7.622-6.467 12.557-6.971 4.798-.509 9.659.955 13.387 3.952.23-.534.545-1.054.924-1.526a3.212 3.212 0 0 0 .767-2.374 3.227 3.227 0 0 0-1.208-2.126c-1.983-1.596-2.804-4.143-2.095-6.54l1.894-6.345zm-26.325-6.001c-14.374-4.262-22.578-19.433-18.316-33.808s19.428-22.604 33.802-18.332c14.389 4.273 22.593 19.455 18.331 33.82-4.269 14.362-19.446 22.595-33.817 18.32z" fill={color} />
    <path  d="M504.82 338.213a8.735 8.735 0 0 1 2.005-6.498.565.565 0 0 0 .062-.599l-3.149-5.836c-.178-.332-.388-.296-.535-.274-2.31.295-4.745-.388-6.562-1.863a8.742 8.742 0 0 1-3.176-6.026.543.543 0 0 0-.372-.463l-6.357-1.878c-.21-.052-.44.011-.561.178-1.434 1.849-3.612 3.076-5.942 3.308a8.656 8.656 0 0 1-6.51-2.016c-.115-.085-.272-.23-.587-.063l-5.838 3.16c-.189.105-.314.337-.283.535a8.766 8.766 0 0 1-1.859 6.552 8.747 8.747 0 0 1-6.015 3.181.542.542 0 0 0-.473.379l-1.891 6.342a.527.527 0 0 0 .185.576 8.704 8.704 0 0 1 3.306 5.953c.231 2.331-.487 4.713-2.005 6.51-.142.151-.152.397-.067.582l3.166 5.838c.172.33.371.288.535.268a8.548 8.548 0 0 1 3.547.293 8.73 8.73 0 0 1 2.999 1.576 8.71 8.71 0 0 1 3.18 6.015.53.53 0 0 0 .379.463l6.342 1.88c.21.072.44 0 .571-.158 1.454-1.87 3.617-3.077 5.937-3.307 2.337-.264 4.716.486 6.525 2.005.111.1.273.221.582.068l5.828-3.171a.535.535 0 0 0 .283-.53c-.294-2.342.368-4.734 1.874-6.552 1.465-1.836 3.659-2.981 6.005-3.182a.573.573 0 0 0 .482-.377l1.886-6.352a.575.575 0 0 0-.189-.578c-1.858-1.433-3.056-3.607-3.308-5.939zm-9.932 5.992c-2.016 6.817-9.196 10.697-16.016 8.671-6.809-2.005-10.697-9.196-8.682-16.016 2.025-6.798 9.212-10.709 16.016-8.684 6.809 2.038 10.709 9.219 8.682 16.029z" fill={color} />
  </svg>
)

export default Cpl
