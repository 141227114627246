import * as React from "react"

const Fon = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M157.827 253.47c-16.122.504-25.329 16.636-25.329 37.815 0 21.339 9.521 36.604 25.497 37.003 16.513.398 26.166-15.822 26.166-37.835-.001-20.334-9.487-37.511-26.334-36.983z" fill={color} />
    <path  d="M537.115 48.979h-177.39c-.787 0-1.553.077-2.329.124V0L11.176 46.206v492.311l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.745 0 37.627-16.888 37.627-37.627V86.602c0-20.743-16.882-37.623-37.627-37.623zM96.162 260.453l-36.274 1.075v23.513l33.828-.36v19.458l-33.828-.161v41.38l-20.485-.776V243.357l56.759-2.551v19.647zm60.557 90.413c-30.903-1.218-48.323-25.87-48.323-56.332 0-32.056 19.238-56.912 50.066-58.339 33.648-1.56 52.744 24.118 52.744 55.987-.001 37.869-22.584 59.938-54.487 58.684zm177.16 4.924-31.77-1.208-27.481-47.627c-7.449-12.878-15.562-28.274-21.588-42.133l-.549.185c.732 15.696 1.092 32.462 1.092 51.879v35.843l-25.016-.945V234.861l31.961-1.436 26.179 43.434c7.659 12.782 15.391 28.073 21.249 42.021l.589.011c-1.952-16.324-2.552-32.973-2.552-51.459v-36.053l27.886-1.25V355.79zM553.24 497.823c0 8.892-7.232 16.127-16.125 16.127h-177.39c-.797 0-1.563-.111-2.329-.231V376.635h55.527v-7.791c-20.83-1.938-23.318-4.714-17.218-22.204 3.612-11.665 7.78-23.33 12.216-35.822h59.145c6.101 16.934 11.653 31.927 15.265 42.762 3.612 10.829 1.114 13.05-16.378 15.265v7.78h68.022v-7.78c-18.048-1.659-21.66-4.152-30.542-28.042-17.773-48.302-36.367-101.062-53.585-148.811l-8.608 2.499-57.472 145.76c-8.766 22.053-12.819 25.555-26.372 27.869V70.722c.766-.112 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.126v411.217zM412.095 298.885c8.326-22.759 17.207-46.922 26.1-70.794h.556l24.432 70.794h-51.088z" fill={color} />
  </svg>
)

export default Fon
