import React from "react";

const SideBarOpen = ({ color = "var(--text-color-card)", width = 18, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1715 10.8285L17 8L14.1715 5.1715M17 1.5H1H17ZM17 8H1H17ZM17 14.5H1H17Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SideBarOpen;
