import * as React from "react";

const FileTypeIcon = (props) => (
  <svg
    width={7.408}
    height={7.408}
    viewBox="-0.4 0 7.408 7.408"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M132.904 42.002a1 1 0 0 0-.9.998l-.008 2c-.03 1.363 2.03 1.363 2 0v-.996H148v23.998h-14.004v-1.004c-.034-1.304-1.976-1.296-2 .008l.008 1.992a1 1 0 0 0 .996 1.004h16.004a1 1 0 0 0 .996-1.004V43a1 1 0 0 0-.996-.998zM129.994 47a1 1 0 0 0-.701.295l-4 4.002a1 1 0 0 0-.295.701V64a1 1 0 0 0 .996.996H139a1 1 0 0 0 .998-.996V47.996A1 1 0 0 0 139 47Zm.414 2h7.588v13.996h-10.998V52.41Zm-.414 4.998c-1.363-.03-1.363 2.03 0 2H135c1.364.03 1.364-2.03 0-2zm0 4.002c-1.305.028-1.305 1.972 0 2H135c1.362.03 1.362-2.03 0-2z"
      style={{
        color: "var(--header-search-text-color)",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: "medium",
        lineHeight: "normal",
        fontFamily: "sans-serif",
        fontVariantLigatures: "normal",
        fontVariantPosition: "normal",
        fontVariantCaps: "normal",
        fontVariantNumeric: "normal",
        fontVariantAlternates: "normal",
        fontFeatureSettings: "normal",
        textIndent: 0,
        textAlign: "start",
        textDecoration: "none",
        textDecorationLine: "none",
        textDecorationStyle: "solid",
        textDecorationColor: "#000",
        letterSpacing: "normal",
        wordSpacing: "normal",
        textTransform: "none",
        writingMode: "lr-tb",
        direction: "ltr",
        textOrientation: "mixed",
        dominantBaseline: "auto",
        baselineShift: "baseline",
        textAnchor: "start",
        whiteSpace: "normal",
        shapePadding: 0,
        clipRule: "nonzero",
        display: "inline",
        overflow: "visible",
        visibility: "visible",
        opacity: 1,
        isolation: "auto",
        mixBlendMode: "normal",
        colorInterpolation: "sRGB",
        colorInterpolationFilters: "linearRGB",
        solidColor: "#000",
        solidOpacity: 1,
        vectorEffect: "none",
        fill: "var(--text-color-card)",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
        strokeDasharray: "none",
        strokeDashoffset: 0,
        strokeOpacity: 1,
        colorRendering: "auto",
        imageRendering: "auto",
        shapeRendering: "auto",
        textRendering: "auto",
        enableBackground: "accumulate",
      }}
      transform="matrix(.26458 0 0 .26458 -33.072 -11.113)"
    />
  </svg>
);

export default FileTypeIcon;
