import * as React from "react"

const Apk = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.33c.776.042 1.542.109 2.329.109h177.39c20.75 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.077-2.329.113V0L11.176 46.206v492.311l346.22 47.401V535.33zm2.33-464.854h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-10.803l3.127-2.794.504-.509c7.256-7.271 7.256-19.16-.011-26.432-7.264-7.264-19.154-7.264-26.426.006l-27.044 27.028h-13.219l33.653-33.648-118.933-118.923-20.567 20.566v-13.214l13.953-13.956c7.274-7.281 7.274-19.165 0-26.437-3.833-3.838-8.926-5.539-13.953-5.292V70.711c.765-.112 1.532-.235 2.329-.235zM90.498 346.57l-7.979-28.636-29.124-.351-7.126 27.869-23.105-.589 30.226-108.52 30.184-.785 32.494 111.658-25.57-.646zm109.447-50.766c-8.811 8.447-21.743 12.153-36.638 12.042-3.297-.026-6.257-.221-8.525-.566v40.925l-24.41-.61V235.866c7.535-1.538 18.205-2.832 33.417-3.236 15.598-.409 26.845 2.402 34.457 8.443 7.326 5.743 12.294 15.397 12.294 26.883.001 11.504-3.768 21.25-10.595 27.848zm94.433 55.958-30.057-52.629-10.376 12.566v39.034l-26.614-.672V231.835l26.614-.693v52.882h.528c2.676-4.626 5.541-8.918 8.229-13.207l27.813-40.623 34.895-.913-41.5 52.662 43.786 70.666-33.318-.847z" fill={color} />
    <path  d="M165.112 252.223c-5.103.097-8.536.661-10.331 1.205v33.798c2.11.507 4.746.672 8.357.672 13.417-.021 21.766-7.095 21.766-18.974-.004-10.674-7.198-16.922-19.792-16.701zM522.731 381.831l24.43-7.712a2.332 2.332 0 0 0 1.514-2.93c-.368-1.233-1.701-1.914-2.919-1.526l-24.798 7.816a83.995 83.995 0 0 0-17.462-25.411 83.812 83.812 0 0 0-25.416-17.451l7.822-24.803a2.34 2.34 0 0 0-1.524-2.93 2.352 2.352 0 0 0-2.929 1.534l-7.706 24.425c-27.187-10.242-58.604-5.922-82.205 12.986l118.201 118.196c18.913-23.588 23.24-55.017 12.992-82.194zm-69.114-13.568c-5.29 5.274-13.868 5.274-19.16-.011-5.292-5.287-5.292-13.87 0-19.155 5.28-5.292 13.87-5.292 19.171 0 5.281 5.291 5.281 13.868-.011 19.166zm33.691 52.849c-5.291-5.285-5.28-13.868 0-19.154 5.303-5.293 13.869-5.293 19.172 0 5.285 5.286 5.285 13.869 0 19.154-5.303 5.298-13.87 5.298-19.172 0zM69.043 255.89h-.307c-1.499 6.552-3.016 14.89-4.659 21.252l-5.984 23.266 22.481.078-6.386-23.412c-1.817-6.531-3.633-14.675-5.145-21.184z" fill={color} />
  </svg>
)

export default Apk
