import * as React from "react"

const Ttf = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M537.108 48.979H359.721c-.782 0-1.554.077-2.335.124V0L11.176 46.206v492.311l346.22 47.402v-50.583c.776.052 1.542.114 2.329.114h177.39c20.756 0 37.627-16.888 37.627-37.627V86.602c-.005-20.743-16.871-37.623-37.634-37.623zM114.556 260.271l-32.817.682v103.168l-26.271-1.213V261.499l-29.919.625v-23.297l89.012-3.257v24.702h-.005zm111.227-2.323-37.586.785v110.322l-30.005-1.392V259.354l-34.1.717v-24.855l101.691-3.733v26.465zm109.749-2.953-59.382 1.26v32.783l55.36-.124v27.246l-55.36-.714v57.682l-33.268-1.539V230.861l92.65-3.396v27.53zm217.703 242.828c0 8.892-7.238 16.127-16.127 16.127H359.721c-.792 0-1.563-.111-2.335-.231V236.94c.099-.139.179-.302.278-.436 2.797-4.945 7.092-6.664 24.945-6.664h13.327v105.797c0 17.002-1.716 19.149-21.292 20.44v6.015h61.724v-6.015c-20.43-1.291-22.153-3.438-22.153-20.44V229.84h15.267c16.125 0 19.783 1.503 23.222 6.664 2.111 2.811 3.791 6.963 5.628 13.987h-17.249c-6.015 0-8.819-.42-11.821-5.37h-4.306c-.441 11.827-1.501 25.803-2.571 38.714h6.226c2.578-9.473 4.295-15.485 6.876-19.142 2.799-4.945 7.103-6.67 24.946-6.67h13.338v105.803c0 16.997-1.717 19.14-21.292 20.44v6.01h61.708v-6.01c-20.42-1.301-22.137-3.443-22.137-20.44V258.023h15.265c16.138 0 19.796 1.505 23.219 6.67 2.582 3.441 4.52 8.812 6.888 19.142l6.235-.651c-1.07-12.465-2.163-28.166-2.163-38.063h-4.514c-3.434 4.092-5.155 5.37-10.962 5.37H465.44c-.933-11.562-1.773-24.816-1.773-33.551h-4.514c-3.444 4.089-5.166 5.369-10.972 5.369h-84.925c-2.395 0-4.274-.086-5.858-.479V70.722c.766-.112 1.532-.242 2.329-.242h177.39c8.893 0 16.125 7.236 16.125 16.126v411.216h-.007z" fill={color} />
  </svg>
)

export default Ttf
