import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import {
  renderLeftNav,
  renderRightNav,
  renderPlayPauseButton,
  renderFullscreenButton,
} from "./components/Controls";

import "./styles.scss";

const Screenshots = () => {
  const loading = useSelector((state) => state.analysisReports.screenshotsLoading);
  const screenShotsFiles = useSelector((state) => state.analysisReports.screenshots);
  const [items, setItems] = useState([]);

  const ref = useRef();

  useEffect(() => {
    if (screenShotsFiles) {
      const imageGalleryItems = screenShotsFiles.map((ssFile) => ({
        original: `data:image/jpeg;base64,${ssFile}`,
        thumbnail: `data:image/jpeg;base64,${ssFile}`,
        bulletClass: "small-bullet",
      }));
      setItems(imageGalleryItems);
    }
  }, [screenShotsFiles]);

  const viewAllHandler = () => {
    ref.current.toggleFullScreen();
  };

  return (
    <div className="analysis-reports__body-block__screenshots border">
      <Backdrop show={loading} absolute={true} />
      <div className="analysis-reports__body-block__screenshots-header">
        <h3 className="article">Screenshots of File Activity</h3>
        {!loading && (
          <button
            onClick={viewAllHandler}
            className="analysis-reports__body-block__screenshots-header__button">
            View All
          </button>
        )}
      </div>

      {!loading && (
        <div className="analysis-reports__body-block__screenshots__image-gallery">
          <ImageGallery
            items={items}
            showBullets={true}
            thumbnailPosition="right"
            ref={ref}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            renderPlayPauseButton={renderPlayPauseButton}
            renderFullscreenButton={renderFullscreenButton}
          />
        </div>
      )}
    </div>
  );
};

export default Screenshots;
