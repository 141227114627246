import UploadIcon from "../../../Icon-SVG/UploadIcon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RequestSetReportLogo,
  RequestGetReportLogo,
} from "../../../../store/actions/analysisReportActions";
import "./styles.scss";

const SelectLogo = () => {
  const dispatch = useDispatch();
  const [bannerLogo, setBannerLogo] = useState("");
  const reportLogo = useSelector((state) => state.analysisReports.reportLogo);
  var bannerImg = document.getElementById("tableBanner");

  const setViewLogo = (logoSrc) => {
    setBannerLogo(logoSrc);
  };

  useEffect(() => {
    dispatch(RequestGetReportLogo());
  }, []);

  useEffect(() => {
    setBannerLogo("data:image/png;base64," + reportLogo);
  }, [reportLogo]);

  const imageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64StringUS = reader.result.replace("data:", "").replace(/^.+,/, "");
        localStorage.setItem("wallpaperXXX", base64StringUS);
        const myImage = localStorage.getItem("wallpaperXXX");
        bannerImg = document.getElementById("tableBanner");
        setViewLogo("data:image/png;base64," + myImage);
        dispatch(RequestSetReportLogo(file));
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select an image file.");
    }
  };

  return (
    <div className="selectlogo">
      <table>
        <tbody>
          <tr>
            <td>Report Logo</td>
          </tr>
          <tr>
            <td>
              <img alt="" id="tableBanner" className="selectlogo--img" src={bannerLogo} />
            </td>
            <td>
              <label htmlFor="imageFile" className="selectlogo--img-upload">
                <UploadIcon color="var(--header-search-text-color)" width={20} height={20} />
                Upload Logo
              </label>
              <input
                className="selectlogo--input"
                type="file"
                id="imageFile"
                placeholder="deneme"
                name="imageFile"
                onChange={imageUpload}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SelectLogo;
