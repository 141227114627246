const NetworkDetectionsAction = (props) => {
  const action = props?.row?.original?.action || "-";
  return (
    <span
      className={`network-detections__table__action--${
        action === "Block" ? "block" : action === "Request" ? "request" : "empty"
      }`}>
      {action}
    </span>
  );
};

export default NetworkDetectionsAction;
