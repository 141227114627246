import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ThreeDotsIcon from "../../../../Icon-SVG/ThreeDotsIcon";
import StaticAnalysisIcon from "../../../../Icon-SVG/StaticAnalysisIcon";
import DownloadIcon from "../../../../Icon-SVG/DownloadIcon";
import MiniMenu from "../../../../common/MiniMenu/MiniMenu";
import Backdrop from "../../../../UI/Backdrop/Backdrop";
import DownloadMessage from "../../../../common/DownloadMessage/DownloadMessage";

import { useOnClickOutside } from "../../../../../utils/hook/useOnClickOutside";
import axiosApi from "../../../../../utils/axios";

import { addNotification } from "../../../../../store/actions/notifierActions";

const ThreeDots = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fileHash = props?.row?.original?.file?.hash || "-";

  const [menuOpen, setMenuOpen] = useState(false);
  const [downloadOptions, setDownloadOptions] = useState({
    loaded: 0,
    total: 0,
    progress: 0,
    downloadStarted: false,
  });
  const [fileName, setFileName] = useState("");

  const ref = useRef();
  useOnClickOutside(ref, () => setMenuOpen(false));

  const staticResultLink = props.row.original.staticResultLink;

  const iconOnClickHandler = (event) => {
    event.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  const menuOnClickHandler = (event) => {
    event.stopPropagation();
  };

  const staticResultsOnClickHandler = (event) => {
    event.stopPropagation();

    history.push(staticResultLink);
  };

  const downloadSampleHandler = async () => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total, progress } = progressEvent;
        setDownloadOptions({
          loaded,
          total,
          progress: progress * 100,
          downloadStarted: loaded && progress > 0 ? true : false,
        });
      },
    };
    try {
      const _ = await axiosApi.get(`api/files/check-sample/${fileHash}`);
      const response = await axiosApi.get(`api/files/download-sample/${fileHash}`, {
        responseType: "blob",
        ...options,
      });
      const fileName =
        response.headers["content-disposition"].split("filename=")[1] || "sample.zip";
      setFileName(fileName);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      setDownloadOptions((options) => ({ ...options, downloadStarted: false }));
      dispatch(
        addNotification({
          message: err.response.status === 404 ? "Sample not found" : "Error",
          options: { variant: "error" },
        }),
      );
    } finally {
      setTimeout(() => {
        setDownloadOptions((options) => ({ ...options, downloadStarted: false }));
      }, 1500);
    }
  };

  return (
    <div className="dashboard__table__threedots" ref={ref} onClick={(e) => e.stopPropagation()}>
      <div className="dashboard__table__threedots__icon" onClick={iconOnClickHandler}>
        <ThreeDotsIcon />
      </div>
      <MiniMenu isOpen={menuOpen} className="dashboard__table__threedots__menu">
        <div onClick={staticResultsOnClickHandler}>
          <StaticAnalysisIcon color="white" height={22} width={22} /> Static Results
        </div>
        <div onClick={downloadSampleHandler}>
          <DownloadIcon height={21} width={21} color="white" /> Download Sample
        </div>
      </MiniMenu>
      <Backdrop
        show={downloadOptions.downloadStarted}
        message={
          <DownloadMessage
            fileName={fileName}
            loaded={downloadOptions.loaded}
            total={downloadOptions.total}
            progress={downloadOptions.progress}
          />
        }
      />
    </div>
  );
};

export default ThreeDots;
