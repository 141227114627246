import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import InternetIcon from "../../../Icon-SVG/InternetIcon";
import VpnIcon from "../../../Icon-SVG/VpnIcon";
import AnalyserIcon from "../../../Icon-SVG/AnalyserIcon";
import DiskIcon from "../../../Icon-SVG/DiskIcon";
import MLSystemIcon from "../../../Icon-SVG/MLSystemIcon";
import TableSpinnerIcon from "../../../Icon-SVG/TableSpinnerIcon";

import { TriggerHealthCheck } from "../../../../store/actions/healthCheckActions";

import "./styles.scss";

const ICON_SIZE = {
  width: 20,
  height: 20,
};

const SERVICES = [
  {
    id: "internet",
    header: "Internet",
    renderIcon: (color, width, height) => (
      <InternetIcon color={color} width={width} height={height} />
    ),
  },
  {
    id: "vpn",
    header: "VPN",
    renderIcon: (color, width, height) => <VpnIcon color={color} width={width} height={height} />,
  },
  {
    id: "analyser",
    header: "Analyser Svc.",
    renderIcon: (color, width, height) => (
      <AnalyserIcon color={color} width={width} height={height} />
    ),
  },
  {
    id: "reporter",
    header: "Reporter Svc.",
    renderIcon: (color, width, height) => (
      <AnalyserIcon color={color} width={width} height={height} />
    ),
  },
  {
    id: "broker",
    header: "Broker Svc.",
    renderIcon: (color, width, height) => (
      <AnalyserIcon color={color} width={width} height={height} />
    ),
  },
  {
    id: "disk",
    header: "Disk Size",
    renderIcon: (color, width, height) => <DiskIcon color={color} width={width} height={height} />,
  },
  {
    id: "fti",
    header: "FortressTI",
    renderIcon: (color, width, height) => (
      <MLSystemIcon color={color} width={width} height={height} />
    ),
  },
  {
    id: "ml",
    header: "ML System",
    renderIcon: (color, width, height) => (
      <MLSystemIcon color={color} width={width} height={height} />
    ),
  },
];

const SystemHealth = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.healthCheck.loading);
  const internetStatus = useSelector((state) => state.healthCheck.internetStatus);
  const vpnStatus = useSelector((state) => state.healthCheck.vpnStatus);
  const analyserStatus = useSelector((state) => state.healthCheck.analyserStatus);
  const reporterStatus = useSelector((state) => state.healthCheck.reporterStatus);
  const brokerStatus = useSelector((state) => state.healthCheck.brokerStatus);
  const diskStatus = useSelector((state) => state.healthCheck.diskStatus);
  const ftiStatus = useSelector((state) => state.healthCheck.ftiStatus);
  const mlStatus = useSelector((state) => state.healthCheck.mlStatus);

  const getStatus = (id) => {
    let status = "close";
    switch (id) {
      case "internet":
        status = internetStatus;
        break;
      case "vpn":
        status = vpnStatus;
        break;
      case "analyser":
        status = analyserStatus;
        break;
      case "reporter":
        status = reporterStatus;
        break;
      case "broker":
        status = brokerStatus;
        break;
      case "disk":
        status = diskStatus;
        break;
      case "fti":
        status = ftiStatus;
        break;
      case "ml":
        status = mlStatus;
        break;
    }
    return status;
  };

  useEffect(() => {
    dispatch(TriggerHealthCheck());
  }, []);

  const refreshHandler = () => {
    dispatch(TriggerHealthCheck());
  };

  return (
    <div className="system-health">
      <div className="system-health__header">
        <h2 className="system-health__header__heading">Services</h2>
        <div className="system-health__header__refresh" onClick={refreshHandler}>
          <TableSpinnerIcon
            className="system-health__spinner"
            customStyle={{ margin: "0", height: "20px" }}
            spin={loading}
          />
        </div>
      </div>
      {SERVICES.map((service) => (
        <div className="system-health__block border" key={service.id}>
          <div className="system-health__block--icon">
            {service.renderIcon("white", ICON_SIZE.width, ICON_SIZE.height)}
          </div>
          <p className="system-health__block--text" style={{ color: "white" }}>
            {service.header}
          </p>
          <div className="system-health__block--signal">
            <b className={`system-health__block--signal--status ${getStatus(service.id)}`}></b>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SystemHealth;
