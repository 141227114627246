import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RequestNewScript } from "../../../../../../store/actions/dashboardActions";
import Warning from "../../../../../common/Warning/Warning";
import "./styles.scss";
import FileCardButton from "../../../../../UI/FileCardButton/FileCardButton";
import ScriptSubmitIcon from "../../../../../Icon-SVG/ScriptSubmitIcon";

const AnalyzeScript = () => {
  const dispatch = useDispatch();
  //const [value, setValue] = useState("");
  const [text, setText] = useState("");
  const [active, setActive] = useState(false);
  const [extension, setExtension] = useState(".js");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  //For the enciption of the script if needed
  //const getBase64 = (newFile) => {
  //return new Promise((resolve, reject) => {
  //const reader = new FileReader();
  //reader.readAsDataURL(newFile);
  //reader.onload = () => resolve(reader.result);
  //reader.onerror = (error) => reject(error);
  //});
  //};

  const ConvertString = () => {
    const blob = new Blob([document.getElementById("myInput").value], {
      type: "text/plain",
    });

    let file_type = extension;
    let types = {
      ".js": "application/x-javascript",
      ".ppt": "application/vnd.ms-powerpoint",
      ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ".doc": "application/msword",
      ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ".docm": "application/vnd.ms-word.document.macroEnabled.12",
      ".xls": "application/vnd.ms-excel",
      ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ".xlsm": "application/vnd.ms-excel.sheet.macroEnabled.12",
      ".swf": "application/x-shockwave-flash",
      ".vbs": "application/vnd.ms-vbscript",
      ".cmd": "text/plain",
      ".bat": "text/plain",
      ".exe": "application/octet-stream",
      ".csv": "text/csv",
      ".rtf": "application/rtf",
      ".xml": "application/xml",
      ".msi": "application/octet-stream",
    };
    //Create a new file
    let newFile = new File([blob], "testScript" + file_type, { type: types[file_type] });

    const data = {
      file: newFile,
      fileType: file_type.split(".").pop(),
      type: [["full_triage"], ["high_evasion"]],
      command_line: ["", ""],
      enviromental_variable: ["", ""],
      related_password: ["", ""],
      runtime_duration: [120, 120],
      enforse_runtime: [false, false],
      enforse_submission: [false, false],
      memory_dump: ["advanced", "advanced"],
      network_traffic: [["vpn"], ["vpn"]],
      win7: true,
      win10: false,
      action_script: ["default", "default"],
      machine: ["win7", "win10"],
    };
    if (data.file.size === 0) {
      setError("Please set a script to submit");
      setShow(true);
    } else {
      dispatch(RequestNewScript(data));
      setText("");
    }
  };
  //FileNAme value, if needed for the user change
  //const changeValue = (e) => setValue(e.target.value);
  //File script text
  const changeText = (e) => {
    setText(e.target.text);
  };
  return (
    <div className="analyze-script">
      <textarea
        id="myInput"
        className="analyze-script__input"
        type="textarea"
        value={text}
        onChange={changeText}
        placeholder="Write your script here."
      />
      <div className="analyze-script__second-row">
        <section className="analyze-script__buttons">
          <div className="submit-popup-container__buttons__container">
            <div className="analyze-script__buttons__container--submit" onClick={ConvertString}>
              <FileCardButton setActive={setActive}>
                <ScriptSubmitIcon />
                <p>Submit</p>
              </FileCardButton>
            </div>

            <Warning
              onClickOk={() => setShow(false)}
              show={show}
              setShow={setShow}
              text={error}
              article="Warning"
              cancelDisable={true}
              textOK="Okay"
            />
          </div>
        </section>

        <div className="analyze-script__second-column">
          <div className="analyze-script__script">
            <p className="analyze-script__script__label">Name: </p>
            <p className="analyze-script__script__value">testScript</p>
          </div>

          <div className="analyze-script__submitting-block">
            <p>Type: </p>
            <select
              id="standard-select"
              defaultValue={extension}
              onChange={(e) => setExtension(e.target.value)}>
              <option value=".js">JScript </option>
              <option value=".ps1">PS1 </option>
              <option value=".cmd">CMD </option>
              <option value=".bat">MS-DOS </option>
              <option value=".swf">SWF </option>
              <option value=".vbs">VBS </option>
              <option value=".ppt">PPT </option>
              <option value=".pptx">PPTx </option>
              <option value=".doc">DOC </option>
              <option value=".docm">DOCM </option>
              <option value=".docx">DOCx </option>
              <option value=".xls">XLS </option>
              <option value=".xlsm">XLSM </option>
              <option value=".xlsx">XLSx </option>
              <option value=".csv">CSV </option>
              <option value=".exe">Exe </option>
              <option value=".rtf">Rtf </option>
              <option value=".msi">MSI </option>
              <option value=".xml">XML </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyzeScript;
