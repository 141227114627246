import { useSelector } from "react-redux";

import LoadingIcon from "../../../../Icon-SVG/LoadingIcon";

const SeverityStatusStaticPopup = (props) => {
  const fileHash = props?.row?.original.file.hash;
  const preStaticResult = useSelector((state) => state.analysisReports.preStaticResults[fileHash]);

  return (
    <div
      className={`severity-status severity-status__${
        !preStaticResult
          ? "none"
          : preStaticResult?.error
          ? "error"
          : !preStaticResult?.data ||
            !preStaticResult?.data?.actual_decision ||
            preStaticResult?.data?.actual_decision === "n/a"
          ? "none"
          : preStaticResult?.data?.actual_decision === "Not Suspicious"
          ? "success"
          : preStaticResult?.data?.actual_decision === "Malicious"
          ? "secondary"
          : "loading"
      }`}>
      {!preStaticResult ? (
        "n/a"
      ) : preStaticResult?.loading ? (
        <LoadingIcon color={"var(--text-color-card)"} />
      ) : !preStaticResult.data || !preStaticResult.data.actual_decision ? (
        "n/a"
      ) : (
        preStaticResult.data.actual_decision
      )}
    </div>
  );
};

export default SeverityStatusStaticPopup;
