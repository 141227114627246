import * as React from "react"

const Otf = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.6 588.6"
    xmlSpace="preserve">
    <path  d="M359.036 537.775c.771.053 1.551.116 2.342.116h178.2c20.84 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.96-37.8-37.8-37.8h-178.2c-.786 0-1.571.071-2.342.113V0L11.222 46.417v494.564L359.036 588.6v-50.825zm2.342-466.977h178.2c8.933 0 16.199 7.27 16.199 16.2v413.103c0 8.934-7.267 16.2-16.199 16.2h-178.2c-.797 0-1.571-.127-2.342-.242v-149.64c6.703-2.057 12.862-5.221 18.437-9.556 10.642-8.247 21.905-22.707 33.812-43.368h61.637l4.63 55.751 51.911-8.596 1.461-7.468c-7.535.454-11.949.686-13.226.686-7.167 0-12.113-2.885-14.871-8.659-2.764-5.769-4.889-17.75-6.396-35.922l-6.561-78.195-1.471-37.687-23.062 4.185c-7.467.602-13.035 1.016-16.743 1.242-18.467 1.281-32.563 4.116-42.293 8.49-9.713 4.379-18.156 11.738-25.317 22.074-6.486 9.284-10.446 21.022-11.876 35.208l22.159-10.989c0-14.634 4.282-25.893 12.846-33.782 8.554-7.889 20.715-11.825 36.472-11.825 5.874 0 12.925.49 21.136 1.469l-48.731 84.232c-13.569 23.514-25.233 39.898-34.989 49.138-6.064 5.728-12.388 9.677-18.958 11.845V71.031c.764-.114 1.533-.233 2.335-.233zm54.769 234.328 48.748-85.013 7.235 85.013h-55.983zM85.862 361.715c-30.499-.812-47.946-25.744-47.946-56.842 0-32.725 19.256-57.699 49.652-58.493 32.545-.859 50.733 24.959 50.733 56.516 0 37.494-21.524 59.647-52.439 58.819zm143.488-94.528-31.892.509v95.096l-25.871-.675v-94.018l-29.858.469v-21.745l87.626-2.241v22.605h-.005zm41.894 97.519-27.551-.712V244.215l75.708-1.927v22.934l-48.157.765v27.686l44.94-.185v22.718l-44.94-.237v48.737z" fill={color} />
    <path  d="M86.945 266.44c-15.831.277-24.94 16.488-24.94 37.953 0 21.621 9.416 37.02 25.099 37.294 16.065.29 25.373-15.989 25.373-37.953.005-20.281-9.152-37.563-25.532-37.294z" fill={color} />
  </svg>
)

export default Otf
