import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { VectorMap } from "@react-jvectormap/core";
import worldMill from "./worldMill.json";
import "./styles.scss";

const Map = ({ report = false, reportPdf = false }) => {
  const networkActivityMap = useSelector((state) => state.analysisReports.networkActivityMap);
  const [country, setCountry] = useState({});

  useEffect(() => {
    if (networkActivityMap) {
      const countryValues = {};
      networkActivityMap.forEach((na) => {
        const countryCode = na["country_code"];
        countryValues[countryCode] = 1;
      });
      setCountry(countryValues);
    }
  }, [networkActivityMap]);

  return (
    <div className="map border">
      <div className={report || reportPdf ? "map-report-body" : "map-body"}>
        {Object.keys(country).length === 0 && (
          <p
            className={
              report || reportPdf
                ? "map-report-body__no-region-message"
                : "map-body__no-region-message"
            }>
            No Region Detected
          </p>
        )}
        {report || reportPdf ? (
          <VectorMap
            backgroundColor={report ? "var(--matrix-table-color)" : "none"}
            map={worldMill}
            mapRef={"map"}
            zoomMin={1}
            zoomMax={6}
            zoomStep={0}
            focusOn={{
              scale: 2,
              lng: 7.0926,
              lat: 31.7917,
            }}
            zoomOnScroll={true}
            zoomButtons={false}
            panOnDrag={true}
            zoomAnimate={false}
            regionsSelectable={false}
            markersSelectable={false}
            series={{
              regions: [
                {
                  values: country,
                  scale: report ? ["#a6b0cf"] : ["#ec374a"],
                  attribute: "fill",
                },
              ],
            }}
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
            regionStyle={{
              initial: {
                fill: report ? "var(--map-region-color)" : "var(--text-color-card)",
              },
              selected: {
                fill: "var(--header-search-text-color)",
              },
            }}
          />
        ) : (
          <VectorMap
            backgroundColor="var(--matrix-table-color)"
            map={worldMill}
            mapRef={"map"}
            zoomMin={1}
            zoomMax={6}
            zoomStep={0}
            focusOn={{
              scale: 2,
              lng: 7.0926,
              lat: 31.7917,
            }}
            zoomOnScroll={true}
            zoomButtons={false}
            panOnDrag={true}
            zoomAnimate={false}
            regionsSelectable={false}
            markersSelectable={false}
            series={{
              regions: [
                {
                  values: country,
                  scale: ["#a6b0cf"],
                  attribute: "fill",
                },
              ],
            }}
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
            regionStyle={{
              initial: {
                fill: "var(--map-region-color)",
              },
              selected: {
                fill: "var(--header-search-text-color)",
              },
            }}
          />
        )}
      </div>
      <div className="map-footer">
        <div className={report || reportPdf ? "map-footer-report__header" : "map-footer__header"}>
          <p
            className={
              report || reportPdf ? "map-footer-report__header__item" : "map-footer__header-item"
            }>
            Destination IP
          </p>
          <p
            className={
              report || reportPdf ? "map-footer-report__header__item" : "map-footer__header-item"
            }>
            Destination Port
          </p>
          <p
            className={
              report || reportPdf ? "map-footer-report__header__item" : "map-footer__header-item"
            }>
            Sni/Hostname
          </p>
          <p
            className={
              report || reportPdf ? "map-footer-report__header__item" : "map-footer__header-item"
            }>
            Uri
          </p>
          <p
            className={
              report || reportPdf ? "map-footer-report__header__item" : "map-footer__header-item"
            }>
            Country Name
          </p>
        </div>
        <div className={report || reportPdf ? "map-report__scrollable" : "map-scrollable"}>
          {networkActivityMap?.map((na, i) => (
            <div
              key={i + 1}
              className={report || reportPdf ? "map-footer-report__body" : "map-footer__body"}>
              <p
                className={
                  report || reportPdf ? "map-footer-report__body__item" : "map-footer__body-item"
                }>
                {na.ip}
              </p>
              <p
                className={
                  report || reportPdf ? "map-footer-report__body__item" : "map-footer__body-item"
                }>
                {na.port}
              </p>
              <p
                className={
                  report || reportPdf ? "map-footer-report__body__item" : "map-footer__body-item"
                }>
                {na?.sni || na?.hostname}
              </p>
              <p
                className={
                  report || reportPdf ? "map-footer-report__body__item" : "map-footer__body-item"
                }>
                {na.uri || "-"}
              </p>
              <p
                className={
                  report || reportPdf ? "map-footer-report__body__item" : "map-footer__body-item"
                }>
                {na.country_name || "-"}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Map;
