import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { JsonView } from "react-json-view-lite";

import CustomTable from "../../../../../Components/common/Table/CustomTable";
import { overview, overviewReport } from "../../../../../utils/constants/tableHeaders";
import { RequestP2signatureList } from "../../../../../store/actions/analysisReportActions";

import "./styles.scss";
import Backdrop from "../../../../../Components/UI/Backdrop/Backdrop";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const shouldInitiallyExpandHandler = (level) => (level <= 1 ? true : false);

const Overview = ({ report = false }) => {
  const dispatch = useDispatch();

  const overviewRef = useRef(null);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  const signatureList = useSelector((state) => state.analysisReports.signatureList);
  const p2signatureList = useSelector((state) => state.analysisReports.p2signatureList);
  const isSignaturesLoading = useSelector((state) => state.analysisReports.signaturesLoading);
  const isP2SignaturesLoading = useSelector((state) => state.analysisReports.p2signaturesloading);

  useEffect(() => {
    dispatch(RequestP2signatureList(query.get("data")));
  }, [query.get("data")]);

  const getSignatureFields = (signatureList) => {
    let newSignatureList = [];

    if (signatureList && Array.isArray(signatureList)) {
      newSignatureList = [
        ...signatureList.map((signature) => {
          let ttpValues = [];
          if (Array.isArray(signature.ttp)) ttpValues = signature.ttp;
          else if (signature.ttp !== null && typeof signature.ttp === "object")
            ttpValues = Object.keys(signature.ttp);
          return {
            ...signature,
            ttp: ttpValues,
          };
        }),
      ];
    }

    return newSignatureList;
  };

  const getUniqueP2Signatures = (signatures_, p2signatures_) => {
    const newP2SignatureList = p2signatures_.filter(
      (p2Signature) =>
        !signatures_.some(
          (signature) => signature.name && p2Signature.same && signature.name === p2Signature.same,
        ),
    );

    return newP2SignatureList;
  };

  const Details = React.useCallback(({ row }) => {
    const details = row?.original?.data;
    const information = row?.original?.information;

    const styleProps = {
      container: "signature-matches__json-view__container",
      basicChildStyle: "signature-matches__json-view__basic-child-style",
      expander: "signature-matches__json-view__expander",
      label: "signature-matches__json-view__label",
      nullValue: "signature-matches__json-view__null-value",
      undefinedValue: "signature-matches__json-view__undefined-value",
      numberValue: "signature-matches__json-view__number-value",
      stringValue: "signature-matches__json-view__string-value",
      booleanValue: "signature-matches__json-view__boolean-value",
      otherValue: "signature-matches__json-view__other-value",
      punctuation: "signature-matches__json-view__punctuation",
      pointer: "signature-matches__json-view__pointer",
    };
    return (
      <div
        style={{
          padding: "10px",
          width: "100%",
          display: "flex",
          gap: "10px",
          position: "relative",
        }}>
        <div style={{ flex: 1 }}>
          {details && details.length > 0 ? (
            details.map((detail, i) => (
              <JsonView
                key={i}
                data={detail}
                style={styleProps}
                shouldInitiallyExpand={shouldInitiallyExpandHandler}
              />
            ))
          ) : (
            <p>No Details Found</p>
          )}
        </div>
        <div style={{ width: "2px", backgroundColor: "#6A7187", margin: 0 }}></div>
        <div className="signature-matches__details__information">
          {information || "No Information Found"}
        </div>
      </div>
    );
  }, []);

  return (
    <div
      className={
        report
          ? "overview__table-block overview__table-block-report"
          : "overview__table-block overview__table-block-main"
      }>
      <Backdrop show={isSignaturesLoading || isP2SignaturesLoading} absolute={true} />
      {!isSignaturesLoading && !isP2SignaturesLoading && (
        <CustomTable
          dataOverview={[
            ...getSignatureFields(signatureList),
            ...getUniqueP2Signatures(
              getSignatureFields(signatureList),
              getSignatureFields(p2signatureList),
            ),
          ].sort((a, b) => b.severity - a.severity)}
          tableName={"overview"}
          column={report ? overviewReport : overview}
          refresh={!report}
          exportFile={!report}
          paginationButtons={!report}
          customPageSize={report ? 100 : 10}
          tableRef={overviewRef}
          leftAction
          renderRowSubComponent={!report && Details}
          minHeight={report ? "auto" : "585px"}
          tableFolder={"analysisReports"}
          minBottom="0px"
          paginationBorder={!report}
          paginationHeight="38px"
          defaultPosition="relative"
          reportPage={report}
          tableBorder={!report}
        />
      )}
    </div>
  );
};

export default Overview;
