import * as React from "react"

const Cur = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.599 588.6"
    xmlSpace="preserve">
    <path  d="M539.573 49.204h-178.2c-.791 0-1.561.076-2.342.124V0L11.227 46.417V540.98l347.804 47.62v-50.814c.781.053 1.551.116 2.342.116h178.2c20.851 0 37.8-16.965 37.8-37.8V86.999c0-20.839-16.95-37.795-37.8-37.795zM90.734 337.469c6.518.168 13.819-1.234 18.162-3.027l3.341 18.926c-4.071 2.062-13.131 4.066-24.729 3.597-31.839-1.281-47.619-23.588-47.619-52.302 0-34.383 21.666-54.498 49.729-55.771 11.211-.509 19.862 1.635 23.783 3.684l-4.504 19.303c-4.482-1.922-10.645-3.633-18.301-3.401-16.82.496-29.586 11.981-29.586 34.789.003 20.544 10.848 33.737 29.724 34.202zm123.781-27.707c0 35.564-17.605 51.785-46.132 50.636-26.462-1.071-41.544-16.743-41.544-51.185v-60.23l23.227-1.021v63.261c0 18.921 6.834 28.714 19.098 29.024 12.849.333 20.008-8.886 20.008-28.872v-65.137l25.344-1.113v64.637zm87.595 54.031c-2.115-3.533-5.171-13.61-8.965-28.719-3.409-15.209-8.862-19.391-20.387-19.639l-8.44-.053v46.929l-26.99-1.05v-115.58c8.641-1.77 21.724-3.401 36.537-4.066 18.71-.831 32.094 1.308 41.407 7.878 7.857 5.601 12.214 14.144 12.214 25.608 0 15.902-12.014 26.844-23.267 30.705v.549c9.102 3.485 14.186 11.76 17.539 23.203 4.146 14.111 8.312 30.512 10.916 35.432l-30.564-1.197zm253.663 136.309c0 8.933-7.268 16.199-16.2 16.199h-178.2c-.802 0-1.571-.11-2.342-.231V71.046c.771-.114 1.54-.243 2.342-.243h178.2c8.933 0 16.2 7.27 16.2 16.2v413.099z" fill={color} />
    <path  d="M277.003 258.979c-6.837.203-10.676.852-12.691 1.263v32.951l11.021-.076c14.207-.108 22.747-7.059 22.747-17.75.011-11.239-7.978-16.61-21.077-16.388zM516.812 265.014l-146.327-44.798 44.804 146.325 16.963-67.658 86.311 86.315 16.907-16.901-86.321-86.311z" fill={color} />
  </svg>
)

export default Cur
