import * as React from "react"

const Ps = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.4 535.325c.767.052 1.543.114 2.33.114h177.39c20.745 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.882-37.628-37.627-37.628H359.73c-.782 0-1.563.07-2.33.113V0L11.17 46.206v492.311l346.23 47.401v-50.593zm2.33-464.849h177.39c8.892 0 16.125 7.236 16.125 16.126v411.22c0 8.893-7.233 16.127-16.125 16.127H359.73c-.792 0-1.563-.127-2.33-.243V300.634h4.797c1.896 38.189 32.62 69.654 70.795 72.473v19.854h11.192v-19.854c38.178-2.818 68.903-34.283 70.792-72.473h19.677V289.45H514.61c-3.602-36.759-33.606-66.038-70.426-68.747v-19.864h-11.192v19.864c-36.825 2.708-66.83 31.987-70.432 68.747h-5.165V70.707c.772-.113 1.538-.231 2.335-.231zm14.091 218.974c3.516-30.564 28.514-54.867 59.171-57.523v19.276h11.192v-19.276c30.656 2.656 55.647 26.964 59.171 57.523h-19.066v11.184h19.477c-1.859 32.063-27.571 58.478-59.581 61.251v-19.277h-11.192v19.277c-32.011-2.773-57.717-29.188-59.581-61.251h19.476V289.45h-19.067zm-207.843 17.676c-8.525 8.295-21.008 11.943-35.437 11.823-3.181-.021-6.037-.21-8.239-.562v40.263l-23.615-.608V248.14c7.294-1.501 17.615-2.775 32.326-3.15 15.079-.389 25.954 2.38 33.313 8.331 7.094 5.654 11.884 15.142 11.884 26.447.001 11.291-3.641 20.886-10.232 27.358zm54.581 55.818c-13.208-.358-26.098-4.147-32.494-7.927l5.218-21.616c6.929 3.731 17.659 7.547 28.83 7.736 12.158.21 18.625-4.786 18.625-12.535 0-7.391-5.596-11.66-19.652-16.81-19.181-6.833-31.493-17.532-31.493-34.401 0-19.809 16.231-35.391 43.63-36.113 13.331-.349 23.266 2.226 30.405 5.247l-6.071 21.966c-4.811-2.226-13.302-5.452-24.848-5.26-11.467.188-16.966 5.507-16.966 11.607 0 7.488 6.553 10.75 21.68 16.452 21.016 7.71 31.053 18.739 31.053 35.679 0 20.147-15.552 36.83-47.917 35.975z" fill={color} />
    <path  d="M132.284 264.255c-4.924.084-8.252.644-9.981 1.176v33.25c2.048.499 4.59.662 8.084.651 12.977-.01 21.05-6.962 21.05-18.646.001-10.496-6.95-16.641-19.153-16.431zM438.588 328.146c17.229 0 31.249-14.011 31.249-31.249 0-17.231-14.011-31.245-31.249-31.245-17.229 0-31.245 14.013-31.245 31.245 0 17.238 14.016 31.249 31.245 31.249zm0-51.292c11.061 0 20.054 8.992 20.054 20.043 0 11.065-8.998 20.052-20.054 20.052-11.065 0-20.048-8.991-20.048-20.052-.005-11.051 8.982-20.043 20.048-20.043z" fill={color} />
  </svg>
)

export default Ps
