import React from "react";

const IntegrationsIcon = ({ color = "var(--text-color-card)", width = 16, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.55556 2.66667H12.4444V8H3.55556V2.66667ZM14.6667 5.33333C14.6667 6.06972 15.2636 6.66667 16 6.66667V9.33333C16 10.0697 15.4031 10.6667 14.6667 10.6667H1.33333C0.596944 10.6667 0 10.0697 0 9.33333V6.66667C0.736389 6.66667 1.33333 6.06972 1.33333 5.33333C1.33333 4.59694 0.736389 4 0 4V1.33333C0 0.596944 0.596944 0 1.33333 0H14.6667C15.4031 0 16 0.596944 16 1.33333V4C15.2636 4 14.6667 4.59694 14.6667 5.33333ZM13.3333 2.44444C13.3333 2.07625 13.0349 1.77778 12.6667 1.77778H3.33333C2.96514 1.77778 2.66667 2.07625 2.66667 2.44444V8.22222C2.66667 8.59042 2.96514 8.88889 3.33333 8.88889H12.6667C13.0349 8.88889 13.3333 8.59042 13.3333 8.22222V2.44444Z"
        fill={color || "var(--text-color-card)"}
      />
    </svg>
  );
};

export default IntegrationsIcon;
