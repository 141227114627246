import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  RequestDistinctHosts,
  SuccessDistinctHosts,
  FailureDistinctHosts,
  RequestNetworkDetections,
  SuccessNetworkDetections,
  FailureNetworkDetections,
} from "../actions/networkDefenderActions";

import axiosApi from "../../utils/axios";

export function* distinctHosts() {
  try {
    const response = yield call(axiosApi.get, "/api/nx/network-detections/hosts");
    const mappedData = response?.data?.map((item) => ({ id: item, value: item }));
    yield put(SuccessDistinctHosts(mappedData));
  } catch (e) {
    yield put(FailureDistinctHosts());
  }
}

export function* networkDetections({ payload }) {
  const { host, timeFilter } = payload;
  try {
    const response = yield call(
      axiosApi.get,
      timeFilter
        ? `/api/nx/network-detections/${host}?filterByLast=${timeFilter.id}`
        : `/api/nx/network-detections/${host}`,
    );
    yield put(SuccessNetworkDetections(response?.data?.network_detections));
  } catch (e) {
    yield put(FailureNetworkDetections());
  }
}

const networkDefenderSagas = [
  takeLatest(RequestDistinctHosts, distinctHosts),
  takeEvery(RequestNetworkDetections, networkDetections),
];

export default networkDefenderSagas;
