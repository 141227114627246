import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import { RequestMitreAttack } from "../../../../../store/actions/analysisReportActions";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
/*report: this variable belongs to the pdf page */
const Matrix = ({ report = false }) => {
  const dispatch = useDispatch();
  const mitre = useSelector((state) => state.analysisReports.mitre);
  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  useEffect(() => {
    dispatch(RequestMitreAttack(query.get("data")));
  }, [dispatch, query.get("data")]);

  return (
    <>
      {report ? (
        <div className="matrix__block-columns--report">
          {Object.keys(mitre).map((key) =>
            key === "mitre_attck"
              ? Object.keys(mitre[key]).map((keyo, i) => (
                  <div key={i} className="matrix__block-column--report">
                    <p className="matrix__block-column--report__header">{keyo}</p>
                    {Object.keys(mitre[key][keyo]).map((keyx, x) => (
                      <div key={x} className="matrix-card">
                        <p>{mitre[key][keyo][keyx].ttp_name}</p>
                      </div>
                    ))}
                  </div>
                ))
              : null,
          )}
        </div>
      ) : (
        <div className="matrix border">
          <div className="matrix__block-columns">
            <div className="matrix__block-columns">
              {mitre?.mitre_attck ? (
                Object.keys(mitre.mitre_attck).length > 0 ? (
                  Object.keys(mitre.mitre_attck).map((header, i) =>
                    header !== "success" ? (
                      <div key={i} className="matrix__block-column">
                        <p className="matrix__block-column__header">{header}</p>
                        {mitre.mitre_attck[header].map((data, j) => (
                          <div key={j} className="matrix-card">
                            <p>{data.ttp_name}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="matrix__no-data">No data</p>
                    ),
                  )
                ) : (
                  <p className="matrix__no-data">No data</p>
                )
              ) : (
                <p className="matrix__no-data">No data</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Matrix;
