const UrlIcon = ({ width = 24, height = 24, color = "white" }) => {
  return (
    <svg
      fill={color}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 95"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve">
      <path d="M51.421,49.198c-0.965,0-1.513,0.078-1.8,0.13v4.853h1.566c1.982,0,3.156-0.991,3.156-2.531  C54.344,50.033,53.248,49.224,51.421,49.198z" />
      <path d="M84.679,28.109L64.39,7.821C62.209,5.64,60.663,5,57.58,5H18.484C14.188,5,12.5,6.688,12.5,10.984v78.032  c0,4.296,1.688,5.984,5.984,5.984h63.031c4.297,0,5.984-1.688,5.984-5.984V34.919C87.5,31.835,86.859,30.291,84.679,28.109z   M82.266,88.171c0,1.146-0.45,1.595-1.596,1.595H19.33c-1.146,0-1.596-0.45-1.596-1.595V68.813h64.531V88.171z M35.043,61.067  c2.086,0,3.234-1.46,3.234-4.565V46.38h3.965v9.861c0,5.426-2.739,8.009-7.33,8.009c-4.436,0-7.044-2.452-7.044-8.061v-9.81h3.991  v10.122C31.859,59.528,33.007,61.067,35.043,61.067z M57.526,59.007c0.547,1.982,1.095,4.278,1.435,4.957h-4.07  c-0.287-0.496-0.703-1.93-1.226-4.096c-0.47-2.191-1.226-2.792-2.843-2.817h-1.201v6.913h-3.938V46.615  c1.277-0.209,3.182-0.365,5.294-0.365c2.608,0,4.436,0.391,5.688,1.382c1.044,0.834,1.617,2.062,1.617,3.678  c0,2.244-1.591,3.783-3.104,4.331v0.079C56.404,56.216,57.082,57.39,57.526,59.007z M72.132,60.624v3.339H61.148V46.38h3.992v14.244  H72.132z M82.266,41.687H17.734V11.829c0-1.146,0.449-1.595,1.596-1.595h39.846c0.572,0,0.798,0.225,0.798,0.797v15.511  c0,4.296,1.688,5.984,5.983,5.984h15.511c0.573,0,0.798,0.226,0.798,0.798V41.687z" />
      <text
        x="0"
        y="115"
        fill={color}
        fontSize="5px"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text>
      <text
        x="0"
        y="120"
        fill={color}
        fontSize="5px"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text>
    </svg>
  );
};

export default UrlIcon;
