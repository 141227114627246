import "./staticInfoCardItem.scss";

const renderSubItem = (subItem, i) => {
  if (Object.keys(subItem).includes("matches") || Object.keys(subItem).includes("description"))
    return (
      <div className="static-info-card-item__yara" key={i}>
        {subItem.description && (
          <p className="static-info-card-item__yara__description">
            <span className="static-info-card-item__yara__header">Description: </span>
            {subItem.description}
          </p>
        )}
        {subItem.matches?.length && (
          <div className="static-info-card-item__yara__matches">
            <span className="static-info-card-item__yara__header">Matches: </span>
            {subItem.matches?.map((match, i) => (
              <p key={i}>&bull; {match}</p>
            ))}
          </div>
        )}
      </div>
    );
  else if (Object.keys(subItem).includes("title") && Object.keys(subItem).includes("href"))
    return (
      <div className="static-info-card-item__prev-matches" key={i}>
        {subItem.title && (
          <p className="static-info-card-item__prev-matches__title">
            <span className="static-info-card-item__prev-matches__header">Title: </span>
            {subItem.title}
          </p>
        )}
        {subItem.href && (
          <a href={subItem.href} className="static-info-card-item__prev-matches__href">
            {subItem.href}
          </a>
        )}
      </div>
    );
  else if (
    Object.keys(subItem).includes("threatCategories") &&
    Object.keys(subItem).includes("familyLabels")
  )
    return (
      <div className="static-info-card-item__virustotal" key={i}>
        {subItem.threatCategories?.length && (
          <div className="static-info-card-item__virustotal__categories">
            <span className="static-info-card-item__virustotal__header">Threat Categories: </span>
            {subItem.threatCategories?.map((threatCategory, i) => (
              <p key={i}>&bull; {threatCategory}</p>
            ))}
          </div>
        )}
        {subItem.familyLabels?.length && (
          <div className="static-info-card-item__virustotal__labels">
            <span className="static-info-card-item__virustotal__header">Family Labels: </span>
            {subItem.familyLabels?.map((familyLabel, i) => (
              <p key={i}>&bull; {familyLabel}</p>
            ))}
          </div>
        )}
      </div>
    );
  else
    return (
      <p className="static-info-card-item__subitem" key={i}>
        {subItem}
      </p>
    );
};

const StaticInfoCardItem = ({ staticInfoCardData, onCollapse }) => {
  const { title, items } = staticInfoCardData;

  const collapseHandler = (subTitle) => {
    onCollapse(title, subTitle);
  };

  return (
    <div className="static-info-card-item">
      {title && <h6 className="static-info-card-item__title">{title}</h6>}
      {items?.map((item, i) =>
        !item?.subTitle ? (
          <p className="static-info-card-item__item" key={i}>
            {item}
          </p>
        ) : (
          <div key={i}>
            <p
              className={`static-info-card-item__${
                item?.subItems.length > 0 ? "subtitle" : "item"
              }`}>
              {item?.subTitle}
              {item?.subItems.length > 0 &&
                (item?.collapsed ? (
                  <span
                    className="static-info-card-item__collapse"
                    onClick={() => collapseHandler(item.subTitle)}>
                    +
                  </span>
                ) : (
                  <span
                    className="static-info-card-item__collapse"
                    onClick={() => collapseHandler(item.subTitle)}>
                    -
                  </span>
                ))}
            </p>

            {!item?.collapsed && item?.subItems && item?.subItems?.map(renderSubItem)}
          </div>
        ),
      )}
    </div>
  );
};

export default StaticInfoCardItem;
