import React from "react";

import "./styles.scss";

const Input = ({ onChange, value, placeholder, type="" }) => {
  return (
    <input className="input-field" onChange={onChange} value={value} placeholder={placeholder} type={type} />
  );
};

export default Input;
