import * as React from "react"

const Tar = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M163.655 238.683h-.338c-1.64 6.832-3.283 15.536-5.092 22.178l-6.52 24.287 24.528.157-6.971-24.497c-1.985-6.81-3.956-15.33-5.607-22.125zM271.341 236.039c-6.677.105-10.446.693-12.422 1.079v33.053l10.793-.01c13.803 0 22.061-6.854 22.061-17.491-.001-11.176-7.71-16.641-20.432-16.631z" fill={color} />
    <path  d="M357.4 535.325c.767.052 1.543.114 2.33.114h177.39c20.745 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.882-37.628-37.627-37.628H359.73c-.782 0-1.563.07-2.33.118V0L11.17 46.206v492.311l346.23 47.401v-50.593zm2.33-464.849h177.39c8.892 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.233 16.127-16.125 16.127H359.73c-.792 0-1.563-.121-2.33-.243V368.319h96.842v-19.528h78.215v-26.52h-43.706v-10.51h17.261v-7.778h-17.261v-9.045h17.261v-7.786h-17.261v-8.842h17.261v-7.767h-17.261v-6.826h43.706v-26.539h-78.215v26.539h26.468v58.554h-26.468v25.345H357.4v-9.46h76.726v-90.309H357.4v-10.659h96.842v-20.716H357.4V70.711c.767-.117 1.533-.235 2.33-.235zM92.715 332.16l-23.36-.66v-89.162l-26.99.365v-20.641l79.13-1.84v21.408l-28.78.386v90.144zm95.909 2.7-8.714-29.954-31.791-.473-7.756 29.072-25.179-.715 32.935-113.185 32.94-.764 35.5 116.805-27.935-.786zm107.012 3.024c-2.026-3.496-4.976-13.486-8.638-28.499-3.294-15.145-8.578-19.329-19.825-19.591l-8.249-.077v47.121l-26.712-.761V219.23c8.601-1.608 21.533-2.973 36.053-3.307 18.184-.44 31.066 1.974 39.991 8.67 7.485 5.672 11.632 14.187 11.632 25.486 0 15.685-11.444 26.425-22.216 30.205v.535c8.726 3.483 13.571 11.688 16.763 23.006 3.947 13.911 7.91 30.059 10.356 34.872l-29.155-.813z" fill={color} />
  </svg>
)

export default Tar
