import * as React from "react"

const Mpa = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.913 585.913"
    xmlSpace="preserve">
    <path  d="m276.234 268.564-.349.01c-1.758 6.752-3.509 15.331-5.433 21.875l-6.963 23.865 26.255.135-7.494-24.136c-2.133-6.709-4.246-15.073-6.016-21.749z" fill={color} />
    <path  d="M11.173 46.2v492.311l346.214 47.402V535.33c.782.058 1.543.109 2.335.109h177.39c20.75 0 37.627-16.883 37.627-37.627V86.597c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.787 0-1.553.077-2.335.124V0L11.173 46.2zm102.516 310.119-1.394-38.483c-.407-12.068-.826-26.656-.826-41.258l-.416.011c-2.897 12.842-6.756 27.099-10.31 38.785L89.67 353.79l-15.748-.573-9.339-37.807c-2.822-11.322-5.76-25.034-7.797-37.562l-.265.011c-.499 12.979-.883 27.803-1.515 39.766l-1.517 36.396-17.583-.672 5.307-94.719 25.693-1.005 8.585 32.237c2.756 11.286 5.52 23.486 7.51 34.993l.396.011c2.532-11.351 5.595-24.358 8.549-35.369l9.75-33.231 27.75-1.092 5.292 101.954-21.049-.809zm68.655-34.741c-3.077-.042-5.827-.221-7.966-.556v37.621l-22.625-.865V255.69c6.961-1.501 16.861-2.816 31.053-3.38 14.659-.588 25.297 1.869 32.544 7.391 6.981 5.263 11.732 14.194 11.732 24.903 0 10.706-3.606 19.792-10.115 25.919-8.387 7.817-20.61 11.203-34.623 11.055zm120.792 41.995L293.7 333.83l-34.004-.618-8.21 28.388-26.386-1.008 34.602-110.518 35.265-1.375 38.736 116.05-30.567-1.176zm54.252-126.132c17.695-19.449 43.092-31.774 71.387-31.774 53.302 0 96.673 43.357 96.673 96.668 0 53.302-43.371 96.673-96.673 96.673-28.306 0-53.691-12.325-71.387-31.771V237.441zm2.335-166.965h177.39c8.887 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.238 16.127-16.125 16.127h-177.39c-.797 0-1.563-.116-2.335-.232V389.542c19.459 15.964 44.31 25.592 71.387 25.592 62.194 0 112.8-50.605 112.8-112.799 0-62.202-50.605-112.795-112.8-112.795-27.108 0-51.917 9.729-71.387 25.723V70.717c.771-.118 1.532-.241 2.335-.241z" fill={color} />
    <path  d="M184.041 270.339c-4.779.123-7.987.682-9.668 1.196v31.067c1.976.462 4.438.613 7.818.603 12.596-.031 20.491-6.581 20.491-17.579 0-9.885-6.798-15.605-18.641-15.287zM396.622 360.677c7.988-1.984 13.549-8.021 13.585-14.194h.021l.21-65.054 46.925-8.59v52.3c-3.405-1.207-7.49-1.417-11.633-.384-9.186 2.301-15.191 9.843-13.428 16.908 1.764 7.035 10.615 10.871 19.801 8.599 8.357-2.109 13.712-7.853 13.712-15.033v-86.805l-64.048 10.386v77.016c-3.35-1.175-7.344-1.385-11.444-.367-9.076 2.268-15.023 9.754-13.297 16.725 1.023 6.455 10.531 10.77 19.596 8.493z" fill={color} />
  </svg>
)

export default Mpa
