import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import { RequestSetConfig } from "../../../../store/actions/integrationsActions";
import Input from "../../../common/SubmitPopup/Input/Input";
import EyeCloseIcon from "../../../Icon-SVG/EyeCloseIcon";
import EyeIcon from "../../../Icon-SVG/EyeIcon";

const PostfixemailSettings = ({ show, setShow, dataTmp = 0, setActiveInt }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getConfig = useSelector((state) => state.integrations.getConfig);

  const [server, setServer] = useState("");
  const [port, setPort] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [short, setShort] = useState("");
  const [cirtemail, setCirtEmail] = useState("");
  const [hiddenPw, setHiddenPw] = useState(true);

  const [serverError, setServerError] = useState(false);
  const [portError, setPortError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [shortError, setShortError] = useState(false);
  const [cirtError, setCirtError] = useState(false);

  const closePopupHandler = () => {
    history.push("/integration-market");
    setShow(false);
    setActiveInt("");
  };

  const addConfig = () => {
    if (isValidInput()) {
      const config = {
        server: server,
        port: port,
        username: username,
        password: password,
        company: company,
        short: short,
        cirtemail: cirtemail,
        tls: 1,
      };
      const configString = JSON.stringify(config);
      const data = { appname: "postfixemail", config: configString };

      dispatch(RequestSetConfig(data));
      closePopupHandler();
    } else {
      console.log("error");
    }
  };

  const isValidInput = () => {
    if (server && port && username && password && company && short && cirtemail) {
      return true;
    } else {
      if (!server) {
        setServerError(true);
      } else {
        setServerError(false);
      }
      if (!port) {
        setPortError(true);
      } else {
        setPortError(false);
      }
      if (!username) {
        setUsernameError(true);
      } else {
        setUsernameError(false);
      }
      if (!password) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
      if (!company) {
        setCompanyError(true);
      } else {
        setCompanyError(false);
      }
      if (!short) {
        setShortError(true);
      } else {
        setShortError(false);
      }
      if (!cirtemail) {
        setCirtError(true);
      } else {
        setCirtError(false);
      }
      return false;
    }
  };

  useEffect(() => {
    setServer(getConfig?.config?.server);
    setPort(getConfig?.config?.port);
    setUsername(getConfig?.config?.username);
    setPassword(getConfig?.config?.password);
    setCompany(getConfig?.config?.company);
    setShort(getConfig?.config?.short);
    setCirtEmail(getConfig?.config?.cirtemail);
  }, [getConfig]);

  return (
    <div className={`${show && "postfixemail-settings-backdrop"}`} onClick={closePopupHandler}>
      <div onClick={(e) => e.stopPropagation()}>
        {show ? (
          <div className="postfixemail-settings border">
            <div className="postfixemail-settings-buttons">
              <div className="postfixemail-settings-close-button__outer">
                <div
                  onClick={closePopupHandler}
                  className="postfixemail-settings-close-button__inner">
                  <label className="postfixemail-settings-close-button__close">Close</label>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-align-items-center">
                <div className="flex-column m-r-10">
                  <p className={serverError ? "label-error" : "label"}>Server</p>
                  <Input
                    onChange={(e) => setServer(e.target.value)}
                    value={server}
                    placeholder="Enter Server"
                  />
                  {serverError && <div className="error-message">Please enter a valid value.</div>}
                </div>
                <div className="flex-column m-l-10 relative">
                  <p className={portError ? "label-error" : "label"}>Port</p>
                  <Input
                    onChange={(e) => setPort(e.target.value)}
                    value={port}
                    placeholder="Enter Port"
                  />
                  {portError && <div className="error-message">Please enter a valid value.</div>}
                </div>
                <div className="flex-column m-l-10 relative">
                  <p className={usernameError ? "label-error" : "label"}>Username</p>
                  <Input
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    placeholder="Enter Username"
                  />
                  {usernameError && (
                    <div className="error-message">Please enter a valid value.</div>
                  )}
                </div>
              </div>
              <div className="flex flex-align-items-center">
                <div className="flex-column m-r-10">
                  <p className={passwordError ? "label-error" : "label"}>Password</p>
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Enter Password"
                    type={hiddenPw ? "password" : "text"}
                  />
                  {hiddenPw ? (
                    <span className="eye_pw" onClick={() => setHiddenPw(!hiddenPw)}>
                      <EyeIcon />
                    </span>
                  ) : (
                    !hiddenPw && (
                      <span className="eye_pw" onClick={() => setHiddenPw(!hiddenPw)}>
                        <EyeCloseIcon />
                      </span>
                    )
                  )}
                  {passwordError && (
                    <div className="error-message">Please enter a valid value.</div>
                  )}
                </div>
                <div className="flex-column m-l-10">
                  <p className={companyError ? "label-error" : "label"}>Company</p>
                  <Input
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                    placeholder="Enter Company"
                  />
                  {companyError && <div className="error-message">Please enter a valid value.</div>}
                </div>
                <div className="flex-column m-l-10">
                  <p className={shortError ? "label-error" : "label"}>Short</p>
                  <Input
                    onChange={(e) => setShort(e.target.value)}
                    value={short}
                    placeholder="Enter Short"
                  />
                  {shortError && <div className="error-message">Please enter a valid value.</div>}
                </div>
              </div>
              <div className="flex flex-align-items-center">
                <div className="flex-column m-r-10">
                  <p className={cirtError ? "label-error" : "label"}>Cirt Email</p>
                  <Input
                    onChange={(e) => setCirtEmail(e.target.value)}
                    value={cirtemail}
                    placeholder="Enter Cirt Email"
                  />
                  {cirtError && <div className="error-message">Please enter a valid value.</div>}
                </div>
              </div>
              <div>
                <button onClick={() => addConfig()} className="submit-button">
                  Update
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PostfixemailSettings;
