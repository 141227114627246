import * as React from "react"

const Css = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 585.918 585.918"
    xmlSpace="preserve">
    <path  d="M357.396 535.335c.776.042 1.542.115 2.329.115h177.39c20.75 0 37.627-16.888 37.627-37.628V86.602c0-20.743-16.877-37.628-37.627-37.628h-177.39c-.781 0-1.553.076-2.329.113V0L11.176 46.206v492.311l346.22 47.401v-50.583zm0-464.628c.766-.108 1.532-.231 2.329-.231h177.39c8.893 0 16.125 7.236 16.125 16.126v411.22c0 8.888-7.232 16.127-16.125 16.127h-177.39c-.792 0-1.563-.127-2.329-.243V70.707zM98.12 321.895c7.283.125 15.398-1.418 20.21-3.36l3.714 20.137c-4.504 2.246-14.583 4.503-27.549 4.157-36.029-.971-54.119-25.008-54.119-56.214 0-37.365 24.795-58.822 56.473-59.667 12.526-.327 22.132 2.124 26.492 4.371l-5.011 20.501c-4.966-2.11-11.809-4.008-20.368-3.875-18.922.329-33.397 12.568-33.397 37.219-.005 22.189 12.305 36.372 33.555 36.731zm67.491 22.834c-12.83-.348-25.354-4.085-31.556-7.791l5.058-21.323c6.729 3.686 17.152 7.443 28.01 7.633 11.809.199 18.098-4.725 18.098-12.367 0-7.287-5.438-11.486-19.108-16.568-18.627-6.729-30.601-17.28-30.601-33.913 0-19.527 15.769-34.874 42.404-35.58 12.945-.339 22.594 2.205 29.523 5.188l-5.903 21.636c-4.648-2.205-12.89-5.375-24.134-5.19-11.118.179-16.47 5.431-16.47 11.433 0 7.381 6.352 10.607 21.061 16.212 20.42 7.602 30.16 18.481 30.16 35.174.005 19.833-15.096 36.295-46.542 35.456zm94.324 2.509c-13.935-.369-27.517-4.274-34.247-8.147l5.489-22.185c7.293 3.833 18.614 7.759 30.391 7.959 12.822.221 19.654-4.913 19.654-12.867 0-7.602-5.913-11.984-20.757-17.285-20.199-7.022-33.197-17.993-33.197-35.313 0-20.323 17.115-36.326 46.019-37.09 14.066-.375 24.533 2.258 32.087 5.365l-6.414 22.56c-5.068-2.289-14.024-5.585-26.226-5.386-12.064.2-17.88 5.671-17.88 11.929 0 7.686 6.897 11.045 22.855 16.882 22.194 7.929 32.778 19.268 32.778 36.664 0 20.683-16.409 37.827-50.552 36.914z" fill={color} />
    <path  d="M377.637 309.599c0 6.342-.938 12.662-1.878 19.004-.945 6.352-1.891 12.902-1.891 19.243 0 23.687 14.551 32.16 34.038 32.16h4.924v-12.925h-4.221c-13.14 0-18.541-7.276-18.541-20.399 0-5.638.709-11.045 1.644-16.913.939-5.617 1.642-11.476 1.642-17.828.241-15.013-6.335-22.998-16.896-25.583v-.451c10.562-2.821 17.138-10.344 16.896-25.357 0-6.32-.702-11.958-1.642-17.824-.935-5.638-1.644-11.268-1.644-16.664 0-12.669 4.688-20.168 18.541-20.168h4.221v-12.922h-4.924c-19.949 0-34.038 9.155-34.038 30.754 0 6.572.945 12.911 1.891 19.486.939 6.551 1.878 13.11 1.878 19.693 0 8.21-2.346 17.363-19.002 17.363v11.958c16.656-.001 19.002 10.087 19.002 17.373zM513.32 347.846c0-6.341-.94-12.892-1.886-19.243-.935-6.342-1.88-12.662-1.88-19.004 0-7.285 2.357-17.374 19.015-17.374v-11.958c-16.662 0-19.015-9.153-19.015-17.363 0-6.586.945-13.142 1.88-19.693.945-6.575 1.886-12.914 1.886-19.486 0-21.599-14.322-30.754-34.264-30.754h-4.693v12.922h4.226c13.616.22 18.542 7.499 18.542 20.168 0 5.396-.95 11.026-1.644 16.664-.956 5.872-1.891 11.51-1.891 17.824 0 15.014 6.573 22.531 16.903 25.357v.451c-10.33 2.58-16.903 10.57-16.903 25.583 0 6.357.935 12.211 1.891 17.828.693 5.868 1.644 11.275 1.644 16.913 0 13.123-5.644 20.179-18.772 20.399h-3.995v12.925h4.93c19.242.001 34.026-8.473 34.026-32.159z" fill={color} />
  </svg>
)

export default Css
