import * as React from "react"

const Fiv = ({ width = "23", height = "23", color = "var(--border)" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 588.601 588.6"
    xmlSpace="preserve">
    <path  d="M359.031 537.78c.781.048 1.551.111 2.342.111h178.2c20.846 0 37.8-16.96 37.8-37.801V86.994c0-20.838-16.954-37.8-37.8-37.8h-178.2c-.786 0-1.561.077-2.342.113V0L11.228 46.417v494.564L359.031 588.6v-50.82zm0-466.738c.771-.114 1.54-.243 2.342-.243h178.2c8.933 0 16.2 7.27 16.2 16.2v413.103c0 8.934-7.268 16.2-16.2 16.2h-178.2c-.796 0-1.571-.127-2.342-.232V71.042zM121.345 257.618l-40.5.562v25.397l37.8-.077v20.82l-37.8-.28v44.719l-23.219-.653V238.08l63.719-1.484v21.022zm88.842 94.779-70.354-1.967V236.173l25.062-.588v93.604l45.291.833v22.375zm64.161 1.804-31.989-.896-37.676-118.642 29.014-.688 14.521 50.34c4.103 14.24 7.857 27.994 10.734 43.018l.541.011c3.066-14.438 6.863-28.725 11.032-42.533l15.715-52.049 30.182-.707-42.074 122.146z" fill={color} />
    <path  d="M413.433 384.318c13.547-5.675 29.226-18.341 38.411-34.003 3.565-6.139 9.028-18.742 14.961-32.717h41.286v-35.324h-26.188c4.029-8.976 7.673-16.58 10.441-20.914 10.046-15.67 34.9-15.272 34.9-15.272v-33.581s-20.926-4.802-47.092 14.829c-26.162 19.606-42.726 72.812-56.669 102.022-13.964 29.204-39.677 25.734-39.677 25.734v34.414c.006-.005 16.127.444 29.627-5.188z" fill={color} />
  </svg>
)

export default Fiv
