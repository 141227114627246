import React from "react";

const LineAltIcon = ({ color = "FFFFFF", width = 18, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.5"
        y="9.75"
        width="3"
        height="3"
        rx="1.5"
        transform="rotate(90 4.5 9.75)"
        stroke={color}
      />
      <rect
        x="12.75"
        y="9"
        width="3"
        height="3"
        rx="1.5"
        transform="rotate(-90 12.75 9)"
        stroke={color}
      />
      <path
        d="M13.5 8.25L12.375 9.375C11.9465 9.80349 11.7323 10.0177 11.5102 10.1477C10.8862 10.5129 10.1138 10.5129 9.4898 10.1477C9.26773 10.0177 9.05349 9.80349 8.625 9.375V9.375C8.19651 8.94651 7.98227 8.73227 7.7602 8.6023C7.13623 8.23714 6.36377 8.23714 5.7398 8.6023C5.51773 8.73227 5.30349 8.94651 4.875 9.375L3.75 10.5"
        stroke={color}
      />
    </svg>
  );
};

export default LineAltIcon;
