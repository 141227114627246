import { createSlice } from "@reduxjs/toolkit";

const healthCheckSlice = createSlice({
  name: "healthCheck",
  initialState: {
    loading: false,
    internetStatus: "close",
    vpnStatus: "close",
    analyserStatus: "close",
    reporterStatus: "close",
    brokerStatus: "close",
    diskStatus: "close",
    ftiStatus: "close",
    mlStatus: "close",
  },
  reducers: {
    TriggerHealthCheck: (state) => {
      state.loading = true;
    },
    FinishHealthCheck: (state) => {
      state.loading = false;
    },
    SuccessInternetStatus: (state, action) => {
      state.internetStatus = action.payload;
    },
    FailureInternetStatus: (state) => {
      state.internetStatus = "close";
    },
    SuccessVpnStatus: (state, action) => {
      state.vpnStatus = action.payload;
    },
    FailureVpnStatus: (state) => {
      state.vpnStatus = "close";
    },
    SuccessAnalyserStatus: (state, action) => {
      state.analyserStatus = action.payload;
    },
    FailureAnalyserStatus: (state) => {
      state.analyserStatus = "close";
    },
    SuccessReporterStatus: (state, action) => {
      state.reporterStatus = action.payload;
    },
    FailureReporterStatus: (state) => {
      state.reporterStatus = "close";
    },
    SuccessBrokerStatus: (state, action) => {
      state.brokerStatus = action.payload;
    },
    FailureBrokerStatus: (state) => {
      state.brokerStatus = "close";
    },
    SuccessDiskStatus: (state, action) => {
      state.diskStatus = action.payload;
    },
    FailureDiskStatus: (state) => {
      state.diskStatus = "close";
    },
    SuccessFtiStatus: (state, action) => {
      state.ftiStatus = action.payload;
    },
    FailureFtiStatus: (state) => {
      state.ftiStatus = "close";
    },
    SuccessMlStatus: (state, action) => {
      state.mlStatus = action.payload;
    },
    FailureMlStatus: (state) => {
      state.mlStatus = "close";
    },
  },
});

export default healthCheckSlice;
